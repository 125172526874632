//@ts-nocheck
import React from "react";
import {
    withStyles,
    Checkbox,
    TextField,
    Typography,
    InputAdornment,
    Grid,
    Button,
    Box,
    Snackbar
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { Link, Redirect } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import ChangePasswordController from "./changePasswordController.web";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import TopNav from "../../../components/src/TopNav";
import lock from "../assets/lock.png";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';

const useStyles = (theme) => ({
    root: {
        paddingTop: "60px",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
    },
    sideLeafs: {
        position: "absolute",
        bottom: "-5px",
        right: "-35px",
    },
    leafTL: {
        width: "100px",
        height: "100px",
        borderRadius: "0 40px",
        margin: "5px 0",
    },
    leafTR: {
        width: "100px",
        height: "100px",
        borderRadius: "40px 0",
        margin: "5px 0",
    },
    orange: {
        backgroundColor: "#FF9A30",
    },
    lBlue: {
        backgroundColor: "#B6D9FD",
    },
    blue: {
        backgroundColor: "#5597F4",
    },
    dBlue: {
        backgroundColor: "#0D016A",
    },
    pageTitle: {
        fontSize: "40px",
        fontWeight: "700",
        display: "inline-block",
        position: "relative",
        color: "#000000",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "22%",
            height: "20px",
            bottom: "-3",
            left: "40%",
            borderBottom: "4px solid #B5D8FD",
        },
        '@media (max-width:1425px)': {
            fontSize: "30px",
        },
    },
    underLine: {
        fontWeight: "bold",
        position: "relative",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "40%",
            height: "5px",
            bottom: "0",
            left: "30%",
            borderBottom: "0.5px solid #D6D6D6",
        },
    },
    leafGrid: {
        gap: "10px",
    },
    normalUrl: {
        color: "#7BAEF7",
        textDecoration: "none",
    },
    orangeText: {
        color: "#FF9B31",
        fontWeight: 'bold'
    },
    form: {
        gap: "20px",
        zIndex: 1,
    },
    textBtn: {
        padding: "0 5px",
        fontWeight: "bold",
        cursor: "pointer",
    },
    formBtn: {
        textTransform: "none",
        backgroundColor: "#5597F4",
        borderRadius: "30px",
        width: "40%",
        "&:hover": {
            backgroundColor: "#5597F4",
        },
        "& p": {
            fontWeight: "bold",
            color: "#ffffff"
        },
    },
    checkBoxFont: {
        fontSize: "16px",
        '@media (max-width:1425px)': {
            fontSize: "12px",
        },
    },
    formBtnFont: {
        fontSize: "13px",
        fontWeight: "700",
        '@media (max-width:1425px)': {
            fontSize: "9.75px",
        },
    },
    checkBox: {
        padding: 5
    },
    checkBoxRoot: {
        padding: 0,
        color: "black"
    },
    input: {
        marginLeft: "10px",
        '&::placeholder': {
            //textOverflow: 'ellipsis !important',
            color: '#3f4144',
            opacity: 1,
            fontWeight: "700",
            fontFamily: "Open Sans",
            fontSize: "20px",
            '@media (max-width:1425px)': {
                fontSize: "15px",
            },

        }
    },
    ovalFontStyle: {
        fontSize: "40px",
        fontWeight: "700",
        color: "#FFFFFF",
        textAlign: "center",
        '@media (max-width:1425px)': {
            fontSize: "30px",
        },
    },
    ovalBox: {
        height: "60px",
        width: "60px",
        backgroundColor: "#FF9933",
        borderRadius: "50%",
        margin: "auto",
        marginBottom: "30px",
        marginTop: "-20px",
        '@media (max-width:1425px)': {
            height: "45px",
            width: "45px",
        },
    },
    subHeaderFont: {
        color: "#000000",
        fontSize: "30px",
        fontWeight: "700",
        textAlign: "center",
        marginBottom: "60px",
        '@media (max-width:1425px)': {
            fontSize: "22.5px",
        },
    },
    subHeaderBodyStyle: {
        color: "#3F4144",
        fontSize: "20px",
        fontWeight: "400",
        textAlign: "center",
        marginBottom: "60px",
        '@media (max-width:1425px)': {
            fontSize: "15px",
        },
    },

});
class ConfirmationSignup extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        this.state = {
            loginPage: true,
            ...this.state,
        };
    }
    // goToDashboard() {
    //     this.props.history.push('/dashboard');
    // }
    // updatePage = () => {
    //     this.setState({ loginPage: !this.state.loginPage, userName: "", email: "", password: "", reTypePassword: "", phone: "" })
    // }
    // updateValueRetype = (name, value) => {
    //     this.setState({ [name]: value, triggerMethodR: true });

    // }
    // updateValue = (name, value) => {
    //     this.setState({ [name]: value, triggerMethod: true });

    // };
    // updateTokenUrl() {
    //     this.setState({ tokenUrl: new URLSearchParams(location.search)?.get('token') })
    // }
    // componentDidMount() {

    //     // console.log(new URLSearchParams(location.search)?.get('token'))
    // }
    // componentDidMount(): Promise<void> {
    //     // this.setState({ search: location.search })
    //     // this.setState({ tokenUrl: new URLSearchParams(this.state.search)?.get('token') })
    //     // console.log("lalalla", this.state.tokenUrl)
    //     // console.log(this.state.search)
    //     // console.log(new URLSearchParams(location.search)?.get('token'))

    // }
    async componentDidMount() {
        this.setState({ signUpEmail: await StorageProvider.get('signupEmail') })

    }


    render() {
        const { classes } = this.props;

        return (
            <Grid
                container
                justifyContent="center"
                alignItems="flex-start"
                className={classes.root}
            >
                <Grid
                    item
                    container
                    direction="column"
                    xs={10} sm={10} md={12}
                    alignItems="center"
                    justifyContent="center"
                    className={classes.form}
                >
                    <Grid item container xs={12} justifyContent="center" style={{ marginBottom: "50px" }}>

                        <Typography variant="h5" className={classes.pageTitle} >
                            Almost there! Last steps.
                        </Typography>

                    </Grid>

                    <Grid item xs={12} style={{ width: "100%" }}>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={5}
                            //style={{ borderRight: "1px solid" }}
                            >
                                <Grid container direction="column">
                                    <Grid item >
                                        <Box className={classes.ovalBox}><Typography className={classes.ovalFontStyle}>1</Typography></Box>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.subHeaderFont}>Email Verification</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.subHeaderBodyStyle}>You have succesfully created a Neeuh Account.<br /> Please click the link below to verify your email<br /> address and complete your registration. </Typography>
                                    </Grid>
                                    <Grid item container xs={12} justifyContent="center">
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                //this.checkSubmitStatus() ? this.resetPasswordMain() : <></>
                                                this.sendVerification(this.state.signUpEmail)

                                            }
                                            className={classes.formBtn}
                                        >
                                            <Typography variant="body2" className={classes.formBtnFont}>SEND EMAIL</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box style={{ height: "420px", width: "2px", backgroundColor: "#979797" }} />
                            {/* <Grid item xs={1}>
                                <Box style={{ height: "100%", width: "5px", backgroundColor: "black" }} />
                            </Grid> */}
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={this.state.errorMessage}
                                onClose={() => {
                                    this.setState({
                                        errorMessage: false
                                    });
                                }}
                                autoHideDuration={3000}
                            >
                                <Alert severity="error"
                                    elevation={6} variant="filled"
                                >Account not exist.</Alert>
                            </Snackbar>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={this.state.successMessage}
                                onClose={() => {
                                    this.setState({
                                        successMessage: false
                                    });
                                }}
                                autoHideDuration={3000}
                            >
                                <Alert severity="success"
                                    elevation={6} variant="filled"
                                >Email activation link sent to your email!.</Alert>
                            </Snackbar>

                            <Grid item xs={5}
                            //style={{ borderRight: "1px solid" }}
                            >
                                <Grid container direction="column">
                                    <Grid item>
                                        <Box className={classes.ovalBox}><Typography className={classes.ovalFontStyle}>2</Typography></Box>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.subHeaderFont} style={{ marginBottom: "90px" }}>Start using Neeuh!</Typography>
                                    </Grid>

                                    <Grid item container xs={12} justifyContent="center">
                                        <Button
                                            variant="contained"
                                            onClick={() =>

                                                this.setState({ openDashboardLink: true })
                                            }
                                            className={classes.formBtn}
                                        >
                                            <Typography variant="body2" className={classes.formBtnFont}>OPEN DASHBOARD</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.openDashboardLink ? <Redirect to={'/'} /> : <></>}
                    {/* <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.notSuccessful}
                        onClose={() => {
                            this.setState({
                                notSuccessful: false
                            });
                        }}
                        autoHideDuration={3000}
                    >
                        <Alert severity="error"
                            elevation={6} variant="filled"
                        >Token Expired. Please try Again.</Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.resetPasswordSuccessful}
                        onClose={() => {
                            this.setState({
                                resetPasswordSuccessful: false, redirectLink: true
                            });
                        }}
                        autoHideDuration={3000}
                    >
                        <Alert severity="success"
                            elevation={6} variant="filled"
                        >Your password has been reset successfully.</Alert>
                    </Snackbar> */}





                    {/* {this.state.redirectLink ? <Redirect to={'/EmailAccountRegistration'} /> : <></>} */}




                    {/* <Grid item container xs={12} justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() =>
                                this.checkSubmitStatus() ? this.resetPasswordMain() : <></>

                            }
                            className={classes.formBtn}

                        >

                            <Typography variant="body2" className={classes.formBtnFont}>Submit</Typography>



                        </Button>

                    </Grid> */}


                </Grid>
            </Grid>
        );
    }
}

export default withStyles(useStyles)(ConfirmationSignup);

// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  InputBase,
  Divider,Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import { ReactComponent as VisaSvg } from '../assets/Visa.svg';
import { CardPng } from "./assets";
import { AmericanExpress, dinersCard, discoverCard, jcbCard, MasterCard, lockWithChecked,maestroCard,unionCard ,blank_Card} from "./assets";
import { ReactComponent as QuestionSvg } from '../assets/question-circle.svg';
import VerifyAccountController from '../../Settings5/src/VerifyAccountController.web';
interface Props {
  clases:any;
  navigation:any;
  id:any;
  history:any;
}

const useStyles = (theme) => ({

  emailText:{
    fontSize:40,
    fontWeight:700,
    color:"#000000",
    margin:"5px 0px",
    '@media (max-width:1425px)':{
      fontSize:30
        }
  },
  underLine: {
    width:114,
    height:5,
    backgroundColor:"#B5D8FD",
    '@media (max-width:1425px)':{
        width:85.50,
        height:3.75,
          }
},
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  headerText:{
    fontSize:25,
    fontWeight:500,
    color:"#000000",
    justifyContent:"center",
    alignItems:"center",
    margin:"10px 0px 20px 0px",
    '@media (max-width:1425px)':{
      fontSize:18.75
        }
  },
  paddingLeft:{
    padding:"20px 10px 20px 0px" 
  },
  desText:{
    fontSize:13,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:9.75
        }
  },
  visa:{
    border: "1px solid #000000",
    padding: "0px 5px 0px 5px",
    height:31,
    width:62,
    '@media (max-width:1425px)':{
      width:41,
    height:22,
        }

  },
  logo:{
    width:62,
    height:33,
    '@media (max-width:1425px)':{
      width:42,
    height:23,
        }
  },
  logos:{
    width:84,
    height:43,
    '@media (max-width:1425px)':{
      width:63,
    height:32.25,
        }
  },
  border:{
    borderTop:"1px solid #979797",
    borderBottom:"1px solid #979797",
    margin:"30px 0px "
  },
  verifyButton:{
    width:163,
    height:28,
    color:"white",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0"
  },
  padding:{
    paddingTop:20,
  },
  link:{
    color:"#5696F4",
    fontSize:18,
    fontWeight:400,
    marginTop:10,
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
formBtnBlue:{
    margin:"20px 0px",
    padding:"0px 40px",
    backgroundColor: "#5597F4",
    // width:119,
    height:28,
    borderRadius: "14px",
    "&:hover": {
        backgroundColor: "#5597F4",
    },
    "& p": {
        fontWeight: "bold",
        color: "#ffffff"
    },
    '@media (max-width:1425px)': {
      height: "18.75",
  },
},
formBtnFont: {
    color: "#3F4144",
    fontSize: "13px",
    fontWeight: 700,
    '@media (max-width:1425px)': {
        fontSize: "9.75px",
    },
},
textField:{
    border:"1px solid #000000",
    padding:"10px 0px 5px 10px",
    borderRadius:7, 
    margin:"5px 0px 0px 0px",
    width:474,
    height:64,
    '@media (max-width:1425px)': {
      width:355,
      height:55,
      padding:"7.5px 0px 7.5px 7.5px",
  },

},
currentEmailInput:{
    pading:0,
    margin:0,
    width:"90%",

    '&::placeholder': {
        //textOverflow: 'ellipsis !important',
        color: '#3F4144',
        opacity: 1,
        fontWeight: "500",
        fontFamily: "Open Sans",
        fontSize: "20px",
        '@media (max-width:1425px)': {
            fontSize: "15px",
        },
    }
},
paymentSlip:{
    backgroundColor:"#EBEBEB",
    height:434, 
    width:465,
    marginTop:40,
    borderRadius:13,
    marginLeft:'7%',
    '@media (max-width:1425px)': {
        height:325.50, 
        width:348.75,
    },
},
typo:{
  fontSize:12, 
  color:"#AEAEAE",
  '@media (max-width:1425px)': {
  fontSize:"9px"
},
},
headingPayment:{
  fontSize:26,
    fontWeight:700,
    color:"#000000",
    marginTop:"20px",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:19.75
        }
},
cardText:{
  fontSize:25,
  fontWeight:500,
  color:"#3F4144",
  // justifyContent:"center",
  // alignItems:"center",
  // margin:"10px 0px 20px 0px",
  '@media (max-width:1425px)':{
    fontSize:18.75
      }
},
paymentText:{
  fontSize:21,
  fontWeight:500,
  color:"#000000",
  // margin:"10px 0px 20px 0px",
  '@media (max-width:1425px)':{
    fontSize:15.75
      }
},
paymentTotalText:{
  fontSize:30,
  fontWeight:700,
  color:"#000000",
  // margin:"10px 0px 20px 0px",
  '@media (max-width:1425px)':{
    fontSize:22.50
      }
}
  
})
class InitialPaymentWeb extends VerifyAccountController{
  constructor(props:any){
    super(props)
    this.state = {
      // timeToStop: localStorage.getItem("endTime"),
      // expiredTime : false,
      ...this.state
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <><Grid style={{   marginLeft:  "80%"  }}>
        {this.state.expiredTime ? (
            <Typography   variant="h6">
                    Time has expired.
              </Typography>
        ) :   (
              <Typography     variant="h6">
                    Time remaining:{Array.isArray(this.state.time) ? (
              this.state.time.map((d, i) =>   <u    key={i}> {d} {i === 0 && ':'}   </u>)
            ) :     null}
            </Typography>
        )}
      </Grid><Grid item container xs={12} lg={12} md={12} sm={12} justifyContent="center" alignItems="center" className={classes.paddingLeft}>
          <Grid item xs={12} lg={12} className={classes.alignment}>
            <img src={CardPng} className={classes.logos} />
          </Grid>
          <Grid item xs={12} lg={12} className={classes.alignment}>
            <Typography>
              STEP 3 OF 3
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} className={classes.alignment}>
            <Typography className={classes.emailText}>
              Set up payment
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} className={classes.alignment}>
            <div className={classes.underLine}>
            </div>
          </Grid>
          <Grid item xs={12} lg={12} className={classes.alignment}>
            <Typography className={classes.headerText}>
              Your membership begins as soon as you set up payment.
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.padding} style={{ width: "90%" }} justifyContent="center">
            <Grid item container lg={5} md={5} sm={12} xs={12} className={classes.alignment}>
              <Grid item container xs={12} lg={8} md={8} sm={12} className={classes.alignment} style={{ marginBottom: 10 }}>
                <Grid item xs={3} sm={2} md={2} lg={2}>
                  <VisaSvg className={classes.visa} />
                </Grid>
                <Grid item xs={3} sm={2} md={2} lg={2}>
                  <img src={MasterCard} className={classes.logo} />
                </Grid>
                <Grid item xs={3} sm={2} md={2} lg={2}>
                  <img src={AmericanExpress} className={classes.logo} />
                </Grid>
                <Grid item xs={3} sm={2} md={2} lg={2}>
                  <img src={discoverCard} className={classes.logo} />
                </Grid>
              </Grid>
              <Grid item container xs={10} lg={10} sm={9} md={9} className={classes.alignment} className={classes.textField}>
                <Typography className={classes.typo}>
                  First Name
                </Typography>
                <TextField
                  id="filled-read-only-input"
                  // label="First Name"
                  value={this.state.firstNameCard ? this.state.firstNameCard : ""}
                  fullWidth
                  // defaultValue="First Name"
                  placeholder="First Name"
                  onChange={(e) => this.setState({ firstNameCard: e.target.value })}

                  className={classes.alignment}
                  InputProps={{
                    classes: { input: classes.currentEmailInput },
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    style: { color: '#AEAEAE', fontWeight: 500, fontSize: 12 },
                  }} />

              </Grid>
              <Grid item container xs={10} lg={10} sm={9} md={9} className={classes.alignment} className={classes.textField}>
                <Typography className={classes.typo}>
                  Last Name
                </Typography>
                <TextField
                  placeholder="Last Name"
                  value={this.state.lastNameCard ? this.state.lastNameCard : ""}
                  onChange={(e) => this.setState({ lastNameCard: e.target.value })}

                  fullWidth
                  className={classes.alignment}
                  InputProps={{
                    classes: { input: classes.currentEmailInput },
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    style: { color: '#AEAEAE', fontWeight: 500, fontSize: 12 },
                  }} />

              </Grid>
              <Grid item container xs={10} lg={10} sm={9} md={9} className={classes.alignment} className={classes.textField}>
                <Typography className={classes.typo}>
                  Card Number
                </Typography>
                <TextField
                  // id="filled-read-only-input"
                  placeholder="************0000"
                  value={this.state.cardNumberCard ? this.state.cardNumberCard : ""}
                  onChange={this.cardHandler}
                  // onChange={(e)=> this.setState({cardNumberCard:e.target.value})}
                  fullWidth
                  className={classes.alignment}
                  InputProps={{
                    classes: { input: classes.currentEmailInput, },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { color: '#AEAEAE', fontWeight: 500, fontSize: 12 },
                  }} />

              </Grid>
              <Grid item container xs={10} lg={10} sm={9} md={9} className={classes.alignment} className={classes.textField}>
                <Typography className={classes.typo}>
                  Expiration Date (MM/YY)
                </Typography>
                <TextField
                  id="filled-read-only-input"
                  placeholder="MM/YY"
                  onChange={this.expiryIdHandler}
                  // defaultValue="First Name"
                  value={this.state.expirationDateCard ? this.state.expirationDateCard : ""}
                  fullWidth
                  className={classes.alignment}
                  InputProps={{
                    // readOnly: true,
                    classes: { input: classes.currentEmailInput },
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    style: { color: '#AEAEAE', fontWeight: 500, fontSize: 12 },
                  }} />

              </Grid>
              <Grid item container xs={10} lg={10} sm={9} md={9} className={classes.alignment} className={classes.textField}>
                <TextField
                  id="filled-read-only-input"
                  type="number"
                  placeholder="Security Code (CVV)"
                  value={this.state.securityCodeCard ? this.state.securityCodeCard : ""}
                  onChange={(e) => this.setState({ securityCodeCard: (e.target.value).slice(0, 3) })}
                  fullWidth
                  className={classes.alignment}
                  InputProps={{
                    // readOnly: true,
                    classes: { input: classes.currentEmailInput },
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    style: { color: '#AEAEAE', fontWeight: 500, fontSize: 12 },
                  }} />

              </Grid>
              <Grid item container xs={10} lg={10} sm={9} md={9} className={classes.alignment} className={classes.textField}>
                <Typography className={classes.typo}>
                  Billing Zip Code
                </Typography>
                <TextField
                  id="filled-read-only-input"
                  placeholder="000000"
                  type="number"
                  // defaultValue="First Name"
                  value={this.state.zipCodeCard ? this.state.zipCodeCard : ""}
                  onChange={(e) => this.setState({ zipCodeCard: (e.target.value).slice(0, 6) })}
                  fullWidth
                  className={classes.alignment}
                  InputProps={{
                    // readOnly: true,
                    classes: { input: classes.currentEmailInput },
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    style: { color: '#AEAEAE', fontWeight: 500, fontSize: 12 },
                  }} />

              </Grid>
              <Grid item container xs={10} lg={10} sm={9} md={9} style={{ textAlign: "justify", margin: "10px 0px 20px 0px" }}>
                <Typography className={classes.desText}>
                  By clicking the “Save” button below, you agree that <b>Neeuh will automatically continue your membership and charge the membership fee (currently $00.00) to your payment method until you cancel.</b> You may cancel at any time to avoid future charges. To cancel, go to Company Settings click Billing & Subscription and click “Cancel Membership.”
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} md={12} sm={12} className={classes.alignment}>
                <Button
                  variant="contained"
                  className={classes.formBtnBlue}
                  onClick={this.addPaymentCard}
                >
                  <Typography variant="body2" className={classes.formBtnFont}>save</Typography>
                </Button>

              </Grid>


            </Grid>
            <Grid item container lg={5} md={5} sm={12} xs={12}>
              <Grid item container lg={10} md={12} sm={12} xs={12} className={classes.paymentSlip}>
                <Grid item sm={12} xs={12} md={12} lg={12} style={{ height: "50px", paddingBottom: 0, marginBottom: 0 }}>
                  <Typography className={classes.headingPayment}>
                    Payment Summary
                  </Typography>
                </Grid>
                <Grid item container lg={12} md={12} xs={12} sm={12} style={{ height: "45px" }} className={classes.alignment}>
                  <Grid item xs={3} lg={2} md={3} sm={3}>
                    {this.state.cardData[0]?.attributes.card_type == "masterCard" ?
                      <img src={MasterCard} className={classes.logos} /> :
                      this.state.cardData[0]?.attributes.card_type == "discover" ?
                        <img src={discoverCard} className={classes.logos} /> :
                        this.state.cardData[0]?.attributes.card_type == "americanExpress" ?
                          <img src={AmericanExpress} className={classes.logos} /> :
                          this.state.cardData[0]?.attributes.card_type == "visa" ?
                            <VisaSvg className={classes.logos} /> :
                            this.state.cardData[0]?.attributes.card_type == "diners" ?
                              <img src={dinersCard} className={classes.logos} /> :
                              this.state.cardData[0]?.attributes.card_type == "maestro" ?
                                <img src={maestroCard} className={classes.logos} /> :
                                this.state.cardData[0]?.attributes.card_type == "unionPay" ?
                                  <img src={unionCard} className={classes.logos} /> :
                                  this.state.cardData[0]?.attributes.card_type == "jcb" ?
                                    <img src={jcbCard} className={classes.logos} /> : <img src={blank_Card} className={classes.logos} />}
                  </Grid>
                  <Grid item xs={8} lg={6} md={8} sm={9}>
                    <Typography className={classes.cardText}>
                      {this.state.cardData[this.state.cardData.length - 1]?.attributes?.card_number ? this.state.cardData[this.state.cardData.length - 1]?.attributes?.card_number : "**** **** **** 0000"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12} className={classes.alignment} style={{ padding: "0px 40px" }}>
                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentText} style={{ textAlign: "start" }}>
                      Subtotal
                    </Typography>
                  </Grid>
                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentText} style={{ textAlign: "end" }}>
                      {this.state.planDataById?.attributes?.price ? "$" + this.state.planDataById?.attributes?.price : "$0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12} className={classes.alignment} style={{ padding: "0px 40px" }}>
                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentText} style={{ textAlign: "start" }}>
                      My Savings
                    </Typography>
                  </Grid>

                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentText} style={{ textAlign: "end" }}>
                      {this.state.planDataById?.attributes?.saving ? "-$" + this.state.planDataById?.attributes?.saving : "$0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12} className={classes.alignment} style={{ margin: "0px 40px", borderBottom: "2px solid #979797", paddingBottom: 5 }}>
                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentText} style={{ textAlign: "start" }}>
                      Ext.Taxes
                    </Typography>
                  </Grid>

                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentText} style={{ textAlign: "end" }}>
                      {this.state.planDataById?.attributes?.est_tax ? "$" + this.state.planDataById?.attributes?.est_tax : "$0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12} className={classes.alignment} style={{ padding: "5px 40px" }}>
                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentTotalText} style={{ textAlign: "start" }}>
                      Total
                    </Typography>
                  </Grid>

                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Typography className={classes.paymentTotalText} style={{ textAlign: "end" }}>
                      {this.state.planDataById?.attributes?.total ? "$" + this.state.planDataById?.attributes?.total : "$0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12} className={classes.alignment}>
                  <Button
                    variant="contained"
                    className={classes.formBtnBlue}
                    onClick={this.makePayment}
                  >
                    <Typography variant="body2" className={classes.formBtnFont}>MAKE PAYMENT</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showSnackbar}
            onClose={() => {
              this.setState({
                showSnackbar: false
              });
            } }
            autoHideDuration={3000}
          >
            <Alert severity={this.state.alertType}
              elevation={6} variant="filled"
            >{this.state.snackbarMessage}</Alert>
          </Snackbar>
        </Grid></>
       
    );
  }
}

export {InitialPaymentWeb}
export default withStyles(useStyles)(InitialPaymentWeb)
// Customizable Area End
// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputBase,
  Divider,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import VerifyAccountController from './VerifyAccountController.web';
import { lockWithChecked } from "./assets";
import {ReactComponent as EnvelopeSvg} from '../assets/Envelope.svg';
import { CardPng } from "./assets";
const useStyles = (theme) => ({
  gridBox:{
    padding: "20px 0 20px 0",
    borderRadius:7,
    border:"1px solid #AEAEAE",
    margin:"5px 0px",
    height:82.67,
    width:526,
    margin:"20px 0",
    '@media (max-width:1425px)':{
      padding: "10px 0 10px 0",
      height:50,
      width:324.5
       }
  },
  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:21.75
       }
  },
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:13.5
       }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  locksvg:{
    width:75,
    height:85,
    paddingBottom:10,
    '@media (max-width:1425px)':{
      width:55,
      height:65,
       }
  },
  headerText:{
    fontSize:18,
    fontWeight:700,
    color:"#3F4144",
    paddingRight:5,
    '@media (max-width:1425px)':{
      fontSize:13.5
       }
  },

  desText:{
    fontSize:16,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:12
       }
  },
  envelopesvg:{
    width:19,
    height:12,
    '@media (max-width:1425px)':{
      width:15,
      height:10
       }
    
  },
  text2:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:13.5
       }
  },
  verifyButton:{
    width:119,
    height:28,
    color:"white",
    fontWeight:"bold",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0",
    "&:hover": {
      backgroundColor: "#5696F4",
  },
  '@media (max-width:1425px)':{
    width:89,
    height:28,
    fontSize: "9.75px",

      }
  },
  input:{
    paddingTop:4,
    color:"black",
    '@media (max-width:1425px)':{
      paddingTop:6,
      fontSize:12
       }
  },
  padding:{
    paddingTop:80
  }
  
})
class VerifyPaymentInfoWeb extends  VerifyAccountController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
      <form onSubmit={this.verifyCardNumber}>
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" className={classes.padding}>

           <Grid item container lg={8} xs={8}  >
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                <img src ={lockWithChecked} className={classes.locksvg}/>     
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                    Ok, lets verify your Payment info.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment} >
                    <Typography className={classes.text}>
                    Please re-enter the card on file
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment} >
                        <Typography className={classes.text}>
                            <b className={classes.headerText}>VISA ending in {this.state.cardDataById?.attributes?.card_number.slice(-4)?this.state.cardDataById?.attributes?.card_number.slice(-4): "0000"} </b> to help protect your account
                        </Typography>
                   
                </Grid>
                <Grid item container xs={12} lg={12} justifyContent="center" alignItems="center"  >
                <Grid item container xs={8} lg={8} className={classes.gridBox}>
                    <Grid item xs={2} lg={2}  className={classes.alignment}>
                    <img src ={CardPng} className={classes.envelopesvg}/>     
                    </Grid>
                    <Grid item xs={10} lg={10} md={10}>
                         <TextField
                            required
                            id="filled-read-only-input"
                            type="number"
                            value={this.state.cardNumber}
                            onChange={(e)=>{
                              this.setState({cardNumber:e.target.value})
                            }}
                            placeholder="Debit or Credit Card Number"
                            fullWidth
                            className={classes.alignment}
                            InputProps={{
                                classes: { input: classes.input },
                                disableUnderline: true,
                            }}
                            InputLabelProps={{
                                style: { color: '#AEAEAE',fontWeight:500, fontSize:12 },
                              }}
                            />

                    </Grid>
                </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} className={classes.alignment}>
                <Button variant="contained" type="submit" className={classes.verifyButton}>
                  Verify
                </Button>

                </Grid>
           </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        </form>
    );
  }
}

export default withStyles(useStyles)(VerifyPaymentInfoWeb)
// Customizable Area End
// Customizable Area Start
//@ts-nocheck
import React from "react";
import MileageController from "./MileageContoller.tsx";
import {
  Box,
  Typography,
  Button,
  withStyles,
  Grid,
  TextField,
  Select,
  Snackbar,
  MenuItem,
  TextareaAutosize
} from "@material-ui/core";
import DatePicker from 'react-date-picker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ReactComponent as CarSVG } from '../assets/car.svg'
import Alert from '@material-ui/lab/Alert';
import StorageProvider from "../../../framework/src/StorageProvider.web";


const useStyles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    position: "relative",
  },
  mainDiv: {
    minHeight: "100vh"
  },
  formBtn: {
    textTransform: "none",
    backgroundColor: "#5597F4",
    width: "130px",
    borderRadius: "30px",
    "& p": {
      fontWeight: "bold",
      color: "#ffffff"
    },
    "&:hover": {
      backgroundColor: "#5597F4",
    },
  },
  sideDiv: {
    borderLeft: "1px solid #000000",
  },
  leaf: {
    backgroundColor: "#B5D8FD",
    width: "60px",
    height: "60px",
    borderRadius: "20px 0",
    margin: "2px",
  },
  monthsDate: {
    width: "60px",
  },
  justifyCenter: {
    justifyContent: "space-evenly",
    textAlign: "center"
  },
  modalButtons: {
    textTransform: "none",
    backgroundColor: "#5597F4",
    width: "80px",
    borderRadius: "30px",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#5597F4",
    },
  },
  leafBorder: {
    border: ".5px solid #B5D8FD",
    width: "60px",
    height: "60px",
    borderRadius: "20px 0",
    margin: "2px",
  },
  greyBox: {
    width: "286px",
    height: "177px !important",
    padding: "5px"
  },
  formDate: {
    "& div": {
      minWidth: 0,
      padding: "0 2px",
    },
    "& > div": {
      padding: "4px"
    }
  },
  formField: {
    alignItems: "center",
    width: "300px",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
  dialogFormField: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
  formSelect: {
    border: "thin solid gray",
    padding: "4px",
    width: "100%",
    "& svg": {
      width: "20px",
    }
  },
  spaceLR: {
    margin: "0 10px"
  },
  middleDiv: {
    position: "relative",
    marginTop: "20px",
    marginBottom: "10px",
    paddingBottom: "10px",
  },
  bottomDiv: {
    marginTop: "20px",
  },
  formTextField: {
    width: "90px",
    // textAlign: "end",
    "& .MuiInputBase-inputMarginDense": {
      padding: 0
    }
  },
  circle: {
    borderRadius: "50%",
    height: "60px",
    width: "60px",
    border: "1px solid #FF9933",
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      cursor: 'pointer'
    }
  },
  filledCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "50%",
    height: "60px",
    width: "60px",
    backgroundColor: "#FF9933",
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      cursor: 'pointer'
    }
  },
  dayDetails: {
    textAlign: "center",
  },
  boldFont: {
    fontWeight: "bold",
    padding: "0 1px"
  },
  blueFont: {
    color: "#5696F4"
  },
  carSVG: {
    width: "40px",
    height: "40px"
  },
  grayFont: {
    color: "#B4B5B5"
  },
  carName: {
    color: "#B4B5B5"
  },
  carNameTextField: {
    width: "80%"
  },
  topDiv: {
    paddingTop: "30px",
    borderBottom: "1px solid #000000"
  },
  carDetail: {
    marginTop: "20px",
  },
  dBlueFont: {
    color: "#19249C"
  },
  whiteFont: {
    color: "#ffffff"
  },
  totalMiles: {
    marginTop: "20px"
  },
  marginTopDiv: {
    marginTop: "20px"
  },
  grayBox: {
    background: "#ECECEC",
    padding: "10px 0"
  },
  yellowFont: {
    color: "#FF9A30"
  },
  monthlyDetuction: {
    margin: "20px 0",
    "& > div": {
      margin: "10px 0"
    }
  },
  allCaps: {
    textTransform: 'uppercase'
  },
  font20: {
    fontSize: "20px"
  },
  font24: {
    fontSize: "24px"
  },
  font18: {
    fontSize: "18px",
  },
  font15: {
    fontSize: "15px"
  },
  font25: {
    fontSize: "25px"
  },
  font30: {
    fontSize: "30px"
  },
  '@media (max-width:1425px)': {
    font20: {
      fontSize: "15px"
    },
    greyBox: {
      width: "214.5px",
      height: "132.75px !important",
    },
    font18: {
      fontSize: "13.5px"
    },
    formSelect: {
      fontSize: "13.5px"
    },
    font15: {
      fontSize: "12px"
    },
    font25: {
      fontSize: "18.75px"
    },
    font30: {
      fontSize: "22.5px"
    },
    font24: {
      fontSize: "18px"
    },
  },
  '@media (max-width:1100px)': {
    formDiv: {
      justifyContent: "space-around"
    },
    font20: {
      fontSize: "11.25px"
    },
    font18: {
      fontSize: "10px"
    },
    formSelect: {
      fontSize: "10px",
      width: "86%"
    },
    font15: {
      fontSize: "8.5px"
    },
    font25: {
      fontSize: "14px"
    },
    font30: {
      fontSize: "16px"
    },
    leaf: {
      width: "40px",
      height: "40px",
      borderRadius: "13px 0"
    },
    leafBorder: {
      width: "40px",
      height: "40px",
      borderRadius: "13px 0"
    },
    monthsDate: {
      width: "40px",
    },
    font24: {
      fontSize: "13.5px"
    },
    carSVG: {
      width: "25px",
      height: "25px"
    },
    addButton: {
      width: "30%"
    },
    greyBox: {
      width: "85%",
    },
    grayBox: {
      maxWidth: "65%"
    },
    formField: {
      width: "99%"
    },
    topSideDiv: {
      maxWidth: "50%",
      flexBasis: "50%"
    },
    formBtn: {
      width: "30%"
    }
  }
});
class MileageWeb extends MileageController {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }
  async componentDidMount() {
        if (localStorage.getItem("role")) {
      this.setState({ role: localStorage.getItem("role") }, () => { this.checkTheRole() })
    }
    if (localStorage.getItem("mileageDate")) {
      this.setState({ formDate: localStorage.getItem("mileageDate") }, () => {
        localStorage.removeItem('mileageDate')
        this.updateWeek(0, this.props.taxYear)
      })
    }
        if (localStorage.getItem("carId")) {
      this.setState({ formCar: localStorage.getItem("carId") }, () => localStorage.removeItem('carId'))
    }
    if (localStorage.getItem("carId") === "undefined") {
      this.setState({ showSnackbar: true, snackbarMessage: 'Car is not added, first you need to add car to enter a miles.' })
    } else {
      this.setState({ formCar: localStorage.getItem("carId") ? localStorage.getItem("carId").toString : "" })
    }
    this.setState({ token: await StorageProvider.get('token') }
      , () => this.updateWeek(0, this.props.taxYear))

  }

  limitLength(e) {
    if (e.target.value.length <= 250) {
      this.setState({ formText: e.target.value })
    }
  }
  limitCarNameLength(e) {
    if (e.target.value.length <= 20) this.setState({ newCarName: e.target.value })
  }

  carMonthlyMiles(car) {
    if (this.state.mileageData.length) {
      car.data = this.state.mileageData.find((el: any) => el.car_id == car.id)
      car.monthlyMiles = car.data?.monthly_miles
    }
    return car
  }
  addCarMessage(classes) {
    if (this.state.cars.length) {
      return <Select className={classes.formSelect} disableUnderline onChange={(e) => this.setState({ formCar: e.target.value })} value={this.state.formCar}>
        {this.state.cars.map(car =>
          <MenuItem key={car.id} value={car.id} className={`${classes.font18} ${classes.boldFont}`}>{car.name}</MenuItem>
        )}
      </Select>
    }
    else
      return <Typography variant='caption'>Add car to Select</Typography>
  }
  replaceButton(flag, index, car) {
    if (flag) {
      return <>
        <Button variant="outlined">
          <Typography variant="caption" onClick={() => this.updateCarName(car.id)}>
            Update
          </Typography>
        </Button>
      </>
    } else {
      return <Button variant="outlined">
        <Typography variant="caption" onClick={() =>
          this.setState({ activeEdit: index, newCarName: car.name })}>
          Edit
        </Typography>
      </Button>
    }
  }
  showLeaf(index, classes, deduction = 0) {
    if (index <= this.state.currentMonth) {
      return (
        <Grid item container className={classes.leaf} justifyContent="center" alignItems="center" >
          <Typography variant="caption" className={classes.font18} >${Math.round(deduction.value) || 0}</Typography>
        </Grid>
      );
    } else {
      return <Grid item className={classes.leafBorder}>
      </Grid>
    }
  }

  mileageCircleClass(mileageData, classes) {
    if (mileageData) return classes.filledCircle
    else return classes.circle
  }

  mileageCircleClick(mileageData, index, car) {
    if (mileageData) this.openEditModal(new Date(this.state.weekDate[index]), car.id, { ...mileageData })
    else this.openEditModal(new Date(this.state.weekDate[index]), car.id, {})
  }
  updateCarNameComponent(index, classes, car) {
    if (this.state.activeEdit == index)
      return <TextField size="small" autoFocus value={this.state.newCarName}
        onChange={(e) => this.limitCarNameLength(e)} className={classes.carNameTextField} />
    else
      return <Typography variant="h6" className={`${classes.carName} ${classes.font25}`}>{car.name}</Typography>
  }
  render() {
    const { classes } = this.props;
        return (
      <Grid container className={classes.mainDiv} >
                <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={2000}
        >
          <Alert severity="error"
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        {this.state.addCarModal &&
          <Dialog open={this.state.addCarModal} fullWidth onClose={() => this.handleCarModal()} >
            <DialogTitle>Add Car</DialogTitle>
            <DialogContent>
              <Grid container justifyContent="space-evenly" alignItems="center">
                <TextField
                  autoFocus
                  value={this.state.newCarName}
                  onChange={(e) => this.limitCarNameLength(e)}
                  margin="dense"
                  label="Car Name"
                  fullWidth
                />
                {this.state.errorMsg &&
                  <Typography variant="caption" color="error">{this.state.errorMsg}</Typography>}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleCarModal()} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.addCar()} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        }
        {this.state.deleteCarModal &&
          <Dialog open={this.state.deleteCarModal} fullWidth onClose={() => this.handleDeleteModal()} >
            <DialogTitle className={classes.justifyCenter}>Delete Car</DialogTitle>
            <DialogContent>
              <DialogContentText>
                On deletion of car, all the car mileage entries will get deleted, are you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.justifyCenter}>
              <Button className={classes.modalButtons} onClick={() => this.handleDeleteModal()} color="primary">
                No
              </Button>
              <Button className={classes.modalButtons} onClick={() => this.deleteCar(this.state.deleteCarNameId)} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>}
        {this.state.openModal &&
          <Dialog open={this.state.openModal} fullWidth onClose={() => this.handleModal()} >
            <DialogTitle>Edit Miles</DialogTitle>
            <DialogContent>
              <Grid container xs={10} item justifyContent="center" direction="column">
                <Grid item container className={classes.dialogFormField} >
                  <Grid item container xs={3}>
                    <Typography variant="body2" className={`${classes.font20} ${classes.boldFont}`}>Date</Typography>
                  </Grid>
                  <Grid item container xs={9}>
                    <DatePicker format="MM/dd/y"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      maxDate={new Date()}
                      value={this.state.formDate} onChange={(value) => this.updateFormDate(value)} className={`${classes.font18} ${classes.formDate}`} calendarIcon={null} clearIcon={null}></DatePicker>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" className={classes.dialogFormField}>
                  <Grid item container xs={3}>
                    <Typography variant="body2" className={`${classes.font20} ${classes.boldFont}`}>Vehicle</Typography>
                  </Grid>
                  <Grid item container xs={9}>

                    {this.addCarMessage(classes)
                    }
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" className={classes.dialogFormField}>
                  <Grid item container xs={3}>
                    <Typography variant="body2" className={`${classes.font20} ${classes.boldFont}`}>Miles</Typography>
                  </Grid>
                  <Grid item container xs={9} justifyContent="flex-end" className={classes.grayBox}>
                    <TextField type="number" size="small" value={this.state.formMiles}
                      autoFocus
                      onChange={(e) => this.setState({ formMiles: e.target.value })} placeholder="0"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <Typography className={classes.font18} >Miles</Typography>
                        ),
                      }} className={`${classes.font18} ${classes.formTextField}`} />
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" className={classes.dialogFormField}>
                  <Typography variant="subtitle2" className={`${classes.font20} ${classes.boldFont}`}>Business Purpose</Typography>
                  <TextareaAutosize inputProps={{ maxLength: 12 }} value={this.state.formText}
                    onChange={(e) => this.limitLength(e)}
                    className={classes.greyBox} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleModal()} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.updateMileageData()} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>}
        <Grid container item xs={9}>
          <Grid container item className={classes.topDiv} justifyContent="center" alignItems="center">
            <Grid container item justifyContent="space-evenly" alignItems="center" className={classes.formDiv}>
              <Grid container item xs={4} direction="column" className={classes.topSideDiv}>
                <Grid item container className={classes.formField} justifyContent="space-between" >
                  <Grid item container xs={3}>
                    <Typography variant="body2" className={`${classes.font20} ${classes.boldFont}`}>Date</Typography>
                  </Grid>
                  <Grid item container xs={8}>
                    <DatePicker format="MM/dd/y"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      maxDate={new Date()}
                      value={this.state.formDate} onChange={(value) => this.updateFormDate(value)} className={`${classes.font18} ${classes.formDate}`} calendarIcon={null} clearIcon={null}></DatePicker>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" justifyContent="space-between" className={classes.formField}>
                  <Grid item container xs={3}>
                    <Typography variant="body2" className={`${classes.font20} ${classes.boldFont}`}>Vehicle</Typography>
                  </Grid>
                  <Grid item container xs={8}>
                    {this.addCarMessage(classes)}
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" className={classes.formField} justifyContent="space-between">
                  <Grid item container xs={3}>
                    <Typography variant="body2" className={`${classes.font20} ${classes.boldFont}`}>Miles</Typography>
                  </Grid>
                  <Grid item container xs={8} justifyContent="flex-end" className={classes.grayBox}>
                    {this.state.errorMsg ? <Typography variant="caption" color="error">{this.state.errorMsg}</Typography>
                      : <TextField disabled={this.state.notAccountant} size="small" type="number" onChange={(e) => this.setState({ formMiles: e.target.value })}
                        value={this.state.formMiles} placeholder="0" autoFocus InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <Typography className={classes.font18} >Miles</Typography>
                          ),
                        }} className={`${classes.font18} ${classes.formTextField}`} />}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={4} direction="column" className={classes.topSideDiv} justifyContent="center" alignItems="center">
                <Typography variant="subtitle2" className={`${classes.font20} ${classes.boldFont}`}>Business Purpose</Typography>
                <TextareaAutosize value={this.state.formText} disabled={this.state.notAccountant}
                  onChange={(e) => this.limitLength(e)}
                  className={`${classes.greyBox} ${classes.font18}`} />
              </Grid>
            </Grid>
            <Grid container item xs={11} justifyContent="center" className={classes.middleDiv}>
              <Button
                variant="outlined"
                onClick={() => this.createMileageData()}
                className={classes.formBtn}
                disabled={this.state.notAccountant}
              >
                <Typography variant="body2" className={classes.font18}>SAVE</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container item justifyContent="center" alignItems="center" className={classes.bottomDiv}>
            <Grid container item justifyContent="space-around" xs={10}>
              {this.state.months.map((month, index) => {
                return (
                  <Box>
                    <Typography variant="subtitle2" className={`${classes.blueFont} ${classes.boldFont} ${classes.font20}`}>
                      {month}
                    </Typography>
                  </Box>
                )
              })}
            </Grid>
            <Grid className={classes.marginTopDiv} item container xs={12} justifyContent="center" alignItems="center">
              <Typography variant="caption" className={`${classes.boldFont} ${classes.font15}`}>WEEK RANGE</Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center">
              <Button onClick={() => this.updateWeek(this.state.selectedWeek.currentCount - 7, this.props.taxYear)} className={`${classes.boldFont} ${classes.blueFont}`}>{'<'}</Button>
              <Typography variant="subtitle1" className={`${classes.boldFont} ${classes.grayFont} ${classes.allCaps} ${classes.font25}`}>{`${this.state.selectedWeek.sunday.format('MMM D')} - ${this.state.selectedWeek.saturday.format('MMM D')}`}</Typography>
              <Button data-test-id="updateWeekPlus" onClick={() => this.updateWeek(this.state.selectedWeek.currentCount + 7, this.props.taxYear)} className={`${classes.boldFont} ${classes.blueFont}`}>{'>'}</Button>
            </Grid>
            <Grid item container >
              {this.state.cars.map((car, index) => {
                car = this.carMonthlyMiles(car)
                                return (
                  <Grid item container justifyContent="space-evenly" className={classes.carDetail} spacing={2}>
                    <Grid item container alignItems="center" justifyContent="center" xs={12}>
                      <Grid item container alignItems="center" xs={3} justifyContent="flex-end">
                        <Button variant="outlined" className={classes.spaceLR}>
                          <Typography variant="caption"
                            onClick={() => this.setState({ deleteCarNameId: car.id, deleteCarName: car.name, deleteCarModal: true })}>
                            Delete
                          </Typography>
                        </Button>
                        {this.replaceButton(this.state.activeEdit === parseInt(index), index, car)}
                      </Grid>
                      <Grid item container alignItems="center" xs={1} justifyContent="center">
                        <CarSVG className={classes.carSVG} />
                      </Grid>
                      <Grid item container alignItems="center" xs={3} justifyContent="flex-start">
                        {this.state.errorMsg ? <Typography variant="caption" color="error">{this.state.errorMsg}</Typography>
                          : this.updateCarNameComponent(index, classes, car)}
                      </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-evenly" alignItems="center" xs={11}>
                      {this.state.weekDays.length && this.state.weekDate.length && this.state.weekDays.map((day, index) => {
                                                let mileageData = car.data?.mileage_data?.find((el: any) => el.date_of_mileage === this.state.weekDate[index].format("MM/DD/YYYY"))
                                                                                                return (
                          <Grid item className={classes.dayDetails} >
                            <Typography variant="subtitle2" className={`${classes.boldFont} ${classes.allCaps} ${classes.font15}`}>{day}</Typography>
                            <Typography variant="caption" className={`${classes.grayFont} ${classes.font15}`}>{this.state.weekDate[index].format("MM/DD")}</Typography>
                            <Box className={this.mileageCircleClass(mileageData, classes)} onClick={() => this.mileageCircleClick(mileageData, index, car)}>
                              
                              {mileageData ?
                                <Typography variant="body1" className={`${classes.font20} ${classes.boldFont}`}>{mileageData.miles}</Typography>
                                : <></>}
                            </Box>
                          </Grid>)
                      })}
                    </Grid>
                    {index === this.state.mileageData.length - 1 &&
                      <Grid container justifyContent="center" alignItems="center" className={classes.addButton}>
                          <Button disabled={this.state.notAccountant} variant="outlined" onClick={() => this.handleCarModal()} >
                            <Typography className={classes.font18}>
                              + Add Car
                            </Typography>
                          </Button>
                      </Grid>
                    }
                  </Grid>
                )
              })
              }
            </Grid>
          </Grid>
          {!this.state.cars.length &&
            <Grid container justifyContent="center" alignItems="center">
              <Button disabled={this.state.notAccountant} variant="outlined" onClick={() => this.handleCarModal()} >
                <Typography className={classes.font18}>
                  + Add Car
                </Typography>
              </Button>
            </Grid>
          }
        </Grid>
        <Grid container item xs={3} direction="column" className={classes.sideDiv}>
          <Grid item container justifyContent="space-around" direction="column" alignItems="center" className={classes.totalMiles} >
            <Typography variant="subtitle1" className={`${classes.boldFont} ${classes.font20}`} >Total Monthly Miles</Typography>
            <Typography variant="h6" display="block" className={`${classes.yellowFont} ${classes.boldFont} ${classes.font30}`}>{this.state.totalMonthlyMiles} Miles</Typography>
          </Grid>
          <Grid item container justifyContent="space-around" alignItems="center" className={classes.totalMiles} >
            <Typography variant="subtitle1" className={`${classes.boldFont} ${classes.font20}`}>Year to Date Business Miles</Typography>
            <Typography variant="h6" className={`${classes.yellowFont} ${classes.boldFont} ${classes.font30}`}>{this.state.yearlyMiles} Miles</Typography>
          </Grid>
          <Grid container item className={classes.monthlyDetuction}>
            {this.state.months.map((month, index) => {
              let deduction = this.state.mileageDeductionMonths.find(el => el.month.includes(month))
              return (
                <Grid item container justifyContent="center" alignItems="center" xs={4}>
                  <Grid item container className={classes.monthsDate}>
                    <Typography className={`${classes.boldFont} ${classes.font15}`} variant="caption">{month.toUpperCase()}</Typography>
                  </Grid>
                  {this.showLeaf(index, classes, deduction)
                  }
                </Grid>
              )
            })}
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" direction="column">
            <Typography variant="body1" className={`${classes.boldFont} ${classes.font24}`} align="center">Total Calculated Mileage</Typography>
            <Typography variant="body1" className={`${classes.boldFont} ${classes.font24}`} align="center">Deduction</Typography>
            <Typography variant="h5" className={`${classes.yellowFont} ${classes.boldFont} ${classes.font30}`}>${Number(this.state.totalMileageDeduction).toFixed(2).toLocaleString(2)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(MileageWeb);
// Customizable Area End
// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
    withStyles,
    Checkbox,
    TextField,
    Typography,
    InputAdornment,
    Grid,
    Button,
    Box,
    Divider,
    Snackbar
} from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import { useLocation } from "react-router-dom";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import TopNav from "../../../components/src/TopNav";
import lock from "../assets/lock.png";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';
import Settings5Controller from "./VerifyAccountController.web"

const useStyles = (theme) => ({
    root: {
        paddingTop: "60px",
        // width: "100vw",
        // height: "100vh",
        // overflow: "hidden",
        // position: "relative",
    },
   
    pageTitle: {
        fontSize: "29px",
        fontWeight: "700",
        display: "inline-block",
        position: "relative",
        color: "#000000",
        paddingBottom: "3px",
        textAlign:"center",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "33%",
            height: "20px",
            bottom: "-3",
            left: "38%",
            // borderBottom: "4px solid #B5D8FD",
        },
        '@media (max-width:1425px)': {
            fontSize: "21.75px",
        },
    },
    pageDesText:{
        fontSize:18,
        fontWeight:400,
        color:"#3F4144",
        textAlign:"center",
        '@media (max-width:1425px)': {
            fontSize: "13.5px",
        },
    },
   
    form: {
        gap: "20px",
        zIndex: 1,
        margin:"0px 0px 50px 0px"
    },
    link:{
        color:"#5696F4",
        fontSize:16,
        fontWeight:400,
        underline:"none",
        padding:-30,
        margin:0,
        '@media (max-width:1425px)': {
            fontSize: "12px",
        },
      },
    textBtn: {
        padding: "0 5px",
        fontWeight: "bold",
        cursor: "pointer",
    },
    formBtn: {
        
        backgroundColor: "#5597F4",
        borderRadius: "30px",
        width: "178",
        "&:hover": {
            backgroundColor: "#5597F4",
        },
        "& p": {
            fontWeight: "bold",
            color: "#ffffff"
        },
    },
    checkBoxFont: {
        fontSize: "16px",
        '@media (max-width:1425px)': {
            fontSize: "12px",
        },
    },
    formBtnFont: {
        fontSize: "13px",
        fontWeight: "700",
        '@media (max-width:1425px)': {
            fontSize: "9.75px",
        },
    },
    checkBox: {
        padding: 5
    },
    checkBoxRoot: {
        padding: 0,
        color: "black"
    },
    input: {
        padingBottom:0,
        marginBottom:0,
        marginLeft: "10px",
        '&::placeholder': {
            //textOverflow: 'ellipsis !important',
            color: '#3f4144',
            opacity: 1,
            fontWeight: "700",
            fontFamily: "Open Sans",
            fontSize: "20px",
            '@media (max-width:1425px)': {
                fontSize: "15px",
            },

        }
    }

});
class ChangePasswordSettingsWeb extends Settings5Controller {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.handlePasswordChange}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.root}
                
            >
                <Grid
                    item
                    container
                    // direction="column"
                    xs={8} sm={8} md={7} lg={6}
                    alignItems="center"
                    justifyContent="center"
                    className={classes.form}
                >
                    <Grid item container xs={12} lg={12} md={12} sm={12}justifyContent="center">

                        <Typography variant="h5" className={classes.pageTitle} >
                            Change Password
                        </Typography>

                    </Grid>
                    <Grid item container xs={12} justifyContent="center">

                        <Typography variant="h5" className={classes.pageDesText} >
                        Please confirm your current password and                     
                         </Typography>
                         <Typography variant="h5" className={classes.pageDesText} >
                        then enter and re-enter a new password.                     
                         </Typography>

                    </Grid>
                    <Grid item container xs={12} style={{ marginTop: "20px" }}>
                        <TextField
                        required
                            size="small"
                            name="current password"
                            type="password"
                            helperText={ <Link to="/ForgotPassword" style={{textDecoration:"none"}}>
                            <Typography className={classes.link} variant="body2">
                                Forgot Password?
                            </Typography>
                        </Link>}
                            value={this.state.current_password}
                            onChange={this.handleUpdateChange("current_password")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                                        <img src={lock} height="15px" />
                                    </InputAdornment>
                                ),
                                classes: { input: classes.input }
                            }}
                            fullWidth
                            placeholder="Current Password"
                        />
                    </Grid>

                    <Grid item container xs={12} >
                    {this.state.triggerMethod ? <>{this.setState({ triggerMethod: false }), this.validatePassword()}</> : <></>}
                        <TextField
                        required
                            size="small"
                            name="new_password"
                            type="password"
                            value={this.state.new_password}
                            onChange={(e:any)=> this.setState({new_password: e.target.value, triggerMethod: true })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                                        <img src={lock} height="15px" />
                                    </InputAdornment>
                                ),
                                classes: { input: classes.input }
                            }}
                            fullWidth
                            placeholder="New Password"
                        />
                        
                    </Grid>

                    <Grid item container xs={12} style={{ marginTop: "20" }}>
                        <TextField
                        required
                            size="small"
                            name="reTypePassword"
                            type="password"
                           value={this.state.confirm_password}
                           onChange={this.handleUpdateChange("confirm_password")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                                        <img src={lock} height="15px" />
                                    </InputAdornment>
                                ),
                                classes: { input: classes.input }
                            }}
                            fullWidth
                            placeholder="Confirm New Password"
                        />
                    </Grid>

                    <Grid item container xs={12} style={{ display: "block" }}>
                        <Typography style={{ marginBottom: "6px", fontWeight: "600", color: "#3F4144" }} className={classes.checkBoxFont}>Password Guidelines</Typography>
                        <Box style={{ textAlign: "start" }}>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.eightCharracter ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} At least 8 characters-the more characters, the better</Typography>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.mixtureOfUpLow ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} A mixture of both upper and lowercase letters</Typography>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.mixtureOfLetterNumbers ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} A mixture of letters and numbers</Typography>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.oneSpecialCharacter ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} Inclusion of at least one special character e.g,! @ # ? ]</Typography>
                            <Typography style={{ margin: "0 0 35 23", fontWeight: "400", letterSpacing: "-1px", color: "#3F4144" }} className={classes.checkBoxFont}>Note: {`<`} and {`>`} are not accepted</Typography>
                        </Box>
                    </Grid>



                    <Grid item container xs={12} justifyContent="center">
                        <Button
                            variant="contained"
                            className={classes.formBtn}
                           type="submit"
                        >
                            <Typography variant="body2" className={classes.formBtnFont}>change Password</Typography>
                        </Button>

                    </Grid>

                </Grid>
                <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
             
            </Grid>
            </form>
        );
    }
}

export default withStyles(useStyles)(ChangePasswordSettingsWeb);
// Customizable Area End
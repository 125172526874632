//@ts-nocheck
import React from "react";
import { AppBar, Typography, Button, withStyles, Grid } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import Logo from "./Neeuh Logo_Blue.png"
const useStyles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#15066A",
    overflow: "hidden",
    position: "relative",
  },
  activeLink: {
    borderRadius: "20px",
    border: "2px solid #FFBB78"
  },
  link: {
    color: "#000",
    textDecoration: "none"
  },
  linkButton: {
    fontWeight: "bold",
    borderRadius: "20px",
    padding: "1px 15px",
    fontSize: "14px"
  },
  lBlue: {
    color: "#2a1d77",
    fontWeight: "300"
  },
  dBlue: {
    color: "#2a1d77",
  },
  logo: {
    width: "100px"
  },
  navBar: {
    boxShadow: "none",
    padding: "0 20px"
  }
});
// const TopNav = ({ history }) => {
class TopNav extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <AppBar position="static" color="white" className={classes.navBar}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            {/* <NavLink to="/">
              <img src={Logo} className={classes.logo}/>
            </NavLink> */}
          </Grid>
          <Grid item style={{ margin: "20px 0 18px 0" }}>
            {/* <NavLink to="/"  exact={true} activeClassName={classes.activeLink} className={classes.link}>
              <Typography variant="caption" className={`${classes.linkButton} ${classes.lBlue}`}>
                Learn More
              </Typography>
            </NavLink> */}
            <NavLink to="/EmailAccountRegistration" exact={true} activeClassName={classes.activeLink} className={classes.link}>
              <Typography variant="caption" className={`${classes.linkButton} ${classes.dBlue}`}>Log In</Typography>
            </NavLink>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}


export default withStyles(useStyles)(TopNav)

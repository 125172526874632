Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.saveFormDetailsApiEndPoint = "bx_block_report/save_to_forms_details"
exports.saveFormDataApiEndPoint = "bx_block_report/save_to_forms"
exports.notificationCountEndPoint = "bx_block_push_notifications/push_notifications/get_notification_count"
exports.notificationEndPoint = "bx_block_push_notifications/push_notifications"
exports.gamificationEndPoint = "bx_block_gamification/gamifications"
exports.getDetailsPDF = "/bx_block_report/expense_details_to_pdf";
exports.getDetailsCSV = "/bx_block_report/expense_details_to_csv";
exports.getPDF = "/bx_block_report/report_to_pdf";
exports.getCSV = "/bx_block_report/report_to_csv";
exports.getReport = "/bx_block_report/show_report";
exports.getReportDetails = "/bx_block_report/get_expense_details";
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.mileageApiEndPoint = "bx_block_mileage";
exports.transactionApiEndPoint = "bx_block_transactions";
exports.plaidAPIEndPonit = "bx_block_apiintegrationwithplaid/plaids"
exports.postApiMethodType = "POST"
exports.formContentType = 'multipart/form-data'
exports.putApiMethodType = "PUT"
exports.getApiMethodType = "GET"
exports.errorTitle = "Error",
  exports.errorCarNameEmpty = "Car Name can't be empty"
exports.ApiContentType = "application/json"
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.formApiEndPoint = "bx_block_forms";
exports.formDeleteMethodType = "DELETE";
exports.emailSame = "Email already used. Please try with a new Email.";
exports.taxApiEndPoint = "bx_block_taxes/taxes";
exports.taxSettingsApiEndpoint = "bx_block_taxes/tax_payment_settings";
exports.treasureData2getUrl = "bx_block_gamification/treasure_box";
exports.formApiW1EndPoint = "bx_block_forms/w1_forms"
exports.getTransactionCountApiEndPoint = "bx_block_transactions/get_transaction_count?tax_year=";
// Customizable Area End
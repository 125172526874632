//@ts-nocheck
// Customizable Area Start
import React from "react";
//import DashboardController from "./DashboardController";
import TaxesController from "./TaxesController.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import {
  InputAdornment,
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  TextField,
  Snackbar
} from "@material-ui/core";
import { Divider } from "react-native-elements";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import PercentIcon from "@material-ui/icons/Percent";
import Alert from '@material-ui/lab/Alert';
import thumbsup from "../assets/thumbs_up.png";


const useStyles = (theme) => ({
  fullDisplay: {
    width: "100%",
    height: "100%"
  },
  heading: {
    fontSize: "25px",
    fontWeight: "700",
    padding: "10px 0px 0px 0px",
    textAlign: "center",
    borderBottom: "1px solid",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "18.75px",
    },
  },
  contentSpacing: {
    margin: "30px 0px 30px 0px",
    '@media (max-width:1425px)': {
      margin: "35px 0px 35px 0px",
    },
  },
  buttonLayout: {
    backgroundColor: "#5597f4",
    borderRadius: "30px",
    color: "white",
    height: "25px",
    fontSize: "13px",
    fontWeight: "700",
    '&:hover': {
      backgroundColor: "#5597f4"
    },
    '@media (max-width:1425px)': {
      fontSize: "9.75px",
    },
  },
  estimatedPayment1: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },
  estimatedPaymentFontFifteen: {
    fontSize: "15px",
    '@media (max-width:1425px)': {
      fontSize: "11.25px",
    },
  },
  makePaymentFederalTax: {
    fontSize: "30px",
    fontWeight: "800",
    color: "#5696F4",
    '@media (max-width:1425px)': {
      fontSize: "22.5px",
    },
  },
  textFielsFonts: {
    textAlign: 'end',
    fontWeight: '700',
    fontSize: '20px',
    color: "#3F4144",
    padding: "0px",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },
  modalHeaderFont: {
    fontWeight: "400",
    fontSize: "25px",
    marginTop: "40px",
    '@media (max-width:1425px)': {
      fontSize: "18.75px",
    },
  },
  modalCheckboxFont: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },
  modalTaxValueFont: {
    fontWeight: "700",
    margin: "20 0",
    fontSize: '49px',
    '@media (max-width:1425px)': {
      fontSize: "36.75px",
    },
  },
  // textFielsFontsMakePayment: {
  //   textAlign: 'end',
  //   fontWeight: '700',
  //   fontSize: '20px',
  //   '@media (max-width:1425px)': {
  //     fontSize: "15px",
  //   },
  // },
  // spacingForContents:{
  //   margin: "30px 0px 30px 0px",
  //     '@media (max-width:1425px)': {
  //       margin: "35px 0px 35px 0px",
  //   },
  // },
  fixedPercentage1: {
    textAlign: "end",
    backgroundColor: "#ececec",
    padding: "4px 1px",
    borderRadius: "5px",
    fontWeight: "bold"
  },
  closeIcon: {
    position: "relative",
    left: "50%",
    top: "-70px"
  },
  DialogContentSM: {
    maxWidth: "660px",
  },
  // paddingDialog: {
  //   padding: "20px"
  // },
  modalContent: {
    fontSize: "0.9rem",
    fontWeight: "bold"
  },
  colorBlack: {
    color: "#3F4144"
  },
  displayNone: {
    display: 'none'
  },
  displayNormal: {
    display: ''
  },
  textOnly: {
    fontSize: "20px",
    fontWeight: "400",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },
  boldText: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  }

})

class TaxesWeb extends TaxesController {
  async componentDidMount() {
    this.props?.taxYear ? this.updateStatusTax(this.props?.taxYear) : this.updateStatusTax(this.state.currentYear)
    window.scrollTo(0, 0)
    this.populateStates()
    this.setState({ token: await StorageProvider.get('token') })
    this.callAllApis()
  }

  updateStatusTax(value: any) {
    this.setState({ currentYear: value, runApis: true })
  }

  checkValuesN(val: any) {
    if (val && !isNaN(val) && val !== -0) {
      return true
    } else {
      return false
    }
  }
  checkValuesN1(val: any) {
    if (val && !isNaN(val) && val !== -0) {
      return this.nf.format(val)
    } else {
      return "$0.00"
    }
  }

  render() {

    console.log(this.props);
    const { classes } = this.props;
    return (
      <div className="root" style={{ overflowX: "auto", }}>
        {/* <Typography variant="h5">Taxes</Typography> */}
        {/* <h1 className={classes.lol}>Hello</h1> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state?.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });

          }}
          autoHideDuration={2000}
        >
          <Alert severity="error"
            elevation={6} variant="filled"
          >{this.state?.snackbarMessage}
          </Alert>
        </Snackbar>
        {StorageProvider.get("taxYearChanged") ? <>{StorageProvider.set("taxYearChanged", false), console.log("ala", StorageProvider.get("taxYear"))}</> : <></>}
        <Dialog open={this.state.federalTaxPayment} fullWidth

          style={{ textAlign: "center" }}
        // classes={{
        //   paperWidthSm: classes.DialogContentSM
        // }}

        >

          <DialogTitle>
            {this.state.paymentConfirm ?
              <Typography className={classes.modalHeaderFont} > Payment Processed!</Typography>
              :
              <Typography className={classes.modalHeaderFont} ><AddCircleOutlineIcon style={{ marginBottom: "-5px" }} /> Federal Tax Payment</Typography>
            }

            <CloseIcon onClick={() => { this.closeModal() }} className={classes.closeIcon} />
          </DialogTitle>
          {this.state.paymentConfirm ?
            <DialogContent>

              <Box style={{ width: "40%", height: "140px", margin: "auto", textAlign: "center", marginTop: "-30px" }}>
                <img src={thumbsup} alt="" style={{ height: "100%" }} />
              </Box>
              <Typography className={classes.boldText}>A tax payment of</Typography>
              <Typography className={classes.modalTaxValueFont} style={{ margin: "20 0" }}>$0.00</Typography>

              <Typography className={classes.boldText}>has been processed from the account *0001 through EFTPS</Typography>
              <Box style={{ textAlign: "center", backgroundColor: "#f3f3f3", padding: "10", margin: "20 0 30 0" }}>
                <Typography className={classes.textOnly}>Your payment number is #123456. A confirmation of your payment has been sent to demo@demo.com</Typography>
                {/* <Typography className={classes.textOnly}>has been sent to demo@demo.com</Typography> */}
              </Box>

            </DialogContent>
            :
            <DialogContent>
              <Typography className={classes.boldText} >You are making a federal tax payment of</Typography>
              <Box style={{ width: "65%", height: "40px", backgroundColor: "#ececec", margin: "5px auto 30px auto", textAlign: "end" }}>
                <Typography className={classes.modalHeaderFont}>$0.00</Typography>
              </Box>

              <Typography className={classes.boldText}>Please accept these terms before confirming your payment</Typography>
              <Box style={{ textAlign: "start" }}>
                <Typography className={classes.modalCheckboxFont}><Checkbox color="primary" onChange={(e) => { this.handleCheckBox(e) }} value={"time"} /> I understand that this payment will take 2 business days to process</Typography>
                <Typography className={classes.modalCheckboxFont}><Checkbox color="primary" onChange={(e) => { this.handleCheckBox(e) }} value={"duration"} /> I understand that this payment date will be logged as {this.state.todayDate}</Typography>
                <Typography className={classes.modalCheckboxFont}><Checkbox color="primary" onChange={(e) => { this.handleCheckBox(e) }} value={"delivary"} /> I understand that this payment will processed in the 4th Quarter</Typography>
              </Box>
              {this.state.checkValidation ? <Typography style={{ color: "red", fontSize: "0.9rem", margin: "5px 0" }}>Please select all boxes to continue</Typography> : <></>}
              <Button className={classes.buttonLayout} style={{ margin: "30 0" }} onClick={() => {
                { this.state.checkedArray.length === 3 ? this.setState({ paymentConfirm: true, checkedArray: [] }) : this.setState({ paymentConfirm: false }) }
                //this.setState({ paymentConfirm: true })
              }}>Confirm Payment</Button>
            </DialogContent>
          }

        </Dialog>
        <Grid container direction="column" className={classes.fullDisplay} style={{ flexWrap: "nowrap", height: "auto" }}>
          <Grid item xs={9} className={classes.fullDisplay} style={{ maxWidth: "100%" }}>
            <Grid container direction="row" className={classes.fullDisplay}>
              <Grid item xs className={classes.fullDisplay} style={{ borderRight: "1px solid", height: "auto" }}>
                <Typography className={classes.heading} >Tables</Typography>
                <Box style={{ padding: "0 20px" }}>
                  {this.state?.tablesBeforeLine.map((data, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" className={classes.contentSpacing}>
                          <Typography className={classes.textOnly}>{data?.name}</Typography>
                          <Grid item xs> <Typography className={classes.textOnly} style={{ textAlign: "end" }}>{this.checkValuesN1(data.value)}</Typography></Grid>

                        </Grid>
                      </>
                    )
                  })}
                  <Box style={{ margin: "0 10px" }}>
                    <Divider varient="middle" style={{ backgroundColor: "#a8a8aa" }} />
                  </Box>

                  {this.state?.tablesAfterLine.map((data, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" style={{ fontWeight: "bold" }} className={classes.contentSpacing}>
                          <Typography className={classes.boldText}>{data?.name}</Typography>
                          <Grid item xs ><Typography className={classes.boldText} style={{ textAlign: "end" }}>{this.checkValuesN1(data.value)}</Typography></Grid>
                        </Grid>

                      </>
                    )
                  })}
                </Box>
              </Grid>
              {this.props?.updatedTaxYear ? this.updateStatusTax(this.props?.taxYear) :

                <></>
              }
              {this.state.runApis ? <>{this.callAllApis(), this.setState({ runApis: false })}</> : <></>}
              <Grid item xs className={`${classes.fullDisplay} ${this.state.fixedPercentageSettings & localStorage.getItem('role') !== "SUPER_ADMIN" ? classes.displayNormal : classes.displayNone}`} style={{ borderRight: "1px solid", height: "auto" }}>
                <Typography className={classes.heading}>Fixed Percentage</Typography>
                <Box style={{ padding: "0 20px" }}>
                  <Grid container direction="row" justifyContent="space-around"
                    alignItems="center" className={classes.contentSpacing} >
                    <Grid item xs><Typography className={classes.textOnly}>Fixed Percentage</Typography></Grid>
                    <Grid item xs style={{ textAlign: "end", backgroundColor: "#ececec", padding: "4px 1px", borderRadius: "5px", fontWeight: "bold" }}>
                      <TextField inputProps={{ className: classes.textFielsFonts }} value={this.state.fixedPercentage} InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" >

                            <Typography style={{ color: "#3F4144", fontWeight: "700", marginLeft: "-5" }}>%</Typography>



                          </InputAdornment>
                        ), disableUnderline: true, style: { textAlign: 'end' }
                      }} onChange={(e) => {
                        this.setState({ fixedPercentage: e.target.value })
                      }}

                        onBlur={() => { this.fixedPercentageUpdate() }}
                        onKeyDown={(e) => { e.key === 'Enter' ? this.fixedPercentageUpdate() : <></> }}

                      />
                    </Grid>

                  </Grid>
                  {this.state?.fixedBeforeLine.map((data, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" className={classes.contentSpacing}>
                          <Typography className={classes.textOnly}>{data?.name}</Typography>
                          <Grid item xs style={{ textAlign: "end" }}><Typography className={classes.textOnly}>{this.checkValuesN1(data.value)}</Typography></Grid>
                          {/* <Grid item xs style={{ textAlign: "end" }}>${data?.value}</Grid> */}
                        </Grid>
                      </>
                    )
                  })}
                  <Box style={{ margin: "0 10px" }}>
                    <Divider varient="middle" style={{ backgroundColor: "#a8a8aa" }} />
                  </Box>
                  {this.state?.fixedAfterLine.map((data1, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" style={{ fontWeight: "bold" }} className={classes.contentSpacing}>
                          <Typography className={classes.boldText}>{data1?.name}</Typography>
                          <Grid item xs style={{ textAlign: "end" }}><Typography className={classes.boldText}>{this.checkValuesN1(data1.value)}</Typography></Grid>
                        </Grid>
                      </>
                    )
                  })}
                </Box>
              </Grid>
              <Grid item xs className={`${classes.fullDisplay} ${this.state.estimatedPaymentsSettings & localStorage.getItem('role') !== "SUPER_ADMIN" ? classes.displayNormal : classes.displayNone}`} style={{ borderRight: "1px solid", height: "auto" }}>
                <Typography className={classes.heading}>Estimated Payments</Typography>
                <Box style={{ padding: "5 20px" }}>
                  <Grid container direction="column" style={{ textAlign: "center" }}>
                    <Grid item xs>
                      <Typography className={classes.estimatedPayment1} style={{ padding: "10px 0 5px 0" }}>Prior Year Tax Liability</Typography>
                      {/* <Box style={{ width: "80%", textAlign: "end" }}> */}

                      <Grid container direction="row" >
                        <Grid item sm={12} md={5} style={{ textAlign: "center", paddingLeft: 40 }}>
                          <Button className={classes.buttonLayout} onClick={() => { this.resetEstimatedPayment() }} style={{ height: "28px", width: "87px" }}>Reset</Button>
                        </Grid>
                        <Grid item sm={12} md={6} style={{ textAlign: "end", backgroundColor: "#ececec", padding: "0px 1px", borderRadius: "5px" }}>
                          <TextField inputProps={{ className: classes.textFielsFonts }} value={`$${this.state.taxLiability}`} InputProps={{ disableUnderline: true, style: { textAlign: 'end' } }} onChange={(e) => {
                            this.setState({ taxLiability: e.target.value.substring(1) })

                            //, () => { setTimeout(this.estimatedPaymentUpdate(), 5000) }
                            //, setTimeout(() => { this.setState({ trigger: true }), 1500 })
                          }}
                            onBlur={() => { this.estimatedPaymentUpdate() }}
                            onKeyDown={(e) => { e.key === 'Enter' ? this.estimatedPaymentUpdate() : <></> }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs={5} style={{ paddingLeft: 20 }}>
                          <Typography className={classes.estimatedPayment1}>Safe Harbor</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "end" }}>
                          <Typography className={classes.estimatedPayment1}>{this.checkValuesN1(this.state.safeHarbour)}</Typography>
                        </Grid>
                      </Grid>
                      {/* </Box> */}
                      <Typography className={classes.estimatedPaymentFontFifteen}>Prior year Tax Liability x 110% = Safe Harbor</Typography>
                      <Typography className={classes.estimatedPaymentFontFifteen} style={{ marginBottom: "-20px" }}>Safe Harbor/4 = Quarterly Payment</Typography>
                    </Grid>
                    <br />
                    <Box style={{ margin: "0 10px" }}>
                      <Divider varient="middle" style={{ backgroundColor: "#a8a8aa" }} />
                    </Box>

                    {this.state.estimatedBeforeLine.map((data, index) => {
                      return (
                        <Grid container direction="column" style={{ margin: "5px 0" }}>
                          <Grid item ><Typography className={classes.estimatedPaymentFontFifteen}>Due {data?.date}</Typography></Grid>
                          <Grid item ><Typography className={classes.estimatedPayment1} style={{ margin: "3px 0" }}>{data?.quarter}</Typography></Grid>
                          <Grid item xs style={{ backgroundColor: "#ececec", padding: "4px 1px", borderRadius: "5px", fontWeight: "bold", width: "65%", margin: "auto" }}>
                            {index === 0 ? <><TextField inputProps={{ className: classes.textFielsFonts }} value={`$${this.state.quaterlyPayment1}`} InputProps={{ disableUnderline: true, style: { textAlign: 'end' } }} onChange={(e) => {
                              this.setState({ quaterlyPayment1: e.target.value.substring(1) })

                            }}
                              onBlur={() => { this.quaterlyPaymentUpdate(0) }}
                              onKeyDown={(e) => { e.key === 'Enter' ? this.quaterlyPaymentUpdate(0) : <></> }}
                            /></> : <></>}
                            {index === 1 ? <><TextField inputProps={{ className: classes.textFielsFonts }} value={`$${this.state.quaterlyPayment2}`} InputProps={{ disableUnderline: true, style: { textAlign: 'end' } }} onChange={(e) => {
                              this.setState({ quaterlyPayment2: e.target.value.substring(1) })

                            }}
                              onBlur={() => { this.quaterlyPaymentUpdate(1) }}
                              onKeyDown={(e) => { e.key === 'Enter' ? this.quaterlyPaymentUpdate(1) : <></> }}
                            /></> : <></>}
                            {index === 2 ? <><TextField inputProps={{ className: classes.textFielsFonts }} value={`$${this.state.quaterlyPayment3}`} InputProps={{ disableUnderline: true, style: { textAlign: 'end' } }} onChange={(e) => {
                              this.setState({ quaterlyPayment3: e.target.value.substring(1) })

                            }}
                              onBlur={() => { this.quaterlyPaymentUpdate(2) }}
                              onKeyDown={(e) => { e.key === 'Enter' ? this.quaterlyPaymentUpdate(2) : <></> }}
                            /></> : <></>}
                            {index === 3 ? <><TextField inputProps={{ className: classes.textFielsFonts }} value={`$${this.state.quaterlyPayment4}`} InputProps={{ disableUnderline: true, style: { textAlign: 'end' } }} onChange={(e) => {
                              this.setState({ quaterlyPayment4: e.target.value.substring(1) })

                            }}
                              onBlur={() => { this.quaterlyPaymentUpdate(3) }}
                              onKeyDown={(e) => { e.key === 'Enter' ? this.quaterlyPaymentUpdate(3) : <></> }}
                            /></> : <></>}
                          </Grid>
                        </Grid>
                      )
                    })}
                    <Box style={{ margin: "0 10px" }}>
                      <Divider varient="middle" style={{ backgroundColor: "#a8a8aa" }} />
                    </Box>
                    {this.state?.estimatedAfterLine.map((data, index) => {
                      return (
                        <>
                          <Grid container direction="row" justifyContent="space-around"
                            alignItems="center" style={{ fontWeight: "bold", padding: "5px 0" }}>
                            <Typography className={classes.boldText}>{data?.name}</Typography>
                            <Grid item xs style={{ textAlign: "end" }}><Typography className={classes.boldText}>{this.checkValuesN1(data.value)}</Typography></Grid>
                          </Grid>
                        </>
                      )
                    })}

                  </Grid>
                </Box>

              </Grid>
              <Grid item xs className={`${classes.fullDisplay} ${this.state.stateSettings & this.checkStatus() & localStorage.getItem('role') !== "SUPER_ADMIN" ? classes.displayNormal : classes.displayNone}`} style={{ borderRight: "1px solid", height: "auto" }}>
                <Typography className={classes.heading}>State</Typography>
                <Box style={{ padding: "0px 20px" }}>
                  <Grid container direction="row" justifyContent="space-around"
                    alignItems="center" className={classes.contentSpacing}>
                    <Grid item xs><Typography className={classes.textOnly}>Fixed Percentage</Typography></Grid>
                    <Grid item xs className={classes.fixedPercentage1}>
                      <TextField inputProps={{ className: classes.textFielsFonts }} value={this.state.stateFixedPercentage} InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" >

                            <Typography style={{ color: "#3F4144", fontWeight: "700", marginLeft: "-5" }}>%</Typography>
                          </InputAdornment>
                        ), disableUnderline: true, style: { textAlign: 'end' }
                      }} onChange={(e) => {
                        this.setState({ stateFixedPercentage: e.target.value })
                      }}
                        onBlur={() => { this.stateTaxUpdate() }}
                        onKeyDown={(e) => { e.key === 'Enter' ? this.stateTaxUpdate() : <></> }}
                      />
                    </Grid>
                  </Grid>
                  {this.state?.stateBeforeLine.map((data, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" className={classes.contentSpacing}>
                          <Grid item xs ><Typography className={classes.textOnly}>{data?.name}</Typography></Grid>
                          <Grid item xs style={{ textAlign: "end" }}><Typography className={classes.textOnly}>{this.checkValuesN1(data.value)}</Typography></Grid>
                        </Grid>
                      </>
                    )
                  })}
                  <Box style={{ margin: "0 10px" }}>
                    <Divider varient="middle" style={{ backgroundColor: "#a8a8aa" }} />
                  </Box>
                  {this.state?.stateAfterLine.map((data, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" style={{ fontWeight: "bold" }} className={classes.contentSpacing}>
                          <Typography className={classes.boldText}>{data?.name}</Typography>
                          <Grid item xs style={{ textAlign: "end" }}><Typography className={classes.boldText}>{this.checkValuesN1(data.value)}</Typography></Grid>
                        </Grid>
                      </>
                    )
                  })}
                </Box>
              </Grid>


            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.fullDisplay} style={{ maxWidth: "100%" }}>
            <Grid container direction="row" className={classes.fullDisplay}>
              <Grid item xs={4} className={`${classes.fullDisplay} ${this.state.fixedPercentageSettings & this.state.estimatedPaymentsSettings & this.state.stateSettings & localStorage.getItem('role') !== "SUPER_ADMIN" ? classes.displayNormal : classes.displayNone}`} style={{ borderRight: "1px solid" }} >
                <Typography className={classes.heading} style={{ borderTop: "1px solid" }}>State</Typography>
                <Box style={{ padding: "5 20px" }}>
                  <Grid container direction="row" justifyContent="space-around"
                    alignItems="center" >
                    <Grid item xs><Typography className={classes.textOnly}>Fixed Percentage</Typography></Grid>
                    <Grid item xs style={{ textAlign: "end", backgroundColor: "#ececec", padding: "4px 1px", borderRadius: "5px", fontWeight: "bold" }}>
                      {/* %{this.state.stateFixedPercentage} */}
                      <TextField inputProps={{ className: classes.textFielsFonts }} value={this.state.stateFixedPercentage} InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" >

                            <Typography style={{ color: "#3F4144", fontWeight: "700", marginLeft: "-5" }}>%</Typography>



                          </InputAdornment>
                        ), disableUnderline: true, style: { textAlign: 'end' }
                      }} onChange={(e) => {
                        this.setState({ stateFixedPercentage: e.target.value })
                        //, setTimeout(() => { this.setState({ trigger: true }), 1500 })
                      }}
                        onBlur={() => { this.stateTaxUpdate() }}
                        onKeyDown={(e) => { e.key === 'Enter' ? this.stateTaxUpdate() : <></> }}
                      />
                    </Grid>
                    {/* <Grid item xs style={{ textAlign: "end" }}>${data?.value}</Grid> */}
                  </Grid>
                  {this.state?.stateBeforeLine.map((data, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" >
                          <Typography className={classes.textOnly}>{data?.name}</Typography>
                          <Grid item xs style={{ textAlign: "end" }} className={classes.textOnly}><Typography>{this.checkValuesN1(data.value)}</Typography></Grid>
                          {/* <Grid item xs style={{ textAlign: "end" }}>${data?.value}</Grid> */}
                        </Grid>
                      </>
                    )
                  })}
                  <Box style={{ margin: "0 10px" }}>
                    <Divider varient="middle" style={{ backgroundColor: "#a8a8aa" }} />
                  </Box>
                  {this.state?.stateAfterLine.map((data, index) => {
                    return (
                      <>
                        <Grid container direction="row" justifyContent="space-around"
                          alignItems="center" style={{ fontWeight: "bold" }}>
                          <Typography className={classes.boldText}>{data?.name}</Typography>
                          <Grid item xs style={{ textAlign: "end" }}><Typography className={classes.boldText}>{this.checkValuesN1(data.value)}</Typography></Grid>
                        </Grid>
                      </>
                    )
                  })}
                </Box>
              </Grid>
              <Grid item xs className={classes.fullDisplay} >
                <Typography className={classes.heading} style={{ borderTop: "1px solid" }}>Make Payment</Typography>

                <Grid container direction="row" style={{ padding: "20px", textAlign: "center" }}>
                  <Grid item xs>
                    <Typography className={classes.boldText}>Total Federal Taxes Paid</Typography>
                    <Typography className={classes.makePaymentFederalTax} style={{}}>${this.state.totalFederalTaxesPaid !== -0 ? this.state.totalFederalTaxesPaid : '0.00'}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Box style={{ height: "100%", width: "2px", backgroundColor: "#b5b5b7", margin: "auto" }}></Box>
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.boldText}>FEDERAL TAX PAYMENT</Typography>
                    <Typography className={classes.estimatedPaymentFontFifteen} style={{ marginBottom: "15px" }}>DO NOT INCLUDE STATE TAXES</Typography>
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item xs={6} style={{ textAlign: "end", backgroundColor: "#ececec", padding: "4px 1px", borderRadius: "5px" }}>
                        <Typography className={classes.boldText}>$0.00</Typography>
                      </Grid>
                      <Grid item xs={5} style={{ margin: "auto 0" }}>
                        <Button className={classes.buttonLayout} onClick={() => {
                          this.setState({ federalTaxPayment: true })
                        }} style={{ height: "28px", width: "145px" }}>make payment</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {this.state.trigger ? <>{this.setState({ trigger: false }), this.stateTaxUpdate(), this.estimatedPaymentUpdate(), this.fixedPercentageUpdate()}</> : <></>}
            </Grid>

          </Grid>

        </Grid>
      </div >
    );
  }
}
// Customizable Area End
export default withStyles(useStyles)(TaxesWeb);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
//import { useHistory } from "react-router-dom"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    password: string;
    otpAuthToken: string;
    reTypePassword: string;
    data: any[];
    passwordHelperText: string;
    enablePasswordField: boolean;
    enableReTypePasswordField: boolean;
    countryCodeSelected: string;
    phone: string;
    eightCharracter: boolean;
    mixtureOfUpLow: boolean;
    mixtureOfLetterNumbers: boolean;
    oneSpecialCharacter: boolean;
    matchedPassword: boolean;
    clearToTrigger: boolean;
    triggerMethod: boolean;
    triggerMethodR: boolean;
    resetPasswordSuccessful: boolean;
    notSuccessful: boolean;
    redirectLink: boolean;
    signUpEmail: any;
    openDashboardLink: boolean;
    successMessage: boolean;
    errorMessage: boolean;
    successFormApi: any;
    errorFromApi: any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    arrayholder: any[];
    passwordReg: RegExp;
    emailReg: RegExp;
    createAccountApiCallId: any;
    loginApiCallId: any;
    validationApiCallId: string = "";

    imgPasswordVisible: any;
    imgPasswordInVisible: any;

    labelHeader: any;
    labelFirstName: string;
    lastName: string;
    labelEmail: string;
    labelPassword: string;
    labelRePassword: string;
    labelLegalText: string;
    labelLegalTermCondition: string;
    labelLegalPrivacyPolicy: string;
    btnTextSignUp: string;
    updatePasswordID: any;
    signUpSendEmailId: any;

    currentCountryCode: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            firstName: "",
            userName: "",
            lastName: "",
            email: "",
            password: "",
            reTypePassword: "",
            otpAuthToken: "",
            data: [],
            passwordHelperText: "",
            enablePasswordField: true,
            enableReTypePasswordField: true,
            countryCodeSelected: "91",
            phone: "",
            eightCharracter: false,
            mixtureOfLetterNumbers: false,
            mixtureOfUpLow: false,
            oneSpecialCharacter: false,
            matchedPassword: false,
            clearToTrigger: false,
            triggerMethod: false,
            triggerMethodR: false,
            resetPasswordSuccessful: false,
            notSuccessful: false,
            redirectLink: false,
            signUpEmail: '',
            openDashboardLink: false,
            successMessage: false,
            errorMessage: false,
            successFormApi: '',
            errorFromApi: 'any',

            // Customizable Area End
        };

        // Customizable Area Start
        this.arrayholder = [];
        this.passwordReg = new RegExp("\\w+");
        this.emailReg = new RegExp("\\w+");

        this.imgPasswordVisible = imgPasswordVisible;
        this.imgPasswordInVisible = imgPasswordInVisible;

        this.labelHeader = configJSON.labelHeader;
        this.labelFirstName = configJSON.labelFirstName;
        this.lastName = configJSON.lastName;
        this.labelEmail = configJSON.labelEmail;
        this.labelPassword = configJSON.labelPassword;
        this.labelRePassword = configJSON.labelRePassword;
        this.labelLegalText = configJSON.labelLegalText;
        this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
        this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
        this.btnTextSignUp = configJSON.btnTextSignUp;
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        //const history = useHistory();
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            //console.log("buriiiiiiii", responseJson.meta.token);
            localStorage.setItem("token", responseJson?.meta?.token);
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.validationApiCallId) {
                    this.arrayholder = responseJson?.data;

                    if (this.arrayholder && this.arrayholder?.length !== 0) {
                        let regexData = this.arrayholder[0];

                        if (regexData.password_validation_regexp) {
                            this.passwordReg = new RegExp(
                                regexData.password_validation_regexp
                            );
                        }

                        if (regexData.password_validation_rules) {
                            this.setState({
                                passwordHelperText: regexData.password_validation_rules,
                            });
                        }

                        if (regexData.email_validation_regexp) {
                            this.emailReg = new RegExp(regexData.email_validation_regexp);
                        }
                    }
                } else if (apiRequestCallId === this.createAccountApiCallId) {
                    if (!responseJson.errors) {
                        const msg: Message = new Message(
                            getName(MessageEnum.AccoutResgistrationSuccess)
                        );
                        msg.addData(
                            getName(MessageEnum.NavigationPropsMessage),
                            this.props
                        );

                        this.send(msg);
                    } else {
                        //Check Error Response
                        console.log("response --- error", responseJson?.errors);
                        if (responseJson?.errors) {
                            if (responseJson?.errors[0]?.account === "Email invalid") {
                                this.showAlert("Error", "Email Already Used. Please try with a new Email")
                            }

                            else {
                                let temp: any = "";
                                if (responseJson?.errors[0]?.full_phone_number) {
                                    temp = temp + responseJson?.errors[0]?.full_phone_number
                                }
                                if (responseJson?.errors[0]?.user_name) {
                                    temp = temp + responseJson?.errors[0]?.user_name
                                }
                                if (responseJson?.errors[0]?.message) {
                                    temp = temp + responseJson?.errors[0]?.message
                                }

                                if (responseJson?.errors[1]?.full_phone_number) {
                                    temp = temp + responseJson?.errors[1]?.full_phone_number
                                }
                                if (responseJson?.errors[1]?.user_name) {
                                    temp = temp + responseJson?.errors[1]?.user_name
                                }


                                this.showAlert("Error", temp)


                            }
                            //temp = temp + 
                            {
                                responseJson?.errors.map((data: any) => {
                                    //console.log("haluuuuum", data)
                                })

                            }

                        }
                        //this.parseApiErrorResponse(responseJson);
                    }

                    this.parseApiCatchErrorResponse(errorReponse);
                } else if (apiRequestCallId === this.loginApiCallId) {
                    if (!responseJson.errors) {
                        const msg: Message = new Message(
                            getName(MessageEnum.AccoutLoginSuccess)
                        );

                        await StorageProvider.set("token", responseJson.meta.token)
                        await StorageProvider.set("role", responseJson?.account?.data?.attributes?.role)
                        await StorageProvider.set("manage_account", responseJson?.account?.data?.attributes?.manage_account)
                        await StorageProvider.set("manage_company", responseJson?.account?.data?.attributes?.manage_company)
                        await StorageProvider.set("manage_subscription", responseJson?.account?.data?.attributes?.manage_subscription)
                        msg.addData(
                            getName(MessageEnum.NavigationPropsMessage),
                            this.props
                        );
                        console.log(msg)
                        this.send(msg);
                    } else {
                        //Check Error Response
                        this.parseApiErrorResponse(responseJson);
                    }

                    this.parseApiCatchErrorResponse(errorReponse);
                } else if (apiRequestCallId === this.updatePasswordID) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            console.log(responseJson.meta)
                            this.setState({ resetPasswordSuccessful: true, notSuccessful: false })
                        }
                    } else {
                        //console.log(responseJson.errors.message)
                        //history.push('/EmailAccountRegistration')
                        //location.assign(""),
                        this.setState({ resetPasswordSuccessful: false, notSuccessful: true })


                    }
                } else if (apiRequestCallId === this.signUpSendEmailId) {
                    if (!responseJson.errors) {
                        if (responseJson?.success) {
                            this.setState({ successFormApi: responseJson?.success?.message })
                            this.setState({ successMessage: true })
                        }
                    } else {
                        this.setState({ errorFromApi: responseJson?.errors?.message })
                        this.setState({ errorMessage: true })

                    }
                }
            }
        }

        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const otpAuthTkn = message.getData(
                getName(MessageEnum.AuthTokenDataMessage)
            );
            if (otpAuthTkn && otpAuthTkn.length > 0) {
                this.setState({ otpAuthToken: otpAuthTkn });
                runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
                runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
            }
        }

        if (getName(MessageEnum.CountryCodeMessage) === message.id) {
            var selectedCode = message.getData(
                getName(MessageEnum.CountyCodeDataMessage)
            );

            if (selectedCode !== undefined) {
                this.setState({
                    countryCodeSelected:
                        selectedCode.indexOf("+") > 0
                            ? selectedCode.split("+")[1]
                            : selectedCode,
                });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    goToPrivacyPolicy() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationPrivacyPolicyMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    goToTermsAndCondition() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationTermAndConditionMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    isStringNullOrBlank(str: string) {
        return str === null || str.length === 0;
    }

    isValidEmail(email: string) {
        return this.emailReg.test(email);
    }

    createAccount(): boolean {
        if (
            this.isStringNullOrBlank(this.state.userName) ||
            this.isStringNullOrBlank(this.state.email) ||
            this.isStringNullOrBlank(this.state.password) ||
            this.isStringNullOrBlank(this.state.reTypePassword)
        ) {
            this.showAlert(
                configJSON.errorTitle,
                configJSON.errorAllFieldsAreMandatory
            );
            return false;
        }

        var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
            this.state.countryCodeSelected,
            this.state.phone
        );

        if (phoneNumberError) {
            this.showAlert(configJSON.errorTitle, phoneNumberError);
            return false;
        }

        if (!this.isValidEmail(this.state.email)) {
            this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
            return false;
        }

        if (!this.passwordReg.test(this.state.password)) {
            this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
            return false;
        }

        if (this.state.password !== this.state.reTypePassword) {
            this.showAlert(
                configJSON.errorTitle,
                configJSON.errorBothPasswordsNotSame
            );
            return false;
        }

        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
        };

        const attrs = {
            first_name: this.state.firstName,
            user_name: this.state.userName,
            email: this.state.email,
            password: this.state.password,
            full_phone_number:
                "+" + this.state.countryCodeSelected + this.state.phone,
        };

        const data = {
            type: "email_account",
            attributes: attrs,
        };

        const httpBody = {
            data: data,
            token: this.state.otpAuthToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createAccountApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.accountsAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    login(): boolean {
        if (
            this.isStringNullOrBlank(this.state.userName) ||
            this.isStringNullOrBlank(this.state.password)
        ) {
            this.showAlert(
                configJSON.errorTitle,
                configJSON.errorAllFieldsAreMandatory
            );

            return false;
        }

        if (!this.passwordReg.test(this.state.password)) {
            this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
            return false;
        }

        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
        };

        const attrs = {
            user_name: this.state.userName,
            password: this.state.password,
            role_id: "admin",
        };

        const data = {
            type: "email_account",
            attributes: attrs,
        };

        const httpBody = {
            data: data,
            token: this.state.otpAuthToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.loginApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.loginAPIEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    getValidations() {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.validationApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.urlGetValidations
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    isNonNullAndEmpty(value: String) {
        console.log(value);
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 0
        );
    }

    validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
        let error = null;

        if (this.isNonNullAndEmpty(phoneNumber)) {
            if (!this.isNonNullAndEmpty(String(countryCode))) {
                error = configJSON.errorCountryCodeNotSelected;
            }
        } else if (this.isNonNullAndEmpty(countryCode)) {
            if (!this.isNonNullAndEmpty(phoneNumber)) {
                error = "Phone " + configJSON.errorBlankField;
            }
        }

        return error;
    }

    imgEnableRePasswordFieldProps = {
        source: imgPasswordVisible,
    };

    btnConfirmPasswordShowHideProps = {
        onPress: () => {
            this.setState({
                enableReTypePasswordField: !this.state.enableReTypePasswordField,
            });
            this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
                .enableReTypePasswordField;
            this.imgEnableRePasswordFieldProps.source = this
                .txtInputConfirmPasswordProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        },
    };

    imgEnablePasswordFieldProps = {
        source: imgPasswordVisible,
    };

    btnPasswordShowHideProps = {
        onPress: () => {
            this.setState({ enablePasswordField: !this.state.enablePasswordField });
            this.txtInputPasswordProps.secureTextEntry = !this.state
                .enablePasswordField;
            this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
                .secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        },
    };

    btnSignUpProps = {
        onPress: () => this.createAccount(),
    };

    btnLegalPrivacyPolicyProps = {
        onPress: () => this.goToPrivacyPolicy(),
    };

    btnLegalTermsAndConditionProps = {
        onPress: () => this.goToTermsAndCondition(),
    };

    txtInputEmailWebPrpos = {
        onChangeText: (text: string) => {
            this.setState({ email: text });
            //@ts-ignore
            this.txtInputEmailPrpos.value = text;
        },
    };

    txtInputEmailMobilePrpos = {
        ...this.txtInputEmailWebPrpos,
        keyboardType: "email-address",
    };

    txtInputEmailPrpos = this.isPlatformWeb()
        ? this.txtInputEmailWebPrpos
        : this.txtInputEmailMobilePrpos;

    txtPhoneNumberWebProps = {
        onChangeText: (text: string) => {
            this.setState({ phone: text });

            //@ts-ignore
            this.txtPhoneNumberProps.value = text;
        },
    };

    txtPhoneNumberMobileProps = {
        ...this.txtPhoneNumberWebProps,
        autoCompleteType: "tel",
        keyboardType: "phone-pad",
    };

    txtPhoneNumberProps = this.isPlatformWeb()
        ? this.txtPhoneNumberWebProps
        : this.txtPhoneNumberMobileProps;

    txtInputLastNamePrpos = {
        onChangeText: (text: string) => {
            this.setState({ lastName: text });

            //@ts-ignore
            this.txtInputLastNamePrpos.value = text;
        },
    };

    txtInputFirstNamePrpos = {
        onChangeText: (text: string) => {
            this.setState({ firstName: text });

            //@ts-ignore
            this.txtInputFirstNamePrpos.value = text;
        },
    };

    txtInputConfirmPasswordProps = {
        onChangeText: (text: string) => {
            this.setState({ reTypePassword: text });

            //@ts-ignore
            this.txtInputConfirmPasswordProps.value = text;
        },
        secureTextEntry: true,
    };

    txtInputPasswordProps = {
        onChangeText: (text: string) => {
            this.setState({ password: text });

            //@ts-ignore
            this.txtInputPasswordProps.value = text;
        },
        secureTextEntry: true,
    };

    validatePassword() {
        var formatUpperandLower = /[A-Z][a-z]/
        var formatUpperandLower2 = /[a-z][A-Z]/
        var formatUpperandLower3 = /[A-Z][0-9][a-z]/
        var formatUpperandLower4 = /[a-z][0-9][A-Z]/
        var formatUpperandLower5 = /[0-9][A-Z][a-z]/
        var formatUpperandLower6 = /[0-9][a-z][A-Z]/
        var formatSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.\/?]+/
        var formatLettersandNumbers = /[0-9]/
        var formatLettersandNumbers2 = /[A-Z][0-9]/
        var formatLettersandNumbers3 = /[a-z][0-9]/
        var formatLettersandNumbers4 = /[0-9][A-Z]/
        var formatLettersandNumbers5 = /[0-9][a-z]/
        // var formatLettersandNumbers2 = /[A-Z][0-9][a-z]/
        // var formatLettersandNumbers3 = /[0-9][A-Z][a-z]/
        // var formatLettersandNumbers3 = /[a-z][A-Z][0-9]/
        // var formatLettersandNumbers3 = /[0-9][A-Z][a-z]/

        //0-9a-zA-Z
        console.log("aluuuuu", this.state.password, "--", formatUpperandLower.test(this.state.password), "--", formatLettersandNumbers.test(this.state.password), "--", formatSpecialCharacter.test(this.state.password))
        if (this.state.password.length >= 8) {
            this.setState({ eightCharracter: true })
        } else {
            this.setState({ eightCharracter: false })
        }
        if (formatUpperandLower.test(this.state.password) || formatUpperandLower2.test(this.state.password) || formatUpperandLower3.test(this.state.password) || formatUpperandLower4.test(this.state.password) || formatUpperandLower5.test(this.state.password) || formatUpperandLower6.test(this.state.password)) {
            this.setState({ mixtureOfUpLow: true })
        } else {
            this.setState({ mixtureOfUpLow: false })
        }
        if (this.state.mixtureOfUpLow) {
            if (formatLettersandNumbers.test(this.state.password)) {
                this.setState({ mixtureOfLetterNumbers: true })
            } else {
                this.setState({ mixtureOfLetterNumbers: false })
            }
        } else {
            this.setState({ mixtureOfLetterNumbers: false })
        }
        if (formatLettersandNumbers2.test(this.state.password) || formatLettersandNumbers3.test(this.state.password) || formatLettersandNumbers4.test(this.state.password) || formatLettersandNumbers5.test(this.state.password)) {
            this.setState({ mixtureOfLetterNumbers: true })
        } else {
            this.setState({ mixtureOfLetterNumbers: false })
        }

        if (formatSpecialCharacter.test(this.state.password)) {
            this.setState({ oneSpecialCharacter: true })
        } else {
            this.setState({ oneSpecialCharacter: false })
        }
        if (this.state.oneSpecialCharacter && this.state.mixtureOfUpLow || this.state.mixtureOfUpLow || this.state.oneSpecialCharacter) {
            if (formatLettersandNumbers.test(this.state.password)) {
                this.setState({ mixtureOfLetterNumbers: true })
            } else {
                this.setState({ mixtureOfLetterNumbers: false })
            }
        }

    }
    matchWithPassword() {
        if (this.state.reTypePassword) {
            if (this.state.password === this.state.reTypePassword) {
                this.setState({ matchedPassword: false })
            } else {
                this.setState({ matchedPassword: true })
            }
        }
    }
    checkSubmitStatus() {
        if (this.state.eightCharracter && this.state.mixtureOfLetterNumbers && this.state.mixtureOfUpLow && this.state.oneSpecialCharacter && !this.state.matchedPassword) {
            console.log(this.state.password, "--", this.state.reTypePassword)
            if (this.state.reTypePassword) {
                if (this.state.password.match(this.state.reTypePassword)) {
                    return true
                } else {
                    return false
                }
            }

        } else {
            return false
        }
    }

    resetPasswordMain = async () => {
        //this.setState({ tokenUrl: new URLSearchParams(location.search)?.get('token') })
        //console.log(new URLSearchParams(location.search)?.get('token'))
        //console.log(this.state.password, "--", this.state.reTypePassword, "token", new URLSearchParams(location.search)?.get('token'))

        let token = await StorageProvider.get('token');

        if (!token) {
            return;
        }

        const headers = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
            token: token
            // token: new URLSearchParams(location.search)?.get('token')

        }
        const attrs = {
            token: token,
            // token: new URLSearchParams(location.search)?.get('token'),
            password: this.state.password,
            password_confirmation: this.state.reTypePassword
        };
        const data = {
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updatePasswordID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.resetPasswordEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    sendVerification(email: string) {
        if (this.isStringNullOrBlank(email)) {
            this.showAlert(
                configJSON.goToOtpAfterPhoneValidationErrorTitle,
                "please fill up the signup again."
            );
            return false;
        }
        if (!this.isValidEmail(email)) {
            this.showAlert(
                configJSON.goToOtpAfterPhoneValidationErrorTitle,
                configJSON.errorEmailNotValid
            );
            return false;
        }
        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.verifyAccountSignup
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.signUpSendEmailId = requestMessage.messageId;
        const data = {
            attributes: {
                email: email.trim(),
            },
        };

        const httpBody = {
            data: data,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { table } from "console";
import { config } from "process";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    tablesBeforeLine: any[];
    tablesAfterLine: any[];
    fixedBeforeLine: any[];
    fixedAfterLine: any[];
    estimatedBeforeLine: any[];
    estimatedAfterLine: any[];
    stateBeforeLine: any[];
    stateAfterLine: any[];
    federalTaxPayment: boolean;
    paymentConfirm: boolean;
    allChecked: boolean;
    checkValidation: boolean;
    checkedArray: boolean[];
    token: any;
    currentYear: any;
    safeHarbour: any;
    taxLiability: any;
    fixedPercentage: any;
    stateFixedPercentage: any;
    fixedPercentageSettings: boolean;
    estimatedPaymentsSettings: boolean;
    stateSettings: boolean;
    trigger: boolean;
    todayDate: any;
    check1: boolean;
    runApis: boolean;
    totalFederalTaxesPaid: any;
    snackbarMessage: any;
    showSnackbar: boolean;
    quaterlyPayment1: any;
    quaterlyPayment2: any;
    quaterlyPayment3: any;
    quaterlyPayment4: any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class TaxesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // arrayholder: any[];
    // passwordReg: RegExp;
    // emailReg: RegExp;
    createAccountApiCallId: any;
    getMileagesId: any;
    createFormId: any;
    getFormsId: any;

    quaterlyPaymentUpdateID: any;
    getCarsId: any;
    loginApiCallId: any;
    validationApiCallId: string = "";

    imgPasswordVisible: any;
    imgPasswordInVisible: any;

    // labelHeader: any;
    // labelFirstName: string;
    // lastName: string;
    // labelEmail: string;
    // labelPassword: string;
    // labelRePassword: string;
    // labelLegalText: string;
    // labelLegalTermCondition: string;
    // labelLegalPrivacyPolicy: string;
    // btnTextSignUp: string;
    dummyColumn: any[]=[];
    hehe: boolean = true;
    checked: boolean = true
    getEstimatedColumnId: any;
    getStateTaxId: any;
    getFixedPercentageTaxId: any;
    getTableSectionId: any;
    getFedaralTaxTotal: any;
    getTaxSettingsDataId: any;
    getTaxSettingsId: any;
    createEstimatedPaymentId: any;
    createFixedPercentageId: any;
    createStateTaxId: any;
    updateStateTaxId: any;
    updateFixedPercentageTaxId: any;
    updateEstimatedTaxId: any;
    resetTaxLiabilityId: any;
    nf: any = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
    });



    currentCountryCode: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            tablesBeforeLine: [],
            tablesAfterLine: [],
            fixedBeforeLine: [],
            fixedAfterLine: [],
            estimatedBeforeLine: [],
            estimatedAfterLine: [],
            stateBeforeLine: [],
            stateAfterLine: [],
            federalTaxPayment: false,
            paymentConfirm: false,
            allChecked: false,
            checkValidation: false,
            checkedArray: [],
            token: '',
            currentYear: new Date().getFullYear(),
            safeHarbour: '',
            taxLiability: 0.00,
            fixedPercentage: '',
            stateFixedPercentage: '',
            fixedPercentageSettings: true,
            estimatedPaymentsSettings: true,
            stateSettings: true,
            trigger: false,
            todayDate: moment().format('DDMMM YYYY'),
            check1: false,
            runApis: false,
            totalFederalTaxesPaid: '',
            showSnackbar: false,
            snackbarMessage: '',
            quaterlyPayment1: '0.00',
            quaterlyPayment2: '0.00',
            quaterlyPayment3: '0.00',
            quaterlyPayment4: '0.00',
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                // if (apiRequestCallId === this.getFormsId) {
                //     if (!responseJson.errors) {
                //         //console.log("--------->", responseJson.data[0].form_data[0].attributes.form_date);
                //         //this.setState({ totalMileageDeduction: 0, mileageDeductionMonths: [], mileageData: [], yearlyMiles: 0 })
                //         if (responseJson?.data[0]?.form_type == "Document") {
                //             this.setState({ documentFormData: responseJson.data[0].form_data })
                //         }
                //         if (responseJson?.data[1]?.form_type == "Finantial Statements") {
                //             this.setState({ finantialFormData: responseJson.data[1].form_data })
                //         }

                //     } else {
                //         //Check Error Response
                //         this.parseApiErrorResponse(responseJson);
                //         this.parseApiCatchErrorResponse(errorReponse);
                //     }
                // } else if (apiRequestCallId === this.getCarsId) {
                //     if (!responseJson.errors) {
                //         if (responseJson.data) {
                //             let data: any[] = []
                //             responseJson.data.map((el: any) => {
                //                 data = data.concat({ id: el.id, name: el.attributes.name })
                //             })
                //             this.setState({ formCar: data[0].id })
                //             this.setState({ cars: data })
                //         }
                //     } else {
                //         this.parseApiErrorResponse(responseJson);
                //     }

                //     this.parseApiCatchErrorResponse(errorReponse);
                // }
                if (apiRequestCallId === this.getEstimatedColumnId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            let a: any[] = [
                                { date: moment(responseJson?.data?.attributes?.q1_date).format('MMMM D'), quarter: "1st Quarter Payment", value: responseJson?.data?.attributes?.q1 != -0 ? parseFloat(responseJson?.data?.attributes?.q1).toFixed(2) : 0.00 },
                                { date: moment(responseJson?.data?.attributes?.q2_date).format('MMMM D'), quarter: "2nd Quarter Payment", value: responseJson?.data?.attributes?.q2 != -0 ? parseFloat(responseJson?.data?.attributes?.q2).toFixed(2) : 0.00 },
                                { date: moment(responseJson?.data?.attributes?.q3_date).format('MMMM D'), quarter: "3rd Quarter Payment", value: responseJson?.data?.attributes?.q3 != -0 ? parseFloat(responseJson?.data?.attributes?.q3).toFixed(2) : 0.00 },
                                { date: moment(responseJson?.data?.attributes?.q4_date).format('MMMM D'), quarter: "4th Quarter Payment", value: responseJson?.data?.attributes?.q4 != -0 ? parseFloat(responseJson?.data?.attributes?.q4).toFixed(2) : 0.00 }
                            ]
                            this.setState({ estimatedBeforeLine: a });
                            this.setState({ quaterlyPayment1: parseFloat(responseJson?.data?.attributes?.q1).toFixed(2), quaterlyPayment2: parseFloat(responseJson?.data?.attributes?.q2).toFixed(2), quaterlyPayment3: parseFloat(responseJson?.data?.attributes?.q3).toFixed(2), quaterlyPayment4: parseFloat(responseJson?.data?.attributes?.q4).toFixed(2) })
                            let b: any[] = [
                                { name: "Tax Paid", value: parseFloat(responseJson?.data?.attributes?.tax_paid).toFixed(2) },
                                { name: "Over/(Under) Paid", value: parseFloat(responseJson?.data?.attributes?.over_or_under_paid).toFixed(2) }
                            ]
                            this.setState({ estimatedAfterLine: b })
                            this.setState({ safeHarbour: parseFloat(responseJson?.data?.attributes?.safe_harbor).toFixed(2), taxLiability: parseFloat(responseJson?.data?.attributes?.tax_liability).toFixed(2) })
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }

                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }


                } else if (apiRequestCallId === this.getFixedPercentageTaxId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            let a: any[] = [
                                // { name: "Fixed Percentage", value: parseFloat(responseJson?.data?.attributes?.fixed_percentage).toFixed(2) },
                                { name: "Net Profit", value: parseFloat(responseJson?.data?.attributes?.tentative_profit).toFixed(2) },
                                { name: "QBI Deduction", value: parseFloat(responseJson?.data?.attributes?.qbi_deduction).toFixed(2) },
                                { name: "Taxable Income", value: parseFloat(responseJson?.data?.attributes?.est_taxable_income).toFixed(2) }
                            ]
                            this.setState({ fixedPercentage: parseFloat(responseJson?.data?.attributes?.fixed_percentage) })
                            this.setState({ fixedBeforeLine: a })
                            let b: any[] = [
                                { name: "Calculated Tax", value: parseFloat(responseJson?.data?.attributes?.calulated_tax).toFixed(2) },
                                { name: "Tax Paid", value: parseFloat(responseJson?.data?.attributes?.tax_paid).toFixed(2) },
                                { name: "Over/(Under) Paid", value: parseFloat(responseJson?.data?.attributes?.over_or_under_paid).toFixed(2) }
                            ]
                            this.setState({ fixedAfterLine: b })
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }

                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }

                } else if (apiRequestCallId === this.getStateTaxId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            let a: any[] = [
                                // { name: "Fixed Percentage", value: parseFloat(responseJson?.data?.attributes?.fixed_percentage).toFixed(2) },
                                { name: "Taxable Income", value: parseFloat(responseJson?.data?.attributes?.est_taxable_income).toFixed(2) }
                            ]

                            this.setState({ stateBeforeLine: a, stateFixedPercentage: parseFloat(responseJson?.data?.attributes?.fixed_percentage) })
                            let b: any[] = [
                                { name: "Calculated Tax", value: parseFloat(responseJson?.data?.attributes?.calulated_tax).toFixed(2) },
                                { name: "Tax Paid", value: parseFloat(responseJson?.data?.attributes?.tax_paid).toFixed(2) },
                                { name: "Over/(Under) Paid", value: parseFloat(responseJson?.data?.attributes?.over_or_under_paid).toFixed(2) }
                            ]
                            this.setState({ stateAfterLine: b })
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }

                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }

                } else if (apiRequestCallId === this.getTableSectionId) {
                    if (!responseJson.errors) {
                        //responseJson?.data.map((data1: any, index: any) => { console.log("dst data", data1, "lala", index) })
                        if (responseJson.data) {
                            let a: any[] = [
                                { name: "Net Profit", value: parseFloat(responseJson?.data?.tentative_profit).toFixed(2) },
                                { name: "QBI Deduction", value: parseFloat(responseJson?.data?.qbi_deduction).toFixed(2) },
                                { name: "Taxable Income", value: parseFloat(responseJson?.data?.est_taxable_income).toFixed(2) },
                                { name: "Federal Tax", value: parseFloat(responseJson?.data?.federal_tax).toFixed(2) },
                                { name: "Employment Tax", value: parseFloat(responseJson?.data?.employment_tax).toFixed(2) }
                            ]
                            console.log("aaaaaa", a.map((data, index) => { data.value }), NaN, this.state.todayDate)
                            this.setState({ tablesBeforeLine: a })

                            let b: any[] = [
                                { name: "Total Tax", value: parseFloat(responseJson?.data?.total_tax).toFixed(2) },
                                { name: "Tax Paid", value: parseFloat(responseJson?.data?.tax_paid).toFixed(2) },
                                { name: "Over/(Under) Paid", value: parseFloat(responseJson?.data?.over_tax_paid).toFixed(2) }
                            ]
                            this.setState({ tablesAfterLine: b })

                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }


                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }

                } else if (apiRequestCallId === this.getTaxSettingsId) {

                    if (!responseJson.errors) {

                        if (responseJson?.data) {
                            this.setState({ fixedPercentageSettings: responseJson?.data?.attributes?.fixed_percentage_tax_calculation, estimatedPaymentsSettings: responseJson?.data?.attributes?.estimated_tax_payments, stateSettings: responseJson?.data?.attributes?.state_taxes })
                        }


                    }
                    else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }

                } else if (apiRequestCallId === this.getFedaralTaxTotal) {

                    if (!responseJson.errors) {

                        if (responseJson?.data) {
                            this.setState({ totalFederalTaxesPaid: responseJson?.data?.federal_tax_paid.toFixed(2) })
                        }


                    }
                    else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }

                }
                else if (apiRequestCallId === this.createEstimatedPaymentId) {
                    if (!responseJson.errors) {

                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }
                } else if (apiRequestCallId === this.createStateTaxId) {
                    if (!responseJson.errors) {

                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }
                } else if (apiRequestCallId === this.createFixedPercentageId) {
                    if (!responseJson.errors) {

                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }
                } else if (apiRequestCallId === this.updateStateTaxId) {
                    if (!responseJson.errors) {
                        if (this.state.stateFixedPercentage) {
                            this.getStateTax()
                        }


                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }
                } else if (apiRequestCallId === this.updateFixedPercentageTaxId) {
                    if (!responseJson.errors) {
                        if (this.state.fixedPercentage) {
                            this.getFixedPercentageTax()
                        }


                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }
                } else if (apiRequestCallId === this.updateEstimatedTaxId) {
                    if (!responseJson.errors) {
                        if (this.state.taxLiability) {
                            this.getEstimatedPayment()
                        }



                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }
                } else if (apiRequestCallId === this.resetTaxLiabilityId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            this.getEstimatedPayment()
                        }


                    } else {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    }
                } else if (apiRequestCallId === this.quaterlyPaymentUpdateID) {
                    if (responseJson.errors) {
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                    } else {
                        if (responseJson.data) {
                            this.getEstimatedPayment()
                        }
                    }

                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start




    // showErrorMsg = (msg: string) => {
    //     this.setState({ errorMsg: msg })
    //     setTimeout(() => this.setState({ errorMsg: '' }), 2000)
    // }
    callAllApis() {
        this.getTotalFederalTax()
        this.getEstimatedPayment()
        this.getStateTax()
        this.getFixedPercentageTax()
        //console.log("????calling back")
        this.getTableData()
        //console.log("????calling")
        this.getTaxSettingsData()
        //console.log("????calla")
        this.createEstimatedPaymentTax()
        this.createStateTax()
        this.createFixedPercentageTax()


    }

    getTaxSettingsData() {

        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTaxSettingsId = requestMessage.messageId;
        //console.log("eeeee", this.getTaxSettingsId)
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxSettingsApiEndpoint}/get_tax_section`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getTableData() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTableSectionId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/get_table_tax_data?tax_year=${this.state?.currentYear}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getTotalFederalTax() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFedaralTaxTotal = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/get_federal_tax?tax_year=${this.state?.currentYear}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getEstimatedPayment() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEstimatedColumnId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/get_estimated_payment_data?tax_year=${this.state?.currentYear}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getStateTax() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getStateTaxId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/get_state_tax_data?tax_year=${this.state?.currentYear}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getFixedPercentageTax() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFixedPercentageTaxId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/get_fixed_percentage_data?tax_year=${this.state?.currentYear}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    createEstimatedPaymentTax() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createEstimatedPaymentId = requestMessage.messageId;

        const attrs = {
            tax_liability: this.state?.taxLiability,
            tax_year: this.state?.currentYear

        };
        const data = {
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/estimated_payment`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    createStateTax() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createStateTaxId = requestMessage.messageId;

        const attrs = {
            fixed_percentage: 10,
            tax_year: this.state?.currentYear?.toString()

        };
        const data = {
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/create_state_tax`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    createFixedPercentageTax() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createFixedPercentageId = requestMessage.messageId;

        const attrs = {
            fixed_percentage: 20,
            tax_year: this.state?.currentYear?.toString()

        };
        const data = {
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/create_fixed_percentage`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    resetEstimatedPayment() {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token

        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.resetTaxLiabilityId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/reset_estimated_payment?year=${this.state?.currentYear}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    stateTaxUpdate() {
        //console.log("check3", this.state.stateFixedPercentage)
        if (this.state.stateFixedPercentage) {
            const headers = {
                "Content-Type": configJSON.ApiContentType,
                token: this.state.token

            }
            const attrs = {
                fixed_percentage: this.state.stateFixedPercentage ? parseFloat(this.state.stateFixedPercentage) : 0
            };
            const data = {
                attributes: attrs,
            };

            const httpBody = {
                data: data,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.updateStateTaxId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.taxApiEndPoint}/state_tax_updation?tax_year=${this.state?.currentYear}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putApiMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

        }


    }
    fixedPercentageUpdate() {
        //console.log("check1", this.state.fixedPercentage)
        if (this.state.fixedPercentage) {
            const headers = {
                "Content-Type": configJSON.ApiContentType,
                token: this.state.token

            }
            const attrs = {
                fixed_percentage: this.state.fixedPercentage ? parseFloat(this.state.fixedPercentage) : 0
            };
            const data = {
                attributes: attrs,
            };

            const httpBody = {
                data: data,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.updateFixedPercentageTaxId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.taxApiEndPoint}/fixed_percentage_updation?tax_year=${this.state?.currentYear}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putApiMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);


        }

    }
    estimatedPaymentUpdate() {
        //console.log("check2", this.state.taxLiability)
        if (this.state.taxLiability) {
            if (this.state.taxLiability == 0.00) {
                this.resetEstimatedPayment()

            } else {
                const headers = {
                    "Content-Type": configJSON.ApiContentType,
                    token: this.state.token

                }
                const attrs = {
                    tax_liability: parseFloat(this.state.taxLiability)

                };
                const data = {
                    attributes: attrs,
                };

                const httpBody = {
                    data: data,
                };

                const requestMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
                this.updateEstimatedTaxId = requestMessage.messageId;
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify(httpBody)
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    `${configJSON.taxApiEndPoint}/estimated_payment_updation?tax_year=${this.state?.currentYear}`
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(headers)
                );

                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.putApiMethodType
                );
                runEngine.sendMessage(requestMessage.id, requestMessage);


            }
            //            console.log(0.00, "kiki")


        }




    }
    quaterlyPaymentUpdate(id: any) {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token
        }
        let attres = {
        };
        if (id === 0) {
            attres = {
                quarterly_key: "q1",
                quarterly_value: this.state.quaterlyPayment1
            };
        } else if (id === 1) {
            attres = {
                quarterly_key: "q2",
                quarterly_value: this.state.quaterlyPayment2
            };
        } else if (id === 2) {
            attres = {
                quarterly_key: "q3",
                quarterly_value: this.state.quaterlyPayment3
            };
        } else if (id === 3) {
            attres = {
                quarterly_key: "q4",
                quarterly_value: this.state.quaterlyPayment4
            };
        }
        const attrs = attres;

        const data = {
            attributes: attrs,
        };
        const httpBody = {
            data: data,
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.quaterlyPaymentUpdateID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/update_quarterly_payments?tax_year=${this.state?.currentYear}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);


    }

    populateStates() {
        this.setState({
            tablesBeforeLine: [
                { name: "Net Profit", value: "0.00" },
                { name: "QBI Deduction", value: "0.00" },
                { name: "Taxable Income", value: "0.00" },
                { name: "Federal Tax", value: "0.00" },
                { name: "Employment Tax", value: "0.00" }
            ],
            tablesAfterLine: [
                { name: "Total Tax", value: "0.00" },
                { name: "Tax Paid", value: "0.00" },
                { name: "Over/(Under) Paid", value: "0.00" }
            ],
            fixedBeforeLine: [
                // { name: "Fixed Percentage", value: "0.00" },
                { name: "Net Profit", value: "0.00" },
                { name: "QBI Deduction", value: "0.00" },
                { name: "Taxable Income", value: "0.00" }
            ],
            fixedAfterLine: [
                { name: "Calculated Tax", value: "0.00" },
                { name: "Tax Paid", value: "0.00" },
                { name: "Over/(Under) Paid", value: "0.00" }
            ],
            estimatedBeforeLine: [
                { date: "", quarter: "1st Quarter Payment", value: "0.00" },
                { date: "", quarter: "2nd Quarter Payment", value: "0.00" },
                { date: "", quarter: "3rd Quarter Payment", value: "0.00" },
                { date: "", quarter: "4th Quarter Payment", value: "0.00" }
            ],
            estimatedAfterLine: [
                { name: "Tax Paid", value: "0.00" },
                { name: "Over/(Under) Paid", value: "0.00" }
            ],
            stateBeforeLine: [
                { name: "Fixed Percentage", value: "0.00" },
                { name: "Taxable Income", value: "0.00" }
            ],
            stateAfterLine: [
                { name: "Calculated Tax", value: "0.00" },
                { name: "Tax Paid", value: "0.00" },
                { name: "Over/(Under) Paid", value: "0.00" }
            ]

        })
    }
    closeModal() {
        this.setState({ paymentConfirm: false, federalTaxPayment: false })
    }
    handleCheckBox(e: any) {
        //console.log("e for any", e.target.checked)

        if (e.target.checked === true) {
            this.state.checkedArray.push(e.target.checked)
        }
        else {
            this.state.checkedArray.pop()
        }
        //console.log("e for arrayyy", this.state.checkedArray.length)
        this.updateValue()
    }
    updateValue() {
        if (this.state.checkedArray.length == 3) {
            this.setState({ checkValidation: false })
        }
        else {
            this.setState({ checkValidation: true })
        }
    }
    checkStatus() {
        if (this.state.fixedPercentageSettings === false || this.state.estimatedPaymentsSettings === false) {
            return true
        }
        else {
            return false
        }
    }





    // Customizable Area End
}

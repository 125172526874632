import React from "react";
// import { getStorageData } from "../../../framework/src/Helpers";
// import {setStorageData} from '../../../framework/src/Helpers'
import NotificationContext from "./NotificationContext";

interface Props {

}

interface State {
  notificationCount: any;
}

export default class NotificationProvider extends React.Component<Props, State> {
  // subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      notificationCount: 0
    }
  }

  async componentDidMount() {
    this.getNotificationCount();
    // const colorScheme = Appearance.getColorScheme();
    // let getThemeColor = (await getStorageData('themeColor')) || '';
    // if(getThemeColor){
    //   this.setState({themeColor:getThemeColor})
    // }
    // else{
    //   this.setState({themeColor:colorScheme})
    // }     
  }


  getNotificationCount = () => {
    // Customizable Area Start

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('token') || "",
    };

    const baseURL = "https://neeuhfinalbuildcard-125853-ruby.b125853.dev.eastus.az.svc.builder.cafe/bx_block_push_notifications/push_notifications/get_notification_count";

    fetch(baseURL, {
      method: "GET",
      headers: header
    })
      .then(response => response.json())
      .then(json => {
        console.log('notification count api res', json)
        if (json?.data?.notification_count) {
          this.setState({ notificationCount: json.data.notification_count })
        } else {
          this.setState({ notificationCount: 0 })
        }
      })
      .catch(err => console.log('notification count api err', err));
  };



  // componentWillUnmount() {
  //   // this.subscription.remove()
  // }



  render() {
    return (
      <NotificationContext.Provider value={{
        notificationCount: this.state.notificationCount,
        getNotificationCountFun: this.getNotificationCount,
        changeNotificationCount: (count: any) => {
          console.log('notification count has been changed ....... ', count);

          this.setState({ notificationCount: count }, () => {
            console.log('notification count has been changed : ', count, this.state.notificationCount);
          })
        }

      }}>
        {this.props.children}
      </NotificationContext.Provider>
    );
  }
}

import React, { Component } from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Modal,
  Image,
  TouchableWithoutFeedback,
  ScrollView,
} from "react-native";
import { DeleteImage } from "../../blocks/categoriessubcategories/src/assets";
import Button from "./Button";
import DeletePhotoModal from "./DeletePhotoModal";
import { scaledSize } from "../../framework/src/Utilities";

// Customizable Area End
export default class SingleTransactionModal extends Component<any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const { state, handleSingleTransactionModal, handleAddDetails, handleAddtoReport, handleNoteChange, handleDeleteImage, handleImageLibrary, closeModal, deleteImage, handleDeleteSelectedImage, deleteCameraImage, handleCameraPress } = this.props;
    const imageToBeDeleted = state.imagesList[state?.selectedIndex];
    const checkValue = (state.imagesList?.length || state.selectedImage?.length) || state.addNote?.length

    return (
        <ScrollView keyboardShouldPersistTaps="never">
          <View style={styles.view}>
            <Modal visible={state.isShowSingleTransactionModal} transparent animationType='slide'
              onRequestClose={handleSingleTransactionModal}
            >
              <TouchableWithoutFeedback>
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                    <View>
                      {state.showModal ?
                        <DeletePhotoModal
                          showModal={state.showModal}
                          handleDeleteSelectedImage={handleDeleteSelectedImage}
                          imageToBeDeleted={imageToBeDeleted}
                          deleteImage={deleteImage}
                          closeModal={closeModal}
                          selectedImage={state.selectedImage}
                          deleteCameraImage={deleteCameraImage}
                          isUploadCamera={state.isUploadCamera} /> : (<>
                            <View style={{ marginTop: 5, alignItems: 'center', justifyContent: 'center' }}>
                              {
                                state.isUpload && (
                                  state.imagesList &&
                                  state.imagesList?.map((image: { uri: any; }, index: React.Key) => {
                                    return (
                                      <>
                                        <View
                                          style={{
                                            marginBottom: 10,
                                            flexDirection:"row",
                                            width: "100%",
                                            position:"relative"
                                          }}
                                          key={index}
                                        >
                                          <View style={{width:"100%"}}>
                                            <Text
                                              style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontFamily: "OpenSans-Bold",
                                                fontWeight: "700",
                                                fontSize: 18,
                                                letterSpacing: 0,
                                                color: "#0F172A",
                                                width: '100%',
                                                textAlign: 'center',
                                              }}
                                            >{`Attached Photo`}</Text>
                                          </View>
                                          <View style={styles.crossIcon}>
                                            <TouchableOpacity onPress={handleSingleTransactionModal}>
                                              <Image source={require("./close_btn.png")} />
                                            </TouchableOpacity>
                                          </View>
                                        </View>
                                        <View style={styles.imageCard}>
                                          <View key={index} style={styles.imageContainer}>
                                            <Image source={{ uri: image.uri }} style={styles.image} />
                                            <TouchableOpacity data-test-id="deleteId" style={styles.deleteButton} onPress={() => handleDeleteImage(index)}>
                                              <Image source={DeleteImage} style={styles.deleteIcon} />
                                            </TouchableOpacity>
                                          </View>
                                        </View>


                                      </>
                                    )
                                  })
                                )}
                              {state.isUploadCamera &&
                                state.selectedImage &&
                                state.selectedImage?.map((image: { uri: any; }, index: React.Key)=>{
                                  return(
                                    <>
                                      <View
                                        style={{
                                          marginBottom: 10,
                                          flexDirection:"row",
                                          width: "100%",
                                          position:"relative"
                                        }}
                                      >
                                        <View style={{ width: "100%"}}>
                                          <Text
                                            style={{
                                              alignItems: "center",
                                              justifyContent: "center",
                                              fontFamily: "OpenSans-Bold",
                                              fontWeight: "700",
                                              fontSize: 18,
                                              letterSpacing: 0,
                                              color: "#0F172A",
                                              width: '100%',
                                              textAlign: 'center',
                                            }}
                                          >{`Attached Photo`}</Text></View>
                                        <View style={styles.crossIcon}>
                                          <TouchableOpacity onPress={handleSingleTransactionModal}>
                                            <Image source={require("./close_btn.png")} />
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                      <View style={styles.imageCard}>
                                        <View key={index} style={styles.imageContainer}>
                                          <Image source={{ uri: image?.uri }} style={styles.image} />
                                          <TouchableOpacity style={styles.deleteButton} onPress={handleDeleteSelectedImage} >
                                            <Image source={DeleteImage} style={styles.deleteIcon} />
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    </>
                                  )
                                })
                              }

                              {(state.imagesList?.length === 0 && state.selectedImage?.length === 0) && (
                                <>
                                  <View
                                    style={{
                                      marginBottom: 10,
                                      flexDirection:"row",
                                      width: "100%",
                                      position:"relative"
                                    }}
                                  >
                                    <View style={{width: '100%'}}>
                                      <Text
                                        style={{
                                          fontFamily: "OpenSans-Bold",
                                          fontWeight: "700",
                                          fontSize: 18,
                                          letterSpacing: 0,
                                          color: "#0F172A",
                                          textAlign: 'center',
                                          width: '100%',
                                          justifyContent: 'center'
                                        }}
                                      >{`Add Photo Receipt From`}</Text>
                                    </View>
                                    <View style={styles.crossIcon}>
                                      <TouchableOpacity onPress={handleSingleTransactionModal}>
                                        <Image source={require("./close_btn.png")} />
                                      </TouchableOpacity>
                                    </View>

                                  </View>
                                  <View style={{padding:10,}}>
                                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                                      <TouchableOpacity
                                        style={{
                                          height: 44,
                                          width: 325,
                                          borderRadius: 30,
                                          paddingHorizontal: 16,
                                          paddingVertical: 10,
                                          backgroundColor: "#15066A"
                                        }}
                                        onPress={handleCameraPress}>
                                        <Text style={{
                                          color: '#fff',
                                          fontSize: 16,
                                          fontWeight: '700',
                                          textAlign: 'center',
                                        }}>CAMERA</Text>
                                      </TouchableOpacity>
                                    </View>
                                    <View style={{ marginTop: 12,  }}>
                                      <TouchableOpacity
                                        style={{
                                          borderColor: "#15066A",
                                          backgroundColor: "white",
                                          borderWidth: 1,
                                          borderStyle: 'solid',
                                          height: 44,
                                          width: 325,
                                          borderRadius: 30,
                                          paddingHorizontal: 16,
                                          paddingVertical: 10,
                                        }}
                                        onPress={handleImageLibrary}
                                      >
                                        <Text style={{
                                          color: '#15066A',
                                          fontSize: 16,
                                          fontWeight: '700',
                                          textAlign: 'center',
                                        }}>PHOTO LIBRARY</Text>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </>
                              )}
                            </View>
                            <View style={{ marginTop: 15, marginBottom: 12, justifyContent: "center", alignItems: "center" }}>
                              <Text
                                style={{
                                  fontFamily: "OpenSans-Bold",
                                  fontWeight: "700",
                                  fontSize: 16,
                                  letterSpacing: 0,
                                  color: "#0f172a",
                                  textAlign: 'center',
                                  width: 160,
                                }}
                              >
                                {"Add Note"}
                              </Text>
                            </View>
                            <View style={{ alignItems: "center" }}>
                              <TextInput
                                data-test-id="addNote"
                                style={styles.textbox}
                                placeholder={"Describe your note here..."}
                                maxLength={100}
                                multiline
                                value={state.addNote}
                                onChangeText={(text: string) => handleNoteChange(text)}
                              />
                            </View>
                            <View style={{ flexDirection: "row",justifyContent: 'space-between', marginHorizontal: scaledSize(15), marginTop: scaledSize(15), marginBottom: scaledSize(20) }}>
                              <Button
                                theme={checkValue ? "secondary" : "primary"}
                                text={"SAVE DETAILS"}
                                disabled={checkValue ? false : true}
                                textStyle={{ color: checkValue ? "#3876F1": "#ffffffff", fontSize: scaledSize(12), lineHeight: scaledSize(19) }}
                                style={{
                                  height: scaledSize(30),
                                  width: scaledSize(130),
                                  borderRadius: scaledSize(44),
                                  paddingHorizontal: 0,
                                  paddingVertical: 0,
                                  ...checkValue && {
                                    borderColor: "#3876F1",
                                    borderWidth: 1,
                                    backgroundColor: "#ffffffff",
                                  }}}
                                testID="testAddDetails"
                                onPress={handleAddDetails}
                              />
                              <Button
                                theme={checkValue ? "secondary" : "primary"}
                                text={"ADD TO REPORT"}
                                disabled={checkValue ? false: true}
                                textStyle={{ color: "#ffffffff", fontSize: scaledSize(12), lineHeight: scaledSize(19) }}
                                style={{
                                  height: scaledSize(30),
                                  width: scaledSize(130),
                                  borderRadius: scaledSize(44),
                                  paddingHorizontal: 0,
                                  paddingVertical: 0,
                                }}
                                testID="testAddReport"
                                onPress={handleAddtoReport}
                              />
                            </View>
                          </>)}
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>

            </Modal>
          </View>
        </ScrollView>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
  crossIcon: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 30,
    width: 30,
    borderRadius: 15,
    position:"absolute",
    right: 20,
  },
  deleteIcon: {
    // color: "gray",
    // backgroundColor: "white",
    width: 16,
    height: 22,
  },
  textbox: {
    borderWidth: 1,
    borderColor: '#CBD5E1',
    borderRadius: 2,
    fontSize: 16,
    width: 300,
    paddingBottom: 75,
    paddingLeft: 7,
    alignItems: 'center',
    justifyContent: 'center',
    textAlignVertical: 'top',
  },
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#D9D9D980", width: '100%', height: 2000, position: 'absolute', zIndex: 99
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 110,
    margin:12,
    zIndex: -1,
    position: 'relative',
    bottom: 0,
  },
  modalView: {
    margin: 10,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
      position: 'relative',
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  imageCard: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'relative',
    margin: 5,
  },
  image: {
    width: 135,
    height: 135,
  },
  modalDeleteButton: {
    paddingVertical: 5,
    paddingHorizontal: 8,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: '#fff',
    height: 36,
    width: 36,
    borderRadius: 100,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#B3B3B3',
    marginBottom: 20
  },
  deleteButton: {
    position: 'absolute',
    zIndex: 4,
    marginTop: 115,
    left: -1,
    color: "gray",
    paddingVertical: 5,
    paddingHorizontal: 8,
    backgroundColor: '#fff',
    height: 38,
    width: 38,
    borderRadius: 100,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#B3B3B3'
  },
});
// Customizable Area End

//@ts-nocheck
import React from "react";
import {
  // Customizable Area Start
  withStyles,
  Checkbox,
  TextField,
  Typography,
  InputAdornment,
  Grid,
  Button,
  Modal
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { Link, Redirect } from "react-router-dom";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import TopNav from "../../../components/src/TopNav";
import { number } from "yup";
interface Props {
  id:string;
  navigation:any;
  classes:any;
}
// Customizable Area End
// Customizable Area Start
const useStyles = () => ({
  root: {
    paddingTop: "60px",
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
  },
  leafTL: {
    width: "176.9px",
    height: "176.89px",
    borderRadius: "0 60px",
    margin: "5px 0",
  },
  leafTR: {
    width: "176.9px",
    height: "176.89px",
    borderRadius: "60px 0",
    margin: "5px 0",
  },
  orange: {
    backgroundColor: "#FF9A30",
  },
  lBlue: {
    backgroundColor: "#B6D9FD",
  },
  blue: {
    backgroundColor: "#5597F4",
  },
  dBlue: {
    backgroundColor: "#0D016A",
  },
  pageTitle: {
    fontWeight: "700",
    fontSize: "40px",
    display: "inline-block",
    position: "relative",
    paddingBottom: "3px",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "70%",
      height: "5px",
      bottom: "0",
      left: "15%",
      borderBottom: "2px solid #B5D8FD",
    },
  },
  underLine: {
    fontWeight: "bold",
    position: "relative",
    paddingBottom: "3px",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "50%",
      height: "5px",
      bottom: "0",
      left: "25%",
      borderBottom: "0.5px solid #3F4144",
    },
  },
  leafGrid: {
    gap: "10px",
  },
  normalUrl: {
    color: "#7BAEF7",
    textDecoration: "none",
  },
  orangeText: {
    fontSize: "30px",
    color: "#FF9B31",
    fontWeight: '800'
  },
  form: {
    gap: "20px",
    zIndex: 1,
    maxWidth: "38%"
  },
  textBtn: {
    padding: "0 5px",
    fontWeight: "800",
    fontSize: "30px",
    cursor: "pointer",
  },
  formBtn: {
    textTransform: "none",
    backgroundColor: "#5597F4",
    borderRadius: "30px",
    width: "50%",
    "&:hover": {
      backgroundColor: "#5597F4",
    },
    "& p": {
      fontWeight: "bold",
      color: "#ffffff",
      fontSize: "13px"
    },
  },
  checkBox: {
    padding: 5
  },
  textField: {
    "& input": {
      fontSize: "20px",
    }
  },
  iconClass: {
    fill: "#D4D4D4"
  },
  font20px: {
    fontSize: "20px",
    fontWeight: "700"
  },
  font25px: {
    fontSize: "25px"
  },
  '@media (max-width:1425px)': {
    font20px: {
      fontSize: "15px"
    },
    font25px: {
      fontSize: "17.5px"
    },
    orangeText: {
      fontSize: "22.5px"
    },
    pageTitle: {
      fontSize: "30px"
    },
    textField: {
      "& input": {
        fontSize: "15px",
      }
    },
    textBtn: {
      fontSize: "22.5px"
    }
  },
  '@media (max-width:1250px)': {
    font20px: {
      fontSize: "11.25px"
    },
    font25px: {
      fontSize: "13px"
    },
    orangeText: {
      fontSize: "16"
    },
    pageTitle: {
      fontSize: "22.5px"
    },
    textField: {
      "& input": {
        fontSize: "11.25px",
      }
    },
    textBtn: {
      fontSize: "16px"
    },
    checkBox: {
      "& svg": {
        width: "15px",
        height: "15px"
      }
    },
    formBtn: {
      width: "40%"
    },
    iconClass: {
      width: "20px"
    },
    flexFor1250: {
      maxWidth: "100%",
      justifyContent: "center",
      flexBasis: "100%"
    }
  }
});
// Customizable Area End
export class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      loginPage: true,
      ...this.state,
      // Customizable Area End
    };
  }
  // Customizable Area Start
  goToDashboard() {
    this.props.history.push('/dashboard');
  }

  componentDidMount(): Promise<void> {
      document.addEventListener('keydown', this.handleKeyPress)
  }
  
  componentWillUnmount(): Promise<void> {
      document.removeEventListener('keydown', this.handleKeyPress)
  }

  handleKeyPress = (event: KeyboardEvent) => {
    if(event.key === "Enter"){
      this.state.loginPage ? this.login() : this.createAccount()
    }
  }

  updatePage = () => {
    this.setState({ loginPage: !this.state.loginPage, userName: "", email: "", password: "", reTypePassword: "", phone: "" })
  }
  updateValue = (name, value) => {


    if (name.includes('phone')) {
      const numberValue = value.replace(/[^\d]/g, "")
      if (numberValue.length > 10) return
      value = numberValue.length > 3 && numberValue.length < 7 ? '(' + numberValue.substr(0, 3) + ')' + numberValue.substr(3)
        : numberValue.length > 6 ? '(' + numberValue.substr(0, 3) + ')' + numberValue.substr(3, 3) + '-' + numberValue.substr(6)
          : numberValue


      this.setState({ phone: value });
    } else {
      this.setState({ [name]: value });
    }
  };
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        className={classes.root}
      >
        <Grid
          item
          container
          direction="column"
          xs={4}
          alignItems="center"
          className={classes.form}
        >
          <Grid item container xs={12} justifyContent="center">
            {this.state.loginPage ? (
              <Typography variant="h5" className={classes.pageTitle}>
                Sign In
              </Typography>
            ) : (
              <Typography variant="h5" className={classes.pageTitle}>
                Sign Up
              </Typography>
            )}
          </Grid>
          {this.state.loginPage && (
            <Grid>
              <Typography variant="h6" className={classes.orangeText}>
                Welcome to Neeuh!
              </Typography>
            </Grid>
          )}
          <Grid item container xs={12}>
            <TextField className={classes.textField}
              size="small"
              style={{ fontWeight: 'bold' }}
              value={this.state.userName}
              name="userName"
              onChange={(e) => {
                this.updateValue(e.target.name, e.target.value);
              }}
              data-testid="user-name"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <PersonOutlineOutlinedIcon className={classes.iconClass} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="username"
            />
          </Grid>
          {this.state.goToDashboard && this.state.loginSuccess ? <Redirect to={'/dashboard'} /> : <></>}
          {this.state.goToChangePassword && this.state.loginSuccess ? <Redirect to={'/changepassword'} /> : <></>}
          {!this.state.loginPage && (
            <Grid item xs={12} container style={{ gap: "20px" }}>
              <Grid item xs={12}>
                <TextField className={classes.textField}
                  size="small"
                  value={this.state.email}
                  name="email"
                  onChange={(e) =>
                    this.updateValue(e.target.name, e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <EmailOutlinedIcon className={classes.iconClass} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="email"
                  data-testid="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField className={classes.textField}
                  size="small"
                  value={this.state.phone}
                  name="phone"
                  //type="number"
                  onChange={(e) =>
                    this.updateValue(e.target.name, e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <CallEndOutlinedIcon className={classes.iconClass} />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  data-testid="phone"
                  placeholder="phone number"
                />
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12}>
            <TextField className={classes.textField}
              size="small"
              value={this.state.password}
              name="password"
              type="password"
              data-testid="password"
              onChange={(e) =>
                this.updateValue(e.target.name, e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <LockOutlinedIcon className={classes.iconClass} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="password"
            />
          </Grid>
          {this.state.signedUp ? <Redirect to={'/emailverification'} /> : <></>}
          {!this.state.loginPage && (
            <Grid item container xs={12}>
              <TextField className={classes.textField}
                size="small"
                name="reTypePassword"
                type="password"
                value={this.state.reTypePassword}
                data-testid="retype-password"
                onChange={(e) =>
                  this.updateValue(e.target.name, e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <LockOutlinedIcon className={classes.iconClass} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="confirm password"
              />
            </Grid>
          )}
          {this.state.loginPage && (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
            >
              <Grid item container alignItems="center" xs={5} className={classes.flexFor1250}>
                <Checkbox color="primary" className={classes.checkBox} />
                <Typography display="inline" variant="caption" className={`${classes.font20px} ${classes.normalUrl}`}>
                  Remember Me
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={7} className={classes.flexFor1250}>
                <Link to="/ForgotPassword" className={classes.normalUrl}>
                  <Typography variant="caption" className={classes.font20px}>Forgot Username/Password?</Typography>
                </Link>
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12} justifyContent="center">
            <Button
              variant="contained"
              onClick={() =>
                this.state.loginPage ? this.login() : this.createAccount()

              }
              className={classes.formBtn}
              data-testid="button"
            >
              {this.state.loginPage ? (
                <Typography variant="body2">SIGN IN</Typography>
              ) : (
                <Typography variant="body2">SIGN UP</Typography>
              )}
            </Button>
          </Grid>
          {this.state.loginPage ? (
            <Grid
              item
              container
              xs={12}
              className={classes.underLine}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2" className={classes.font25px}>Don't have an Account?</Typography>
              <Button onClick={() =>
                  this.updatePage()
                } style={{textTransform:'none'}} onFocus={this.onFocusedSignInNavigator} data-testid = "signUp-button">
              <Typography
                variant="body1"
                color="primary"
                className={classes.textBtn}
              >
                Sign Up
              </Typography>
              </Button>
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.underLine}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2" className={classes.font25px}>Already have an account?</Typography>
              <Button style={{textTransform:'none'}} onClick={() =>
                  this.updatePage()
                } onFocus={this.onFocusedSignInNavigator} data-testid="signin-button">
              <Typography
                variant="body2"
                color="primary"
                className={classes.textBtn}
              >
                Sign In
              </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        <Modal
          style={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Open Sans Roman" }}
          open={this.state.openModalLogin}
          onClose={this.handleClose}

        >
          <div style={{ backgroundColor: "white", padding: "10px 10px 10px 10px", width: "fitContent", borderRadius: 5 }}>
            <Typography style={{ fontSize: 26, fontWeight: "bolder" }} >{this.state.title}</Typography>

            <Typography style={{ fontSize: 18, color: "black", padding: "5 0 10 0", }}>
              {this.state.modalContent}
            </Typography>

            <Button size="small" variant="contained" style={{ backgroundColor: "#5597F4", marginLeft: 35, color: "white", width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 2, cursor: "pointer", }}
             onClick={this.handleClose}>
              OK
            </Button>
          </div>
        </Modal>
      </Grid>
      // Customizable Area End

    );
  }
}

export default withStyles(useStyles)(EmailAccountRegistration);

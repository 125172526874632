import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleSheet, Image, View, ViewStyle, TextInput, ImageSourcePropType, TouchableOpacity } from 'react-native';
import { scaledSize } from '../../framework/src/Utilities';
type MyProps = { testID:string, placeholder: string; size: "small" | "medium" | "large" , leftIcon?: ImageSourcePropType, rightIcon?: ImageSourcePropType, style: ViewStyle, disabled?: boolean, onChangeText: (value: string) => void, value: string, placeholderColor?: string, secureTextEntry?: boolean, onRightIconPress?: () => void };
type MyState = { value: string };

export default class Input extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string, 
    placeholder: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    leftIcon: PropTypes.number,
    rightIcon: PropTypes.number,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onChangeText: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    placeholderColor: PropTypes.string,
    // secureTextEntry: PropTypes.boolean,
    onRightIconPress: PropTypes.func
  };

  constructor(props: any) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }
  
  getSize = () => {
    const { size } = this.props;
    if (size === "small") {
        return scaledSize(36)
    } else if (size === "medium") {
        return scaledSize(44)
    } else if (size === "large") {
        return scaledSize(56)
    }
  }

  render() {
    const { testID, style, value, onChangeText, placeholder, disabled, leftIcon, rightIcon, placeholderColor, secureTextEntry, onRightIconPress } = this.props;
    return (
        <View testID={testID} style={[styles.mainContainer, style, { height: this.getSize() }]}>
            {leftIcon !== undefined && <View>
                <Image source={leftIcon} style={styles.iconStyle} />
            </View>}
            <TextInput 
                value={value}
                style={[styles.textInputStyle]}
                onChangeText={onChangeText}
                placeholder={placeholder}
                editable={!disabled}
                placeholderTextColor={placeholderColor}
                secureTextEntry={secureTextEntry}
            />
            {rightIcon !== undefined && <TouchableOpacity onPress={() => { onRightIconPress && onRightIconPress() }}>
                <Image source={rightIcon} style={styles.iconStyle} />
            </TouchableOpacity>}
        </View>
    );
  }
}

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'row',
        width: scaledSize(286),
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#CBD5E1',
        paddingHorizontal: scaledSize(10),
        borderRadius: scaledSize(8)
    },
    btnTextStyle: {
        fontSize: scaledSize(16),
        fontFamily: "OpenSans-Bold",
        fontWeight: "700",
        textAlign: 'center'
    },
    leftIconView: {
        height: scaledSize(30),
        width: scaledSize(40),
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-start'
    },
    iconStyle: {
        height: scaledSize(19),
        width: scaledSize(19),
        tintColor: "#94A3B8"
    },
    rightIconView: {
        height: scaledSize(30),
        width: scaledSize(40),
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-end'
    },
    textInputStyle: {
        color: "#000000",
        fontSize: scaledSize(16),
        fontFamily: 'OpenSans-Regular',
        fontWeight: '400',
        flex: 1
    },
})

import { MODE, LIST_MODE, DROPDOWN_DIRECTION, LANGUAGE, TRANSLATIONS } from './constants';
import THEMES from './themes';
import Picker from './components/Picker';

Picker.MODE = MODE;
Picker.setMode = (mode: any) => {
    Picker.MODE.DEFAULT = mode;
}

Picker.LIST_MODE = LIST_MODE;
Picker.setListMode = (mode: any) => {
    Picker.LIST_MODE.DEFAULT = mode;
}

Picker.DROPDOWN_DIRECTION = DROPDOWN_DIRECTION;
Picker.setDropDownDirection = (direction: any) => {
    Picker.DROPDOWN_DIRECTION.DEFAULT = direction;
}

Picker.LANGUAGE = LANGUAGE;
Picker.setLanguage = (language: any) => {
    LANGUAGE.DEFAULT = language;
}

Picker.addTranslation = (language: any, translation: any) => {
    TRANSLATIONS[language] = translation;
}

Picker.modifyTranslation = (language: any, translation: any) => {
    if (TRANSLATIONS.hasOwnProperty(language)) {
        TRANSLATIONS[language] = {...TRANSLATIONS[language], ...translation};
    }
}

Picker.THEMES = THEMES;
Picker.setTheme = (name: any) => {
    Picker.THEMES.DEFAULT = name;
}
Picker.addTheme = (name: any, theme: any) => {
    Picker.THEMES[name] = theme;
}

export default Picker;
//@ts-nocheck
import React from "react";
import {
    withStyles,
    Checkbox,
    TextField,
    Typography,
    InputAdornment,
    Grid,
    Button,
    Box,
    Snackbar
} from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import ChangePasswordController from "./changePasswordController.web";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import TopNav from "../../../components/src/TopNav";
import lock from "../assets/lock.png";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';

const useStyles = (theme) => ({
    root: {
        paddingTop: "60px",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
    },
    sideLeafs: {
        position: "absolute",
        bottom: "-5px",
        right: "-35px",
    },
    leafTL: {
        width: "100px",
        height: "100px",
        borderRadius: "0 40px",
        margin: "5px 0",
    },
    leafTR: {
        width: "100px",
        height: "100px",
        borderRadius: "40px 0",
        margin: "5px 0",
    },
    orange: {
        backgroundColor: "#FF9A30",
    },
    lBlue: {
        backgroundColor: "#B6D9FD",
    },
    blue: {
        backgroundColor: "#5597F4",
    },
    dBlue: {
        backgroundColor: "#0D016A",
    },
    pageTitle: {
        fontSize: "40px",
        fontWeight: "700",
        display: "inline-block",
        position: "relative",
        color: "#000000",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "33%",
            height: "20px",
            bottom: "-3",
            left: "38%",
            borderBottom: "4px solid #B5D8FD",
        },
        '@media (max-width:1425px)': {
            fontSize: "30px",
        },
    },
    underLine: {
        fontWeight: "bold",
        position: "relative",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "40%",
            height: "5px",
            bottom: "0",
            left: "30%",
            borderBottom: "0.5px solid #D6D6D6",
        },
    },
    leafGrid: {
        gap: "10px",
    },
    normalUrl: {
        color: "#7BAEF7",
        textDecoration: "none",
    },
    orangeText: {
        color: "#FF9B31",
        fontWeight: 'bold'
    },
    form: {
        gap: "20px",
        zIndex: 1,
    },
    textBtn: {
        padding: "0 5px",
        fontWeight: "bold",
        cursor: "pointer",
    },
    formBtn: {
        textTransform: "none",
        backgroundColor: "#5597F4",
        borderRadius: "30px",
        width: "40%",
        "&:hover": {
            backgroundColor: "#5597F4",
        },
        "& p": {
            fontWeight: "bold",
            color: "#ffffff"
        },
    },
    checkBoxFont: {
        fontSize: "16px",
        '@media (max-width:1425px)': {
            fontSize: "12px",
        },
    },
    formBtnFont: {
        fontSize: "13px",
        fontWeight: "700",
        '@media (max-width:1425px)': {
            fontSize: "9.75px",
        },
    },
    checkBox: {
        padding: 5
    },
    checkBoxRoot: {
        padding: 0,
        color: "black"
    },
    input: {
        marginLeft: "10px",
        '&::placeholder': {
            //textOverflow: 'ellipsis !important',
            color: '#3f4144',
            opacity: 1,
            fontWeight: "700",
            fontFamily: "Open Sans",
            fontSize: "20px",
            '@media (max-width:1425px)': {
                fontSize: "15px",
            },

        }
    }

});
class ChangePassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        this.state = {
            loginPage: true,
            ...this.state,
        };
    }
    goToDashboard() {
        this.props.history.push('/dashboard');
    }
    updatePage = () => {
        this.setState({ loginPage: !this.state.loginPage, userName: "", email: "", password: "", reTypePassword: "", phone: "" })
    }
    updateValueRetype = (name, value) => {
        this.setState({ [name]: value, triggerMethodR: true });

    }
    updateValue = (name, value) => {
        this.setState({ [name]: value, triggerMethod: true });

    };
    updateTokenUrl() {
        this.setState({ tokenUrl: new URLSearchParams(location.search)?.get('token') })
    }
    // componentDidMount() {

    //     // console.log(new URLSearchParams(location.search)?.get('token'))
    // }
    // componentDidMount(): Promise<void> {
    //     // this.setState({ search: location.search })
    //     // this.setState({ tokenUrl: new URLSearchParams(this.state.search)?.get('token') })
    //     // console.log("lalalla", this.state.tokenUrl)
    //     // console.log(this.state.search)
    //     // console.log(new URLSearchParams(location.search)?.get('token'))

    // }


    render() {
        const { classes } = this.props;
        //this.setState({ search: location.search })
        //this.setState({ tokenUrl: new URLSearchParams(location.search)?.get('token') })
        //console.log("got it", this.state.tokenUrl)
        //console.log(this.state.tokenUrl)
        //console.log(this.state.search)
        //this.updateTokenUrl()
        return (
            <Grid
                container
                justifyContent="center"
                alignItems="flex-start"
                className={classes.root}
            >
                <Grid
                    item
                    container
                    direction="column"
                    xs={10} sm={6} md={4}
                    alignItems="center"
                    className={classes.form}
                >
                    <Grid item container xs={12} justifyContent="center">

                        <Typography variant="h5" className={classes.pageTitle} >
                            Change Password
                        </Typography>

                    </Grid>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.notSuccessful}
                        onClose={() => {
                            this.setState({
                                notSuccessful: false
                            });
                        }}
                        autoHideDuration={3000}
                    >
                        <Alert severity="error"
                            elevation={6} variant="filled"
                        >Token Expired. Please try Again.</Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.resetPasswordSuccessful}
                        onClose={() => {
                            this.setState({
                                resetPasswordSuccessful: false, redirectLink: true
                            });
                        }}
                        autoHideDuration={3000}
                    >
                        <Alert severity="success"
                            elevation={6} variant="filled"
                        >Your password has been reset successfully.</Alert>
                    </Snackbar>






                    <Grid item container xs={12} style={{ marginTop: "80px" }}>
                        {this.state.triggerMethod ? <>{this.setState({ triggerMethod: false }), this.validatePassword()}</> : <></>}
                        <TextField
                            size="small"
                            value={this.state.password}
                            name="password"
                            type="password"
                            onChange={(e) =>
                                this.updateValue(e.target.name, e.target.value)
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                                        <img src={lock} height="15px" />
                                    </InputAdornment>
                                ),
                                classes: { input: classes.input }
                            }}
                            fullWidth
                            placeholder="new password"
                        />
                    </Grid>

                    <Grid item container xs={12} style={{ marginTop: "20" }}>
                        {this.state.triggerMethodR ? <>{this.setState({ triggerMethodR: false }), this.matchWithPassword()}</> : <></>}
                        <TextField
                            size="small"
                            name="reTypePassword"
                            type="password"
                            value={this.state.reTypePassword}
                            onChange={(e) =>
                                this.updateValueRetype(e.target.name, e.target.value)
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                                        <img src={lock} height="15px" />
                                    </InputAdornment>
                                ),
                                classes: { input: classes.input }
                            }}
                            fullWidth
                            placeholder="confirm password"
                        />
                        {this.state.matchedPassword ? <Typography style={{ color: "red", fontSize: "12px" }}>Please match with your password</Typography> : <></>}
                    </Grid>
                    {this.state.redirectLink ? <Redirect to={'/EmailAccountRegistration'} /> : <></>}

                    <Grid item container xs={12} style={{ display: "block" }}>
                        <Typography style={{ marginBottom: "6px", fontWeight: "600", color: "#3F4144" }} className={classes.checkBoxFont}>Password Guideline</Typography>
                        <Box style={{ textAlign: "start" }}>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.eightCharracter ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} At least 8 characters-the more characters, the better</Typography>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.mixtureOfUpLow ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} A mixture of both uppercase and lowercase letters</Typography>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.mixtureOfLetterNumbers ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} A mixture of letters and numbers</Typography>
                            <Typography style={{ fontWeight: "400", color: "#AEAEAE" }} className={classes.checkBoxFont}>{this.state.oneSpecialCharacter ? <CheckBoxIcon style={{ fill: "#5597f4", marginBottom: "-5px", color: "black" }} fontSize="small" /> : <CheckBoxOutlineBlankTwoToneIcon style={{ marginBottom: "-5px", color: "black" }} fontSize="small" />} Inclusion of at least one special character e.g,! @ # ? ]</Typography>
                            <Typography style={{ margin: "0 0 35 23", fontWeight: "400", letterSpacing: "-1px", color: "#3F4144" }} className={classes.checkBoxFont}>Note: {`<`} and {`>`} are not accepted</Typography>
                        </Box>
                    </Grid>



                    <Grid item container xs={12} justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() =>
                                this.checkSubmitStatus() ? this.resetPasswordMain() : <></>

                            }
                            className={classes.formBtn}

                        >

                            <Typography variant="body2" className={classes.formBtnFont}>Submit</Typography>



                        </Button>

                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <Box style={{ height: "1px", width: "70%", backgroundColor: "black", marginTop: "10px" }}></Box>
                    </Grid>

                </Grid>
                {/* <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    className={classes.sideLeafs}
                    xs={6}
                >
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        className={classes.leafGrid}
                    >
                        <Grid item className={`${classes.leafTL} ${classes.orange}`} />
                        <Grid item className={`${classes.leafTR} ${classes.lBlue}`} />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        className={classes.leafGrid}
                    >
                        <Grid item className={`${classes.leafTR} ${classes.lBlue}`} />
                        <Grid item className={`${classes.leafTR} ${classes.blue}`} />
                        <Grid item className={`${classes.leafTR} ${classes.dBlue}`} />
                    </Grid>
                </Grid> */}
            </Grid>
        );
    }
}

export default withStyles(useStyles)(ChangePassword);

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const DeleteImage = require("../assets/image_.png");
export const check = require("../assets/check.png");
export const downArrowIcon = require("../assets/downArrow.png");
export const roundCheckIcon = require("../assets/round_check.png");

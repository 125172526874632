// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  isTransactionUpdated?: any;
  showSnackBarMessage?: any;
}

export interface S {
  // Customizable Area Start
  token: string;
  modalState: boolean;
  from: any;
  catTypes: any;
  splitData: any;
  categories: any;
  func: any;
  activeTab: number;
  id: number;
  name: any;
  splitName: any;
  splitFile: any;
  splitNotes: any;
  amount: any;
  file: any;
  note: any;
  date: any;
  category: any;
  transactionId: any;
  editFields: boolean;
  error: any;
  snackbarMessage: any;
  showSnackbar: any;
  fileModal: boolean;
  dragActive: boolean;
  deleteFilesId: any;
  uploadedFiles: any;
  disableDelete: boolean;
  saveButtonState: boolean;
  transactionType: string;
  deleteTransactionModal: boolean;
  tranDelete: boolean;
  expenseCategories: any
  expenseCategory: any
  paddingTop:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class TransactionDetailsModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  allCategoriesId: any;
  addTransactionId: any;
  getTransactionId: any;
  updateTransactionId: any;
  addSplitId: any;
  deleteTransactionId: any;
  expenseCategoriesId: any;
  getGamificationDataApiId: string = "";
  getAllCategoriesId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeTab: 0,
      deleteFilesId: [],
      from: 0,
      catTypes: '',
      transactionId: '',
      splitFile: '',
      dragActive: false,
      splitName: '',
      showSnackbar: false,
      splitNotes: '',
      splitData: [null, null],
      func: '',
      editFields: false,
      id: 0,
      modalState: false,
      date: moment(),
      categories: [],
      token: '',
      file: [],
      name: '',
      amount: '',
      category: "null",
      note: '',
      snackbarMessage: '',
      error: {},
      uploadedFiles: [],
      fileModal: false,
      saveButtonState: false,
      transactionType: "",
      disableDelete: true,
      deleteTransactionModal: false,
      tranDelete: false,
      expenseCategories: [],
      expenseCategory: 'null',
      paddingTop:"",
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.message) {
      this.setState({ snackbarMessage: responseJson.message, showSnackbar: true, saveButtonState: false })
      this.parseApiErrorResponse(responseJson);
    }
    switch (apiRequestCallId) {
      case this.allCategoriesId: {
        let data: any[] = [{ name: 'Choose Category', id: "null" }]
        responseJson.data = responseJson.data.filter((el: any) => el.name !== 'other_category')
        responseJson.data.filter((el: any) => el.values.length).forEach((el: any, index: number) => {
          let valuesData = el.values
          const tierOne = valuesData.filter((el: any) => el.tier == "tier_one")
          const tierTwo = valuesData.filter((el: any) => el.tier == "tier_two")
          const tierThree = valuesData.filter((el: any) => el.tier == "tier_three")
          const staticCat = valuesData.filter((el: any) => el.type == "transaction_category")
          valuesData = [...tierOne, ...tierTwo, ...tierThree]
          valuesData.forEach((catEl: any) => {
            data = [...data, ...catEl.values.filter((el2: any) => el2.attributes.is_active).map((el2: any) => {
              let obj: any = {}
              let name = el2.attributes.name.split('_').join(" ")
              obj.name = name
              obj.id = el2.attributes.id
              obj.catFrom = index
              return obj
            })]
          })
          this.state.transactionId && staticCat.filter((catEl: any) => catEl.attributes.is_active).forEach((catEl: any) => {
            let obj: any = {}
            let name = catEl.attributes.name.split('_').join(" ")
            obj.name = name
            obj.id = catEl.attributes.id
            obj.catFrom = index
            obj.catOf = 'static'
            data.push(obj)
          })
        })
        data = data.filter(el => el.name)
        this.setState({ categories: data }, () => {
          if (this.state.transactionId) this.getTransactionData(this.state.transactionId, this.state.from)
        })
        break;
      }
      case this.getAllCategoriesId : {
        console.log("ok googgle")
        let data: any[] = [{ name: 'Choose Category', id: "null" }]
        data = responseJson;
        data.filter((catEl: any) => catEl.attributes.is_active).forEach((catEl: any,index:number) => {
          let obj: any = {}
          let name = catEl.attributes.name.split('_').join(" ")
          obj.name = name
          obj.id = catEl.attributes.id
          obj.catFrom = index
          data.push(obj)
        })
        data = data.filter(el => el.name)
        this.setState({ categories: data }, () => {
          if (this.state.transactionId) this.getTransactionData(this.state.transactionId, this.state.from)
        })
        break;
      }
      case this.getTransactionId: {
        const keys = Object.keys(responseJson?.data?.attributes)
        const splitKey = keys.find(el => el.includes("split_"))
        let dataType = this.checkType(responseJson.data.type)
        let disableDelete = true
        let transactionType = responseJson.data.attributes[`${dataType}`].split('_').join(" ")
        if (responseJson.data.attributes[`${dataType}`] == 'capital') {
          disableDelete = false
        }
        if (responseJson.data.attributes[`${dataType}`] == 'bank') {
          transactionType = 'bank account'
        }
        if (transactionType == 'capital') {
          this.setState({ categories: this.state.categories.filter((el: any) => el.catOf !== 'static') })
        }
        let splitName = ''
        let splitNotes = ''
        let splitData: any = [null, null];
        if (splitKey && responseJson.data.attributes[splitKey].data.length) {
          splitName = responseJson.data.attributes[splitKey].data[0]?.attributes.name
          splitNotes = responseJson.data.attributes[splitKey].data[0]?.attributes.notes
          splitData = responseJson.data.attributes[splitKey].data.map((el: any) => {
            let obj: any = {}
            obj.id = el.id
            obj.amount = el.attributes.amount
            obj.transaction_category_id = el.attributes.transaction_category_id
            return obj
          })
        }
        this.setState({
          name: responseJson.data.attributes.name,
          disableDelete: disableDelete,
          transactionType: transactionType,
          amount: responseJson.data.attributes.amount,
          category: responseJson.data.attributes.transaction_category_id,
          date: moment(responseJson.data.attributes.date).format('MM/DD/YYYY'),
          note: responseJson.data.attributes.notes,
          id: responseJson.data.id,
          uploadedFiles: responseJson.data.attributes.files,
          splitName: splitName,
          splitNotes: splitNotes,
          splitData: splitData
        })
        break;
      }
      case this.addTransactionId:
      case this.addSplitId: {
        this.getGamificationData()
        this.closeModal(this.state.func)
        break;
      }
      case this.updateTransactionId: {
        this.props?.isTransactionUpdated && this.props?.isTransactionUpdated(true);
        this.closeModal(this.state.func)
        break;
      } case this.deleteTransactionId: {
        this.props?.isTransactionUpdated(true);
        this.setState({ tranDelete: true }, () => {
          this.setState({ deleteTransactionModal: false })
          this.props?.showSnackBarMessage(responseJson.message)
        })
        break;
      } case this.expenseCategoriesId: {
        let data: any[] = [{ name: 'Choose Category', id: "null" }]
        let tierOneData: any[] = []
        let tierTwoData: any[] = []
        let tierThreeData: any[] = []
        this.setState({ category: "null" })
        responseJson.data = responseJson.data.filter((el: any) => el.name !== 'other_category')
        responseJson.data.filter((el: any) => el.values.length && el.name !== 'static_categories').forEach((el: any, index: number) => {
          let valuesData = el.values
          tierOneData = [...tierOneData, valuesData.filter((el: any) => el.tier == "tier_one")]
          tierTwoData = [...tierTwoData, valuesData.filter((el: any) => el.tier == "tier_two")]
          tierThreeData = [...tierThreeData, valuesData.filter((el: any) => el.tier == "tier_three")]
        })
        const tierData = [...tierOneData, ...tierTwoData, ...tierThreeData].filter((el: any) => el.length)
        tierData.forEach((catEl: any) => {
          catEl.map((el: any) => {
            data = [...data,
            ...el.values.filter((catEl: any) => catEl.attributes.is_active).map((el2: any) => {
              let obj: any = {}
              let name = el2.attributes.name.split('_').join(" ")
              obj.name = name
              obj.id = el2.attributes.id
              return obj
            })]
          })
        })
        data = data.filter(el => el.name)
        this.setState({ expenseCategories: data })
        break;
      } case this.getGamificationDataApiId:
        break;
    }

  }
  // Customizable Area End

  // Customizable Area Start

  switchTab(tab: number) {
    this.setState({ activeTab: tab })
  }

  checkType(type: string) {
    if (type == 'income') return 'income_type'
    else return "expenses_type"
  }

  showErrorMsg = (msg: string, type: string) => {
    this.setState({ error: { type: type, message: msg } })
    setTimeout(() => this.setState({ error: {} }), 3000)
  }

  getExpenseCategories = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.expenseCategoriesId = requestMessage.messageId;
    const catOf = 'expense'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/transaction_categories?transaction_type=${catOf}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCategories = (from: any) => {
    this.setState({ categories: [] }, () => {
      const headers = {
        "Content-Type": configJSON.ApiContentType,
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.allCategoriesId = requestMessage.messageId;
      this.setState({ from: from })
      const catOf = from ? 'expense' : 'income'
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.transactionApiEndPoint}/transaction_categories?transaction_type=${catOf}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  getAllCategories = () => {
    this.setState({ categories: [] }, () => {
      const headers = {
        "Content-Type": configJSON.ApiContentType,
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllCategoriesId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.transactionApiEndPoint}/transaction_categories/all_categories`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }
  getTransactionData = (id: any, of: any) => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionId = requestMessage.messageId;
    let fetchDataOf = of ? 'expenses' : 'incomes'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/${fetchDataOf}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addTransactionData = async (of: any, context: any) => {
    if (!this.state.name || !this.state.amount) {
      this.setState({ snackbarMessage: "Fill all required fields", showSnackbar: true, saveButtonState: false })
      return 0
    }
    if ((!this.state.category || this.state.category == "null") && (!this.state.expenseCategory || this.state.expenseCategory == 'null')) {
      this.setState({ snackbarMessage: "Select Category to proceed", showSnackbar: true, saveButtonState: false })
      return 0
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const transactionType = of ? 'expenses_type' : 'income_type'

    let files: any = []
    for (let el of this.state.file) {
      let data = await this.toBase64(el)
      files.push({ data: data, filename: el.name })
    }

    const attributes: any = {
      "amount": this.state.amount,
      "transaction_category_id": (this.state.expenseCategory && this.state.expenseCategory !== "null") ? this.state.expenseCategory : this.state.category,
      "name": this.state.name,
      "date": moment(this.state.date).format('DD/MM/YYYY'),
      "notes": this.state.note,
      "files": files
    }

    if (this.state.transactionType && this.state.transactionType == 'bank account') {
      attributes[`${transactionType}`] = 'bank'
    } else if (this.state.transactionType && this.state.transactionType == "credit card payment") {
      attributes[`${transactionType}`] = "credit_card_payment"
    } else if (this.state.transactionType) {
      attributes[`${transactionType}`] = this.state.transactionType
    } else {
      attributes[`${transactionType}`] = 'capital'
    }
    if (!of) {
      attributes['reimbursement'] = (this.state.expenseCategory && this.state.expenseCategory !== "null") ? true : false
    }
    let splitData: any = this.state.splitData.filter((el: any) => el)
    if (splitData.length) {
      const totalSplitAmount = splitData.reduce((total: number, data: any) => total + parseFloat(data.amount), 0);
      if (totalSplitAmount != this.state.amount) {
        this.setState({ snackbarMessage: "Split amount not same as base amount", showSnackbar: true })
        return 0
      }
      const checkCategories = splitData.filter((el: any) => el["transaction_category_id"]).length
      const checkAmount = splitData.filter((el: any) => el["amount"]).length
      if (!this.state.splitName) {
        this.setState({ snackbarMessage: "Split name can't be empty", showSnackbar: true })
        return 0
      }
      if (splitData.length !== checkAmount || splitData.length !== checkCategories) {
        this.setState({ snackbarMessage: "Fill all categories and amounts", showSnackbar: true })
        return 0
      }

      const compareAbleVariable = of ? 14 : 40
      if (this.state.category == compareAbleVariable) {
        this.setState({ snackbarMessage: "Choose category of transaction", showSnackbar: true })
        return 0
      }
      splitData = splitData.map((el: any) => {
        el["name"] = this.state.splitName
        if (this.state.transactionType) {
          if (this.state.transactionType == 'bank account') {
            el[`${transactionType}`] = 'bank'
          } else if (this.state.transactionType == "credit card payment") {
            el[`${transactionType}`] = "credit_card_payment"
          } else {
            el[`${transactionType}`] = this.state.transactionType
          }
        } else {
          el[`${transactionType}`] = 'capital'
        }
        el["notes"] = this.state.splitNotes
        return el
      })
      const splitTransactionType = of ? 'split_expense_attributes' : 'split_incomes_attributes'
      attributes[`${splitTransactionType}`] = splitData
    }
    const data = { attributes }
    const body = { data }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.addTransactionId = requestMessage.messageId;
    let fetchDataOf = of ? 'expenses' : 'incomes'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/${fetchDataOf}/`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    let count = context.notificationCount + 1;
    context.changeNotificationCount(count)
  }

  getCurrentDate = (today: any = new Date()) => {
    // let today: any = new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };
  getGamificationData = () => {
    let p: any = moment().startOf('isoWeek')
    // let date: any = new Date(p)
    let date: any = new Date()
    const mondayOfTheWeek = this.getCurrentDate(date);
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGamificationDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gamificationEndPoint + "?date=" + mondayOfTheWeek
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updateTransactionData = async (of: any) => {
    if (!this.state?.amount || !this.state?.name) {
      this.setState({ snackbarMessage: "Fill all required fields", saveButtonState: false, showSnackbar: true })
      return 0
    }
    if ((!this.state.category || this.state.category == "null") && (!this.state.expenseCategory || this.state.expenseCategory == 'null')) {
      this.setState({ snackbarMessage: "Select Category to proceed", showSnackbar: true, saveButtonState: false })
      return 0
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    let files: any = []
    for (let el of this.state.file) {
      let data = await this.toBase64(el)
      files.push({ data: data, filename: el.name })
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const transactionType = of ? 'expenses_type' : 'income_type'
    const deletedOf: any = of ? 'expense_files' : 'income_files'
    const deleteId: any = of ? 'expense_id' : 'income_id'
    const attributes: any = {
      "amount": this.state.amount,
      "transaction_category_id": (this.state.expenseCategory && this.state.expenseCategory !== "null") ? this.state.expenseCategory : this.state.category,
      "name": this.state.name,
      "date": moment(this.state.date).format('DD/MM/YYYY'),
      "notes": this.state.note,
      "files": files,
      "delete_file": {
        [`${deletedOf}`]: this.state.deleteFilesId.map((el: any) => {
          return {
            [`${deleteId}`]: el
          }
        })
      }
    }
    attributes[`${transactionType}`] = this.state.transactionType ? this.state.transactionType == 'bank account' ? 'bank' : this.state.transactionType == "credit card payment" ? "credit_card_payment" : this.state.transactionType : 'capital'
    let splitData: any = this.state.splitData.filter((el: any) => el)
    if (!of) {
      attributes['reimbursement'] = (this.state.expenseCategory && this.state.expenseCategory !== "null") ? true : false
    }
    if (splitData.length) {
      const totalSplitAmountN = splitData.reduce((total: number, data: any) => total + parseFloat(data.amount), 0);
      if (totalSplitAmountN != this.state.amount) {
        this.setState({ snackbarMessage: "Split amount should be same as base amount ", showSnackbar: true })
        return 0
      }
      const checkAmountN = splitData.filter((el: any) => el["amount"]).length
      const checkCategories = splitData.filter((el: any) => el["transaction_category_id"]).length
      if (!this.state?.name) {
        this.setState({ snackbarMessage: "Split name can't be empty", showSnackbar: true })
        return 0
      }
      const compareAbleVariableN = of ? 14 : 40
      if (splitData?.length !== checkAmountN || splitData?.length !== checkCategories) {
        this.setState({ snackbarMessage: "Fill all categories and amounts", showSnackbar: true })
        return 0
      }
      if (this.state.category == compareAbleVariableN) {
        this.setState({ snackbarMessage: "Choose category of transaction", showSnackbar: true })
        return 0
      }
      splitData = splitData?.map((el: any) => {
        el["name"] = this.state.splitName,
          el["transaction_category_id"] = el.transaction_category_id,
          el[`${transactionType}`] = this.state.transactionType ? this.state.transactionType == 'bank account' ? 'bank' : this.state.transactionType == "credit card payment" ? "credit_card_payment" : this.state.transactionType : 'capital',
          el["amount"] = el.amount,
          el["notes"] = this.state.splitNotes
        return el
      })
      const splitTransactionType = of ? 'split_expense_attributes' : 'split_incomes_attributes'
      attributes[`${splitTransactionType}`] = splitData
    }
    const data = { attributes }
    const body = { data }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.updateTransactionId = requestMessage.messageId;
    let fetchDataOf = of ? 'expense' : 'income'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/update_${fetchDataOf}/${this.state.transactionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteTransactionData = async (of: any) => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTransactionId = requestMessage.messageId;
    let fetchDataOf = of ? 'expenses' : 'incomes'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/${fetchDataOf}/${this.state.transactionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formDeleteMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  closeModal = (fun: any) => {
    fun(false, null)
  }
  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  addSplitColumn = () => {
    this.setState({ splitData: [...this.state.splitData, null] })
  }
  updateSplitData = (value: any, from: any, index: any) => {
    let splitIndexData = this.state.splitData
    if (from == 'amount' && parseFloat(this.state.amount) < parseFloat(value)) return 0
    if (splitIndexData[index]) {
      splitIndexData[index][`${from}`] = value
    } else {
      const obj: any = {}
      obj[`${from}`] = value
      splitIndexData[index] = obj
    }
    if (from == 'amount') {
      if (!this.state.amount) {
        this.setState({ snackbarMessage: "Base amount is empty", showSnackbar: true })
        return 0
      }
    }
    this.setState({ splitData: [...splitIndexData] })
  }
  formatBytes = (bytes: any) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  updateDragActive = () => {
  }
  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover" || e.type === 'dragleave') {
      this.setState({ dragActive: true });
    }
  }
  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = [...e.dataTransfer.files].map((el: any) => {
        return el
      })
      this.setState({ file: [...this.state.file, ...files] })
    }
  };

  handleFile = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const files = [...e.target.files].map((el: any) => {
        return el
      })
      this.setState({ file: [...this.state.file, ...files] })
    }
  }

  deleteFile = (file: any, id: any) => {
    if (id) {
      let deletedFile = this.state.deleteFilesId
      deletedFile.push(id)
      this.setState({ uploadedFiles: this.state.uploadedFiles.filter((el: any) => el.id != id), deleteFilesId: deletedFile })
    } else {
      const files = this.state.file.filter((el: any) => file.name != el.name)
      this.setState({ file: files })
    }
  }

  updateCategory = (value: any, reimbursement: boolean, returnToIncome: boolean) => {
    console.log(value, reimbursement, returnToIncome, this.state.expenseCategory)
    return new Promise((resolve, reject) => {
      if (!value || value == 'null') {
        resolve(false)
      }
      else if (returnToIncome) {
        this.setState({ category: value, expenseCategory: "null", categories: this.state.expenseCategories, expenseCategories: this.state.categories }, () => {
          this.setState({ expenseCategories: this.state.expenseCategories.filter((el: any) => el.name !== "income categories") }, () => {
            resolve(true)
          })
        })
      } else {
        let expenseData = this.state.categories.find((el: any) => el.name == 'expense reimbursement')
        if (value == expenseData?.id && this.state.expenseCategory) {
          this.setState({ category: "null", categories: this.state.expenseCategories, expenseCategories: this.state.categories }, () => {
            const incomeCategoriesArray = this.state.categories
            incomeCategoriesArray.splice(3, 0, { name: "income categories" })
            this.setState({ categories: incomeCategoriesArray }, () => {
              resolve(true)
            })
          })
        } else if (reimbursement) {
          this.setState({ expenseCategory: value }, () => {
            resolve(true)
          })
        } else if (value == expenseData?.id) {
          this.setState({ category: "null", expenseCategory: "null" }, () => {
            resolve(true)
          })
        } else {
          this.setState({ category: value, expenseCategory: "null" }, () => {
            resolve(true)
          })
        }
      }
    })
  }

  // Customizable Area End
}
// Customizable Area End

import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  Pressable,
  // Customizable Area Start
  FlatList,
  Dimensions,
  TouchableHighlight,
  // Customizable Area End
} from "react-native";
// Customizable Area Start
import Carousel from '../../../components/src/Carousel/Carousel';
import { scaledSize } from "framework/src/Utilities";
import { check, downArrowIcon, roundCheckIcon, rightArrow } from "./assets";
import CfGrabshowTransactionalInfoController, {
  Props,
  TransactionDataObjectType,
  ExpenseItemType
} from "./CfGrabshowTransactionalInfoController";
import Button from "../../../components/src/Button";
import SingleTransactionModal from "../../../components/src/SingleTransactionModal";
import CfGrabTransactionalInfoMainCompo from "../../../components/src/CfGrabShowTransactionalInfoMainCompo";
// Customizable Area End

export default class CfGrabshowTransactionalInfo extends CfGrabshowTransactionalInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <CfGrabTransactionalInfoMainCompo 
          carousel={this.carousel}
          handleSnapToItem={this.handleSnapToItem}
          closeModal={this.closeModal}
          deleteCameraImage={this.deleteCameraImage}
          deleteImage={this.deleteImage}
          handleBackPress={this.handleBackPress}
          handleCameraPress={this.handleCameraPress}
          handleDeleteImage={this.handleDeleteImage}
          handleDeleteSelectedImage={this.handleDeleteSelectedImage}
          handleImageLibrary={this.handleImageLibrary}
          handleInputChange={this.handleInputChange}
          handleNoteChange={this.handleNoteChange}
          handlePressAddDetails={this.handleAddDetails}
          handleSingleTransactionModal={this.handleSingleTransactionModal}
          onClickDownArrow={this.onClickDownArrow}
          onPressAddToReport={this.handleAddtoReport}
          setCategoryItem={this.setCategoryItem}
          state={this.state}
          check={check} 
          downArrowIcon={downArrowIcon} 
          rightArrow={rightArrow}
          roundCheckIcon={roundCheckIcon}
          handleSpiltTransaction={this.handleSpiltTransaction}
          handleEditSplit={this.handleEditSplit}
          handleEditDetails={this.handleEditDetails}
          handlePressSplitTransaction={this.handlePressSplitTransaction}
        />
            {this.state.isShowSingleTransactionModal
              && <SingleTransactionModal
                state={this.state}
                handleSingleTransactionModal={this.handleSingleTransactionModal}
                handleCameraPress={this.handleCameraPress}
                handleAddDetails={this.handleAddDetails}
                closeModal={this.closeModal}
                handleNoteChange={this.handleNoteChange}
                handleDeleteImage={this.handleDeleteImage}
                deleteImage={this.deleteImage}
                handleDeleteSelectedImage={this.handleDeleteSelectedImage}
                handleImageLibrary={this.handleImageLibrary}
                deleteCameraImage={this.deleteCameraImage}
                handleAddtoReport={this.handleAddtoReport}
              />}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  button: {
    top: 10,
    borderRadius: 20,
    padding: 10,
    width: 100,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  categoryFlatListView: {
    marginTop: scaledSize(15),
    // height: scaledSize(250)
  },
  categoryItemContainer: {
    width: scaledSize(284),
    minHeight: scaledSize(33),
    borderRadius: scaledSize(44),
    backgroundColor: "#FFF",
    shadowColor: "#000000",
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 20,
    alignSelf: "center",
    marginVertical: scaledSize(7),
    elevation: 7,
    alignItems: "center",
    flexDirection: "row"
  },
  categoryText: {
    fontSize: scaledSize(14),
    lineHeight: scaledSize(21),
    fontFamily: "OpenSans-Bold",
    color: "#0F172A",
    marginLeft: scaledSize(22),
    width: scaledSize(224)
  },
  checkIconStyle: {
    height: scaledSize(21),
    width: scaledSize(21),
  },
  carouselView: {
    marginTop: scaledSize(10)
  },
  rightArrowIcon: {
    height: scaledSize(23),
    width: scaledSize(23)
  },
  leftArrowIconView: {
    position: 'absolute',
    top: scaledSize(65),
    left: scaledSize(80),
    paddingLeft: 6
  },
  leftArrowIcon: {
    height: scaledSize(23),
    width: scaledSize(23),
    transform: [{ rotate: '180deg' }]
  },
  rightArrowIconView: {
    position: 'absolute',
    top: scaledSize(65),
    left: scaledSize(249),
  },
  transactionItemContainer: {
    height: scaledSize(140),
    width: scaledSize(140),
    borderRadius: scaledSize(70),
    backgroundColor: "#FF9933",
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: "center",
    opacity: 1,
    marginTop: scaledSize(13),
  },
  innerTransationItemContainer: {
    height: scaledSize(124),
    width: scaledSize(124),
    borderRadius: scaledSize(62),
    backgroundColor: "#FFFFFF",
    alignItems: "center",
  },
  inactiveTransactionItemContainer: {
    height: scaledSize(126),
    width: scaledSize(126),
    borderRadius: scaledSize(63),
    backgroundColor: "#FF9933",
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: "center",
    marginTop: scaledSize(17),
    opacity: 0.8,
  },
  inactiveInnerTransactionItemContainer: {
    height: scaledSize(112),
    width: scaledSize(112),
    borderRadius: scaledSize(56),
    backgroundColor: "#FFFFFF",
    alignItems: "center"
  },
  transactionDateText: {
    fontSize: scaledSize(12),
    fontFamily: "OpenSans-Bold",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(109),
    marginTop: scaledSize(26)
  },
  transactionPriceText: {
    fontSize: scaledSize(15),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#000",
    width: scaledSize(107),
    marginTop: scaledSize(5),
  },
  transactionTitleText: {
    fontSize: scaledSize(12),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#3876F1",
    width: scaledSize(120),
    // marginTop: scaledSize(5),
    textTransform: "uppercase"
  },
  transactionCreditText: {
    fontSize: scaledSize(7),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(12),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(90),
    textTransform: "uppercase"
  },
  roundCheckIcon: {
    height: scaledSize(44),
    width: scaledSize(44),
    position: "absolute",
    alignSelf: "center",
    top: scaledSize(0),
    zIndex: 1000
  }
});
// Customizable Area End

//@ts-nocheck
import React from "react";
// Customizable Area Start	
import {
    withStyles,
    Checkbox,
    TextField,
    Typography,
    InputAdornment,
    Grid,
    Button,
    Modal,
    Container,
    Card,
    CardContent,
    CardActions,
    Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Link, Redirect } from "react-router-dom";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import TopNav from "../../../components/src/TopNav";
import DoneIcon from '@material-ui/icons/Done';
import user from "../assets/user.png";
import { number } from "yup";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import TimeCalculator from "timecalup"
// Customizable Area End	
// Customizable Area Start	
const useStyles = (theme) => ({

    root: {
        paddingTop: "60px",
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
    },
    pageTitle: {
        fontWeight: "700",
        fontSize: "40px",
        display: "inline-block",
        position: "relative",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "20%",
            height: "5px",
            bottom: "0",
            left: "40%",
            borderBottom: "3px solid #B5D8FD",
        },
        '@media (max-width:1425px)': {
            fontSize: "30px"

        },
    },
    underLine: {
        fontWeight: "bold",
        position: "relative",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "50%",
            height: "5px",
            bottom: "0",
            left: "25%",
            borderBottom: "0.5px solid #3F4144",
        },
    },

    normalUrl: {
        color: "#7BAEF7",
        textDecoration: "none",
    },
    cardTitleGray: {
        fontSize: "31px",
        fontWeight: "800",
        color: "#A8AAAE",
        '@media (max-width:1425px)': {
            fontSize: "23.25px"

        },
    },
    cardTitleBlue: {
        fontSize: "36px",
        fontWeight: "700",
        color: "#5696F4",
        '@media (max-width:1425px)': {
            fontSize: "27px"

        },
    },
    cardTitleBlueSelected: {
        fontSize: "51px",
        fontWeight: "800",
        color: "#5696F4",
        lineHeight: "1",
        '@media (max-width:1425px)': {
            fontSize: "38.25px"

        },
    },
    cardRate: {
        fontSize: "45px",
        fontWeight: "600",
        color: "#000000",
        '@media (max-width:1425px)': {
            fontSize: "33.75px"

        },
    },
    cardRateText: {
        fontSize: "34px",
        fontWeight: "400",
        color: "#000000",
        '@media (max-width:1425px)': {
            fontSize: "25.5px"

        },
    },
    cardRateTextGrey: {
        fontSize: "18px",
        fontWeight: "400",
        color: "#AEAEAE",
        '@media (max-width:1425px)': {
            fontSize: "13.5px"

        },
    },
    cardBodyText: {
        fontSize: "17px",
        fontWeight: "600",
        color: "#000000",
        '@media (max-width:1425px)': {
            fontSize: "12.75px"

        },
    },

    textBtn: {
        padding: "0 5px",
        fontWeight: "800",
        fontSize: "30px",
        cursor: "pointer",
    },
    border: {
        border: "3px solid #5696F4"
    },
    formBtn: {
        padding: "3px 8px",
        textTransform: "none",
        backgroundColor: "#5597F4",
        borderRadius: "30px",
        width: "50%",
        "&:hover": {
            backgroundColor: "#5597F4",
        },
        "& p": {
            fontWeight: "bold",
            color: "#ffffff",
            fontSize: "13px",
            '@media (max-width:1425px)': {
                fontSize: "9.75px"

            },
        },
    },



});
// Customizable Area End	
class SignupSubscriptionPlan extends EmailAccountRegistrationController {
// Customizable Area Start	

    constructor(props: any) {
        super(props);
        this.state = {
            loginPage: true,
            end_time: localStorage.getItem("endTime"),
            ...this.state,
        };
    }
    goToDashboard() {
        this.props.history.push('/dashboard');
    }
    updatePage = () => {
        this.setState({ loginPage: !this.state.loginPage, userName: "", email: "", password: "", reTypePassword: "", phone: "" })
    }
    componentDidMount() {
        this.getAllPackages(0)
        this.updateTime();
    }

    updateTime = () => {
        new TimeCalculator(this.state.end_time).updateTime((d) => {
            this.setState({ remainingTime: d[0], isExpired: d[1] })
        })
    }

// Customizable Area End	

    render() {
// Customizable Area Start	

        const { classes } = this.props;
        return (
            <Grid
                container
                justifyContent="center"
                alignItems="flex-start"
                className={classes.root}
            >
                <Grid style={{ marginLeft: "80%", }}>
                    {this.state.isExpired ? (
                        <Typography variant="h6">
                            Time has expired.
                        </Typography>
                    ) : (
                        <Typography variant="h6" >
                            Time remaining:{Array.isArray(this.state.remainingTime) ? (
                                this.state.remainingTime.map((d, i) => <u key={i}> {d} {i === 0 && ':'}</u>)
                            ) : null}
                        </Typography>
                    )}
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.errorVerification}
                    onClose={() => {
                        this.setState({
                            errorVerification: false
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="error"
                        elevation={6} variant="filled"
                    >{this.state.errorVerificationMessage}</Alert>
                </Snackbar>
                {/* <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.errorMessage1}
            onClose={() => {
              this.setState({
                errorMessage1: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity="error"
              elevation={6} variant="filled"
            >Account not activated.</Alert>
          </Snackbar> */}
                <Grid
                    item
                    container
                    direction="column"
                    xs={12}
                    alignItems="center"
                    className={classes.form}
                    style={{ maxWidth: "100%" }}
                >

                    <Grid>
                        <img src={user} style={{ height: "59", width: "56.05" }} />
                    </Grid>
                    <Grid>
                        <Typography variant="h6">
                            STEP 2 OF 3

                        </Typography>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">

                        <Typography variant="h5" className={classes.pageTitle}>
                            Choose your subscription plan.
                        </Typography>

                    </Grid>

                    <Grid item container xs={12} justifyContent="center" style={{
                        background: "linear-gradient(to bottom, #EFEFEF 0%, #EFEFEF 40%, #EFEFEF 90%,#ffffff 90%, #ffffff 100%)",
                        maxWidth: "100%",
                        marginTop: "30px",
                        padding: "40 0 0 0"
                    }} alignItems="flex-end">
                        {this.state.selected === 2 ?
                            <>
                                <Grid item xs>
                                    <Card  data-testid="planbtn" style={{ cursor: "pointer", textAlign: "center", width: "40%", height: "auto", marginLeft: "52%", borderRadius: "15px" }} className={this.state.subscriptionPlan == 1 ? classes.border : <></>} onClick={() => { this.setState({ selected: 1, subscriptionPlan: 1 }) }}>
                                        <CardContent>
                                            <Typography className={classes.cardTitleGray} style={{ margin: "5 0 10 0" }}>{this.state.packageType1}</Typography>
                                            <Typography className={classes.cardTitleBlue}>{this.state.packageName1} </Typography>
                                            <Typography className={classes.cardRate}>${this.state.packageRate1}<span className={classes.cardRateText}>/mo</span></Typography>
                                            <Typography className={classes.cardRateTextGrey}>+ applicable taxes</Typography>
                                            <Typography style={{ margin: "30 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /> <span className={classes.cardBodyText}>Cancel anytime</span></Typography>

                                        </CardContent>
                                        <CardActions style={{ margin: "0 0 50 0" }}>
                                            <Button onClick={() => { this.setState({ subscriptionPlan: 1, selected: 1 }) }} className={classes.formBtn} style={{ margin: "auto" }}><Typography>CHOOSE PLAN</Typography></Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs>

                                    <Card style={{ cursor: "pointer", textAlign: "center", width: "40%", height: "auto", borderRadius: "15px", marginLeft: "3%" }} className={this.state.subscriptionPlan == 2 ? classes.border : <></>} onClick={() => { this.setState({ selected: 2, subscriptionPlan: 2 }) }}>
                                        <CardContent>
                                            <Typography style={{ margin: "10 0 30 0" }} className={classes.cardTitleGray}>{this.state.packageType2}</Typography>
                                            <Typography className={classes.cardTitleBlueSelected}>{this.state.packageName2}!</Typography>
                                            <Typography className={classes.cardRate}>${this.state.packageRate2}<span className={classes.cardRateText}>/year</span></Typography>
                                            <Typography className={classes.cardRateTextGrey}>+ applicable taxes</Typography>
                                            <Typography style={{ margin: "30 0 10 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /><span className={classes.cardBodyText}> Save money</span></Typography>
                                            <Typography style={{ margin: "10 0 30 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /><span className={classes.cardBodyText}> One time payment</span></Typography>
                                        </CardContent>
                                        <CardActions style={{ margin: "0 0 50 0" }}>
                                            <Button className={classes.formBtn} style={{ margin: "auto" }} onClick={() => { this.setState({ subscriptionPlan: 2, selected: 2 }) }}><Typography>CHOOSE PLAN</Typography></Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs>
                                    <Card  data-testid="planbtn1"
                                        style={{ cursor: "pointer", textAlign: "center", width: "40%", height: "auto", marginLeft: "52%", borderRadius: "15px" }} className={this.state.subscriptionPlan == 1 ? classes.border : <></>} onClick={() => { this.setState({ selected: 1, subscriptionPlan: 1 }) }}>
                                        <CardContent>
                                            <Typography style={{ margin: "5 0 80 0" }} className={classes.cardTitleGray}>{this.state.packageType1}</Typography>
                                            <Typography className={classes.cardTitleBlue}>{this.state.packageName1} </Typography>
                                            <Typography className={classes.cardRate}>${this.state.packageRate1}<span className={classes.cardRateText}>/mo</span></Typography>
                                            <Typography className={classes.cardRateTextGrey}>+ applicable taxes</Typography>
                                            <Typography style={{ margin: "20 0 70 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /> <span className={classes.cardBodyText}>Cancel anytime</span></Typography>

                                        </CardContent>
                                        <CardActions style={{ margin: "0 0 50 0" }}>
                                            <Button onClick={() => { this.setState({ subscriptionPlan: 1, selected: 1 }), console.log("dekhooooooo4321"), this.validateNewAccount(0) }} className={classes.formBtn} style={{ margin: "auto" }}><Typography>CHOOSE PLAN</Typography></Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs>

                                    <Card style={{ cursor: "pointer", textAlign: "center", width: "40%", height: "auto", borderRadius: "15px", marginLeft: "3%" }} className={this.state.subscriptionPlan == 2 ? classes.border : <></>} onClick={() => { this.setState({ selected: 2, subscriptionPlan: 2 }) }}>
                                        <CardContent>
                                            <Typography className={classes.cardTitleGray}>{this.state.packageType2}</Typography>
                                            <Typography className={classes.cardTitleBlueSelected} style={{ fontSize: "41px" }}>{this.state.packageName2}!</Typography>
                                            <Typography className={classes.cardRate}>${this.state.packageRate2}<span className={classes.cardRateText}>/year</span></Typography>
                                            <Typography className={classes.cardRateTextGrey}>+ applicable taxes</Typography>
                                            <Typography style={{ margin: "30 0 10 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /><span className={classes.cardBodyText}> Save money</span></Typography>
                                            <Typography style={{ margin: "10 0 30 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /><span className={classes.cardBodyText}> One time payment</span></Typography>
                                        </CardContent>
                                        <CardActions style={{ margin: "0 0 50 0" }}>
                                            <Button className={classes.formBtn} style={{ margin: "auto" }} onClick={() => { this.setState({ subscriptionPlan: 2, selected: 2 }) }}><Typography>CHOOSE PLAN</Typography></Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </>}



                    </Grid>


                    {/* {this.state.goToDashboard && this.state.loginSuccess ? <Redirect to={'/dashboard'} /> : <></>} */}
                    {/* {this.state.goToChangePassword && this.state.loginSuccess ? <Redirect to={'/changepassword'} /> : <></>} */}


                    {this.state.goToInitialPayment ? <Redirect to={'/initialPayment'} /> : <></>}




                </Grid>
                <Grid item container xs={11} justifyContent="flex-end" style={{ paddingBottom: "40" }}>
                    <Button
                        variant="contained"
                        data-testid="continubtn"
                        onClick={() =>
                            this.passValue()


                        }
                        className={classes.formBtn}
                        style={{ width: "auto" }}
                    >

                        <Typography variant="body2">CONTINUE</Typography>

                    </Button>
                </Grid>

            </Grid>

        );
// Customizable Area End	
}
}
// Customizable Area Start
export default withStyles(useStyles)(SignupSubscriptionPlan);
export {SignupSubscriptionPlan}
// Customizable Area End	

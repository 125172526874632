Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";
exports.loginAPIEndPoint = "login/login";
exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
//customizable area start
exports.emailActivationEndPoints = "account/accounts";
exports.httpPostMethod = "POST";
exports.verifyAccountSignup = "account_block/accounts/send_email_verification_link";
exports.forgotPasswordAPiContentType = "application/json";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.getAllPlans = "bx_block_plan/plans";
//customizable area ends
// Customizable Area Start
exports.signUpWebUrl = "https://neeuhfinalbuildcard-125853-react-native.b125853.dev.eastus.az.svc.builder.cafe/";
exports.visitNeeuh = "VISIT NEEUH.COM";
exports.signIn = "SIGN IN";
exports.signUp = "Sign Up";
exports.createAccount = "Please navigate to our website to create an account.";
exports.returnTo = "Return to";
// Customizable Area End

//@ts-nocheck
import React from 'react'
import ReportsController from './ReportsController.web'
import TransactionDetailsModalWeb from './TransactionDetailsModal.web'
import {
  Typography,
  Button,
  withStyles,
  Grid,
  Box,
  Container,
  Divider,
  TextField,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputBase,
  Icon,
  Link,
  Snackbar,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Scrollbars } from 'react-custom-scrollbars'
import car from '../assets/car1.png'
import { NavLink } from 'react-router-dom'
import GetApp from '@material-ui/icons/GetApp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import fileDownload from 'js-file-download'

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: '15px',
  },
  head: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  body: {
    // borderBottom: 'none',
    textAlign: 'end',
    border: '1px solid #bcbcbc',
    fontSize: '16px',
    paddingRight: '10px',
    '@media (max-width:1425px)': {
      fontSize: '12px',
    },

  },
}))(TableCell)

const StyledTableCellLeft = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: '15px',
  },
  head: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  body: {
    // borderBottom: 'none',
    border: '1px solid #bcbcbc',
    fontSize: '16px',
    paddingRight: '10px',
    '@media (max-width:1425px)': {
      fontSize: '12px',
    },

  },
}))(TableCell)

const GreenSelectFieldForTable = withStyles((theme) => ({
  input: {
    // backgroundColor: "blue",
    // color: "#000",
    // borderRadius: "1px",
    // border: "1px solid #f0f",
    // padding: "4px"
  },
  root: {
    // backgroundColor: "red",
    color: '#000',
    borderRadius: '1px',
    border: '1px solid #bcbcbc',
    // padding: "4px 0px",
    paddingLeft: '8px',
    borderRadius: '4px',
  },
  select: {
    color: '#000',
    '&:focus': {
      backgroundColor: 'green',
      color: '#000',
      borderRadius: '1px',
      border: '1px solid #bcbcbc',
      // padding: "4px 0px",
      paddingLeft: '8px',
    },
  },
  icon: {
    color: '#000',
    marginLeft: '10px',
    backgroundColor: 'red',
  },
}))(InputBase)

const useStyles = (theme) => ({
  demo: {
    backgroundColor: 'green',
  },
  transactionLeafOverCircle: {
    color: '#ffffff',
    fontSize: '18px',
    backgroundColor: '#000000',
    width: '30px',
    height: '30px',
    borderRadius: '12px 0',
    position: 'absolute',
    right: '0px',
    top: '25px',
    fontWeight: 'bold',
  },
  transactionCircleButton: {
    marginLeft: '45px',
    borderRadius: '20px',
    fontSize: '0.3rem',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: '1px 15px 0px 15px',
    backgroundColor: 'white',
  },
  transactionButtonText1: {
    paddingTop: '55px',
    paddingLeft: '35px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.2 rem',
  },
  transactionButtonText2: {
    paddingBottom: '15px',
    paddingLeft: '45px',
    color: 'white',
    fontWeight: 'bold',
  },

  fullDisplay: {
    //height: "100%",
    width: '100%',
  },
  fullDisplayCircle: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    maxWidth: '100%',
  },
  mileageCircle: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    backgroundColor: '#ff9933',
    border: '2px solid #ff9933',
  },
  mileageCircleWithoutContent: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    border: '2px solid #ff9933',
  },
  mileageCircleDate: {
    color: '#d3d3d3',
    fontSize: '0.5rem',
  },
  mileageCircleDay: {
    fontSize: '0.5rem',
    fontWeight: 'bold',
  },
  gridItems: {
    maxWidth: '100%',
  },
  gridItemsPadding: {
    padding: '0px',
    maxWidth: '100%',
    textAlign: 'center',
  },
  containerBox: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
  },
  title: {
    textAlign: 'center',
  },
  transactionsFont: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  navLinkMain: {
    textDecoration: 'none',
    color: 'black',
  },
  equalWindow: {
    minHeight: window.innerHeight, //"45vh",
    // height: window.innerHeight / 1.7,
    //paddingBottom: "10px"
  },
  displayNone: {
    display: 'none',
  },
  displayNormal: {
    display: '',
  },
  mileageRoundTextMiles: {
    fontSize: '0.7em',
  },
  mileageRoundText: {
    fontSize: '0.6rem',
    lineHeight: '1',
  },
  rightLeafLetter: {
    fontSize: '0.8rem',
    color: '#bdbdbd',
    paddingBottom: '15px',
    paddingTop: '15px',
  },
  boldFontbutton: {
    textTransform: 'none',
    // marginLeft: "45px",
    borderRadius: '20px',
    // fontSize: "0.3rem",
    fontWeight: 'bold',
    padding: '0px',
    // backgroundColor: "white"
  },
  button: {
    textTransform: 'none',
    // marginLeft: "45px",
    borderRadius: '20px',
    // fontSize: "0.3rem",
    padding: '1px 25px 1px 25px',
    // backgroundColor: "white"
  },
  boldFont: {
    fontWeight: 'bold',
    fontSize: '13px',
    '@media (max-width:1425px)': {
      fontSize: '9.75px',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownFocused: {
    width: '200px',
  },
  datePicker: {
    // backgroundColor:'red'
    // MuiInputBase-input MuiOutlinedInput-input
    // padding: "7.5px 14px"
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px',
    },
  },
  formDate: {
    '& div': {
      minWidth: 0,
      padding: theme.spacing(0.42),
      // paddingRight: theme.spacing(3),
      borderRadius: '4px',
      borderColor: '#bcbcbc',
      // border: "none !important"
    },
  },
  modalLink: {
    textTransform: 'capitalize',
    color: '#000000',
    // '&:hover': {
    //   cursor: 'pointer',
    // },
  },
  cursorHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonLayout: {
    backgroundColor: '#5597f4',
    borderRadius: '30px',
    color: 'white',
    height: '25px',
    fontSize: '13px',
    '@media (max-width:1425px)': {
      fontSize: '9.75px',
      height: '18.75px',
      padding: '3.75px 15px 3.75px 15px',
      margin: '11.25px 0px 11.25px 0px',
      borderRadius: '22.5px',
    },
    fontWeight: 'bold',
    padding: '5px 20px 5px 20px',
    margin: '15px 0px 15px 0px',
    '&:hover': {
      backgroundColor: '#5597f4',
    },
  },
  BoldReportsSub: {
    fontSize: '18px',
    fontWeight: '700',

    '@media (max-width:1425px)': {
      fontSize: '13.5px',
    },
  },
  textOnly: {
    fontSize: '18px',
    fontWeight: '400',
    '@media (max-width:1425px)': {
      fontSize: '13.5px',
    },
  },
  tabButton: {
    borderRadius: '20px',
    border: '1px solid #ffffff',
  },
  incomeButton: {
    // width: "80%",
    padding: '3 50',
    background: '#5696F4',
    // border: "1px solid #5696F4",
    '& h6': {
      color: '#ffffff',
    },
    '&:hover': {
      background: '#5696F4',
    },
  },
  topDiv: {
    margin: '10px 5px',
  },
  tableHeadings: {
    fontSize: '20px',
    fontWeight: '700',
    '@media (max-width:1425px)': {
      fontSize: '15px',
    },
  },
  reportPeriod: {
    fontSize: '20px',
    fontWeight: '600',
    '@media (max-width:1425px)': {
      fontSize: '15px',
    },
  },
  categorySelected: {
    background: "#5696F4",
    color: "white"
  },
  categorySelectedName: {
    color: "#fff",
    fontSize: '18px',
    fontWeight: '700',

    '@media (max-width:1425px)': {
      fontSize: '13.5px',
    },
  },
  categoryUnselectedName: {
    fontSize: "18px",
    color: "#5696F4",
    fontWeight: 400,
    '@media (max-width:1425px)': {
      fontSize: '13.5px',
    },
  },
  BoldTotalOperatingExpenses: {
    fontSize: '18px',
    fontWeight: '700',
    color: "#fff",

    '@media (max-width:1425px)': {
      fontSize: '13.5px',
    },
  },
  //   categoryUnselectedPrice:{
  //     color : "#5696F4",
  //     fontWeight : 400,
  // },
})

class ReportsWeb extends ReportsController {
  super()
  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.taxYear) {
      this.setState({ reportPeriodStartDate: moment(`01/01/${nextProps.taxYear}`), reportPeriodEndDate: moment(`12/31/${nextProps.taxYear}`) },
        () => {
          this.resetDateAndGetReport()
        })
    }
  }

  getSelectedCategoryAmount = (amount) => {
    if (amount == 0) {
      return "$0.00"
    } else {
      return this.nf.format(amount)
    }
  }

  getSelectView = (classes: any) => {
    // if (this.state.reportPeriod == 'Custom') {
    //   return (
    //     <Select
    //       variant="outlined"
    //       disabled
    //       className={classes.dropdownFocused}
    //       value={this.state.reportPeriod}
    //       onChange={(event: any) => {
    //         this.setState({
    //           reportPeriod: event.target.value
    //         })
    //       }}
    //       input={<GreenSelectFieldForTable />}
    //     >
    //       <MenuItem value={"Custom"}>Custom</MenuItem>
    //     </Select>
    //   )
    // } else {
    return (
      <Select
        variant="outlined"
        // disabled={this.state.reportPeriod == 'Custom'}
        IconComponent={KeyboardArrowDownIcon}
        className={classes.dropdownFocused}
        value={this.state.reportPeriod}
        onChange={(event: any) => {
          switch (event.target.value) {
            case 'Current Month':
              const startOfMonth = moment().startOf('month')
              const endOfMonth = moment().endOf('month')

              this.setState(
                {
                  reportPeriodStartDate: new Date(startOfMonth),
                  reportPeriodEndDate: new Date(endOfMonth),
                },
                () => {
                  console.log('start .....', this.state.reportPeriodStartDate)
                  console.log('end .....', this.state.reportPeriodEndDate)
                },
              )
              break
            case 'Current Quarter':
              const startOfQuarter = moment().startOf('quarter')
              const endOfQuarter = moment().endOf('quarter')

              this.setState(
                {
                  reportPeriodStartDate: new Date(startOfQuarter),
                  reportPeriodEndDate: new Date(endOfQuarter),
                },
                () => {
                  console.log('start .....', this.state.reportPeriodStartDate)
                  console.log('end .....', this.state.reportPeriodEndDate)
                },
              )

              break
            case 'Year to Date':
              const yearStartDate = moment().startOf('year')
              // const currentDate = new Date();
              this.setState(
                {
                  reportPeriodStartDate: new Date(yearStartDate),
                  reportPeriodEndDate: new Date(),
                },
                () => {
                  console.log('start .....', this.state.reportPeriodStartDate)
                  console.log('end .....', this.state.reportPeriodEndDate)
                },
              )
              break
            case 'Previous Month':
              const previousMonthStartDate = moment()
                .subtract(1, 'M')
                .startOf('month')
              const previousMonthEndDate = moment()
                .subtract(1, 'M')
                .endOf('month')
              this.setState(
                {
                  reportPeriodStartDate: new Date(previousMonthStartDate),
                  reportPeriodEndDate: new Date(previousMonthEndDate),
                },
                () => {
                  console.log('start .....', this.state.reportPeriodStartDate)
                  console.log('end .....', this.state.reportPeriodEndDate)
                },
              )
              break
            case 'Previous Quarter':
              const previousQuarterStartDate = moment()
                .subtract(1, 'Q')
                .startOf('quarter')
              const previousQuarterEndDate = moment()
                .subtract(1, 'Q')
                .endOf('quarter')
              this.setState(
                {
                  reportPeriodStartDate: new Date(previousQuarterStartDate),
                  reportPeriodEndDate: new Date(previousQuarterEndDate),
                },
                () => {
                  console.log('start .....', this.state.reportPeriodStartDate)
                  console.log('end .....', this.state.reportPeriodEndDate)
                },
              )
              break
            case 'Previous Year':
              const previousYearStartDate = moment()
                .subtract(1, 'years')
                .startOf('year')
              const previousYearEndDate = moment()
                .subtract(1, 'years')
                .endOf('year')
              this.setState(
                {
                  reportPeriodStartDate: new Date(previousYearStartDate),
                  reportPeriodEndDate: new Date(previousYearEndDate),
                },
                () => {
                  console.log('start .....', this.state.reportPeriodStartDate)
                  console.log('end .....', this.state.reportPeriodEndDate)
                },
              )
              break
            default:
              break
          }
          this.setState({
            reportPeriod: event.target.value,
          })
        }}
        input={<GreenSelectFieldForTable />}
      >
        {/* <MenuItem value="" disabled>
            <em>Custom</em>
          </MenuItem> */}
        {this.state.reportPeriodData.map((element) => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
        {/* <MenuItem value={"Current Month"}>Current Month</MenuItem>
          <MenuItem value={"Current Quarter"}>Current Quarter</MenuItem>
          <MenuItem value={"Year to Date"}>Year to Date</MenuItem>
          <MenuItem value={"Previous Month"}>Previous Month</MenuItem>
          <MenuItem value={"Previous Quarter"}>Previous Quarter</MenuItem>
          <MenuItem value={"Previous Year"}>Previous Year</MenuItem> */}
      </Select>
    )
    // }
  }

  getCategoryNameForCapital = (item) => {
    if (item?.name?.split) {
      return item?.name?.split('_').join(" ")
    }

    if (item?.name == "income_or_sales") {
      return "Income or Sales"
    }
    if (item?.name == "state_tax") {
      return "State Taxes"
    }
    if (item?.name == "federal_tax") {
      return "Federal Taxes"
    }
    if (item?.name == "credit_card_payment") {
      return "Credit Card Payment"
    }
    if (item?.name == "roth_retirement_contributions") {
      return "Roth Retirement Contributions"
    }
    if (item?.transaction_type == 'expense' && item?.name == "capital") {
      return "Capital Distributions"
    }
    if (item?.transaction_type == 'income' && item?.name == "capital") {
      return "Capital Contributions"
    }
  }

  getFinancialOrCapitalView = (classes: any) => {

    const headCells1 = [
      { id: 'cat', label: 'Categories' },
      { id: 'amount', label: 'Amount' },
    ]

    const headCells = [
      { id: 'date', label: 'Date' },
      { id: 'name', label: 'Name' },
      { id: 'cat', label: 'Categories' },
      // { id: 'documents', label: 'Documents' },
      { id: 'amount', label: 'Amount' },
    ]

    if (this.state.selectedButton == 'Capital') {
      return (
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          className={classes.fullDisplay}
          style={{
            // minHeight: "100vh",
            margin: '5px 20px',
          }}
          spacing={3}
        >
          <Grid
            item
            xs={5}
            className={classes.fullDisplay}
            style={{ height: '100%', maxWidth: '95%', paddingBottom: '45px' }}
          >
            <Grid
              container
              direction="column"
              //justifyContent="space-between"
              alignItems="center"
              className={classes.equalWindow}
              style={{
                width: '100%',
                backgroundColor: 'white',
                marginBottom: '33px',
              }}
            >
              <Grid
                item
                xs={12}
                className={classes.title}
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <Typography
                  className={classes.tableHeadings}
                  style={{ padding: '5px' }}
                >
                  Capital Statement
                </Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  border: '2px solid #bababa',
                  maxHeight: window.innerHeight,
                  marginBottom: '-35px',
                  marginTop: '-8px',
                }}
              >
                {/* <Box style={{ position: "absolute", height: "160px", width: "4px", backgroundColor: "#3f4144", top: "270px", right: "717px" }}></Box> */}
                <Scrollbars
                  renderThumbVertical={(style, ...props) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        top: '50',
                        backgroundColor: '#3d3f42',
                        position: 'relative',
                        display: 'block',
                        width: '80%',
                        left: '2',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  renderTrackVertical={(style, ...props) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        position: 'absolute',
                        width: '6px',
                        right: '2px',
                        bottom: '75px',
                        top: '2px',
                        borderRadius: '3px',
                      }}
                    />
                  )}
                  thumbSize={175}
                  ref={this.scrollRef}
                >
                  <Box
                    style={{
                      width: '100%',
                      alignContent: 'center',
                      height: window.innerHeight,
                      overflow: 'auto',
                      // paddingLeft: 10,
                      //paddingRight: 20,
                      margin: '10px 0px',
                      //scrollbarColor: "red",
                    }}
                  // sx={{ overflow: 'scroll' }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                      className={this.state.selectedCategory.id == "xyz1" && classes.categorySelected}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          style={{ cursor: 'pointer', }}
                          className={this.state.selectedCategory.id == "xyz1" ? classes.categorySelectedName : classes.categoryUnselectedName}
                          onClick={() => {
                            let e = {
                              id: "xyz1",
                              name: "Capital Contribution",
                              amount: this.state.capitalReportData?.capital_contribution ? this.state.capitalReportData?.capital_contribution : 0
                              // ? `${parseInt(
                              //   this.state.capitalReportData
                              //     .capital_contribution,
                              // ) !== 0 ? this.nf.format(this.state.capitalReportData.capital_contribution) : "$0.00"}`
                              // : '$0.00'
                            }
                            this.setState({
                              selectedCategory: e,
                              defaultBackground: false
                            }, () => {
                              this.getReportDetails(e?.id)
                              // this.getReportDetailsPdf(e?.id)
                              this.getReportDetailsCsv(e?.id)
                            })

                          }}
                        >
                          Capital Contribution
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={this.state.selectedCategory.id == "xyz1" && classes.categorySelectedName}
                        >

                          {this.state.capitalReportData?.capital_contribution
                            ? `${parseInt(
                              this.state.capitalReportData
                                .capital_contribution,
                            ) !== 0 ? this.nf.format(this.state.capitalReportData.capital_contribution) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ height: 2 }} />
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                      className={this.state.selectedCategory.id == "xyz2" && classes.categorySelected}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          style={{ cursor: 'pointer', }}
                          className={this.state.selectedCategory.id == "xyz2" ? classes.categorySelectedName : classes.categoryUnselectedName}

                          onClick={() => {
                            let e = {
                              id: "xyz2",
                              name: "Capital Distribution",
                              amount: this.state.capitalReportData?.capital_distribution ? this.state.capitalReportData?.capital_distribution : 0
                              // ? `${parseInt(this.state.capitalReportData
                              //   .capital_distribution) !== 0 ? this.nf.format(this.state.capitalReportData.capital_distribution)
                              //   : "$0.00"}`
                              // : '$0.00'
                            }
                            this.setState({
                              selectedCategory: e,
                              defaultBackground: false
                            }, () => {
                              this.getReportDetails(e?.id)
                              // this.getReportDetailsPdf(e?.id)
                              this.getReportDetailsCsv(e?.id)
                            })
                          }}
                        >
                          Capital Distribution
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={this.state.selectedCategory.id == "xyz2" && classes.categorySelectedName}
                        >

                          {this.state.capitalReportData?.capital_distribution
                            ? `${parseInt(this.state.capitalReportData
                              .capital_distribution) !== 0 ? this.nf.format(
                                this.state.capitalReportData
                                  .capital_distribution) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>

                    {
                      this.state.capitalReportData?.capital_categories?.map(element =>
                        <>
                          <Divider style={{ height: 2 }} />
                          <Grid
                            container
                            alignItems="center"
                            style={{ padding: '2px 20px' }}
                            className={this.state.selectedCategory.id == element?.id && classes.categorySelected}
                          >
                            <Grid item xs>
                              <Typography
                                // gutterBottom
                                component="div"
                                className={this.state.selectedCategory.id == element?.id ? classes.categorySelectedName : classes.categoryUnselectedName}
                                style={{ cursor: 'pointer', }}
                                onClick={() => {
                                  let e = {
                                    id: element?.id,
                                    name: element?.name,
                                    amount: element?.amount ? element?.amount : 0
                                    // ? `${parseInt(element?.amount) !== 0 ? this.nf.format(
                                    //   element?.amount)
                                    //   : "$0.00"}`
                                    // : '$0.00'
                                  }
                                  this.setState({
                                    selectedCategory: e
                                  }, () => {
                                    this.getReportDetails(element?.id)
                                    // this.getReportDetailsPdf(e?.id)
                                    this.getReportDetailsCsv(element?.id)
                                  })
                                }}
                              >
                                {element?.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                // gutterBottom
                                component="div"
                                className={this.state.selectedCategory.id == element?.id && classes.categorySelectedName}
                              >

                                {element?.amount
                                  ? `${parseInt(element?.amount) !== 0 ? this.nf.format(
                                    element?.amount) : "$0.00"}`
                                  : '$0.00'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    <Divider style={{ height: 2 }} />
                    <Grid
                      item
                      xs
                      style={{ marginTop: '20px', padding: '2px 20px' }}
                    >
                      <Typography
                        component="div"
                        className={classes.BoldReportsSub}
                      >
                        Taxes
                      </Typography>
                    </Grid>
                    <Divider style={{ height: 2 }} />
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                      className={this.state.selectedCategory.id == this.state.stateTaxForCapital?.id && classes.categorySelected}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          style={{ cursor: 'pointer', }}
                          className={this.state.selectedCategory.id == this.state.stateTaxForCapital?.id ?
                            classes.categorySelectedName : classes.categoryUnselectedName}
                          onClick={() => {
                            let e = {
                              id: this.state.stateTaxForCapital?.id,
                              name: "State Taxes",
                              amount: this.state.stateTaxForCapital?.amount ? this.state.stateTaxForCapital?.amount : 0
                              // parseInt(this.state.stateTaxForCapital?.amount) !== 0 ? this.nf.format(this.state.stateTaxForCapital?.amount) : "$0.00"
                            }
                            this.setState({
                              selectedCategory: e,
                              defaultBackground: false
                            }, () => {
                              this.getReportDetails(e?.id)
                              // this.getReportDetailsPdf(e?.id)
                              this.getReportDetailsCsv(e?.id)
                            })
                          }}
                        >
                          State Taxes
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={this.state.selectedCategory.id == this.state.stateTaxForCapital?.id && classes.categorySelectedName}
                        >
                          {
                            parseInt(this.state.stateTaxForCapital?.amount) !== 0 ? this.nf.format(this.state.stateTaxForCapital?.amount) : "$0.00"
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ height: 2 }} />
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                      className={this.state.selectedCategory.id == this.state.federalTaxForCapital?.id && classes.categorySelected}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          style={{ cursor: 'pointer', }}
                          className={this.state.selectedCategory.id == this.state.federalTaxForCapital?.id ?
                            classes.categorySelectedName : classes.categoryUnselectedName}
                          onClick={() => {
                            let e = {
                              id: this.state.federalTaxForCapital?.id,
                              name: "Federal Taxes",
                              amount: this.state.federalTaxForCapital?.amount ? this.state.federalTaxForCapital?.amount : 0
                              // parseInt(this.state.federalTaxForCapital?.amount) !== 0 ? this.nf.format(this.state.federalTaxForCapital?.amount) : "$0.00"
                            }
                            this.setState({
                              selectedCategory: e,
                              defaultBackground: false
                            }, () => {
                              this.getReportDetails(e?.id)
                              // this.getReportDetailsPdf(e?.id)
                              this.getReportDetailsCsv(e?.id)
                            })
                          }}
                        >
                          Federal Taxes
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={this.state.selectedCategory.id == this.state.federalTaxForCapital?.id && classes.categorySelectedName}
                        >
                          {

                            parseInt(this.state.federalTaxForCapital?.amount) !== 0 ? this.nf.format(this.state.federalTaxForCapital?.amount) : "$0.00"
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ height: 2 }} />
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          className={classes.BoldReportsSub}
                        >
                          Total Taxes Paid
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={classes.BoldReportsSub}
                        >

                          {this.state.capitalReportData.total_tax_paid
                            ? `${parseInt(this.state.capitalReportData.total_tax_paid,) !== 0 ? this.nf.format(
                              this.state.capitalReportData.total_tax_paid) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>

                    <br />

                    <Grid
                      container
                      alignItems="center"
                      style={{
                        // backgroundColor: '#5696F4',
                        position: 'absolute',
                        bottom: 0,
                        marginBottom: '10px',
                      }}
                      className={this.state.defaultBackground && classes.categorySelected}
                    >
                      <Grid item xs style={{ padding: '2px 20px' }}>
                        <Typography
                          component="div"
                          className={classes.BoldReportsSub}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.setState({
                              selectedCategory: {},
                              defaultBackground: true,
                            }, () => {
                              this.getReportDetails()
                              this.getReportDetailsCsv()
                            })
                          }}
                        >
                          Net Capital
                        </Typography>
                      </Grid>
                      <Grid item style={{ padding: '2px 20px' }}>
                        <Typography
                          component="div"
                          className={classes.BoldReportsSub}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.setState({
                              selectedCategory: {},
                              defaultBackground: true,
                            }, () => {
                              this.getReportDetails()
                              this.getReportDetailsCsv()
                            })
                          }}
                        >

                          {this.state.capitalReportData.net_capital
                            ? `${parseInt(this.state.capitalReportData.net_capital) !== 0 ? this.nf.format(
                              this.state.capitalReportData.net_capital) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Scrollbars>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              // spacing={2}
              xs={12}
              style={{
                // backgroundColor: 'red',
                marginBottom: 5,
                paddingRight: '15px',
              }}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                xs={1}
                alignItems="center"
                style={{
                  minWidth: '50px',
                }}
              >
                <Link
                  underline="none"
                // onClick={() => {
                //   this.downloadPDF(
                //     this.state.financialCapitalStatementPdf.attachment,
                //     this.state.financialCapitalStatementPdf.filename,
                //   )
                // }}
                >
                  <Button
                    onClick={() => this.saveFormData()}
                    className={classes.buttonLayout}
                    style={{ marginLeft: '-60px' }}
                  >
                    Save
                  </Button>
                </Link>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={1}
                style={{
                  minWidth: '50px',
                }}
              >
                <Link
                  underline="none"
                  onClick={() => {
                    this.downloadPDF(
                      this.state.financialCapitalStatementCsv.attachment,
                      this.state.financialCapitalStatementCsv.filename,
                    )
                  }}
                >
                  <Button className={classes.buttonLayout}>csv</Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={7}
            className={classes.fullDisplay}
            style={{ height: '100%', maxWidth: '95%', paddingBottom: '45px' }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className={classes.equalWindow}
              style={{
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <Typography
                gutterBottom
                component="div"
                className={classes.tableHeadings}
              >
                {this.state.selectedCategory?.name ? this.state.selectedCategory.name : "Net Capital"}
              </Typography>
              <Typography
                gutterBottom
                component="div"
                className={classes.tableHeadings}
                style={{ paddingRight: '40px' }}
              >

                {this.state.selectedCategory?.amount != undefined ? this.nf.format(this.state.selectedCategory.amount) : this.state.capitalReportData.net_capital
                  ? `${parseInt(this.state.capitalReportData.net_capital) !== 0 ? this.nf.format(
                    this.state.capitalReportData.net_capital) : "$0.00"}`
                  : '$0.00'}
              </Typography>
              <Grid
                item
                xs={12}
                style={{
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box
                  style={{
                    maxHeight: window.innerHeight,
                    overflow: 'auto',
                  }}
                >
                  <TableContainer
                    style={{
                      height: window.innerHeight - 5,
                      borderRadius: '10px',
                      border: '2px solid #bababa',
                      width: '98%',
                    }}
                  >
                    <Scrollbars
                      renderThumbVertical={(style, ...props) => (
                        <div
                          {...props}
                          style={{
                            ...style,
                            top: '50',
                            backgroundColor: 'black',
                            position: 'relative',
                            display: 'block',
                            width: '80%',
                            left: '2',
                            borderRadius: 'inherit',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                      thumbSize={175}
                    >
                      {this.state.resetPage ? <Table>
                        <TableHead>
                          <TableRow>
                            {headCells.map((headCell) => {
                              return (
                                <StyledTableCell
                                  className={classes.BoldReportsSub}
                                  style={{ padding: '5px', textAlign: 'end' }}
                                  key={headCell.id}
                                >
                                  {headCell.label}
                                </StyledTableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {this.state.capitalDetailsData.map((bodyRow) => {
                            return (
                              <TableRow key={bodyRow.id}>
                                <StyledTableCell
                                  key={bodyRow.id}
                                  style={{
                                    fontStyle: bodyRow?.attributes
                                      ?.split_transaction_present
                                      ? 'italic'
                                      : 'normal',
                                  }}
                                >
                                  {bodyRow?.attributes?.date ? moment(bodyRow?.attributes?.date).format(
                                    'MM/DD/YYYY',
                                  ) : ''}
                                </StyledTableCell>
                                <StyledTableCell
                                  key={bodyRow.id}
                                  className={classes.cursorHover}
                                  onClick={() =>
                                    this.setState(
                                      {
                                        from: bodyRow.type === 'expense' ? 1 : 0,
                                      },
                                      () => this.handleModal(true, bodyRow.id),
                                    )
                                  }
                                  style={{
                                    fontStyle: bodyRow?.attributes
                                      ?.split_transaction_present
                                      ? 'italic'
                                      : 'normal',
                                    color: '#5696F4',
                                  }}
                                >
                                  {bodyRow?.attributes?.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  className={classes.modalLink}
                                  key={bodyRow.id}
                                  // onClick={() =>
                                  //   this.setState(
                                  //     {
                                  //       from: bodyRow.type === 'expense' ? 1 : 0,
                                  //     },
                                  //     () => this.handleModal(true, bodyRow.id),
                                  //   )
                                  // }
                                  style={{
                                    fontStyle: bodyRow?.attributes
                                      ?.split_transaction_present
                                      ? 'italic'
                                      : 'normal',
                                  }}
                                >
                                  {
                                    this.getCategoryNameForCapital(bodyRow?.attributes?.transaction_category?.data?.attributes)
                                    // bodyRow?.attributes?.transaction_category.data.attributes.transaction_type== "expense" &&
                                    // bodyRow?.attributes?.transaction_category.data.attributes.name
                                    // .split('_')
                                    // .join(' ')
                                  }
                                </StyledTableCell>
                                {/* <StyledTableCell key={bodyRow.id}>
                                  {bodyRow?.attributes?.file_url}
                                </StyledTableCell> */}
                                <StyledTableCell
                                  key={bodyRow.id}
                                  style={{
                                    fontStyle: bodyRow?.attributes
                                      ?.split_transaction_present
                                      ? 'italic'
                                      : 'normal',
                                  }}
                                >
                                  {parseInt(bodyRow?.attributes?.amount) !== 0 && !isNaN(bodyRow?.attributes?.amount) ? this.nf.format(bodyRow?.attributes?.amount) : "$0.00"}
                                </StyledTableCell >
                              </TableRow >
                            )
                          })
                          }
                        </TableBody>

                      </Table > : <Table></Table>}
                    </Scrollbars >
                  </TableContainer >
                </Box >
              </Grid >
            </Grid >
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ marginBottom: 5, paddingRight: '15px' }}
            >
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={2}
                style={{ minWidth: '200px', flexBasis: '1', flexShrink: '0' }}
              >
                <Grid item style={{ marginRight: '16px' }}>
                  <Link
                    underline="none"
                  // onClick={() => {
                  //   this.downloadPDF(
                  //     this.state.capitalDetailStatementPdf?.attachment,
                  //     this.state.capitalDetailStatementPdf?.filename,
                  //   )
                  // }}
                  >
                    <Button onClick={() => this.saveFormDetails()} className={classes.buttonLayout}>
                      Save
                    </Button>
                  </Link>
                </Grid>
                <Grid item style={{ marginRight: '5px' }}>
                  <Link
                    underline="none"
                    onClick={() => {
                      this.downloadPDF(
                        this.state.capitalDetailStatementCsv?.attachment,
                        this.state.capitalDetailStatementCsv?.filename,
                      )
                    }}
                  >
                    <Button className={classes.buttonLayout}>csv</Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid >
        </Grid >
      )
    } else {
      if (this.state.selectedCategory.id == "abc3") {
        return (
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            className={classes.fullDisplay}
            style={{
              // minHeight: "100vh",
              margin: '5px 20px',
            }}
            spacing={3}
          >
            <Grid
              item
              xs={5}
              className={classes.fullDisplay}
              style={{ height: '100%', maxWidth: '95%', paddingBottom: '45px' }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.equalWindow}
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  marginBottom: '33px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.title}
                  style={{ width: '100%', marginBottom: '0' }}
                >
                  <Typography
                    style={{ padding: '5px' }}
                    className={classes.tableHeadings}
                  >
                    {this.state.companyName} Financial Statement
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px',
                    border: '2px solid #bababa',
                    maxHeight: window.innerHeight,
                    marginBottom: '-35px',
                  }}
                >
                  <Scrollbars
                    //onScrollFrame={this.scrollValues}
                    renderThumbVertical={(style, ...props) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          top: '50',
                          backgroundColor: '#3d3f42',
                          position: 'relative',
                          display: 'block',
                          width: '80%',
                          left: '2',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                    renderTrackVertical={(style, ...props) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: 'absolute',
                          width: '6px',
                          right: '2px',
                          bottom: '75px',
                          top: '2px',
                          borderRadius: '3px',
                        }}
                      />
                    )}
                    thumbSize={175}
                    ref={this.scrollRef}
                  >
                    <Box
                      id='scrollUp'
                      style={{
                        width: '100%',
                        alignContent: 'center',
                        maxHeight: window.innerHeight,
                        //overflow: 'auto',
                        // paddingLeft: 10,
                        //paddingRight: 25
                        margin: '10px 0px',
                      }}
                    // sx={{ overflow: 'scroll' }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        style={{ padding: '2px 20px' }}
                        className={this.state.selectedCategory.id == "abc1" && classes.categorySelected}

                      >
                        <Grid item xs>
                          <Typography
                            component="div"
                            style={{ cursor: 'pointer', }}
                            className={this.state.selectedCategory.id == "abc1" ?
                              classes.categorySelectedName : classes.categoryUnselectedName}
                            onClick={() => {
                              let e = {
                                id: "abc1",
                                name: "Total Income",
                                amount: this.state.reportData?.total_income
                                  ? `${parseInt(this.state.reportData.total_income) !== 0 && !isNaN(this.state.reportData.total_income) ? this.nf.format(
                                    this.state.reportData.total_income) : "$0.00"}`
                                  : '$0.00'
                              }
                              this.setState({
                                selectedCategory: e,
                                defaultBackground: false
                              }, () => {
                                this.getReportDetails(e?.id)
                                // this.getReportDetailsPdf(e?.id)
                                this.getReportDetailsCsv(e?.id)
                              })
                            }}
                          >
                            Total Income
                          </Typography>
                        </Grid>
                        <Grid item >
                          <Typography
                            component="div"
                            className={this.state.selectedCategory.id == "abc1" && classes.categorySelectedName}
                          >

                            {this.state.reportData?.total_income
                              ? `${parseInt(this.state.reportData.total_income) !== 0 && !isNaN(this.state.reportData.total_income) ? this.nf.format(
                                this.state.reportData.total_income) : "$0.00"}`
                              : '$0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider style={{ height: 2 }} />
                      <Grid
                        container
                        alignItems="center"
                        style={{ padding: '2px 20px' }}
                        className={this.state.selectedCategory.id == "abc2" && classes.categorySelected}
                      >
                        <Grid item xs>
                          <Typography
                            component="div"
                            style={{ cursor: 'pointer', }}
                            className={this.state.selectedCategory.id == "abc2" ? classes.categorySelectedName : classes.categoryUnselectedName}
                            onClick={() => {
                              let e = {
                                id: "abc2",
                                name: "Costs of Goods Sold",
                                amount: this.state.reportData?.cost_of_goods ? this.state.reportData?.cost_of_goods : 0
                              }
                              this.setState({
                                selectedCategory: e,
                                defaultBackground: false
                              }, () => {
                                this.getReportDetails(e?.id)
                                // this.getReportDetailsPdf(e?.id)
                                this.getReportDetailsCsv(e?.id)
                              })
                            }}
                          >
                            Costs of Goods Sold
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            component="div"
                            className={this.state.selectedCategory.id == "abc2" && classes.categorySelectedName}

                          >

                            {this.state.reportData?.cost_of_goods
                              ? `${parseInt(this.state.reportData.cost_of_goods) !== 0 ? this.nf.format(
                                this.state.reportData.cost_of_goods) : "$0.00"}`
                              : '$0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider style={{ height: 2 }} />

                      <Grid
                        container
                        alignItems="center"
                        style={{ padding: '2px 20px' }}
                      >
                        <Grid item xs>
                          <Typography
                            component="div"
                            className={classes.BoldReportsSub}
                          >
                            Gross Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            component="div"
                            className={classes.BoldReportsSub}
                          >

                            {this.state.reportData.gross_profit
                              ? `${parseInt(this.state.reportData.gross_profit) !== 0 ? this.nf.format(
                                this.state.reportData.gross_profit) : "$0.00"}`
                              : '$0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider style={{ height: 2 }} />

                      <Grid
                        container
                        alignItems="center"
                        style={{ marginTop: '20px' }}
                      >
                        <Grid item xs style={{ padding: '2px 20px' }}>
                          <Typography
                            component="div"
                            className={classes.BoldReportsSub}
                          >
                            Operating Expenses
                          </Typography>
                        </Grid>
                        <Divider style={{ width: '100%', height: 2 }} />

                        {this.state.reportData?.operating_expenses?.data?.map(
                          (e) => (
                            <>
                              <Grid
                                container
                                alignItems="center"
                                style={{ padding: '2px 20px' }}
                                className={this.state.selectedCategory.id == e.id && classes.categorySelected}
                              >
                                <Grid
                                  item
                                  xs
                                  onClick={() => {
                                    if (!e?.id) {
                                      return
                                    }

                                    this.setState({
                                      selectedCategory: e,
                                      defaultBackground: false
                                    }, () => {
                                      this.getReportDetails(e?.id)
                                      // this.getReportDetailsPdf(e?.id)
                                      this.getReportDetailsCsv(e?.id)
                                    })
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    className={this.state.selectedCategory.id == e.id ? classes.categorySelectedName : classes.categoryUnselectedName}

                                  >
                                    {e.name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    component="div"
                                    className={this.state.selectedCategory.id == e.id && classes.categorySelectedName}
                                  >

                                    {e.amount
                                      ? `${parseInt(e.amount) !== 0 ? this.nf.format(e.amount) : "$0.00"}`
                                      : '$0.00'}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider style={{ width: '100%', height: 2 }} />
                            </>
                          ),
                        )}
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        style={{ padding: '2px 20px' }}
                      >
                        <Grid item xs>
                          <Typography
                            component="div"
                            className={classes.textOnly}
                            style={{ color: '#5696F4' }}
                          >
                            Mileage
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            component="div"
                            className={classes.textOnly}
                          >

                            {this.state.reportData.mileage
                              ? `${parseInt(this.state.reportData.mileage) !== 0 ? this.nf.format(this.state.reportData.mileage) : "$0.00"}`
                              : '$0.00'}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        className={this.state.defaultBackground && classes.categorySelected}
                      >
                        <Grid
                          item
                          xs
                          style={{
                            cursor: 'pointer',
                            padding: '2px 20px',
                          }}
                          onClick={() => {
                            this.setState({
                              selectedCategory: {},
                              defaultBackground: true,
                            }, () => {
                              this.getReportDetails()
                              // this.getReportDetailsPdf()
                              this.getReportDetailsCsv()
                            })
                          }}
                        >
                          <Typography
                            className={this.state.defaultBackground ? classes.BoldTotalOperatingExpenses : classes.BoldReportsSub}

                          >
                            Total Operating Expenses
                          </Typography>
                        </Grid>
                        <Grid item style={{ padding: '2px 20px' }}>
                          <Typography
                            className={this.state.defaultBackground ? classes.BoldTotalOperatingExpenses : classes.BoldReportsSub}


                          >

                            {this.state.reportData.total_expenses
                              ? `${parseInt(this.state.reportData.total_expenses) !== 0 ? this.nf.format(
                                this.state.reportData.total_expenses) : "$0.00"}`
                              : '$0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />

                      <Grid
                        container
                        alignItems="center"
                        // style={{
                        //   marginTop: '10px',
                        //   marginBottom: '20px',
                        //   padding: '2px 20px',
                        // }}
                        style={{ padding: '2px 20px' }}
                        className={this.state.selectedCategory.id == "abc3" && classes.categorySelected}
                      >
                        <Grid item xs>
                          <Typography
                            component="div"
                            style={{ cursor: 'pointer', }}
                            className={this.state.selectedCategory.id == "abc3" ? classes.categorySelectedName : classes.categoryUnselectedName}
                            onClick={() => {
                              let e = {
                                id: "abc3",
                                name: "Net Profit",
                                amount: this.state.reportData?.net_operating_profit
                                  ? `${parseInt(this.state.reportData.net_operating_profit) !== 0 ? this.nf.format(
                                    this.state.reportData.net_operating_profit) : "$0.00"}`
                                  : '$0.00'
                              }
                              this.setState({
                                selectedCategory: e,
                                defaultBackground: false
                              }, () => {
                                this.getReportDetails(e?.id)
                                // this.getReportDetailsPdf(e?.id)
                                this.getReportDetailsCsv(e?.id)
                              })
                            }}
                          >
                            Net Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            component="div"
                            className={this.state.selectedCategory.id == "abc3" && classes.categorySelectedName}
                          >

                            {this.state.reportData.net_operating_profit
                              ? `${parseInt(this.state.reportData.net_operating_profit) !== 0 ? this.nf.format(
                                this.state.reportData.net_operating_profit) : "$0.00"}`
                              : '$0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Scrollbars>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                xs={12}
                style={{
                  marginBottom: 5,
                  paddingRight: '15px',
                }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  xs={1}
                  alignItems="center"
                  style={{
                    minWidth: '50px',
                  }}
                >
                  <Link
                    underline="none"
                  // onClick={() => {
                  //   this.downloadPDF(
                  //     this.state.financialStatementPdf.attachment,
                  //     this.state.financialStatementPdf.filename,
                  //   )
                  // }}
                  >
                    <Button
                      onClick={() => this.saveFormData()}
                      className={classes.buttonLayout}
                      style={{
                        marginLeft: '-60px',
                      }}
                    >
                      Save
                    </Button>
                  </Link>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xs={1}
                  style={{
                    minWidth: '50px',
                  }}
                >
                  <Link
                    underline="none"
                    onClick={() => {
                      this.downloadPDF(
                        this.state.financialStatementCsv?.attachment,
                        this.state.financialStatementCsv.filename,
                      )
                    }}
                  >
                    <Button className={classes.buttonLayout}>csv</Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={7}
              className={classes.fullDisplay}
              style={{ height: '100%', maxWidth: '95%', paddingBottom: '45px' }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                className={classes.equalWindow}
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                }}
              >
                <Typography gutterBottom className={classes.tableHeadings}>
                  {this.state.selectedCategory?.name ? this.state.selectedCategory.name : "Total Operating Expenses"}
                </Typography>

                <Typography
                  gutterBottom
                  className={classes.tableHeadings}
                  style={{ paddingRight: '40px' }}
                >

                  {this.state.selectedCategory?.amount != undefined ? this.state.selectedCategory.amount : this.state.reportData.total_expenses
                    ? `${parseInt(this.state.reportData.total_expenses) !== 0 ? this.nf.format(this.state.reportData.total_expenses) : "$0.00"}`
                    : '$0.00'}
                </Typography>

                <Grid
                  item
                  xs={12}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Box
                    style={{
                      maxHeight: window.innerHeight,
                      overflow: 'auto',
                    }}
                  >
                    <TableContainer
                      style={{
                        height: window.innerHeight - 5,
                        borderRadius: '10px',
                        border: '2px solid #bababa',
                        width: '98%',
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCellLeft
                              className={classes.BoldReportsSub}
                              style={{ padding: '5px', paddingLeft: "15px" }}
                              key={headCells1[0].label}
                            >
                              {headCells1[0].label}
                            </StyledTableCellLeft>
                            <StyledTableCell
                              className={classes.BoldReportsSub}
                              style={{ padding: '5px', paddingLeft: "15px" }}
                              key={headCells1[1].id}
                            >
                              {headCells1[1].label}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {this.state.reportDetailsData.map((bodyRow) => {
                            return (
                              <TableRow key={bodyRow?.category}>
                                <StyledTableCellLeft
                                  className={classes.modalLink}
                                  key={bodyRow?.category}
                                >
                                  {bodyRow?.category?.split('_')?.join(' ')}
                                </StyledTableCellLeft>
                                {/* <StyledTableCell key={bodyRow.id}>
                                  {bodyRow?.attributes?.file_url}
                                </StyledTableCell> */}
                                <StyledTableCellLeft
                                  key={bodyRow?.category}
                                >
                                  {/* ${bodyRow?.attributes?.amount} */}
                                  {parseInt(bodyRow?.amount) !== 0 && !isNaN(bodyRow?.amount) ? this.nf.format(bodyRow?.amount) : "$0.00"}
                                </StyledTableCellLeft>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                xs={12}
                style={{
                  marginBottom: 5,
                  paddingRight: '15px',
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  xs={2}
                  style={{ minWidth: '200px', flexBasis: '1', flexShrink: '0' }}
                >
                  <Grid item style={{ marginRight: '16px' }}>
                    {' '}
                    <Link
                      underline="none"
                    // onClick={() => {
                    //   this.downloadPDF(
                    //     this.state.financialDetailStatementPdf?.attachment,
                    //     this.state.financialDetailStatementPdf?.filename,
                    //   )
                    // }}
                    >
                      <Button onClick={() => this.saveFormDetails()} className={classes.buttonLayout}>Save</Button>
                    </Link>
                  </Grid>
                  <Grid item style={{ marginRight: '5px' }}>
                    {' '}
                    <Link
                      underline="none"
                      onClick={() => {
                        this.downloadPDF(
                          this.state.financialDetailStatementCsv?.attachment,
                          this.state.financialDetailStatementCsv?.filename,
                        )
                      }}
                    >
                      <Button className={classes.buttonLayout}>csv</Button>
                    </Link>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        )
      }

      return (
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          className={classes.fullDisplay}
          style={{
            // minHeight: "100vh",
            margin: '5px 20px',
          }}
          spacing={3}
        >
          <Grid
            item
            xs={5}
            className={classes.fullDisplay}
            style={{ height: '100%', maxWidth: '95%', paddingBottom: '45px' }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.equalWindow}
              style={{
                width: '100%',
                backgroundColor: 'white',
                marginBottom: '33px',
              }}
            >
              <Grid
                item
                xs={12}
                className={classes.title}
                style={{ width: '100%', marginBottom: '0' }}
              >
                <Typography
                  style={{ padding: '5px' }}
                  className={classes.tableHeadings}
                >
                  {this.state.companyName} Financial Statement
                </Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  border: '2px solid #bababa',
                  maxHeight: window.innerHeight,
                  marginBottom: '-35px',
                }}
              >
                <Scrollbars
                  //onScrollFrame={this.scrollValues}
                  renderThumbVertical={(style, ...props) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        top: '50',
                        backgroundColor: '#3d3f42',
                        position: 'relative',
                        display: 'block',
                        width: '80%',
                        left: '2',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  renderTrackVertical={(style, ...props) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        position: 'absolute',
                        width: '6px',
                        right: '2px',
                        bottom: '75px',
                        top: '2px',
                        borderRadius: '3px',
                      }}
                    />
                  )}
                  thumbSize={175}
                  ref={this.scrollRef}
                >
                  <Box
                    id='scrollUp'
                    style={{
                      width: '100%',
                      alignContent: 'center',
                      maxHeight: window.innerHeight,
                      //overflow: 'auto',
                      // paddingLeft: 10,
                      //paddingRight: 25
                      margin: '10px 0px',
                    }}
                  // sx={{ overflow: 'scroll' }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                      className={this.state.selectedCategory.id == "abc1" && classes.categorySelected}

                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          style={{ cursor: 'pointer', }}
                          className={this.state.selectedCategory.id == "abc1" ?
                            classes.categorySelectedName : classes.categoryUnselectedName}
                          onClick={() => {
                            let e = {
                              id: "abc1",
                              name: "Total Income",
                              amount: this.state.reportData?.total_income ? this.state.reportData?.total_income : 0
                            }
                            this.setState({
                              selectedCategory: e,
                              defaultBackground: false
                            }, () => {
                              this.getReportDetails(e?.id)
                              // this.getReportDetailsPdf(e?.id)
                              this.getReportDetailsCsv(e?.id)
                            })

                          }}
                        >
                          Total Income
                        </Typography>
                      </Grid>
                      <Grid item >
                        <Typography
                          component="div"
                          className={this.state.selectedCategory.id == "abc1" && classes.categorySelectedName}
                        >

                          {this.state.reportData?.total_income
                            ? `${parseInt(this.state.reportData.total_income) !== 0 && !isNaN(this.state.reportData.total_income) ? this.nf.format(
                              this.state.reportData.total_income) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ height: 2 }} />
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                      className={this.state.selectedCategory.id == "abc2" && classes.categorySelected}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          style={{ cursor: 'pointer', }}
                          className={this.state.selectedCategory.id == "abc2" ? classes.categorySelectedName : classes.categoryUnselectedName}
                          onClick={() => {
                            let e = {
                              id: "abc2",
                              name: "Costs of Goods Sold",
                              amount: this.state.reportData?.cost_of_goods ? this.state.reportData?.cost_of_goods : 0
                            }
                            this.setState({
                              selectedCategory: e,
                              defaultBackground: false
                            }, () => {
                              this.getReportDetails(e?.id)
                              // this.getReportDetailsPdf(e?.id)
                              this.getReportDetailsCsv(e?.id)
                            })

                          }}
                        >
                          Costs of Goods Sold
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={this.state.selectedCategory.id == "abc2" && classes.categorySelectedName}

                        >

                          {this.state.reportData?.cost_of_goods
                            ? `${parseInt(this.state.reportData.cost_of_goods) !== 0 ? this.nf.format(
                              this.state.reportData.cost_of_goods) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ height: 2 }} />

                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          className={classes.BoldReportsSub}
                        >
                          Gross Profit
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={classes.BoldReportsSub}
                        >

                          {this.state.reportData.gross_profit
                            ? `${parseInt(this.state.reportData.gross_profit) !== 0 ? this.nf.format(
                              this.state.reportData.gross_profit) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ height: 2 }} />

                    <Grid
                      container
                      alignItems="center"
                      style={{ marginTop: '20px' }}
                    >
                      <Grid item xs style={{ padding: '2px 20px' }}>
                        <Typography
                          component="div"
                          className={classes.BoldReportsSub}
                        >
                          Operating Expenses
                        </Typography>
                      </Grid>
                      <Divider style={{ width: '100%', height: 2 }} />

                      {this.state.reportData?.operating_expenses?.data?.map(
                        (e) => (
                          <>
                            <Grid
                              container
                              alignItems="center"
                              style={{ padding: '2px 20px' }}
                              className={this.state.selectedCategory.id == e.id && classes.categorySelected}
                            >
                              <Grid
                                item
                                xs
                                onClick={() => {

                                  if (!e?.id) {
                                    return
                                  }

                                  this.setState({
                                    selectedCategory: e,
                                    defaultBackground: false
                                  }, () => {
                                    this.getReportDetails(e?.id)
                                    // this.getReportDetailsPdf(e?.id)
                                    this.getReportDetailsCsv(e?.id)
                                  })
                                }}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <Typography
                                  component="div"
                                  className={this.state.selectedCategory.id == e.id ? classes.categorySelectedName : classes.categoryUnselectedName}

                                >
                                  {e.name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  component="div"
                                  className={this.state.selectedCategory.id == e.id && classes.categorySelectedName}
                                >

                                  {e.amount
                                    ? `${parseInt(e.amount) !== 0 ? this.nf.format(e.amount) : "$0.00"}`
                                    : '$0.00'}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider style={{ width: '100%', height: 2 }} />
                          </>
                        ),
                      )}
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: '2px 20px' }}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          className={classes.textOnly}
                          style={{ color: '#5696F4' }}
                        >
                          Mileage
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={classes.textOnly}
                        >

                          {this.state.reportData.mileage
                            ? `${parseInt(this.state.reportData.mileage) !== 0 ? this.nf.format(this.state.reportData.mileage) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      className={this.state.defaultBackground && classes.categorySelected}
                    >
                      <Grid
                        item
                        xs
                        style={{
                          cursor: 'pointer',
                          padding: '2px 20px',
                        }}
                        onClick={() => {
                          this.setState({
                            selectedCategory: {},
                            defaultBackground: true,
                          }, () => {
                            this.getReportDetails()
                            // this.getReportDetailsPdf()
                            this.getReportDetailsCsv()
                          })
                        }}
                      >
                        <Typography
                          className={this.state.defaultBackground ? classes.BoldTotalOperatingExpenses : classes.BoldReportsSub}

                        >
                          Total Operating Expenses
                        </Typography>
                      </Grid>
                      <Grid item style={{ padding: '2px 20px' }}>
                        <Typography
                          className={this.state.defaultBackground ? classes.BoldTotalOperatingExpenses : classes.BoldReportsSub}


                        >

                          {this.state.reportData.total_expenses
                            ? `${parseInt(this.state.reportData.total_expenses) !== 0 ? this.nf.format(
                              this.state.reportData.total_expenses) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <br />

                    <Grid
                      container
                      alignItems="center"
                      // style={{
                      //   marginTop: '10px',
                      //   marginBottom: '20px',
                      //   padding: '2px 20px',
                      // }}
                      style={{ padding: '2px 20px' }}
                      className={this.state.selectedCategory.id == "abc3" && classes.categorySelected}
                    >
                      <Grid item xs>
                        <Typography
                          component="div"
                          style={{ cursor: 'pointer', }}
                          className={this.state.selectedCategory.id == "abc3" ? classes.categorySelectedName : classes.categoryUnselectedName}
                          onClick={() => {
                            let e = {
                              id: "abc3",
                              name: "Net Profit",
                              amount: this.state.reportData?.net_operating_profit
                                ? `${parseInt(this.state.reportData.net_operating_profit) !== 0 ? this.nf.format(
                                  this.state.reportData.net_operating_profit) : "$0.00"}`
                                : '$0.00'
                            }
                            this.setState({
                              selectedCategory: e,
                              defaultBackground: false
                            }, () => {
                              this.getReportDetails(e?.id)
                              // this.getReportDetailsPdf(e?.id)
                              this.getReportDetailsCsv(e?.id)
                            })
                          }}
                        >
                          Net Profit
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="div"
                          className={this.state.selectedCategory.id == "abc3" && classes.categorySelectedName}
                        >

                          {this.state.reportData.net_operating_profit
                            ? `${parseInt(this.state.reportData.net_operating_profit) !== 0 ? this.nf.format(
                              this.state.reportData.net_operating_profit) : "$0.00"}`
                            : '$0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Scrollbars>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              xs={12}
              style={{
                marginBottom: 5,
                paddingRight: '15px',
              }}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                xs={1}
                alignItems="center"
                style={{
                  minWidth: '50px',
                }}
              >
                <Link
                  underline="none"
                // onClick={() => {
                //   this.downloadPDF(
                //     this.state.financialStatementPdf.attachment,
                //     this.state.financialStatementPdf.filename,
                //   )
                // }}
                >
                  <Button
                    onClick={() => this.saveFormData()}
                    className={classes.buttonLayout}
                    style={{
                      marginLeft: '-60px',
                    }}
                  >
                    Save
                  </Button>
                </Link>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={1}
                style={{
                  minWidth: '50px',
                }}
              >
                <Link
                  underline="none"
                  onClick={() => {
                    this.downloadPDF(
                      this.state.financialStatementCsv?.attachment,
                      this.state.financialStatementCsv.filename,
                    )
                  }}
                >
                  <Button className={classes.buttonLayout}>csv</Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={7}
            className={classes.fullDisplay}
            style={{ height: '100%', maxWidth: '95%', paddingBottom: '45px' }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className={classes.equalWindow}
              style={{
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <Typography gutterBottom className={classes.tableHeadings}>
                {this.state.selectedCategory?.name ? this.state.selectedCategory.name : "Total Operating Expenses"}
              </Typography>

              <Typography
                gutterBottom
                className={classes.tableHeadings}
                style={{ paddingRight: '40px' }}
              >

                {this.state.selectedCategory?.amount != undefined ? this.getSelectedCategoryAmount(this.state.selectedCategory.amount) : this.state.reportData.total_expenses
                  ? `${parseInt(this.state.reportData.total_expenses) !== 0 ? this.nf.format(this.state.reportData.total_expenses) : "$0.00"}`
                  : '$0.00'}
              </Typography>

              <Grid
                item
                xs={12}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  style={{
                    maxHeight: window.innerHeight,
                    overflow: 'auto',
                  }}
                >
                  <TableContainer
                    style={{
                      height: window.innerHeight - 5,
                      borderRadius: '10px',
                      border: '2px solid #bababa',
                      width: '98%',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => {
                            return (
                              <StyledTableCell
                                className={classes.BoldReportsSub}
                                style={{ padding: '5px', textAlign: 'end' }}
                                key={headCell.id}
                              >
                                {headCell.label}
                              </StyledTableCell>
                            )
                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {this.state.reportDetailsData.map((bodyRow) => {
                          return (
                            <TableRow key={bodyRow.id}>
                              <StyledTableCell
                                key={bodyRow.id}
                                style={{
                                  fontStyle: bodyRow?.attributes
                                    ?.split_transaction_present
                                    ? 'italic'
                                    : 'normal',
                                }}
                              >
                                {bodyRow?.attributes?.date ? moment(bodyRow?.attributes?.date).format(
                                  'MM/DD/YYYY',
                                ) : ""}
                              </StyledTableCell>
                              <StyledTableCell
                                key={bodyRow.id}
                                className={classes.cursorHover}
                                onClick={() =>
                                  this.setState(
                                    {
                                      from: bodyRow.type === 'expense' ? 1 : 0,
                                    },
                                    () => this.handleModal(true, bodyRow.id),
                                  )
                                }
                                style={{
                                  fontStyle: bodyRow?.attributes
                                    ?.split_transaction_present
                                    ? 'italic'
                                    : 'normal',
                                  color: '#5696F4',
                                }}
                              >
                                {bodyRow?.attributes?.name}
                              </StyledTableCell>
                              <StyledTableCell
                                className={classes.modalLink}
                                key={bodyRow.id}
                                // onClick={() =>
                                //   this.setState(
                                //     {
                                //       from: bodyRow.type === 'expense' ? 1 : 0,
                                //     },
                                //     () => this.handleModal(true, bodyRow.id),
                                //   )
                                // }
                                style={{
                                  fontStyle: bodyRow?.attributes
                                    ?.split_transaction_present
                                    ? 'italic'
                                    : 'normal',
                                }}
                              >
                                {bodyRow?.attributes?.transaction_category?.data?.attributes?.name
                                  .split('_')
                                  .join(' ')}
                              </StyledTableCell>
                              {/* <StyledTableCell key={bodyRow.id}>
                                {bodyRow?.attributes?.file_url}
                              </StyledTableCell> */}
                              <StyledTableCell
                                key={bodyRow.id}
                                style={{
                                  fontStyle: bodyRow?.attributes
                                    ?.split_transaction_present
                                    ? 'italic'
                                    : 'normal',
                                }}
                              >
                                {/* ${bodyRow?.attributes?.amount} */}
                                {parseInt(bodyRow?.attributes?.amount) !== 0 && !isNaN(bodyRow?.attributes?.amount) ? this.nf.format(bodyRow?.attributes?.amount) : "$0.00"}
                              </StyledTableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              xs={12}
              style={{
                marginBottom: 5,
                paddingRight: '15px',
              }}
            >
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={2}
                style={{ minWidth: '200px', flexBasis: '1', flexShrink: '0' }}
              >
                <Grid item style={{ marginRight: '16px' }}>
                  {' '}
                  <Link
                    underline="none"
                  // onClick={() => {
                  //   this.downloadPDF(
                  //     this.state.financialDetailStatementPdf?.attachment,
                  //     this.state.financialDetailStatementPdf?.filename,
                  //   )
                  // }}
                  >
                    <Button onClick={() => this.saveFormDetails()} className={classes.buttonLayout}>Save</Button>
                  </Link>
                </Grid>
                <Grid item style={{ marginRight: '5px' }}>
                  {' '}
                  <Link
                    underline="none"
                    onClick={() => {
                      this.downloadPDF(
                        this.state.financialDetailStatementCsv?.attachment,
                        this.state.financialDetailStatementCsv?.filename,
                      )
                    }}
                  >
                    <Button className={classes.buttonLayout}>csv</Button>
                  </Link>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  render() {
    console.log(this.props)
    const { classes } = this.props
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.fullDisplay}
        style={{
          // minHeight: "100vh",
          // margin: "20px"
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        {this.state.openModal && (
          <TransactionDetailsModalWeb
            handleModal={this.handleModal}
            transactionId={this.state.transactionId}
            from={this.state.from}
            isTransactionUpdated={(isTransactionUpdated) => {
              this.setState({ isTransactionUpdated: isTransactionUpdated })
            }}
            showSnackBarMessage={(message) => this.setState({ showSnackbar: true, snackbarMessage: message })}
            isFromReports = {true}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false,
            })
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>

        <Grid
          item
          container
          xs={6}
          justifyContent="space-evenly"
          alignItems="center"
          className={classes.topDiv}
        >
          <Grid item justifyContent="center" alignItems="center">
            <Button
              className={`${classes.tabButton} ${classes.incomeButton}`}
              style={{
                backgroundColor:
                  this.state.selectedButton == 'Financial'
                    ? '#FF9933'
                    : '#5696F4',
              }}
              onClick={() => {
                this.scrollRef.current?.scrollTop(0)
                this.setSelectedButton('Financial')
                this.setState({
                  defaultBackground: true
                })
              }}
            >
              <Typography
                variant="subtitle2"
                className={classes.boldFont}
              // style={{ fontSize: '13px' }}
              >
                {this.state.companyName} Financial Statement
              </Typography>
            </Button>
          </Grid>

          <Grid item justifyContent="center" alignItems="center">
            <Button
              className={`${classes.tabButton} ${classes.incomeButton}`}
              style={{
                backgroundColor:
                  this.state.selectedButton == 'Financial'
                    ? '#5696F4'
                    : '#FF9933',
              }}
              onClick={() => {
                this.scrollRef.current?.scrollTop(0)
                this.setSelectedButton('Capital')
                this.setState({
                  defaultBackground: true
                })
              }}
            >
              <Typography
                variant="subtitle2"
                className={classes.boldFont}
              // style={{ fontSize: '13px' }}
              >
                Capital Statement
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {/* <Grid item container spacing={3} sm={12} direction="row" className={classes.fullDisplay}
          justifyContent="center"
          alignItems="center">
          <Grid item>
            <Button variant="outlined" className={classes.boldFontbutton} style={{
              border: "0",
              borderBottom: this.state.selectedButton == 'Financial' ? "2px solid #afb0b0" : '0',
              borderWidth: 1.9,
              borderRadius: "0",
              fontSize: "1.1rem"
            }}
              onClick={() => {
                this.setSelectedButton('Financial')
              }}
            >Neeuh Financial Statement</Button>
          </Grid>
          <Grid item>
            <Button className={classes.boldFontbutton} style={{

              borderBottom: this.state.selectedButton == 'Capital' ? "2px solid #afb0b0" : '0',
              borderRadius: "0",
              fontSize: "1.1rem"
            }}
              onClick={() => {
                this.setSelectedButton('Capital')
              }}
            >Capital Statement</Button>
          </Grid>
        </Grid> */}

        <Grid
          item
          container
          spacing={3}
          sm={12}
          direction="row"
          className={classes.fullDisplay}
          justifyContent="center"
          alignItems="center"
        //style={{ marginTop: 20 }}
        >
          <Grid item>
            <Typography variant="subtitle2" className={classes.reportPeriod}>
              Report Period
            </Typography>
          </Grid>

          <Grid item>{this.getSelectView(classes)}</Grid>
          {/* <Select
          value={10}
          label="Age"
          // onChange={handleChange}
        >
          <MenuItem value={10}>Current Month</MenuItem>
          <MenuItem value={20}>Current Quarter</MenuItem>
          <MenuItem value={30}>Year to Date</MenuItem>
          <MenuItem value={30}>Previous Month</MenuItem>
          <MenuItem value={30}>Previous Quarter</MenuItem>
          <MenuItem value={30}>Previous Year</MenuItem>
        </Select> */}

          <Grid
            item
          // container direction="row" sm={12}
          >
            <DatePicker
              format="MM/dd/y"
              maxDate={new Date()}
              disabled={this.state.reportPeriod != 'Custom'}
              value={this.state.reportPeriodStartDate}
              onChange={(value) => {
                if (
                  moment(value).isBefore(this.state.reportPeriodEndDate) ||
                  moment(value).isSame(this.state.reportPeriodEndDate)
                ) {
                  this.setState({
                    reportPeriod: 'Custom',
                    reportPeriodStartDate: new Date(value),
                  })
                } else {
                  this.showSnackBar('Please choose a valid date range.')
                }
              }}
              className={classes.formDate}
              calendarIcon={null}
              clearIcon={null}
            ></DatePicker>
            {/* <TextField
              id="date"
              variant="outlined"
              // label="Birthday"
              type="date"
              defaultValue="2017-05-24"
              className={classes.datePicker}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // keyboardIcon={<Icon>add_circle</Icon>}

            /> */}
          </Grid>

          <Grid
            item
            style={{
              padding: 0,
            }}
          >
            <Typography variant="subtitle2" className={classes.reportPeriod}>
              to
            </Typography>
          </Grid>

          {/* <Grid>
<TextField
              id="date"
              variant="outlined"
              // label="Birthday"
              type="date"
              defaultValue="2017-05-24"
              className={classes.datePicker}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // keyboardIcon={<Icon>add_circle</Icon>}

            />
            </Grid> */}
          <Grid item>
            <DatePicker
              format="MM/dd/y"
              maxDate={new Date()}
              disabled={this.state.reportPeriod != 'Custom'}
              value={this.state.reportPeriodEndDate}
              onChange={(value) => {
                if (
                  moment(value).isAfter(this.state.reportPeriodStartDate) ||
                  moment(value).isSame(this.state.reportPeriodStartDate)
                ) {
                  this.setState({
                    reportPeriod: 'Custom',
                    reportPeriodEndDate: new Date(value),
                  })
                } else {
                  this.showSnackBar('Please choose a valid date range.')
                }
              }}
              className={classes.formDate}
              calendarIcon={null}
              clearIcon={null}
            ></DatePicker>
          </Grid>

          <Grid item>
            <Button
              className={classes.buttonLayout}
              onClick={() => {
                this.getReports()
                // this.getPdfForFinancial()
                this.getCsvForFinancial()

                // this.getReportDetailsPdf()
                this.setState({ selectedCategory: {} }, () => {
                  this.getReportDetails()
                  this.getReportDetailsCsv()
                })
              }}
            >
              Run Report
            </Button>
          </Grid>
        </Grid>

        {this.getFinancialOrCapitalView(classes)}
      </Grid>
    )
  }
}

export default withStyles(useStyles, { withTheme: true })(ReportsWeb)

import React, { Component } from 'react';
import {
    StyleSheet,
    ScrollView,
    View,
    Text,
    Dimensions,
    Image,
    TouchableOpacity,
    FlatList,
    TouchableHighlight,
} from "react-native";
import { scaledSize } from '../../framework/src/Utilities';
import Categoriesdetails from './Categoriesdetails';
import Carousel from './Carousel/Carousel';
import Button from './Button';
import {
    CategoryItemType,
    IncomeDataObjectType,
  } from "../../blocks/categoriessubcategories/src/CategoriessubcategoriesController";
type MyProps = {
    state: any
    onClickDownArrow: () => void;
    handleSingleTransactionModal: () => void;
    onPressAddToReport: () => void;
    carousel: any;
    handleBackPress: () => void;
    handlePressAddDetails: () => void;
    handleCameraPress: () => void;
    deleteCameraImage: () => void;
    handleDeleteSelectedImage: () => void;
    deleteImage: () => void;
    closeModal: () => void;
    handleImageLibrary: () => void;
    handleDeleteImage: any;
    handleNoteChange: any;
    handleInputChange: any;
    setCategoryItem: any;
    check: any
    downArrowIcon: any;
    roundCheckIcon: any;
    handleSnapToItem: any;
    handleSpiltTransaction:any
    handleEditSplit: () => void;
    handleEditDetails: () => void;
};

export default class CategoriessubcategoriesMainCompo extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let { state, handleEditSplit,handleEditDetails, check, downArrowIcon,handleSpiltTransaction, roundCheckIcon,handleSnapToItem, setCategoryItem, onClickDownArrow, handleSingleTransactionModal, onPressAddToReport, carousel, handleBackPress, handlePressAddDetails, handleCameraPress, deleteCameraImage, handleDeleteSelectedImage, deleteImage, closeModal, handleImageLibrary, handleDeleteImage, handleNoteChange, handleInputChange } = this.props;

        const expenseReimbursementCategory = state.selectedCategory.find(
            (category:any) => category.attributes.name === "expense_reimbursement"
          );
        const updatedSelectedCategory = state.selectedCategory.filter(
            (category:any) => category.attributes.name !== "expense_reimbursement"
          );
          const selectedCategoryiesandExpenses = expenseReimbursementCategory ? state.selectedExpense.length > 0 : updatedSelectedCategory.length > 0 

        const _renderItem = ({ item, index }: { item: IncomeDataObjectType, index: number }) => {
            return (
                <View style={{ height: state.activeIndex !== index ? scaledSize(146) : scaledSize(165) }}>
                    {state.activeIndex === index && state.selectedCategory.length > 0 && <Image source={roundCheckIcon} style={styles.roundCheckIcon} />}
                    <TouchableHighlight style={[state.activeIndex !== index ? styles.inactiveIncomeItemContainer : styles.incomeItemContainer]}>
                        <View style={state.activeIndex !== index ? styles.inactiveInnerIncomeItemContainer : styles.innerIncomeItemContainer}>
                            <Text style={state.activeIndex !== index ? styles.inactiveIncomeDateText : styles.incomeDateText} testID="testDate">{item.date}</Text>
                            <Text style={state.activeIndex !== index ? styles.inactiveIncomeTitleText : styles.incomeTitleText} testID="testName">{item.name}</Text>
                            <Text style={state.activeIndex !== index ? styles.inactiveIncomePriceText : styles.incomePriceText} testID="testAmount">${item.amount}</Text>
                            <Text style={state.activeIndex !== index ? styles.inactiveIncomCreditText : styles.incomCreditText}>Credit Card</Text>
                        </View>
                    </TouchableHighlight>
                </View>
            );
        }
          
        const subCategories = ({ item, index}: { item: CategoryItemType, index: number}) => {
          return (
            <TouchableOpacity style={[styles.expenseItemContainer, item.attributes.isSelected === true && { borderColor: "#3876F1", borderWidth: 1 }]} onPress={() => setCategoryItem(item)} testID="testSubCategory">
              <Text style={styles.expenseCategoryText}>{item.attributes.name}</Text>
              {item.attributes.isSelected === true && <Image source={check} style={styles.checkIconStyle} /> }
            </TouchableOpacity>
          )
        }

        const renderHalfView = () => {
            return (
                <>
                    <View style={styles.carouselView}>
                        <Carousel
                            layout={"default"}
                            ref={(ref: any) => carousel = ref}
                            data={state.incomeData}
                            sliderWidth={Dimensions.get('window').width}
                            itemWidth={scaledSize(160)}
                            renderItem={_renderItem}
                            scrollEnabled={true}
                            initialScrollIndex={state.activeIndex}
                            enableSnap={true}
                            onScroll={() => { }}
                            inactiveSlideOpacity={1}
                            enableMomentum
                            inactiveSlideScale={1}
                            onSnapToItem={(index: number) => handleSnapToItem(index)}
                        />
                        {state.activeIndex < state.incomeData.length - 1 && <TouchableOpacity style={styles.rightArrowIconView} onPress={() => carousel.snapToNext(true, true)} testID="testRightArrow">
                            <Image source={require("./rightArrow.png")} style={styles.rightArrowIcon} />
                        </TouchableOpacity>}
                        {state.activeIndex !== 0 && <TouchableOpacity style={styles.leftArrowIconView} onPress={() => carousel.snapToPrev(true, true)} testID="testLeftArrow">
                            <Image source={require("./rightArrow.png")} style={styles.leftArrowIcon} />
                        </TouchableOpacity>}
                    </View>
                    <ScrollView>
                        <FlatList
                            data={state.categoriesArray}
                            renderItem={_renderCategoryItem}
                            keyExtractor={(item: any) => item.id}
                            style={[styles.categoryFlatListView]}
                            testID="testFlatList"
                            scrollEnabled={false}
                        />
                    </ScrollView>
                    {!state.isAllItem && <TouchableOpacity style={{ alignItems: "center", marginTop: scaledSize(4) }} onPress={onClickDownArrow} testID="testDownArrow">
                        <Image source={downArrowIcon} style={{ height: scaledSize(21), width: scaledSize(21) }} />
                    </TouchableOpacity>}
                    {state.selectedCategory.length <= 1 ? <View style={{ flexDirection: "row", justifyContent: 'space-between', marginHorizontal: scaledSize(30), marginTop: scaledSize(15), marginBottom: scaledSize(90) }}>
                    {!state.isSave ?
                        <Button
                            theme={selectedCategoryiesandExpenses ? "secondary" : "primary"}
                            text={"ADD DETAILS"}
                            disabled={expenseReimbursementCategory ? state.selectedExpense.length === 0 : updatedSelectedCategory.length === 0}
                            textStyle={{ color: selectedCategoryiesandExpenses ? "#3876F1" : "#ffffffff", fontSize: scaledSize(12), lineHeight: scaledSize(19) }}
                            style={{
                                height: scaledSize(28),
                                width: scaledSize(140),
                                borderRadius: scaledSize(44),
                                paddingHorizontal: 0,
                                paddingVertical: 0,
                                ...selectedCategoryiesandExpenses && {
                                    borderColor: "#3876F1",
                                    borderWidth: 1,
                                    backgroundColor: "#ffffffff",
                                }
                            }}
                            testID="testAddDetails"
                            onPress={handleSingleTransactionModal}
                        />
              :
                        <Button
                            theme={"primary"}
                            text={"EDIT DETAILS"}
                            disabled={expenseReimbursementCategory ? state.selectedExpense.length === 0 : updatedSelectedCategory.length === 0}
                textStyle={{
                  color: "#3876F1",
                  fontSize: scaledSize(12),
                  lineHeight: scaledSize(19)
                }}
                style={{
                  height: scaledSize(28),
                  width: scaledSize(140),
                  borderRadius: scaledSize(44),
                  paddingHorizontal: 0,
                  paddingVertical: 0,
                  borderColor: "#3876F1",
                  borderWidth: 1,
                  backgroundColor: "#ffffffff",
                }}
                onPress={()=>handleEditDetails()}
                testID=""
              />
            }
                        <Button
                            theme={selectedCategoryiesandExpenses ? "secondary" : "primary"}
                            text={"ADD TO REPORT"}
                            disabled={expenseReimbursementCategory ? state.selectedExpense.length === 0 : updatedSelectedCategory.length === 0}
                            textStyle={{ color: "#ffffffff", fontSize: scaledSize(12), lineHeight: scaledSize(19) }}
                            style={{
                                height: scaledSize(28),
                                width: scaledSize(140),
                                borderRadius: scaledSize(44),
                                paddingHorizontal: 0,
                                paddingVertical: 0,
                            }}
                            testID="testAddReport"
                            onPress={() => onPressAddToReport()}
                        />
                    </View> : 
                      (
              !state.isEditSplit ?
                <View style={{ alignItems: 'center', marginHorizontal: scaledSize(30), marginTop: scaledSize(15), marginBottom: scaledSize(90) }}>
                  <Button
                    theme={"secondary"}
                    text={"SPLIT TRANSACTION"}
                    disabled={state.selectedCategory.length === 0}
                    textStyle={{
                      color: "#ffffffff",
                      fontSize: scaledSize(12),
                      lineHeight: scaledSize(19)
                    }}
                    style={{
                      height: scaledSize(28),
                      width: scaledSize(140),
                      borderRadius: scaledSize(44),
                      paddingHorizontal: 0,
                      paddingVertical: 0
                    }}
                    onPress={handleSpiltTransaction}
                    testID="testSplitTransaction"
                  />
                </View> :
                <View style={{ flexDirection: "row", justifyContent: 'space-between', marginHorizontal: scaledSize(30), marginTop: scaledSize(15), marginBottom: scaledSize(90) }}>
                  <Button
                                        theme={"primary"}
                                        text={"EDIT SPLIT"}
                                        disabled={state.selectedCategory.length === 0}
                                        textStyle={{
                                            color: "#3876F1",
                                            fontSize: scaledSize(12),
                                            lineHeight: scaledSize(19)
                                        }}
                                        style={{
                                            height: scaledSize(28),
                                            width: scaledSize(140),
                                            borderRadius: scaledSize(44),
                                            paddingHorizontal: 0,
                                            paddingVertical: 0,
                                            borderColor: "#3876F1",
                                            borderWidth: 1,
                                            backgroundColor: "#ffffffff",
                                        }}
                                        onPress={handleEditSplit}
                                        testID=""  />
                  <Button
                    theme={"secondary"}
                    text={"ADD TO REPORT"}
                    disabled={state.selectedCategory.length === 0}
                    textStyle={{
                      color: "#ffffffff",
                      fontSize: scaledSize(12),
                      lineHeight: scaledSize(19)
                    }}
                    onPress={onPressAddToReport}
                    style={{
                      height: scaledSize(28),
                      width: scaledSize(140),
                      borderRadius: scaledSize(44),
                      paddingHorizontal: 0,
                      paddingVertical: 0
                    }}
                    testID="AddtoReports"
                  />
                </View>
            )
          }
                </>
            )
        }
      
          const _renderCategoryItem = ({ item, index}: { item: CategoryItemType, index: number}) => {
            return (
              <View>        
                <TouchableOpacity style={[styles.categoryItemContainer, item.attributes.isSelected === true && { borderColor: "#3876F1", borderWidth: 1 }]} onPress={() => setCategoryItem(item)} testID="testCategory">
                  <View style={{flexDirection: 'column'}}><Text style={styles.categoryText}>{item.attributes.name}</Text>
                  {(item.attributes.name==="expense_reimbursement" && item.attributes.isSelected) && <Text style={{fontSize: 12,color:'#666',paddingLeft: 22, marginTop:-3}}>Select at least one expense category</Text>}
                  </View>
                  {item.attributes.isSelected === true && <Image source={check} style={styles.checkIconStyle} /> }
                </TouchableOpacity>
                {(item.attributes.name==="expense_reimbursement" && item.attributes.isSelected) && 
                  <FlatList 
                    data={state.expenseData}
                    renderItem={subCategories}
                    keyExtractor={(item: any) => item.id}
                    style={styles.expenseFlatListView}
                    testID="expenseFlatList"
                  />
                }
              </View>
            )
          }
        return (
            <>
                {state.isAddDetail ?
                    <Categoriesdetails state={state}
                        handleNoteChange={handleNoteChange}
                        handleInputChange={handleInputChange}
                        handleDeleteImage={handleDeleteImage}
                        handleImageLibrary={handleImageLibrary}
                        closeModal={closeModal}
                        deleteImage={deleteImage}
                        handleDeleteSelectedImage={handleDeleteSelectedImage}
                        deleteCameraImage={deleteCameraImage}
                        handleCameraPress={handleCameraPress}
                        handlePressAddDetails={handlePressAddDetails}
                        handleBackPress={handleBackPress}
                        handleSpiltTransaction={handleSpiltTransaction}
                        onPressAddToReport={onPressAddToReport}
                    />
                    :
                    !state.isAllItem ? <View style={styles.container} >
                        {renderHalfView()}
                    </View> : 
                    <ScrollView>
                        {renderHalfView()}
                    </ScrollView>
                }
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#ffffffff"
    },
    carouselView: {
        marginTop: scaledSize(10)
    },
    incomeItemContainer: {
        height: scaledSize(140),
        width: scaledSize(140),
        borderRadius: scaledSize(70),
        backgroundColor: "#3876F1",
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: "center",
        opacity: 1,
        marginTop: scaledSize(13),
    },
    inactiveIncomeItemContainer: {
        height: scaledSize(126),
        width: scaledSize(126),
        borderRadius: scaledSize(63),
        backgroundColor: "#3876F1",
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: "center",
        marginTop: scaledSize(17),
        opacity: 0.8,
    },
    innerIncomeItemContainer: {
        height: scaledSize(124),
        width: scaledSize(124),
        borderRadius: scaledSize(62),
        backgroundColor: "#FFFFFF",
        alignItems: "center"
    },
    inactiveInnerIncomeItemContainer: {
        height: scaledSize(112),
        width: scaledSize(112),
        borderRadius: scaledSize(56),
        backgroundColor: "#FFFFFF",
        alignItems: "center"
    },
    incomeDateText: {
        fontSize: scaledSize(12),
        fontFamily: "OpenSans-Bold",
        lineHeight: scaledSize(22),
        textAlign: 'center',
        color: "#B3B3B3",
        width: scaledSize(109),
        marginTop: scaledSize(26)
    },
    inactiveIncomeDateText: {
        fontSize: scaledSize(11),
        fontFamily: "OpenSans-Bold",
        lineHeight: scaledSize(20),
        textAlign: 'center',
        color: "#B3B3B3",
        width: scaledSize(98),
        marginTop: scaledSize(23.4)
    },
    incomeTitleText: {
        fontSize: scaledSize(12),
        fontFamily: "OpenSans-Medium",
        lineHeight: scaledSize(22),
        textAlign: 'center',
        color: "#3876F1",
        width: scaledSize(120),
        textTransform: "uppercase"
    },
    inactiveIncomeTitleText: {
        fontSize: scaledSize(11),
        fontFamily: "OpenSans-Medium",
        lineHeight: scaledSize(20),
        textAlign: 'center',
        color: "#3876F1",
        width: scaledSize(108),
        textTransform: "uppercase"
    },
    incomePriceText: {
        fontSize: scaledSize(15),
        fontFamily: "OpenSans-Medium",
        lineHeight: scaledSize(22),
        textAlign: 'center',
        color: "#000",
        width: scaledSize(107),
        marginTop: scaledSize(5),
    },
    inactiveIncomePriceText: {
        fontSize: scaledSize(13),
        fontFamily: "OpenSans-Medium",
        lineHeight: scaledSize(20),
        textAlign: 'center',
        color: "#000",
        width: scaledSize(96),
        marginTop: scaledSize(5),
    },
    incomCreditText: {
        fontSize: scaledSize(7),
        fontFamily: "OpenSans-Medium",
        lineHeight: scaledSize(12),
        textAlign: 'center',
        color: "#B3B3B3",
        width: scaledSize(90),
        textTransform: "uppercase"
    },
    inactiveIncomCreditText: {
        fontSize: scaledSize(7),
        fontFamily: "OpenSans-Medium",
        lineHeight: scaledSize(12),
        textAlign: 'center',
        color: "#B3B3B3",
        width: scaledSize(81),
        textTransform: "uppercase"
    },
    expenseItemContainer:{
        width: scaledSize(260),
        minHeight: scaledSize(38),
        borderRadius: scaledSize(44),
        backgroundColor: "#FFF",
        shadowColor: "#000000",
        shadowOffset: { height: 2, width: 0 },
        shadowOpacity: 0.4,
        shadowRadius: 20,
        alignSelf: "center",
        marginVertical: scaledSize(7),
        elevation: 7,
        alignItems: "center",
        flexDirection: "row"
    },
    expenseFlatListView:{
        paddingLeft: 30,
    },
    expenseCategoryText: {
        fontSize: scaledSize(14),
        lineHeight: scaledSize(21),
        fontFamily: "OpenSans-Bold",
        color: "#0F172A",
        marginLeft: scaledSize(22),
        width: scaledSize(200)
    },
    rightArrowIconView: {
        position: 'absolute',
        top: scaledSize(65),
        left: scaledSize(253),
    },
    rightArrowIcon: {
        height: scaledSize(23),
        width: scaledSize(23)
    },
    leftArrowIconView: {
        position: 'absolute',
        top: scaledSize(65),
        left: scaledSize(80),
    },
    leftArrowIcon: {
        height: scaledSize(23),
        width: scaledSize(23),
        transform: [{ rotate: '180deg' }]
    },
    categoryFlatListView: {
        marginTop: scaledSize(15),
        // height: scaledSize(260)
    },
    categoryItemContainer: {
        width: scaledSize(284),
        minHeight: scaledSize(38),
        borderRadius: scaledSize(44),
        backgroundColor: "#FFF",
        shadowColor: "#000000",
        shadowOffset: { height: 2, width: 0 },
        shadowOpacity: 0.4,
        shadowRadius: 20,
        alignSelf: "center",
        marginVertical: scaledSize(7),
        elevation: 7,
        alignItems: "center",
        flexDirection: "row"
    },
    categoryText: {
        fontSize: scaledSize(14),
        lineHeight: scaledSize(21),
        fontFamily: "OpenSans-Bold",
        color: "#0F172A",
        marginLeft: scaledSize(22),
        width: scaledSize(224)
    },
    checkIconStyle: {
        height: scaledSize(21),
        width: scaledSize(21),
    },
    roundCheckIcon: {
        height: scaledSize(44),
        width: scaledSize(44),
        position: "absolute",
        alignSelf: "center",
        top: scaledSize(0),
        zIndex: 1000
    }
});
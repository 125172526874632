//@ts-nocheck
import React from "react";
import DashboardController from "./DashboardController";
import {
    AppBar,
    Typography,
    Button,
    withStyles,
    Grid,
    NativeSelect,
    TextField,
    Box,
    Snackbar,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Divider
} from "@material-ui/core";
import Settings5Controller from "./Settings5Controller";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Scrollbars } from 'react-custom-scrollbars';
import StorageProvider from "../../../framework/src/StorageProvider.web";
import Alert from '@material-ui/lab/Alert';
import { date } from "yup";
import { Redirect } from "react-router-dom";

const useStyles = (theme) => ({
    border: {
        border: "1px solid",
    },
    typography: {
        color: "red"
        // [theme.breakpoints.up('sm')]: {
        //   fontSize: "12px",
        // },
        // [theme.breakpoints.up('md')]: {
        //   fontSize: "52px",

        // }
    },

    fullDisplay: {
        maxWidth: "100%",
        '& Typography': {
            color: "green"
        }
    },
    buttonLayout: {
        backgroundColor: "#5597f4",
        borderRadius: "30px",
        color: "white",
        height: "25px",
        fontSize: "13px",
        fontWeight: "700",
        '@media (max-width:1425px)': {
            fontSize: '9.75px',

        },
        '&:hover': {
            backgroundColor: "#5597f4"
        }
    },
    mainScreenHeader: {
        fontSize: "20px",
        fontWeight: "700",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "15px",
        }
    },
    emailHelperTxt: {
        fontSize: "12px",
        fontWeight: "400",
        color: "#AEAEAE",
        fontStyle: "italic",
        '@media(max-width:1425px)': {
            fontSize: "9px",
        }
    },
    mainTableHeader: {
        fontSize: "13px",
        fontWeight: "600",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "9.75px",
        }
    },
    addUserSelectUser: {
        fontSize: "16px",
        fontWeight: "700",
        color: "#3F4144",
        marginBottom: "20px",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },
    addUserRadioButtonMain: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },
    addUserRadioHelperText: {
        fontSize: "12px",
        fontWeight: "400",
        color: "#AEAEAE",
        '@media(max-width:1425px)': {
            fontSize: "9px",
        }
    },
    fontStyleSecondaryPart: {
        fontSize: "16px",
        fontWeight: "600",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },
    fontForTextField: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#AEAEAE",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },
    mainTableData: {
        fontSize: "13px",
        fontWeight: "600",
        color: "#AEAEAE",
        '@media(max-width:1425px)': {
            fontSize: "9.75px",
        }
    },
    marginP: {
        margin: "5 2"
    },
    headerUser: {
        textAlign: "center",
        fontWeight: "700",
        fontSize: "20px",
        color: "#3f4144",
        margin: "52 0",
        '@media (max-width:1425px)': {
            fontSize: '15px'

        },



    },
    textInsideBox1: {
        fontSize: "20px",
        '@media (max-width:1425px)': {
            fontSize: '15px'

        },
    },
    textInsideBox2: {
        fontSize: "17",
        '@media (max-width:1425px)': {
            fontSize: '12.75px'

        },
    },
    invitationContainerBox1: {
        height: "50px",
        width: "50px",
        '@media (max-width:1425px)': {
            height: "45px",
            width: "45px",

        },
    },
    inputPropsClass: {
        fontSize: "18px",
        paddingLeft: '15px',
        '@media (max-width:1425px)': {
            fontSize: "13.5px",
            paddingLeft: '15px'

        },
    }



})


class UserSettingsEdit extends Settings5Controller {
    async componentDidMount() {
        this.setState({ token: await StorageProvider.get('token'), editId: await StorageProvider.get('editId'), editFirstName: await StorageProvider.get('editFirstName'), editLastName: await StorageProvider.get('editLastName'), editEmail: await StorageProvider.get('editEmail'), editUserRole: await StorageProvider.get('editRole'), editUserName: await StorageProvider.get('editUser') }),
            this.getAllUser()
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        this.populateDataForEdit()

    }


    render() {
        console.log(this.props);
        const { classes } = this.props;


        return (
            <div className="root">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.errorStatus}
                    onClose={() => {
                        this.setState({
                            errorStatus: false
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="error"
                        elevation={6} variant="filled"
                    >{this.state.errorMsg}</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.successStatus}
                    onClose={() => {
                        this.setState({
                            successStatus: false,
                            userAccouuntMain: true
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="success"
                        elevation={6} variant="filled"
                    >{this.state.successMessage}</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.successStatus}
                    onClose={() => {
                        this.setState({
                            successStatus: false,
                            addAccountFinish: true,
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="success"
                        elevation={6} variant="filled"
                    >{this.state.successMessage}</Alert>
                </Snackbar>
                <Grid container direction="column" justifyContent="space-evenly" className={classes.fullDisplay}>
                    <Grid item xs={1} className={classes.fullDisplay}>
                        <Typography className={classes.headerUser}>Edit User</Typography>

                    </Grid>
                    <Box style={{ width: "85%", margin: "auto" }}>
                        <Grid item xs={5} className={classes.fullDisplay}>
                            <Typography className={classes.addUserSelectUser}>Select user type</Typography>
                            <Typography className={classes.addUserRadioButtonMain} style={{ marginBottom: "10px" }}>The count toward your user limit</Typography>
                            <FormControl>

                                <RadioGroup
                                    aria-labelledby="addScreen1-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={this.state.editUserRole}
                                    onChange={(event) => { this.setState({ editUserRole: event.target.value }) }}
                                >
                                    <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="admin" control={<Radio color="primary" />} label="Admin" />
                                    <FormLabel id="addScreen1-1-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }}>They can see and do anything. This includes sending money, changing passwords, and adding users. Not everyone should be an admin.</Typography></FormLabel>
                                    <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="basic" control={<Radio color="primary" />} label="Basic" />
                                    <FormLabel id="addScreen1-2-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }} >Full access without the ability to access company settings.</Typography></FormLabel>
                                    <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="accountant" control={<Radio color="primary" />} label="Accountant" />
                                    <FormLabel id="addScreen1-3-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }}>Full access without the ability to access company settings.</Typography></FormLabel>
                                </RadioGroup>
                            </FormControl>


                        </Grid>
                        <Divider style={{ backgroundColor: "#979797", margin: "20 0" }} />
                        <Grid item xs={3} className={classes.fullDisplay}>
                            <Typography className={classes.fontStyleSecondaryPart}>What is their contact information?</Typography>
                            <Typography className={classes.addUserRadioHelperText} style={{ color: "#3F4144", margin: "10 0 0 0" }}>We will invite them to make a Neeuh account and password for access to your company. This invite expires after 30 days.</Typography>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.fullDisplay}>
                                <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }}>

                                    <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="First Name" value={this.state.editFirstName} onChange={(e) => { this.setState({ editFirstName: e.target.value }) }} />
                                </Grid>
                                <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }}>
                                    <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="Last Name" value={this.state.editLastName} onChange={(e) => { this.setState({ editLastName: e.target.value }) }} />
                                </Grid>
                                <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }} >
                                    <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%", marginTop: "33px" }} disabled placeholder="Email" value={this.state.editEmail} onChange={(e) => { this.setState({ editEmail: e.target.value }) }} />
                                    <Typography style={{ paddingTop: "10px" }} className={classes.emailHelperTxt}>This will be their user id. </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%", marginTop: "20px" }}>
                                    <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} disabled placeholder="User Name" value={this.state.editUserName} onChange={(e) => { this.setState({ editUserName: e.target.value }) }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ textAlign: "end", margin: "20 0 40 0" }}>
                            <Button className={classes.buttonLayout} style={{ padding: "0 20" }} onClick={() => { this.editUserData() }}>Save User Information</Button>
                        </Grid>
                    </Box>
                    {this.state.userAccouuntMain ? <><Redirect to={'/Dashboard/companysettings/user'} />{this.setState({ userAccouuntMain: false })} </> : <></>}

                </Grid>

                { }





            </div>
        );
    }
}

export default withStyles(useStyles)(UserSettingsEdit);

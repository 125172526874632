import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { table } from "console";
import { config } from "process";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    formDataDoc: any;
    formDataFin: any;
    formDate: any;
    formText: string;
    formMiles: any;
    formCar: any;
    months: any[];
    currentMonth: any;
    selectedWeek: any;
    weekDays: any[];
    weekDate: any[];
    totalMileageDeduction: number;
    mileageDeductionMonths: any[];
    yearlyMiles: number;
    mileageData: any[];
    cars: any[];
    token: string;
    openModal: boolean;
    addCarModal: boolean;
    newCarName: string;
    activeEdit: any;
    mileageId: any;
    errorMsg: string;
    firstDay: any;
    lastDay: any;
    column1: any[];
    column2: any[];
    row: any[];

    openEmail: boolean;
    openDownload: boolean;
    openUpload: boolean;
    openDelete: boolean;
    emailSend: boolean,
    deleteForms: boolean;
    documentFormData: any[];
    finantialFormData: any[];
    deleteId: any[];
    documentsToBeDeleted: any[];
    statementToBeDeleted: any[];
    finantialDeleteId: any[];
    selectedDocForEmail: any;
    selectedStatementForEmail: any;
    idOfFormsToBeDeleted: any;
    deleteSuccess: string;
    file: any;
    selectedFiles: any;
    convertedFiles: any;
    fileConvert: any;
    documentSection: boolean;
    sendCopyToYourself: boolean;
    emailMessage: any;
    emailSubject: any;
    emailTo: any;
    emails: any;
    emailsCC: any;
    emailsBCC: any;
    showSnackbar: any;
    alertType: any;
    snackbarMessage: any;
    companyName: string;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class FormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // arrayholder: any[];
    // passwordReg: RegExp;
    // emailReg: RegExp;
    createAccountApiCallId: any;
    getMileagesId: any;
    createFormId: any;
    sendEmailId: any;
    getFormsId: any;
    getDeleteId: any;

    //updateMileageId: any;
    getCarsId: any;
    //createCarId: any;
    //updateCarId: any;
    loginApiCallId: any;
    validationApiCallId: string = "";

    imgPasswordVisible: any;
    imgPasswordInVisible: any;

    // labelHeader: any;
    // labelFirstName: string;
    // lastName: string;
    // labelEmail: string;
    // labelPassword: string;
    // labelRePassword: string;
    // labelLegalText: string;
    // labelLegalTermCondition: string;
    // labelLegalPrivacyPolicy: string;
    // btnTextSignUp: string;


    currentCountryCode: any;

    tempo: any;
    docType: string = "Document";
    finType: string = "Financial Statement";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            sendCopyToYourself: false,
            documentsToBeDeleted: [],
            statementToBeDeleted: [],
            formDate: new Date(),
            emailMessage: "",
            emailSubject: "",
            emailTo: "",
            emails: [],
            emailsCC: [],
            emailsBCC: [],
            showSnackbar: false,
            alertType: 'error',
            snackbarMessage: "",
            formText: "",
            months: moment.monthsShort(),
            weekDays: moment.weekdays(),
            currentMonth: new Date().getMonth(),
            selectedWeek: {
                sunday: moment().day(0),
                saturday: moment().day(6),
                currentCount: 0
            },
            weekDate: [],
            mileageId: '',
            totalMileageDeduction: 0,
            mileageDeductionMonths: [],
            yearlyMiles: 0,
            mileageData: [],
            cars: [],
            newCarName: '',
            activeEdit: '',
            addCarModal: false,
            formCar: "",
            formMiles: "",
            token: "",
            openModal: false,
            errorMsg: '',
            firstDay: moment().day(0).format("MMMM Do"),
            lastDay: moment().day(6).format("MMMM Do"),
            column1: [],
            column2: [],
            row: [],
            openEmail: false,
            openDelete: false,
            openDownload: false,
            openUpload: false,
            formDataDoc: "",
            formDataFin: "",
            emailSend: false,
            deleteForms: false,
            documentFormData: [],
            finantialFormData: [],
            deleteId: [],
            finantialDeleteId: [],
            selectedDocForEmail: [],
            selectedStatementForEmail: [],
            idOfFormsToBeDeleted: 0,
            deleteSuccess: "",
            file: '',
            selectedFiles: [],
            convertedFiles: [],
            fileConvert: '',
            documentSection: false,
            companyName: ""
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getFormsId) {
                    if (!responseJson.errors) {
                        this.setState({ documentFormData: [], finantialFormData: [] })

                        this.updateFormData(responseJson)

                    } else {
                        //Check Error Response
                        this.parseApiErrorResponse(responseJson);
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                }
                if (apiRequestCallId === this.getDeleteId) {
                    if (!responseJson.errors) {
                        // this.getFormData();
                        if (responseJson?.message) {
                            if (this.state.idOfFormsToBeDeleted == 0) {
                                const filteredData = this.state.documentFormData.filter(a => false === this.state.documentsToBeDeleted.some(b => a.id === b.id));

                                this.setState({
                                    deleteSuccess: responseJson?.message[0],
                                    deleteId: [],
                                    documentFormData: filteredData,
                                    documentsToBeDeleted: [],
                                })
                                this.closeModal();

                            } else {
                                const filteredData = this.state.finantialFormData.filter(a => false === this.state.statementToBeDeleted.some(b => a.id === b.id));

                                this.setState({
                                    deleteSuccess: responseJson?.message[0],
                                    finantialDeleteId: [],
                                    finantialFormData: filteredData,
                                    statementToBeDeleted: [],
                                })
                                this.closeModal();

                            }
                        }
                    }
                    else {
                        //Check Error Response
                        this.parseApiErrorResponse(responseJson);
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                }
                if (apiRequestCallId === this.createFormId) {
                    if (!responseJson.errors) {
                        console.log("helooooo responses", responseJson)
                        this.setState({ file: '', selectedFiles: [] });
                        this.closeModal();
                        this.getFormData(moment().format('YYYY'));
                        if (responseJson.data) {

                        }
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }

                    this.parseApiCatchErrorResponse(errorReponse);
                }
                if (apiRequestCallId === this.sendEmailId) {
                    if (!responseJson.errors) {
                        this.setState({
                            emailTo: "",
                            emails: [],
                            emailsCC: [],
                            emailsBCC: [],
                            emailSubject: "",
                            emailMessage: "",
                            sendCopyToYourself: false,
                        })
                        this.closeModal();
                        console.log('alerttype ...');

                        this.showSnackBar("Email sent successfully.", "success");
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }

                    this.parseApiCatchErrorResponse(errorReponse);
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    updateFormData(responseJson: any) {
        if (responseJson?.data[0]?.form_type == "Document" || responseJson?.data[0]?.form_type == "Financial Statement") {
            if (responseJson?.data[0]?.form_type == "Document") {
                this.setState({ documentFormData: responseJson?.data[0]?.form_data })
            } else if (responseJson?.data[0]?.form_type == "Financial Statement") {
                this.setState({ finantialFormData: responseJson?.data[0]?.form_data })
            }
        } else if (responseJson?.data[0]?.form_type != "Document" && responseJson?.data[1]?.form_type != "Document") {
            this.setState({ documentFormData: [] })
        }
        if (responseJson?.data[1]?.form_type == "Financial Statement" || responseJson?.data[1]?.form_type == "Document") {
            if (responseJson?.data[1]?.form_type == "Financial Statement") {
                this.setState({ finantialFormData: responseJson?.data[1]?.form_data })
            } else if (responseJson?.data[1]?.form_type == "Document") {
                this.setState({ documentFormData: responseJson?.data[1]?.form_data })
            }
        } else if (responseJson?.data[0]?.form_type != "Financial Statement" && responseJson?.data[1]?.form_type != "Financial Statement") {
            this.setState({ finantialFormData: [] })
        }
    }


    // updateWeek = (num: number) => {
    //     console.log(num)
    //     const pre = num + 6
    //     this.setState({
    //         selectedWeek: {
    //             sunday: moment().day(num),
    //             saturday: moment().day(pre),
    //             currentCount: num
    //         }
    //     }, () => this.allGetApis())
    //     this.getWeekDays(num)
    // }

    downloadPDF = (urlN: any, nameN: any) => {

        if (!urlN) {
            return;
        }

        fetch(urlN, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    nameN,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                //@ts-ignore
                link.parentNode.removeChild(link);
            });
    }

    removeAttachedDoc = (id: any, state: any) => {
        if (state == "deleteId") {
            let data = this.state.selectedDocForEmail.filter((e: any) => e != id);
            this.setState({ selectedDocForEmail: data })
        } else {
            let data = this.state.selectedStatementForEmail.filter((e: any) => e != id);
            this.setState({ selectedStatementForEmail: data })
        }
    }

    showSnackBar = (message: string, alertType?: string) => {
        console.log('alertType ', alertType);

        this.setState({
            snackbarMessage: message,
            alertType: alertType ? "success" : 'error'
        }, () => {
            this.setState({
                showSnackbar: true
            })
        })
    }
    showErrorMsg = (mesg: string) => {
        this.setState({ errorMsg: mesg })
        setTimeout(() => this.setState({ errorMsg: '' }), 2000)
    }

    getWeekDays = (num: number) => {
        this.setState({ weekDate: [] })
        let from = num;
        const weekDates = []
        const to = num + 6;
        while (from <= to) {
            const weekDay = moment().day(from)
            weekDates.push(weekDay)
            from++
        }
        this.setState({ weekDate: [...weekDates] })
    }

    getMileageData = () => {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMileagesId = requestMessage.messageId;
        console.log(this.state.selectedWeek)
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.mileageApiEndPoint}/mileages?start_date=${this.state.selectedWeek.sunday.format('DD/MM/YYYY')}&end_date=${this.state.selectedWeek.saturday.format('DD/MM/YYYY')}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    // getCarsData = () => {
    //     const headers = {
    //         "Content-Type": configJSON.ApiContentType,
    //         token: this.state.token,
    //     };

    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );

    //     this.getCarsId = requestMessage.messageId;

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         `${configJSON.mileageApiEndPoint}/cars`
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(headers)
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.dashboarApiMethodType
    //     );
    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    // }
    createFormDataDoc = () => {
        if (!this.state.formDataDoc) {

        }

    }

    // allGetApis = () => {
    //     this.getCarsData()
    //     this.getMileageData()
    // }
    matched(index: any, matchedData: any): any {

        for (let count = 0; count < this.state.mileageData[index]?.mileage_data?.length; count++) {
            if (this.state.mileageData[index]?.mileage_data[count]?.date_of_mileage == matchedData) {

                return this.state.mileageData[index]?.mileage_data[count]?.miles;
            }
        }
        return null;

    }

    getFormData = (year: any) => {
        const headers = {
            token: this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFormsId = requestMessage.messageId;
        //console.log("message id", this.getFormsId);
        //console.log("token", this.state.token);

        console.log('yearrrrrrrrrrrr', year)
        const taxYear = year ? year : moment().format("YYYY")
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.formApiEndPoint}/forms?year=${taxYear}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    deleteFormData = (id: number) => {
        this.setState({
            idOfFormsToBeDeleted: id
        })
        const headers = {
            token: this.state.token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDeleteId = requestMessage.messageId;
        if (id === 0) {
            if (this.state.documentsToBeDeleted.length > 1) {
                let endPoint = "";
                this.state.documentsToBeDeleted.map((data, index) => {
                    if (index == 0) {
                        endPoint = endPoint + `form_ids[]=${data?.id}`
                    }
                    else {
                        endPoint = endPoint + `&form_ids[]=${data?.id}`
                    }
                })
                console.log("endpoint", endPoint)

                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    `${configJSON.formApiEndPoint}/forms/delete_forms?${endPoint}`
                );

            }
            else if (this.state?.documentsToBeDeleted?.length == 1) {
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    `${configJSON.formApiEndPoint}/forms/delete_forms?&form_ids[]=${this.state?.documentsToBeDeleted[0]?.id}`
                );
            }
        }
        else if (id === 1) {
            if (this.state.statementToBeDeleted.length > 1) {
                let endPoint = "";
                this.state.statementToBeDeleted.map((data, index) => {
                    if (index == 0) {
                        endPoint = endPoint + `form_ids[]=${data?.id}`
                    }
                    else {
                        endPoint = endPoint + `&form_ids[]=${data?.id}`
                    }
                })
                console.log("endpoint", endPoint)

                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    `${configJSON.formApiEndPoint}/forms/delete_forms?${endPoint}`
                );

            }
            else if (this.state.statementToBeDeleted.length == 1) {
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    `${configJSON.formApiEndPoint}/forms/delete_forms?&form_ids[]=${this.state?.statementToBeDeleted[0]?.id}`
                );
            }

        }




        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.formDeleteMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createFormData = (id: any) => {

        if (
            // this.state.file === '' || 
            this.state.selectedFiles?.length <= 0) {
            this.showSnackBar("Please attach file.");
            return
        }
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createFormId = requestMessage.messageId;

        // const attach = {
        //     data: this.state.fileConvert
        // }
        //console.log("dat", dat);
        //console.log("--", this.state.fileConvert)
        // var attr: any = '';

        // if (id === 1) {
        //     attr = {
        //         form_type: this.finType,
        //         filename: this.state?.file?.name,
        //         attachment: attach
        //     }

        // }
        // else if (id === 0) {
        //     attr = {
        //         form_type: this.docType,
        //         filename: this.state?.file?.name,
        //         attachment: attach
        //     }
        // }
        //console.log("check", attr)


        const data = {
            attributes: this.state.convertedFiles
        }
        //console.log("check", data)
        const httpBody = {
            data: data,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.formApiEndPoint}/forms`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    isValidEmail(email: string) {
        const emailRegex = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        return emailRegex.test(email);
    }

    sendEmail = () => {

        if (this.state.emails.length <= 0) {
            this.showSnackBar("Please add a recipient.");
            return;
        }

        // if (!this.isValidEmail(this.state.emailTo)) {
        //     this.showSnackBar("Please enter a valid email address.");
        //     return;
        // }

        if (this.state.emailSubject == "") {
            this.showSnackBar("Please add subject.");
            return;
        }
        if (this.state.emailMessage == "") {
            this.showSnackBar("Please add a message.");
            return;
        }

        let formIds = [];

        if (this.state.documentSection) {
            formIds = this.state.selectedDocForEmail.map((e: any) => e.id);
        } else {
            formIds = this.state.selectedStatementForEmail.map((e: any) => e.id);
        }

        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendEmailId = requestMessage.messageId;

        let attr = {
            "form_ids": formIds,
            // "email_to": this.state.emailTo,
            "email_to": this.state.emails,
            "cc": this.state.emailsCC,
            "bcc": this.state.emailsBCC,
            "subject": this.state.emailSubject,
            "message": this.state.emailMessage,
            "form_type": "pdf",
            "send_copy_yourself": this.state.sendCopyToYourself
        };

        const data = {
            attributes: attr
        }

        const httpBody = {
            data: data,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.formApiEndPoint}/forms/send_forms_by_email`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    handleCheckBox = (e: any, id: number, itemId: any) => {
        if (id == 0) {
            let arr = this.state.documentFormData.map(t => {
                if (t.id == itemId) {
                    return Object.assign({}, t, {
                        isChecked: e.target.checked
                    });
                }
                return t;
            });

            this.setState({
                documentFormData: arr
            });
            if (e.target.checked) {

                this.state.deleteId.push(e.target.value);

            }
            else {
                let array = [];
                let count = 0;
                for (let c = 0; c < this.state.deleteId.length; c++) {
                    if (e.target.value != this.state?.deleteId[c]) {
                        array[count] = this.state.deleteId[c];
                        count++;
                    }
                }

                this.setState({ deleteId: array });



            }
        }
        else if (id == 1) {
            let arr = this.state.finantialFormData.map(t => {
                if (t.id == itemId) {
                    return Object.assign({}, t, {
                        isChecked: e.target.checked
                    });
                }
                return t;
            });

            this.setState({
                finantialFormData: arr
            });


            if (e.target.checked) {

                this.state.finantialDeleteId.push(e.target.value);

            }
            else {
                let array = [];
                let count = 0;
                for (let c = 0; c < this.state.finantialDeleteId.length; c++) {
                    if (e.target.value != this.state?.finantialDeleteId[c]) {
                        array[count] = this.state.finantialDeleteId[c];
                        count++;
                    }
                }

                this.setState({ finantialDeleteId: array });



            }
        }

    }
    checkArray(array: any[]) {
        if (array.length == 0) {
            return true;
        }
        else {
            return false;
        }
    }
    openModal(id: number) {
        if (id == 0) {
            //this.state.openDelete = false;
            //this.state.openDownload = false;
            this.setState({ openDelete: false })
            this.setState({ openDownload: false })
            this.setState({ openUpload: false })
            this.setState({ openEmail: true })

        }
        if (id == 1) {
            this.setState({ openDelete: false })
            this.setState({ openUpload: false })
            this.setState({ openEmail: false })
            this.setState({ openDownload: true })

        }
        if (id == 2) {
            this.setState({ openDelete: false })
            this.setState({ openDownload: false })
            this.setState({ openEmail: false })
            this.setState({ openUpload: true })


        }
        if (id == 3) {

            this.setState({ openDownload: false })
            this.setState({ openUpload: false })
            this.setState({ openEmail: false })
            this.setState({ openDelete: true })
        }

    }
    closeModal() {
        this.setState({ openDelete: false })
        this.setState({ openDownload: false })
        this.setState({ openEmail: false })
        this.setState({ openUpload: false })
    }

    // checkCheckBox(index: number): boolean {
    //     console.log("checkCheckbox : index", index)
    //     if (this.dummyColumn[index]?.checked == true) {
    //         return true
    //     }
    //     else {
    //         return false;
    //     }

    // }
    changeCheck(index: number, tableNumber: number) {
        if (tableNumber == 0) {
            let temp = this.state.column1;
            temp[index].checked = !temp[index].checked;
            this.setState({ column1: temp });
            //console.log("column 1", this.state.column1);

        }
        if (tableNumber == 1) {
            let temp = this.state.column2;

            temp[index].checked = !temp[index].checked;

            this.setState({ column2: temp });
            //console.log("column 2", this.state.column2);

        }

    }
    deleteFunction(id: number) {
        let temp = [];
        let tempCount = 0;
        if (id == 0) {
            //temp = this.state.column1;
            for (let count = 0; count < this.state.column1.length; count++) {
                if (this.state.column1[count].checked == false) {
                    temp[tempCount] = this.state.column1[count];
                    tempCount++;

                }
            }
            this.setState({ column1: temp })
            this.closeModal();
            this.setState({ emailSend: false })
        }
        else {
            for (let count = 0; count < this.state.column2.length; count++) {
                if (this.state.column2[count].checked == false) {
                    temp[tempCount] = this.state.column2[count];
                    tempCount++;

                }
            }
            this.setState({ column2: temp })
            this.closeModal();
            this.setState({ emailSend: false })

        }

    }

    convertBase(file: any): any {
        //const temp = "";
        //console.log("file", this.state.file)
        let reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
            //this.setState({ fileConvert: reader.result as string }) 
            const temp = reader.result as string;
            console.log("emptyyy", temp)
            //console.log("hiii", reader.result as string)
            //this.tempo = temp;
            this.setState({
                // fileConvert: temp,
                convertedFiles: [...this.state.convertedFiles, {
                    form_type: this.state.documentSection ? this.docType : this.finType,
                    filename: file?.name,
                    attachment: {
                        "data": temp
                    }
                }]
            }, () => {
                console.log('files, ', this.state.convertedFiles);
            })
            //console.log("aihaiiii", this.state.fileConvert)
        }
        //console.log("alllllu", this.state.fileConvert);
        //console.log("tempo", temp);
    }

    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  editFlag: boolean;
  token: any;
  taxCategories: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class BillAndSubSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  w1DataId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
        token: '',
        editFlag:false,
        taxCategories:['Individual', 'Sole Prop', 'S Copr', 'Unreimbersable Partnership Expenses(UPE)']
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.w1DataId){
          if (!responseJson.errors) {
            if (responseJson.data) {
              const personalData = this.gettingKeyAndValue(responseJson.data[0].personal_1040[0])
              const scheduleC =   this.gettingKeyAndValue(responseJson.data[0].schedule_c[0])
              const dataToRender = [{...personalData, name: "Personal 1040", text: "U.S. Individual Income Tax Return"}, {...scheduleC, name: "Schedule C" ,text: "Profit or Loss from Your Business"}]
              const totalKeys = personalData.keys.length > scheduleC.keys.length ? personalData.keys: scheduleC.keys
              // this.setState({dataToRender: dataToRender, w1Data :responseJson.data[0], totalKeys: totalKeys}, ()=> console.log(dataToRender, '---------'))
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    }
  }
    // Customizable Area End

  // Customizable Area Start

  getData= ()=>{
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.w1DataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.formApiEndPoint}/w1_forms?year=2022`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  gettingKeyAndValue =(data:any) =>{
    let keys= Object.keys(data).map(el=> el.split('_').join(" "))
    keys = keys.map(el => {
      console.log(el)
      if(el =='over tax paid'){
        el = 'Over/(Under) Tax Paid'
      }else if (el == "qbi deduction"){
        el = "QBI deduction"
      }else if(el == "est taxable income"){
        el = "est. taxable income"
      }else if(el == "cost of goods"){
        el = 'Cost of Goods Sold'
      }
      return el
    })
    const values = Object.values(data)
    return {keys,values}
  }



  // Customizable Area End
}

//@ts-nocheck
import React from "react";
import DashboardController from "./DashboardController";
import {
  // Customizable Area Start
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  NativeSelect,
  TextField,
  Box,
  Snackbar,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Divider,
  Popover,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";
import Settings5Controller from "./Settings5Controller";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Scrollbars } from 'react-custom-scrollbars';
import StorageProvider from "../../../framework/src/StorageProvider.web";
import Alert from '@material-ui/lab/Alert';
import { date } from "yup";
import { Redirect } from "react-router-dom";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close"; import deleteIcon from "../assets/delete.png";
// Customizable Area Start
const useStyles = (theme) => ({
  border: {
    border: "1px solid",
  },
  border1: {
    border: "0px solid",
    padding: "10px"
  },
  typography: {
    color: "red"
    // [theme.breakpoints.up('sm')]: {
    //   fontSize: "12px",
    // },
    // [theme.breakpoints.up('md')]: {
    //   fontSize: "52px",

    // }
  },

  fullDisplay: {
    maxWidth: "100%",
    '& Typography': {
      color: "green"
    }
    // '@media (min-width:768px)': {
    //   //fontSize: '18px',
    //   //color: "red",
    // },

    // [theme.breakpoints.up('sm')]: {
    //   color: "red",
    // },
    // [theme.breakpoints.up('md')]: {
    //   color: "green"

    // }




    //height: "100%"
  },
  buttonLayout: {
    backgroundColor: "#5597f4",
    borderRadius: "30px",
    color: "white",
    height: "25px",
    fontSize: "13px",
    fontWeight: "700",
    '@media (max-width:1425px)': {
      fontSize: '9.75px',

    },
    '&:hover': {
      backgroundColor: "#5597f4"
    }
  },
  mainScreenHeader: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#3F4144",
    '@media(max-width:1425px)': {
      fontSize: "15px",
    }
  },
  emailHelperTxt: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#AEAEAE",
    fontStyle: "italic",
    '@media(max-width:1425px)': {
      fontSize: "9px",
    }
  },
  mainTableHeader: {
    fontSize: "13px",
    fontWeight: "600",
    color: "#3F4144",
    '@media(max-width:1425px)': {
      fontSize: "9.75px",
    }
  },
  addUserSelectUser: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#3F4144",
    marginBottom: "20px",
    '@media(max-width:1425px)': {
      fontSize: "12px",
    }
  },
  addUserRadioButtonMain: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#3F4144",
    '@media(max-width:1425px)': {
      fontSize: "12px",
    }
  },
  addUserRadioHelperText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#AEAEAE",
    '@media(max-width:1425px)': {
      fontSize: "9px",
    }
  },
  fontStyleSecondaryPart: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#3F4144",
    '@media(max-width:1425px)': {
      fontSize: "12px",
    }
  },
  mainTableData: {
    fontSize: "13px",
    fontWeight: "600",
    color: "#AEAEAE",
    overflow:'hidden',
    textOverflow: 'ellipsis',
    '@media(max-width:1425px)': {
      fontSize: "9.75px",
    }
  },
  marginP: {
    margin: "5 2"
  },
  headerUser: {
    textAlign: "center",
    fontWeight: "700",
    fontSize: "20px",
    color: "#3f4144",
    margin: "52 0",
    '@media (max-width:1425px)': {
      fontSize: '15px'

    },



  },
  closeIcon: {
    position: "relative",
    left: "35%",
    top: "5px"
  },
  closeIcon1: {
    position: "relative",
    left: "30%",
    top: "5px"
  },
  selectArea: {
    minWidth: "0px"
  },
  textInsideBox1: {
    fontSize: "20px",
    '@media (max-width:1425px)': {
      fontSize: '15px'

    },
  },
  iconStyle: {
    left: "100%",
    marginTop: "0",
    right: "0"
  },
  textInsideBox2: {
    fontSize: "17",
    '@media (max-width:1425px)': {
      fontSize: '12.75px'

    },
  },
  invitationContainerBox1: {
    height: "50px",
    width: "50px",
    '@media (max-width:1425px)': {
      height: "45px",
      width: "45px",

    },
  },
  inputPropsClass: {
    fontSize: "18px",
    paddingLeft: '15px',
    '@media (max-width:1425px)': {
      fontSize: "13.5px",
      paddingLeft: '15px'

    },
  },
  dialogHeader: {
    margin: "0",
    padding: "15px",
    textAlign: "center",
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundColor: "red"

  },
  tabButton: {
    borderRadius: "20px",
    border: "1px solid #ffffff"
  },
  saveButton: {
    // width: "80%",
    background: "#5696F4",
    border: "1px solid #5696F4",
    "& h6": {
      color: "#ffffff"
    },
    "&:hover": {
      background: "#5696F4"
    }
  },
  boldFont: {
    fontSize: '13px',
    fontWeight: 'bold',
    '@media (max-width:1425px)': {
      fontSize: '9.75px',
      fontWeight: 'bold',
    },
  },



})
// Customizable Area End

class UserAccountSettingWeb extends Settings5Controller {
  // Customizable Area Start
  async componentDidMount() {
    this.setState({ token: await StorageProvider.get('token') }),
      this.getAllUser()
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    //console.log("userdata", this.state.userData)
  }
// Customizable Area End

  render() {
    console.log(this.props);
    const { classes } = this.props;

    return (
      // Customizable Area Start
      <div className="root">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.errorStatus}
          onClose={() => {
            this.setState({
              errorStatus: false,
              errorMsg: ''
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity="error"
            elevation={6} variant="filled"
          >{this.state.errorMsg}</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.successStatus}
          onClose={() => {
            this.setState({
              successStatus: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity="success"
            elevation={6} variant="filled"
          >{this.state.successMessage}</Alert>
        </Snackbar>

        <Grid container direction="column" justifyContent="space-evenly" className={classes.fullDisplay}>
          <Grid item xs={1} className={classes.fullDisplay} style={{ width: "95%", margin: "auto" }}>
            <Grid container direction="row" justifyContent="space-between" className={classes.fullDisplay}>
              <Grid item>
                <Typography className={classes.headerUser}>Manage Users</Typography>
              </Grid>
              <Grid item style={{ margin: "auto 0px" }}>
                {this.state.addUserAccount ? <Button className={classes.buttonLayout} style={{ padding: "0 20" }} onClick={() => { this.setState({ clickOnAddAccount: true }) }}>Add new user</Button> : <Button className={classes.buttonLayout} style={{ padding: "0 20" }} onClick={() => { this.setState({ errorStatus: true, errorMsg: "You are not allowed to create new user as you have reached maximum limit." }) }}>Add new user</Button>}

              </Grid>
            </Grid>
          </Grid>
          {this.state.clickOnAddAccount ? <><Redirect to={'/Dashboard/companysettings/adduser'} />{this.setState({ clickOnAddAccount: false })} </> : <></>}
          {this.state.editUserPage ? <><Redirect to={'/Dashboard/companysettings/edituser'} />{this.setState({ editUserPage: false })} </> : <></>}
          {this.state.performDelete ? <>{this.setState({ editUserPage: false, performDelete: false }), this.deleteAccountUser(this.edditId1)} </> : <></>}

          <Dialog
            fullWidth
            open={this.state.deleteUserOpen}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle className={classes.dialogHeader}>
              <img src={deleteIcon} style={{ height: "20px", width: "20px", paddingRight: "15px" }} />
              Delete User
              <CloseIcon onClick={() => { this.setState({ deleteUserOpen: false }) }} className={classes.closeIcon} style={{ cursor: "pointer" }} />
            </DialogTitle>
            {/* <DialogContent>
              <DialogContentText style={{ color: "#3F4144" }}>
                Are you sure, you want to delete <span style={{ fontWeight: "700" }}>{this.state.userName}</span> ?
              </DialogContentText>
            </DialogContent>
            <DialogActions >
              <Button autoFocus onClick={() => { this.setState({ deleteUserOpen: false }) }}>
                NO
              </Button>
              <Button autoFocus onClick={() => { this.setState({ deleteUserOpen: false, performDelete: true }) }}>
                YES
              </Button>
            </DialogActions> */}
            <DialogContent style={{ overflow: "auto" }}>
              <Grid container direction="column" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                <Grid item xs={2} style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                  <Typography style={{
                    textAlign: "center",
                    // marginTop: "30px",
                    marginBottom: "30px", fontWeight: "500"
                  }}>
                    Are you sure, you want to delete <span style={{ fontWeight: "700" }}>{this.state.userName}</span> ?

                  </Typography>

                </Grid>

                <Grid item xs={2} className={classes.fillDis} style={{ maxWidth: "100%" }}>
                  <Grid container direction="row" justifyContent="center"  >
                    <Grid item xs={2} style={{ marginBottom: "10px", marginRight: "15px" }}>
                      <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                        type="submit"
                        onClick={() => {
                          this.setState({ deleteUserOpen: false, performDelete: true })
                        }}
                      >
                        <Typography variant="subtitle2" className={classes.boldFont}>
                          delete
                        </Typography>
                      </Button>
                      {/* <Button className={classes.buttonForm} onClick={() => { this.deleteFormData(), this.setState({ emailSend: false }), this.getFormData(), this.closeModal() }}>
                            delete
                          </Button> */}
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: "10px", marginLeft: "15px" }}>
                      <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                        type="submit"
                        onClick={() => { this.setState({ deleteUserOpen: false }) }}
                      >
                        <Typography variant="subtitle2" className={classes.boldFont}>
                          cancel
                        </Typography>
                      </Button>
                      {/* <Button className={classes.buttonForm}
                          onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}>
                            cancel
                          </Button> */}
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>

            </DialogContent>

          </Dialog>



          <Grid item className={classes.fullDisplay} style={{ width: "95%", margin: "auto" }}>
            <Grid container direction="row" justifyContent="space-between" className={classes.fullDisplay} style={{ borderBottom: "1px solid #3F4144" }}

            >
              <Grid item xs={3}>
                <Typography className={classes.mainTableHeader}>NAME</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.mainTableHeader}>EMAIL</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.mainTableHeader} style={{marginLeft:"12px"}}>USER TYPE</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.mainTableHeader}>STATUS</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.mainTableHeader}>DATE ADDED</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.mainTableHeader} align="right">ACTION</Typography>
              </Grid>
            </Grid>
            {/* {this.state.valve1 && this.state.valve2 ? <>{this.poopulateUserAction(1), this.setState({ valve1: false, valve2: false })}</> : <></>} */}
            {this.state.userData.map((data, index) => {
              return (
                <>
                  <Grid container direction="row" justifyContent="space-between" className={classes.fullDisplay} style={{ borderBottom: "1px solid #AEAEAE", margin: "10px 0px" }}

                  >
                    {/* {this.setState({ valve1: true })} */}
                    <Grid item xs={3}>
                      <Typography className={classes.mainTableData}>{data?.attributes?.first_name} {data?.attributes?.last_name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title={data?.attributes?.email} arrow >
                      <Typography className={classes.mainTableData}>{data?.attributes?.email}</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className={classes.mainTableData} style={{marginLeft:"12px"}}>{data?.attributes?.role}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={classes.mainTableData}>{data?.attributes?.activated ? "Active" : "Inactive"}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className={classes.mainTableData}>{moment(data?.attributes?.created_at).format("MM/DD/YYYY")}</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ margin: "auto" }}>
                      {this.state.valve1 ? <>{this.setState({ userActionValue: index, valve1: false })}</> : <></>}
                      {/* <KeyboardArrowDownIcon style={{ fill: "#D4D4D4", marginBottom: "-7px" }} /> */}
                      {/* this.populateDataForEdit(data?.id) */}
                      {/* <Typography className={classes.mainTableData} style={{ color: "#5696F4", marginBottom: "7px" }} align="right">{this.state.userAction === 'delete' ? <span>{this.state.userAction[index]}</span> : <span onClick={() => { this.setState({ dummy1: true }), this.saveEditId(data?.id, data?.attributes?.email, data?.attributes?.first_name, data?.attributes?.last_name, data?.attributes?.role, data?.attributes?.user_name), this.edditId1 = data?.id, this.setState({ userData }) }}>{this.state.userAction[index]}</span>}<KeyboardArrowDownIcon style={{ fill: "#D4D4D4", marginBottom: "-7px" }} onClick={(e) => {
                        console.log("halum", index)
                        if (this.state.openEditPopover === true) {
                          this.setState({ openEditPopover: false, anchorElUserEdit: null });
                        } else {
                          this.setState({
                            openEditPopover: true,
                            anchorElUserEdit: e.currentTarget,
                          });
                        }
                      }} /> </Typography> */}
                      <Typography className={classes.mainTableData} style={{ color: "#5696F4", marginBottom: "5px", marginTop: "-7px", textAlign: "end", marginRight: "20", overflow:'visible'}} ><span onClick={() => { this.saveEditId(data?.id, data?.attributes?.email, data?.attributes?.first_name, data?.attributes?.last_name, data?.attributes?.role, data?.attributes?.user_name), this.edditId1 = data?.id, this.callUserAccountEditorDeleteApi(index), this.setState({ userName: data?.attributes?.user_name }) }} style={{ cursor: 'pointer' }}>{this.state?.userAction[index]}</span>
                        <NativeSelect
                          IconComponent={KeyboardArrowDownIcon}
                          value={this.state.userAction[index]}
                          style={{ width: "0", textAlign: "right" }}
                          onChange={(e) => { this.changeEditState(e.target.value, index) }}
                          variant="outlined"
                          disableUnderline={true}
                          classes={{
                            outlined: classes.border1,
                            iconOutlined: classes.iconStyle,
                            select: classes.selectArea
                          }}
                          inputProps={{ style: { paddingLeft: '0px', color: "transparent" } }}




                        >


                          <option value={"Edit"} className={classes.mainTableData} style={{ color: "black", padding: "4 4 2 4" }}>Edit</option>
                          <option value={"Delete"} className={classes.mainTableData} style={{ color: "red", padding: "2 4 4 4" }} >Delete</option>




                        </NativeSelect>
                      </Typography>
                      {/* <Popover
                        open={this.state.openEditPopover}
                        onClose={() => {
                          this.setState({ openEditPopover: false });
                        }}
                        //anchorReference='anchorPosition'
                        //anchorPosition={{ top: 700, left: 1000 }}
                        // vertical: (window.innerHeight / 2),
                        //   horizontal: (window.innerWidth / 2.4)
                        anchorEl={this.state.anchorElUserEdit}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      // transformOrigin={{
                      //   vertical: "top",
                      //   horizontal: "center"
                      // }}
                      // classes={{
                      //   paper: classes.popoverPaper
                      // }}
                      >
                        

                        <Box style={{ width: "100%", padding: "10px 15px" }}>


                          <Box style={{ width: "100%" }}>



                            <Typography onClick={() => {
                              //this.editUserAction(2, index)
                              //console.log("halum", data?.id, index)
                              this.setState({ valve1: true })
                            }}>edit</Typography>
                            <Typography onClick={() => {
                              //this.editUserAction(1, index)
                              this.setState({ valve1: true })
                            }}>delete</Typography>
                          </Box>
                        </Box>
                      </Popover> */}
                    </Grid>
                  </Grid>
                </>
              )
            })}
          </Grid>


        </Grid>


        { }
        {this.state.dummy1 ? <>{this.setState({ editUserPage: true })}</> : <></>}


      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(useStyles)(UserAccountSettingWeb);

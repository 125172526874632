import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import StorageProvider from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    // mondayOfTheWeek: any;
    // randomKeyForRoutes: any;
    randomKey: any;
    openTreasure: any;
    formDate: any;
    formText: string;
    formMiles: any;
    formCar: any;
    months: any[];
    currentMonth: any;
    selectedWeek: any;
    weekDays: any[];
    weekDate: any[];
    totalMileageDeduction: number;
    mileageDeductionMonths: any[];
    yearlyMiles: any;
    mileageData: any[];
    cars: any[];
    token: string;
    openModal: boolean;
    addCarModal: boolean;
    newCarName: string;
    activeEdit: any;
    mileageId: any;
    errorMsg: string;
    firstDay: any;
    lastDay: any;
    reportData: any;
    bankCount: any;
    creditCardCount: any;
    estimatedTaxableIncome: any;
    calculatedTax: any;
    totalPaid: any;
    overUnderPaid: any;
    currentYear: any;
    treasureBoxData: any
    notificationData: any
    notificationCountData: any
    gamificationData: any;
    idOfNotiToMarkRead: any;
    isgamificationDataLoaded: boolean;
    taxYearForData: any;
    runTaxApi: boolean;
    mondayData: any;
    tuesdayData: any;
    wednesdayData: any;
    thursdayData: any;
    fridayData: any;
    directTransaction: boolean;
    showSnackbar: boolean;
    snackbarMessage: string;
    updateNotification: boolean;
    bankExpenceCount: any;
    bankIncomeCount: any;
    creditExpenceCount: any;
    creditIncomeCount: any;
    redirectionMileage: boolean;
    companyName: string;
    companyEmail: string;
    companyLogo: string;
    allNotifications:number;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class DashboardController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // arrayholder: any[] = []
    // passwordReg: RegExp;
    // emailReg: RegExp;
    createAccountApiCallId: any;
    getMileagesId: any;
    //createMileageId: any;
    //updateMileageId: any;
    getCarsId: any;
    getReportApiCallId: any;
    //createCarId: any;
    //updateCarId: any;
    loginApiCallId: any;
    validationApiCallId: string = "";

    imgPasswordVisible: any;
    imgPasswordInVisible: any;

    labelHeader: any;
    // labelFirstName: string;
    // lastName: string;
    // labelEmail: string;
    // labelPassword: string;
    // labelRePassword: string;
    // labelLegalText: string;
    // labelLegalTermCondition: string;
    // labelLegalPrivacyPolicy: string;
    // btnTextSignUp: string;

    currentCountryCode: any;
    transactionDesktopId: any;
    getTableSectionId: any;
    getTreasureTip2DataApiId: string = ""
    getnotificationApiId: string = ""
    getnotificationCountApiId: string = ""
    markNotificationViewedApiId: string = ""
    getGamificationDataApiId: string = ""
    nf: any = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
    });

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            // mondayOfTheWeek: this.getCurrentDate(),
            randomKey: Math.random(),
            // randomKeyForRoutes: "",
            openTreasure: false,
            idOfNotiToMarkRead: "",
            formDate: new Date(),
            formText: "",
            months: moment.monthsShort(),
            weekDays: moment.weekdays(),
            currentMonth: new Date().getMonth(),
            selectedWeek: {
                sunday: moment().day(0),
                saturday: moment().day(6),
                currentCount: 0
            },
            weekDate: [],
            mileageId: '',
            totalMileageDeduction: 0,
            mileageDeductionMonths: [],
            yearlyMiles: '00000',
            mileageData: [],
            cars: [],
            newCarName: '',
            activeEdit: '',
            addCarModal: false,
            formCar: "",
            formMiles: "",
            token: "",
            openModal: false,
            errorMsg: '',
            firstDay: moment().day(0).format("MMM D").toUpperCase(),
            lastDay: moment().day(6).format("MMM D").toUpperCase(),
            reportData: {},
            bankCount: 0,
            creditCardCount: 0,
            estimatedTaxableIncome: '',
            calculatedTax: '',
            totalPaid: '',
            overUnderPaid: '',
            currentYear: new Date().getFullYear(),
            treasureBoxData: [],
            notificationData: [],
            notificationCountData: 0,
            gamificationData: [],
            isgamificationDataLoaded: false,
            taxYearForData: new Date().getFullYear(),
            runTaxApi: false,
            mondayData: [false, false, false],
            tuesdayData: [false, false, false],
            wednesdayData: [false, false, false],
            thursdayData: [false, false, false],
            fridayData: [false, false, false],
            directTransaction: false,
            showSnackbar: false,
            snackbarMessage: '',
            updateNotification: true,
            bankExpenceCount: '',
            bankIncomeCount: '',
            creditExpenceCount: '',
            creditIncomeCount: '',
            redirectionMileage: false,
            companyName: "",
            companyEmail: "",
            companyLogo: "",
            allNotifications:0,
            // Customizable Area End
        };

        setTimeout(() => {
            this.getNotifications();
            this.getNotificationCount();
            // this.getTreasureTip2Data();
        }, 1000);

        StorageProvider.set("taxYearChanged", false);
    }

    // async componentDidMount() {
    //     alert('here')
    //     //.scrollTo(0, 0)

    //     setTimeout(() => {
    //         this.getNotifications();
    //         this.getNotificationCount();
    //         this.getTreasureTip2Data();
    //     }, 1000);

    //     StorageProvider.set("taxYearChanged", false);
    //     // this.getGamificationData();
    // }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getMileagesId) {
                    if (!responseJson.errors) {
                        console.log("--------->", responseJson);
                        this.setState({ totalMileageDeduction: 0, mileageDeductionMonths: [], mileageData: [], yearlyMiles: 0 })
                        if (responseJson.data.mileage_deduction.total_mileage_deduction) {
                            this.setState({ totalMileageDeduction: responseJson.data.mileage_deduction.total_mileage_deduction.toFixed(2) })
                        }
                        if (responseJson.data.mileage_deduction.mileage_deduction_month.length) {
                            this.setState({ mileageDeductionMonths: responseJson.data.mileage_deduction.mileage_deduction_month })
                        }
                        if (responseJson.data?.mileage_deduction?.yearly_miles) {
                            console.log("---<>-----", responseJson.data?.mileage_deduction?.yearly_miles)
                            this.setState({ yearlyMiles: responseJson.data?.mileage_deduction?.yearly_miles })
                        }
                        if (responseJson.data.mileages.length) {
                            this.setState({ mileageData: responseJson.data.mileages })
                        }
                    }
                } else if (apiRequestCallId === this.getCarsId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            let data: any[] = []
                            responseJson.data.map((el: any) => {
                                data = data.concat({ id: el.id, name: el.attributes.name })
                            })
                            this.setState({ formCar: data[0].id })
                            this.setState({ cars: data })
                        }
                    }

                } else if (apiRequestCallId === this.getReportApiCallId) {
                    if (responseJson.data) {
                        this.setState({
                            reportData: responseJson?.data[0]
                        })
                    }
                } else if (apiRequestCallId === this.transactionDesktopId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            this.setState({ bankCount: responseJson?.data?.bank?.bank_count, creditCardCount: responseJson?.data?.credit_card?.credit_card_count, bankExpenceCount: responseJson?.data?.bank?.expense_count, bankIncomeCount: responseJson?.data?.bank?.income_count, creditExpenceCount: responseJson?.data?.credit_card?.expense_count, creditIncomeCount: responseJson?.data?.credit_card?.income_count })
                            // console.log("bank account123", responseJson?.data?.bank?.bank_count, responseJson?.data?.credit_card_count)
                        }

                    }

                } else if (apiRequestCallId === this.getTableSectionId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            //console.log("yahuu", responseJson?.data)
                            if (responseJson?.data?.est_taxable_income == null) {
                                this.setState({ estimatedTaxableIncome: 0, calculatedTax: responseJson?.data?.total_tax, totalPaid: responseJson?.data?.tax_paid, overUnderPaid: responseJson?.data?.over_tax_paid })
                            }
                            else {
                                this.setState({ estimatedTaxableIncome: responseJson?.data?.est_taxable_income, calculatedTax: responseJson?.data?.total_tax, totalPaid: responseJson?.data?.tax_paid, overUnderPaid: responseJson?.data?.over_tax_paid })
                            }

                        }
                    }

                }
                else if (apiRequestCallId === this.getTreasureTip2DataApiId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            this.setState({
                                treasureBoxData: responseJson?.data,
                                randomKey: Math.random(),
                                openTreasure: true
                            }, () => {
                                console.log('this.state.randomKey >>> ', this.state.randomKey);

                            })
                        }
                    }
                }
                if (apiRequestCallId === this.getnotificationApiId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            console.log('notification API data ...', responseJson);

                            this.setState({
                                notificationData: responseJson?.data
                            })
                        }
                    }
                }
                if (apiRequestCallId === this.getnotificationCountApiId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            console.log('notification count API data ...', responseJson);

                            this.setState({
                                notificationCountData: responseJson?.data?.treasure_box_count,
                                allNotifications: responseJson?.data?.notification_count
                            })
                        }
                    }
                }
                if (apiRequestCallId === this.markNotificationViewedApiId) {
                    if (!responseJson.errors) {
                        if (responseJson) {
                            let arr = this.state.notificationData?.filter((e: any) => e.id != this.state.idOfNotiToMarkRead)
                            this.setState({ notificationData: arr })
                            console.log('markNotificationViewedApiId ...', responseJson);
                        }
                    }
                }
                if (apiRequestCallId === this.getGamificationDataApiId) {
                    if (!responseJson.errors) {
                        if (responseJson.data) {
                            // this.setState({
                            //     gamificationData: responseJson?.data,
                            //     isgamificationDataLoaded: true
                            let mondayData: any = []
                            let tuesdayData: any = []
                            let wednesdayData: any = []
                            let thursdayData: any = []
                            let fridayData: any = []

                            responseJson.data.map((days: any) => {
                                if (days.attributes.day == "Monday") {
                                    mondayData.push(days.attributes.expense_leaf)
                                    mondayData.push(days.attributes.income_leaf)
                                    mondayData.push(days.attributes.mileage_leaf)

                                }
                                else if (days.attributes.day == "Tuesday") {
                                    tuesdayData.push(days.attributes.expense_leaf)
                                    tuesdayData.push(days.attributes.income_leaf)
                                    tuesdayData.push(days.attributes.mileage_leaf)
                                }
                                else if (days.attributes.day == "Wednesday") {
                                    wednesdayData.push(days.attributes.expense_leaf)
                                    wednesdayData.push(days.attributes.income_leaf)
                                    wednesdayData.push(days.attributes.mileage_leaf)
                                }
                                else if (days.attributes.day == "Thursday") {
                                    thursdayData.push(days.attributes.expense_leaf)
                                    thursdayData.push(days.attributes.income_leaf)
                                    thursdayData.push(days.attributes.mileage_leaf)
                                }
                                else if (days.attributes.day == "Friday") {
                                    fridayData.push(days.attributes.expense_leaf)
                                    fridayData.push(days.attributes.income_leaf)
                                    fridayData.push(days.attributes.mileage_leaf)
                                }
                            })
                            this.setState({
                                gamificationData: responseJson?.data,
                                isgamificationDataLoaded: true,
                                mondayData: mondayData,
                                tuesdayData: tuesdayData,
                                wednesdayData: wednesdayData,
                                thursdayData: thursdayData,
                                fridayData: fridayData
                            })
                        }
                    }
                }

            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async directionalTransaction(id: any) {
        if (id == 1) {
            if (this.state?.bankIncomeCount > this.state.bankExpenceCount) {
                this.setState({ directTransaction: true })
            } else if (this.state?.bankIncomeCount < this.state.bankExpenceCount) {
                await StorageProvider.set("activeTab", 1)
                this.setState({ directTransaction: true })
            } else {
                this.setState({ directTransaction: true })
            }

        } else if (id == 2) {
            if (this.state?.creditIncomeCount > this.state.creditExpenceCount) {
                this.setState({ directTransaction: true })
            } else if (this.state?.creditIncomeCount < this.state.creditExpenceCount) {
                await StorageProvider.set("activeTab", 1)
                this.setState({ directTransaction: true })
            } else {
                this.setState({ directTransaction: true })
            }
        } else {
            this.setState({ directTransaction: true })
        }
        // await StorageProvider.set("activeTab", 1)
        // this.setState({ directTransaction: true })
    }
    getTransactionData(year: any) {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.transactionDesktopId = requestMessage.messageId;
        const yearSearch = year ? year : moment().format('YYYY')
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.transactionApiEndPoint}/get_transaction_count?tax_year=${yearSearch}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getTableData(value: any) {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTableSectionId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxApiEndPoint}/get_table_tax_data?tax_year=${value ? value : this.state.taxYearForData}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    updateDate = () => {
        var date: any = localStorage.getItem('mileageDate');
        console.log(date, "date");
        date = new Date(date);
        if (date > new Date()) {
            this.setState({ showSnackbar: true, snackbarMessage: 'Not allowed to update mileage for future date' })
            console.log("hello hai ")

            return false;
        } else {
            //@ts-ignore
            this.setState({ redirectionMileage: true })
        }
    }

    updateWeek = (num: number, year: any) => {
        console.log(num)
        const pre = num + 6
        if (year && this.state.currentYear != year) {
            const date = year + '-12-31'
            this.setState({
                selectedWeek: {
                    sunday: moment(date).day(num),
                    saturday: moment(date).day(pre),
                    currentCount: num
                }
            }, () => this.allGetApis())
            this.getWeekDays(num, date)
        } else {
            this.setState({
                selectedWeek: {
                    sunday: moment().day(num),
                    saturday: moment().day(pre),
                    currentCount: num
                }
            }, () => this.allGetApis())
            this.getWeekDays(num, null)
        }
    }
    getWeekDays = (num: number, date: any) => {
        let from = num;
        this.setState({ weekDate: [] })
        const weekDates = []
        const to = num + 6;
        while (from <= to) {
            const weekDay = date ? moment(date).day(from) : moment().day(from)
            weekDates.push(weekDay)
            from++
        }
        if (date) {
            this.setState({ firstDay: moment(date).day(0).format("MMM D").toUpperCase(), lastDay: moment(date).day(6).format("MMM D").toUpperCase() })
        }
        this.setState({ weekDate: [...weekDates] })
    }

    showErrorMsg = (msg: string) => {
        this.setState({ errorMsg: msg })
        setTimeout(() => this.setState({ errorMsg: '' }), 2000)
    }
    getMileageData = () => {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMileagesId = requestMessage.messageId;
        console.log("week datessss", this.state.selectedWeek)
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.mileageApiEndPoint}/mileages?start_date=${this.state.selectedWeek.sunday.format('DD/MM/YYYY')}&end_date=${this.state.selectedWeek.saturday.format('DD/MM/YYYY')}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    getCarsData = () => {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCarsId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.mileageApiEndPoint}/cars`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    allGetApis = () => {
        this.getCarsData()
        this.getMileageData()
    }
    matched(index: any, matchedData: any): any {

        for (let count = 0; count < this.state.mileageData[index]?.mileage_data?.length; count++) {
            if (this.state.mileageData[index]?.mileage_data[count]?.date_of_mileage == matchedData) {

                return this.state.mileageData[index]?.mileage_data[count]?.miles;
            }
        }
        return null;

    }

    testy() {
        //let confused: any[];
        //confused = this.state.mileageData[0]?.mileage_data;
        //let confusedAgain = confused.filter(n.date_of_mileage == moment(this.state.weekDate[0]).format('M/D/YYYY'));
        // console.log("cars", this.state.cars);
        // console.log("mileage", this.state.mileageData);
        //console.log("mileage array o to milage data", this.state.mileageData[0]?.mileage_data);
        // console.log("mileage milage data date of mileage", this.state.mileageData[0]?.mileage_data[0]?.date_of_mileage);
        // console.log("mileage formatted date", moment(this.state.mileageData[0]?.mileage_data[0]?.date_of_mileage).format("MMMM Do"));
        // console.log("mileage car id", this.state.mileageData[0]?.mileage_data[0]?.car_id);
        // console.log("mileage miles", this.state.mileageData[0]?.mileage_data[0]?.miles);
        // console.log("week daysssss", moment(this.state.weekDate[0]).format('M/D'));
        // console.log("week dateeeee", this.state.weekDays[0]?.toUpperCase());
        // console.log("world tourist day", this.matched(0, moment(this.state.weekDate[3]).format('MM/DD/YYYY')))
        // console.log("confused", confused);
        // console.log("dateee", moment(this.state.weekDate[2]).format('MM/DD/YYYY'));
        // console.log("date from gather", this.state.mileageData[0]?.mileage_data[0]?.date_of_mileage);
        //console.log("please seeeeee", this.matched(0, this.state.weekDate[6]?.format('MM/DD/YYYY')));

    }
    getReports = (year: any) => {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getReportApiCallId = requestMessage.messageId;
        const fromDate = year ? `${year}-01-01` : undefined
        const toDate = !year ? moment() : year == moment().format('YYYY') ? moment() : `${year}-12-31`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getReport}?start_date=${moment(fromDate).startOf('year').format('DD/MM/YYYY')}&end_date=${moment(toDate).format('DD/MM/YYYY')}&report_type=Financial Statement&transaction_type=expense`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getCurrentDate = (today: any = new Date()) => {
        // let today: any = new Date();
        const yyyy = today.getFullYear();
        let mm: any = today.getMonth() + 1; // Months start at 0!
        let dd: any = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        today = dd + "/" + mm + "/" + yyyy;
        return today;
    };
    getTreasureTip2Data = () => {
        // Customizable Area Start

        const todayDate = this.getCurrentDate();
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTreasureTip2DataApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.treasureData2getUrl + "?date=" + todayDate + "&end_date=" + todayDate
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    getNotifications = () => {
        // Customizable Area Start

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getnotificationApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.notificationEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    getNotificationCount = () => {
        // Customizable Area Start

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getnotificationCountApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.notificationCountEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };


    markNotificationViewed = (notificationID: any) => {
        const body: any = {
            "data": {
                "attributes": {
                    "is_read": true
                }
            }
        }

        const headers = {
            token: localStorage.getItem('token'),
            "Content-Type": configJSON.dashboarContentType
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.markNotificationViewedApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.notificationEndPoint}/${notificationID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    getGamificationData = () => {
        // Customizable Area Start
        let p: any = moment().startOf('isoWeek')
        // let date: any = new Date(p)
        let date: any = new Date()
        const mondayOfTheWeek = this.getCurrentDate(date);
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getGamificationDataApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.gamificationEndPoint + "?date=" + mondayOfTheWeek
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    async updateLocalStorageData() {
        return this.setState({
            companyName: await StorageProvider.get('companyName'),
            companyEmail: await StorageProvider.get('companyEmail'),
            companyLogo: await StorageProvider.get('companyLogo')
        })
    }
    // Customizable Area End
}
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FlatList, Image, ImageSourcePropType, StyleSheet, Text, TextStyle, TouchableOpacity, View } from 'react-native';
import { scaledSize } from '../../framework/src/Utilities';
type CheckBoxGroupType = {
    name: string;
    isDisable: boolean;
    disableCheckBoxSelected: boolean;
}
type MyProps = { testID: string, checkBoxGroup: boolean; checkBoxGroupTitle: string; checkBoxGroupData?: CheckBoxGroupType[]; rightClickIcon?: ImageSourcePropType; checkBoxButtonStyle?: object; checkBoxTextStyle?: TextStyle | TextStyle[]; checkBoxDisableSelectedButtonStyle?: object; checkBoxDisableButtonStyle?: object; disableCheckBoxTextStyle?: object; viewGradientColorStyle?: string[]; checkBoxText: string; onCheckBoxPress?: () => void; isCheckBoxSelected: boolean; ischeckBoxDisable?: boolean; checkBoxDisableSelected?: boolean; checkBoxGroupTitleStyle?: TextStyle };
type MyState = { isCheckBoxSelected: boolean };

export default class CheckBox extends Component<MyProps, MyState> {
    static propTypes = {
        testID: PropTypes.string,
        checkBoxGroup: PropTypes.bool.isRequired,
        checkBoxGroupTitle: PropTypes.string.isRequired,
        checkBoxGroupData: PropTypes.array,
        rightClickIcon: PropTypes.string,
        checkBoxButtonStyle: PropTypes.object,
        checkBoxTextStyle: PropTypes.object,
        checkBoxDisableSelectedButtonStyle: PropTypes.object,
        checkBoxDisableButtonStyle: PropTypes.object,
        disableCheckBoxTextStyle: PropTypes.object,
        viewGradientColorStyle: PropTypes.array,
        checkBoxText: PropTypes.string.isRequired,
        onCheckBoxPress: PropTypes.func.isRequired,
        isCheckBoxSelected: PropTypes.bool.isRequired,
        ischeckBoxDisable: PropTypes.bool,
        checkBoxDisableSelected: PropTypes.bool,
        checkBoxGroupTitleStyle: PropTypes.object
    };

    constructor(props: any) {
        super(props);

        this.state = {
            isCheckBoxSelected: this.props.isCheckBoxSelected
        };
    }

    renderCheckBoxView = (item: CheckBoxGroupType, index: number) => {
        const { isCheckBoxSelected, rightClickIcon, onCheckBoxPress } = this.props;
        return (
            <View style={styles.container}>
                <TouchableOpacity style={[styles.sqaureBoxStyle, { backgroundColor: isCheckBoxSelected ? "#3876F1" : "#FFFFFF" }]} onPress={onCheckBoxPress}>
                    {isCheckBoxSelected && rightClickIcon ? <Image source={rightClickIcon} style={styles.rightIconStyle} /> : null}
                </TouchableOpacity>
                <Text style={styles.titleTextStyle}>{item.name}</Text>
            </View>
        )
    }

    render() {

        const { testID, checkBoxGroupData, rightClickIcon, checkBoxButtonStyle, checkBoxTextStyle, checkBoxDisableSelectedButtonStyle, checkBoxDisableButtonStyle, disableCheckBoxTextStyle, viewGradientColorStyle, checkBoxText, onCheckBoxPress, isCheckBoxSelected, ischeckBoxDisable, checkBoxDisableSelected, checkBoxGroupTitleStyle } = this.props;

        if (checkBoxGroupData && checkBoxGroupData.length > 0) {
            return (
                <FlatList
                    data={checkBoxGroupData}
                    renderItem={({ item, index }: { item: CheckBoxGroupType, index: number }) => this.renderCheckBoxView(item, index)}
                />
            )
        } else {
            return (
                <View style={styles.container}>
                    <TouchableOpacity style={[styles.sqaureBoxStyle]} onPress={onCheckBoxPress} testID={testID} disabled={checkBoxDisableSelected}>
                       {isCheckBoxSelected && rightClickIcon ? <Image source={rightClickIcon} style={styles.rightIconStyle} /> : null}
                    </TouchableOpacity>
                    <Text style={[styles.titleTextStyle, checkBoxTextStyle]}>{checkBoxText}</Text>
                </View>
            )
        }
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        height: scaledSize(24),
    },
    sqaureBoxStyle: {
        height: scaledSize(20),
        width: scaledSize(20),
        borderRadius: scaledSize(3),
        borderColor: "#64748B",
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    titleTextStyle: {
        fontSize: scaledSize(13),
        fontFamily: "OpenSans-Regular",
        color: "#0F172A",
        marginLeft: scaledSize(8),
        width: scaledSize(150)
    },
    rightIconStyle: {
        position: 'absolute',
        top: 0,
        maxHeight: scaledSize(30),
        maxWidth: scaledSize(30),
        resizeMode: "stretch",
        zIndex: 9
    }
})

// Customizable Area Start
//@ts-nocheck
import React from "react";
import W1Controller from "./W1Controller.web";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import moment from "moment";


const useStyles = (theme) => ({
  root: {
    padding: "30px 0",
  },
  boldFont: {
    fontWeight: "bold"
  },
  borderBox: {
    border: "1px solid #000000",
  },
  moveRight: {
    paddingLeft: "5px"
  },
  moveLeft: {
    paddingRight: "5px"
  },
  capitalText: {
    textTransform: 'capitalize'
  },
  dataBox: {
    height: "50px"
  },
  amountBox: {
    background: "#e9f3fe"
  },
  topDataBox: {
    height: "75px"
  },
  font20: {
    fontSize: "20px"
  },
  font19: {
    fontSize: "19px"
  },
  font25: {
    fontSize: "25px"
  },
  font58: {
    fontSize: '58.93px'
  },
  '@media (max-width:1425px)': {
    font20: {
      fontSize: "15px"
    },
    font19: {
      fontSize: "14px"
    },
    font58: {
      fontSize: "44px"
    },
    font25: {
      fontSize: "18.75px"
    }
  },
  '@media (max-width:1100px)': {
    font20: {
      fontSize: "11.25px"
    },
    font19: {
      fontSize: "10.6px"
    },
    font58: {
      fontSize: "33px"
    },
    font25: {
      fontSize: "14px"
    }
  },
})
class W1Web extends W1Controller {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }
  async componentDidMount() {
    this.setState({ token: await StorageProvider.get('token') }, () => {
      this.getData(this.props.taxYear)
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.taxYear != this.props.taxYear) {
      this.getData(nextProps.taxYear)
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container xs={12} justifyContent="center" alignItems="center" className={classes.root}>
        <Grid container item xs={11}>
          <Grid container item xs={12}>
            <Grid container item xs={5} >
              <Grid container item xs={12} className={`${classes.borderBox} ${classes.topDataBox}`}>
                <Grid container item alignItems="center" xs={5} className={classes.moveRight}>
                  <Typography variant="caption" className={`${classes.boldFont} ${classes.font20}`}>Business Name: </Typography>
                </Grid>
                <Grid container item alignItems="center" xs={7}>
                  <Typography variant="caption" className={classes.font19}>{this.state.w1Data && this.state.w1Data.company[0]?.business_name}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={`${classes.borderBox} ${classes.topDataBox}`}>
                <Grid container item alignItems="center" xs={5} className={classes.moveRight}>
                  <Typography variant="caption" className={`${classes.boldFont} ${classes.font20}`}>Business Address:</Typography>
                </Grid>
                <Grid container item alignItems="center" xs={7}>
                  <Typography variant="caption" className={classes.font19}>{this.state.w1Data && this.state.w1Data.company[0]?.company_address}</Typography>
                  <Typography variant="caption" className={classes.font19}>{this.state.w1Data && this.state.w1Data.company[0]?.company_address2}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={3} >
              <Grid container item xs={12} className={`${classes.borderBox} ${classes.topDataBox}`}>
                <Grid container item alignItems="center" xs={6} className={classes.moveRight}>
                  <Typography variant="caption" className={`${classes.boldFont} ${classes.font20}`}>EIN/SSN: </Typography>
                </Grid>
                <Grid container item alignItems="center" xs={6}>
                  <Typography variant="caption" className={classes.font19}>{this.state.w1Data && this.state.w1Data.company[0]?.company_ssn}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={`${classes.borderBox} ${classes.topDataBox}`}>
                <Grid container item alignItems="center" xs={6} className={classes.moveRight}>
                  <Typography variant="caption" className={`${classes.boldFont} ${classes.font20}`}>Business Code:</Typography>
                </Grid>
                <Grid container item alignItems="center" xs={6}>
                  <Typography variant="caption" className={classes.font19}>{this.state.w1Data && this.state.w1Data.company[0]?.business_code}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" xs={2} className={classes.borderBox}>
              <Typography variant="h4" className={`${classes.boldFont} ${classes.font58}`} >{this.props.taxYear ? this.props.taxYear : moment().format('YYYY')}</Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" xs={2} className={classes.borderBox}>
              <Typography variant="h4" className={`${classes.boldFont} ${classes.font58}`}>
                <Typography variant="caption" className={`${classes.boldFont} ${classes.font19}`}>Form</Typography>W-1</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {this.state.dataToRender.length ? this.state.dataToRender.map(el => (
              <Grid container item xs={6}>
                <Grid container item xs={12} className={`${classes.borderBox} ${classes.dataBox}`}>
                  <Grid container item alignItems="center" xs={4} className={classes.moveRight}>
                    <Typography variant="caption" className={`${classes.boldFont} ${classes.font20}`}>{el.name}:</Typography>
                  </Grid>
                  <Grid container item alignItems="center" xs={8}>
                    <Typography variant="caption" className={classes.font20}>{el.text}</Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid container item justifyContent="flex-start" alignItems="center" xs={5} className={`${classes.borderBox} ${classes.dataBox}`}>
                    <Typography variant="caption" className={`${classes.boldFont} ${classes.moveRight} ${classes.font20}`}>LINE ITEM</Typography>
                  </Grid>
                  <Grid container item justifyContent="center" alignItems="center" xs={2} className={`${classes.borderBox} ${classes.dataBox}`}>
                    <Typography variant="caption" className={`${classes.boldFont} ${classes.font20}`}>LINE#</Typography>
                  </Grid>
                  <Grid container item justifyContent="flex-end" alignItems="center" xs={5} className={`${classes.borderBox} ${classes.dataBox}`}>
                    <Typography variant="caption" className={`${classes.boldFont} ${classes.moveLeft} ${classes.font20}`}>TOTAL</Typography>
                  </Grid>
                </Grid>
                {this.state.totalKeys.map((el2, index) => (
                  <Grid container item xs={12}>
                    <Grid container item justifyContent="flex-start" alignItems="center" xs={5} className={`${classes.borderBox} ${classes.dataBox}`}>
                      <Typography variant="caption" className={`${classes.moveRight} ${classes.capitalText} ${classes.font19}`}>{el.data[index] ? el.data[index].key : ""}</Typography>
                    </Grid>
                    <Grid container item justifyContent="center" alignItems="center" xs={2} className={classes.borderBox}>
                      <Typography variant="caption" className={`${classes.boldFont} ${classes.font25}`}>{el.data[index] ? el.data[index].number : ""}</Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-end" alignItems="center" xs={5} className={`${classes.borderBox} ${classes.amountBox}`}>
                      <Typography variant="caption" className={`${classes.moveLeft} ${classes.font20}`}>{el.data[index] && el.data[index].value !== -0 ? `$${el.data[index].value}` : "$0.00"}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )) : <></>}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(W1Web)
// Customizable Area End
//@ts-nocheck
import React from "react";
import {
  // Customizable Area Start
  withStyles,
  Checkbox,
  TextField,
  Typography,
  InputAdornment,
  Grid,
  Button,
  Modal,
  Snackbar
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { Link, Redirect } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import EmailAccountRegistrationController, {Props} from "./EmailAccountRegistrationController";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import envelope from "../assets/envelope.png";
import TopNav from "../../../components/src/TopNav";
import { number } from "yup";
import StorageProvider from "../../../framework/src/StorageProvider.web";

// Customizable Area End

// Customizable Area Start
const useStyles = (theme) => ({
  root: {
    paddingTop: "60px",
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
  },
  pageTitle: {
    fontWeight: "700",
    fontSize: "40px",
    display: "inline-block",
    position: "relative",
    paddingBottom: "3px",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "30%",
      height: "5px",
      bottom: "0",
      left: "35%",
      borderBottom: "3px solid #B5D8FD",
    },
    '@media (max-width:1425px)': {
      fontSize: "30px"

    },
  },
  underLine: {
    fontWeight: "bold",
    position: "relative",
    paddingBottom: "3px",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "50%",
      height: "5px",
      bottom: "0",
      left: "25%",
      borderBottom: "0.5px solid #3F4144",
    },
  },
  leafGrid: {
    gap: "10px",
  },
  normalUrl: {
    color: "#7BAEF7",
    textDecoration: "none",
  },
  orangeText: {
    fontSize: "30px",
    color: "#FF9B31",
    fontWeight: '800'
  },
  form: {
    gap: "20px",
    zIndex: 1,
    maxWidth: "28%"
  },
  textBtn: {
    padding: "0 5px",
    fontWeight: "800",
    fontSize: "30px",
    cursor: "pointer",
  },
  formBtn: {
    textTransform: "none",
    backgroundColor: "#5597F4",
    borderRadius: "30px",
    //width: "50%",
    "&:hover": {
      backgroundColor: "#5597F4",
    },
    "& p": {
      fontWeight: "bold",
      color: "#ffffff",
      fontSize: "13px",
      '@media (max-width:1425px)': {
        fontSize: "9.75px"

      },
    },

  },

  secondaryTitle1: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: "500",
    margin: "40 0",
    '@media (max-width:1425px)': {
      fontSize: '18.75px',

    },
  },
  secondaryTitle2: {
    color: "#000000",
    fontSize: "30px",
    fontWeight: "700",
    '@media (max-width:1425px)': {
      fontSize: '22.5px',

    },
  },
  bodyParagraph1: {
    color: "#3F4144",
    fontSize: "20px",
    fontWeight: "400",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  countStepStyle: {
    color: "#000000",
    fontSize: "26px",
    fontWeight: "500",
    '@media (max-width:1425px)': {
      fontSize: '19.5px',

    },
  },


  checkBox: {
    padding: 5
  },
  textField: {
    "& input": {
      fontSize: "20px",
    }
  },
  iconClass: {
    fill: "#D4D4D4"
  },
  font20px: {
    fontSize: "20px",
    fontWeight: "700"
  },
  font25px: {
    fontSize: "25px"
  },

});
// Customizable Area End
export class EmailVerification extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      loginPage: true,
      ...this.state,
      // Customizable Area End
    };
  }



  // Customizable Area Start
  goToDashboard() {
    this.props.history.push('/dashboard');
  }
  updatePage = () => {
    this.setState({ loginPage: !this.state.loginPage, userName: "", email: "", password: "", reTypePassword: "", phone: "" })
  }
  updateValue = (name, value) => {


    if (name.includes('phone')) {
      const numberValue = value.replace(/[^\d]/g, "")
      if (numberValue.length > 10) return
      value = numberValue.length > 3 && numberValue.length < 7 ? '(' + numberValue.substr(0, 3) + ')' + numberValue.substr(3)
        : numberValue.length > 6 ? '(' + numberValue.substr(0, 3) + ')' + numberValue.substr(3, 3) + '-' + numberValue.substr(6)
          : numberValue


      this.setState({ phone: value });
    } else {
      this.setState({ [name]: value });
    }

  };
  async componentDidMount() {
    this.setState({ signUpEmail: await StorageProvider.get('signupEmail') })

  }
      // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        className={classes.root}
      >
        <Grid
          item
          container
          direction="column"
          xs={4}
          style={{ maxWidth: "33%" }}
          alignItems="center"
          className={classes.form}
        >
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.errorMessage}
            onClose={() => {
              this.setState({
                errorMessage: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity="error"
              elevation={6} variant="filled"
            >Account not exist.</Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.errorMessage1}
            onClose={() => {
              this.setState({
                errorMessage1: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity="error"
              elevation={6} variant="filled"
            >Account not activated.</Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.successMessage}
            onClose={() => {
              this.setState({
                successMessage: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity="success"
              elevation={6} variant="filled"
            >Email activation link sent to your email.</Alert>
          </Snackbar>
          <Grid item container xs={12} justifyContent="center">

            <Typography variant="h5" className={classes.pageTitle}>
              Welcome to Neeuh!
            </Typography>

          </Grid>

          <Grid>
            <Typography variant="h6" className={classes.secondaryTitle1}>
              A few more steps to get you started.
            </Typography>
          </Grid>

          <Grid>
            <Typography variant="h6" className={classes.countStepStyle}>
              STEP 1 OF 3
            </Typography>
          </Grid>

          <Grid>
            <Typography variant="h6" className={classes.secondaryTitle2}>
              Email Verification
            </Typography>
          </Grid>
          <Grid>
            <img src={envelope} style={{ height: "37", width: "58.58" }} />
          </Grid>
          <Grid>
            <Typography variant="h6" className={classes.bodyParagraph1} style={{ textAlign: "center" }}>
              You have successfully created a Neeuh Account.
              Please click the link below to verify your email
              address and complete your registration.
            </Typography>
          </Grid>

          {/* {this.state.goToDashboard && this.state.loginSuccess ? <Redirect to={'/dashboard'} /> : <></>} */}
          {/* {this.state.goToChangePassword && this.state.loginSuccess ? <Redirect to={'/changepassword'} /> : <></>} */}


          {/* {this.state.signedUp ? <Redirect to={'/confirmationsignup'} /> : <></>} */}


          <Grid item container xs={12} justifyContent="center">
            <Button
              variant="contained"

              className={classes.formBtn}
              style={{ margin: "40 0" }}
              onClick={() => { this.sendVerification(this.state.signUpEmail) }}
            >

              <Typography >SEND EMAIL</Typography>

            </Button>
          </Grid>

        </Grid>
        <Grid item container xs={11} justifyContent="flex-end" style={{ paddingBottom: "40" }}>
          <Button
            variant="contained"

            className={classes.formBtn}
            style={{ width: "auto" }}
            onClick={() => {
              //this.setState({ goToSignupPayment: true }) 
              this.setState({ errorMessage1: true })
            }}
            data-testid="continue-button"
          >

            <Typography variant="body2">CONTINUE</Typography>

          </Button>
        </Grid>
        {this.state.goToSignupPayment ? <Redirect to={'/signupsubscriptionplan'} /> : <></>}

      </Grid>
      // Customizable Area End

    );
  }
}

export default withStyles(useStyles)(EmailVerification);

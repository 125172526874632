// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputBase,
  Divider,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import VerifyAccountController from './VerifyAccountController.web';
import { lockWithChecked } from "./assets";
import {ReactComponent as PhoneSvg} from '../assets/phone.svg';
import { CardPng } from "./assets";
const useStyles = (theme) => ({
  gridBox:{
    padding: "20px 0 20px 0",
    borderRadius:7,
    border:"1px solid #AEAEAE",
    margin:"5px 0px",
    height:82.67,
    width:526,
    margin:"20px 0"
  },
  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:21.75
        }
  },
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  phoneSvg:{
    width:75,
    height:85,
    paddingBottom:10,
    '@media (max-width:1425px)':{
      width:55,
    height:65,
        }
  },
  headerText:{
    fontSize:18,
    fontWeight:700,
    color:"#3F4144",
    paddingRight:5,
    '@media (max-width:1425px)':{
    fontSize:13.5
        }
  },

  desText:{
    fontSize:16,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:12
        }
  },
  envelopesvg:{
    width:19,
    height:12
  },
  text2:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
    
  },
  verifyButton:{
    width:163,
    height:28,
    color:"white",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0",
    "&:hover": {
      backgroundColor: "#5696F4",
  },
  '@media (max-width:1425px)':{
    width:120,
    height:28,
    fontSize:9.75
      }
  },
  input:{
    paddingTop:4,
    PaddingLeft:20,
    width:40,
    color:"black",
    borderBottom:"1px solid #AEAEAE",
    '@media (max-width:1000px)':{
      width:30
        }
  },
  padding:{
    paddingTop:80
  },
  link:{
    color:"#5696F4",
    fontSize:18,
    fontWeight:400,
    marginTop:10,
    '@media (max-width:1425px)':{
      fontSize:13.5
        }

  },
  
})
class CheckPhoneViaCodeWeb extends VerifyAccountController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" className={classes.padding}>

           <Grid item container lg={8} xs={8}  >
           <form onSubmit={this.verifyPhoneOtp}>
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                <PhoneSvg className={classes.phoneSvg}/>   
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                   Let's verify your phone number.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment} >
                        <Typography className={classes.text}>
                        We've texted  <b className={classes.headerText}>{localStorage.getItem("phoneNumber") ?localStorage.getItem("phoneNumber"): "(000)000-0000"}</b>a code. Enter it below to verify your phone number.
                        </Typography>
                   
                </Grid>
               
                  <Grid item container xs={12} lg={12} spacing={3} justifyContent="center" alignItems="center" style={{margin:"10px 0 20px 0"}}>
                    
                      <Grid item xs={2} lg={2} md={2}>
                      <TextField 
                              required
                              id="txt1"
                              autoFocus={true}
                              className={classes.input}
                              onKeyUp={(event)=>{this.move(event,'','txt1','txt2')}}
                              value={this.state.first}
                              onChange={(e)=>this.setState({first:e.target.value})}
                            
                              InputProps={{disableUnderline:true,   
                              }}
                              inputProps={{
                                  maxLength: 1
                                }}
                                />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2} >
                          <TextField
                          required
                           value={this.state.second}
                          id="txt2"
                          onKeyUp={(event)=>{this.move(event,'txt1','txt2','txt3')}}
                           onChange={(e)=>this.setState({second:e.target.value})}
                            InputProps={{disableUnderline:true,   
                          }}
                          inputProps={{
                              maxLength: 1
                            }}
                          className={classes.input}
                          />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2} style={{justifyContent:"center", alignContent:"center"}}>
                          <TextField
                        required
                        
                         value={this.state.third}
                          id="txt3"
                          onKeyUp={(event)=>{this.move(event,'txt2','txt3','txt4')}}
                         onChange={(e)=>this.setState({third:e.target.value})}
                          InputProps={{disableUnderline:true,   
                          }}
                          inputProps={{
                              maxLength: 1
                            }}
                          className={classes.input}
                          />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2}>
                          <TextField
                          required
                           value={this.state.fourth}
                            id="txt4"
                            onKeyUp={(event)=>{this.move(event,'txt3','txt4','txt5')}}
                           onChange={(e)=>this.setState({fourth:e.target.value})}
                            InputProps={{disableUnderline:true,   
                            }}
                            inputProps={{
                                maxLength: 1
                              }}
                          className={classes.input}
                          />
                      </Grid>
                    
                    
                  </Grid>
               
                <Grid item container xs={12} lg={12} className={classes.alignment}>
                  <Button variant="contained" className={classes.verifyButton} type="submit" >
                    Confirm Code
                  </Button>

                </Grid>
                <Grid item container xs={12} lg={12} className={classes.alignment}>
                   
                <Button variant="text" style={{textTransform:"none"}}  className={classes.link} onClick={this.resendPhoneVerificationCode}>
                      Resend Code
                    </Button>

                </Grid>
                </form>
           </Grid>
           <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        </Grid>
        
    );
  }
}

export default withStyles(useStyles)(CheckPhoneViaCodeWeb)
// Customizable Area End
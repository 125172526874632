Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Calendar";
exports.labelBodyText = "Calendar Body";

exports.btnExampleTitle = "CLICK ME";
exports.updateAPiMethod = "PUT";
exports.vehicleAPIEndpoint = "bx_block_mileage/cars"

exports.yearToDateBusinessMileTitle = "Year to Date Business Miles"
exports.totalMonthlyMile = "Total Monthly Miles";
exports.totalDeduction = "Total Deduction";
exports.noMileageRecord = "NO MILEAGE RECORDED";
exports.getMileageApiEndPoint = "bx_block_mileage/mileages?";
exports.getCarsApiEndPoint = "bx_block_mileage/cars";
exports.addBusiness = "Add business vehicle to";
exports.recordYourMiles = " to record your miles everyday.";
exports.emptyMilesMessage = "Enter business miles";
exports.emptyBusinessPurpose = "Select business purpose";
exports.addMileageApiEndPoint = "bx_block_mileage/mileages";
// Customizable Area End
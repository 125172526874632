//@ts-nocheck
import React from "react";
// Customizable Area Start
import ReactDOM from 'react-dom';
import TransactionsController from "./TransactionsController.web";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import TransactionDetailsModalWeb from "./TransactionDetailsModal.web";
import AddOrEditAccountModalWeb from "./AddOrEditAccountModal.web";
import { Link } from "react-router-dom";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import Alert from '@material-ui/lab/Alert';
import moment from "moment";

import {
  Typography,
  Button,
  withStyles,
  Grid,
  Box,
  Snackbar,
  Select,
  MenuItem,
  ClickAwayListener
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
const useStyles = (theme) => ({
  tabButton: {
    borderRadius: "0",
    border: "1px solid #ffffff",
    textTransform: "capitalize"
  },
  activeTabButton: {
    color: "#5696F4"
  },
  formSelect: {
    textTransform: "capitalize",
    padding: "4px",
    width: "100%",
    "& svg": {
      width: "20px",
    }
  },
  selectOption: {
    textTransform: 'capitalize'
  },
  addAccountButton: {
    margin: "10px 55px",
    width: "220px",
    height: "auto",
    textAlign: "center",
    borderRadius: "20px",
    background: "#5597F4",
    padding: "5px 20px",
    "& h6": {
      color: "#ffffff",
      margin: 'auto'
    },
    "&:hover": {
      background: "#5597F4",
    }
  },
  orangeLeaf: {
    backgroundColor: "#FF9A30",
    width: "30px",
    height: "30px",
    borderRadius: "15px 0",
    margin: "2px 5px",
  },
  blueLeaf: {
    backgroundColor: "#5696F4",
    width: "30px",
    height: "30px",
    borderRadius: "15px 0",
    margin: "2px 5px",
  },
  boldFont: {
    fontWeight: "600"
  },
  topDiv: {
    marginTop: "20px",
  },
  whiteFont: {
    color: "#ffffff"
  },
  orangeCircle: {
    width: "120px",
    height: "120px",
    flexShrink: 0,
    background: "linear-gradient(to right, #FF860D, #FFCC99)",
    borderRadius: "50%",
    cursor: 'pointer'
  },
  positionAbsolute: {
    position: "absolute",
    width: "100%"
  },
  blueCircleTransaction: {
    width: "120px",
    height: "120px",
    flexShrink: 0,
    background: "linear-gradient(to right, #0D016A, #5597F4)",
    borderRadius: "50%",
    cursor: 'pointer'
  },
  whiteCircle: {
    width: "110px",
    height: "110px",
    padding: "5px 0",
    background: "#ffffff",
    borderRadius: "50%"
  },
  blueCircle: {
    width: "120px",
    height: "120px",
    flexShrink: 0,
    marginRight: "5px",
    background: "#15066A",
    borderRadius: "50%"
  },
  addButton: {
    width: "90px",
    height: "90px",
  },
  addIcon: {
    fill: "#ffffff",
    width: "50px",
    height: "50px",
    strokeWidth: "2px"
  },
  links: {
    textDecoration: 'none',
    color: "#000000"
  },
  transactionCircles: {
    overflowX: "scroll",
    scrollbarWidth: "none",
    gap: "27px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "& > div:first-child": {
      marginLeft: 'auto'
    }
  },
  rightDiv: {
    borderBottom: "1px solid #000000",
    borderLeft: "1px solid #000000",
    borderRight: "1px solid #000000",
    background: "#ececec",
    gap: "10px"
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    margin: "10px 0",
    width: "202px",
    height: "98px",
    background: "#ffffff",
    "& p": {
      textAlign: 'center',
      padding: "0 5px",
      textTransform: "capitalize"
    }
  },
  cardSmall: {
    height: '38.52px'
  },
  boxBlue: {
    margin: "0 10px",
    width: "18px",
    height: "18px",
    background: "#B6D9FD",
    border: "1px solid #AFB0B0"
  },
  boxGray: {
    margin: "0 10px",
    width: "18px",
    height: "18px",
    background: "#AFB0B0",
    border: "1px solid #AFB0B8"
  },
  smallOrangeLeaf: {
    width: "14px",
    height: "14px",
    borderRadius: "7px 0",
    margin: "0 10px",
    background: "#FF9A30"
  },
  categoryGroup: {
    gap: "10px",
  },
  category: {
    position: 'relative',
    margin: "10px 30px",
    height: "65px",
    maxHeight: "65px",
    width: "226px",
    border: "1px solid #BBBABA",
    borderLeft: "10px solid #B6D9FD"
  },
  userCategory: {
    position: 'relative',
    margin: "10px 30px",
    height: "65px",
    maxHeight: "65px",
    border: "1px solid #BBBABA",
    borderLeft: "10px solid #AFB0B0"
  },
  categoryWithLeaf: {
    position: 'absolute',
    top: "-5px",
    right: "-5px",
    width: '21px',
    height: '21px',
    borderRadius: "9px 0",
    background: "#FF9A30"
  },
  firstThreeCategory: {
    width: "226px",
    height: "94px",
    maxHeight: "94px"
  },
  catName: {
    wordBreak: "break-all",
    textAlign: "center",
    textTransform: "capitalize"
  },
  displayNone: {
    display: 'none'
  },
  displayNormal: {
    display: '',
  },
  textFont: {
    fontSize: "12px",
    width: "100%",
    padding: "0 3px",
    fontWeight: "600",
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  smallTextFont: {
    maxWidth: "70%"
  },
  textDate: {
    color: '#BABCBA',
    marginTop: "10px",
    fontWeight: 'inherit'
  },
  blueName: {
    color: "#597EF7",
  },
  navigationButton: {
    "& svg": {
      fill: "#6BA4F5",
      fontSize: "30px"
    },
    "&:hover": {
      background: "#ffffff"
    }
  },
  bottomDiv: {
    borderTop: "1px solid black",
    minHeight: "350px"
  },
  midDiv: {
    padding: "15px 0",
    height: "200px"
  },
  verticalLine: {
    width: "2px",
    height: "40px",
    background: "#9f9f9f"
  },
  catDef: {
    paddingTop: "10px"
  },
  font25: {
    fontSize: "25px"
  },
  font20: {
    fontSize: "20px"
  },
  font16: {
    fontSize: "16px"
  },
  font13: {
    fontSize: "13px"
  },
  font14: {
    fontSize: "14px"
  },
  font15: {
    fontSize: "15px"
  },
  '@media (max-width:1425px)': {
    font20: {
      fontSize: "15px"
    },
    font16: {
      fontSize: "12px"
    },
    font15: {
      fontSize: "11.25px"
    },
    font14: {
      fontSize: '10.5px'
    },
    font13: {
      fontSize: "9.75px"
    },
    font25: {
      fontSize: "18.75px"
    },
    category: {
      margin: "10px 15px",
      width: "169.5px",
      height: "48.75px",
    },
    userCategory: {
      margin: "10px 15px",
      width: "169.5px",
      height: "48.75px"
    },
    firstThreeCategory: {
      width: "169.5px",
      height: "70.5px"
    },
    addAccountButton: {
      width: "180px"
    },
    cards: {
      width: '151.5px',
      height: '73.5px'
    },
    cardSmall: {
      height: '28.89px'
    },
    smallOrangeLeaf: {
      width: "10.5px",
      height: "10.5px",
      borderRadius: "5.25px 0"
    },
    boxGray: {
      width: "13.5px",
      height: "13.5px"
    },
    boxBlue: {
      width: "13.5px",
      height: "13.5px"
    },
    categoryWithLeaf: {
      width: "15.75px",
      height: "15.75px",
      borderRadius: "7px 0"
    }
  },
  '@media (max-width:1250px)': {
    font20: {
      fontSize: "10px"
    },
    font16: {
      fontSize: "9px"
    },
    font13: {
      fontSize: "7.3px"
    },
    font25: {
      fontSize: "14px"
    },
    orangeCircle: {
      width: "90px",
      height: "90px"
    },
    blueCircleTransaction: {
      width: "90px",
      height: "90px"
    },
    whiteCircle: {
      width: "82.5px",
      height: "82.5px"
    },
    blueCircle: {
      width: "90px",
      height: "90px"
    },
    addIcon: {
      width: "37.5px",
      height: " 37.5px"
    },
    cards: {
      width: '151.5px'
    }
  }
})
// Customizable Area End
export class TransactionsWeb extends TransactionsController {
  // Customizable Area Start
  constructor(props: any) {
    super(props);
    this.scrollRef = React.createRef()
    this.circleRef = React.createRef()
    this.state = {
      ...this.state,
    };
  }
  async componentDidMount() {

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    let notificableType = await StorageProvider.get('push_notificable_type')

    let activeTab: any;
    if (await StorageProvider.get('activeTab') == 0) {
      activeTab = 0;
    }
    if (await StorageProvider.get('activeTab') == 1) {
      activeTab = 1;
    }

    await StorageProvider.set('activeTab', 0);



    switch (notificableType) {
      case "BxBlockTransactions::Income":
        activeTab = 0;
        break;
      case "BxBlockTransactions::Expense":
        activeTab = 1
        break;
    }



    window.scrollTo(0, 0)
    this.setState({ token: await StorageProvider.get('token'), activeTab: activeTab, taxYear: this.props.taxYear }
      , () => this.onLoad())
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.taxYear != this.props.taxYear) {
      this.setState({ taxYear: nextProps.taxYear }, () => this.onLoad())
    }
  }


  resize() {
    const currWidth = this.scrollRef?.current?.clientWidth
    const eleWidth = this.circleRef?.current?.clientWidth
    const ele = this.state.transactionData?.length + 1
    let eleToDisplay = Math.floor(currWidth / eleWidth)
    if (ele > 4) {
      eleToDisplay = eleToDisplay < ele ? eleToDisplay : ele
      const spaceLeft = (currWidth % eleWidth)

      if (!this.scrollRef?.current?.style?.gap) {
        return;
      }

      if (spaceLeft > 30) {
        this.scrollRef.current.style.gap = `${(spaceLeft / eleToDisplay)}px`
      }
      else this.scrollRef.current.style.gap = `${(spaceLeft + eleWidth) / (eleToDisplay - 1)}px`
    } else {
      this.scrollRef.current.style.gap = `10px`
    }
  }

  classCheck(catFrom, tier, classes) {
    if (catFrom) {
      if (tier[0].tier !== 1) {
        return `${classes.userCategory}`
      } else {
        return `${classes.userCategory} ${classes.firstThreeCategory}`
      }
    } else {
      if (tier[0].tier !== 1) {
        return `${classes.category}`
      } else {
        return `${classes.category} ${classes.firstThreeCategory}`
      }
    }
  }

  classCheckSideBar(el, classes) {
    if (el.name === 'State Taxes' || el.name == 'Federal Taxes') return `${classes.links} ${classes.cards} ${classes.cardSmall}`
    else return `${classes.links} ${classes.cards}`
  }
  onDropFunc(event, el) {
    if (el.name == 'expense_reimbursement') this.setState({ dropEventDataTransferId: event.dataTransfer.getData('id') }, () => this.getExpenseCategoriesMain())
    else this.dropOver(event, el.id)
  }

  updatedScroll(positive) {
    if (positive) {
      this.scrollRef.current.scrollLeft += parseInt(this.scrollRef.current.style.gap.replace('px', '')) + this.circleRef.current?.clientWidth;
      if (!this.state.showArrow && this.scrollRef.current.scrollLeft) this.setState({ showArrow: true })
    } else {
      this.scrollRef.current.scrollLeft -= parseInt(this.scrollRef.current.style.gap.replace('px', '')) + this.circleRef.current?.clientWidth
      if (!this.scrollRef.current.scrollLeft) this.setState({ showArrow: false })
    }
  }
  checkUpdateScroll() {
    if (this.state.showArrow && this.state.updateScroll) this.setState({ updateScroll: false }, () => this.scrollRef.current.scrollLeft += this.scrollRef.current.offsetLeft)
  }
  checkTier(tier) {
    if (tier === 3) return 2
    else return 3
  }
  checkCircleClass(type, classes) {
    if (type) return classes.orangeCircle
    else return classes.blueCircleTransaction
  }
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start 
      <Grid container xs={12} className={classes.root} justifyContent="center" alignItems="stretch"  >
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={2000}
        >
          <Alert severity={this.state.snackbarState || "success"}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        {this.state.openAddOrEditModal && <AddOrEditAccountModalWeb from={this.state.activeTab} handleAddOrEditModal={this.handleAddOrEditModal} />}
        {this.state.openModal && <TransactionDetailsModalWeb
          data-test-id="transaction-detail-modal"
          showSnackBarMessage={(message) => this.setState({ showSnackbar: true, snackbarMessage: message })}
          handleModal={this.handleModal}
          transactionId={this.state.transactionId}
          from={this.state.activeTab}
          addCats={[{ id: 98, name: "Federal Taxes" }, { id: 99, name: "State Taxes" }]}
        />}
        <Grid item container xs={10} alignItems="center" className={classes.topDiv}>
          <Grid item>
            <Button onClick={() => this.switchTab(0)} className={this.state.activeTab ? classes.tabButton : `${classes.tabButton} ${classes.activeTabButton}`}>
              <Grid container justifyContent="center" alignItems="center" className={classes.blueLeaf} >
                <Typography variant="caption" className={`${classes.font20} ${classes.whiteFont}`}>
                  {this.state.dataLengths[0]}
                </Typography>
              </Grid>
              <Typography variant="subtitle2" className={`${classes.font25} ${classes.boldFont}`}>
                Income
              </Typography>
            </Button>
          </Grid>
          <Grid item className={classes.verticalLine}></Grid>
          <Grid item>
            <Button onClick={() => this.switchTab(1)} className={this.state.activeTab ? `${classes.tabButton} ${classes.activeTabButton}` : classes.tabButton}>
              <Grid container justifyContent="center" alignItems="center" className={classes.orangeLeaf}>
                <Typography variant="caption" className={`${classes.font20} ${classes.whiteFont}`}>
                  {this.state.dataLengths[1]}
                </Typography>
              </Grid>
              <Typography variant="subtitle2" className={`${classes.font25} ${classes.boldFont}`}>
                Expenses
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.midDiv}>
          <Grid container item xs={1} justifyContent="flex-end">
            {(this.scrollRef.current?.scrollLeft && this.state.showArrow) ?
              <>
                <Button className={classes.navigationButton} onClick={() => this.updatedScroll(false)}>
                  <NavigateBeforeIcon />
                </Button>
              </>
              : <Grid className={classes.navigationButton}></Grid>}
          </Grid>
          <Grid container item xs={10} ref={this.scrollRef} wrap="nowrap" className={classes.transactionCircles}>
            {this.state.transactionData.length
              ? this.resize() : console.log('empty')}
            {this.state.transactionData.map(el => {
              this.checkUpdateScroll()
              return (
                <Grid item key={el.id} ref={this.circleRef} container justifyContent="center" draggable onDragStart={(event) => this.dragStart(event, el.id)} alignItems="center" onClick={() => this.setState({ transactionId: el.id, openModal: true })} className={this.checkCircleClass(el.type, classes)}>
                  <Grid item container justifyContent="center" alignItems="center" className={classes.whiteCircle}>
                    <Typography align="center" variant="caption" className={`${classes.textFont} ${classes.textDate} ${classes.font14}`} diplay="block">{moment(el.date).format('MM/DD/YYYY')}</Typography>
                    <Typography align="center" noWrap className={`${classes.textFont} ${classes.blueName}`}>{el.name}</Typography>
                    <Typography align="center" className={`${classes.textFont} ${classes.font15} ${classes.smallTextFont}`}>${Number(el.amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}</Typography>
                  </Grid>
                </Grid>

              )
            })}
            <Grid item container justifyContent="center" alignItems="center" className={classes.blueCircle}>
              <Button data-test-id="modal-button" className={classes.addButton} onClick={() => this.handleModal(true, null)}>
                <ControlPointIcon className={classes.addIcon} />
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={1}>
            <Button className={classes.navigationButton} onClick={() => this.updatedScroll(true)}>
              <NavigateNextIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.bottomDiv} >
          <Grid item container xs={9} alignItems="flex-start" >
            <Grid item container xs={12} className={classes.catDef} justifyContent="space-evenly" alignItems="center">
              <Grid item container xs={4} alignItems="center" justifyContent="flex-end">
                <Box item className={classes.boxBlue}></Box>
                <Typography variant="subtitle2" className={classes.font16}>Schedule C</Typography>
              </Grid>
              <Grid item container xs={4} alignItems="center" justifyContent="center">
                <Box item className={classes.boxGray}></Box>
                <Typography variant="subtitle2" className={classes.font16}>User-Created</Typography>
              </Grid>
              {this.state.activeTab ? <Grid item container xs={4} alignItems="center">
                <Box item className={classes.smallOrangeLeaf}></Box>
                <Typography variant="subtitle2" className={classes.font16}>Cost of Goods Sold</Typography>
              </Grid> : <></>}
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" alignItems="stretch" className={classes.categoryGroup}>
              {this.state.catByTier.map((tier, index) => {
                return (<Grid item key={tier} container xs={11} >
                  {
                    tier.map((el, index) => {
                      return (
                        <Grid item key={el} container xs={this.checkTier(tier[0].tier)} onDragOver={(event) => event.preventDefault()} onDrop={(event) =>
                          this.onDropFunc(event, el)
                        } alignItems="center"
                          className={this.classCheck(el.catFrom, tier, classes)}
                          justifyContent="center">
                          <Box onClick={async () => await StorageProvider.set('id', el.id)}>
                            <Typography variant="subtitle2" className={`${classes.font20} ${classes.catName}`}>{el.name}</Typography>
                            {el.isCOG && <Grid className={classes.categoryWithLeaf}></Grid>}
                          </Box>
                          <ClickAwayListener onClickAway={() => this.state.openExpenseCategoriesDropDown && this.setState({ openExpenseCategoriesDropDown: false })}>
                            <Grid className={classes.positionAbsolute}>
                              {(el.name == 'expense_reimbursement' && this.state.openExpenseCategoriesDropDown) &&
                                <Select id="select_menu"
                                  open={this.state.openExpenseCategoriesDropDown}
                                  className={`${classes.formSelect} ${classes.font20}`} disableUnderline
                                  onChange={(e) => this.setState({ openExpenseCategoriesDropDown: false }, () => this.updateCategoryDND(this.state.dropEventDataTransferId, e.target.value, true))}
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left"
                                    },
                                    classes: { paper: classes.menuPaper }
                                  }} >
                                  {this.state.expenseCategories.map(el =>
                                    <MenuItem key={el.id} value={el.id} className={`${classes.selectOption} ${classes.font20}`}>{el.name}</MenuItem>
                                  )}
                                </Select>
                              }

                            </Grid>
                          </ClickAwayListener>
                        </Grid>
                      )
                    })
                  }
                </Grid>)
              })
              }
              <Grid item container xs={11} justifyContent='flex-end' alignItems="center">
                <Button className={`${classes.links} ${classes.addAccountButton}`} onClick={() => this.setState({ openAddOrEditModal: true })}>
                  <Typography variant="caption" className={`${classes.font13} ${classes.whiteFont}`}>
                    ADD OR EDIT ACCOUNT
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={3} direction="column" justifyContent="space-around" alignItems="center" className={classes.rightDiv}>
            {this.state.catTypes.map(el => {
              return (
                <Link key={el.id} className={this.classCheckSideBar(el, classes)} to="#">
                  <Grid xs={12} onDragOver={(event) => event.preventDefault()} onDrop={(event) => this.dropOver(event, el.id)} item container justifyContent="center" alignItems="center">
                    <Typography className={classes.font20}>{el.name}</Typography>
                  </Grid>
                </Link>
              )
            })
            }
          </Grid>
        </Grid>
      </Grid >
      // Customizable Area End
    );
  }
}

export default withStyles(useStyles)(TransactionsWeb)

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { Linking } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  signedUp: boolean;
  redirectPage: boolean;
  goToDashboard: boolean;
  goToChangePassword: boolean;
  openRedirectModal: boolean;
  loginSuccess: boolean;
  newAccountToken: any;
  triggerNewRegistration: boolean;
  firebaseToken: any;
  loginPage: boolean,
  openModalLogin: boolean;
  modalContent: any;
  title: string;
  successFormApi: any;
  successMessage: any;
  errorFromApi: any;
  errorMessage: any;
  signUpEmail: any;
  goToSignupPayment: boolean;
  subscriptionPlan: any;
  goToInitialPayment: boolean;
  errorMessage1: boolean;
  selected: any;
  packageName1: any;
  packageName2: any;
  packageType1: any;
  packageType2: any;
  packageRate1: any;
  packageRate2: any;
  callApi: boolean;
  activated: boolean;
  message: any;
  errorVerification: boolean;
  errorVerificationMessage: any;
  successVerification: boolean;
  successVerificationMessage: any;
  openRedirectToSignin: boolean;
  phoneNum: any;
  onFocus:Boolean;
  subscriptionId:string;
  remainingTime:string | null | number[];
  isExpired: Boolean

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  loginApiCallId: any;
  validationApiCallId: string = "";
  verifyNewAccountID: any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  signUpSendEmailId: any;
  getAllPackagesID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      userName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "91",
      phone: "",
      signedUp: false,
      redirectPage: false,
      loginPage: true,
      goToChangePassword: false,
      goToDashboard: false,
      loginSuccess: false,
      openRedirectModal: true,
      newAccountToken: '',
      triggerNewRegistration: false,
      openModalLogin: false,
      modalContent: [],
      title: '',
      firebaseToken: this.isPlatformWeb() === true && localStorage?.getItem("firebaseToken"),
      successFormApi: '',
      successMessage: '',
      errorFromApi: '',
      errorMessage: '',
      signUpEmail: '',
      goToSignupPayment: false,
      subscriptionPlan: 2,
      goToInitialPayment: false,
      errorMessage1: false,
      selected: 2,
      packageName1: '',
      packageName2: '',
      packageRate1: '',
      packageRate2: '',
      packageType1: '',
      packageType2: '',
      callApi: false,
      activated: false,
      message: '',
      errorVerification: false,
      errorVerificationMessage: '',
      successVerification: false,
      successVerificationMessage: '',
      openRedirectToSignin: false,
      phoneNum: '',
      onFocus: true,
      subscriptionId : "",
      remainingTime: null,
      isExpired: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.validationApiCallId: {
          this.validationAPIResponseSwitch(responseJson)
          break;
        }
        case this.createAccountApiCallId: {
          await this.createAccountApiCallSwitch(responseJson)
          break;
        }
        case this.loginApiCallId: {
          await this.loginApiCallSwitch(responseJson)
          break;
        }
        case this.verifyNewAccountID: {
          this.verifyNewAccountSwitch(responseJson)
          break;
        }
        case this.signUpSendEmailId: {
          this.signUpSendEmailSwitch(responseJson)
          break;
        }
        case this.getAllPackagesID: {
          this.getPackageSwitch(responseJson)
          break;
        }
      }

    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToSignIn() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  goToSignUp() {
    Linking.openURL(configJSON.signUpWebUrl)
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handlePasswordRegex = (regexData: any) => {
    if (regexData.password_validation_regexp) {
      this.passwordReg = new RegExp(regexData.password_validation_regexp);
    }
    if (regexData.password_validation_rules) {
      this.setState({ passwordHelperText: regexData.password_validation_rules });
    }
  }

  handleEmailRegex = (regexData: any) => {
    if (regexData.email_validation_regexp) {
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    }
  }

  handleErrors(responseJson: any) {

    let errorMessage = "";
    const errors = responseJson?.errors || [];

    errors.forEach((error: any) => {
      if (error?.account === "Email invalid") {
        errorMessage = "Email Already Used. Please try with a new Email.";
      } else {
        if (error?.full_phone_number) {
          errorMessage += error.full_phone_number;
        }
        if (error?.user_name) {
          errorMessage += error.user_name;
        }
        if (error?.message) {
          errorMessage += error.message;
        }
      }
    });

    this.showModal(errorMessage);
  }

  updatePackagesState(responseJson: any) {
    responseJson?.plans?.map((items: any) => {
      if (items?.id == 1) {
        this.setState({ packageName1: items?.attributes?.name, packageRate1: items?.attributes?.price })
        if (items?.attributes?.duration == "1 Year") {
          this.setState({ packageType1: 'Annually' })
        } else {
          this.setState({ packageType1: 'Monthly' })
        }
      } else {
        this.setState({ packageName2: items?.attributes?.name, packageRate2: items?.attributes?.price , subscriptionId : items.id})
        if (items?.attributes?.duration == "1 Year") {
          this.setState({ packageType2: 'Annually' })
        } else {
          this.setState({ packageType2: 'Monthly' })
        }
      }
    })
  }

  validationAPIResponseSwitch(responseJson: any) {
    this.arrayholder = responseJson?.data;
    if (this.arrayholder?.length) {
      let regexData = this.arrayholder[0];
      this.handlePasswordRegex(regexData);
      this.handleEmailRegex(regexData);
    }
  }

  async createAccountApiCallSwitch(responseJson: any) {
    if (!responseJson.errors) {
      await StorageProvider.set("signupEmail", this.state.email);
      await StorageProvider.set("token",responseJson.meta.token);
      await StorageProvider.set("signupEmail", this.state.email);
      await StorageProvider.set("company_id", null)
      this.setState({ signedUp: true })
    } else {
      this.handleErrors(responseJson)
    }
  }

  async loginApiCallSwitch(responseJson: any) {
    if (!responseJson.errors) {
      await StorageProvider.set("token", responseJson.meta.token)
      await StorageProvider.set("role", responseJson?.account?.data?.attributes?.role)
      await StorageProvider.set("manage_account", responseJson?.account?.data?.attributes?.manage_account)
      await StorageProvider.set("manage_company", responseJson?.account?.data?.attributes?.manage_company)
      await StorageProvider.set("manage_subscription", responseJson?.account?.data?.attributes?.manage_subscription)
      await StorageProvider.set("company_id", responseJson?.account?.data?.attributes?.company_id)
      await StorageProvider.set("companyName", responseJson?.company?.attributes?.business_name || '')
      await StorageProvider.set("companyEmail", responseJson?.company?.attributes?.contact_email || '')
      await StorageProvider.set("companyLogo", responseJson?.company?.attributes?.logo)

      const resetPwdInstruction = responseJson?.account?.data?.attributes?.reset_pwd_instruction;
      if (resetPwdInstruction) {
        this.setState({ goToDashboard: false, goToChangePassword: true, loginSuccess: true });
      } else {
        this.setState({ goToChangePassword: false, goToDashboard: true, loginSuccess: true });
      }
    } else {
      this.showModal(JSON.stringify(responseJson?.errors[0]?.failed_login + ".").replace(/['"]/g, ''))
    }
  }

  async verifyNewAccountSwitch(responseJson: any) {
    if (!responseJson.errors) {
      this.setState({ activated: responseJson?.meta?.already_activated, message: responseJson?.meta?.message })
      localStorage.setItem('signupEmail', responseJson?.data?.attributes?.email);
    }
    else {
      responseJson?.errors.map((data: any) => { this.setState({ errorVerificationMessage: data?.token }) })
      this.setState({ errorVerification: true })
    }
  }

  async signUpSendEmailSwitch(responseJson: any) {
    if (!responseJson.errors && responseJson?.success) {
      this.setState({ successFormApi: responseJson?.success?.message })
      this.setState({ successMessage: true })
    } else {
      this.setState({ errorFromApi: responseJson?.errors?.message })
      this.setState({ errorMessage: true })
    }
  }

  async getPackageSwitch(responseJson: any) {
    if (!responseJson.errors) {
      this.updatePackagesState(responseJson)
    } else {
      responseJson?.errors.map((data: any) => { this.setState({ errorVerificationMessage: data?.token }) })
      this.setState({ errorVerification: true })
    }
  }
  showModal(content: string) {
    this.setState({ openModalLogin: true, title: "Error", modalContent: content });
  }
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.userName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)

    ) {
      this.setState({ openModalLogin: true, title: configJSON.errorTitle, modalContent: configJSON.errorAllFieldsAreMandatory })
      return false;
    }
    if (this.state.phone.length != 13) {

      this.setState({ openModalLogin: true, title: configJSON.errorTitle, modalContent: "Phone number should be (xxx) xxx-xxxx" })

      return false;

    }


    let phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.setState({ openModalLogin: true, title: configJSON.errorTitle, modalContent: phoneNumberError })
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.setState({ openModalLogin: true, title: configJSON.errorTitle, modalContent: configJSON.errorEmailNotValid })

      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.setState({ openModalLogin: true, title: configJSON.errorTitle, modalContent: configJSON.errorPasswordNotValid })

      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.setState({ openModalLogin: true, title: configJSON.errorTitle, modalContent: configJSON.errorBothPasswordsNotSame })
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      user_name: this.state.userName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };


    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  login(): boolean {
    if (
      this.isStringNullOrBlank(this.state.userName) ||
      this.isStringNullOrBlank(this.state.password)
    ) {
      this.setState({ openModalLogin: true, title: configJSON.errorTitle, modalContent: configJSON.errorAllFieldsAreMandatory })
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      user_name: this.state.userName,
      password: this.state.password,
      role_id: "admin",
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  validateNewAccount(id: number,token:string) {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyNewAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.emailActivationEndPoints}/email_confirmation`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

 
  sendVerification(email: string) {
    if (this.isStringNullOrBlank(email)) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        "please fill up the signup again."
      );
      return false;
    }
    if (!this.isValidEmail(email)) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.errorEmailNotValid
      );
      return false;
    }
    localStorage.setItem("signupEmail", email);
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyAccountSignup
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.signUpSendEmailId = requestMessage.messageId;
    const data = {
      attributes: {
        email: email.trim(),
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllPackages(id: number, token?:string) {

    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: token || localStorage.getItem("tempToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllPackagesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllPlans}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  passValue() {
    localStorage.setItem("subscriptionPlan", this.state.subscriptionPlan === 2 ? this.state.subscriptionId : "1");
    this.setState({ goToInitialPayment: true })

  }

  onFocusedSignInNavigator = () => {
    this.setState({
      onFocus : false
    })
  }
  handleClose = () => {
    this.setState({ openModalLogin: false })
  };

  handleKeyPress = (event: KeyboardEvent) => {
    if(event.key === "Enter" && this.state.onFocus ){
      if(this.state.openModalLogin){
        this.handleClose();
        
      }else{
        this.state.loginPage ? this.login() : this.createAccount()
      }
    }
  
 

  }  

  async componentDidMount() {
    super.componentDidMount()
    document.addEventListener('keydown', this.handleKeyPress)
  }

  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount();
    document.removeEventListener('keydown', this.handleKeyPress)
  }
  // Customizable Area End
}

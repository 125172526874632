//@ts-nocheck
import React from "react";
import TaxesSettingController from "./TaxesSettingController.web";
import {
  AppBar,
  Typography,
  Popover,
  withStyles,
  Grid,
  Checkbox,
  Switch,
  Popper,
  Box,
  NativeSelect,
  Button,
  Snackbar,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Settings5Controller from "./Settings5Controller";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Scrollbars } from 'react-custom-scrollbars';
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import StorageProvider from "../../../framework/src/StorageProvider.web";

const useStyles = (theme) => ({
  fullDisplay: {
    height: "100%",
    width: "100%",
    maxWidth: "100%"
  },
  heading: {
    fontWeight: "700",
    margin: "10px 0",
    fontSize: "20px",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },
  viewSwitch: {
    width: "55px",
    height: "24px",
    padding: "0px",
    paddingLeft: "3"
  },
  switchBase: {
    color: "#818181",
    padding: "1px",
    width: "30px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "green"
      }
    }
  },
  thumb: {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px"
  },
  track: {
    borderRadius: "20px",
    backgroundColor: "#AEAEAE",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "10px",
      position: "absolute",
      top: "6px",
      '@media (max-width:1425px)': {
        fontSize: "7.5px",
      },
    },
    "&:after": {
      content: "'YES'",
      left: "6px"
    },
    "&:before": {
      content: "'NO'",
      right: "6px"
    }
  },
  checked: {
    color: "#FF9933 !important",
    transform: "translateX(26px) !important"
  },
  border: {
    border: "1px solid"
  },
  colorCheck: {
    "&$checked": {
      color: "#5597f4",
    }
  },
  checked1: {

  },
  palette: {
    secondary: "#5597f4"
  },
  popoverPaper: {
    padding: "10px",
    maxWidth: "330px",
    color: "white",
    backgroundColor: "transparent",
    boxShadow: "none",
    // marginLeft: "-30px",
    // '@media (max-width:1425px)': {
    //   fontSize: "37.5px",
    // },


  },
  closeIcon: {
    position: "absolute",
    top: 18,
    left: "90%",
    '@media (max-width:1425px)': {
      top: 11,
    },
  },
  backgrondColor1: {
    backgroundColor: "grey"
  },
  ArrowDropUpIconFont: {
    position: "absolute",
    top: -17,
    left: 20,
    color: "#363636",
    fontSize: "50px",
    '@media (max-width:1425px)': {
      fontSize: "37.5px",
    },
  },
  qbiTooltip: {
    fontSize: "14px",
    color: "#ffffff",
    '@media (max-width:1425px)': {
      fontSize: "10.5px",
    },
  },
  taxPaymentSystemStyle: {
    margin: "30 0",
    fontSize: "20px",
    fontWeight: "400",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },
  fillingStatusStyle: {
    margin: 0,
    fontSize: "20px",
    fontWeight: "400",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },

})

class TaxesSettingWeb extends TaxesSettingController {
  async componentDidMount() {
    this.setState({ token: await StorageProvider.get('token') }, () => {
      this.updateTaxConfig()
    })
  }
  render() {
    console.log(this.props);
    const { classes } = this.props;
    return (
      <div className="root">
        <Grid container direction="row" className={classes.fullDisplay}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showSnackbar}
            onClose={() => {
              this.setState({
                showSnackbar: false
              });
              this.updateTaxConfig();
            }}
            autoHideDuration={2000}
          >
            <Alert severity="error"
              elevation={6} variant="filled"
            >{this.state.snackbarMessage}
            </Alert>
          </Snackbar>
          <Grid item xs={6} className={classes.fullDisplay} style={{ borderRight: "1px solid" }}>
            <Grid container direction="column" className={classes.fullDisplay}>
              <Grid item xs={4} className={classes.fullDisplay}
                //style={{ maxWidth: "100%" }}
                style={{ borderBottom: "1px solid", padding: "10px" }}
              >
                <Box style={{ margin: "0 30px" }}>
                  <Typography className={classes.heading} style={{ margin: "0 0 10 10" }}>Filing Status</Typography>
                  <Typography gutterBottom className={classes.fillingStatusStyle}>
                    <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"single"} checked={this.state.single && !this.state.married && !this.state.marriedFillingSeparate && !this.state.headOfHousehold} />
                    {/* {this.state.single ? <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"single"} checked={this.state.single && !this.state.married && !this.state.marriedFillingSeparate && !this.state.headOfHousehold} /> : <Checkbox disabled />} */}
                    {/* {this.state.fillStatus.length === 1 && this.state?.fillStatus[0] === "single" ? <CheckBox onChange={(e) => { this.updateFillingStatus(e) }} value={"single"} /> : <Checkbox disabled />} */}
                    {/* <Checkbox
                    
                  /> */}
                    Single
                  </Typography>
                  <Typography gutterBottom className={classes.fillingStatusStyle}>
                    {/* <Checkbox /> */}
                    <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"married"} checked={this.state.married && !this.state.single && !this.state.marriedFillingSeparate && !this.state.headOfHousehold} />
                    {/* {this.state.married ? <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"married"} checked={this.state.married && !this.state.single && !this.state.marriedFillingSeparate && !this.state.headOfHousehold} /> : <Checkbox disabled />} */}
                    {/* {this.state.fillStatus.length === 1 ?

                    this.state?.fillStatus[0] === "married" ? <Checkbox onChange={(e) => { this.updateFillingStatus(e) }} value={"married"} /> : <Checkbox disabled />
                    :
                    <Checkbox onChange={(e) => { this.updateFillingStatus(e) }} value={"married"} />} */}
                    Married

                  </Typography>
                  {this.state.checkedTriggeredValue ? <>{this.setState({ checkedTriggeredValue: false }), this.updateTaxSettingsData()}</> : <> </>}
                  <Typography gutterBottom className={classes.fillingStatusStyle}>
                    {/* <Checkbox /> */}
                    <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"marriedFillingSeparate"} checked={this.state.marriedFillingSeparate && !this.state.single && !this.state.married && !this.state.headOfHousehold} />
                    {/* {this.state.marriedFillingSeparate ? <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"marriedFillingSeparate"} checked={this.state.marriedFillingSeparate && !this.state.single && !this.state.married && !this.state.headOfHousehold} /> : <Checkbox disabled />} */}

                    Married Filing Separate

                  </Typography>
                  <Typography gutterBottom className={classes.fillingStatusStyle}>
                    {/* <Checkbox /> */}
                    <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"headOfHousehold"} checked={this.state.headOfHousehold && !this.state.single && !this.state.married && !this.state.marriedFillingSeparate} />
                    {/* {this.state.headOfHousehold ? <Checkbox color="primary" onChange={(e) => { this.updateFillingStatus(e) }} value={"headOfHousehold"} checked={this.state.headOfHousehold && !this.state.single && !this.state.married && !this.state.marriedFillingSeparate} /> : <Checkbox disabled />} */}

                    Head of Household
                  </Typography>

                </Box>
              </Grid>
              <Grid item xs={2} className={classes.fullDisplay} style={{ borderBottom: "1px solid", padding: "20px 0 20px 30px" }}>
                <Typography className={classes.heading}>Calculated QBI <HelpOutlineIcon style={{ position: "relative", top: 5 }} onClick={(e) => {
                  if (this.state.openQuestion == true) {
                    this.setState({ openQuestion: false, anchorE1: null });
                  }
                  else {
                    this.setState({ openQuestion: true, anchorE1: e.currentTarget });
                  }
                  //this.setState({anchorE1: e.currentTarget})
                  //this.updateTaxSettingsData()

                }} />

                  <Popover

                    open={this.state.openQuestion}
                    onClose={() => { this.setState({ openQuestion: false }) }}
                    //anchorReference='anchorPosition'
                    //anchorPosition={{ top: 700, left: 1000 }}
                    // vertical: (window.innerHeight / 2),
                    //   horizontal: (window.innerWidth / 2.4)
                    anchorEl={this.state.anchorE1}
                    // anchorReference="anchorPosition"
                    // anchorPosition={{ top: 800, left: 200 }}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    // transformOrigin={{
                    //   vertical: "top",
                    //   horizontal: "left"
                    // }}
                    classes={{
                      paper: classes.popoverPaper
                    }}

                  >

                    {/* <ArrowDropUpIcon className={classes.ArrowDropUpIconFont} /> */}

                    <Box style={{ width: "100%", padding: "20px 15px", backgroundColor: "#363636" }}>
                      <CloseIcon
                        className={classes.closeIcon}
                        onClick={(e) => {
                          if (this.state.openQuestion == true) {
                            this.setState({ openQuestion: false, anchorE1: null });
                          }
                          else {
                            this.setState({ openQuestion: true, anchorE1: e.currentTarget });
                          }
                          //this.setState({anchorE1: e.currentTarget})

                        }}
                      />

                      <Box style={{ width: "100%" }}>
                        <Typography style={{ fontWeight: "300" }} className={classes.qbiTooltip}>Put simply, the QBI deduction is a deduction <br />of 20% of the Net income for a sole proprietor <br />filing form Schedule C. There are income <br />limits, for 2021, the limits are $164,900 for single <br />filers and $329,800 for joint filers. These are <br />limits to your overall tax return, not only your <br />schedule C amounts. If your only income is the <br />schedule C, you can consider turning QBI on. </Typography>
                        <Typography style={{ fontWeight: "600", letterSpacing: "-1px", margin: "10px 0" }} className={classes.qbiTooltip}>IF YOU DO, IT WILL CALCULATE A LOWER <br />TAX AMOUNT DUE.</Typography>
                        <Typography style={{ fontWeight: "300" }} className={classes.qbiTooltip}>It will calculate tax off of 80% of your Net <br />income, as opposed to 100%. If you want to be <br />conserative, or you are not sure if you qualify,</Typography>
                        <Typography style={{ fontWeight: "600" }} className={classes.qbiTooltip}>we suggest you leave the QBI toggled to "no".</Typography>
                      </Box>
                    </Box>
                  </Popover>



                  {/* <Popper
                    open={this.state.openQuestion}
                    anchorEl={this.state.anchorE1}
                    classes={{ paper: classes.backgrondColor1 }}
                    modifiers={[
                      // {
                      //   name: 'flip',
                      //   enabled: false,
                      //   options: {
                      //     altBoundary: true,
                      //     rootBoundary: 'document',
                      //     padding: 8,
                      //   },
                      // },
                      // {
                      //   name: 'preventOverflow',
                      //   enabled: true,
                      //   options: {
                      //     altAxis: true,
                      //     altBoundary: true,
                      //     tether: false,
                      //     rootBoundary: 'document',
                      //     padding: 8,
                      //   },
                      // },
                      {
                        name: 'arrow',
                        enabled: true,
                        options: {
                          element: this.state.anchorE1,
                        },
                      },
                    ]}
                  >
                    <Typography>
                      hi
                    </Typography>
                  </Popper> */}




                  {/* <Popper
                    open={this.state.openQuestion}
                    anchorEl={this.state.anchorE1}
                    transition
                    placement="bottom-start"
                    disablePortal={false}
                    modifiers={[
                      {
                        name: 'flip',
                        enabled: true,
                        options: {
                          altBoundary: true,
                          rootBoundary: 'document',
                          padding: 8,
                        },
                      },
                      {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                          altAxis: true,
                          altBoundary: true,
                          tether: false,
                          rootBoundary: 'document',
                          padding: 8,
                        },
                      },
                      {
                        name: 'arrow',
                        enabled: true,
                        options: {
                          //element: this.state.anchorE1,
                        },
                      },
                    ]}
                  >
                    <Box>
                      <Typography style={{ fontSize: "14px", fontWeight: "300", color: "#ffffff" }}>Put simply, the QBI deduction is a deduction <br />of 20% of the Net income for a sole proprietor <br />filing form Schedule C. There are income <br />limits, for 2021, the limits are $164,900 for single <br />filers and $329,800 for joint filers. These are <br />limits to your overall tax return, not only your <br />schedule C amounts. If your only income is the <br />schedule C, you can consider turning QBI on. </Typography>
                      <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#ffffff", letterSpacing: "-1px", margin: "10px 0" }}>IF YOU DO, IT WILL CALCULATE A LOWER <br />TAX AMOUNT DUE.</Typography>
                      <Typography style={{ fontSize: "14px", fontWeight: "300", color: "#ffffff" }}>It will calculate tax off of 80% of your Net <br />income, as opposed to 100%. If you want to be <br />conserative, or you are not sure if you qualify,</Typography>
                      <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#ffffff" }}>we suggest you leave the QBI toggled to "no".</Typography>
                    </Box>

                  </Popper> */}
                </Typography>

                <Switch
                  checked={this.state.calculatedQbi}
                  classes={{
                    root: classes.viewSwitch,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked
                  }}
                  onChange={(e) => { this.setState({ calculatedQbi: e.target.checked, dummyValue: true }) }}
                />
              </Grid>
              <Grid item xs className={classes.fullDisplay} style={{ padding: "20px 0 20px 30px" }}>
                <Typography className={classes.heading}>Number of dependents</Typography>
                <NativeSelect
                  IconComponent={KeyboardArrowDownIcon}
                  value={this.state.numberOfDependencies}
                  style={{ width: "200px" }}
                  onChange={(e) => { this.setState({ numberOfDependencies: e.target.value, dummyValue: true }) }}
                  variant="outlined"
                  disableUnderline={true}
                  classes={{
                    outlined: classes.border,
                  }}
                  inputProps={{ style: { paddingLeft: '15px' } }}


                //this.setState({numberOfDependencies: e.target.value})

                >
                  {/* <Scrollbars
                    renderThumbVertical={(style, ...props) => <div {...props} style={{ ...style, top: "50", backgroundColor: "#3d3f42", position: "relative", display: "block", width: "80%", left: "2", cursor: "pointer" }} />}
                    renderTrackVertical={(style, ...props) => <div {...props} style={{ ...style, position: "absolute", width: "6px", right: "2px", bottom: "75px", top: "2px", borderRadius: "3px" }} />}
                    thumbSize={175}
                  > */}

                  <option value={0} >0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>

                  {/* </Scrollbars> */}

                </NativeSelect>
              </Grid>
              {this.state.dummyValue ? <>{this.setState({ dummyValue: false }), this.updateTaxSettingsData()}</> : <></>}
              {/* {this.state.checkedTriggeredValue ? <>{this.setState({ checkedTriggeredValue: false }), this.updateTaxSettingsData()}</> : <> </>} */}

            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.fullDisplay} style={{ padding: "0px 0 0 30px" }}>
            <Typography className={classes.heading}>Tax Payment Settings</Typography>

            <Typography className={classes.taxPaymentSystemStyle}>Show Fixed Percentage Tax Calculation</Typography>
            <Switch
              checked={this.state.fixedPercentageTaxCalculation}
              classes={{
                root: classes.viewSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
              }}
              style={{ marginBottom: 30 }}
              onChange={(e) => { this.setState({ fixedPercentageTaxCalculation: e.target.checked, dummyValue: true }) }}
            />
            <Typography className={classes.taxPaymentSystemStyle}>Show Estimated Tax Payments</Typography>
            <Switch
              checked={this.state.estimatedTaxPayment}
              classes={{
                root: classes.viewSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
              }}
              style={{ marginBottom: 30 }}
              onChange={(e) => { this.setState({ estimatedTaxPayment: e.target.checked, dummyValue: true }) }}
            />
            <Typography className={classes.taxPaymentSystemStyle}>Show State Taxes <ErrorOutlineIcon style={{ position: "relative", top: 5 }} /></Typography>
            <Switch
              checked={this.state.stateTaxes}
              classes={{
                root: classes.viewSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
              }}
              style={{ marginBottom: 30 }}
              onChange={(e) => { this.setState({ stateTaxes: e.target.checked, dummyValue: true }) }}
            />
            <Typography className={classes.taxPaymentSystemStyle}>Subject to Employment Taxes</Typography>
            <Switch
              checked={this.state.subjectToEmploymentTaxes}
              classes={{
                root: classes.viewSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
              }}
              style={{ marginBottom: 30 }}
              onChange={(e) => { this.setState({ subjectToEmploymentTaxes: e.target.checked, dummyValue: true }), this.populateStorage(this.state.subjectToEmploymentTaxes) }}

            />

          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(TaxesSettingWeb);

import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
// import LandingPage2 from '../../blocks/LandingPage2/src/LandingPage2.web';
// import AdminLandingPage from '../../blocks/LandingPage2/src/AdminLandingPage.web';
// import StartRoadMap from "../../blocks/LandingPage2//src/StartRoadMap.web";
// import TestUI from "../../blocks/LandingPage2/src/testUI.web";
// import AdminDashborad from "../../blocks/AdminConsole3/src/AdminDashborad.web";
// import ProfileDashboard from "../../blocks/LandingPage2/src/ProfileDashboard.web";
// import PastRoadmaps from "../../blocks/LandingPage2/src/PastRoadmaps.web";
import DashboardComponent from './DashboardComponent';
import CompanySetting from '../../Settings5/src/CompanySetting.web'
import GlobalSetting from '../../Settings5/src/GlobalSetting.web'
import Mileage from './Mileage.web'
import Taxes from './Taxes.web'
import Reports from './Reports.web'
import Forms from './Forms.web'
import W1 from './W1.web'
import Transactions from './Transactions.web'


class Routes extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        console.log(location.href + ' ============ location.href');
        return (
            <Switch>
                <Route path="/Dashboard" exact>
                    <DashboardComponent taxYear={this.props.taxYear} updatedTaxYear={this.props.updatedTaxYear} />
                </Route>
                <Route path="/Dashboard/mileage">
                    <Mileage taxYear={this.props.taxYear} />
                </Route>
                <Route path="/Dashboard/taxes">
                    <Taxes taxYear={this.props.taxYear} updatedTaxYear={this.props.updatedTaxYear} />
                </Route>
                <Route exact path="/Dashboard/transactions" key={location.href}>
                    <Transactions taxYear={this.props.taxYear} />
                </Route>
                <Route exact path="/Dashboard/forms">
                    <Forms taxYear={this.props.taxYear} />
                </Route>
                <Route path="/Dashboard/reports">
                    <Reports taxYear={this.props.taxYear} />
                </Route>
                <Route path="/Dashboard/w1">
                    <W1 taxYear={this.props.taxYear} />
                </Route>
                <Route path="/Dashboard/companysettings">
                    <CompanySetting />
                </Route>
                <Route path="/Dashboard/globalsettings">
                    <GlobalSetting />
                </Route>
            </Switch>
        );
    }
}
export default Routes;

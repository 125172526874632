import React from "react";

// Customizable Area Start
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  Image,
  KeyboardAvoidingView,
} from "react-native";
import CheckBox from "../../../components/src/CheckBox";
import Input from "../../../components/src/Input";
import Button from "../../../components/src/Button";
import {
  blueImage,
  checkIcon,
  hidePassword,
  leftLeave,
  rightLeave,
  showPassword,
} from "./assets";
import { scaledSize } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        {/* Customizable Area Start */}
        <ScrollView
          keyboardShouldPersistTaps="never"
          style={
            Platform.OS === "web" ? styles.containerWeb : styles.containerMobile
          }
        >
          {/* ------------------- HEADER ---------------------- */}
          <View style={styles.SafeAreaView}>
            {/* Component code starts here */}
            <View style={styles.changePasswordTextView}>
              <Text style={styles.changePasswordText}>
                {configJSON.changePassword}
              </Text>
              <View style={styles.orangeLine} />
            </View>
            <View style={styles.topView}>
              <View style={styles.topImageView}>
                <Image style={styles.topImage} source={blueImage} />
              </View>
              <View style={styles.leftLeaveImageView}>
                <Image style={styles.leftLeaveImage} source={leftLeave} />
              </View>
              <View style={styles.rightLeaveImageView}>
                <Image style={styles.rightLeaveImage} source={rightLeave} />
              </View>
            </View>
            <View style={styles.firstCheckboxView}>
              <CheckBox
                checkBoxGroup={false}
                checkBoxText={configJSON.atLease8Characters}
                isCheckBoxSelected={this.state.isAtLeast8Character}
                checkBoxDisableSelected={true}
                checkBoxTextStyle={[
                  styles.checkboxText,
                  {
                    color: this.state.isAtLeast8Character
                      ? "#3f4144ff"
                      : "#dc2626ff",
                  },
                ]}
                rightClickIcon={checkIcon}
                testID="testFirstCheckbox"
                checkBoxGroupTitle=""
              />
            </View>
            <View style={styles.secondCheckboxView}>
              <CheckBox
                checkBoxGroup={false}
                checkBoxText={configJSON.oneUpperCaseLatters}
                isCheckBoxSelected={this.state.isOneUpperCaseLatter}
                checkBoxDisableSelected={true}
                checkBoxTextStyle={[
                  styles.checkboxText,
                  {
                    color: this.state.isOneUpperCaseLatter
                      ? "#3f4144ff"
                      : "#dc2626ff",
                  },
                ]}
                rightClickIcon={checkIcon}
                testID="testSecondCheckbox"
                checkBoxGroupTitle=""
              />
            </View>
            <View style={styles.thirdCheckboxView}>
              <CheckBox
                checkBoxGroup={false}
                checkBoxText={configJSON.oneLowerCaseLatters}
                isCheckBoxSelected={this.state.isOneLowerCaseLatter}
                checkBoxDisableSelected={true}
                checkBoxTextStyle={[
                  styles.checkboxText,
                  {
                    color: this.state.isOneLowerCaseLatter
                      ? "#3f4144ff"
                      : "#dc2626ff",
                  },
                ]}
                rightClickIcon={checkIcon}
                testID="testThirdCheckbox"
                checkBoxGroupTitle=""
              />
            </View>
            <View style={styles.fourthCheckboxView}>
              <CheckBox
                checkBoxGroup={false}
                checkBoxText={configJSON.oneNumber}
                isCheckBoxSelected={this.state.isOneNumber}
                checkBoxDisableSelected={true}
                checkBoxTextStyle={[
                  styles.checkboxText,
                  { color: this.state.isOneNumber ? "#3f4144ff" : "#dc2626ff" },
                ]}
                rightClickIcon={checkIcon}
                testID="testFourthCheckbox"
                checkBoxGroupTitle=""
              />
            </View>
            <View style={styles.fifthCheckboxView}>
              <CheckBox
                checkBoxGroup={false}
                checkBoxText={configJSON.oneSpecialCharacter}
                isCheckBoxSelected={this.state.isOneSpecialCharacter}
                checkBoxDisableSelected={true}
                checkBoxTextStyle={[
                  styles.checkboxText,
                  {
                    color: this.state.isOneSpecialCharacter
                      ? "#3f4144ff"
                      : "#dc2626ff",
                  },
                ]}
                rightClickIcon={checkIcon}
                testID="testFifthCheckbox"
                checkBoxGroupTitle=""
              />
            </View>
            <View style={styles.confirmInputContainer}>
              <Text style={styles.confirmPasswordText}>
                {configJSON.confirmPassword}
              </Text>
              <View style={styles.confirmPasswordInputView}>
                <Input
                  placeholder={configJSON.placeholderOfPassword}
                  size="medium"
                  rightIcon={
                    this.state.btnConfirmPasswordShowHide
                      ? hidePassword
                      : showPassword
                  }
                  style={styles.confirmPasswordInput}
                  value={this.state.confirmPassword}
                  onChangeText={this.onChangeConfirmPassword}
                  testID="txtConfirmPasswordInput"
                  secureTextEntry={this.state.btnConfirmPasswordShowHide}
                  onRightIconPress={this.onConfirmPasswordBtnShowHide}
                />
              </View>
            </View>
            <View style={styles.newInputContainer}>
              <Text style={styles.newPasswordText}>
                {configJSON.newPassword}
              </Text>
              <View style={styles.newPasswordInputView}>
                <Input
                  placeholder={configJSON.placeholderOfPassword}
                  size="medium"
                  rightIcon={
                    this.state.btnNewPasswordShowHide
                      ? hidePassword
                      : showPassword
                  }
                  style={styles.newPasswordInput}
                  value={this.state.newPassword}
                  onChangeText={this.onChangeNewPassword}
                  testID="txtNewPasswordInput"
                  secureTextEntry={this.state.btnNewPasswordShowHide}
                  onRightIconPress={this.onNewPasswordBtnShowHide}
                />
              </View>
            </View>
            <View style={styles.submitBtnView}>
              <Button
                theme={"secondary"}
                text={configJSON.submitBtnText}
                textStyle={{ color: "#ffffffff" }}
                style={styles.submitBtn}
                onPress={this.onSubmitNewPassword}
                testID="submitBtn"
              />
            </View>
            {this.state.isConfirmPasswordError && (
              <Text style={styles.yourPasswordText}>
                {configJSON.confirmPasswordErorr}
              </Text>
            )}
            <Text style={styles.noteText}>{configJSON.noteText}</Text>

            {/* Component code ends here */}
          </View>
        </ScrollView>
        {/* Customizable Area End */}
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    // padding: 16,
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: "100%",
    // maxWidth: 650,
    backgroundColor: "#fff",
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
  },
  SafeAreaView: {
    backgroundColor: "#ffffffff",
    width: "100%",
    height: scaledSize(750),
  },
  changePasswordTextView: {
    top: scaledSize(235),
    // left: scaledSize(150),
    // right: scaledSize(150),
    alignSelf: "center",
    position: "absolute",
    width: "100%",
    height: scaledSize(40),
  },
  changePasswordText: {
    fontFamily: "OpenSans-Bold",
    // fontWeight: "700",
    fontSize: scaledSize(22),
    letterSpacing: 0,
    color: "#000000ff",
    textAlign: "center",
  },
  orangeLine: {
    top: scaledSize(10),
    // left: scaledSize(44),
    // right: scaledSize(44),
    width: scaledSize(114),
    borderWidth: 2.5,
    borderColor: "#FF860D",
    backgroundColor: "#FF860D",
    alignSelf: "center",
  },
  topView: {
    top: scaledSize(0),
    left: scaledSize(0),
    right: scaledSize(0),
    position: "absolute",
    width: "100%",
    height: scaledSize(200),
  },
  topImageView: {
    top: scaledSize(-105),
    left: scaledSize(-25),
    right: scaledSize(-169),
    position: "absolute",
  },
  topImage: { width: scaledSize(470), height: scaledSize(300) },
  leftLeaveImageView: {
    top: scaledSize(120),
    left: scaledSize(-59),
    right: scaledSize(300),
    position: "absolute",
  },
  leftLeaveImage: { width: scaledSize(99), height: scaledSize(99) },
  rightLeaveImageView: {
    top: scaledSize(88),
    left: scaledSize(66),
    right: scaledSize(249),
    position: "absolute",
  },
  rightLeaveImage: { width: scaledSize(99), height: scaledSize(99) },
  confirmInputContainer: {
    top: scaledSize(378),
    left: "7%",
    right: scaledSize(17),
    position: "absolute",
    width: "86%",
    height: scaledSize(60),
  },
  confirmPasswordText: {
    fontFamily: "OpenSans-Regular",
    fontWeight: "400",
    fontSize: scaledSize(13),
    letterSpacing: 0,
    color: "#334155ff",
  },
  confirmPasswordInputView: {
    top: scaledSize(24),
    left: 0,
    right: 0,
    position: "absolute",
  },
  confirmPasswordInput: {
    backgroundColor: "#ffffffff",
    borderRadius: 8,
    width: "100%",
    borderWidth: 1,
    borderColor: "#cbd5e1ff",
  },
  newInputContainer: {
    top: scaledSize(295),
    left: "7%",
    right: scaledSize(17),
    position: "absolute",
    width: "86%",
    height: scaledSize(60),
  },
  newPasswordText: {
    fontFamily: "OpenSans-Regular",
    fontWeight: "400",
    fontSize: scaledSize(13),
    letterSpacing: 0,
    color: "#334155ff",
  },
  newPasswordInputView: {
    top: scaledSize(24),
    left: 0,
    right: 0,
    position: "absolute",
  },
  newPasswordInput: {
    backgroundColor: "#ffffffff",
    borderRadius: 8,
    width: "100%",
    borderWidth: 1,
    borderColor: "#cbd5e1ff",
  },
  firstCheckboxView: {
    top: scaledSize(480),
    left: scaledSize(40),
    position: "absolute",
  },
  checkboxText: {
    color: "#3f4144ff",
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: scaledSize(11),
    letterSpacing: 0,
    width: scaledSize(190),
  },
  secondCheckboxView: {
    top: scaledSize(505),
    left: scaledSize(40),
    position: "absolute",
  },
  thirdCheckboxView: {
    top: scaledSize(530),
    left: scaledSize(40),
    position: "absolute",
  },
  fourthCheckboxView: {
    top: scaledSize(555),
    left: scaledSize(40),
    position: "absolute",
  },
  fifthCheckboxView: {
    top: scaledSize(580),
    left: scaledSize(40),
    position: "absolute",
  },
  submitBtnView: {
    top: scaledSize(670),
    alignSelf: "center",
    position: "absolute",
  },
  submitBtn: {
    // height: scaledSize(28),
    width: scaledSize(178),
    borderRadius: 30,
    backgroundColor: "#3876f1ff",
    paddingHorizontal: scaledSize(3),
    paddingVertical: scaledSize(5),
  },
  yourPasswordText: {
    height: scaledSize(15),
    width: "100%",
    top: scaledSize(455),
    // left: scaledSize(39),
    // right: scaledSize(38),
    position: "absolute",
    fontFamily: "OpenSans-Regular",
    fontWeight: "400",
    fontSize: scaledSize(11),
    letterSpacing: 0,
    color: "#ff1717ff",
    textAlign: "center",
  },
  noteText: {
    height: scaledSize(13),
    width: scaledSize(190),
    top: scaledSize(610),
    left: scaledSize(70),
    position: "absolute",
    fontFamily: "OpenSans-Regular",
    fontWeight: "400",
    fontSize: scaledSize(11),
    letterSpacing: 0,
    color: "#3f4144ff",
  },
});
// Customizable Area End

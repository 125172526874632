import React, { Component } from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
  Modal,
  Platform,
  Image,
  SafeAreaView,
  FlatList
} from "react-native";
import { DeleteImage } from "../../blocks/categoriessubcategories/src/assets";
import Button from "./Button";
import { scaledSize } from "../../framework/src/Utilities";

// Customizable Area End
export default class Categoriesdetails extends Component<any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const { state, handleBackPress, handleSpiltTransaction, handleInputChange, onPressAddToReport, handleNoteChange, handleDeleteImage, handleImageLibrary, closeModal, deleteImage, handleDeleteSelectedImage, deleteCameraImage, handleCameraPress } = this.props;
    const imageToBeDeleted = state.imagesList[state.selectedIndex];
    const checkValue = (state.imagesList?.length || state.selectedImage?.length) ||  state.addNote?.length
    const listItem = state.isExpenseCategory ? state.transactionData[state.activeIndex] : state.incomeData[state.activeIndex]

    const renderItem = ({ item, index }:any) => {
      const isEnd = index === (state.isExpenseCategory ? state.selectedExpense.length - 1 : state.selectedCategory.length - 1);
      const attributeName = item?.attributes?.name || '';
      const inputValue = isEnd
        ? state.remainingAmount ? state.remainingAmount?.toFixed(2) : '$0.00'
        : state.inputFieldData[attributeName] ;
      return (
        <>
          <View style={styles.item}>
            <Text numberOfLines={2} style={styles.itemLabel}>
              {attributeName}
            </Text>
            <TextInput
              style={styles.input}
              keyboardType="numeric"
              value={inputValue}
              onChangeText={(text) =>
                handleInputChange(index, text, attributeName)
              }
              editable={!isEnd}
              placeholder="$0.00"
              placeholderTextColor="#3876F1"
            />
          </View>
          <View style={styles.divider} />
        </>
      );
    };

    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView style={styles.container}  >
          <View style={styles.safeAreaView}>
            {/* Component code starts here */}
            <View style={{ top: 16, left: 10 }}>
              <TouchableOpacity onPress={handleBackPress}>
                <Image source={require("../../blocks/categoriessubcategories/assets/rightArrow.png")} style={styles.leftArrowIcon} />
              </TouchableOpacity>
            </View>
            <View style={{ left: 30 }}>
              <TouchableOpacity onPress={handleBackPress}>
                <Text style={{ color: "#3876F1", fontWeight: "400", fontSize: 16, letterSpacing: 0 }}>
                  Back
                </Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity activeOpacity={1}>
              <View style={styles.transactions}>
                <View style={styles.cardContainer}>
                  <Text style={styles.amount}>
                    {listItem?.amount ? `$${listItem?.amount}` :"$0.00"}
                  </Text>
                  <Text style={styles.category}>{listItem?.name}</Text>
                </View>
                <View style={styles.divider} />
                <View style={{ marginTop: 12 }}>
                  <Text style={{ fontWeight: "700", fontSize: 16, marginBottom: 14, color: '#0F172A', }}>Split Transaction</Text>
                  <FlatList
                    data={state.isExpenseCategory ? state.selectedExpense : state.selectedCategory}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={renderItem}
                  />
                </View>
              </View>
            </TouchableOpacity>
            <View
              style={{
                borderRadius: 25,
                width: '100%',
                paddingHorizontal: 60,
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: '100%',
                }}
              >
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>

                  {
                    state.isUpload && (
                      state.imagesList &&
                      state.imagesList?.map((image: { uri: any; }, index: React.Key) => (
                        <>
                          <View
                            style={{
                              alignItems: "center",
                              marginTop: 10,
                              width: '100%',
                              justifyContent: 'center',
                              marginBottom: 22,
                            }}
                          >
                            <Text
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "OpenSans-Bold",
                                fontWeight: "700",
                                fontSize: 18,
                                letterSpacing: 0,
                                color: "#0F172A",
                                width: '100%',
                                textAlign: 'center',
                              }}
                            >{`Attached Photo`}</Text>
                          </View>
                          <View style={styles.imageCard}>
                            <View key={index} style={styles.imageContainer}>
                              <Image source={{ uri: image.uri }} style={styles.image} />
                              <TouchableOpacity data-test-id="deleteId" style={styles.deleteButton} onPress={() => handleDeleteImage(index)}>
                                <Image source={DeleteImage} style={styles.deleteIcon} />
                              </TouchableOpacity>
                            </View>
                          </View>
                          <Modal
                            animationType="slide"
                            transparent={true}
                            visible={state.showModal}
                            onRequestClose={closeModal}
                          >
                            <View style={styles.centeredView}>
                              <View style={styles.modalView}>
                                <Text style={styles.modalText}>Attached Photo</Text>

                                {imageToBeDeleted && (
                                  <View>
                                    <Image source={{ uri: imageToBeDeleted.uri }} style={styles.modalImage} />
                                  </View>
                                )}
                                <View style={{ justifyContent: "center", alignItems: "center" }}>
                                  <TouchableOpacity style={styles.modalDeleteButton}>
                                    <Image source={DeleteImage} style={styles.deleteIcon} />
                                  </TouchableOpacity>
                                  <Text style={{ fontSize: 13, fontWeight: "bold" }}>Delete Photo?</Text>
                                </View>
                                <View style={styles.modalButtons}>
                                  <View>
                                    <TouchableOpacity
                                      style={{
                                        borderColor: "#3876F1",
                                        height: 45,
                                        width: 150,
                                        borderRadius: 30,
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        margin: 3,
                                        backgroundColor: "white",
                                        alignItems: "center",

                                      }}
                                      onPress={closeModal}>
                                      <Text style={{
                                        color: '#3876F1',
                                        fontSize: 16,
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        marginTop: 9
                                      }}>No</Text>
                                    </TouchableOpacity>
                                  </View>
                                  <View>
                                    <TouchableOpacity
                                      style={{
                                        borderColor: "blue",
                                        height: 45,
                                        width: 150,
                                        borderRadius: 30,
                                        margin: 3,
                                        backgroundColor: "#3876F1"
                                      }}
                                      onPress={deleteImage}>
                                      <Text style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        marginTop: 9
                                      }}>Yes</Text>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </Modal>

                        </>
                      ))
                    )}
                  {state.isUploadCamera &&
                    state.selectedImage &&
                    state.selectedImage?.map((image: { uri: any; }, index: React.Key)=>{
                      return(
                        <>
                          <View
                            style={{
                              alignItems: "center",
                              marginTop: 10,
                              width: '100%',
                              justifyContent: 'center',
                              marginBottom: 22,
                            }}
                          >
                            <Text
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "OpenSans-Bold",
                                fontWeight: "700",
                                fontSize: 18,
                                letterSpacing: 0,
                                color: "#0F172A",
                                width: '100%',
                                textAlign: 'center',
                              }}
                            >{`Attached Photo`}</Text>
                          </View>
                          <View style={styles.imageCard}>
                            <View key={index} style={styles.imageContainer}>
                              <Image source={{ uri: image?.uri }} style={styles.image} />
                              <TouchableOpacity style={styles.deleteButton} onPress={handleDeleteSelectedImage} >
                                <Image source={DeleteImage} style={styles.deleteIcon} />
                              </TouchableOpacity>
                            </View>
                          </View>
                          <Modal
                            animationType="slide"
                            transparent={true}
                            visible={state.showModal}
                            onRequestClose={closeModal}
                          >
                            <View style={styles.centeredView}>
                              <View style={styles.modalView}>
                                <Text style={styles.modalText}>Attached Photo</Text>
                                <View key={index}>
                                  <Image source={{ uri: image?.uri }} style={styles.modalImage} />
                                </View>
                                <View style={{ justifyContent: "center", alignItems: "center" }}>
                                  <View style={{ flexDirection: "row" }}>
                                    <TouchableOpacity style={styles.modalDeleteButton}>
                                      <Image source={DeleteImage} style={styles.deleteIcon} />
                                    </TouchableOpacity>
                                  </View>
                                  <View>
                                    <Text style={{ fontSize: 13, fontWeight: "bold" }}>Delete Photo?</Text>
                                  </View>
                                </View>
                                <View style={styles.modalButtons}>
                                  <View>
                                    <TouchableOpacity
                                      style={{
                                        borderColor: "#3876F1",
                                        height: 45,
                                        width: 150,
                                        borderRadius: 30,
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        margin: 3,
                                        backgroundColor: "white",
                                        alignItems: "center",

                                      }}
                                      onPress={closeModal}>
                                      <Text style={{
                                        color: '#3876F1',
                                        fontSize: 16,
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        marginTop: 9
                                      }}>No</Text>
                                    </TouchableOpacity>
                                  </View>
                                  <View>
                                    <TouchableOpacity
                                      style={{
                                        borderColor: "blue",
                                        height: 45,
                                        width: 150,
                                        borderRadius: 30,
                                        margin: 3,
                                        backgroundColor: "#3876F1"
                                      }}
                                      onPress={deleteCameraImage}>
                                      <Text style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        marginTop: 9
                                      }}>Yes</Text>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </Modal>
                        </>
                      )
                    }) }

                  {(state.imagesList?.length === 0 && state.selectedImage?.length === 0) && (
                    <>
                      <View
                        style={{
                          alignItems: "center",
                          marginTop: 10,
                          width: '100%',
                          justifyContent: 'center',
                          marginBottom: 22,
                        }}
                      >
                        <TouchableOpacity activeOpacity={1}>
                          <Text
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "OpenSans-Bold",
                              fontWeight: "700",
                              fontSize: 18,
                              letterSpacing: 0,
                              color: "#0F172A",
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >{`Add Photo Receipt From`}</Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <TouchableOpacity
                          style={{
                            height: 44,
                            width: 325,
                            borderRadius: 30,
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                            backgroundColor: "#15066A"
                          }}
                          onPress={handleCameraPress}>
                          <Text style={{
                            color: '#fff',
                            fontSize: 16,
                            fontWeight: '700',
                            textAlign: 'center',
                          }}>CAMERA</Text>
                        </TouchableOpacity>

                      </View>
                      <View style={{ marginTop: 12, width: '100%', }}>
                        <TouchableOpacity
                          style={{
                            borderColor: "#15066A",
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderStyle: 'solid',
                            height: 44,
                            width: 325,
                            borderRadius: 30,
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                          }}
                          onPress={handleImageLibrary}>
                          <Text style={{
                            color: '#15066A',
                            fontSize: 16,
                            fontWeight: '700',
                            textAlign: 'center',
                          }}>PHOTO LIBRARY</Text>
                        </TouchableOpacity>

                      </View>
                    </>
                  )}
                </View>
                <View
                  style={{ marginTop: 24, marginBottom: 12 }}>
                  <Text
                    style={{
                      fontFamily: "OpenSans-Bold",
                      fontWeight: "700",
                      fontSize: 16,
                      letterSpacing: 0,
                      color: "#0f172a",
                      textAlign: 'center',
                      width: 160,
                    }}
                  >{`Add Note`}</Text>
                </View>
                <View>
                  <TextInput
                    data-test-id="addNote"
                    style={styles.textbox}
                    placeholder={"Describe your note here..."}
                    maxLength={100}
                    value={state.addNote}
                    multiline
                    onChangeText={(text: string) => handleNoteChange(text)}
                  />
                </View>
                <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal: scaledSize(20), marginTop: scaledSize(15), marginBottom: scaledSize(30) }}>
                  <Button
                    theme={"primary"}
                    text={"SAVE EDITS"}
                    textStyle={{ color: checkValue ? "#3876F1": "#ffffffff", fontSize: scaledSize(12), lineHeight: scaledSize(19) }}
                    style={{
                      marginRight: 10,
                      height: scaledSize(30),
                      width: scaledSize(130),
                      borderRadius: scaledSize(44),
                      paddingHorizontal: 0,
                      paddingVertical: 0,
                      ...checkValue && {
                        borderColor: "#3876F1",
                        borderWidth: 1,
                        backgroundColor: "#ffffffff",
                      }}}
                    testID="testAddDetails"
                    onPress={handleSpiltTransaction}
                  />
                  <Button
                    theme={checkValue ? "secondary" : "primary"}
                    text={"ADD TO REPORT"}
                    disabled={checkValue ? false : true}
                    textStyle={{ color: "#ffffffff", fontSize: scaledSize(12), lineHeight: scaledSize(19) }}
                    style={{
                      marginLeft: 10,
                      height: scaledSize(30),
                      width: scaledSize(130),
                      borderRadius: scaledSize(44),
                      paddingHorizontal: 0,
                      paddingVertical: 0,
                    }}
                    testID="testAddReport"
                    onPress={onPressAddToReport}
                  />
                </View>
              </View>
            </View>
            {/* Component code ends here */}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    flexGrow: 1,
    backgroundColor: "#ffffffff"
  },
  safeAreaView: {
    backgroundColor: "#ffffffff",
  },
  cardContainer: {
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
  },
  amount: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 32,
    color: '#0F172A',

  },
  category: {
    fontSize: 18,
    color: '#3876F1',
    marginTop: 2,
    fontWeight: '400',
  },
  divider: {
    borderBottomColor: '#B3B3B3',
    borderBottomWidth: 1,
    marginVertical: 8,
    top: 0
  },
  transactions: {
    paddingVertical: 1,
    paddingHorizontal: 45,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 0,
    padding: 10,
  },
  itemLabel: {
    fontSize: 16,
    color: '#0F172A',
    paddingRight: 7,
    flexWrap: 'wrap',
    maxWidth: 90,
    maxHeight: 50

  },
  input: {
    borderWidth: 1,
    borderColor: '#CBD5E1',
    borderRadius: 4,
    fontSize: 16,
    width: 150,
    textAlign: 'right',
    color: '#3876F1',
    fontWeight: '400',
    paddingHorizontal: 11,
    paddingVertical: 2,
  },
  textbox: {
    borderWidth: 1,
    borderColor: '#CBD5E1',
    borderRadius: 2,
    fontSize: 16,
    width: 300,
    paddingBottom: 75,
    paddingLeft: 7,
    alignItems: 'center',
    justifyContent: 'center',
    textAlignVertical: 'top',
    top: 0,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  modalImage: {
    width: 200,
    height: 200,
    marginBottom: 20,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontWeight: "700",
    fontSize: 16,
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
    padding: 2
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
    marginBottom: 30
  },
  Bottom_Container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },
  dropdownView: {
    height: 150
  },
  checkbtn: {
    flexDirection: "row",
    marginVertical: 10
  },
  checktxt: {
    paddingLeft: 10,
    marginLeft: 10
  },
  leftArrowIcon: {
    top: 2,
    height: 16,
    width: 16,
    marginRight: 4,
    transform: [{ rotate: '180deg' }]
  },
  btnContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  webviewStyle: {
    height: 200
  },
  infoText: {
    width: "70%",
    fontSize: 16,
    marginVertical: 4
  },
  labelText: {
    fontWeight: "bold"
  },
  viewBtn: {
    backgroundColor: "blue",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderWidth: 1,
    borderColor: "blue"
  },
  addBtn: {
    backgroundColor: "blue",
    marginBottom: 10,
    width: 150,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end"
  },
  closeBtn: {
    backgroundColor: "#fff",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderColor: "#ccc",
    borderWidth: 1
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  modalBox: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff"
  },
  modal: {
    width: "80%",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    marginTop: 80,
    marginLeft: 40,
    padding: 15
  },
  viewBtnWidth: {
    width: "48%"
  },
  closeBtnText: {
    color: "#000",
    textAlign: "center",
    fontSize: 16
  },
  imageCard: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'relative',
    margin: 5,
  },
  image: {
    width: 135,
    height: 135,
  },
  modalDeleteButton: {
    paddingVertical: 5,
    paddingHorizontal: 8,
    alignItems: "center",
    justifyContent: "center",
    color: "gray",
    backgroundColor: '#fff',
    height: 36,
    width: 36,
    borderRadius: 100,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#B3B3B3',
    marginBottom: 20
  },
  deleteButton: {
    position: 'absolute',
    zIndex: 4,
    top: 115,
    left: -1,
    color: "gray",
    paddingVertical: 5,
    paddingHorizontal: 8,
    backgroundColor: '#fff',
    height: 38,
    width: 38,
    borderRadius: 100,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#B3B3B3'
  },
  deleteIcon: {
    color: "gray",
    backgroundColor: "white",
    width: 16,
    height: 22,
  },
});
// Customizable Area End

//@ts-nocheck
// Customizable Area Start

import React from "react";
import DashboardController from "./DashboardController.web";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import StorageProvider from "../../../framework/src/StorageProvider.web";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Box,
  Container,
  Divider,
  TextField,
  Card,
  CardContent,
  Snackbar,
} from "@material-ui/core";
import car from "../assets/car_new.png";
import { NavLink, Redirect } from "react-router-dom";
import moment from "moment";
import Alert from '@material-ui/lab/Alert';



const useStyles = (theme) => ({
  demo: {
    backgroundColor: "green"
  },
  transactionLeafOverCircle: {
    color: "#ffffff",
    fontSize: "60px",
    width: "30px",
    height: "30px",
    position: "absolute",
    right: "75px",
    top: "25px",
    fontWeight: "700",
    WebkitTextStroke: "1.2px black",
    '@media (max-width:1425px)': {
      fontSize: "45px",
      right: "60px",

    },
  },
  transactionCircleButton: {
    marginLeft: "40px",
    borderRadius: "20px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    textTransform: 'none',
    padding: "1px 15px 0px 15px",
    backgroundColor: "white"
  },
  transactionButtonText1: {
    paddingTop: "108px",
    color: "#FFF",
    fontWeight: "500",
    fontSize: "14px",
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width:1425px)': {
      fontSize: "15px",
      paddingTop: "108px",

    },
  },
  transactionButtonText2: {
    paddingBottom: "15px",
    color: "#FFF",
    fontWeight: "500",
    fontSize: "14px",
    textAlign: "center",
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },


  fullDisplay: {
    //height: "100%",
    width: "100%"
  },
  fullDisplayCircle: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    maxWidth: "100%"
  },
  mileageFirstRow: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    maxWidth: "100%",
    paddingTop: "15",
    paddingBottom: "10",
    '@media (max-width:1425px)': {
      paddingTop: "5px",
      paddingBottom: "11px",

    },
  },
  mileageCircle: {
    height: "44px",
    width: "44px",
    borderRadius: "50%",
    backgroundColor: "#ff9933",
    border: "2px solid #ff9933",
    '@media (max-width:1425px)': {
      height: "36px",
      width: "36px",

    },
  },
  mileageCircleWithoutContent: {
    height: "44px",
    width: "44px",
    borderRadius: "50%",
    border: "2px solid #ff9933",
    '@media (max-width:1425px)': {
      height: "36px",
      width: "36px",

    },
  },
  mileageCircleDate: {
    //#d9d9d9
    color: "#AEAEAE",
    fontSize: "11px",
    fontWeight: "700",
    '@media (max-width:1425px)': {
      fontSize: "8.25px",

    },

  },
  mileageCircleDay: {
    fontSize: "11px",
    fontWeight: "700",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "8.25px",

    },
  },
  gridItems: {
    maxWidth: "100%"
  },
  gridItemsPadding: {
    padding: "0px",
    maxWidth: "100%",
    textAlign: "center"
  },
  containerBox: {
    backgroundColor: "#ffffff",
    borderRadius: "10px"

  },
  title: {
    textAlign: "center"

  },
  transactionsFont: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#414346"
  },
  navLinkMain: {
    textDecoration: "none",
    color: "black"
  },
  equalWindow: {
    minHeight: "40vh"
  },
  displayNone: {
    display: "none",
  },
  displayNormal: {
    display: "",
  },
  mileageRoundTextMiles: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#3F4144",
    marginTop: "5",
    '@media (max-width:1425px)': {
      fontSize: "15px",

    },


  },
  mileageRoundText: {
    fontSize: "0.6rem",
    lineHeight: "1"
  },
  rightLeafLetter: {
    fontSize: "15px",
    color: "#4a4b4e",
    paddingBottom: "25px",
    paddingTop: "25px",
    fontWeight: 700,
    '@media (max-width:1425px)': {
      fontSize: "11.25px",
    },
  },
  boldFont: {
    fontWeight: "700",
    color: "#3F4144",
    fontSize: "20px",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  carNameFont: {
    color: "#AEAEAE",
    fontWeight: "700",
    paddingLeft: "10px",
    fontSize: "20px",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  carSize: {
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  transactionsOval: {
    height: "188px",
    width: "188px",
    borderRadius: "50%",
    '@media (max-width:1425px)': {
      height: "150px",
      width: "150px",

    },
  },
  transactionHeader2: {
    fontSize: "20px",
    color: "#3F4144",
    fontWeight: "700",
    textAlign: "center",
    '@media (max-width:1425px)': {
      fontSize: "15px",

    }

  },
  financialNormalFont: {
    fontSize: "20px",
    fontWeight: "400",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  headerBold: {
    fontWeight: "700",
    color: "#3F4144",
    fontSize: "25px",
    padding: "5px",
    '@media (max-width:1425px)': {
      fontSize: '18.75px',

    },
  },
  taxContentMedia: {
    fontWeight: "700",
    color: "#3F4144",
    fontSize: "20px",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  taxBottomContent: {
    fontWeight: "400",
    fontSize: "15px",
    color: "#ABABAB",
    paddingBottom: "15px",
    '@media (max-width:1425px)': {
      fontSize: '11.25px',

    },
  },
  mileageWeekRange: {
    textAlign: "center",
    fontSize: "11px",
    color: "#555759",
    marginBottom: "-5px",
    fontWeight: "700",
    '@media (max-width:1425px)': {
      fontSize: '8.25px',
      marginBottom: "-2px",

    },
  },
  mileageWeekDate: {
    fontWeight: "700",
    fontSize: "20px",
    '@media (max-width:1425px)': {
      fontSize: '15px',
      marginBottom: 3

    },
  },
  mileageLastLine: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: '12px',


    },
  },
  transactionBoxContainer: {
    width: '100%',
    height: "100%",
    backgroundColor: 'white',
    borderRadius: "10px",
    marginBottom: "41px",
    display: "grid",
    '@media (max-width:1425px)': {

      marginBottom: "22px",

    },

  },
  leafDivider: {
    border: "1.2px solid #444649",
    backgroundColor: "#444649"

  },
  leftFirstLeaf: {
    backgroundColor: "#ff9933",
    height: "30px",
    width: "30px",
    margin: "5px 0px 5px 5px",
    borderRadius: "0px 15px",
    '@media (max-width:1425px)': {
      height: "25px",
      width: "25px",
    },

  },
  leftLastLeaf: {
    backgroundColor: "#5696f4",
    height: "30px",
    width: "30px",
    margin: "0 0 20px 5px",
    borderRadius: "0px 15px",
    '@media (max-width:1425px)': {
      height: "25px",
      width: "25px",
    },

  },
  rightLeaf: {
    backgroundColor: "#b5d8fd",
    height: "30px",
    width: "30px",
    borderRadius: "15px 0px",
    marginBottom: "15px",
    '@media (max-width:1425px)': {
      height: "25px",
      width: "25px",
    },
  },
  borders: {
    border: "2px solid #cecece",
    borderRadius: "10px",
    padding: "10px"
  },
  buttonLayout: {
    backgroundColor: "#5597f4",
    borderRadius: "30px",
    color: "white",
    height: "30px",
    fontSize: "13px",
    fontWeight: "700",
    padding: "5px 20px",
    '&:hover': {
      backgroundColor: "#5597f4"
    },
    '@media (max-width:1425px)': {
      fontSize: "9.75px",

    },
  },
  gridMainDisplay: {

  }


})


class DashboardWeb extends DashboardController {
  super();
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    }
  }
  async componentDidMount() {
    this.startingApis()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.taxYear != this.props.taxYear) {
      this.updateWeek(0, nextProps.taxYear)
      this.getReports(nextProps.taxYear)
      this.getTransactionData(nextProps.taxYear)
    }
  }
  updateStatusTax(value: any) {
    this.setState({ taxYearForData: value }, () => { this.getTableData(this.props?.taxYear) })

  }
  async startingApis() {
    this.setState({
      token: await StorageProvider.get('token')
    })
    this.updateWeek(0, this.props?.taxYear)
    this.getReports(this.props?.taxYear)
    this.getTransactionData(this.props?.taxYear)
    this.getTableData(this.props?.taxYear)
    this.getGamificationData()
  }
  check1(v1: any, value: any) {
    if (v1 && !isNaN(value) && value !== 0 && value !== -0) {
      return true
    } else {
      return false
    }
  }
  checkMileageN(value: any) {
    if (value !== -0) {
      return true
    } else {
      return false
    }
  }


  render() {
    console.log(this.props);
    //const { classes } = this.props;
    const { classes } = this.props;




    return (

      <Grid
        id="root"
        container
        direction="row"
        justifyContent="center"
        alignItems="baseline"
        className={classes.fullDisplay}


        style={{
          // minHeight: "80vh" 
          height: "auto",
          marginTop: "15px",
          overflowX: "auto",
        }}


      >

        {this.props?.updatedTaxYear ? this.updateStatusTax(this.props?.taxYear) :

          <></>
        }

        {this.state.redirectionMileage ? <><Redirect to={"Dashboard/mileage"} />{this.setState({ redirectionMileage: false })}</> : ''}
        <Grid item xs={11} className={classes.fullDisplay} style={{ marginTop: "15px" }}>
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={classes.fullDisplay} >
            <Grid item xs={12} sm={12} md={6} className={classes.fullDisplay}
              style={{ padding: "10px" }}
            >
              <Grid container direction="column" justifyContent="space-between" alignItems="center" className={classes.fullDisplay}>
                <Grid item xs={6} className={classes.fullDisplay} style={{ height: "100%", maxWidth: "100%", paddingBottom: "15px" }}>
                  <NavLink to="/Dashboard/reports" className={classes.navLinkMain} >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.equalWindow}
                      style={{ width: '100%', backgroundColor: 'white' }}

                    >
                      <Grid item xs className={classes.title} style={{ width: "100%", marginBottom: "0px" }}>
                        <Typography className={classes.headerBold}>Financial Summary</Typography>

                      </Grid>
                      <Grid item xs={10} className={classes.borders} style={{ width: "90%", maxWidth: "100%" }}>
                        <Box style={{ width: "90%", alignContent: "center", margin: "auto", padding: "20px", color: "#515356" }}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont} >
                                Total Income
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont}>
                                {this.check1(this.state?.reportData?.report_type, this.state?.reportData.total_income) ? this.nf.format(this.state.reportData.total_income) : "$0.00"}

                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont}>
                                Costs of Goods Sold
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont}>
                                {this.check1(this.state?.reportData?.report_type, this.state?.reportData?.cost_of_goods) ? this.nf.format(this.state?.reportData?.cost_of_goods) : "$0.00"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ margin: "4px 0 7px 0" }} />
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom component="div" className={classes.boldFont} >
                                Gross Profit
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom component="div" className={classes.boldFont} >
                                {this.check1(this.state?.reportData?.report_type, this.state?.reportData?.gross_profit) ? this.nf.format(this.state.reportData.gross_profit) : "$0.00"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont}>
                                Operating Expenses
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont}>

                                {this.check1(this.state?.reportData?.report_type, this.state?.reportData.operating_expenses?.total_operating_expenses) ? this.nf.format(this.state?.reportData?.operating_expenses?.total_operating_expenses) : "$0.00"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont}>
                                Mileage
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom component="div" className={classes.financialNormalFont}>
                                {this.check1(this.state?.reportData?.report_type, this.state?.reportData?.mileage) ? this.nf.format(this.state.reportData.mileage) : "$0.00"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ margin: "4px 0 7px 0" }} />
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom className={classes.boldFont} >
                                Total Expenses
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom className={classes.boldFont} >
                                {this.check1(this.state.reportData?.report_type, this.state.reportData?.total_expenses) ? this.nf.format(this.state.reportData.total_expenses) : "$0.00"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ margin: "4px 0 7px 0" }} />
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom component="div" className={classes.boldFont} >
                                Net Profit
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom component="div" className={classes.boldFont} >
                                {this.check1(this.state.reportData?.report_type, this.state.reportData?.net_operating_profit) ? this.nf.format(this.state.reportData.net_operating_profit) : "$0.00"}
                              </Typography>
                            </Grid>
                          </Grid>


                        </Box>

                      </Grid>


                    </Grid>
                  </NavLink>
                </Grid>
                <Grid item xs={6} className={`${classes.fullDisplay} ${classes.containerBox}`} style={{ height: "100%", maxWidth: "95%" }}>
                  {/* <NavLink to="/Dashboard/mileage" className={classes.navLinkMain}> */}
                  <Grid container direction="column" xs={12} justifyContent="space-between" alignItems="center" className={`${classes.fullDisplay} ${classes.equalWindow}`} //wrap="nowrap"
                  >
                    <Grid item xs={2} className={`${classes.fullDisplay} ${classes.title}`} style={{ maxWidth: "100%", height: "100%", marginBottom: "0px" }}>
                      <Typography className={classes.headerBold}>Mileage</Typography>
                    </Grid>
                    <Box className={classes.borders} style={{ width: "90%" }} >
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        open={this.state.showSnackbar}
                        onClose={() => {
                          this.setState({
                            showSnackbar: false
                          });
                        }}
                        autoHideDuration={2000}
                      >
                        <Alert severity="error"
                          elevation={6} variant="filled"
                        >{this.state.snackbarMessage}
                        </Alert>
                      </Snackbar>
                      <NavLink to="/Dashboard/mileage" className={classes.navLinkMain}>
                        <Grid item xs={2} className={classes.mileageFirstRow} >
                          <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                            <Grid item style={{ zIndex: "1" }}>
                              <Typography className={classes.mileageWeekRange}>WEEK RANGE</Typography>
                              {/* #b0b1b1 */}
                              <Grid container direction="row" alignItems="center" justifyContent="center" style={{ color: "#c2c3c3" }}>
                                <Grid item style={{ color: "#9bc2f9" }}> <ChevronLeftIcon /> </Grid>
                                <Grid item >
                                  {/* <ChevronLeftIcon style={{ color: "#9bc2f9" }} /> */}
                                  <Typography className={classes.mileageWeekDate}>
                                    {this.state.firstDay} - {this.state.lastDay}
                                  </Typography>
                                  {/* <ChevronRightIcon style={{ color: "#9bc2f9" }} /> */}
                                </Grid>
                                <Grid item style={{ color: "#9bc2f9" }}><ChevronRightIcon /></Grid>

                              </Grid>

                            </Grid>
                            <Grid item style={{ textAlign: "end", paddingRight: "20px" }}>
                              <Button className={classes.buttonLayout} style={{ marginTop: "10px" }}>Add Miles</Button>
                            </Grid>
                          </Grid>


                          <br />
                          {/* <Typography> <ChevronLeftIcon /> August 14th - August 8th<ChevronRightIcon/></Typography> */}

                        </Grid>
                      </NavLink>
                      <Grid item xs={4} className={classes.fullDisplay} style={{ maxWidth: "100%", marginBottom: "5px", marginTop: "-25px" }}>
                        <Grid container direction="column">
                          <Grid item xs className={classes.fullDisplay} style={{ backgroundColor: "white", maxWidth: "100%", marginBottom: "5px" }}>
                            <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.fullDisplay} //wrap="nowrap"
                            >
                              <Grid item xs={4} className={classes.fullDisplay} style={{ maxWidth: "100%", textAlign: "end" }}>
                                <img src={car} alt="" style={{ height: "24px", width: "27.43px" }} />

                              </Grid>
                              <Grid item xs={6} className={classes.fullDisplay} style={{ maxWidth: "100%", overflow: "hidden", whiteSpace: "nowrap" }}>
                                {this.state.cars[0]?.name ? <Typography className={classes.carNameFont} >
                                  {this.state.cars[0]?.name}
                                </Typography> : <Box style={{ height: "30px" }}></Box>}


                              </Grid>
                            </Grid>
                            {/* <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.fullDisplay} //wrap="nowrap"
                            >
                              <Grid item xs={3} className={classes.fullDisplay} style={{ maxWidth: "100%", overflow: "hidden" }}>
                               
                              </Grid>
                              <Grid item xs={6} className={classes.fullDisplay} style={{ maxWidth: "80%" }}>
                                <img src={car} alt="" style={{ height: "60px", paddingLeft: "10%" }} />
                              </Grid>
                            </Grid> */}
                          </Grid>

                          <Grid item xs className={classes.fullDisplay} style={{ maxWidth: "100%" }}>
                            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={classes.fullDisplay} //wrap="nowrap"
                              style={{ maxWidth: "100%" }}>

                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => {
                                  localStorage.setItem("carId", this.state.cars[0]?.id), localStorage.setItem("mileageDate", this.state.weekDate[0]?.format('MM/DD/YYYY')), this.updateDate()
                                }
                                  //this.setMileageDateAndCar(this.state.cars[0]?.id, this.state.weekDate[0]?.format('MM/DD/YYYY'))

                                } style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[0]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[0]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }}>
                                    <Box className={this.matched(0, this.state.weekDate[0]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}

                                    >
                                      <Box className={this.matched(0, this.state.weekDate[0]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(0, this.state.weekDate[0]?.format('MM/DD/YYYY'))) ? this.matched(0, this.state.weekDate[0]?.format('MM/DD/YYYY')) : "0"}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} style={{ border: "1px solid red" }} onClick={() => {
                                  localStorage.setItem("carId", this.state.cars[0]?.id), localStorage.setItem("mileageDate", this.state.weekDate[1]?.format('MM/DD/YYYY')), this.updateDate()
                                }} style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[1]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[1]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }}>
                                    <Box className={this.matched(0, this.state.weekDate[1]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent} >
                                      <Box className={this.matched(0, this.state.weekDate[1]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone} style={{ cursor: "pointer" }}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(0, this.state.weekDate[1]?.format('MM/DD/YYYY'))) ? this.matched(0, this.state.weekDate[1]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => {
                                  localStorage.setItem("carId", this.state.cars[0]?.id), localStorage.setItem("mileageDate", this.state.weekDate[2]?.format('MM/DD/YYYY')), this.updateDate()
                                }
                                  //this.setMileageDateAndCar(this.state.cars[0]?.id, this.state.weekDate[2]?.format('MM/DD/YYYY'))

                                } style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[2]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[2]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "8px" }}>
                                    <Box className={this.matched(0, this.state.weekDate[2]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent} >
                                      <Box className={this.matched(0, this.state.weekDate[2]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(0, this.state.weekDate[2]?.format('MM/DD/YYYY'))) ? this.matched(0, this.state.weekDate[2]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => {
                                  localStorage.setItem("carId", this.state.cars[0]?.id), localStorage.setItem("mileageDate", this.state.weekDate[3]?.format('MM/DD/YYYY')), this.updateDate()
                                }
                                  //this.setMileageDateAndCar(this.state.cars[0]?.id, this.state.weekDate[3]?.format('MM/DD/YYYY'))

                                } style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[3]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[3]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "10px" }}>
                                    <Box className={this.matched(0, this.state.weekDate[3]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent} >
                                      <Box className={this.matched(0, this.state.weekDate[3]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(0, this.state.weekDate[3]?.format('MM/DD/YYYY'))) ? this.matched(0, this.state.weekDate[3]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => {
                                  localStorage.setItem("carId", this.state.cars[0]?.id), localStorage.setItem("mileageDate", this.state.weekDate[4]?.format('MM/DD/YYYY')), this.updateDate()
                                }
                                  //this.setMileageDateAndCar(this.state.cars[0]?.id, this.state.weekDate[4]?.format('MM/DD/YYYY'))

                                } style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[4]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[4]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }}>
                                    <Box className={this.matched(0, this.state.weekDate[4]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent} >
                                      <Box className={this.matched(0, this.state.weekDate[4]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(0, this.state.weekDate[4]?.format('MM/DD/YYYY'))) ? this.matched(0, this.state.weekDate[4]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => {
                                  localStorage.setItem("carId", this.state.cars[0]?.id), localStorage.setItem("mileageDate", this.state.weekDate[5]?.format('MM/DD/YYYY')), this.updateDate()
                                }
                                  //this.setMileageDateAndCar(this.state.cars[0]?.id, this.state.weekDate[5]?.format('MM/DD/YYYY'))

                                } style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[5]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[5]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }}>
                                    <Box className={this.matched(0, this.state.weekDate[5]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent} >
                                      <Box className={this.matched(0, this.state.weekDate[5]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(0, this.state.weekDate[5]?.format('MM/DD/YYYY'))) ? this.matched(0, this.state.weekDate[5]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => {
                                  localStorage.setItem("carId", this.state.cars[0]?.id), localStorage.setItem("mileageDate", this.state.weekDate[6]?.format('MM/DD/YYYY')), this.updateDate()
                                }
                                } style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[6]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[6]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "8px" }}>
                                    <Box className={this.matched(0, this.state.weekDate[6]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}
                                    >
                                      <Box className={this.matched(0, this.state.weekDate[6]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(0, this.state.weekDate[6]?.format('MM/DD/YYYY'))) ? this.matched(0, this.state.weekDate[6]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                      <Grid item xs={4} className={classes.fullDisplay} style={{ maxWidth: "100%" }}>
                        <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
                          <Grid item xs className={classes.fullDisplay} style={{ backgroundColor: "white", maxWidth: "100%", marginBottom: "5px" }}>
                            <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.fullDisplay} //wrap="nowrap"
                            >

                              <Grid item xs={4} className={classes.fullDisplay} style={{ maxWidth: "100%", textAlign: "end" }}>
                                <img src={car} alt="" style={{ height: "24px", width: "27.43px" }} />

                              </Grid>
                              <Grid item xs={6} className={classes.fullDisplay} style={{ maxWidth: "100%", overflow: "hidden", whiteSpace: "nowrap" }}>
                                {this.state.cars[1]?.name ? <Typography className={classes.carNameFont} >
                                  {this.state.cars[1]?.name}
                                </Typography>
                                  :
                                  <Box style={{ height: "30px" }}></Box>
                                }


                              </Grid>
                            </Grid>

                          </Grid>

                          <Grid item xs className={classes.fullDisplay} style={{ maxWidth: "100%" }}>
                            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={classes.fullDisplay} //wrap="nowrap"
                              style={{ maxWidth: "100%" }}>

                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`} >
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[0]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[0]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }} >
                                    <Box className={this.matched(1, this.state.weekDate[0]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}
                                      onClick={() => { localStorage.setItem("carId", this.state.cars[1]?.id), localStorage.setItem("mileageDate", this.state.weekDate[0]?.format('MM/DD/YYYY')), this.updateDate() }} style={{ cursor: "pointer" }}
                                    >
                                      <Box className={this.matched(1, this.state.weekDate[0]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(1, this.state.weekDate[0]?.format('MM/DD/YYYY'))) ? this.matched(1, this.state.weekDate[0]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => { localStorage.setItem("carId", this.state.cars[1]?.id), localStorage.setItem("mileageDate", this.state.weekDate[1]?.format('MM/DD/YYYY')), this.updateDate() }} style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[1]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[1]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }}>
                                    <Box className={this.matched(1, this.state.weekDate[1]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}

                                    >
                                      <Box className={this.matched(1, this.state.weekDate[1]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(1, this.state.weekDate[1]?.format('MM/DD/YYYY'))) ? this.matched(1, this.state.weekDate[1]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => { localStorage.setItem("carId", this.state.cars[1]?.id), localStorage.setItem("mileageDate", this.state.weekDate[2]?.format('MM/DD/YYYY')), this.updateDate() }} style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[2]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[2]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "8px" }}>
                                    <Box className={this.matched(1, this.state.weekDate[2]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}

                                    >
                                      <Box className={this.matched(1, this.state.weekDate[2]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(1, this.state.weekDate[2]?.format('MM/DD/YYYY'))) ? this.matched(1, this.state.weekDate[2]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => { localStorage.setItem("carId", this.state.cars[1]?.id), localStorage.setItem("mileageDate", this.state.weekDate[3]?.format('MM/DD/YYYY')), this.updateDate() }} style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[3]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[3]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "10px" }}>
                                    <Box className={this.matched(1, this.state.weekDate[3]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}

                                    >
                                      <Box className={this.matched(1, this.state.weekDate[3]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(1, this.state.weekDate[3]?.format('MM/DD/YYYY'))) ? this.matched(1, this.state.weekDate[3]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => { localStorage.setItem("carId", this.state.cars[1]?.id), localStorage.setItem("mileageDate", this.state.weekDate[4]?.format('MM/DD/YYYY')), this.updateDate() }} style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[4]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[4]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }}>
                                    <Box className={this.matched(1, this.state.weekDate[4]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}

                                    >
                                      <Box className={this.matched(1, this.state.weekDate[4]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(1, this.state.weekDate[4]?.format('MM/DD/YYYY'))) ? this.matched(1, this.state.weekDate[4]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => { localStorage.setItem("carId", this.state.cars[1]?.id), localStorage.setItem("mileageDate", this.state.weekDate[5]?.format('MM/DD/YYYY')), this.updateDate() }} style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[5]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[5]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "5px" }}>
                                    <Box className={this.matched(1, this.state.weekDate[5]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}

                                    >
                                      <Box className={this.matched(1, this.state.weekDate[5]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(1, this.state.weekDate[5]?.format('MM/DD/YYYY'))) ? this.matched(1, this.state.weekDate[5]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={`${classes.fullDisplay} ${classes.gridItemsPadding}`}>
                                <Grid container direction="column" alignContent="center" className={classes.fullDisplay} onClick={() => { localStorage.setItem("carId", this.state.cars[1]?.id), localStorage.setItem("mileageDate", this.state.weekDate[6]?.format('MM/DD/YYYY')), this.updateDate() }} style={{ cursor: "pointer" }}>
                                  <Grid item xs={2} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDay}>{this.state.weekDays[6]?.toUpperCase()}</Typography></Grid>
                                  <Grid item xs={1} className={classes.fullDisplayCircle}><Typography className={classes.mileageCircleDate}>{this.state.weekDate[6]?.format('MM/DD')}</Typography></Grid>
                                  <Grid item xs={4} className={classes.fullDisplay} style={{ marginLeft: "8px" }}>
                                    <Box className={this.matched(1, this.state.weekDate[6]?.format('MM/DD/YYYY')) != null ? classes.mileageCircle : classes.mileageCircleWithoutContent}

                                    >
                                      <Box className={this.matched(1, this.state.weekDate[6]?.format('MM/DD/YYYY')) != null ? classes.displayNormal : classes.displayNone}>
                                        <Typography className={classes.mileageRoundTextMiles}>{this.checkMileageN(this.matched(1, this.state.weekDate[6]?.format('MM/DD/YYYY'))) ? this.matched(1, this.state.weekDate[6]?.format('MM/DD/YYYY')) : '0'}</Typography>
                                        {/* <Typography className={classes.mileageRoundText}>Miles</Typography> */}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>

                      <Grid item xs={1} className={classes.fullDisplayCircle} style={{ maxWidth: "100%", marginTop: "10px" }}>
                        <Typography className={classes.mileageLastLine}>Year to Date Business Miles &nbsp;&nbsp;&nbsp;&nbsp; {this.checkMileageN(this.state.yearlyMiles) ? this.state.yearlyMiles : '0'} </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                  {/* </NavLink>  */}

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.fullDisplay} style={{ padding: "10px" }}>
              <Grid container direction="column" alignItems="center" justifyContent="space-between" className={classes.fullDisplay} >
                <Grid item xs={6} className={classes.fullDisplay} style={{ height: "100%", maxWidth: "95%", paddingBottom: "15px", marginRight: "27px", marginLeft: "-60px" }}>
                  <NavLink to="/Dashboard/transactions" className={classes.navLinkMain}>
                    <Grid container direction="column" alignItems="center" justifyContent="space-between" style={{ height: "100%", maxWidth: "100%" }} className={`${classes.fullDisplay} ${classes.equalWindow}`} >
                      <Grid item xs={2} className={classes.title} style={{ width: "100%", height: "100%", maxWidth: "100%", marginBottom: "0px" }}>
                        <Typography className={classes.headerBold}>Transactions</Typography>

                      </Grid>
                      <Box className={classes.borders} style={{ width: "90%" }}>
                        <Grid item xs={10} style={{ maxWidth: "100%", height: "100%", width: "100%" }} >
                          <Grid item xs={12} style={{ width: "100%", height: "100%" }}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"

                              className={classes.transactionBoxContainer}
                            >
                              <Grid item xs={8} style={{ maxWidth: "100%", width: '100%', height: '100%', display: "flex", marginTop: "28" }}>

                                <Box>
                                  <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
                                    <CardContent style={{ paddingBottom: "0", padding: "14px 12px", marginBottom: "15px", marginTop: "5px" }}>
                                      <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Grid item xs>
                                          <Box display={"inline-flex"}>
                                            <Typography style={{ margin: "-5 0 5 0" }} className={classes.transactionHeader2}>Income</Typography>
                                          </Box>
                                          {this.state.directTransaction ? <><Redirect to={'/Dashboard/transactions'} />{this.setState({ directTransaction: false })} </> : <></>}
                                        </Grid>
                                        <Grid item xs>
                                          <Grid style={{ position: "relative" }} onClick={() => { this.directionalTransaction(1) }}>
                                            <Box style={{ backgroundImage: "linear-gradient(to right, #15066A ,#3877F2, #5696F4)" }} className={classes.transactionsOval} >
                                              <Typography className={classes.transactionButtonText1} >Uncategorized</Typography>
                                              <Typography className={classes.transactionButtonText2} >Transactions</Typography>

                                              {/* <Button variant="contained" size='small' className={classes.transactionCircleButton}>  Categorize  </Button> */}

                                            </Box>
                                            <Grid
                                              item
                                              container
                                              justifyContent="center"
                                              alignItems="center"
                                              className={classes.transactionLeafOverCircle}
                                            >
                                              {this.checkMileageN(this.state.bankCount) ? this.state.bankCount : '0'}
                                            </Grid>
                                          </Grid>


                                        </Grid>

                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                                <Box>
                                  <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>

                                    <CardContent style={{ paddingBottom: "0", padding: "14px 12px", marginTop: "10px" }}>

                                      <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Box display={"inline-flex"}>
                                            <Typography style={{ margin: "-10 0 14 0" }} className={classes.transactionHeader2}>Expenses</Typography>
                                          </Box>


                                        </Grid>
                                        <Grid item>
                                          <Grid style={{ position: "relative" }} onClick={() => { this.directionalTransaction(2) }}>
                                            <Box style={{ backgroundImage: "linear-gradient(to right, #ff860d , #ffcc99)", marginTop: "-10px" }} className={classes.transactionsOval} >
                                              <Typography className={classes.transactionButtonText1}>Uncategorized</Typography>
                                              <Typography className={classes.transactionButtonText2}>Transactions</Typography>

                                              {/* <Button variant="contained" size='small' className={classes.transactionCircleButton} >  Categorize  </Button> */}

                                            </Box>
                                            <Grid
                                              item
                                              container
                                              justifyContent="center"
                                              alignItems="center"
                                              className={classes.transactionLeafOverCircle}
                                            >
                                              {this.state.creditCardCount != 0 ? this.state.creditCardCount : '0'}
                                            </Grid>
                                          </Grid>

                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>


                            </Grid>
                          </Grid>
                          <Grid item style={{ width: "100%", textAlign: "center", marginBottom: "-30px" }}>
                            <Button className={classes.buttonLayout} style={{ position: "relative", top: "-30px" }} onClick={() => { this.setState({ directTransaction: true }) }}>view transactions</Button>
                          </Grid>

                        </Grid>
                      </Box>
                    </Grid>
                  </NavLink>
                </Grid>
                <Grid item xs={6} className={classes.fullDisplay} style={{ height: "100%", maxWidth: "95%", marginRight: "27px", marginLeft: "-60px" }}>
                  <NavLink to="/Dashboard/taxes" className={classes.navLinkMain}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.equalWindow}
                      style={{ width: '100%', backgroundColor: 'white' }}
                    >
                      <Grid item xs={2} className={classes.title} style={{ maxWidth: "100%", width: "100%", marginBottom: "0px" }}>
                        <Typography className={classes.headerBold}>Taxes</Typography>

                      </Grid>
                      <Box className={classes.borders} style={{ width: "90%" }}>
                        <Grid item xs={6} className={`${classes.fullDisplay}`} style={{ maxWidth: "100%" }}>
                          <Box style={{ width: "90%", alignContent: "center", marginRight: "auto", marginLeft: "auto", color: "#515356", fontSize: "0.8rem" }}>
                            <Grid item style={{ textAlign: "center", marginBottom: "10px" }}>
                              <br />
                              <Typography className={classes.boldFont} style={{ marginBottom: "10px" }}>Tables</Typography>
                              <Divider />
                              <br />
                            </Grid>
                            <Grid container alignItems="center" >

                              <Grid item xs>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia} >
                                  Taxable Income
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia}>

                                  {this.check1(this.state?.estimatedTaxableIncome, this.state?.estimatedTaxableIncome) ? this.nf.format(this.state.estimatedTaxableIncome) : "$0.00"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <br />
                            <Grid container alignItems="center">
                              <Grid item xs>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia}>
                                  Calculated Tax
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia}>
                                  {this.check1(this.state.calculatedTax, this.state.calculatedTax) ? this.nf.format(this.state.calculatedTax) : "$0.00"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <br />
                            <Grid container alignItems="center">
                              <Grid item xs>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia}>
                                  Total Paid
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia}>
                                  {this.check1(this.state?.totalPaid, this.state?.totalPaid) ? this.nf.format(this.state.totalPaid) : "$0.00"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <br />
                            <Grid container alignItems="center">
                              <Grid item xs>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia}>
                                  Over/(Under) Paid
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography gutterBottom component="div" className={classes.taxContentMedia}>
                                  {this.check1(this.state?.overUnderPaid, this.state?.overUnderPaid) ? this.nf.format(this.state.overUnderPaid) : "$0.00"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <br />
                            <Grid item className={classes.fullDisplay} style={{ maxWidth: "100%", textAlign: "center" }}>
                              <Typography className={classes.taxBottomContent} >To see fixed and/or estimated tax calculations <span style={{ color: "#5696F4" }}>click here</span></Typography>
                            </Grid>



                          </Box>
                        </Grid>
                        {/* <Grid item xs={2} className={classes.fullDisplay} style={{ maxWidth: "100%", textAlign: "center", marginBottom: "5px" }}>
                          <Typography className={classes.mileageCircleDate} style={{ fontSize: "0.8rem", color: "grey" }}>To see fixed and/or estimated tax calculations <span style={{ color: "lightblue" }}>click here</span></Typography>
                        </Grid> */}
                      </Box>


                    </Grid>
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


        </Grid>
        <Grid item xs={1} className={classes.fullDisplay} style={{ marginLeft: "-40px", marginTop: "auto" }}>
          {this.state.isgamificationDataLoaded ?
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"

              className={classes.fullDisplay}

            >
              <Grid item xs={2.2} className={classes.fullDisplay} style={{ padding: "5px 0px 5px 10px" }}>
                <Typography className={classes.rightLeafLetter} style={{ paddingLeft: "5px", }}>Monday </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `0px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    backgroundColor: "transparent",
                    color: 'text.secondary',
                    '& svg': {
                      m: 1.5,
                    },
                    '& hr': {
                      mx: 0,
                    },
                  }}
                >

                  <Box>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                    //alignItems="flex-end"
                    >
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Monday" ?
                            days?.attributes?.expense_leaf ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))} */}

                        {/* <Box className={classes.leftFirstLeaf}></Box> */}
                        {this.state.mondayData?.[0] ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>}
                      </Grid>
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Monday" ?
                            days?.attributes?.income_leaf ? <Box className={classes.leftLastLeaf} ></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))

                        } */}
                        {this.state.mondayData?.[1] ? <Box className={classes.leftLastLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftLastLeaf}></Box>}

                      </Grid>
                    </Grid>
                  </Box>

                  <Divider orientation="vertical" flexItem className={classes.leafDivider} />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                  //alignItems="flex-end"
                  >
                    <Grid item xs={4}>
                      {/* {this.state.gamificationData.map((days: any) => (
                        days?.attributes?.day == "Monday" ?
                          days?.attributes?.mileage_leaf ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                      ))} */}
                      {this.state.mondayData?.[2] ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.rightLeaf}></Box>}


                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={2.2} className={classes.fullDisplay} style={{ padding: "5px 0px 5px 10px" }}>
                <Typography className={classes.rightLeafLetter} style={{ paddingLeft: "5px" }}>Tuesday</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `0px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    backgroundColor: "transparent",
                    color: 'text.secondary',
                    '& svg': {
                      m: 1.5,
                    },
                    '& hr': {
                      mx: 0,
                    },
                  }}
                >

                  <Box>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                    //alignItems="flex-end"
                    >
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Tuesday" ?
                            days?.attributes?.expense_leaf ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))} */}

                        {/* <Box className={classes.leftFirstLeaf}></Box> */}
                        {this.state.tuesdayData?.[0] ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>}

                      </Grid>
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Tuesday" ?
                            days?.attributes?.income_leaf ? <Box className={classes.leftLastLeaf} ></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))

                        } */}
                        {this.state.tuesdayData?.[1] ? <Box className={classes.leftLastLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftLastLeaf}></Box>}

                      </Grid>
                    </Grid>
                  </Box>

                  <Divider orientation="vertical" flexItem className={classes.leafDivider} />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                  //alignItems="flex-end"
                  >
                    <Grid item xs={4}>
                      {/* {this.state.gamificationData.map((days: any) => (
                        days?.attributes?.day == "Tuesday" ?
                          days?.attributes?.mileage_leaf ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                      ))} */}
                      {this.state.tuesdayData?.[2] ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.rightLeaf}></Box>}


                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={2.2} className={classes.fullDisplay} style={{ padding: "5px 0px 5px 10px" }}>
                <Typography className={classes.rightLeafLetter} style={{ marginLeft: "-5px" }}>Wednesday</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `0px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    backgroundColor: "transparent",
                    color: 'text.secondary',
                    '& svg': {
                      m: 1.5,
                    },
                    '& hr': {
                      mx: 0,
                    },
                  }}
                >

                  <Box>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                    //alignItems="flex-end"
                    >
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Wednesday" ?
                            days?.attributes?.expense_leaf ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))} */}

                        {/* <Box className={classes.leftFirstLeaf}></Box> */}
                        {this.state.wednesdayData?.[0] ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>}

                      </Grid>
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Wednesday" ?
                            days?.attributes?.income_leaf ? <Box className={classes.leftLastLeaf} ></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))

                        } */}
                        {this.state.wednesdayData?.[1] ? <Box className={classes.leftLastLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftLastLeaf}></Box>}

                      </Grid>
                    </Grid>
                  </Box>

                  <Divider orientation="vertical" flexItem className={classes.leafDivider} />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                  //alignItems="flex-end"
                  >
                    <Grid item xs={4}>
                      {/* {this.state.gamificationData.map((days: any) => (
                        days?.attributes?.day == "Wednesday" ?
                          days?.attributes?.mileage_leaf ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                      ))} */}

                      {this.state.wednesdayData?.[2] ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.rightLeaf}></Box>}

                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={2.2} className={classes.fullDisplay} style={{ padding: "5px 0px 5px 10px" }}>
                <Typography className={classes.rightLeafLetter} style={{ paddingLeft: "3px" }}>Thursday</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `0px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    '& svg': {
                      m: 1.5,
                    },
                    '& hr': {
                      mx: 0,
                    },
                  }}
                >
                  <Box>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                    //alignItems="flex-end"
                    >
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Thursday" ?
                            days?.attributes?.expense_leaf ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))} */}

                        {/* <Box className={classes.leftFirstLeaf}></Box> */}
                        {this.state.thursdayData?.[0] ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>}

                      </Grid>
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Thursday" ?
                            days?.attributes?.income_leaf ? <Box className={classes.leftLastLeaf} ></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))

                        } */}
                        {this.state.thursdayData?.[1] ? <Box className={classes.leftLastLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftLastLeaf}></Box>}

                      </Grid>
                    </Grid>
                  </Box>

                  <Divider orientation="vertical" flexItem className={classes.leafDivider} />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                  //alignItems="flex-end"
                  >
                    <Grid item xs={4}>
                      {/* {this.state.gamificationData.map((days: any) => (
                        days?.attributes?.day == "Thursday" ?
                          days?.attributes?.mileage_leaf ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                      ))} */}
                      {this.state.thursdayData?.[2] ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.rightLeaf}></Box>}


                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={2.2} className={classes.fullDisplay} style={{ padding: "5px 0px 5px 10px" }}>
                <Typography className={classes.rightLeafLetter} style={{ paddingLeft: "12px" }}>Friday</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `0px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    '& svg': {
                      m: 1.5,
                    },
                    '& hr': {
                      mx: 0,
                    },
                  }}
                >
                  <Box>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                    //alignItems="flex-end"
                    >
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Friday" ?
                            days?.attributes?.expense_leaf ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))} */}

                        {/* <Box className={classes.leftFirstLeaf}></Box> */}
                        {this.state.fridayData?.[0] ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>}

                      </Grid>
                      <Grid item xs={4}>
                        {/* {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Friday" ?
                            days?.attributes?.income_leaf ? <Box className={classes.leftLastLeaf} ></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))

                        } */}
                        {this.state.fridayData?.[1] ? <Box className={classes.leftLastLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftLastLeaf}></Box>}

                      </Grid>
                    </Grid>
                  </Box>


                  <Divider orientation="vertical" flexItem className={classes.leafDivider} />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                  //alignItems="flex-end"
                  >
                    <Grid item xs={4}>
                      {/* {this.state.gamificationData.map((days: any) => (
                        days?.attributes?.day == "Friday" ?
                          days?.attributes?.mileage_leaf ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                      ))} */}
                      {this.state.fridayData?.[2] ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.rightLeaf}></Box>}


                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* <Grid item xs={2.2} className={classes.fullDisplay} style={{ padding: "5px 0px 5px 10px" }}>
                <Typography className={classes.rightLeafLetter} style={{ paddingLeft: "12px" }}>Saturday</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `0px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    '& svg': {
                      m: 1.5,
                    },
                    '& hr': {
                      mx: 0,
                    },
                  }}
                >
                  <Box>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                    //alignItems="flex-end"
                    >
                      <Grid item xs={4}>
                        {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Saturday" ?
                            days?.attributes?.expense_leaf ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))}


                      </Grid>
                      <Grid item xs={4}>
                        {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Saturday" ?
                            days?.attributes?.income_leaf ? <Box className={classes.leftLastLeaf} ></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))

                        }
                      </Grid>
                    </Grid>
                  </Box>


                  <Divider orientation="vertical" flexItem className={classes.leafDivider} />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                  //alignItems="flex-end"
                  >
                    <Grid item xs={4}>
                      {this.state.gamificationData.map((days: any) => (
                        days?.attributes?.day == "Saturday" ?
                          days?.attributes?.mileage_leaf ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                      ))}


                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={2.2} className={classes.fullDisplay} style={{ padding: "5px 0px 5px 10px" }}>
                <Typography className={classes.rightLeafLetter} style={{ paddingLeft: "12px" }}>Sunday</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `0px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    '& svg': {
                      m: 1.5,
                    },
                    '& hr': {
                      mx: 0,
                    },
                  }}
                >
                  <Box>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                    //alignItems="flex-end"
                    >
                      <Grid item xs={4}>
                        {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Sunday" ?
                            days?.attributes?.expense_leaf ? <Box className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))}

                    </Grid>
                    <Grid item xs={4}>
                        {this.state.gamificationData.map((days: any) => (
                          days?.attributes?.day == "Sunday" ?
                            days?.attributes?.income_leaf ? <Box className={classes.leftLastLeaf} ></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                        ))

                        }
                      </Grid>
                    </Grid>
                  </Box>


                  <Divider orientation="vertical" flexItem className={classes.leafDivider} />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                  //alignItems="flex-end"
                  >
                    <Grid item xs={4}>
                      {this.state.gamificationData.map((days: any) => (
                        days?.attributes?.day == "Sunday" ?
                          days?.attributes?.mileage_leaf ? <Box className={classes.rightLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box> : <Box style={{ backgroundColor: "transparent" }} className={classes.leftFirstLeaf}></Box>
                      ))}


                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}

            </Grid>
            : ""}
        </Grid>

      </Grid >

    );
  }
}

// Customizable Area End
export default withStyles(useStyles)(DashboardWeb);

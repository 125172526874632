import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import StorageProvider from "../../../framework/src/StorageProvider.web";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  token: string;
  modalState: boolean;
  addAccountModal: boolean;
  editAccountModal: boolean;
  modalFlag: boolean;
  handleAddOrEditModal: any;
  catByTier: any;
  valueOfCat: string;
  name: string;
  categories: any;
  category: any;
  splitData: any;
  cog: boolean;
  func: any;
  activeTab: number;
  id: number;
  transactionId: any;
  error: any;
  snackbarMessage: any;
  showSnackbar: any;
  role: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class AddOrEditAccountModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCategoryId: any;
  allCategoriesId: any;
  updateCatergoyId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeTab: 0,
      cog: false,
      category: '',
      categories: [],
      catByTier: [],
      handleAddOrEditModal: null,
      modalFlag: true,
      transactionId: '',
      showSnackbar: false,
      addAccountModal: false,
      editAccountModal: false,
      valueOfCat: 'schedule_c',
      name: '',
      splitData: [null, null],
      func: '',
      id: 0,
      modalState: false,
      token: '',
      snackbarMessage: '',
      error: {},
      role: ""
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createCategoryId) {
          this.responseModalFunction(responseJson)
        } else if (apiRequestCallId === this.allCategoriesId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              let data: any[] = []
              responseJson.data = responseJson.data.filter((el: any) => el.name !== 'other_category')
              if(this.state.role !== "SUPER_ADMIN"){
                responseJson.data = responseJson.data.filter((el: any) => el.name !== 'shedule_c_categories')
              }
              responseJson.data.forEach((el: any, index: number) => {
                if (el.values.length && el.name !== 'static_categories') {
                  el.values.forEach((catEl: any) => {
                    data = [...data, ...catEl.values.map((el2: any) => {
                      let obj: any = {}
                      if (el2.attributes.is_active) {
                        let name = el2.attributes.name.split('_').map((el: any) => el.charAt(0).toUpperCase() + el.slice(1)).join(' ')
                        obj.name = name
                        obj.id = el2.attributes.id
                        obj.catFrom = index
                        obj.catType = 'Capital'
                        obj.transaction_category_type = el2.attributes.transaction_category_type
                        obj.cost_of_goods = el2.attributes.cost_of_goods
                      }
                      return obj
                    })]
                  })
                }
              })
              data = data.filter(el => el.name)
              data.unshift({ id: 0, name: 'Select account you would like to edit' })
              this.setState({ categories: data })
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else if (apiRequestCallId === this.updateCatergoyId) {
          this.responseModalFunction(responseJson)
        }
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  responseModalFunction = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ modalFlag: false })
    } else {
      this.setState({ showSnackbar: true, snackbarMessage: responseJson.errors })
    }
  }
  switchTab(tab: number) {
    this.setState({ activeTab: tab })
  }

  showErrorMsg = (msg: string, type: string) => {
    this.setState({ error: { type: type, message: msg } })
    setTimeout(() => this.setState({ error: {} }), 3000)
  }

  createCategory = async (from: any) => {
    if (!this.state.name) {
      this.setState({ snackbarMessage: "Name is required fields", showSnackbar: true })
      return 0
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const transactionType = from ? 'expense' : 'income'

    const attributes: any = {
      "name": this.state.name,
      "transaction_type": transactionType,
      "tier": "tier_three",
      "transaction_category_type": this.state.valueOfCat,
      "cost_of_goods": this.state.cog,
      "is_active": true,
      "company_id": await StorageProvider.get('company_id'),
    }
    attributes.company_id = parseInt(attributes.company_id)
    const data = { attributes }
    const body = { data }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.createCategoryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/transaction_categories/create_transaction_category`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateCategory = async (from: any) => {
    if (!this.state.name) {
      this.setState({ snackbarMessage: "Name is required fields", showSnackbar: true })
      return 0
    }
    if (!this.state.category) {
      this.setState({ snackbarMessage: "Select Category to update", showSnackbar: true })
      return 0
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const transactionType = from ? 'expense' : 'income'

    const attributes: any = {
      "id": this.state.category,
      "name": this.state.name,
      "transaction_type": transactionType,
      "transaction_category_type": this.state.valueOfCat,
      "cost_of_goods": this.state.cog,
      "is_active": true,
      "company_id": await StorageProvider.get('company_id'),
    }
    attributes.company_id = parseInt(attributes.company_id)
    const data = { attributes }
    const body = { data }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.updateCatergoyId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/transaction_categories/update_transaction_category`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategories = (from: any) => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allCategoriesId = requestMessage.messageId;
    const catOf = from ? 'expense' : 'income'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/transaction_categories?transaction_type=${catOf}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}

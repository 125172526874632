import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Box, Button } from "@material-ui/core";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import {makeStyles} from "@material-ui/core"


const mystyle = makeStyles({
  tboxtips:{
    fontSize:'20px',
    fontWeight: 700,
    "@media (max-width:1425px)":{
      fontSize: "15px"
    }
  },
  tboxComplete:{
    "@media (max-width:1425px)":{
      fontSize: "12px"
    }
  }
})

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "Drop Down Boxes Light Gray #F3F3F3",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 46,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
      justifyContent: 'space-between'
    },
    justifyContent: 'space-between'
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

//   const Circle = (theme) => ({
//     root: {
//         display:" flex",
//         width: "100px",
//         height: "100px",
//         backgroundColor: "orange",
//         borderRadius: "50%",
//     },
//   });

export default function CustomizedAccordions(treasureBoxData: any) {

  const classes = mystyle()
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const markNotificationViewed = (level: any, tipId: any, notificationID: any) => {

    let baseURL = `https://neeuhfinalbuildcard-125853-ruby.b125853.dev.eastus.az.svc.builder.cafe/bx_block_push_notifications/push_notifications/${notificationID}`

    let requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token')
      },
      body: JSON.stringify({
        "data": {
          "attributes": {
            "is_read": true
          }
        }
      })
    };
    // @ts-ignore
    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(data => {
        treasureBoxData?.updateData(level, tipId);
        console.log('data from put', data)
      }
      )
      .catch(e => console.log('eror form put ', e)
      );
  }

  return (
    <Box>
      <Accordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ backgroundColor: "#F3F3F3", fontFamily: "Basic Sans", }}
        >
          <Typography
          className={classes.tboxtips}
          style={{
            marginLeft: "5px",
            }}>
            {treasureBoxData?.treasureBoxData?.attributes?.name}
            <span>
              <Checkbox
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  height: 25,
                  width: 25
                }}
                inputProps={{ "aria-label": "secondary checkbox" }}
                checked={treasureBoxData?.treasureBoxData?.attributes?.status == "Completed"}
              />
            </span>
          </Typography>
          {/* {treasureBoxData?.treasureBoxData?.attributes?.status == "Completed" ? <Button */}
          {treasureBoxData?.treasureBoxData?.attributes?.notification?.data?.attributes?.is_read == false ?
            <Button
              onClick={() => {
                markNotificationViewed(treasureBoxData?.level, treasureBoxData?.treasureBoxData?.id, treasureBoxData?.treasureBoxData?.attributes?.notification?.data?.id);
              }}
            style={{
              marginTop: "8px",
              // marginLeft: "70px",
              backgroundColor: "#5597f4",
              borderRadius: "30px",
              color: "white",
              height: "30px",
              fontSize: "0.7rem",
              fontWeight: "bold",
              padding: "5px 20px",
            }}
          >
            Read Tip
          </Button>
            :
            <Button
              style={{
                marginTop: "8px",
              // marginLeft: "70px",
                backgroundColor: "#5597f4",
                borderRadius: "30px",
                color: "white",
                height: "30px",
                fontSize: "0.7rem",
                fontWeight: "bold",
                padding: "5px 20px",
              }}
            >
              {treasureBoxData?.treasureBoxData?.attributes?.status}
            </Button>
          }
        </AccordionSummary>
        <AccordionDetails
          style={{ backgroundColor: "#F3F3F3", display: "block", }}
        >
          <Typography className={classes.tboxtips}>{treasureBoxData?.treasureBoxData?.attributes?.name}</Typography>
          <Typography className={classes.tboxtips} style={{ textTransform: "uppercase" }}>
            {treasureBoxData?.treasureBoxData?.attributes?.status != "Completed" && treasureBoxData?.treasureBoxData?.attributes?.status}
          </Typography>
          <Typography style={{ marginTop: 20, marginBottom: 15 }} className={classes.tboxComplete}>
            {
              // treasureBoxData?.treasureBoxData?.attributes?.notification?.data?.attributes?.is_read == false ?
              // treasureBoxData?.treasureBoxData?.attributes?.read_tip
              // :
              treasureBoxData?.treasureBoxData?.attributes?.status == "Completed" ? treasureBoxData?.treasureBoxData?.attributes?.read_tip : `Complete all three tasks for ${treasureBoxData?.treasureBoxData?.attributes?.days_count} consecutive days to unlock this tip!`
            }
          </Typography>
          <div
            style={{
              display: "grid",
              rowGap: "8px",
              columnGap: "2px",
              gridTemplateColumns: "auto auto auto auto auto",
            }}
          >
            {treasureBoxData?.treasureBoxData?.attributes?.status != "Completed" && treasureBoxData?.treasureBoxData?.attributes?.no_of_days.map((day: any) => (
              <Typography
                style={{
                  display: " flex",
                  width: "42.82px",
                  height: "42.5px",
                  backgroundColor: day == 0 ? "rgba(0, 0, 0, 0)" : "#FF9933",
                  borderRadius: "50%",
                  marginLeft: "10px",
                  border: "1px solid #979797",
                  margin: "2px",
                }}
              />
            ))}
          </div>


          {/* <div>
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "#FF9933",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
          </div>
          <div>
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "#FF9933",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
          </div>
          <div>
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "#FF9933",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
          </div>
          <div>
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "#FF9933",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
          </div>
          <div>
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "#FF9933",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <p
              style={{
                display: " flex",
                width: "42.82px",
                height: "42.5px",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
          </div> */}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

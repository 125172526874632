export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const fullCalendar = require("../assets/fullCalendar.png");
export const backIcon = require("../assets/chevron_left.png");
export const carIcon = require("../assets/car.png");
export const closeIcon = require("../assets/close_btn.png");
export const calendarIcon = require("../assets/calendar.png");
export const arrowLeft = require("../assets/arrowLeft.png");
export const arrowRight = require("../assets/arrowRight.png");
export const infoIcon = require("../assets/image_Info.png");

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.companyDetail = "bx_block_company_settings/companies";
exports.globalSettingsEndPoint = "bx_block_global_settings/global_settings";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.plaidAPIEndPoint = "bx_block_apiintegrationwithplaid/plaids"
exports.exampleAPiMethod = "POST";
exports.postAPIMethod = "POST"
exports.saveCategoriesAPiMethod = "PUT";
exports.saveUserCreatedCategoriesAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.getCategories = "bx_block_transactions/transaction_categories";
exports.updateCategories = "bx_block_transactions/transaction_categories/update_category_transaction";
exports.companysettings = "bx_block_company_settings/companies"
exports.UpdateMileageEndPoint = "bx_block_company_settings/other_settings"
exports.taxSettingsApiEndpoint = "bx_block_taxes/tax_payment_settings";
exports.putApiMethod = "PUT";
exports.getApiMethodType = "GET"
exports.contentTypeForm = "multipart/form-data";
exports.userAccountEndPoint = "bx_block_company_settings/user_accounts";
exports.deleteUserAccountEndPoint="account_block/accounts/account_delete"
exports.userDataAccountWebEndPoint = "bx_block_company_settings/user_profiles";
exports.createEmailOtpEndPoint = "account_block/accounts/create_email_otp";
exports.otpVerificationApiEndPointWeb = "account_block/accounts/verify_change_email_otp";
exports.changeEmailWebEndPoint = "account_block/accounts/change_email";
exports.changePhoneWebEndPoint = "account_block/accounts/send_sms_otp";
exports.verifyPhoneWebEndPoint = "account_block/accounts/sms_confirmations";
exports.addPaymentCardWebEndPoint = "bx_block_card/card_details";
exports.getAllCardsWebEndPoint = "bx_block_card/card_details";
exports.verifyCardNumberWebEndPoint = "bx_block_card/card_details/card_verified";
exports.changePasswordWebEndApi = "bx_block_forgot_password/reset_passwords/change_password"
exports.btnExampleTitle = "CLICK ME";
exports.makePayment = "bx_block_subscriptionbilling/subscriptions/checkout_subscription";
exports.getAllPlansWebEndPoint = "bx_block_plan/plans";
exports.getUserSubscriptionWeb = "bx_block_subscriptionbilling/subscriptions";
exports.getBillingDetailsWebEndPoint = "bx_block_subscriptionbilling/subscriptions/get_billing_details";
exports.createCompany = "bx_block_company_settings/companies"
// Customizable Area End
// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputBase,
  Divider
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import moment from 'moment'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import VerifyAccountController from './VerifyAccountController.web';
import { lockWithCheckedblue } from "./assets";
import {ReactComponent as EnvelopeSvg} from '../assets/Envelope.svg';
import { CardPng } from "./assets";
const useStyles = (theme) => ({
  gridBox:{
    padding: "20px 0 20px 0",
    borderRadius:7,
    border:"1px solid #AEAEAE",
    margin:"5px 0px",
    height:82.67,
    width:526,
    margin:"20px 0"
  },
  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "21.75px",
  },
  },
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)': {
      fontSize: "13.5px",
  },
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  locksvg:{
    width:75,
    height:85,
    paddingBottom:10,
    '@media (max-width:1425px)':{
      width:55,
      height:65,
       }
  },
  verifyButton:{
    width:163,
    height:28,
    color:"white",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0",
    marginTop:20,
    "&:hover": {
      backgroundColor: "#5696F4",
  },
  '@media (max-width:1425px)':{
    width:120,
    height:28,
   
     }
  },
  padding:{
    paddingTop:80
  },
  link:{
    color:"#5696F4",
    fontSize:18,
    fontWeight:400,
    marginTop:10,
    '@media (max-width:1425px)': {
      fontSize: "13.5px",
  },
  },
  
})
class PasswordSuccessMessageWeb extends VerifyAccountController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" className={classes.padding}>

           <Grid item container lg={8} xs={8}  >
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                <img src ={lockWithCheckedblue} className={classes.locksvg}/>     
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                        Password change successful!
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment} >
                    <Typography className={classes.text}>
                   Sign in using your new password.
                    </Typography>
                </Grid>
              
                <Grid item container xs={12} lg={12} className={classes.alignment}>
                  <Button variant="contained" className={classes.verifyButton} component={Link}
                    to="/EmailAccountRegistration"
                    onClick={() => {
                      localStorage.removeItem("token"),
                        this.setState({ open: false });
                    }}
                     >
                   Sign In
                  </Button>

                </Grid>              
           </Grid>
        </Grid>
    );
  }
}

export default withStyles(useStyles)(PasswordSuccessMessageWeb)
// Customizable Area End
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import moment from 'moment'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import BillAndSubSettingController from "./BillAndSubSettingController.web";
import BillAndSubPaymentWeb from "./BillAndSubPayment.web";
import { ReactComponent as VisaSvg } from '../assets/Visa.svg'

const useStyles = (theme) => ({
  gridBox:{
    padding: "20px 0",
  },
  gridMiddleBox:{
    padding: "20px 0",
    borderTop: "2px solid #DADADA",
    borderBottom: "2px solid #DADADA",
  },
  gridTitle:{
    margin: "0 25px",
    fontWeight: "bold"
  },
  textField:{
    width: "240px",
    "& div":{
      borderRadius: "4px"
    },
    "& input":{
      padding: "10px",
    }
  },
  dataGrid:{
    padding: "5px 0"
  },
  formSelect:{
    width: "240px",
    "& div":{
      padding: "10px",
    }
  },
  circle:{
    marginLeft: "25px",
    marginRight:"10px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "1px solid #524593"
  },
  leftBorder:{
    borderLeft: "1px solid #000000"
  },
  cancelButton:{
    width: "90%",
    background: "#5597F4",
    borderRadius: "20px",
    color: "#ffffff"
  },
  learnButton:{
    marginTop: "20px",
    width: "60%",
    background: "#5597F4",
    borderRadius: "20px",
    color: "#ffffff"
  },
  planBox:{
    width: "80%",
    height: "250px",
    padding: '10px',
    border: "1px solid black",
    borderTop: "15px solid #EFF3EF",
    borderRadius: "10px"
  },
  planSubBox:{
    width: "80%",
    height: "250px",
    padding: '10px',
    border: "1px solid black",
    borderTop: "15px solid #5298F7",
    borderRadius: "10px"
  },
  boxStatus:{
    color: "#5298F7",
    fontWeight: "bold"
  },
  packName:{
    color: "#B3B7BA",
    fontWeight: "bold"
  },
  linkText:{
    color: "#5298F7"
  },
  pageLink:{
    textDecoration: "none",
  },
  logo:{
    border: "1px solid #000000"
  },
  boldFont:{
    fontWeight: "600"
  },
  svg:{
    padding: "5px 0"
  },
  blueText:{
    color: "#5298F7"
  }
})
class BillAndSubSettingWeb extends  BillAndSubSettingController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
      <Grid item container xs={12} justifyContent="center">
        <Grid item container xs={12} className={classes.gridBox}>
          <Grid item container xs={4}>
            <Typography variant="body2" className={classes.gridTitle}>
              Billing
            </Typography>
          </Grid>
          <Grid item container xs={8} justifyContent="center">
            <Grid item container className={classes.dataGrid} justifyContent="space-around" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body2">
                  Change account email
                </Typography>
              </Grid>
              <Grid item container xs={8} justifyContent="center">
                <TextField InputProps={{ disableUnderline: true }} className={classes.textField} variant="filled"/>
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-around" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body2">
                  Change Password
                </Typography>
              </Grid>
              <Grid item container xs={8} justifyContent="center">
                <TextField InputProps={{ disableUnderline: true }}  className={classes.textField} variant="filled"/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.gridMiddleBox}>
          <Grid item container xs={4}>
            <Typography variant="body2" className={classes.gridTitle}>
              Payment Method
            </Typography>
          </Grid>
          <Grid item container xs={4} justifyContent="center">
            <Grid item container  justifyContent="space-around" alignItems="center">
              <Grid item container xs={3} className={classes.logo} justifyContent="center" alignItems="center">
              <VisaSvg className={classes.svg}/>
              </Grid>
              <Grid item container xs={8} direction="column">
              <Typography variant="body2" className={classes.boldFont}>
                Bank Name
              </Typography>
              <Typography variant="body2" className={`${classes.blueText} ${classes.boldFont}`}>
                  Credit***0000
                </Typography>
              </Grid>
            </Grid> 
            <Grid item container  justifyContent="space-around" alignItems="center">
              <Grid item container xs={3} className={classes.logo} justifyContent="center" alignItems="center">
                <AddCircleOutlineOutlinedIcon className={classes.icon}/>
              </Grid>
              <Grid item container xs={8} direction="column">
              <Typography variant="body2" className={classes.boldFont}>
                Add Payment Method
              </Typography>
              </Grid>
            </Grid> 
          </Grid>
          <Grid item container xs={4} justifyContent="center" >
              <div onClick={()=>{navigation.navigate("billPaymentHistory")}} style={{textTransform:"none",cursor:"pointer"}}>
            <Typography variant="body1"className={classes.linkText} >View Payment History</Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.gridBox}>
          <Grid item container xs={12}>
            <Typography variant="body2" className={classes.gridTitle}>
              Subscription
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Grid item container className={classes.dataGrid} justifyContent="space-around" alignItems="center">
              <Grid item container xs={3} justifyContent="flex-start" alignItems="center">
                <Grid className={classes.circle}>
                </Grid>
                <Typography variant="body2">
                  Company Name
                </Typography>
              </Grid>
              <Grid item container xs={3} className={classes.leftBorder} justifyContent="center" alignItems="center">
                <Grid item container xs={8} >
                  <Typography variant="caption">Subscription plan</Typography>
                </Grid>
                <Grid item container xs={8} >
                  <Typography variant="body1" className={classes.blueText}>Basic Plan</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={3} className={classes.leftBorder} justifyContent="center" alignItems="center">
                <Grid item container xs={8} >
                  <Typography variant="caption">Renewal Date</Typography>
                </Grid>
                <Grid item container xs={8} >
                  <Typography variant="body1" className={classes.blueText}>{moment().format("MMMM D, YYYY")}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={3} className={classes.leftBorder} justifyContent="center" alignItems="center">
                <Button variant="contained" className={classes.cancelButton}>
                  CANCEL MEMBERSHIP
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="space-around" className={classes.gridBox}>
          <Grid item container xs={4} justifyContent="center" alignItems="center">
            <Grid item container className={classes.planSubBox}>
              <Grid item container xs={12}>
                <Typography variant="body2" className={classes.boxStatus}>
                  SUBSCRIBED
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body1" className={classes.packName}>
                  Bronze Plan
                </Typography>
              </Grid>
              <Grid item container xs={12} alignItems="flex-end">
                <Typography variant="h3">
                  $0.00
                </Typography>
                <Typography variant="h6">/mo</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body2">
                  +applicable taxes
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body2">
                  Next Charge on {moment().format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Link to="#" className={classes.pageLink}>
                  <Typography variant="body1" className={classes.linkText}>
                    Switch to annual billing?
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="center" alignItems="center">
            <Grid item container className={classes.planBox}>
              <Grid item container xs={12}>
                <Typography variant="body2" className={classes.boxStatus}>
                  UPGRADE
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body1" className={classes.packName}>
                  Silver Plan
                </Typography>
              </Grid>
              <Grid item container xs={12} alignItems="flex-end">
                <Typography variant="h3">
                  $0.00
                </Typography>
                <Typography variant="h6">/mo</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body2">
                  +applicable taxes
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end">
                <Button variant="contained" className={classes.learnButton}>
                  LEARN MORE
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="center" alignItems="center">
            <Grid item container className={classes.planBox}>
              <Grid item container xs={12}>
                <Typography variant="body2" className={classes.boxStatus}>
                  UPGRADE
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body1" className={classes.packName}>
                  Gold Plan
                </Typography>
              </Grid>
              <Grid item container xs={12} alignItems="flex-end">
                <Typography variant="h3">
                  $0.00
                </Typography>
                <Typography variant="h6">/mo</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body2">
                  +applicable taxes
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end">
                <Button variant="contained" className={classes.learnButton}>
                  LEARN MORE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(BillAndSubSettingWeb)


import React, { Component } from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  Pressable,
  FlatList,
  Dimensions,
  TouchableHighlight,
} from "react-native";
import { scaledSize } from 'framework/src/Utilities';
import Categoriesdetails from './Categoriesdetails';
import {
  Props,
  TransactionDataObjectType,
  ExpenseItemType
} from "../../blocks/CfGrabshowTransactionalInfo/src/CfGrabshowTransactionalInfoController";
import Carousel from './Carousel/Carousel';
import Button from './Button';
import SingleTransactionModal from './SingleTransactionModal';

type MyProps = {
  state: any
  onClickDownArrow: () => void;
  handleSingleTransactionModal: () => void;
  carousel: any;
  handleBackPress: () => void;
  handleEditSplit: () => void;
  handlePressAddDetails: () => void;
  handleCameraPress: () => void;
  deleteCameraImage: () => void;
  handleDeleteSelectedImage: () => void;
  deleteImage: () => void;
  closeModal: () => void;
  handleImageLibrary: () => void;
  handleEditDetails: () => void;
  handleSpiltTransaction: () => void;
  handlePressSplitTransaction: () => void;
  onPressAddToReport: () => void;
  handleDeleteImage: any;
  handleNoteChange: any;
  handleInputChange: any;
  setCategoryItem: any;
  check: any;
  rightArrow: any;
  downArrowIcon: any;
  roundCheckIcon: any;
  handleSnapToItem: any;
};

export default class CfGrabTransactionalInfoMainCompo extends Component<MyProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    let { state, check, downArrowIcon, rightArrow, handleSnapToItem, onPressAddToReport,handleEditDetails, handleNoteChange, handlePressSplitTransaction, handleInputChange, onClickDownArrow, handleSingleTransactionModal, setCategoryItem, roundCheckIcon, carousel, handleDeleteImage, handleEditSplit, handleImageLibrary, closeModal, deleteImage, handleDeleteSelectedImage, deleteCameraImage, handleCameraPress, handleSpiltTransaction, handleBackPress } = this.props;

    const _renderItem = ({ item, index }: { item: TransactionDataObjectType, index: number }) => {
      return (
        <View style={{ height: state.activeIndex !== index ? scaledSize(146) : scaledSize(165) }}>
          {state.activeIndex === index && state.selectedExpense.length === 1 && <Image source={roundCheckIcon} style={styles.roundCheckIcon} />}
          <TouchableHighlight style={[state.activeIndex !== index ? styles.inactiveTransactionItemContainer : styles.transactionItemContainer]}>
            <View style={state.activeIndex !== index ? styles.inactiveInnerTransactionItemContainer : styles.innerTransationItemContainer}>
              <Text style={state.activeIndex !== index ? styles.inactiveTransactionDateText : styles.transactionDateText} >{item?.date}</Text>
              <Text style={state.activeIndex !== index ? styles.inactiveTransactionTitleText : styles.transactionTitleText} testID="testName" numberOfLines={1}>{item?.name}</Text>
              <Text style={state.activeIndex !== index ? styles.inactiveTransactionPriceText : styles.transactionPriceText}>${item?.amount}</Text>
              <Text style={state.activeIndex !== index ? styles.inactiveTransactionCreditText : styles.transactionCreditText}>Credit Card</Text>
            </View>
          </TouchableHighlight>
        </View>
      );
    }
    const _renderCategoryItem = ({ item, index }: { item: ExpenseItemType, index: number }) => {
      return (
        <TouchableOpacity testID="testCategory" style={[styles.categoryItemContainer, item?.attributes?.isSelected === true && { borderColor: "#FF860D", borderWidth: 1 }]} onPress={() => setCategoryItem(item)}>
          <Text style={styles.categoryText}>{item?.attributes?.name}</Text>
          {item?.attributes?.isSelected === true && <Image testID="image" source={check} style={styles.checkIconStyle} />}
        </TouchableOpacity>
      )
    }
    const renderHalfView = () => {
      return (
        <>
          <View style={styles.carouselView}>
            <Carousel
              // testID="carousel"
              layout={"default"}
              ref={(ref: any) => carousel = ref}
              data={state.transactionData}
              sliderWidth={Dimensions.get('window').width}
              itemWidth={scaledSize(160)}
              renderItem={_renderItem}
              scrollEnabled={true}
              onScroll={() => { }}
              inactiveSlideOpacity={1}
              enableMomentum
              inactiveSlideScale={1}
              initialScrollIndex={state.activeIndex}
              enableSnap={true}
              onSnapToItem={(index: number) => handleSnapToItem(index)} />
            {state.activeIndex < state.transactionData.length - 1 && <TouchableOpacity style={styles.rightArrowIconView} onPress={() => carousel.snapToNext(true, true)} testID="testRightArrow">
              <Image source={rightArrow} style={styles.rightArrowIcon} />
            </TouchableOpacity>}
            {state.activeIndex !== 0 && <TouchableOpacity style={styles.leftArrowIconView} onPress={() => carousel.snapToPrev(true, true)} testID="testLeftArrow">
              <Image source={rightArrow} style={styles.leftArrowIcon} />
            </TouchableOpacity>}
          </View>
          <ScrollView>
            <FlatList
              testID="flateList"
              data={state.transactionArray}
              renderItem={_renderCategoryItem}
              keyExtractor={(item: any) => item.id}
              style={styles.categoryFlatListView}
              scrollEnabled={false}
            />
          </ScrollView>
          {!state.isAllItem && <TouchableOpacity style={{ alignItems: "center", marginTop: scaledSize(4) }} onPress={onClickDownArrow} testID="testDownArrow">
            <Image source={downArrowIcon} style={{ height: scaledSize(21), width: scaledSize(21) }} />
          </TouchableOpacity>}

          {state.selectedExpense.length <= 1 ? <View style={{ flexDirection: "row", justifyContent: 'space-between', marginHorizontal: scaledSize(30), marginTop: scaledSize(15), marginBottom: scaledSize(90) }}>
            {!state.isSave ?
              <Button
                theme={(state.selectedExpense.length > 0) ? "secondary" : "primary"}
                text={"ADD DETAILS"}
                disabled={(state.selectedExpense.length === 0)}
                textStyle={{ color: (state.selectedExpense.length > 0) ? "#3876F1" : "#ffffffff", fontSize: scaledSize(12), lineHeight: scaledSize(19) }}
                style={{
                  height: scaledSize(28),
                  width: scaledSize(140),
                  borderRadius: scaledSize(44),
                  paddingHorizontal: 0,
                  paddingVertical: 0,
                  ...(state.selectedExpense.length > 0) && {
                    borderColor: "#3876F1",
                    borderWidth: 1,
                    backgroundColor: "#ffffffff",
                  }
                }}
                onPress={handleSingleTransactionModal}
                testID="testAddDetails"
              />
              :
              <Button
                theme={"primary"}
                text={"EDIT DETAILS"}
                disabled={state.selectedExpense.length === 0}
                textStyle={{
                  color: "#3876F1",
                  fontSize: scaledSize(12),
                  lineHeight: scaledSize(19)
                }}
                style={{
                  height: scaledSize(28),
                  width: scaledSize(140),
                  borderRadius: scaledSize(44),
                  paddingHorizontal: 0,
                  paddingVertical: 0,
                  borderColor: "#3876F1",
                  borderWidth: 1,
                  backgroundColor: "#ffffffff",
                }}
                onPress={handleEditDetails}
                testID=""
              />
            }
            <Button
              theme={state.selectedExpense.length === 0 ? "primary" : "secondary"}
              text={"ADD TO REPORT"}
              disabled={state.selectedExpense.length === 0}
              textStyle={{
                color: "#ffffffff",
                fontSize: scaledSize(12),
                lineHeight: scaledSize(19)
              }}
              onPress={onPressAddToReport}
              style={{
                height: scaledSize(28),
                width: scaledSize(140),
                borderRadius: scaledSize(44),
                paddingHorizontal: 0,
                paddingVertical: 0
              }}
              testID="AddtoReports"
            />
          </View>
            : (
              !state.isEditSplit ?
                <View style={{ alignItems: 'center', marginHorizontal: scaledSize(30), marginTop: scaledSize(15), marginBottom: scaledSize(90) }}>
                  <Button
                    theme={"secondary"}
                    text={"SPLIT TRANSACTION"}
                    disabled={state.selectedExpense.length === 0}
                    textStyle={{
                      color: "#ffffffff",
                      fontSize: scaledSize(12),
                      lineHeight: scaledSize(19)
                    }}
                    style={{
                      height: scaledSize(28),
                      width: scaledSize(140),
                      borderRadius: scaledSize(44),
                      paddingHorizontal: 0,
                      paddingVertical: 0
                    }}
                    onPress={handlePressSplitTransaction}
                    testID="testSplitTransaction"
                  />
                </View> :
                <View style={{ flexDirection: "row", justifyContent: 'space-between', marginHorizontal: scaledSize(30), marginTop: scaledSize(15), marginBottom: scaledSize(90) }}>
                  <Button
                    theme={"primary"}
                    text={"EDIT SPLIT"}
                    disabled={state.selectedExpense.length === 0}
                    textStyle={{
                      color: "#3876F1",
                      fontSize: scaledSize(12),
                      lineHeight: scaledSize(19)
                    }}
                    style={{
                      height: scaledSize(28),
                      width: scaledSize(140),
                      borderRadius: scaledSize(44),
                      paddingHorizontal: 0,
                      paddingVertical: 0,
                      borderColor: "#3876F1",
                      borderWidth: 1,
                      backgroundColor: "#ffffffff",
                    }}
                    onPress={handleEditSplit}
                    testID=""
                  />
                  <Button
                    theme={"secondary"}
                    text={"ADD TO REPORT"}
                    disabled={state.selectedExpense.length === 0}
                    textStyle={{
                      color: "#ffffffff",
                      fontSize: scaledSize(12),
                      lineHeight: scaledSize(19)
                    }}
                    onPress={onPressAddToReport}
                    style={{
                      height: scaledSize(28),
                      width: scaledSize(140),
                      borderRadius: scaledSize(44),
                      paddingHorizontal: 0,
                      paddingVertical: 0
                    }}
                    testID="AddtoReports"
                  />
                </View>
            )
          }
        </>
      )
    }
    return (
      <>
        {state.isAddDetail ?
          <Categoriesdetails state={state}
            handleNoteChange={handleNoteChange}
            handleInputChange={handleInputChange}
            handleDeleteImage={handleDeleteImage}
            handleImageLibrary={handleImageLibrary}
            closeModal={closeModal}
            deleteImage={deleteImage}
            handleDeleteSelectedImage={handleDeleteSelectedImage}
            deleteCameraImage={deleteCameraImage}
            handleCameraPress={handleCameraPress}
            handlePressAddDetails={handlePressSplitTransaction}
            handleBackPress={handleBackPress}
            handleSpiltTransaction={handleSpiltTransaction}
            onPressAddToReport={onPressAddToReport}
          />
          :
          state.isAllItem ?<ScrollView>
            {renderHalfView()}
          </ScrollView> :
            <View style={styles.container}>
              {renderHalfView()}
            </View>
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  button: {
    top: 10,
    borderRadius: 20,
    padding: 10,
    width: 100,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  categoryFlatListView: {
    marginTop: scaledSize(15),
    // height: scaledSize(250)
  },
  categoryItemContainer: {
    width: scaledSize(284),
    minHeight: scaledSize(33),
    borderRadius: scaledSize(44),
    backgroundColor: "#FFF",
    shadowColor: "#000000",
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 20,
    alignSelf: "center",
    marginVertical: scaledSize(7),
    elevation: 7,
    alignItems: "center",
    flexDirection: "row"
  },
  categoryText: {
    fontSize: scaledSize(14),
    lineHeight: scaledSize(21),
    fontFamily: "OpenSans-Bold",
    color: "#0F172A",
    marginLeft: scaledSize(22),
    width: scaledSize(224)
  },
  checkIconStyle: {
    height: scaledSize(21),
    width: scaledSize(21),
  },
  carouselView: {
    marginTop: scaledSize(10)
  },
  rightArrowIcon: {
    height: scaledSize(23),
    width: scaledSize(23)
  },
  leftArrowIconView: {
    position: 'absolute',
    top: scaledSize(65),
    left: scaledSize(80),
    paddingLeft: 6
  },
  leftArrowIcon: {
    height: scaledSize(23),
    width: scaledSize(23),
    transform: [{ rotate: '180deg' }]
  },
  rightArrowIconView: {
    position: 'absolute',
    top: scaledSize(65),
    left: scaledSize(249),
  },
  transactionItemContainer: {
    height: scaledSize(140),
    width: scaledSize(140),
    borderRadius: scaledSize(70),
    backgroundColor: "#FF9933",
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: "center",
    opacity: 1,
    marginTop: scaledSize(13),
  },
  innerTransationItemContainer: {
    height: scaledSize(124),
    width: scaledSize(124),
    borderRadius: scaledSize(62),
    backgroundColor: "#FFFFFF",
    alignItems: "center",
  },
  inactiveTransactionItemContainer: {
    height: scaledSize(126),
    width: scaledSize(126),
    borderRadius: scaledSize(63),
    backgroundColor: "#FF9933",
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: "center",
    marginTop: scaledSize(17),
    opacity: 0.8,
  },
  inactiveInnerTransactionItemContainer: {
    height: scaledSize(112),
    width: scaledSize(112),
    borderRadius: scaledSize(56),
    backgroundColor: "#FFFFFF",
    alignItems: "center"
  },
  inactiveTransactionDateText: {
    fontSize: scaledSize(11),
    fontFamily: "OpenSans-Bold",
    lineHeight: scaledSize(20),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(98),
    marginTop: scaledSize(23.4)
  },
  inactiveTransactionTitleText: {
    fontSize: scaledSize(11),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(20),
    textAlign: 'center',
    color: "#3876F1",
    width: scaledSize(108),
    textTransform: "uppercase"
  },
  inactiveTransactionPriceText: {
    fontSize: scaledSize(13),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(20),
    textAlign: 'center',
    color: "#000",
    width: scaledSize(96),
    marginTop: scaledSize(5),
  },
  inactiveTransactionCreditText: {
    fontSize: scaledSize(7),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(12),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(81),
    textTransform: "uppercase"
  },
  transactionDateText: {
    fontSize: scaledSize(12),
    fontFamily: "OpenSans-Bold",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(109),
    marginTop: scaledSize(26)
  },
  transactionPriceText: {
    fontSize: scaledSize(15),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#000",
    width: scaledSize(107),
    marginTop: scaledSize(5),
  },
  transactionTitleText: {
    fontSize: scaledSize(12),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#3876F1",
    width: scaledSize(120),
    // marginTop: scaledSize(5),
    textTransform: "uppercase"
  },
  transactionCreditText: {
    fontSize: scaledSize(7),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(12),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(90),
    textTransform: "uppercase"
  },
  roundCheckIcon: {
    height: scaledSize(44),
    width: scaledSize(44),
    position: "absolute",
    alignSelf: "center",
    top: scaledSize(0),
    zIndex: 1000
  }
});

// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
// import firebase from 'firebase'
// import firebase from "./firebase";
import firebase from 'firebase/app';
import 'firebase/messaging';
import {
  initializeApp
} from "firebase/app";

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import NotificationProvider from '../../components/src/Context/NotificationProvider'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import TopNavPrivate from '../../components/src/TopNavPrivate'
import 'react-multi-email/style.css';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import RedirectPage from "../../blocks/email-account-registration/src/redirectPage.web";
import Archive from "../../blocks/Archive/src/Archive";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Videos4 from "../../blocks/Videos4/src/Videos4";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import ProductTutorial from "../../blocks/ProductTutorial/src/ProductTutorial";
import Customform from "../../blocks/customform/src/Customform";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Download from "../../blocks/Download/src/Download";
import Calendar from "../../blocks/Calendar/src/Calendar";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import CfFinanceLogic from "../../blocks/CfFinanceLogic/src/CfFinanceLogic";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import FingerprintPaymentAuthentication from "../../blocks/FingerprintPaymentAuthentication/src/FingerprintPaymentAuthentication";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import SavedCards from "../../blocks/SavedCards/src/SavedCards";
import Gamification from "../../blocks/Gamification/src/Gamification";
import Payments from "../../blocks/Payments/src/Payments";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import CfGrabshowTransactionalInfo from "../../blocks/CfGrabshowTransactionalInfo/src/CfGrabshowTransactionalInfo";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Videos from "../../blocks/videos/src/Videos";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
//Customizable Area Start
import ChangePassword from "../../blocks/forgot-password/src/changePassword.web";
import ConfirmationSignup from "../../blocks/forgot-password/src/confirmationSignup.web";
import EmailVerification from "../../blocks/email-account-registration/src/EmailVerification.web";
import SignupSubscriptionPlan from "../../blocks/email-account-registration/src/SignupSubscriptionPlan.web";
import InitialPayment from '../../blocks/forgot-password/src/InitialPayment.web';
import PaymentSuccess from '../../blocks/forgot-password/src/PaymentSuccess.web';
import PaymentFailure from '../../blocks/forgot-password/src/PaymentFailure.web';
//Customizable Area End




const routeMap = {
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  //Customizable Area Start
  ChangePassword: {
    component: ChangePassword,
    path: "/changepassword"
  },
  ConfirmationSignup: {
    component: ConfirmationSignup,
    path: "/confirmationsignup"
  },
  RedirectPage: {
    component: RedirectPage,
    path: "/account/accounts/email_confirmation"
  },
  EmailVerification: {
    component: EmailVerification,
    path: "/emailverification"
  },
  SignupSubscriptionPlan: {
    component: SignupSubscriptionPlan,
    path: "/signupsubscriptionplan"
  },
  InitialPayment:{
    component:InitialPayment,
    path:"/initialPayment"
  },
  PaymentSuccess:{
    component:PaymentSuccess,
    path:"/paymentSuccess",
  },
  PaymentFailure:{
    component:PaymentFailure,
    path:"/paymentFailure",
  },
  //Customizable Area End

  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  SubscriptionBilling: {
    component: SubscriptionBilling,
    path: "/SubscriptionBilling"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Archive: {
    component: Archive,
    path: "/Archive"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  LinkShare: {
    component: LinkShare,
    path: "/LinkShare"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Videos4: {
    component: Videos4,
    path: "/Videos4"
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: "/PeopleManagement2"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: "/DataImportexportcsv"
  },
  ProductTutorial: {
    component: ProductTutorial,
    path: "/ProductTutorial"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  Calendar: {
    component: Calendar,
    path: "/Calendar"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: "/EmailNotifications"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  PremiumPlanComparison: {
    component: PremiumPlanComparison,
    path: "/PremiumPlanComparison"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  CfFinanceLogic: {
    component: CfFinanceLogic,
    path: "/CfFinanceLogic"
  },
  ProjecttaskTracking2: {
    component: ProjecttaskTracking2,
    path: "/ProjecttaskTracking2"
  },
  FingerprintPaymentAuthentication: {
    component: FingerprintPaymentAuthentication,
    path: "/FingerprintPaymentAuthentication"
  },
  FileAttachment: {
    component: FileAttachment,
    path: "/FileAttachment"
  },
  SavedCards: {
    component: SavedCards,
    path: "/SavedCards"
  },
  Gamification: {
    component: Gamification,
    path: "/Gamification"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  UserGroups: {
    component: UserGroups,
    path: "/UserGroups"
  },
  CfGrabshowTransactionalInfo: {
    component: CfGrabshowTransactionalInfo,
    path: "/CfGrabshowTransactionalInfo"
  },

  Home: {
    component: EmailAccountRegistration,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

// old
// const firebaseConfig = {
//   apiKey: "AIzaSyBboCsR0pT6euiZbjxX-neyYIhhMkiB3Go",
//   authDomain: "neeuh-cc3aa.firebaseapp.com",
//   databaseURL: "https://neeuh-cc3aa-default-rtdb.firebaseio.com",
//   projectId: "neeuh-cc3aa",
//   storageBucket: "neeuh-cc3aa.appspot.com",
//   messagingSenderId: "415375754456",
//   appId: "1:415375754456:web:e7a12882bbb57a592dc3ad",
//   measurementId: "G-DM6D6163DJ"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAqqp3sKDAeAHdpVFKO7QpqcY3v9klV8Ts",
  authDomain: "neeuhfinalbuildcard-9eb42.firebaseapp.com",
  projectId: "neeuhfinalbuildcard-9eb42",
  storageBucket: "neeuhfinalbuildcard-9eb42.appspot.com",
  messagingSenderId: "465795012621",
  appId: "1:465795012621:web:d9c8ba50b78e25127f9d44",
  measurementId: "G-C8KBC1BML4"
};

// if (!firebase.apps.length) {
initializeApp(firebaseConfig);
// } else {
//   firebase.app();
// }

const messaging = firebase.messaging();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans'
    ].join(','),
  }
});

class App extends Component {

  componentDidMount() {
    Notification.requestPermission().then(permission => {
      if (permission !== 'granted') {
        if (
          !window.location.href.toLowerCase().includes('meeting') ||
          !window.location.href
            .toLowerCase()
            .includes('emailverificationlink')
        ) {
          console.log('Notification permission is required.');
        }
        Notification.requestPermission();
      }

      // console.log('permission', permission);
    });
    this.getNotificationMsg();
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return this.saveFireBase();
      })
      .catch(error => {
        console.log("error in getting notification permission ", error);
      })
  }


  saveFireBase = async () => {
    // let expTime = localStorage.getItem('expireTokenTime');
    // let isTokenValid = moment().isAfter(expTime);
    // let fireBaseToken = localStorage.getItem('fireBaseToken');
    // if (fireBaseToken == null || expTime == null || isTokenValid) {
    messaging.getToken({
      vapidKey: 'BCixj9jRTHMZQO_BRR7vL65UHPb_ptC24uVIBzV8A-xIr4gSyZnQS7YDM9wGvuhVTrpmLRvMWwq7RJy5eO3V6j0'
      // old
      // vapidKey: 'BF0_Ghnlb8ZxuKTSXT23Dxhx4_oienWNJsxYD4vRL0Y8pcGv_QVqPKJFveOlLiuIoFRdR-hqzTHc4Q1CU5OK-HQ'
    })
      .then(currentToken => {
        console.log('firebase token', currentToken);
        localStorage.setItem("firebaseToken", currentToken);
        if (currentToken) {
          console.log('current token for client --> ', currentToken);
          // if (currentToken !== fireBaseToken) {
          //   localStorage.setItem('fireBaseToken', currentToken);
          //   // if (localStorage.getItem('device_id')) {
          //   //   this.deleteFirebaseToken(localStorage.getItem('device_id'));
          //   // }
          //   // this.saveFirebaseToken(currentToken);
          // }
          //  else {
          //   localStorage.setItem(
          //     'expireTokenTime',
          //     moment().add(30, 'minutes')
          //   );
          // }
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
          // shows on the UI that permission is required
        }
        //  else {
        //   localStorage.setItem(
        //     'expireTokenTime',
        //     moment().add(30, 'minutes')
        //   );
        // }
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
    // }
  };


  getNotificationMsg = () =>
    new Promise(resolve => {
      messaging.onMessage(payload => {
        console.log('payload onMessage...................', payload);
        const notificationTitle = payload.data.title;
        const notificationOptions = {
          body: payload.data.message,
          data: payload.data
        };
        var notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        console.log(notification);
        // notification.show()
        // notification.addEventListener('click', event => {
        //   console.log('run');
        //   event.preventDefault(); // prevent the browser from focusing the Notification's tab
        //   // this.props.history.push(payload.data.app_url);
        // });
        resolve(payload);
      });
    });

  render() {

    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      <NotificationProvider>
        <ThemeProvider theme={theme}>
          <View style={{ height: '100vh', width: '100vw' }}>
            {/* <TopNavPrivate /> */}
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
          </View>
        </ThemeProvider>
      </NotificationProvider>
    );
  }
}

export default App;

// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  Divider,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import moment from 'moment'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Settings5Controller from './VerifyAccountController.web';
import { ReactComponent as EnvelopeSvg } from '../assets/Envelope.svg';
import { ReactComponent as ChevronRightSvg } from '../assets/Chevron-right.svg';
import VerifyPaymentInfoWeb from "./VerifyPaymentInfo.web";
import { lockWithChecked } from "./assets";
import { CardPng } from "./assets";
import CheckEamilViaCodeWeb from './CheckEmailViaCode.web'

const useStyles = (theme) => ({
  gridBox: {
    padding: "10px 0",
    borderRadius: 7,
    border: "1px solid #5696F4",
    margin: "5px 0px",
    width: 526,
    height: 82,
    '@media (max-width:1425px)': {
      width: 394.5,
      height: 66,
      padding: "10px 0",
      margin: "5px 0px",
    }
  },
  emailText: {
    fontSize: 29,
    fontWeight: 700,
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: 21.75
    }
  },
  text: {
    fontSize: 18,
    fontWeight: 400,
    color: "#3F4144",
    textAlign: "center",
    paddingBottom: 20,
    '@media (max-width:1425px)': {
      fontSize: 13.50
    }
  },
  alignment: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    display: "flex"
  },
  locksvg: {
    width: 65,
    height: 85,
    paddingBottom: 10,
    '@media (max-width:1425px)': {
      width: 56,
      height: 63,
    }

  },
  headerText: {
    fontSize: 18,
    fontWeight: 700,
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: 13.50
    }
  },

  desText: {
    fontSize: 16,
    fontWeight: 400,
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: 12
    }
  },
  envelopesvg: {
    width: 19,
    height: 12,
    '@media (max-width:1425px)': {
      width: 14.25,
      height: 9,
    }
  },
  arrowsvg: {
    width: 10,
    height: 19,
    '@media (max-width:1425px)': {
      width: 7.5,
      height: 14.25,
    }
  },
  padding: {
    paddingTop: 80
  }

})
class VerifyAccountWeb extends Settings5Controller {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <Grid item container xs={12} lg={12} md={12} sm={12} justifyContent="center" alignItems="center" className={classes.padding}>

        <Grid item container lg={8} xs={8} >
          <Grid item xs={12} lg={12} className={classes.alignment}>
            <img src={lockWithChecked} className={classes.locksvg} />
          </Grid>
          <Grid item xs={12} lg={12} className={classes.alignment}>
            <Typography className={classes.emailText}>
              First, let's verify your account.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} className={classes.alignment} >
            <Typography className={classes.text}>
              Before you make any changes, we'll need to make sure it's you.
            </Typography>
          </Grid>
          <Grid item container  xs={12} lg={12} justifyContent="center" alignItems="center" >
            <Grid item container  xs={8} lg={8}className={classes.gridBox}>
              <Grid item xs={2} lg={2} className={classes.alignment}>
                <EnvelopeSvg className={classes.envelopesvg} />
              </Grid>
              <Grid item xs={8} lg={8} >
                <Grid item xs={12} lg={12} >
                  <Typography className={classes.headerText}>
                    Email a code
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}  >
                  <Typography className={classes.desText}>
                    {this.state.userAccount.attributes?.email ? this.state.userAccount.attributes?.email : "noemail@gmail.com"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={2} lg={2} className={classes.alignment} >
              
                <Button onClick={this.sendEmailVerificationCode}>
                  <ChevronRightSvg className={classes.arrowsvg} />
                </Button>


              </Grid>

            </Grid>
          </Grid>
          <Grid item container  xs={12} lg={12} justifyContent="center" alignItems="center" >

              <Grid item container xs={8} lg={8} className={classes.gridBox}>

                <Grid item xs={2} lg={2} className={classes.alignment}>
                  <img src={CardPng} className={classes.envelopesvg} />
                </Grid>
                <Grid item xs={8} lg={8} >
                  <Grid item xs={12} lg={12} >
                    <Typography className={classes.headerText}>
                      Confirm Payment Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12}  >
                    <Typography className={classes.desText}>
                      VISA ending in  {this.state.cardDataById?.attributes?.card_number.slice(-4) ? this.state.cardDataById?.attributes?.card_number.slice(-4) : " 0000"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} lg={2} className={classes.alignment}>
                  <Link to="/Dashboard/companysettings/billVerifyPaymentInfo" component={VerifyPaymentInfoWeb}>
                    <ChevronRightSvg className={classes.arrowsvg} />
                  </Link>
                </Grid>

              </Grid>
          </Grid>

        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(VerifyAccountWeb)
// Customizable Area End
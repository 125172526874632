import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  formDate: any;
  formText: string;
  formMiles: any;
  formCar: any;
  months: any[];
  currentMonth: any;
  currentYear: any;
  activeMonth: any;
  selectedWeek: any;
  weekDays: any[];
  weekDate: any[];
  totalMileageDeduction: number;
  mileageDeductionMonths: any[];
  yearlyMiles: number;
  mileageData: any[];
  cars: any[];
  token: string;
  totalMonthlyMiles: any;
  openModal: boolean;
  addCarModal: boolean;
  deleteCarModal: boolean;
  deleteCarName: string;
  deleteCarNameId: string;
  newCarName: string;
  activeEdit: any;
  mileageId: any;
  errorMsg: string;
  showSnackbar: boolean;
  snackbarMessage: string;
  role: any;
  notAccountant: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class MileageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // arrayholder: any[];
  // passwordReg: RegExp;
  // emailReg: RegExp;
  createAccountApiCallId: any;
  getMileagesId: any;
  createMileageId: any;
  updateMileageId: any;
  getCarsId: any;
  createCarId: any;
  updateCarId: any;
  deleteCarId: any;
  loginApiCallId: any;
  validationApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  // labelHeader: any;
  // labelFirstName: string;
  // lastName: string;
  // labelEmail: string;
  // labelPassword: string;
  // labelRePassword: string;
  // labelLegalText: string;
  // labelLegalTermCondition: string;
  // labelLegalPrivacyPolicy: string;
  // btnTextSignUp: string;

  currentCountryCode: any;
  getGamificationDataApiId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      //@ts-ignore
      formDate: localStorage.getItem("mileageDate") ? new Date(localStorage.getItem("mileageDate")) : new Date(),
      formText: "",
      deleteCarName: '',
      deleteCarNameId: '',
      deleteCarModal: false,
      totalMonthlyMiles: 0,
      months: moment.monthsShort(),
      weekDays: moment.weekdays(),
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      selectedWeek: {
        sunday: moment().day(0),
        saturday: moment().day(6),
        currentCount: 0
      },
      weekDate: [],
      mileageId: '',
      activeMonth: new Date().getMonth(),
      totalMileageDeduction: 0,
      mileageDeductionMonths: [],
      yearlyMiles: 0,
      mileageData: [],
      cars: [],
      newCarName: '',
      activeEdit: null,
      addCarModal: false,
      formCar: "",
      formMiles: localStorage.getItem("miles") ? localStorage.getItem("miles") : "",
      token: "",
      openModal: false,
      errorMsg: '',
      showSnackbar: false,
      snackbarMessage: '',
      role: '',
      notAccountant: false,

      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    switch (apiRequestCallId) {
      case this.getMileagesId: {
        this.setState({ totalMileageDeduction: 0, mileageDeductionMonths: [], mileageData: [], yearlyMiles: 0, totalMonthlyMiles: 0 })
        if (responseJson.data.mileage_deduction.total_mileage_deduction) {
          this.setState({ totalMileageDeduction: responseJson.data.mileage_deduction.total_mileage_deduction.toFixed(2) })
        }
        if (responseJson.data.mileage_deduction.monthly_miles) {
          this.setState({ totalMonthlyMiles: responseJson.data.mileage_deduction.monthly_miles })
        }
        if (responseJson.data.mileage_deduction.mileage_deduction_month.length) {
          this.setState({ mileageDeductionMonths: responseJson.data.mileage_deduction.mileage_deduction_month })
        }
        if (responseJson.data.mileage_deduction.yearly_miles) {
          this.setState({ yearlyMiles: responseJson.data.mileage_deduction.yearly_miles })
        }
        if (responseJson.data.mileages.length) {
          this.setState({ mileageData: responseJson.data.mileages })
        }
        break;
      } case this.getCarsId: {
        let data: any[] = []
        responseJson.data?.map((el: any) => {
          data = data.concat({ id: el.id, name: el.attributes.name })
        })
        this.setState({ formCar: data[0]?.id })
        this.setState({ cars: data })
        break;
      } case this.createCarId: {
        this.allGetApis()
        this.setState({ newCarName: '' })
        this.handleCarModal()
        break;
      } case this.createMileageId: {
                this.setState({ formText: '', formMiles: '', formCar: '', openModal: false })
        this.getGamificationData()
        this.allGetApis()
        break;
      } case this.updateCarId: {
        this.setState({ activeEdit: null, newCarName: '' })
        this.allGetApis()
        break;
      } case this.updateMileageId: {
                this.setState({ formCar: 0, formMiles: '', formDate: new Date(), mileageId: '' })
        this.getGamificationData()
        this.allGetApis()
        break;
      } case this.deleteCarId: {
        this.setState({ activeEdit: null, newCarName: '' })
        this.handleDeleteModal()
        this.allGetApis()
        break;
      } case this.getGamificationDataApiId:
        break
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleModal() {
    this.setState({ openModal: !this.state.openModal, formDate: new Date(), formText: "", formMiles: "", formCar: 1, mileageId: "" })
  }
  handleCarModal() {
    this.setState({ addCarModal: !this.state.addCarModal })
  }
  handleDeleteModal() {
    this.setState({ deleteCarModal: !this.state.deleteCarModal, deleteCarName: '', deleteCarNameId: '' })
  }
  openEditModal(date: any, carId: any, data: any) {
    console.log(date, new Date())
    if (date > new Date()) {
      this.setState({ showSnackbar: true, snackbarMessage: 'Not allowed to update mileage for future date' })
    } else {
      if (data) {
        this.setState({ formDate: date, formText: data.business_purpose, formMiles: data.miles, formCar: carId, openModal: true, mileageId: data.id })
      } else {
        this.setState({ formDate: date, formCar: carId, openModal: true, })
      }
    }
  }

  getCurrentDate = (today: any = new Date()) => {
    // let today: any = new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };
  getGamificationData = () => {
    let p: any = moment().startOf('isoWeek')
    // let date: any = new Date(p)
    let date: any = new Date()
    const mondayOfTheWeek = this.getCurrentDate(date);
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGamificationDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gamificationEndPoint + "?date=" + mondayOfTheWeek
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  checkTheRole() {
    console.log("lottt", this.state.role)
    if (this.state.role == 'ACCOUNTANT') {
      this.setState({ notAccountant: true, showSnackbar: true, snackbarMessage: 'You are not allowed to add/edit in mileage.' })

    } else {
      this.setState({ notAccountant: false })
    }
  }
  updateFormDate(value: any) {
    localStorage.removeItem("mileageDate")
    this.setState({ formDate: value })
  }
  updateWeek = (num: number, year: any) => {
    const pre = num + 6;
    if (year && this.state.currentYear != year) {
      const date = year + '-12-31'
      this.setState({
        selectedWeek: {
          sunday: moment(date).day(num),
          saturday: moment(date).day(pre),
          currentCount: num
        }
      }, () => {
        this.allGetApis()
        this.updateActiveMonth(this.state.selectedWeek.monday)
        this.getWeekDays(num, date)
      })
    } else {
      this.setState({
        selectedWeek: {
          sunday: moment().day(num),
          saturday: moment().day(pre),
          currentCount: num
        }
      }, () => {
                this.allGetApis()
        this.updateActiveMonth(this.state.selectedWeek.saturday)
        this.getWeekDays(num, null)
      })

    }
  }
  getWeekDays = (num: number, date: any) => {
    let from = num;
    this.setState({ weekDate: [] })
    const weekDates = []
    const to = num + 6;
    while (from <= to) {
      const weekDay = date ? moment(date).day(from) : moment().day(from)
      weekDates.push(weekDay)
      from++
    }
    this.setState({ weekDate: [...weekDates] })
  }

  updateActiveMonth = (date: any) => {
    const month = new Date(date).getMonth()
    const year = new Date(date).getFullYear()

    if (year == this.state.currentYear) {
      this.setState({ activeMonth: month, currentMonth: new Date().getMonth() })
    } else {
      this.setState({ activeMonth: month, currentMonth: 11 })
    }
  }
  showErrorMsg = (msg: string) => {
    this.setState({ errorMsg: msg })
    setTimeout(() => this.setState({ errorMsg: '' }), 2000)
  }
  getMileageData = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMileagesId = requestMessage.messageId;
        requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mileageApiEndPoint}/mileages?start_date=${this.state.selectedWeek.sunday.format('DD/MM/YYYY')}&end_date=${this.state.selectedWeek.saturday.format('DD/MM/YYYY')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createMileageData = () => {
    if (!this.state.formMiles) {
      this.showErrorMsg("Miles can't be empty")
      return
    }
    const carData = this.state.mileageData.find((el: any) => el.car_id === parseInt(this.state.formCar))
    const updateMileage = carData.mileage_data && carData.mileage_data.length && carData.mileage_data.find((el: any) => el.date_of_mileage === moment(this.state.formDate).format('MM/DD/YYYY'))
    if (updateMileage) {
      this.setState({ mileageId: updateMileage.id }, () => this.updateMileageData())
      return
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createMileageId = requestMessage.messageId;

    const attrs = {
      date_of_mileage: moment(this.state.formDate).format('DD/MM/YYYY'),
      car_id: parseInt(this.state.formCar),
      miles: parseInt(this.state.formMiles),
      business_purpose: this.state.formText
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mileageApiEndPoint}/mileages`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCarsData = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCarsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mileageApiEndPoint}/cars`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addCar = () => {
    if (!this.state.newCarName) {
      this.showErrorMsg("Car name can't be empty")
      return false
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };

    const attrs = {
      name: this.state.newCarName
    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createCarId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mileageApiEndPoint}` + '/cars'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateMileageData = () => {
    if (!this.state.mileageId) {
      this.createMileageData()
      return false
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateMileageId = requestMessage.messageId;

    const attrs = {
      date_of_mileage: moment(this.state.formDate).format('DD/MM/YYYY'),
      car_id: parseInt(this.state.formCar),
      miles: parseInt(this.state.formMiles),
      business_purpose: this.state.formText || null
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mileageApiEndPoint}/mileages/${this.state.mileageId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    this.setState({ openModal: false })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateCarName = (id: any) => {
    if (!this.state.newCarName) {
      this.showErrorMsg("Car name can't be empty")
      return
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCarId = requestMessage.messageId;

    const attrs = {
      name: this.state.newCarName
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mileageApiEndPoint}/cars/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteCar = (id: any) => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCarId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mileageApiEndPoint}/cars/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formDeleteMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  allGetApis = () => {
        this.getCarsData()
    this.getMileageData()
  }

  // Customizable Area End
}

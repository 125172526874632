import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  staticCategories: any;
  companyId: any;
  isSaveDisabled: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
  txtInputValue: string;
  txtSavedValue: string;
  activeTab: number;
  addCategoryToStateId: number;
  enableField: boolean;
  // Customizable Area Start
  expensesAccounts: any,
  hiddenExpenseAccounts: any,
  hiddenIncomeAccounts: any,
  incomeAccounts: any,
  token: any,
  incomeTierOneData: any;
  incomeTierTwoData: any;
  incomeTierThreeData: any;
  expenseTierOneData: any;
  expenseTierTwoData: any;
  expenseTierThreeData: any;
  userCreatedCategories: any;
  shouldCallGetCategoryApi: any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoriesApiCallId: string;
  getCompanyDetailApiCallId: string;
  saveCategoriesApiCallId: string;
  saveUserCreatedCategoriesApiCallId: string;
  deleteCategoriesApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getCategoriesApiCallId = "";
    this.getCompanyDetailApiCallId = "";
    this.saveCategoriesApiCallId = "";
    this.saveUserCreatedCategoriesApiCallId = "";
    this.deleteCategoriesApiCallId = "";
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      staticCategories: [],
      companyId: 0,
      isSaveDisabled: false,
      showSnackbar: false,
      snackbarMessage: "",
      shouldCallGetCategoryApi: false,
      incomeTierOneData: [],
      incomeTierTwoData: [],
      incomeTierThreeData: [],
      expenseTierOneData: [],
      expenseTierTwoData: [],
      expenseTierThreeData: [],
      userCreatedCategories: [],
      token: "",
      txtInputValue: "",
      activeTab: 0,
      addCategoryToStateId: 1,
      hiddenExpenseAccounts: [],
      hiddenIncomeAccounts: [],
      expensesAccounts: [{
          name: "Capital"
      },{
          name: "Taxes"
      },{
        name: "Credit Card Payment"
    },{
        name: "Roth Retirement Contributions"
    },{
        name: "Office Expenses",
        tier: 1,
        view: true,
        newTier: 1,
    },{
        name: "Meals",
        tier: 1,
        view: true,
    },{
        name: "Contrat Labor",
        tier: 1,
        view: true,
    },{
        name: "Taxes and Licenses",
        tier: 2,
        view: true,
        newTier: 2,
    },{
        name: "Rent",
        tier: 2,
        view: true,
    },{
        name: "Repiars",
        tier: 2,
        view: true,
    },{
        name: "Advertising",
        tier: 2,
        view: true,
    },{
        name: "Travel",
        tier: 2,
        view: true,
    },{
        name: "Insurance",
        tier: 2,
        view: true,
    },{
        name: "Utilities",
        tier: 3,
        view: true,
        newTier: 3,
    },{
        name: "Legal & Professional",
        tier: 3,
        view: true,
    },{
        name: "Commission",
        tier: 3,
        view: true,
    },{
        name: "Retirement Contributions",
        tier: 3,
        view: true,
        }],
        incomeAccounts: [],
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    let token = localStorage.getItem('token');
    if (token) {
      const from = localStorage.getItem('from')
      this.setState({ token: token, activeTab: from !==null? parseInt(from): 0}, () => {
        this.getCategories();
        // this.getCompanyDetail();
        console.log(from, '-!!!!!')
        localStorage.removeItem('from')
      })
    } else {
      window.location.href = '/EmailAccountRegistration';
    }
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId == this.deleteCategoriesApiCallId) {
        if (responseJson?.message == "You can not delete this transaction category as you can delete categories created by you only") {
          console.log('delete api res ...', responseJson);
          this.showSnackBar(responseJson.message)
        } else {
          this.getCategories()
        }
      }

      if (apiRequestCallId == this.saveUserCreatedCategoriesApiCallId) {

        console.log('save usercat api called responseJson... ', responseJson);
        console.log('save usercat api called errorReponse... ', errorReponse);
        if (responseJson?.data) {
          this.setState({
            userCreatedCategories: []
          }, () => {
            if (this.state.shouldCallGetCategoryApi) {
              this.getCategories();
            }
          })
        } else if (responseJson?.status == 422) {
          setTimeout(() => {
            this.showSnackBar("You can not create two categories with same name.")
          }, 1000);
        }
        this.setState({ isSaveDisabled: false });
      }

      if (apiRequestCallId == this.saveCategoriesApiCallId) {
        // if (responseJson?.data?.invalid[0]?.message) {
        //   this.setState({
        //     snackbarMessage: 'error'
        //   }, () => {
        //     this.setState({
        //       showSnackbar: true
        //     })
        //   })
        // } else 
        if (responseJson?.data) {
          responseJson?.data?.forEach((element: any) => {
            if (element?.message) {
              this.showSnackBar(element?.message)
            }
          });
          this.getCategories();
        }
        console.log('save cat api called responseJson... ', responseJson);
        console.log('save cat api called errorReponse... ', errorReponse);
        this.setState({ isSaveDisabled: false });

      }

      if (apiRequestCallId == this.getCompanyDetailApiCallId) {
        this.setState({ companyId: responseJson?.data?.id })
      }

      if (apiRequestCallId == this.getCategoriesApiCallId) {
        console.log('getCategories RES ==== ', responseJson);
        console.log('getCategories ERR ==== ', errorReponse);
        if (this.state.activeTab == 0 && responseJson?.data?.length > 0) {

          responseJson.data = responseJson.data.filter((el: any) => el.name !== 'other_category')
          // this.setState({ incomeAccounts: responseJson.data[0]?.values });

          let incomeTierOneData: any[] = [];
          let incomeTierTwoData: any[] = [];
          let incomeTierThreeData: any[] = [];
          let incomeHiddenAccountsData: any[] = [];
          let staticCategories: any[] = [];

          responseJson.data.forEach((el: any, index: number) => {
            if (el.values.length && el.name == 'static_categories') {
              console.log('static categories... ', el);
              el.values.forEach((element: any) => {
                if (element?.attributes?.name) {
                  staticCategories.push(element?.attributes?.name)
                }
              });
            }
          });

          responseJson.data = responseJson.data.filter((el: any) => el.name !== 'static_categories')

          console.log('DATA...', responseJson.data);

          responseJson.data.forEach((item: any, index: number) => {
            item?.values.forEach((tierData: any) => {
              console.log('values .....', tierData?.values);
            tierData?.values.forEach((t: any) => {
              t.attributes.name = t.attributes.name //.split('_').join(" ")
              if (t?.attributes?.is_active) {
                if (tierData.tier == "tier_one") {
                  incomeTierOneData = [...incomeTierOneData, t];
                } else if (tierData.tier == "tier_two") {
                  incomeTierTwoData = [...incomeTierTwoData, t];
                } else if (tierData.tier == "tier_three") {
                  incomeTierThreeData = [...incomeTierThreeData, t];
                }
              } else if (t?.attributes?.is_active == false) {
                incomeHiddenAccountsData = [...incomeHiddenAccountsData, t];
              }
            })
          });
          });

          console.log('incomeHiddenAccountsData ...', incomeHiddenAccountsData);
          // responseJson.data[0]?.values.forEach((tierData: any) => {
          //   console.log('values .....', tierData?.values);
          //   tierData?.values.forEach((t: any) => {
          //     t.attributes.name = t.attributes.name //.split('_').join(" ")
          //     if (t?.attributes?.is_active) {
          //       if (tierData.tier == "tier_one") {
          //         incomeTierOneData = [...incomeTierOneData, t];
          //       } else if (tierData.tier == "tier_two") {
          //         incomeTierTwoData = [...incomeTierTwoData, t];
          //       } else if (tierData.tier == "tier_three") {
          //         incomeTierThreeData = [...incomeTierThreeData, t];
          //       }
          //     } else if (t?.attributes?.is_active == false) {
          //       incomeHiddenAccountsData = [...incomeHiddenAccountsData, t];
          //     }
          //   })
          // });

          // console.log('incomeHiddenAccountsData ...', incomeHiddenAccountsData);


          // responseJson.data[1]?.values.forEach((tierData: any) => {
          //   tierData?.values.forEach((t: any) => {
          //     console.log(t.attributes.name, '-------')
          //     console.log(t.attributes.name, '========')
          //     if (t?.attributes?.is_active) {
          //       if (tierData.tier == "tier_one") {
          //         incomeTierOneData = [...incomeTierOneData, t];
          //       } else if (tierData.tier == "tier_two") {
          //         incomeTierTwoData = [...incomeTierTwoData, t];
          //       } else if (tierData.tier == "tier_three") {
          //         incomeTierThreeData = [...incomeTierThreeData, t];
          //       }
          //     } else if (t?.attributes?.is_active == false) {
          //       incomeHiddenAccountsData = [...incomeHiddenAccountsData, t];
          //     }
          //   })
          // });

          this.setState({
            incomeTierOneData: incomeTierOneData,
            incomeTierTwoData: incomeTierTwoData,
            incomeTierThreeData: incomeTierThreeData,
            hiddenIncomeAccounts: incomeHiddenAccountsData,
            staticCategories: staticCategories
          }, () => {
            console.log('this.state. incomeTierOneData >>> ', this.state.incomeTierOneData);
            console.log('this.state. incomeTierTwoData >>> ', this.state.incomeTierTwoData);
            console.log('this.state. incomeTierThreeData >>> ', this.state.incomeTierThreeData);
            console.log('this.state. staticCategories >>> ', this.state.staticCategories);
          })

        } else if (this.state.activeTab == 1 && responseJson?.data?.length > 0) {

          responseJson.data = responseJson.data.filter((el: any) => el.name !== 'other_category')

          let expenseTierOneData: any[] = [];
          let expenseTierTwoData: any[] = [];
          let expenseTierThreeData: any[] = [];
          let hiddenExpenseAccounts: any[] = [];
          let staticCategories: any[] = [];

          responseJson.data.forEach((el: any, index: number) => {
            if (el.values.length && el.name == 'static_categories') {
              console.log('static categories... ', el);
              el.values.forEach((element: any) => {
                if (element?.attributes?.name) {
                  staticCategories.push(element?.attributes?.name)
                }
              });
            }
          });

          responseJson.data = responseJson.data.filter((el: any) => el.name !== 'static_categories')

          responseJson.data.forEach((item: any, index: number) => {
            item?.values.forEach((tierData: any) => {
              console.log('values .....', tierData?.values);
            tierData?.values.forEach((t: any) => {
              t.attributes.name = t.attributes.name //.split('_').join(" ")
              if (t?.attributes?.is_active) {
                if (tierData.tier == "tier_one") {
                  expenseTierOneData = [...expenseTierOneData, t];
                } else if (tierData.tier == "tier_two") {
                  expenseTierTwoData = [...expenseTierTwoData, t];
                } else if (tierData.tier == "tier_three") {
                  expenseTierThreeData = [...expenseTierThreeData, t];
                }
              } else if (t?.attributes?.is_active == false) {
                hiddenExpenseAccounts = [...hiddenExpenseAccounts, t];
              }
            })
          });
          });

          // responseJson.data[0]?.values.forEach((tierData: any) => {
          //   tierData?.values.forEach((t: any) => {
          //     t.attributes.name =  t.attributes.name.split('_').join(" ")
          //     if (t?.attributes?.is_active) {
          //       if (tierData.tier == "tier_one") {
          //         expenseTierOneData = [...expenseTierOneData, t];
          //       } else if (tierData.tier == "tier_two") {
          //         expenseTierTwoData = [...expenseTierTwoData, t];
          //       } else if (tierData.tier == "tier_three") {
          //         expenseTierThreeData = [...expenseTierThreeData, t];
          //       }
          //     } else if (t?.attributes?.is_active == false) {
          //       hiddenExpenseAccounts = [...hiddenExpenseAccounts, t];
          //     }
          //   })
          // });

          // responseJson.data[1]?.values.forEach((tierData: any) => {
          //   tierData?.values.forEach((t: any) => {
          //     t.attributes.name =  t.attributes.name.split('_').join(" ")
          //     if (t?.attributes?.is_active) {
          //       if (tierData.tier == "tier_one") {
          //         expenseTierOneData = [...expenseTierOneData, t];
          //       } else if (tierData.tier == "tier_two") {
          //         expenseTierTwoData = [...expenseTierTwoData, t];
          //       } else if (tierData.tier == "tier_three") {
          //         expenseTierThreeData = [...expenseTierThreeData, t];
          //       }
          //     } else if (t?.attributes?.is_active == false) {
          //       hiddenExpenseAccounts = [...hiddenExpenseAccounts, t];
          //     }
          //   })
          // });

          this.setState({
            expenseTierOneData: expenseTierOneData,
            expenseTierTwoData: expenseTierTwoData,
            expenseTierThreeData: expenseTierThreeData,
            hiddenExpenseAccounts: hiddenExpenseAccounts,
            staticCategories: staticCategories
          }, () => {
            console.log('this.state. expenseTierOneData >>> ', this.state.expenseTierOneData);
            console.log('this.state. expenseTierTwoData >>> ', this.state.expenseTierTwoData);
            console.log('this.state. expenseTierThreeData >>> ', this.state.expenseTierThreeData);
            console.log('this.state. expensestaticCategories >>> ', this.state.staticCategories);
          })

        }

      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }
  switchTab(tab: number){
    if (tab != this.state.activeTab) {
      this.setState({
        activeTab: tab,
        userCreatedCategories: []
      }, () => {
        this.getCategories();
      })
    }
}
  // Customizable Area Start



  getCompanyDetail = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanyDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategories = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategories}?transaction_type=${this.state.activeTab == 0 ? "income" : "expense"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showSnackBar = (message: string) => {
    this.setState({
      snackbarMessage: message
    }, () => {
      this.setState({
        showSnackbar: true
      })
    })
  }

  removeCategory = (category: any) => {
    console.log('cat ...', category);
    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    // if (category?.attributes?.role_name == "ADMIN") {
    //   this.showSnackBar('You can not delete this category')
    //   return;
    // }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategories}/${category?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveUserCreatedCategories = (token: any) => {
    let incomeRecordsAvailableInTierOne = 0;
    let incomeRecordsAvailableInTierTwo = 0;
    let expenseRecordsAvailableInTierOne = 0;
    let expenseRecordsAvailableInTierTwo = 0;

    let dataToBeUpdated: any[] = [];
    let isNameEmpty = false;
    let transaction_category_type = "user_created"

    let role = localStorage.getItem("role")
    if (role == "SUPER_ADMIN") {
      transaction_category_type = "schedule_c"
    }

    this.state.userCreatedCategories?.forEach((element: any) => {
      dataToBeUpdated = [...dataToBeUpdated, {
        "name": element.name,
        "is_active": element.is_active,
        "tier": element.tier,
        "transaction_type": element.transaction_type,
        "transaction_category_type": transaction_category_type

        // company_id: this.state.companyId
      }];
      if (!element.name) {
        isNameEmpty = true;
      }
      if (element?.is_active && element?.tier == "tier_one") {
        if (this.state.activeTab == 0) {
          incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
        } else {
          expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
        }
      } else if (element?.is_active && element?.tier == "tier_two") {
        if (this.state.activeTab == 0) {
          incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
        } else {
          expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
        }
      }
    });

    if (isNameEmpty) {
      this.showSnackBar("Account name is required.");
      this.setState({ isSaveDisabled: false });
      return;
    }

    if (this.state.activeTab == 0) {
      this.state.incomeTierOneData.forEach((element: any) => {
        if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
          incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
          incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
        }
      });

      this.state.incomeTierTwoData.forEach((element: any) => {
        if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
          incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
          incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
        }
      });

      if (incomeRecordsAvailableInTierOne > 3) {
        this.showSnackBar("You can not have more than 3 categories in Tier 1")
        this.setState({ isSaveDisabled: false });
        return;
      }
      if (incomeRecordsAvailableInTierTwo > 6) {
        this.showSnackBar("You can not have more than 6 categories in Tier 2")
        this.setState({ isSaveDisabled: false });
        return;
      }
    } else {

      this.state.expenseTierOneData.forEach((element: any) => {
        if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
          expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
          expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
        }
      });

      this.state.expenseTierTwoData.forEach((element: any) => {
        if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
          expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
          expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
        }
      });

      if (expenseRecordsAvailableInTierOne > 3) {
        this.showSnackBar("You can not have more than 3 categories in Tier 1")
        this.setState({ isSaveDisabled: false });
        return;
      }
      if (expenseRecordsAvailableInTierTwo > 6) {
        this.showSnackBar("You can not have more than 6 categories in Tier 2")
        this.setState({ isSaveDisabled: false });
        return;
      }
    }

    dataToBeUpdated.map((item) => { return item.name });
    let isDuplicate = dataToBeUpdated.some((item, idx) => {
      return dataToBeUpdated.indexOf(item) != idx
    });

    if (isDuplicate) {
      this.setState({ isSaveDisabled: false });
      return;
    }

    const data = {
      attributes: dataToBeUpdated,
    };

    const httpBody = {
      data: data,
    };

    console.log('reqBody for userCreated categories .... ', httpBody);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveUserCreatedCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategories}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveUserCreatedCategoriesAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  triggerValidationForHiddenCategories = (tier: any) => {
    if (this.state.activeTab == 0) {
      let incomeRecordsAvailableInTierOne = 0;
      let incomeRecordsAvailableInTierTwo = 0;

      this.state.incomeTierOneData.forEach((element: any) => {
        if (element?.attributes?.tier == "tier_one") {
          incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.tier == "tier_two") {
          incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
        }
      });

      this.state.incomeTierTwoData.forEach((element: any) => {
        if (element?.attributes?.tier == "tier_one") {
          incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.tier == "tier_two") {
          incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
        }
      });

      if (tier == "tier_one" && incomeRecordsAvailableInTierOne >= 3) {
        this.showSnackBar("You can not have more than 3 categories in Tier 1")
        return false;
      }
      if (tier == "tier_two" && incomeRecordsAvailableInTierTwo >= 6) {
        this.showSnackBar("You can not have more than 6 categories in Tier 2")
        return false;
      }
    } else {
      let expenseRecordsAvailableInTierOne = 0;
      let expenseRecordsAvailableInTierTwo = 0;

      this.state.expenseTierOneData.forEach((element: any) => {
        if (element?.attributes?.tier == "tier_one") {
          expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.tier == "tier_two") {
          expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
        }
      });

      this.state.expenseTierTwoData.forEach((element: any) => {
        if (element?.attributes?.tier == "tier_one") {
          expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
        } else if (element?.attributes?.tier == "tier_two") {
          expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
        }
      });

      if (tier == "tier_one" && expenseRecordsAvailableInTierOne >= 3) {
        this.showSnackBar("You can not have more than 3 categories in Tier 1")
        return false;
      }
      if (tier == "tier_two" && expenseRecordsAvailableInTierTwo >= 6) {
        this.showSnackBar("You can not have more than 6 categories in Tier 2")
        return false;
      }
    }
    return true;
  }

  SaveCategories = (hiddenItem: any) => {

    let token = localStorage.getItem('token');

    if (!token) {
      this.setState({ isSaveDisabled: false });
      return;
    }

    let dataToBeUpdated: any[] = []
    let incomeRecordsAvailableInTierOne = 0;
    let incomeRecordsAvailableInTierTwo = 0;
    let incomeRecordsAvailableInTierThree = 0;
    let expenseRecordsAvailableInTierOne = 0;
    let expenseRecordsAvailableInTierTwo = 0;
    let expenseRecordsAvailableInTierThree = 0;
    let transaction_category_type = "user_created"

    let role = localStorage.getItem("role")
    if (role == "SUPER_ADMIN") {
      transaction_category_type = "schedule_c"
    }

    if (this.state.activeTab == 0) {
      // income
      if (hiddenItem) {
        console.log('hiddenitem ... ', hiddenItem);

        dataToBeUpdated = [{
          "id": hiddenItem?.attributes?.id,
          "is_active": hiddenItem?.attributes?.is_active,
          "tier": hiddenItem?.attributes?.tier,
          "transaction_type": "income",
          "transaction_category_type": transaction_category_type
          // company_id: this.state.companyId
        }]
      } else {
        this.state.incomeTierOneData.forEach((element: any) => {
          if (element.isModified) {
            dataToBeUpdated = [...dataToBeUpdated, {
              "id": element?.attributes?.id,
              "is_active": element?.attributes?.is_active,
              "tier": element?.attributes?.tier,
              "transaction_type": "income",
              "transaction_category_type": transaction_category_type
              // company_id: this.state.companyId
            }]
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
            } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
            }
          } else {
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
            }
          }
        });
        this.state.incomeTierTwoData.forEach((element: any) => {
          if (element.isModified) {
            dataToBeUpdated = [...dataToBeUpdated, {
              "id": element?.attributes?.id,
              "is_active": element?.attributes?.is_active,
              "tier": element?.attributes?.tier,
              "transaction_type": "income",
              "transaction_category_type": transaction_category_type
              // company_id: this.state.companyId
            }]
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
            } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
            }
          } else {
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
            }
          }
        });
        this.state.incomeTierThreeData.forEach((element: any) => {
          if (element.isModified) {
            dataToBeUpdated = [...dataToBeUpdated, {
              "id": element?.attributes?.id,
              "is_active": element?.attributes?.is_active,
              "tier": element?.attributes?.tier,
              "transaction_type": "income",
              "transaction_category_type": transaction_category_type
              // company_id: this.state.companyId
            }]
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              incomeRecordsAvailableInTierOne = incomeRecordsAvailableInTierOne + 1
            } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              incomeRecordsAvailableInTierTwo = incomeRecordsAvailableInTierTwo + 1
            }
          }
          // else {
          //   if (element?.attributes?.is_active && element?.attributes?.tier == "tier_three") {
          //     incomeRecordsAvailableInTierThree = incomeRecordsAvailableInTierThree + 1
          //   }
          // }
        });
      }
    } else {
      // expense
      if (hiddenItem) {
        console.log('hiddenitem ... ', hiddenItem);

        dataToBeUpdated = [{
          "id": hiddenItem?.attributes?.id,
          "is_active": hiddenItem?.attributes?.is_active,
          "tier": hiddenItem?.attributes?.tier,
          "transaction_type": "expense",
          "transaction_category_type": transaction_category_type
          // company_id: this.state.companyId
        }]
      } else {
        this.state.expenseTierOneData.forEach((element: any) => {
          if (element.isModified) {
            dataToBeUpdated = [...dataToBeUpdated, {
              "id": element?.attributes?.id,
              "is_active": element?.attributes?.is_active,
              "tier": element?.attributes?.tier,
              "transaction_type": "expense",
              "transaction_category_type": transaction_category_type
              // company_id: this.state.companyId
            }]
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
            } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
            }
          } else {
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
            }
          }
        });
        this.state.expenseTierTwoData.forEach((element: any) => {
          if (element.isModified) {
            dataToBeUpdated = [...dataToBeUpdated, {
              "id": element?.attributes?.id,
              "is_active": element?.attributes?.is_active,
              "tier": element?.attributes?.tier,
              "transaction_type": "expense",
              "transaction_category_type": transaction_category_type
              // company_id: this.state.companyId
            }]
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
            } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
            }
          } else {
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
            }
          }
        });
        this.state.expenseTierThreeData.forEach((element: any) => {
          if (element.isModified) {
            dataToBeUpdated = [...dataToBeUpdated, {
              "id": element?.attributes?.id,
              "is_active": element?.attributes?.is_active,
              "tier": element?.attributes?.tier,
              "transaction_type": "expense",
              "transaction_category_type": transaction_category_type
              // company_id: this.state.companyId
            }]
            if (element?.attributes?.is_active && element?.attributes?.tier == "tier_one") {
              expenseRecordsAvailableInTierOne = expenseRecordsAvailableInTierOne + 1
            } else if (element?.attributes?.is_active && element?.attributes?.tier == "tier_two") {
              expenseRecordsAvailableInTierTwo = expenseRecordsAvailableInTierTwo + 1
            }
          }
          // else {
          //   if (element?.attributes?.is_active && element?.attributes?.tier == "tier_three") {
          //     expenseRecordsAvailableInTierThree = expenseRecordsAvailableInTierThree + 1
          //   }
          // }
        });
      }
    }

    if (dataToBeUpdated.length == 0) {
      console.log('data length is 0');
      if (this.state.userCreatedCategories?.length > 0) {
        this.setState({ shouldCallGetCategoryApi: true })
        this.saveUserCreatedCategories(token)
      }
      this.setState({ isSaveDisabled: false });
      return;
    }

    if (this.state.activeTab == 0) {
      if (incomeRecordsAvailableInTierOne > 3) {
        this.showSnackBar("You can not have more than 3 categories in Tier 1")
        this.setState({ isSaveDisabled: false });
        return;
      }
      if (incomeRecordsAvailableInTierTwo > 6) {
        this.showSnackBar("You can not have more than 6 categories in Tier 2")
        this.setState({ isSaveDisabled: false });
        return;
      }
    } else {
      if (expenseRecordsAvailableInTierOne > 3) {
        this.showSnackBar("You can not have more than 3 categories in Tier 1")
        this.setState({ isSaveDisabled: false });
        return;
      }
      if (expenseRecordsAvailableInTierTwo > 6) {
        this.showSnackBar("You can not have more than 6 categories in Tier 2")
        this.setState({ isSaveDisabled: false });
        return;
      }
    }

    if (this.state.userCreatedCategories?.length > 0) {
      this.setState({ shouldCallGetCategoryApi: false })
      this.saveUserCreatedCategories(token)
    }

    const data = {
      attributes: dataToBeUpdated,
    };

    const httpBody = {
      data: data,
    };

    console.log('reqBody for save categories .... ', httpBody);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCategories}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveCategoriesAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addCategoryToState = () => {

    let isEmpty = false;
    this.state.userCreatedCategories?.forEach((element: any) => {
      if (!element?.name) {
        isEmpty = true;
      }
    });

    if (isEmpty) {
      this.showSnackBar("Account name is required.")
      return;
    }

    let newCat = {
      id: this.state.addCategoryToStateId,
      "name": "",
      "is_active": true,
      "tier": "tier_three",
      "transaction_type": this.state.activeTab == 0 ? "income" : "expense",
      // company_id: this.state.companyId
    }

    let arr = [...this.state.userCreatedCategories, newCat]

    this.setState({
      userCreatedCategories: arr,
      addCategoryToStateId: this.state.addCategoryToStateId + 1
    })
  }
  // Customizable Area End
  
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import StorageProvider from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  token: any;

  userData: any[];

  errorStatus: boolean;
  errorMsg: any;
  successStatus: boolean;
  successMessage: any;
  deleteAccountOpen: boolean;

  userAccouuntMain: boolean;


  password: any;
  enteredPassword: any
  redirectToLogin: any;








  performDelete: boolean


  // Customizable Area End


}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DeleteAccountController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start

  getAllUserId: any;






  deleteAccountId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.deleteAccountId = "";

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',

      userData: [],

      errorMsg: '',
      errorStatus: true,
      successMessage: '',
      successStatus: false,

      userAccouuntMain: false,

      password: "",
      enteredPassword: "",
      deleteAccountOpen: false,



      performDelete: false,
      redirectToLogin: false


      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({ token: await StorageProvider.get('token') })



  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);



    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );



      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.deleteAccountId) {

          console.log("Response Json", responseJson)
          if (!responseJson.error) {

            if (responseJson.message) {
              console.log("this", this)
              this.deleteAccount()

              this.setState({ successMessage: responseJson?.message, successStatus: true })


            }
          } else {

            this.setState({ errorMsg: responseJson?.error, errorStatus: true })
          }
        }

      }
    }
    // Customizable Area End

  }
  // Customizable Area Start



  setInputPasswordValue = (text: string) => {
    this.setState({ password: text });
  }

  setDeleteUserOpen = () => {
    this.setState({ deleteAccountOpen: true })
  }

  setDeleteUserClose = () => {
    this.setState({ deleteAccountOpen: false })
  }

  deleteAccount = () => {



    if (!this.state.token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }
    const httpBody = {
      password: this.state.password
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteUserAccountEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  // Customizable Area End

}

// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputLabel,
  InputBase,
  Divider,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import VerifyAccountController from './VerifyAccountController.web';
import { AmericanExpress, blank_Card, dinersCard, discoverCard, jcbCard, lockWithChecked, maestroCard, unionCard } from "./assets";
import {ReactComponent as PhoneSvg} from '../assets/phone.svg';
import { CardPng } from "./assets";
import { MasterCard } from "./assets";
import { ReactComponent as VisaSvg } from '../assets/Visa.svg';
import {ReactComponent as ChevronLeftSvg} from '../assets/chevron-left.svg';
import BillAndSubSettingWeb from "./BillAndSubSetting.web";
import UpdatePaymentDetailsWeb from "./UpdatePaymentDetails.web";
import AddPaymentDetailsWeb from "./AddPaymentDetails.web";



const useStyles = (theme) => ({

  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    margin:"10px 0px",
    '@media (max-width:1425px)':{
      fontSize:21.75
        }
  },
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  headerText:{
    fontSize:25,
    fontWeight:500,
    color:"#3F4144",
    justifyContent:"center",
    alignItems:"center",
    '@media (max-width:1425px)':{
      fontSize:18.75
        }
  },
  paddingLeft:{
    padding:"20px 10px 20px 0px" 
  },
  desText:{
    fontSize:16,
    fontWeight:400,
    color:"#AEAEAE",
    '@media (max-width:1425px)':{
      fontSize:12
        }
  },
  logo:{
    width:"72.6px",
    height:"48px",
    '@media (max-width:1425px)':{
      width:"52.6px",
      height:"38px",
        }
  },
  border:{
    
    borderBottom:"1px solid #979797",
    
  },
  verifyButton:{
    width:163,
    height:28,
    color:"white",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0",
    '@media (max-width:1425px)':{
      width:120,
    height:28,
        }
  },
  padding:{
    paddingTop:80
  },
  link:{
    color:"#5696F4",
    fontSize:18,
    fontWeight:400,
    marginTop:10,
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
  formBtn: {
    borderRadius: "30px",
    width: 124,
    height:28,
    
},
formBtnBlue:{
    margin:"20px 0px",
    backgroundColor: "#5597F4",
    borderRadius: "30px",
    width:244,
    "&:hover": {
        backgroundColor: "#5597F4",
    },
    "& p": {
        fontWeight: "bold",
        color: "#ffffff"
    },
    '@media (max-width:1425px)':{
      width:183
        }
},
formBtnFont: {
    color: "#3F4144",
    fontSize: "13px",
    fontWeight: 700,
    '@media (max-width:1425px)': {
        fontSize: "9.75px",
    },
},
  
})


class ManagePaymentInfoWeb extends VerifyAccountController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes, history} = this.props
    return (
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" >
            <Grid item container xs={12} lg={12} md={12}sm={12}>

                <Grid item xs={2} lg={1} md={2}sm={2} className={classes.alignment}>
                <Link to="/Dashboard/companysettings/billPaymentHistory" component={BillAndSubSettingWeb}style={{textDecoration:"none", }}>

                    <ChevronLeftSvg  style={{marginTop:10,width:10, height:10}}/>
                    </Link>
                </Grid>
                <Grid item xs={8} lg={8} md={8}sm={8}>
                    <Link to="/Dashboard/companysettings/billPaymentHistory" component={BillAndSubSettingWeb}style={{textDecoration:"none", }}>
                        <Typography className={classes.link}>
                        Billing & Subscription
                        </Typography>
                    </Link>

                </Grid>

            </Grid>
           <Grid item container lg={11} xs={12} className={classes.padding} >
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                <img src={CardPng} className={classes.logo} />  
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                  Manage Payment Information
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment} >
                        <Typography className={classes.text} >
                            Edit your payment details, add a backup, or switch your preffered payment method.
                        </Typography>  
                </Grid>
                <Grid item container lg={12} md={12} xs={12} sm={12} style={{margin:"30px 0px  0px 0px",borderTop:"1px solid #979797"}}>
                        {this.state.cardData.map((item:any,i) =>
                        <Grid item container lg={12} md={12} xs={12} sm={12} key={i} className={classes.border}>
                          <Grid item container lg={6} md={6} xs={8} sm={7} className={classes.paddingLeft}>
                            <Grid item container lg={12} md={12} xs={12} sm={12} >
                              <Grid item xs={12} lg={3} md={3} sm={3}>
                                {item.attributes.card_type=="masterCard"?
                                <img src={MasterCard} className={classes.logo}/>:
                                item.attributes.card_type=="discover"?
                                <img src={discoverCard} className={classes.logo}/>:
                                 item.attributes.card_type=="americanExpress"?
                                 <img src={AmericanExpress} className={classes.logo}/>:
                                 item.attributes.card_type=="visa"?
                                 <VisaSvg className={classes.logo}/>:
                                 item.attributes.card_type=="diners"?
                                 <img src={dinersCard} className={classes.logo}/>:
                                 item.attributes.card_type=="maestro"?
                                 <img src={maestroCard} className={classes.logo}/>:
                                 item.attributes.card_type=="unionPay"?
                                 <img src={unionCard} className={classes.logo}/>:
                                 item.attributes.card_type=="jcb"?
                                 <img src={jcbCard} className={classes.logo}/>: <img src={blank_Card} className={classes.logo}/>
                                 }
                              </Grid>
                              <Grid item xs={12} lg={9} md={9} sm={9}>
                                <Typography className={classes.headerText}>
                                  {item.attributes.card_number?item.attributes.card_number:"**** **** **** 0000"}
                                </Typography>
                              </Grid>

                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                            {this.state.cardData.length==1?
                              <Typography className={classes.desText}>
                                To remove, first add another payment method.
                              </Typography>:""}
                            </Grid>
                          </Grid>
                          <Grid item container lg={6} md={6} xs={4} sm={5} style={{ textAlign: "right", alignItems: "right", justifyContent: "right" }}>
                              <Grid item xs={12} lg={6} md={6} sm={6} className={classes.alignment} style={{ textAlign: "right", alignItems: "right", justifyContent: "right" }}>

                                {this.state.cardData.length==1?
                                  <Button
                                  variant="outlined"
                                  className={classes.formBtn}
                                  style={{ width: 169, }}
                                >
                                <Typography variant="body2"  className={classes.formBtnFont}>Preferred</Typography>
                                 
                                </Button>:

                                <Button
                                variant="outlined"
                                className={classes.formBtn}
                                style={{ width: 169, }}
                                  onClick={()=>this.handleDeleteCard(item.id)}
                                >
                               <Typography variant="body2"  className={classes.formBtnFont}>Remove</Typography>

                                </Button>
                                    }
                              </Grid>
                              <Grid item xs={12} lg={2} md={2} sm={3} className={classes.alignment} >
                                 <Button variant="text" onClick={() => {
                                  localStorage.setItem('cardDetails',JSON.stringify({test:{"id":item.id}}))
                                  navigation.navigate("UpdatePaymentDetails")
                                 }} className={classes.link} >
                                  Edit
                                </Button>

                              </Grid>

                            </Grid>
                        </Grid>
                        )}
                            
                </Grid>
                <Grid item xs={12} lg={12}md={12} sm={12} className={classes.alignment}>
                            <Button
                                variant="contained"
                                className={classes.formBtnBlue}
                              onClick={()=>{navigation.navigate("AddPaymentDetails")}}
                            >
                                <Typography variant="body2" className={classes.formBtnFont}>+ Add payment method</Typography>
                            </Button>
                        </Grid>
              
           </Grid>
           <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        </Grid>
    );
  }
}

export default withStyles(useStyles)(ManagePaymentInfoWeb)
// Customizable Area End
//@ts-nocheck
import React from "react";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Select,
  MenuItem,
  Box,
  Switch,
  TextField,
  Snackbar,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import TransactionSettingController from './TransactionSettingController';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { Link } from "react-router-dom";

const useStyles = (theme) => ({
  tabButton: {
    borderRadius: "20px",
    border: "1px solid #ffffff"
  },
  activeTabButton: {
    border: "1px solid #FFBD7B"
  },
  newTabButton: {
    border: "1px solid #ffffff"
  },
  newActiveTabButton: {
    borderBottom: "2px solid #AEAEAE"
    // border: "1px solid #FFBD7B"
    // borderBottom: "#5696F4"
  },
  saveButton: {
    width: "80%",
    background: "#5696F4",
    border: "1px solid #5696F4",
    "& h6": {
      color: "#ffffff"
    },
    "&:hover": {
      background: "#5696F4"
    }
  },
  incomeButton: {
    // width: "80%",
    padding: "3 50",
    '@media (max-width:1425px)': {
      padding: "2.25 37.5"
    },
    background: "#5696F4",
    // border: "1px solid #5696F4",
    "& h6": {
      color: "#ffffff"
    },
    "&:hover": {
      background: "#5696F4"
    },

  },
  // saveButton:{
  //   width: "80%",
  //   background: "#15066A",
  //   border: "1px solid #15066A",
  //   "& h6":{
  //     color: "#ffffff"
  //   },
  //   "&:hover":{
  //     background: "#15066A"
  //   }
  // },
  buttons: {
    height: "50px"
  },
  boldFont: {
    fontSize: '13px',
    fontWeight: 'bold',
    '@media (max-width:1425px)': {
      fontSize: '9.75px',
      fontWeight: 'bold',
    },
  },
  subtitle2: {
    // fontWeight: "200",
    fontSize: '15px' ,
    paddingLeft: '10px',
    '@media (max-width:1425px)': {
      fontSize: '11.25px',

    },
  },
  topDiv: {
    margin: "10px 0"
  },
  whiteFont: {
    color: "#ffffff"
  },
  iconHelp: {
    marginLeft: "5px",
    fill: "#3F4144",
    width: "20px",
  },
  iconRemove: {
    width: "20px",
    fill: "#E81919",
    marginRight: "5px"
  },
  tableC1: {
    paddingLeft: "25px",
    textTransform: 'capitalize'
  },
  newTableC1: {
    paddingLeft: "10px",
    textTransform: 'capitalize',
    alignItems: 'center',
  },
  tableC2: {
    borderLeft: "1px solid #DADADA",
    borderRight: "1px solid #DADADA"
  },
  tableRow: {
    border: "1px solid #DADADA"
  },
  removeTableRowBorder: {
    marginTop: "25px"
  },
  blueText: {
    color: "#5696F4",
    fontWeight: "600"
  },
  selectTier: {
    color: "#5696F4",
    fontSize: "20px",
    fontWeight: "700",
    width: "100%",
    "& div": {
      paddingLeft: "5px"
    },
    "&::before": {
      borderBottom: "none"
    },
    '@media (max-width:1425px)': {
      fontSize: '15px',
    },
  },
  viewSwitch: {
    width: "55px",
    height: "24px",
    padding: "0px"
  },
  switchBase: {
    color: "#818181",
    padding: "1px",
    width: "30px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "orange"
      }
    }
  },
  thumb: {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px"
  },
  track: {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "10px",
      position: "absolute",
      top: "6px"
    },
    "&:after": {
      content: "'YES'",
      left: "6px"
    },
    "&:before": {
      content: "'NO'",
      right: "6px"
    }
  },
  checked: {
    color: "#FF9933 !important",
    transform: "translateX(26px) !important"
  },
  boldText: {
    fontSize: "20px",
    fontWeight: "700",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  boldTextWithColor: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#5696F4",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
})

class TransactionSettingWeb extends TransactionSettingController {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  getHiddenCategoriesView = (classes, state) => {
    return (
      this.state[state].map(el => (
        <Grid item container xs={12} className={classes.tableRow} key={el?.attributes?.name + el?.id}>
          <Grid item container alignItems="center" xs={8} className={classes.tableC1}>
            {/* <Button onClick={() => {
              this.removeCategory(el);
            }}> */}
            <RemoveCircleRoundedIcon className={classes.iconRemove}
              onClick={() => {
                this.removeCategory(el);
              }}
            />
            {/* </Button> */}
            <Typography display="inline" variant="subtitle2" className={classes.boldTextWithColor}>{el?.attributes?.name}</Typography>
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>
            {el?.attributes?.tier && <Select value={el?.attributes?.tier} className={classes.selectTier}
              onChange={(event) => {
                console.log(event.target.value);

                let arr = this.state[state].map(item => {
                  if (item?.id == el?.id) {

                    let isValid = this.triggerValidationForHiddenCategories(event.target.value)
                    if (!isValid) {
                      return item;
                    }

                    let attr = Object.assign({}, item?.attributes, {
                      tier: event.target.value,
                    });

                    this.SaveCategories(Object.assign({}, item, {
                      attributes: attr,
                      isModified: true,
                    }))

                    return Object.assign({}, item, {
                      attributes: attr,
                      isModified: true,
                    })
                  }
                  return item
                });

                this.setState({ [state]: arr })
                console.log('arr', arr);


              }}
            >
              <MenuItem value={"tier_one"}>Tier 1</MenuItem>
              <MenuItem value={"tier_two"}>Tier 2</MenuItem>
              <MenuItem value={"tier_three"}>Tier 3</MenuItem>
            </Select>}
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>
            {el?.attributes?.is_active !== undefined && <Switch
              color="secondary"
              classes={{
                root: classes.viewSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
              }}
              checked={el?.attributes?.is_active}
              onChange={(event) => {
                console.log(event.target.checked);


                let arr = this.state[state].map(item => {
                  if (item?.id == el?.id) {

                    let isValid = this.triggerValidationForHiddenCategories(item?.attributes?.tier)
                    if (!isValid) {
                      return item;
                    }

                    let attr = Object.assign({}, item?.attributes, {
                      is_active: event.target.checked,
                    });

                    this.SaveCategories(Object.assign({}, item, {
                      isModified: true,
                      attributes: attr
                    }))

                    return Object.assign({}, item, {
                      isModified: true,
                      attributes: attr
                    })
                  }
                  return item
                });

                this.setState({ [state]: arr })
                console.log('arr... ', arr);


              }}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />}
          </Grid>
        </Grid>
      ))
    )
  }

  getDataBasedOnTier = (number, TIER, classes) => {
    return (
      <>
        <Grid item container xs={12} className={classes.removeTableRowBorder}>
          <Grid item container alignItems="center" xs={8} className={classes.tableC1}>
            <Typography display="inline" variant="subtitle2" className={classes.boldText}>Tier {number}</Typography>
          </Grid>
        </Grid>

        {this.state[TIER].map(el => (
          <Grid item container xs={12} className={classes.tableRow} key={el?.attributes?.name + el?.id}>
            <Grid item container alignItems="center" xs={8} className={classes.tableC1}>
              <Typography display="inline" variant="subtitle2" className={classes.boldTextWithColor}>{el?.attributes?.name}</Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>
              {el?.attributes?.tier && <Select value={el?.attributes?.tier} className={classes.selectTier}
                onChange={(event) => {
                  console.log(event.target.value);

                  let arr = this.state[TIER].map(item => {
                    if (item?.id == el?.id) {
                      let attr = Object.assign({}, item?.attributes, {
                        tier: event.target.value,
                      });
                      return Object.assign({}, item, {
                        attributes: attr,
                        isModified: true,
                      })
                    }
                    return item
                  });

                  this.setState({ [TIER]: arr })
                  console.log('arr', arr);


                }}
              >
                <MenuItem value={"tier_one"}>Tier 1</MenuItem>
                <MenuItem value={"tier_two"}>Tier 2</MenuItem>
                <MenuItem value={"tier_three"}>Tier 3</MenuItem>
              </Select>}
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>
              {el?.attributes?.is_active !== undefined && <Switch
                classes={{
                  root: classes.viewSwitch,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked
                }}
                checked={el?.attributes?.is_active}
                onChange={(event) => {
                  console.log(event.target.checked);


                  let arr = this.state[TIER].map(item => {
                    if (item?.id == el?.id) {
                      let attr = Object.assign({}, item?.attributes, {
                        is_active: event.target.checked,
                      });
                      return Object.assign({}, item, {
                        isModified: true,
                        attributes: attr
                      })
                    }
                    return item
                  });

                  this.setState({ [TIER]: arr })
                  console.log('arr... ', arr);


                }}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />}
            </Grid>
          </Grid>
        ))}
      </>
    )
  }

  getIncomeViewForHiddenCategories = (classes: any) => {
    return this.getHiddenCategoriesView(classes, "hiddenIncomeAccounts")
  }

  getExpenseViewForHiddenCategories = (classes: any) => {
    return this.getHiddenCategoriesView(classes, "hiddenExpenseAccounts")
  }

  getIncomeView = (classes: any) => {
    return (
      <>
        {this.getDataBasedOnTier(1, "incomeTierOneData", classes)}
        {this.getDataBasedOnTier(2, "incomeTierTwoData", classes)}
        {this.getDataBasedOnTier(3, "incomeTierThreeData", classes)}
      </>
    )
  }

  getExpenseView = (classes: any) => {
    return (
      <>
        {this.getDataBasedOnTier(1, "expenseTierOneData", classes)}
        {this.getDataBasedOnTier(2, "expenseTierTwoData", classes)}
        {this.getDataBasedOnTier(3, "expenseTierThreeData", classes)}
      </>
    )
  }

  getCreateCategoryView = (classes: any) => {
    return (
      this.state.userCreatedCategories.length > 0 && <>
        {this.state.userCreatedCategories.map(el => (
          <Grid item container xs={12} className={classes.tableRow}
          // key={el?.name + el?.tier}
          >
            <Grid item container alignItems="center" xs={8} className={classes.tableC1}>
              {/* <Typography display="inline" variant="subtitle2">{el?.name}</Typography> */}
              <TextField placeholder="Account Name"
                value={el?.name}
                fullWidth
                hiddenLabel
                style={{
                  fontSize: "0.875rem",
                }}
                InputProps={{
                  disableUnderline: true, // <== added this
                }}
                onChange={(event) => {
                  console.log(event.target.value);

                  let arr = this.state.userCreatedCategories.map(item => {
                    if (item?.id == el?.id) {
                      return Object.assign({}, item, {
                        name: event.target.value
                      })
                    }
                    return item
                  });

                  this.setState({ userCreatedCategories: arr })
                  console.log(arr);

                }}
              // onBlur={() => {

              // }}
              />
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>
              {<Select
                value={el?.tier}
                // displayEmpty
                // renderValue={(value) => {
                //   el?.tier ? el?.tier : "Choose Tier"
                // }}
                // renderValue={(selected) => {
                //   if (selected.length === 0) {
                //     return <em>Placeholder</em>;
                //   }

                //   return el?.tier;
                // }}
                className={classes.selectTier}
                onChange={(event) => {
                  console.log(event.target.value);

                  let arr = this.state.userCreatedCategories.map(item => {
                    if (item?.id == el?.id) {
                      return Object.assign({}, item, {
                        tier: event.target.value
                      })
                    }
                    return item
                  });

                  this.setState({ userCreatedCategories: arr })
                  console.log('arr', arr);


                }}
              >
                {/* <MenuItem disabled value="">
                  <em>Choose Tier</em>
                </MenuItem> */}
                <MenuItem value={"tier_one"}>Tier 1</MenuItem>
                <MenuItem value={"tier_two"}>Tier 2</MenuItem>
                <MenuItem value={"tier_three"}>Tier 3</MenuItem>
              </Select>}
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>
              {el?.is_active !== undefined && <Switch
                classes={{
                  root: classes.viewSwitch,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked
                }}
                checked={el?.is_active}
                onChange={(event) => {
                  console.log(event.target.checked);
                  let arr = this.state.userCreatedCategories.map(item => {
                    if (item?.id == el?.id) {
                      return Object.assign({}, item, {
                        is_active: event.target.checked
                      })
                    }
                    return item
                  });

                  this.setState({ userCreatedCategories: arr })
                  console.log('arr... ', arr);


                }}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />}
            </Grid>
          </Grid>
        ))}
      </>
    )
  };

  getAccountName = (classes: any) => {
    const ACCOUNT_NAMES_FOR_EXPENSE = ['Capital', 'Taxes', 'Credit Card Payment', 'Roth Retirement Contributions'];
    const ACCOUNT_NAMES_FOR_INCOME = ['Capital'];

    let data = ACCOUNT_NAMES_FOR_INCOME;

    if (this.state.activeTab) {
      data = ACCOUNT_NAMES_FOR_EXPENSE
    }

    // return data.map(el =>
    return this.state.staticCategories.map(el =>
      <Grid item container xs={12} className={classes.tableRow}
        key={el + "1"}
      >
        <Grid item container alignItems="center" xs={8} className={classes.tableC1}>
          <Typography display="inline" variant="subtitle2" className={classes.boldTextWithColor}>{el} </Typography>
          {/* <TextField placeholder="Account Name"
            value={el}
            fullWidth
            hiddenLabel
            style={{
              fontSize: "0.875rem",
            }}
            InputProps={{
              disableUnderline: true, // <== added this
            }}
            onChange={(event) => {
              console.log(event.target.value);

              let arr = this.state.userCreatedCategories.map(item => {
                if (item?.id == el?.id) {
                  return Object.assign({}, item, {
                    name: event.target.value
                  })
                }
                return item
              });

              this.setState({ userCreatedCategories: arr })
              console.log(arr);

            }}
          // onBlur={() => {

          // }}
          /> */}
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>

        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={2} className={classes.tableC2}>

        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container
        justifyContent="space-between"
        // alignItems="space-between"
        direction="column"
        style={{
          minHeight: window.innerHeight * 0.85
        }}>
        {/* 1st child open*/}
        <Grid item xs={12}>
          <Grid container justifyContent="center"
          // alignItems="center" 
          >
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.showSnackbar}
              onClose={() => {
                this.setState({
                  showSnackbar: false
                });
              }}
              autoHideDuration={3000}
            >
              <Alert severity="error"
                elevation={6} variant="filled"
              >{this.state.snackbarMessage}</Alert>
            </Snackbar>
            <Grid item container xs={7} justifyContent="space-evenly" alignItems="center" className={classes.topDiv}>

              <Grid item justifyContent="center" alignItems="center" >
                <Button className={`${classes.tabButton} ${classes.incomeButton}`}
                  style={{ backgroundColor: this.state.activeTab == 0 ? '#FF9933' : '#5696F4' }}
                  onClick={() => this.switchTab(0)}
                >
                  <Typography variant="subtitle2" className={classes.boldFont}>
                    Income Accounts
                  </Typography>
                </Button>
              </Grid>

              <Grid item justifyContent="center" alignItems="center">
                <Button className={`${classes.tabButton} ${classes.incomeButton}`}
                  style={{ backgroundColor: this.state.activeTab == 0 ? '#5696F4' : '#FF9933' }}
                  onClick={() => this.switchTab(1)}
                >
                  <Typography variant="subtitle2" className={classes.boldFont}>
                    Expenses Accounts
                  </Typography>
                </Button>
              </Grid>

              {/* <Grid item>
                <Button onClick={() => {
                  this.switchTab(0)
                }} className={this.state.activeTab ? classes.newTabButton : `${classes.newTabButton} ${classes.newActiveTabButton}`}>
                  <Typography variant="subtitle2" className={classes.boldText} style={{ textTransform: "capitalize" }}>
                    Income Accounts
                  </Typography>
                </Button>
              </Grid> */}
              {/* <Grid item>
                <Button onClick={() => this.switchTab(1)} className={this.state.activeTab ? `${classes.newTabButton} ${classes.newActiveTabButton}` : classes.newTabButton}>
                  <Typography variant="subtitle2" className={classes.boldText} style={{ textTransform: "capitalize" }}>
                    Expenses Accounts
                  </Typography>
                </Button>
              </Grid> */}
            </Grid>
            <Grid item container xs={12}>
              <Grid item container alignItems="center" xs={8} className={classes.tableC1}>
                <Typography display="inline" variant="subtitle2" className={classes.boldText}>Account Name</Typography>
                <HelpOutlineIcon className={classes.iconHelp} />
              </Grid>
              <Grid item container justifyContent="center" alignItems="center" xs={2} >
                <Typography display="inline" variant="subtitle2" className={classes.boldText}>Tier</Typography>
                <HelpOutlineIcon className={classes.iconHelp} />
              </Grid>
              <Grid item container justifyContent="center" alignItems="center" xs={2} >
                <Typography display="inline" variant="subtitle2" className={classes.boldText}>View</Typography>
                <HelpOutlineIcon className={classes.iconHelp} />
              </Grid>
              {this.getAccountName(classes)}
            </Grid>
            <Grid item container xs={12}>
              {this.state.activeTab ? this.getExpenseView(classes) : this.getIncomeView(classes)
              }
            </Grid>

            {this.getCreateCategoryView(classes)}
          </Grid>
        </Grid>
        {/* 1st child closed*/}



        {/* 2nd child open*/}
        <Grid item xs={12}>
          <Grid container justifyContent="center">

            <Grid item container xs={12} className={classes.buttons}>
              <Grid item xs={8}>
              </Grid>
              <Grid item container justifyContent="center" alignItems="center" xs={2} >
                <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                  // disabled={this.state.isSaveDisabled}

                  onClick={() => {
                    if (this.state.isSaveDisabled) {
                      return;
                    }
                    this.setState({ isSaveDisabled: true })
                    this.SaveCategories()
                  }}
                >
                  <Typography variant="subtitle2" className={classes.boldFont}>
                    Save
                  </Typography>
                </Button>
              </Grid>
              <Grid item container justifyContent="center" alignItems="center" xs={2}>
                <Button className={`${classes.tabButton} ${classes.saveButton}`}
                  onClick={() => this.addCategoryToState()}
                >
                  <Typography variant="subtitle2" className={classes.boldFont}>
                    Add Account
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.newTableC1} style={{
              // alignSelf: 'flex-end'
            }}>
              <RemoveCircleRoundedIcon className={classes.iconRemove}
                onClick={() => {
                  this.removeCategory(el);
                }}
              />
              <Typography variant="subtitle2" className={classes.boldText}>Hidden Accounts</Typography>
              <Typography variant="caption" className={classes.subtitle2} style={{ textTransform: "initial" }}>Toggle the view swith on the right hand side to either hide, or view a category on your transaction screen.</Typography>
            </Grid>
            {/* <Grid item container xs={12}> */}
            {this.state.activeTab ? this.getExpenseViewForHiddenCategories(classes) : this.getIncomeViewForHiddenCategories(classes)
            }
            {/* </Grid> */}
          </Grid>
        </Grid>


        {/* <Grid item container xs={12} style={{
          backgroundColor: 'red',
          bottom: 0
        }}> */}
        {/* </Grid> */}
      </Grid>
    );
  }
}


export default withStyles(useStyles)(TransactionSettingWeb);

import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  ActivityIndicator,
  FlatList,
  TouchableHighlight,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import { scaledSize } from "framework/src/Utilities";
import { arrowLeft, arrowRight, backIcon, calendarIcon, carIcon, closeIcon, fullCalendar, infoIcon } from "./assets";
import Button from "../../../components/src/Button";
import moment from "moment";
import CustomModal from "../../../components/src/CustomModal";
import { Calendar as CustomCalendar } from "../../../components/src/CustomCalendar";
import AddMileage from "../../../components/src/AddMileage";
// Customizable Area End

import CalendarController, {
  Props,
  configJSON
} from "./CalendarController";

export default class Calendar extends CalendarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMileageList = (item: any, index: number) => (
    <TouchableHighlight>
      <View style={styles.mileageListContainer}>
        <View style={styles.carView}>
          <Image source={carIcon} style={[styles.carIcon, { tintColor: item.car_color }]} />
          <Text style={styles.carText} numberOfLines={1}>{item.car_name}</Text>
        </View>
        <View style={styles.textsView}>
          <Text style={styles.dateText}>{moment(item.date_of_mileage).format('ddd MMM DD')}</Text>
          <Text style={styles.purposeText} numberOfLines={1}>{item.business_purpose}</Text>
          <Text style={styles.dateText}>{`${item.miles} mi`}</Text>
        </View>
        <Text style={styles.priceText}>{`$${item.mileage_deduction}`}</Text>
      </View>
    </TouchableHighlight>
  )

  mileageView = () => (
    <View>
      <View style={styles.topView}>
        <View style={styles.firstView}>
          <Text style={[styles.totalMonthlyText, { width: scaledSize(105) }]} numberOfLines={2}>{configJSON.yearToDateBusinessMileTitle}</Text>
          <Text style={[styles.totalMonthlyValue, { width: scaledSize(70) }]}>{this.state.yearToDateMiles}</Text>
        </View>
        <View style={styles.seperatorLine} />
        <View style={styles.secondView}>
          <Text style={[styles.totalMonthlyText, { width: scaledSize(97) }]} numberOfLines={2}>{configJSON.totalMonthlyMile}</Text>
          <Text style={[styles.totalMonthlyValue, { width: scaledSize(70) }]}>{this.state.totalMonthlyMiles}</Text>
        </View>
        <View style={styles.seperatorLine} />
        <View style={styles.thirdView}>
          <Text style={[styles.totalMonthlyText, { width: scaledSize(85) }]} numberOfLines={2}>{configJSON.totalDeduction}</Text>
          <Text style={[styles.totalMonthlyValue, { width: scaledSize(85) }]}>{`$${this.state.totalDeduction}`}</Text>
        </View>
      </View>
      <View style={styles.horizontalSeperatorLine} />
      <View style={styles.mileageLogView}>
        <Text style={styles.mileageLogText}>{`Mileage Log (${this.state.totalMonthlyMiles} miles)`}</Text>
        <TouchableOpacity style={styles.yearToDateView} onPress={() => this.openDateFilterModal()} testID="testCalendarIcon">
          <Text style={styles.yearToDateText}>{this.state.selectedFilterValue}</Text>
          <Image source={fullCalendar} style={styles.calendarIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.horizontalSeperatorLine} />
      <View style={styles.mileageListView}>
        {this.state.mileageData.length === 0 ? <Text style={styles.noMileageText}>{configJSON.noMileageRecord}</Text> :
          <FlatList 
            data={this.state.mileageData}
            renderItem={({item, index}: { item: any, index: number}) => this.renderMileageList(item, index)}
            style={{ height: "100%", width: "100%" }}
            keyExtractor={(item: any) => item.id}
            testID="testMileageList"
          />}
      </View>
      <View style={styles.horizontalSeperatorLine} />
      <View style={styles.buttonsView}>
        <Button
          theme={"secondary"}
          text={"NO MILEAGE"}
          disabled={false}
          textStyle={styles.noMileageBtnText}
          style={styles.noMileageBtn}
          testID="testNoMileage"
          onPress={this.onPressNoMileage}
        />
        <Button
          theme={"secondary"}
          text={"ADD MILEAGE"}
          disabled={false}
          textStyle={styles.addMileageBtnText}
          style={styles.addMileageBtn}
          testID="testAddMileage"
          onPress={this.onPressAddMileage}
        />
      </View>
    </View>
  )

  noCarsView = () => (
    <View>
      <TouchableOpacity style={styles.backView} onPress={this.onPressBack} testID="testBackBtn">
        <Image style={styles.backIcon} source={backIcon} />
        <View style={styles.backTextView}>
          <Text style={styles.backText}>Back</Text>
        </View>
      </TouchableOpacity>
      <View style={styles.cardView}>
        <View style={styles.cardTextView}>
          <Text style={styles.cardText}>{configJSON.addBusiness}</Text>
          <Text style={styles.cardText}>{configJSON.recordYourMiles}</Text>
        </View>
        <Button
          theme={"secondary"}
          text={"ADD CAR"}
          disabled={false}
          textStyle={styles.addCarBtnText}
          style={styles.addCarBtnView}
          testID="testAddCar"
          onPress={() => { }}
        />
      </View>
    </View>
  )

   renderViews = () => {
    if (!this.state.isLoading && !this.state.carsLoading) {
      if (this.state.noCarsView) {
        return this.noCarsView();
      } else {
        return this.mileageView();
      }
    } else {
      return (
        <View style={{ marginTop: scaledSize(225), justifyContent: "center", alignItems: "center" }}>
          <ActivityIndicator size={"large"} color={"#3876F1"} />
        </View>
      )
    }
   }

   renderFilterList = (item: string, index: number) => {
    return (
      <TouchableOpacity style={[styles.filterListItemContainer, this.state.selectedFilterValue === item && { borderColor: "#3876F1" }]} onPress={() => this.onPressFilters(item)} testID={`testFilterListItem${index}`}>
        <Text style={styles.filterText}>{item}</Text>
      </TouchableOpacity>
    )
   }

   renderCalendarArrowIcon = (direction: string) => {
    if (direction === "left") {
      return <Image style={styles.arrowStyle} source={arrowLeft} />
    } else {
      return <Image style={styles.arrowStyle} source={arrowRight} />
    }
   }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <View style={styles.container}>
          {/* Merge Engine UI Engine Code */}
          {!this.state.showAddMileageComponent ? <View>
            {this.renderViews()}
            <CustomModal
              visible={this.state.isVisibleYearToDateModal}
              onRequestClose={() => this.closeDateFilterModal()}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalTopView}>
                  <Text style={styles.fromText}>From</Text>
                  <Text style={styles.toText}>To</Text>
                  <TouchableOpacity style={styles.closeBtn} onPress={() => this.onPressCancelModal()} testID="testCloseIcon">
                    <Image style={styles.closeIcon} source={closeIcon} />
                  </TouchableOpacity>
                </View>
                <View style={styles.mainDateView}>
                  <TouchableOpacity style={styles.fromDateView} testID="testFromDate" onPress={this.onOpenFromDateCalendar}>
                    <Image style={styles.calendarIconStyle} source={calendarIcon} />
                    <Text style={styles.dateValue}>{this.state.fromDate}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={[styles.toDateView, styles.fromDateView]} testID="testToDate" onPress={this.onOpenToDateCalendar}>
                    <Image style={styles.calendarIconStyle} source={calendarIcon} />
                    <Text style={styles.dateValue}>{this.state.toDate}</Text>
                  </TouchableOpacity>
                </View>
                <FlatList
                  data={["This Week", "This Month", "This Quarter", "Year to Date"]}
                  renderItem={({ item, index }: { item: string, index: number }) => this.renderFilterList(item, index)}
                  style={styles.filterList}
                  keyExtractor={(item: string) => item}
                  testID="testFilterFlatList"
                />
                <Button
                  theme={"secondary"}
                  text={"APPLY FILTERS"}
                  disabled={false}
                  textStyle={styles.addMileageBtnText}
                  style={[styles.addMileageBtn, styles.applyFiltersBtn]}
                  testID="testApplyFilters"
                  onPress={this.onPressApplyFilters}
                />
              </View>
            </CustomModal>
            <CustomModal
              visible={this.state.isFromDateCalendarVisible}
              onRequestClose={this.onCloseFromDateCalendar}
            >
              <TouchableWithoutFeedback onPress={this.onCloseFromDateCalendar} testID="testCloseFromDate">
                <View style={styles.centeredCalendarView}>
                  <CustomCalendar
                    maxDate={moment(this.state.toDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                    // Initially visible month. Default = now
                    initialDate={moment(this.state.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                    // current={moment(new Date()).format("YYYY-MM-DD")}
                    markedDates={this.state.markedFromDate}
                    // Handler which gets executed on day press. Default = undefined
                    onDayPress={day => this.onPressFromDay(day)}
                    renderArrow={direction => this.renderCalendarArrowIcon(direction)}
                    // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
                    monthFormat={'MMMM yyyy'}
                    onPressArrowLeft={subtractMonth => subtractMonth()}
                    onPressArrowRight={addMonth => addMonth()}// disableArrowRight={true}
                    disableAllTouchEventsForDisabledDays={true}
                    enableSwipeMonths={true}
                    testID="testFromCalendar"
                  />
                </View>
              </TouchableWithoutFeedback>
            </CustomModal>
            <CustomModal
              visible={this.state.isToDateCalendarVisible}
              onRequestClose={this.onCloseToDateCalendar}
            >
              <TouchableWithoutFeedback onPress={this.onCloseToDateCalendar} testID="testCloseToDate">
                <View style={styles.centeredCalendarView}>
                  <CustomCalendar
                    minDate={moment(this.state.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                    initialDate={moment(this.state.toDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                    markedDates={this.state.markedToDate}
                    onDayPress={day => this.onPressToDay(day)}
                    renderArrow={direction => this.renderCalendarArrowIcon(direction)}
                    monthFormat={'MMMM yyyy'}
                    onPressArrowLeft={subtractMonth => subtractMonth()}
                    onPressArrowRight={addMonth => addMonth()}
                    disableAllTouchEventsForDisabledDays={true}
                    enableSwipeMonths={true}
                    testID="testToCalendar"
                  />
                </View>
              </TouchableWithoutFeedback>
            </CustomModal>
            <CustomModal
              visible={this.state.isMileageInfoModalVisible && !this.state.showAddMileageComponent}
              onRequestClose={this.onCloseMileageInfoModal}
            >
              <TouchableWithoutFeedback>
                <View style={styles.centeredModalMileageInfoView}>
                  <View style={styles.modalView}>
                    <View style={styles.crossIcon}>
                      <TouchableOpacity onPress={this.onCloseMileageInfoModal}>
                        <Image source={closeIcon} />
                      </TouchableOpacity>
                    </View>
                    <View style={styles.centerInfoModal}>
                      <View>
                        <Image source={infoIcon} style={styles.infoImage} />
                      </View>
                      <View>
                        <Text style={[styles.modalText]}>
                          Did you drive yesterday?
                        </Text>
                      </View>
                      <View>
                        <Text style={[styles.modalText, { marginTop: scaledSize(10) }]}>
                          If so, click the <Text style={styles.boldText}>"add mileage"</Text> button and enter the distance you traveled. If not, simply click <Text style={styles.boldText}>"no mileage"</Text> and the app will record zero miles driven the previous day.
                        </Text>
                      </View>
                      <View>
                        <Text style={[styles.modalText, { marginTop: scaledSize(20) }]}>
                          Click on any entry to edit it.
                        </Text>
                      </View>
                      <View>
                        <Text style={[styles.modalText, { marginTop: scaledSize(20) }]}>
                          The current IRS Standard Mileage Rate is 65.5 cents per mileage. This can change throughout the year.
                        </Text>
                      </View>
                      <View>
                        <Text style={[styles.modalText, { marginTop: scaledSize(20) }]}>
                          Each day you add yesterday's mileage,
                        </Text>
                      </View>
                      <View>
                        <Text style={[styles.modalText, { marginTop: scaledSize(10) }]}>
                          you will earn a leaf (and decrease your tax liability)!
                        </Text>
                      </View>
                      <View
                        style={{
                          alignItems: 'center',
                          marginTop: 10,
                          marginBottom: 10,
                        }}>
                        <Button
                          theme={"secondary"}
                          text={"GOT IT!"}
                          disabled={false}
                          textStyle={styles.addMileageBtnText}
                          style={styles.addMileageBtn}
                          testID="testGotIt"
                          onPress={this.onCloseMileageInfoModal}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </CustomModal>
          </View> :
            <AddMileage
              onChangeMileageDate={this.onChangeMileageDate}
              onChangeAdditionalNotes={this.onChangeAdditionalNotes}
              state={this.state}
              setOpenCarsDropdown={this.onOpenCarsDropdown}
              onChangeCarsValue={this.onSetCarValue}
              setOpenBusinessPurposeDropdown={this.onOpenBusinessPurposeDropdown}
              onChangeBusinessPurposeValue={this.onSetBusinessPurposeValue}
              onFocusAdditionalNotes={this.onFocusAdditionalNotes}
              onBlurAdditionalNotes={this.onBlurAdditionalNotes}
              onChangeBusinessMiles={this.onChangeBusinessMiles}
              onSaveEdit={this.onSaveAddMileageDetails}
              onOpenDatePicker={this.onOpenDate}
              renderCalendarArrow={this.renderCalendarArrowIcon}
              onFocusBusinessMiles={this.onFocusBusinessMiles}
              onBlurBusinessMiles={this.onBlurBusinessMiles}
              testID="testAddMileage"
            />}
          {/* Merge Engine UI Engine Code */}
        </View>
        <TouchableOpacity style={styles.infoImageView} onPress={this.onPressInfoIcon} testID="testInfoIcon">
          <Image source={infoIcon} style={styles.infoImage} />
        </TouchableOpacity>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffffff",
    // top: scaledSize(-3),
  },
  topView: {
    // flex: 1,
    flexDirection: "row",
    height: scaledSize(97),
    alignItems: "center",
  },
  firstView:{
    width: "38%",
    justifyContent: "center",
    alignItems: "center"
  },
  seperatorLine: {
    height: "100%",
    width: scaledSize(1),
    backgroundColor: "#AEAEAE"
  },
  secondView: {
    width: "27%",
    justifyContent: "center",
    alignItems: "center"
  },
  thirdView: {
    width: "35%",
    justifyContent: "center",
    alignItems: "center"
  },
  horizontalSeperatorLine: {
    width: "100%",
    height: scaledSize(0.5),
    backgroundColor: "#AEAEAE"
  },
  totalMonthlyText: {
    fontSize: scaledSize(11),
    color: "#000",
    textAlign: "center",
    fontFamily: "OpenSans-Regular",
  },
  totalMonthlyValue: {
    fontSize: scaledSize(22),
    color: "#000",
    textAlign: "center",
    fontFamily: "OpenSans-Bold",
  },
  mileageLogView: {
    height: scaledSize(52),
    // flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: scaledSize(26),
  },
  mileageLogText: {
    fontSize: scaledSize(12),
    fontFamily: 'OpenSans-Bold',
    color: "#000"
  },
  yearToDateView: {
    flexDirection: "row",
    width: scaledSize(97),
    justifyContent: "flex-end",
    alignItems: "center"
  },
  yearToDateText: {
    fontSize: scaledSize(11),
    color: "#3877F2",
    fontFamily: "OpenSans-Regular",
    marginRight: scaledSize(3)
  },
  calendarIcon: {
    height: scaledSize(13),
    width: scaledSize(14),
  },
  mileageListView: {
    height: scaledSize(270),
    width: "100%",
    backgroundColor: "#F4F4F4"
  },
  noMileageText: {
    fontSize: scaledSize(12),
    color: "#000000",
    fontFamily: "OpenSans-Bold",
    width: "100%",
    textAlign: "center",
    marginTop: scaledSize(18)
  },
  buttonsView: { 
    flexDirection: "row", 
    justifyContent: 'space-between', 
    marginHorizontal: scaledSize(30), 
    marginTop: scaledSize(15), 
    marginBottom: scaledSize(90) 
  },
  noMileageBtnText: { 
    color: "#3876F1", 
    fontSize: scaledSize(12), 
    lineHeight: scaledSize(19) 
  },
  noMileageBtn: {
    height: scaledSize(28),
    width: scaledSize(140),
    borderRadius: scaledSize(44),
    paddingHorizontal: 0,
    paddingVertical: 0,
    borderColor: "#3876F1",
    borderWidth: 1,
    backgroundColor: "#ffffffff",
  },
  addMileageBtnText: { 
    color: "#ffffffff", 
    fontSize: scaledSize(12), 
    lineHeight: scaledSize(19) 
  },
  addMileageBtn: {
    height: scaledSize(28),
    width: scaledSize(140),
    borderRadius: scaledSize(44),
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  // no Cars styles
  backView: {
    flexDirection: "row",
    marginLeft: scaledSize(17),
    alignItems: "center",
    marginTop: scaledSize(5)
  },
  backIcon: {
    height: scaledSize(13),
    width: scaledSize(7),
  },
  backTextView: {
    marginLeft: scaledSize(9),
  },
  backText: {
    fontSize: scaledSize(14),
    color: "#5696F4",
    fontFamily: "OpenSans-Regular",
    lineHeight: scaledSize(19)
  },
  cardView: {
    marginTop: scaledSize(39),
    marginLeft: scaledSize(35),
    height: scaledSize(164),
    width: scaledSize(303),
    borderRadius: scaledSize(13),
    backgroundColor: "#15066A"
  },
  cardTextView: {
    height: scaledSize(89),
    width: scaledSize(287),
    justifyContent: "center",
    alignItems: "center",
    marginTop: scaledSize(17),
    alignSelf: "center",
  },
  cardText: {
    fontSize: scaledSize(17),
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(27)
  },
  addCarBtnView: {
    marginTop: scaledSize(10),
    alignSelf: "center",
    height: scaledSize(24),
    width: scaledSize(137),
    borderRadius: scaledSize(44),
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  addCarBtnText: { 
    color: "#000000", 
    fontSize: scaledSize(12), 
    lineHeight: scaledSize(19),
  },
  
  // mileage list styles
  mileageListContainer: {
    height: scaledSize(52),
    width: "100%",
    paddingHorizontal: scaledSize(14),
    borderBottomColor: "#B3B3B3",
    borderBottomWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  carView: {
    height: scaledSize(44),
    width: scaledSize(58),
    alignItems: "center",
  },
  carIcon: {
    height: scaledSize(23),
    width: scaledSize(33),
    resizeMode: "contain"
  },
  carText: {
    fontSize: scaledSize(11),
    lineHeight: scaledSize(16),
    color: "#0F172A",
    fontFamily: "OpenSans-Regular",
    textAlign: "center",
    textTransform: "uppercase",
  },
  textsView: {
    width: "42%",
    height: "100%",
  },
  dateText: {
    fontSize: scaledSize(10),
    lineHeight: scaledSize(16),
    fontFamily: "OpenSans-Regular",
    color: "#666666",
    width: "100%",
  },
  purposeText: {
    fontSize: scaledSize(12),
    lineHeight: scaledSize(19),
    fontFamily: "OpenSans-Bold",
    color: "#0F172A",
  },
  priceText: {
    width: "30%",
    color: "#000000",
    fontSize: scaledSize(12),
    lineHeight: scaledSize(19),
    textAlign: "right",
    fontFamily: "OpenSans-Bold"
  },
  centeredView: {
    width: "90%",
    alignSelf: 'center',
    marginTop: scaledSize(200),
    zIndex:-1,
    paddingBottom: scaledSize(16),
    position: 'relative',
    bottom: 0,
    backgroundColor: "white",
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 22,
    elevation: 5,
    paddingHorizontal: scaledSize(21),
    borderRadius: scaledSize(22)
  },
  modalTopView: {
    flexDirection: "row",
    height: scaledSize(37),
    width: "100%",
    alignItems: "center",
    marginTop: scaledSize(5)
  },
  fromText: {
    fontSize: scaledSize(12),
    lineHeight: scaledSize(19),
    color: "#0F172A",
    fontFamily: "OpenSans-Regular",
    width: "30%",
  },
  toText: {
    fontSize: scaledSize(12),
    lineHeight: scaledSize(19),
    color: "#0F172A",
    fontFamily: "OpenSans-Regular",
    width: "30%",
    marginLeft: "20%"
  },
  closeBtn: {
    marginLeft: "10%"
  },
  closeIcon: {
    height: scaledSize(42),
    width: scaledSize(42),
  },
  mainDateView: {
    flexDirection: "row",
    width: "100%"
  },
  fromDateView: {
    width: "40%",
    height: scaledSize(35),
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: scaledSize(5),
    flexDirection: "row",
    alignItems: "center"
  },
  calendarIconStyle: {
    marginLeft: scaledSize(8),
    height: scaledSize(16),
    width: scaledSize(16)
  },
  dateValue: {
    marginLeft: scaledSize(3),
    fontSize: scaledSize(12),
    lineHeight: scaledSize(19),
    color: "#0F172A",
    fontFamily: "OpenSans-Regular"
  },
  toDateView: {
    marginLeft: "10%"
  },
  filterList: {
    marginTop: scaledSize(9),
  },
  filterListItemContainer: {
    width: "100%",
    height: scaledSize(35),
    borderRadius: scaledSize(5),
    borderWidth: 1,
    borderColor: "#E2E8F0",
    marginVertical: scaledSize(5),
    alignItems: "center",
    justifyContent: "center"
  },
  filterText: {
    fontSize: scaledSize(12),
    lineHeight: scaledSize(19),
    color: "#0F172A",
    fontFamily: "OpenSans-Regular"
  },
  applyFiltersBtn: { alignSelf: "center", marginTop: scaledSize(10)},
  centeredCalendarView: {
    width: "90%",
    alignSelf: 'center',
    marginTop: scaledSize(300),
    zIndex:-1,
    paddingBottom: scaledSize(16),
    position: 'relative',
    bottom: 0,
    backgroundColor: "white",
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 22,
    elevation: 5,
    paddingHorizontal: scaledSize(21),
    borderRadius: scaledSize(22)
  },
  arrowStyle: {
    height: scaledSize(21),
    width: scaledSize(21),
  },
  infoImageView: {
    height: scaledSize(27),
    width: scaledSize(27),
    top: "14%",
    position: "absolute",
    right: scaledSize(27),
    zIndex: 10000
  },
  infoImage: {
    height: scaledSize(29),
    width: scaledSize(27),
  },

  //mileage info modal styles
  boldText: {
    fontFamily: "OpenSans-Bold"
  },
  crossIcon: {
    position: 'absolute',
    zIndex:9999,
    top: scaledSize(10),
    right: scaledSize(10),
    bottom: scaledSize(10),
  },
  centeredModalMileageInfoView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1,
    position: 'relative',
    bottom: 0,
    padding: scaledSize(30),
    height: scaledSize(2000), 
    backgroundColor: Platform.OS === 'ios' ?"#D9D9D980" : "transparent", 
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: scaledSize(20),
    paddingTop: scaledSize(40),
    padding: scaledSize(14),
    justifyContent:"center",
    alignItems: 'center',
    shadowColor: '#000',
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: scaledSize(330)
  },
  buttonClose: {
    backgroundColor: '#3876F1',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: scaledSize(14),
  },
  modalText: {
    textAlign: 'center',
    fontSize: scaledSize(14),
    lineHeight: scaledSize(21),
    fontFamily:"OpenSans-Regular",
    color: "#0F172A"
  },
  centerInfoModal: {
    marginTop: scaledSize(14),
    alignItems: "center"
  },
});
// Customizable Area End

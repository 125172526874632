Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "/bx_block_transactions/fetch_all_transaction?transaction_category=income";

exports.incomeApiContentType = "application/json";
exports.incomeAPIEndPoint = "bx_block_transactions/incomes?tax_year=";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.transactionCategoryAPIEndPoint = "bx_block_transactions/transaction_categories/?transaction_type=income"
exports.expenseCategoryAPIEndPoint  ="bx_block_transactions/transaction_categories/?transaction_type=expense"
exports.categorizetransactionUpdate = "bx_block_transactions/categorized_transaction_updation";

exports.addIncomeTransactions = "bx_block_transactions/incomes"
exports.addSplitTransaction = "bx_block_transactions/split_income?split_file_income"




// Customizable Area End
//@ts-nocheck
import React from "react"; 
import {AppBar, Typography, Button, withStyles, Grid} from '@material-ui/core'
import {Link , NavLink} from 'react-router-dom'
import Logo from "./Neeuh Logo_White.png"
const useStyles = (theme) => ({
  root: {
      width: "100vw",
      height: "100vh",
      backgroundColor: "#15066A",
      overflow: "hidden",
      position: "relative",
   },
   wText:{
     color: "#ffffff",
   },
   activeLink:{
    borderRadius: "20px",
    border: "2px solid #FFBB78"
   },
   link:{
     color: "#000",
    textDecoration: "none"
   },
   linkButton:{
     fontWeight: "bold",
    borderRadius: "20px",
     padding: "1px 15px",
     fontSize: "14px"
   },
   lBlue:{
     color: "#2a1d77",
     fontWeight: "300"
   },
   dBlue:{
     color: "#2a1d77",
   },
   logo:{
     width: "100px"
   },
   navBar:{
     boxShadow: "none",
     padding: "0 20px",
   },
   horizontalBar:{
     backgroundImage: 'linear-gradient(to right, #15066A , #5696F4)',
   },
   verticalBar:{
     width: '150px', 
    backgroundColor: '#15066A',
    zIndex: 1
  }
});
  class TopNavPrivate extends React.Component{
    render(){
      const {classes} = this.props
      return (
      <>
        <AppBar position="static" className={`${classes.navBar} ${classes.horizontalBar}`}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Typography variant="h6" className={classes.wText}>
                Tax Year
              </Typography>
              <Typography variant="caption" className={`${classes.linkButton} ${classes.dBlue}`}>Log In</Typography>
          </Grid>
        </AppBar>
        <AppBar position="static" className={`${classes.navBar} ${classes.verticalBar}`}>
            <Grid container direction="column" alignItems="center" >
              <Grid item>
                <NavLink to="/">
                  <img src={Logo} className={classes.logo}/>
                </NavLink>
                <Typography variant="h6" className={classes.wText}>
                  Tax Year
                </Typography>
                <NavLink to="/EmailAccountRegistration"  exact={true} activeClassName={classes.activeLink} className={classes.link}>
                  <Typography variant="caption" className={`${classes.linkButton} ${classes.dBlue}`}>Log In</Typography>
                </NavLink>
              </Grid>
          </Grid>
        </AppBar>
      </>
    );
}}


export default withStyles(useStyles)(TopNavPrivate)

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert } from "react-native";
export const configJSON = require("./config");
import { Key } from "react";
import { createRef } from "react";
import { getStorageData } from "framework/src/Utilities";
import { displayToastAlert } from "../../../components/src/CToastAlert";
import { Platform } from "react-native";
let ImagePicker:any;
if (Platform.OS !== "web") {
  ImagePicker =  require("react-native-image-crop-picker");
}
export type IncomeDataObjectType = {
  id: string,
  date: string,
  amount: string,
  name: string
}
export interface CategoryItemType {
  id:         string;
  type:       string;
  attributes: Attributes;
}

export interface Attributes {
  id:                        number;
  name:                      string;
  account_id:                number;
  is_active:                 boolean;
  tier:                      string;
  transaction_type:          string;
  transaction_category_type: string;
  cost_of_goods:             boolean;
  role_name:                 string;
  isSelected:                boolean
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleBottomTab?: any;
  handleBottomTabShow?: any;
  logout?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  expenseData:any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  inputFieldData:any;
  addNote:string;
  selectedImage: any ;
  imagesList:any;
  isUpload:boolean;
  isUploadCamera:boolean,
  image:any,
  showModal:boolean,
  selectedIndex:any,
  incomeData: IncomeDataObjectType[],
  activeIndex: number,
  selectedCategory: CategoryItemType[],
  selectedExpense: CategoryItemType[]
  isAddDetail:boolean,
  remainingAmount:number;
  price:any,
  total:number,
  allCategoryArray: any;
  isAllItem: boolean;
  isUndoAddToReport: boolean;
  isShowSingleTransactionModal: boolean;
  cameraImage: any;
  isEditSplit: boolean;
  isSave: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoriesApiCallId: any;
  getExpenseCategoriesAPIEndPoint: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  addSplitTransactionId:any;
  saveDetailsApiCallId:any;
  carousel: any;
  getIncomeApiCallId: string = "";
  addToReportApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      expenseData:[],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      inputFieldData:{},
      addNote:"",
      selectedImage: [],
      imagesList:[],
      isUpload:false,
      isUploadCamera:false,
      image:"",
      showModal: false,
      selectedIndex: null,
      incomeData: [],
      activeIndex: 0,
      selectedCategory: [],
      selectedExpense: [],
      isAddDetail:false,
      remainingAmount:0,
      price:[],
      total:0,
      allCategoryArray: [],
      isShowSingleTransactionModal: false,
      isAllItem: false,
      cameraImage: "",
      isUndoAddToReport: false,
      isEditSplit: false,
      isSave: false
          };
    this.carousel = createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
this.getIncomeDataApiCall();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.calculateTotalAndRemaining();
    // Customizable Area End
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let tokenn = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: tokenn }, () => {
        this.getCategories();
        this.getExpense();
      });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      this.apirequestCallingRefactor(apiRequestCallId, responseJson, errorReponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apirequestCallingRefactor = (apiRequestCallId: any, responseJson: any, errorReponse: any) => {
    if (responseJson?.data) {
      this.apiRequestCallIdSQ(apiRequestCallId, responseJson)
    } else if (
      apiRequestCallId === this.deleteCategoriesApiCallId && !responseJson.error
    ) {
      this.getCategories();
    }
        else if (apiRequestCallId === this.deleteCategoriesApiCallId && responseJson.error) {
      this.parseApiCatchErrorResponse(responseJson.error.message);
    }
    else if (
      apiRequestCallId === this.deleteSubCategoriesApiCallId && !responseJson.error
    ) {
      this.getCategories();
    }
    else if (
      apiRequestCallId === this.deleteSubCategoriesApiCallId && responseJson.error
    ) {
      this.parseApiCatchErrorResponse(responseJson.error.message);
    }
    else if (responseJson.errors) {
      this.parseApiCatchErrorResponse(errorReponse);
      if (responseJson.errors[0]?.token) {
        this.props.logout();
      }
    } else if (responseJson.message) {
      displayToastAlert("error", responseJson.message)
    }
  }

  apiRequestCallIdSQ = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getCategoriesApiCallId) {
      this.getCategoriesApiCallIdSQ(responseJson)

    } else if (apiRequestCallId === this.getExpenseCategoriesAPIEndPoint) {
      this.getExpenseCategoriesAPIEndPointSQ(responseJson)

    } else if (apiRequestCallId === this.addCategoryApiCallId) {
      this.setState({ isVisible: false, category: "" }, () => {
        this.getCategories();
      });
    } else if (apiRequestCallId === this.addSubCategoryApiCallId) {
      this.updatedstate1()
    } else if (apiRequestCallId === this.getIncomeApiCallId) {
      this.getIncomeDataAPISuccessCallback(responseJson)
    } else if (apiRequestCallId === this.addToReportApiCallId) {
      this.addToReportApiSuccessCallback(responseJson)
    }
  }

  getExpenseCategoriesAPIEndPointSQ = (responseJson: any) => {
    let resData = responseJson.data;
    let data: CategoryItemType[] = [];
    resData.forEach((element: any) => {
      if (element.values.length > 0) {
        element.values.forEach((value: any) => {
          value.values?.forEach((obj: any) => {
            if (obj.attributes.is_active) {
              data.push(obj)
            }
          })
        })
      }
    });
    this.setState({ expenseData: data })
  }
  getCategoriesApiCallIdSQ = (item: any) => {
    let array = item.data;
    let data: CategoryItemType[] = [];
    array.forEach((element: any) => {
      if (element.values.length > 0) {
        element.values.forEach((value: any) => {
          value.values?.forEach((obj: any) => {
            if (obj.attributes.is_active) {
              data.push(obj)
            }
          })
        })
      }
    });
    this.setState({ categoriesArray: data, allCategoryArray: data, isAllItem: data.length <= 5 });
  }
  updatedstate1 = () => {
    this.setState(
      {
        isVisible: false,
        category: "",
        subCategory: "",
        selectedCategoryID: []
      },
      () => {
        this.getCategories();
      }
    );
  }
  getIncomeDataApiCall = async() => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.incomeApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIncomeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.incomeAPIEndPoint + new Date().getFullYear()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getIncomeDataAPISuccessCallback = (response: any) => {
    if (response.data) {
      this.setState({ incomeData: response.data.bank_transaction })
    }
  }
  setCategoryItem = (item: CategoryItemType) => {
    let categoryData = this.state.categoriesArray;
    categoryData = categoryData.map((mapedItem: CategoryItemType) => {
      if(mapedItem.id === item.id) {
        return {
          ...mapedItem,
          attributes: {
            ...mapedItem.attributes,
            isSelected: !mapedItem.attributes.isSelected,
          }
        }
      } else {
        return {
          ...mapedItem
        }
      }
    })
    let expenses = this.state.expenseData;
    let expensesSelectedData = this.state.selectedExpense;
    expenses = expenses.map((mapedItem: CategoryItemType) => {
      if(mapedItem.id === item.id) {
        expensesSelectedData.push({
          ...mapedItem,
          attributes: {
            ...mapedItem.attributes,
            isSelected: !mapedItem.attributes.isSelected,
          }
        })
        return {
          ...mapedItem,
          attributes: {
            ...mapedItem.attributes,
            isSelected: !mapedItem.attributes.isSelected,
          }
        }
      } else {
        return {
          ...mapedItem
        }
      }
    })
    let data = categoryData.filter((item: CategoryItemType) => item.attributes.isSelected === true)
        this.setState({ selectedCategory: data, categoriesArray: categoryData, expenseData: expenses })
  }

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = async () => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.transactionCategoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInputChange = (index: any, value: any, name: any) => {
  const numericValue = value.replace(/[^\d.]/g, '');
    this.setState(
        (prevState) => ({
            inputFieldData: {
                ...prevState.inputFieldData,
                [name]: numericValue
            },
        }),
        () => {
            this.calculateTotalAndRemaining();
        }
    );
  };

  calculateTotalAndRemaining = () => {
    const { inputFieldData, incomeData, activeIndex } = this.state;
    const values = Object.values(inputFieldData);

    const sum:any = values.reduce((acc:any, price:any) => acc + parseFloat(price), 0);

    let remaining = 0;
    if (incomeData && incomeData[activeIndex] && !isNaN(sum)) {
      const listItem:any = incomeData[activeIndex].amount;
    remaining = listItem - sum;
  }
    this.setState({ total: sum, remainingAmount: remaining});
  };

  handleNoteChange = (text: any) => {
    this.setState({addNote: text})
  }

  handlePressAddDetails= () => {
    this.setState({isAddDetail: !this.state.isAddDetail})
    this.props.handleBottomTab()
  }

  handleBackPress = () => {
    this.handlePressAddDetails();
    this.props.handleBottomTabShow()
  };

  handleImageLibrary = () => {
    if (Platform.OS !== "web"){
    ImagePicker?.openPicker({
      width: 300,
      height: 400,
      imageLoader: "UNIVERSAL",
      mediaType: "any",
      multiple: false,
    }).then((response:any) => {
      if (!response) {
        return;
      }
      const selectedImage = {
        uri: response.path,
        name: response.path.split("/").pop(),
        type: response.mime,
      };
      this.setState({ imagesList: [selectedImage], image: selectedImage, isUpload : true });
    });
  };
}

  handleDeleteImage = (index: Key | null | undefined) => {
    this.setState({ showModal: true, selectedIndex: index });
  };

  deleteImage = () => {
    if (this.state.selectedIndex !== null) {
      const newImagesList = this.state.imagesList.filter((_: any, i: any) => i !== this.state.selectedIndex);
      this.setState({ imagesList: newImagesList, showModal: false, selectedIndex: null });
    }
  };

  handleDeleteSelectedImage = () => {
    this.setState({
      showModal: true
    });
  };
  deleteCameraImage=()=>{
    this.setState({
      showModal: false,
      selectedImage:[],
      isUploadCamera:false
    });
  }

  closeModal = () => {
    this.setState({ showModal: false, selectedIndex: null });
  };

  handleCameraPress = () => {
    if (Platform.OS !== "web"){
    const options = {
      width: 300,
      height: 400,
      cropping: true,
          };
    ImagePicker?.openCamera(options).then((image:any) => {
            const selectImages = {
        uri: image.path,
        name: image.filename,
      }
      this.setState({ selectedImage: [selectImages], cameraImage : selectImages, isUploadCamera: true });
    });
  };
  }

  handleImageToBase64 = (imageUri:any) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function (error) {
        reject(error);
      };
      xhr.responseType = 'blob';
      xhr.open('GET', imageUri, true);
      xhr.send(null);
    });
  };

  handleEditSplit = () => {
    this.setState({isAddDetail: !this.state.isAddDetail})
  }

  handleEditDetails = () => {
    this.setState({ isShowSingleTransactionModal: !this.state.isShowSingleTransactionModal })
  }

  handleAddDetails = () => {
    const { addNote, imagesList, cameraImage } = this.state;
    const today = new Date().toISOString().split('T')[0];
    this.setState({isShowSingleTransactionModal:false,showModal: false,  isSave: true})
    let filesNew:any = [];
    const handleImageToBase64Promises = [];

    const processImage = (imageNew:any) => {
      return new Promise<void>((resolve, reject) => {
        if (typeof this.handleImageToBase64 === 'function') {
          this.handleImageToBase64(imageNew.uri)
            .then((base64String) => {
              filesNew.push({
                data: base64String,
                filename: imageNew.name,
              });
              resolve();
            })
            .catch((err) => {
              console.error('Error while converting image to base64:', err);
              reject(err);
            });
        } else {
          reject(new Error('handleImageToBase64 function is not defined or not a function.'));
        }
      });
    };

    if (imagesList && imagesList.length > 0) {
      for (const imageN of imagesList) {
        handleImageToBase64Promises.push(processImage(imageN));
      }
    } else if (cameraImage) {
      handleImageToBase64Promises.push(processImage(cameraImage));
    }
    Promise.all(handleImageToBase64Promises)
      .then(() => {
        this.addDetailsApiCaller(filesNew, addNote, today)
      })
      .catch((error) => {
        console.error('Error while converting images to base64:', error);
      });
  };

  addDetailsApiCaller = async(files: any, addNote: any, today: any) => {
    const token = await getStorageData("authToken")
    const data = {
      attributes: {
        name: this.state?.incomeData[this.state.activeIndex]?.name,
        amount: parseInt(this.state?.incomeData[this.state.activeIndex]?.amount),
        files: files,
        transaction_category_id: this.state.selectedCategory[0]?.id,
        notes: addNote,
        date: today,
        income_type: "capital",
        reimbursement: false,
      },
    };
    const httpBody = {
      data
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addIncomeTransactions}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSpiltTransaction = () => {
    const { imagesList, cameraImage } = this.state;
    this.state.remainingAmount<0
      ? displayToastAlert("error", "Split should be same as base amount")
      :  this.handlePressAddDetails()

     let files:any = [];
    const handleImageToBase64Promises = [];

    const processImage = (image:any) => {
      return new Promise<void>((resolve, reject) => {
        if (typeof this.handleImageToBase64 === 'function') {
          this.handleImageToBase64(image.uri)
            .then((base64String) => {
              files.push({
                data: base64String,
                filename: image.name,
              });
              resolve();
            })
            .catch((error) => {
              console.error('Error converting image to base64:', error);
              reject(error);
            });
        } else {
          reject(new Error('handleImageToBase64 function is not defined or not a function'));
        }
      });
    };

    if (imagesList && imagesList.length > 0) {
      for (const image of imagesList) {
        handleImageToBase64Promises.push(processImage(image));
      }
    } else if (cameraImage) {
      handleImageToBase64Promises.push(processImage(cameraImage));
    }

    Promise.all(handleImageToBase64Promises)
      .then(() => {
        this.addSplitTransactionApiCaller(files)
      })
      .catch((error) => {
        console.error('Error converting images to base64:', error);
      });
      this.setState({isEditSplit: true, isAddDetail: !this.state.isAddDetail,})
  };

  addSplitTransactionApiCaller = async(files: any) => {
    const token = await getStorageData("authToken")
    const updatedSplit = Object.keys(this.state.inputFieldData).map(name => ({
      name,
      transaction_category_id: 356,
      amount: (parseInt(this.state.inputFieldData[name], 10) || 0),
      notes: this.state.addNote,
    }));

    const remaining = this.state.remainingAmount;

    updatedSplit.push({
      name: 'remainingAmount',
      transaction_category_id: 356,
      amount: remaining,
      notes: this.state.addNote,
    });

    const data = {
      attributes: {
        income_id: 1210,
        transaction_category_id: this.state.selectedCategory[0]?.id,
        notes: this.state.addNote,
        income_type: "capital",
        files: files,
        split: updatedSplit,
      },
    };

    const httpBody = { data };
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addSplitTransactionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addSplitTransaction}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onClickDownArrow = () => {
    this.setState({ categoriesArray: this.state.allCategoryArray, isAllItem: true });
  }

  onPressAddToReport = async () => {
    this.setState({ isUndoAddToReport: true });
    setTimeout(() => {
      this.setState({ isUndoAddToReport: false })
    }, 5000)
    const data = {
      "attributes": {
        "reimbursement": false,
        "transaction_id": this.state.incomeData[this.state.activeIndex]?.id || "",
        "transaction_category_id": this.state.selectedCategory[0].id,
        "transaction_type": "income"
      }
    }
    const httpBody = {
      data
    }
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToReportApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categorizetransactionUpdate
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  }

  addToReportApiSuccessCallback = (responseJson: any) => {
    if (responseJson.data) {
      this.getIncomeDataApiCall();
    }
  }

  handleSingleTransactionModal = () => {
    if (!this.state.isSave) {
        this.setState({ 
            isShowSingleTransactionModal: !this.state.isShowSingleTransactionModal, 
            imagesList : [], 
            selectedIndex: null, 
            addNote: "" 
        });
        this.deleteCameraImage();
    } else {
        this.setState({ isShowSingleTransactionModal: !this.state.isShowSingleTransactionModal });
    }}

  handleSnapToItem = (index: any) => {
    this.setState({ activeIndex: index })
  }

  getExpense = async () => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToReportApiCallId = requestMessage.messageId;
    this.getExpenseCategoriesAPIEndPoint = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categorizetransactionUpdate
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expenseCategoryAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

handleAddtoReport = () => {
  Alert.alert("In Progress");
}
  // Customizable Area End
}

// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  Divider
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import BillAndSubSettingController from "./BillAndSubSettingController.web";
import { ReactComponent as VisaSvg } from '../assets/Visa.svg';
import Settings5Controller from './VerifyAccountController.web';
import { AmericanExpress, dinersCard, discoverCard, jcbCard, MasterCard, lockWithChecked,maestroCard,unionCard ,blank_Card} from "./assets";
import VerifyAccountWeb from "./VerifyAccount.web";
import ChangePasswordSettingsWeb from './ChangePasswordSettings.web'
import ChangePhoneSettingsWeb from './ChangePhoneSettings.web'
import ManagePaymentInfoWeb from "./ManagePaymentInfo.web";
import BillingDetailsWeb from "./BillingDetails.web";



const useStyles = (theme) => ({
  gridBox:{
    padding: "20px 0",
    
  },
  plainText:{
    color:"#3F4144",
    fontWeight:"bold",
    paddingBottom:10,
    fontSize:20,
    '@media (max-width:1425px)':{
       fontSize:15
        }
  },
  cancelButton:{
    width:217,
    height:28,
    background: "#AEAEAE",
    borderRadius: "14px",
    color: "#ffffff",
    fontSize:13,
    '@media (max-width:1425px)':{
        fontSize:9.75,
        width:162, 
        height:21,
         }
  },
  deleteButton:{
    width:217,
    height:28,
    background:  "#5597F4",
    borderRadius: "14px",
    color: "#ffffff",
    fontSize:13,
    '@media (max-width:1425px)':{
        fontSize:9.75,
        width:162, 
        height:21,
         }
  },
  emailText:{
    fontSize:16,
    fontWeight:600,
    color:"#3F4144",
    '@media (max-width:1425px)':{
        fontSize:12
         }
  },
  text:{
    fontSize:16,
    // fontWeight:400,
    color:"#AEAEAE",
    '@media (max-width:1425px)':{
        fontSize:12
         }
  },
  link:{
    color:"#5696F4",
    fontSize:16,
    fontWeight:400,
    textAlign:"right",
    underline:"none",
    '@media (max-width:1425px)':{
        fontSize:12
         }
  },
  borderBottom:{
    borderBottom:"1px solid #AEAEAE",
    padding:"0px 0px 20px 0px"
  },
  borderTop:{
    padding:"20px 0px 0px 0px"
  },
  logo:{
    border: "1px solid #000000",
    padding: "5px 5px 5px 5px",
    width:"65%",
    height:"50%"
  },
  paddingBottom:{
    paddingBottom:10
  },
  yellowText:{
    color:"#FF9933",
    fontWeight:600,
    fontSize:16,
    '@media (max-width:1425px)':{
        fontSize:12
         }
  },
  borderYwBox:{
    border:"1px solid #FF9933",
    borderRadius:4,
    padding:10
  },
  blueText:{
    color:"#5696F4",
    fontWeight:400,
    fontSize:16,
    '@media (max-width:1425px)':{
        fontSize:12
         }
  },
  borderBlBox:{
    border:"1px solid #5696F4",
    borderRadius:4,
    padding:10
  },
  SubText:{
    color:"#AEAEAE",
    fontWeight:"bold",
    fontSize:22,
    '@media (max-width:1425px)':{
        fontSize:16
         }
  },
  subPrice:{  
    fontWeight:"bold",
    color:"black",
    fontSize:24,
    '@media (max-width:1425px)':{
        fontSize:18
         }
  },
  logos:{
    width:"53px",
    height:"27px",
    '@media (max-width:1425px)':{
      width:"39.75px",
      height:"20.25px",
        }
  },
  button:{
    borderRadius: "30px",
    backgroundColor: "#5597f4",
    color: "white",
    height: "30px",
    fontSize: "13px",
    fontWeight: 700,
    padding: "5px 20px",
    '&:hover': {
      backgroundColor: "#5597f4"
    },
    '@media (max-width:1425px)': {
      fontSize: "9.75px",

    },
  }
  
})
class BillAndSubPaymentWeb extends  Settings5Controller{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" >
            <Grid item container xs={12} lg={12} md={12} sm={12} justifyContent="center" style={{borderBottom:"1px solid #3F4144"}}>
                <Grid item container lg={11} md={11} sm={11} xs={11} className={classes.gridBox} >
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{paddingBottom:20}} >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography className={classes.plainText}>
                                Membership & Billing
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={11} sm={12} xs={12}>
                            <Link to="/Dashboard/companysettings/cancelmembership">
                            <Button variant="contained" className={classes.button}>
                            {this.state.status ==="active" ? "cancel subscription" : "Purchase subscription"}
                            </Button>
                            </Link>
                    
                            <Grid item lg={12} md={11} sm={12} xs={12} style={{marginTop:'5%'}}>
                                <Link to={"/Dashboard/companysettings/deleteaccount/"}>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => {
                                            console.log("This is working");
                                        }}
                                    >
                                        DELETE ACCOUNT
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item container lg={8} md={8} sm={12} xs={12}>
                        <Grid item container lg={12} md={12} sm={12} xs={12}className={classes.borderBottom}>
                            <Grid item lg={6} md={6} xs={6} sm={6} >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography className={classes.emailText}>
                                        {this.state.userAccount?.attributes?.email ? this.state.userAccount.attributes?.email: "no Email provided"}
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} >
                                    <Typography className={classes.text}>
                                        Password: ***********
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} >
                                    <Typography className={classes.text}>
                                    Phone:{this.state.userAccount?.attributes?.full_phone_number ? this.state.userAccount?.attributes?.full_phone_number : "No phone number provided"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} xs={6}  sm={6}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div onClick={()=>{navigation.navigate("billVerifyAccount")}} style={{textDecoration:"none",cursor:"pointer"}}>
                                        <Typography className={classes.link}>
                                            Change Email
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={12}md={12} sm={12} xs={12}>
                                <div onClick={()=>{navigation.navigate("ChangePasswordSettings")}} style={{textDecoration:"none",cursor:"pointer"}}>
                                    <Typography  className={classes.link}>
                                    Change Password
                                    </Typography>
                                </div>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div onClick={()=>{navigation.navigate("ChangePhoneSettings")}} style={{textDecoration:"none",cursor:"pointer"}} >
                                    <Typography className={classes.link}>
                                    Change Phone Number
                                    </Typography>
                                 </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  container lg={12} md={12} sm={12} xs={12}  className={classes.borderTop}>
                        <Grid item lg={6} md={6} xs={6} sm={6}>
                                <Grid item container spacing={1} lg={12} md={12} xs={12} sm={12}className={classes.paddingBottom}>
                                    <Grid item xs={12} lg={3} md={3} sm={3}  >
                                    {this.state.cardData[0]?.attributes.card_type=="masterCard"?
                                    <img src={MasterCard} className={classes.logos}/>:
                                    this.state.cardData[0]?.attributes.card_type=="discover"?
                                    <img src={discoverCard} className={classes.logos}/>:
                                    this.state.cardData[0]?.attributes.card_type=="americanExpress"?
                                    <img src={AmericanExpress} className={classes.logos}/>:
                                    this.state.cardData[0]?.attributes.card_type=="visa"?
                                    <VisaSvg className={classes.logos}/>:
                                    this.state.cardData[0]?.attributes.card_type=="diners"?
                                    <img src={dinersCard} className={classes.logos}/>:
                                    this.state.cardData[0]?.attributes.card_type=="maestro"?
                                    <img src={maestroCard} className={classes.logos}/>:
                                    this.state.cardData[0]?.attributes.card_type=="unionPay"?
                                    <img src={unionCard} className={classes.logos}/>:
                                    this.state.cardData[0]?.attributes.card_type=="jcb"?
                                    <img src={jcbCard} className={classes.logos}/>: <img src={blank_Card} className={classes.logos}/>
                                    }
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={9}>
                                        <Typography className={classes.emailText}>
                                          {this.state.cardData[0]?.attributes?.card_number ?this.state.cardData[0]?.attributes?.card_number: "**** **** **** 0000"}
                                        </Typography>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} >
                                    {this.state.expires?
                                    <Typography className={classes.text}>
                                        
                                    Your next billing date is { this.state.getUserSub?.data[0]?.attributes?.expires_on? moment(this.state.getUserSub?.data[0]?.attributes?.expires_on).format('MM-DD-YYYY'):"Month, Day Year"}
                                </Typography>: <Typography className={classes.text}>
                                        
                                        Your next billing date is Month, Day Year
                                    </Typography>}
                                </Grid>
                            
                            </Grid>
                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                <Grid item lg={12} md={12} sm={12} xs={12}className={classes.paddingBottom}>
                                <div onClick={()=>{navigation.navigate("ManagePaymentInfo")}} style={{textDecoration:"none",cursor:"pointer"}}>

                                    <Typography className={classes.link}>
                                        Manage Payment Info
                                    </Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} >
                                    <div onClick={()=>{navigation.navigate("BillingDetails")}} style={{textDecoration:"none",cursor:"pointer"}} >

                                        <Typography className={classes.link}>
                                        Billing Details
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12} lg={12} md={12} sm={12} justifyContent="center" >
                <Grid item container lg={11} md={11} sm={11} xs={11} className={classes.gridBox} >
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography className={classes.plainText}>
                                Subscription
                            </Typography>
                        </Grid>
                    </Grid>                    
                    <Grid item container lg={8} md={8} sm={8} xs={12}>
                        <Grid item container lg={12} md={12} sm={12} xs={12}spacing={3} >

                            {this.state.allPlans.map((item:any)=>
                                <Grid item lg={6} md={6} xs={12} sm={6} >
                                <Grid item lg={12} md={12} sm={12} xs={12}>

                                    {item?.attributes?.is_active===true?<Typography className={item?.attributes?.is_active ===true?classes.yellowText:classes.blueText}>
                                        SUBSCRIBED
                                    </Typography>:<Typography className={item?.attributes?.is_active ===true?classes.yellowText:classes.blueText}>
                                        {item?.id=="1"?" Switch to monthly billing?": "Switch to Annually billing?"}
                                    </Typography>}
                                   
                                </Grid>
                                <Grid className={item?.attributes?.is_active ===true?classes.borderYwBox:classes.borderBlBox}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                    {item?.attributes.name ==="Best Value"?
                                        <Typography className={classes.SubText}>
                                        Annually
                                    </Typography>:
                                       <Typography className={classes.SubText}>
                                       Monthly
                                   </Typography>
                                        }
                                        
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        {item?.attributes.name ==="Best Value"?<Typography className={classes.subPrice}>
                                            ${item?.attributes?.price}/year
                                        </Typography>:
                                        <Typography className={classes.subPrice}>
                                            ${item?.attributes?.price}/mo
                                        </Typography>
                                        }
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <Typography className={classes.text}>
                                        + applicable taxes
                                        </Typography>
                                    </Grid>
                                </Grid>
                                </Grid>
                            )}
                            {/* <Grid item lg={6} md={6} xs={12} sm={6} >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography className={classes.yellowText}>
                                        SUBSCRIBED
                                    </Typography>
                                </Grid>
                                <Grid className={classes.borderYwBox}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <Typography className={classes.SubText}>
                                            Monthly
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <Typography className={classes.subPrice}>
                                            $0.00/mo
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <Typography className={classes.text}>
                                        + applicable taxes
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} sm={6}>
                                <Grid item lg={12}>
                                    <Typography className={classes.blueText}>
                                        Switch to annually billing?
                                    </Typography>
                                </Grid>
                                <Grid className={classes.borderBlBox}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography className={classes.SubText}>
                                            Annually
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <Typography className={classes.subPrice}>
                                            $0.00/year
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <Typography className={classes.text}>
                                        + applicable taxes
                                        </Typography>
                                    </Grid>

                                </Grid>
                                
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
  }
}

export default withStyles(useStyles)(BillAndSubPaymentWeb)
// Customizable Area End
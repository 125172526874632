import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import { scaledSize } from '../../framework/src/Utilities';
type MyProps = { testID:string, theme: "primary" | "secondary"; text: string, textStyle: TextStyle, style: StyleProp<ViewStyle> | undefined, disabled?: boolean, onPress: () => void };
type MyState = { theme: "primary" | "secondary", text: string };

export default class Button extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string, 
    theme: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textStyle: PropTypes.object,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onPress: PropTypes.func.isRequired
  };

  constructor(props: any) {
    super(props);

    this.state = {
      theme: this.props.theme,
      text: this.props.text
    };
  }

  render() {
    const { testID, style, theme, textStyle, onPress, disabled } = this.props;
    return (
        <TouchableOpacity testID={testID} style={[styles.touchableStyle, {backgroundColor: theme === "secondary" ? "#3876F1" : "#B3B3B3"}, style]} onPress={onPress} disabled={disabled}>
            <Text style={[styles.btnTextStyle, textStyle]}>{this.props.text}</Text>
        </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
    touchableStyle: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: scaledSize(15),
        paddingVertical: scaledSize(10)
    },
    btnTextStyle: {
        fontSize: scaledSize(16),
        fontFamily: "OpenSans-Bold",
        fontWeight: "700",
        color: "#ffffff",
        textAlign: 'center'
    },
})

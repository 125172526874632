// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  InputBase,
  Divider,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Settings5Controller from './VerifyAccountController.web';
import { lockWithCheckedblue } from "./assets";
import {ReactComponent as EnvelopeSvg} from '../assets/Envelope.svg';
import { CardPng } from "./assets";
import lock from "../assets/lock.png";

const useStyles = (theme) => ({
 
  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    '@media (max-width:1425px)':{
        fontSize:21.75
         }
  },
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    margin:"10px 0 40px 0",
    '@media (max-width:1425px)':{
        fontSize:13.5
         }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  EnvelopeSvg:{
    width:76,
    height:48,
    paddingBottom:10,
    '@media (max-width:1425px)':{
        width:57,
        height:36,
         }
  },
  verifyButton:{
    width:163,
    height:28,
    color:"white",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0",
    marginTop:25,
  },
  padding:{
    paddingTop:80
  },
  input: {
    padingBottom:0,
    marginBottom:0,
    marginLeft: "10px",
    '&::placeholder': {
        //textOverflow: 'ellipsis !important',
        color: '#3f4144',
        opacity: 1,
        fontWeight: "700",
        fontFamily: "Open Sans",
        fontSize: "20px",
        '@media (max-width:1425px)': {
            fontSize: "15px",
        },
        

    }
},
textField:{
    margin:"50px 0 70px 0"
},
currentEmailInput:{
    padingBottom:0,
    marginBottom:0,
    marginLeft: "10px",
    '&::placeholder': {
        //textOverflow: 'ellipsis !important',
        color: '#AEAEAE',
        opacity: 1,
        fontWeight: "700",
        fontFamily: "Open Sans",
        fontSize: "20px",
        '@media (max-width:1425px)': {
            fontSize: "15px",
        },
    }
},
formBtn: {
        
    backgroundColor: "#5597F4",
    borderRadius: "30px",
    width: 106,
    "&:hover": {
        backgroundColor: "#5597F4",
    },
    "& p": {
        fontWeight: "bold",
        color: "#ffffff"
    },
},
formBtnFont: {
    fontSize: "13px",
    fontWeight: "700",
    '@media (max-width:1425px)': {
        fontSize: "9.75px",
    },
},
  
})
class ChangeEmailSettingsWeb extends Settings5Controller{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
    
  }
  render() {
    // console.log(this.props, "this.props")
    const {classes } = this.props
    return (
        <form onSubmit={this.handleNewEmail}>
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" className={classes.padding}>

           <Grid item container lg={8} xs={8}  >
                <Grid item xs={12} lg={12}  className={classes.alignment}>
               <EnvelopeSvg className={classes.EnvelopeSvg}/>     
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                        Change Email
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment} >
                    <Typography className={classes.text}>
                   Enter and confirm your new email.
                    </Typography>
                </Grid>
                <Grid item container xs={12} >
                        <TextField
                        disabled
                            size="small"
                            label="Current Email"
                            name=" current email"
                            type="email"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                                        <img src={lock} height="15px" />
                                    </InputAdornment>
                                ),
                                classes: { input: classes.currentEmailInput }
                            }}
                            InputLabelProps={{
                                style: { color: '#AEAEAE',fontWeight:700,paddingLeft:45 },
                              }}
                            fullWidth
                            placeholder={this.state.userAccount.attributes?.email ?this.state.userAccount.attributes?.email: "noemail@gmail.com"}
                        />
                    </Grid>
                    <Grid item container xs={12} >
                        <TextField
                        required
                            className={classes.textField}
                            size="small"
                            name="new email"
                            type="email"
                            value={this.state.new_email}
                            onChange={this.handleEmailChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                                        <img src={lock} height="15px" />
                                    </InputAdornment>
                                ),
                                classes: { input: classes.input }
                            }}
                            fullWidth
                            placeholder="New Email"
                        />
                    </Grid>
                    <Grid item container xs={12}  className={classes.alignment}>
                        <Grid item xs={6} lg={4}md={4} sm={6}>
                            <Button
                                variant="contained"
                                className={classes.formBtn}
                                type="submit"
                            >
                                <Typography variant="body2" className={classes.formBtnFont}>save</Typography>
                            </Button>

                        </Grid>
                        <Grid item xs={6} lg={4}md={4} sm={6}>
                            <Button
                                variant="contained"
                                className={classes.formBtn}
                                style={{backgroundColor:"#AEAEAE"}}
                                onClick={this.handleEmailCancel}
                            >
                                <Typography variant="body2"  className={classes.formBtnFont}>cancel</Typography>
                            </Button>

                        </Grid>

                    </Grid>
           </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        </form>
    );
  }
}

export default withStyles(useStyles)(ChangeEmailSettingsWeb)
// Customizable Area End
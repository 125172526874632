//@ts-nocheck
import React, { Component } from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import moment from 'moment'
import checkBox from '../assets/Welcome To EFTPS - Enrollments.png'
import options from '../assets/EFTPS_options.png'
import BillAndSubSettingController from "./BillAndSubSettingController.web";

const useStyles = (theme) => ({
  mainDiv:{
    gap: "20px"
  },
  formDiv:{
    gap: "20px"
  },
  textField:{
    width: "100%",
    "& input":{
      fontSize: "20px",
      "&::placeholder":{
        color: "#000000"
      },
    },
  },
  textGrid:{
    gap: "20px"
  },
  pageHeader:{
    color: "#FF9C31",
    borderBottom: "3px solid #AEAEAE",
    fontWeight: '600',
    width: "130px",
    textAlign: "center"  
  },
  bottomDiv:{
    gap: "10px"
  },
  boldFont:{
    fontWeight: "600"
  },
  link:{
    textDecoration: "none",
    color: "#5597F4"
  },
  pageLink:{
    color: "#5696F4",
    fontWeight: "600",
    paddingLeft: "20px",
    textDecoration: "none",

  },
  redButton:{
    background: "#B82B2C",
    color: "#ffffff",
    borderRadius: 0,
    width:"127px",
    boxShadow: "inset 0 0 10px 3px #43515c",
  },
  grayButton:{
    background: "#777",
    color: "#fff",
    borderRadius: 0,
    width:"127px",
    boxShadow: "inset 0 0 10px 3px #43515c",
  },
  fontBold:{
    fontWeight: "600"
  },
  number:{
    width: "41px",
    height: '39px',
    borderRadius: "50%",
    background: "#FFD29C",
  },
  gapGrid:{
    gap:"20px"
  },
  circleParent:{
    position: "relative"
  },
  circleOver:{
    width:"144px",
    height: "40px",   
    position:"absolute",
    border: "3px solid #5696F4",
    borderRadius: "50%"
  },
  circleOver2:{
    width:"120px",
    height: "40px",   
    position:"absolute",
    border: "3px solid #5696F4",
    borderRadius: "50%",
    left: "70px",
    top: "35px"
  },
  returnButton:{
    padding: "5px 25px",
    borderRadius: "20px",
    background: "#5696F4",
    color: "#ffffff",
    textDecoration: 'none'
  },
  image:{
    width: "400px",
    height: "25px",
    padding: "10px 0"
  },
  image2:{
    height: "80px",
    width: "300px"
  },
  paddingGrid:{
    padding: "10px 0"
  },
  textGap:{
    gap: "15px"
  },
  font30:{
    fontSize: "30px"
  },
  font25:{
    fontSize:"25px"
  },
  font13:{
    fontSize: "13px"
  },
  font18:{
    fontSize: "18px"
  },
  font16:{
    fontSize: "12px"
  },
  font20:{
    fontSize: "20px"
  },
  font22:{
    fontSize: "22px"
  },
  font24:{
    fontSize: "24px"
  },
  '@media (max-width:1425px)': {
    font30:{
      fontSize: "22.5px"
    },
    font20:{
      fontSize: "15px"
    },
    font22:{
      fontSize: "16.5px"
    },
    font25:{
      fontSize:"18.75px"
    },
    font24:{
      fontSize:"18px"
    },
    font13:{
      fontSize: "9.75px"
    },
    font18:{
      fontSize: "13.5px"
    },
    font16:{
      fontSize: "12.75px"
    },
  }
})

class EFTPSSettingInstructionPageWeb extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
      <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.mainDiv}>
        <Grid item container xs={12} justifyContent="center" alignItems="center">
          <Grid item container xs={6} justifyContent='flex-end' alignItems="center" >
            <Typography className={`${classes.font30} ${classes.pageHeader}`}>EFTPS</Typography>
          </Grid>
          <Grid item container xs={4} justifyContent='flex-end' alignItems="center">
            <Link variant="contained" className={classes.returnButton} to="/Dashboard/companysettings/eftps">
              <Typography className={`${classes.font13} ${classes.fontBold}`}>
                RETURN
              </Typography>  
            </Link>
          </Grid>
          <Grid item container xs={12} justifyContent='center' alignItems="center">
              <Typography className={`${classes.font25} ${classes.fontBold}`}>New Tax Payer Enrollment</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.gapGrid}>
          <Grid item container xs={12}>
            <Grid item container xs={12} justifyContent="center">
              <Typography className={classes.font20}>To begin the EFTPS enrollment process go to
                <a className={classes.pageLink} target="_blank" href="https://www.eftps.gov/eftps/">www.eftps.gov/eftps/</a>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="space-evenly" alignItems="center">
            <Grid item container xs={5} justifyContent="flex-end">
              <Typography className={classes.font22}>Click</Typography>
              <Typography className={`${classes.font22} ${classes.boldFont}`}>"Enroll</Typography>
            </Grid>
            <Grid item container xs={6} direction="column" alignItems="flex-start">
              <Button >
                <Typography className={classes.grayButton} variant="caption">MAKE A PAYMENT</Typography>
              </Button>
              <Button className={classes.circleParent}>
                <Grid className={classes.circleOver}></Grid>
                <Typography className={classes.redButton} variant="caption">ENROLL</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} >
            <Grid item container xs={12} justifyContent="space-between" alignItems="flex-start" >
              <Grid item container xs={6} justifyContent="flex-end" className={classes.paddingGrid} alignItems="flex-start">
                <Grid item container xs={12} alignItems="fle" justifyContent="center" className={classes.numberGrid}>
                  <Grid item container xs={2}  alignItems="center" justifyContent="center">
                    <Grid item container justifyContent="center" alignItems="center" className={classes.number}>
                      <Typography className={`${classes.font24} ${classes.boldFont}`}>1</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={10} className={classes.textGap}>
                    <Typography className={`${classes.font22} ${classes.boldFont}`}>Start</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container xs={2}></Grid>
                  <Grid item container xs={10} className={classes.textGap}>
                    <Typography className={classes.font16}>Check the box to accept Privacy Act and Paperwork Reduction Act</Typography>
                    <img className={classes.image} src={checkBox}/>
                    <Typography className={classes.font16}>Click the box to enroll as "individual"</Typography>
                    <Grid className={classes.circleParent}>
                    <Grid className={classes.circleOver2}></Grid>
                      <img className={classes.image2} src={options}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end" className={classes.paddingGrid} alignItems="flex-start">
                <Grid item container xs={12} alignItems="center" justifyContent="center" className={classes.numberGrid}>
                  <Grid item container xs={2}  alignItems="center" justifyContent="center">
                    <Grid item container justifyContent="center" alignItems="center" className={classes.number}>
                      <Typography className={`${classes.font24} ${classes.boldFont}`}>2</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={10} className={classes.textGap}>
                    <Typography className={`${classes.font22} ${classes.boldFont}`}>Enroll</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} alignItems="flex-start">
                  <Grid item container xs={2}></Grid>
                  <Grid item container xs={10} className={classes.textGap} className={classes.textGap} alignItems="flex-start" >
                    <Typography className={classes.font16}>Enter Personal Information</Typography>
                    <Typography className={classes.font16}>Enter Contact Information</Typography>
                    <Typography className={classes.font16}>Enter Financial Information</Typography>
                    <Typography className={classes.font16}>Be sure to designate Checking or saving</Typography>
                    <Typography className={classes.font16}>for the account you would like payments drafted from</Typography>
                    <Typography className={classes.font16}>Click "
                      <Typography display="inline" variant="body2" className={`${classes.font16} ${classes.boldFont}`}>Review</Typography>"
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end" className={classes.paddingGrid} alignItems="flex-start">
                <Grid item container xs={12} alignItems="center" justifyContent="center" className={classes.numberGrid}>
                  <Grid item container xs={2}  alignItems="center" justifyContent="center">
                    <Grid item container justifyContent="center" alignItems="center" className={classes.number}>
                      <Typography className={`${classes.font24} ${classes.boldFont}`}>3</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={10} className={classes.textGap}>
                    <Typography className={`${classes.font22} ${classes.boldFont}`}>Review</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container xs={2}></Grid>
                  <Grid item container xs={10} className={classes.textGap} alignItems="flex-start">
                    <Typography className={classes.font16}>Review the information you have entered</Typography>
                    <Grid>
                      <Typography className={classes.font16}>At the bottom of the page, fill out the</Typography>
                      <Typography className={classes.font16}>Authorization/Electronic Signature Section</Typography>
                    </Grid>
                    <Typography className={classes.font16}>Check the box to authorization agreements</Typography>
                    <Typography display="inline" className={classes.font16}>Click "
                      <Typography display="inline" variant="body2" className={`${classes.font16} ${classes.boldFont}`}>Complete</Typography>"
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end" className={classes.paddingGrid} alignItems="flex-start">
                <Grid item container xs={12} alignItems="center" justifyContent="center" className={classes.numberGrid}>
                  <Grid item container xs={2}  alignItems="center" justifyContent="center">
                    <Grid item container justifyContent="center" alignItems="center" className={classes.number}>
                      <Typography className={`${classes.font24} ${classes.boldFont}`}>4</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={10} className={classes.textGap}>
                    <Typography className={`${classes.font22} ${classes.boldFont}`}>Complete</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container xs={2}></Grid>
                  <Grid item container xs={10} className={classes.textGap}>
                    <Typography className={classes.font16}>Print this page and save for you records</Typography>
                    <Typography className={classes.font16}>In the next 7 days, you will receive a PIN in the mail with</Typography>
                    <Typography className={classes.font16}>further instructions to create an EFTPS password.</Typography>
                    <Typography className={classes.font16}>Enter the PIN you receive in the mail and the EFTPS</Typography>
                    <Typography className={classes.font16}>password you create on the previous page to save</Typography>
                    <Typography className={classes.font16}>your EFTPS account.</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(EFTPSSettingInstructionPageWeb)


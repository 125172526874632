//@ts-nocheck
import React from "react";
import GlobalSettingController from "./GlobalSettingController.web";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Divider,
  TextField,
  Select,
  MenuItem,
  NativeSelect,
  Snackbar
} from "@material-ui/core";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Routes from "./Router";
import { widthPercentageToDP } from "react-native-responsive-screen";
import { borderRadius } from "react-select/src/theme";
import moment from "moment";
import DatePicker from 'react-date-picker';
import Alert from '@material-ui/lab/Alert';

// import { Divider } from "react-native-elements";

const DATA = [
  {
    name: "Capital Distributions",
    price: "0",
  },
  {
    name: "Cost of Goods Sold",
    price: "100",
  },
  {
    name: "Advertising",
    price: "100",
  },
];

const useStyles = (theme) => ({
  root: {
    overflowX: "hidden",
    position: "relative",
  },
  boldText: {
    color: "#000000",
    paddingLeft: "20px",
  },
  topDiv: {
    backgroundColor: "#F5F5F5",
    height: "40px",
    paddingLeft: "20px",
  },
  textBold: {
    fontWeight: "bold",
  },
  activeLink: {
    borderRadius: "20px",
    border: "2px solid #FFBB78",
  },
  link: {
    color: "#000",
    textDecoration: "none",
  },
  linkButton: {
    fontWeight: "bold",
    borderRadius: "20px",
    padding: "1px 15px",
    fontSize: "14px",
  },
  lBlue: {
    color: "#2a1d77",
    fontWeight: "300",
  },
  dBlue: {
    color: "#2a1d77",
  },
  logo: {
    width: "100px",
    marginBottom: "25px",
  },
  navBar: {
    boxShadow: "none",
  },
  wIcon: {
    fill: "#ffffff",
  },
  navImg: {
    backgroundColor: "#ffffff",
    border: "1px solid #ffffff",
    width: "25px",
    margin: "0 10px",
    height: "25px",
    borderRadius: "50%",
  },
  horizontalBar: {
    backgroundImage: "linear-gradient(to right, #15066A , #5696F4)",
    padding: "10px 2px",
  },
  verticalBar: {
    height: "100vh",
    position: "sticky",
    backgroundColor: "#ffffff",
    zIndex: 1,
  },
  navGrid: {
    borderRight: "1px solid #DADADA",
  },
  notificationNumber: {
    color: "#ffffff",
    fontSize: "11px",
    backgroundColor: "#000000",
    width: "20px",
    height: "20px",
    borderRadius: "10px 0",
    position: "absolute",
    right: "-6px",
    top: "-10px",
  },
  notification: {
    position: "relative",
    padding: "0 5px",
  },
  navLink: {
    display: "flex",
    width: "100%",
    textDecoration: "none",
    alignItems: "center",
    padding: "10px 0",
    border: "1px solid #EBEBEB",
  },
  activeNavLink: {
    backgroundColor: "#5696F4",
    "& h6": {
      color: "#ffffff",
    },
  },
  iconStyle: {
    width: "20px",
    height: "20px",
    fill: "#ffffff",
    "& path": {
      fill: "#ffffff !important",
    },
  },
  leafTL: {
    width: "30px",
    height: "30px",
    borderRadius: "0 12px",
    margin: "2px",
  },
  leafTR: {
    width: "30px",
    height: "30px",
    borderRadius: "12px 0",
    margin: "2px",
  },
  leafs: {
    position: "absolute",
    bottom: "15px",
    left: "50%",
    transform: "translateX(-35%)",
  },
  iconLeaf: {
    border: ".5px solid #ffffff",
    borderRadius: "0 12px",
  },
  white: {
    backgroundColor: "#ffffff",
  },
  lBlue: {
    backgroundColor: "#B5D8FD",
  },
  dBlue: {
    backgroundColor: "#5696F4",
  },
  orange: {
    backgroundColor: "#FF9933",
  },
  buttonLayout: {
    backgroundColor: "#5597f4",
    borderRadius: "30px",
    color: "white",
    // height: "25px",
    fontSize: '13px',
    fontWeight: 'bold',
    '@media (max-width:1425px)': {
      fontSize: '9.75px',
      fontWeight: 'bold',
      padding: "3.75px 30px",

    },
    padding: "5px 40px",
    "&:hover": {
      backgroundColor: "#5597f4",
    },
  },
  divider: {
    width: "98%",
    height: "2px",
  },
  mileageTextField: {
    width: "100%",
    height: "30px",
        // backgroundColor: "#ececec",

  },
  input: {
    color: "#000000",
    // lineHeight: "26px",
    paddingRight: '10px',
    fontSize: "22px",
    textAlign: 'right',
    '@media (max-width:1425px)':{
      fontSize:'16.5px'
    }
  },
  qinput: {
    color: "#000000",
    // lineHeight: "20px",
    padding: "2px 10px",
    fontWeight: "300",
    fontSize: "20px",
    textAlign: 'right',
    '@media (max-width:1425px)':{
      fontSize:'15px'
    }
  },
  textFieldLabel: {
    color: "red !important",
  },
  transactionTextField: {
    width: "100%",
    height: "30px",
    // backgroundColor: "#ececec",
  },
  taxFields: {
    width: "150px",
    backgroundColor: "#ececec",
    borderRadius: "5px",
    color: "black",
    padding: "0px",
  },
  selectYears: {
    width: "100px",
    fontWeight: "bold",
    fontSize: "22px",
    '@media (max-width:1425px)':{
      fontSize:'16.5px'
    }
    },
  upperHeadRow: {
    fontWeight: "bold",
    height: "95px",
    fontSize: "15px",
    textAlign: "center",
    lineHeight: "20px",
    '@media (max-width:1425px)': {
      fontSize: '11.25px',
    },
  },
  lowerHeadRow: {
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "right",
    '@media (max-width:1425px)': {
      fontSize: '11.25px',
    },
  },
  formDate: {
    "& div": {
      // minWidth: 0,
      padding: theme.spacing(0.42),
      // paddingRight: theme.spacing(3),
      // borderRadius: "4px",
      // borderColor: "#bcbcbc",
      border: "none !important",

      // minWidth: "135px",
      // height: "26px",
      backgroundColor: "#ececec",
      borderRadius: "8px",
      // marginBottom: "6px",

    },
  },
  boldHeadings: {
    fontSize: "22px",
    fontWeight: "600",
    '@media (max-width:1425px)':{
      fontSize:'16.5px'
    }
  },
  textOnly:{
    fontSize: '22px',
    fontWeight: '300',
    '@media (max-width:1425px)':{
      fontSize:'16.5px'
    }
  },
  headingGS:{
    fontWeight: "600", 
    fontSize: "25px",
    '@media (max-width:1425px)':{
      fontSize : '18.75px'
    }
  },
  taxTablesSubHeading: {
    fontSize: '22px',
    fontWeight: '400',
    '@media (max-width:1425px)':{
      fontSize:'16.5px'
    }
  },
  taxesInput:{
    fontSize: "22px",
    border: "1px solid black",
    paddingRight: "20px !important",
    fontWeight:"300",
    '@media (max-width:1425px)':{
      fontSize:'16.5px'
    }
  },
});

const StyledTableCell = withStyles((theme) => ({
  // root: {
  //   padding: 0,
  //   paddingLeft: "15px"
  // },
  // head: {
  //   borderRight: '1px solid rgba(224, 224, 224, 1)',
  // },
  body: {
    fontSize: "22px",
    border: "1px solid black",
    paddingRight: "20px",
    '@media (max-width:1425px)':{
      fontSize:'16.5px'
    }
  }
}))(TableCell);
class GlobalSetting extends GlobalSettingController {

  getBorderForTransaction = (index: any) => {
    if (this.state.transactions?.length == index + 1) {
      return { border: '1px solid black', borderLeft: 0 }
    } else {
      return { border: '1px solid black', borderLeft: 0, borderBottom: 0 }
    }
  }

  getBorderForTransactionName = (index: any) => {
    if (this.state.transactions?.length == index + 1) {
      return { border: '1px solid black', paddingLeft: '10px', }
    } else {
      return { border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container direction="column" className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        <Grid
          item
          container
          style={{ backgroundColor: "#ececec", height: "69px" }}
        >
          <Grid container xs={11} justifyContent="space-between"
            alignItems="center" style={{ marginLeft: '40px' }}>
            <Grid item>
              <Typography
                variant="h5"
                className={classes.headingGS}
              >
                Global Settings
              </Typography>
            </Grid>

            <Grid item>
              <Button
                onClick={() => this.setEdit(true)}
                className={classes.buttonLayout}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          style={{ margin: "50px 0px 20px 40px" }}
        // justifyContent="space-between"
        // alignItems="center"
        >
          <Grid item xs={2} >
            <Typography variant="h6" className={classes.boldHeadings}>
              Mileage
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container >
              <Grid item xs={4} style={{ border: '1px solid black', paddingLeft: '10px' }}>
                <Typography  className={classes.textOnly}>IRS Mileage Rate</Typography>
              </Grid>
              <Grid item xs={2} style={{ border: '1px solid black', borderLeft: 0 }}>
                <TextField
                  disabled={!this.state.isEdit}
                  type='number'
                  className={classes.mileageTextField}
                  placeholder={`${this.state.mileage ? "$" + this.state.mileage : ""}`}
                  value={!this.state.isEdit ? "" : this.state.mileage}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ className: classes.textFieldLabel }}
                  inputProps={{
                    className: classes.input,
                  }}
                  onChange={(e) => {
                    this.setState({ mileage: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Divider className={classes.divider} />
        </Grid>

        <Grid
          item
          container
          xs={12}
          // justifyContent="space-between"
          style={{ margin: "10px 40px" }}
        >
          <Grid item xs={2} >
            <Typography variant="h6" className={classes.boldHeadings}>
              Transactions
            </Typography>
          </Grid>

          <Grid item xs={10} direction="column">
            {this.state.transactions?.map((item, index) => (
              <Grid container key={item.name + item.id}>
                <Grid item xs={4} style={this.getBorderForTransactionName(index)}>
                  <Typography variant="h6" className={classes.textOnly}>{item.name}</Typography>
                </Grid>
                <Grid item xs={2} xs={2} style={this.getBorderForTransaction(index)
                }>
                  <TextField
                    type="number"
                    disabled={!this.state.isEdit}
                    className={classes.transactionTextField}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{
                      className: classes.input,
                    }}
                    placeholder={item.percentage}
                    value={!this.state.isEdit ? "" : item.percentage}
                    onChange={(event) => {
                      let arr = this.state.transactions.map(el => {
                        if (el.id == item.id) {
                          return Object.assign({}, item, {
                            percentage: event.target.value,
                            isEdited: true,
                          })
                        }
                        return el;
                      });
                      this.setState({ transactions: arr });
                    }}
                  // placeholder={item?.percentage ? item.percentage : ""}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>


        </Grid>

        <Grid item container justifyContent="flex-end">
          <Divider className={classes.divider} />
        </Grid>

        <Grid container style={{ margin: "10px 40px" }}>
          <Grid item xs={2}>
            <Typography variant="h6" className={classes.boldHeadings}>
              Taxes
            </Typography>
          </Grid>

          <Grid item container xs={10} direcrtion="column">
            <Grid item container style={{ margin: "10px 0" }}>
              <Grid item xs={3}>
                <Typography variant="h6" className={classes.boldHeadings}>
                  Tax Year
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="h6" className={classes.boldHeadings}>
                  Federal Tax Calculation Method
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="flex-start"
              style={{ margin: "10px 0" }}
            >
              <Divider style={{ width: "90%", height: "2px" }} />
            </Grid>

            <Grid item container style={{ marginBottom: '20px' }}>
              <Grid item xs={3}>
                <NativeSelect
                  disableUnderline
                  className={classes.selectYears}
                  defaultValue={this.state.selectedYear}
                  // value={this.state.selectedYear}
                  onChange={(e) => {
                    this.setState({ selectedYear: e.target.value }, () => {
                      this.getGlobalSettings()
                      this.createSelfEmploymentTax()
                    })
                  }}
                >
                  <option value={2021}>2021</option>
                  <option value={2022}>2022</option>
                  <option value={2023}>2023</option>
                </NativeSelect>{" "}
              </Grid>

              <Grid item xs={9}>
                <Typography variant="h6" className={classes.boldHeadings}>
                  Percentage Method Tables
                </Typography>
              </Grid>
            </Grid>


            <Grid item container xs={10} direcrtion="column">

              <TableContainer style={{ overflowX: 'hidden' }}>
                <Table
                  // className={classes.table}
                  size="small"
                  aria-label="spanning table"
                  className={classes.newTable}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        // padding="none"
                        align="center"
                        colSpan={2}
                        className={classes.upperHeadRow}

                        style={{ backgroundColor: "#5696F4", width: '40%' }}
                      >
                        If the Adjusted Wage $ amount is:
                      </TableCell>
                      {/* <TableCell
                        // padding="none"
                        align="center"
                        // colSpan={2}
                        className={classes.upperHeadRow}

                        style={{ backgroundColor: "#5696F4", width: '20%' }}
                      > {" "}
                      </TableCell> */}

                      <TableCell
                        // padding="none"
                        // colSpan={1}
                        className={classes.upperHeadRow}
                        style={{ backgroundColor: "#7FB1FB", width: '20%' }}
                      >
                        The tentative amount to withhold is:
                      </TableCell>
                      <TableCell
                        // padding="none"
                        // colSpan={1}

                        className={classes.upperHeadRow}
                        style={{ backgroundColor: "#AECBF6", width: '20%' }}
                      >
                        Percent used to calculate tax on the $ amount over
                        Adjusted Wage Base:
                      </TableCell>
                      <TableCell
                        // padding="none"
                        // colSpan={1}

                        className={classes.upperHeadRow}
                        style={{ backgroundColor: "#D7E5F8", width: '20%' }}
                      >
                        Adjusted Wage Base:
                      </TableCell>
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    <TableRow style={{ marginTop: "10px" }}>
                      <TableCell
                        className={classes.lowerHeadRow}
                        style={{ backgroundColor: "#5696F4", width: "20%" }}
                      >
                        At least
                      </TableCell>
                      <TableCell
                        className={classes.lowerHeadRow}
                        style={{ backgroundColor: "#5696F4" }}
                      >
                        But Less Than
                      </TableCell>
                      <TableCell
                        className={classes.lowerHeadRow}
                        style={{ backgroundColor: "7FB1FB" }}
                      >
                        Base Tax
                      </TableCell>
                      <TableCell
                        className={classes.lowerHeadRow}
                        style={{ backgroundColor: "#AECBF6" }}
                      >
                        %
                      </TableCell>
                      <TableCell
                        className={classes.lowerHeadRow}
                        style={{ backgroundColor: "#D7E5F8" }}
                      >
                        $
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={5} align="center" style={{
                        fontWeight: '400'
                      }}>
                        Married Filing Jointly
                      </StyledTableCell>
                    </TableRow>
                    {this.state.marriedTaxes?.map(e => (
                      <TableRow key={e.id}>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.at_least}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.less_than}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.base_tax}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          {e.percentage}%
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.wage_base}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <StyledTableCell colSpan={5} align="center" style={{
                        fontWeight: '400'
                      }}>
                        Single Married Filing Separately
                      </StyledTableCell>
                    </TableRow>
                    {this.state.singleTaxes?.map(e => (
                      <TableRow key={e.id}>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.at_least}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.less_than}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.base_tax}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          {e.percentage}%
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.wage_base}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <StyledTableCell colSpan={5} align="center" style={{
                        fontWeight: '400'
                      }}>
                        Head of Household
                      </StyledTableCell>
                    </TableRow>
                    {this.state.householdTaxes?.map(e => (
                      <TableRow key={e.id}>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.at_least}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.less_than}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.base_tax}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          {e.percentage}
                          %
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          padding="none"
                          className={classes.taxesInput}
                        >
                          $
                          {e.wage_base}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end" style={{
          marginTop: '20px'
        }}>
          <Divider className={classes.divider} />
        </Grid>

        <Grid item container xs={11} style={{ margin: "20px 40px" }}>
          <Grid item xs={2}>
            <Typography
              variant="h6"
              className={classes.boldHeadings}
            >
              Tax Tables
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={10}
            // justifyContent="space-between"
            style={{}}
          >

            {/* Employment Tax start >>> */}
            <Grid container style={{ marginBottom: '20px' }}>
              <Grid item container xs={12} direction="row">
                <Grid item xs={3}>
                  <Typography className={classes.taxTablesSubHeading}>
                    Employment Tax
                  </Typography>
                </Grid>

                <Grid xs={9} container direction="row">
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }}>
                    <Typography variant="h6" className={classes.textOnly}>Social Security Tax Rate </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                    <TextField
                      type='number'
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.social_tax ? this.state.social_tax + "%" : ""}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        className: classes.input,
                      }}
                      value={!this.state.isEdit ? "" : this.state.social_tax}
                      onChange={(e) => {
                        this.setState({ social_tax: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} direction="row">
                <Grid item xs={3}>
                  {/* <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Employment Tax
                  </Typography> */}
                </Grid>

                <Grid xs={9} container direction="row">
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }}>
                    <Typography variant="h6" className={classes.textOnly}>Medicare Tax Rate</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                    <TextField
                      type='number'
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.medicare_tax ? this.state.medicare_tax + "%" : ""}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        className: classes.input,
                      }}
                      value={!this.state.isEdit ? "" : this.state.medicare_tax}
                      onChange={(e) => {
                        this.setState({ medicare_tax: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} direction="row">
                <Grid item xs={3}>
                  {/* <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Employment Tax
                  </Typography> */}
                </Grid>

                <Grid xs={9} container direction="row">
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }}>
                    <Typography variant="h6" className={classes.textOnly}>Income Threshold--Social Security--up to</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                    <TextField
                      type='number'
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.income_theshold_social ? "$" + this.state.income_theshold_social : ""}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        className: classes.input,
                      }}
                      value={!this.state.isEdit ? "" : this.state.income_theshold_social}
                      onChange={(e) => {
                        this.setState({ income_theshold_social: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} direction="row">
                <Grid item xs={3}>
                  {/* <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Employment Tax
                  </Typography> */}
                </Grid>

                <Grid xs={9} container direction="row"
                >
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }}>
                    <Typography variant="h6" className={classes.textOnly}>Income Threshold--Medicare--no threshold</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                    <TextField
                      type='number'
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.income_theshold_medicare ? "$" + this.state.income_theshold_medicare : ""}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        className: classes.input,
                      }}
                      value={!this.state.isEdit ? "" : this.state.income_theshold_medicare}
                      onChange={(e) => {
                        this.setState({ income_theshold_medicare: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} direction="row">
                <Grid item xs={3}>
                  {/* <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Employment Tax
                  </Typography> */}
                </Grid>

                <Grid xs={9} container direction="row">
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px' }}>
                    <Typography variant="h6" className={classes.textOnly}>Total Employment Tax</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0 }}>
                    <TextField
                      type='number'
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.total_employment_tax ? this.state.total_employment_tax + "%" : ""}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        className: classes.input,
                      }}
                      value={!this.state.isEdit ? "" : this.state.total_employment_tax}
                      onChange={(e) => {
                        this.setState({ total_employment_tax: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <<< Employment Tax end */}





            {/* QBI start >>> */}
            <Grid container style={{ marginBottom: '20px' }}>
              <Grid item container xs={12} direction="row">

                <Grid item xs={3}>
                  <Typography className={classes.taxTablesSubHeading}>
                    QBI
                  </Typography>
                </Grid>

                <Grid xs={9} container direction="row">
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px' }}>
                    <Typography variant="h6" className={classes.textOnly}>Qualified Business Income </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0 }}>
                    <TextField
                      type='number'
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.qbi_value ? this.state.qbi_value + "%" : ""}
                      value={!this.state.isEdit ? "" : this.state.qbi_value}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{
                        className: classes.input,
                      }}
                      onChange={(e) => {
                        this.setState({ qbi_value: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            {/* <<< QBI end */}

            <Grid container style={{ marginBottom: '20px' }}>
              <Grid item container xs={12} direction="row">

                <Grid item xs={3}>
                  <Typography className={classes.taxTablesSubHeading}>
                    Estimated Tax Payments
                  </Typography>
                </Grid>

                <Grid xs={9} container direction="row">
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }}>
                    <Typography variant="h6" className={classes.textOnly}>Q1 Payment </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                    <TextField
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.q1 ? moment(this.state.q1).format('MMMM DD') : "yyyy-mm-dd"}
                      InputProps={{ disableUnderline: true }}
                      value={!this.state.isEdit ? "" : this.state.q1}
                      inputProps={{
                        className: classes.qinput,
                      }}
                      onChange={(e) => {
                        this.setState({ q1: e.target.value })
                      }}
                    />
                    {/* <DatePicker format="MM/dd/y"
                      // disabled={!this.state.isEdit}
                      value={new Date(this.state.q1)}
                      onChange={(value) => {
                        this.setState({
                          q1: new Date(value)
                        });
                      }} className={classes.formDate} calendarIcon={null} clearIcon={null}></DatePicker> */}
                  </Grid>
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }}>
                    <Typography variant="h6" className={classes.textOnly}>Q2 Payment </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                    <TextField
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.q2 ? moment(this.state.q2).format('MMMM DD') : "yyyy-mm-dd"}
                      InputProps={{ disableUnderline: true }}
                      value={!this.state.isEdit ? "" : this.state.q2}
                      inputProps={{
                        className: classes.qinput,
                      }}
                      onChange={(e) => {
                        this.setState({ q2: e.target.value })
                      }}
                    />
                  </Grid>
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px', borderBottom: 0 }}>
                    <Typography variant="h6" className={classes.textOnly}>Q3 Payment </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                    <TextField
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.q3 ? moment(this.state.q3).format('MMMM DD') : "yyyy-mm-dd"}
                      InputProps={{ disableUnderline: true }}
                      value={!this.state.isEdit ? "" : this.state.q3}
                      inputProps={{
                        className: classes.qinput,
                      }}
                      onChange={(e) => {
                        this.setState({ q3: e.target.value })
                      }}
                    />
                  </Grid>
                  <Grid item xs={7} style={{ border: '1px solid black', paddingLeft: '10px' }}>
                    <Typography className={classes.textOnly}>Q4 Payment </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ border: '1px solid black', borderLeft: 0 }}>
                    <TextField
                      disabled={!this.state.isEdit}
                      className={classes.mileageTextField}
                      placeholder={this.state.q4 ? moment(this.state.q4).format('MMMM DD') : "yyyy-mm-dd"}
                      InputProps={{ disableUnderline: true }}
                      value={!this.state.isEdit ? "" : this.state.q4}
                      inputProps={{
                        className: classes.qinput,
                      }}
                      onChange={(e) => {
                        this.setState({ q4: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </Grid>


        <Grid item container justifyContent="flex-end" style={{}}>
          <Divider className={classes.divider} />
        </Grid>


        <Grid item container xs={11} style={{ margin: "20px 40px" }}>
          <Grid item xs={3}><Typography
            variant="h6"
            className={classes.boldHeadings}
          >
            Self-Employment Tax
          </Typography></Grid>

          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={5} style={{ paddingLeft: '10px' }}>
                <Typography className={classes.taxTablesSubHeading}>
                  Self-Employment Tax
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ paddingLeft: '10px' }}>
                <Typography className={classes.taxTablesSubHeading}>
                  Parameters
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="columnn">
              <Grid item container>
                <Grid item xs={5} style={{ paddingLeft: '10px', border: '1px solid black', borderBottom: 0 }}>
                  <Typography
                    variant="h6"
                    className={classes.textOnly}
                  >
                    Self-Employment Tax Threshold
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                  <TextField
                    disabled={!this.state.isEdit}
                    type='number'
                    className={classes.mileageTextField}
                    placeholder={this.state.employment_tax_threshold ? "$" + this.state.employment_tax_threshold : ""}
                    value={!this.state.isEdit ? "" : this.state.employment_tax_threshold}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ className: classes.textFieldLabel }}
                    inputProps={{
                      className: classes.input,
                    }}
                    onChange={(e) => {
                      this.setState({ employment_tax_threshold: e.target.value })
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={5} style={{ paddingLeft: '10px', border: '1px solid black', borderBottom: 0 }}>
                  <Typography
                    variant="h6"
                    className={classes.textOnly}
                  >
                    Maximum subject to Social Security
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                  <TextField
                    disabled={!this.state.isEdit}
                    type='number'
                    className={classes.mileageTextField}
                    placeholder={this.state.max_subject_to_social_security ? "$" + this.state.max_subject_to_social_security : ""}
                    value={!this.state.isEdit ? "" : this.state.max_subject_to_social_security}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ className: classes.textFieldLabel }}
                    inputProps={{
                      className: classes.input,
                    }}
                    onChange={(e) => {
                      this.setState({ max_subject_to_social_security: e.target.value })
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={5} style={{ paddingLeft: '10px', border: '1px solid black', borderBottom: 0 }}>
                  <Typography
                    variant="h6"
                    className={classes.textOnly}
                  >
                    % subject to Self-Employment Tax
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                  <TextField
                    disabled={!this.state.isEdit}
                    type='number'
                    className={classes.mileageTextField}
                    placeholder={this.state.subject_to_self_employment_tax ? this.state.subject_to_self_employment_tax + "%" : ""}
                    value={!this.state.isEdit ? "" : this.state.subject_to_self_employment_tax}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ className: classes.textFieldLabel }}
                    inputProps={{
                      className: classes.input,
                    }}
                    onChange={(e) => {
                      this.setState({ subject_to_self_employment_tax: e.target.value })
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={5} style={{ paddingLeft: '10px', border: '1px solid black', borderBottom: 0 }}>
                  <Typography
                    variant="h6"
                    className={classes.textOnly}
                  >
                    Social Security %
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ border: '1px solid black', borderLeft: 0, borderBottom: 0 }}>
                  <TextField
                    disabled={!this.state.isEdit}
                    type='number'
                    className={classes.mileageTextField}
                    placeholder={this.state.social_security ? this.state.social_security + "%" : ""}
                    value={!this.state.isEdit ? "" : this.state.social_security}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ className: classes.textFieldLabel }}
                    inputProps={{
                      className: classes.input,
                    }}
                    onChange={(e) => {
                      this.setState({ social_security: e.target.value })
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={5} style={{ paddingLeft: '10px', border: '1px solid black' }}>
                  <Typography
                    variant="h6"
                    className={classes.textOnly}
                  >
                    Medicare %
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ border: '1px solid black', borderLeft: 0 }}>
                  <TextField
                    disabled={!this.state.isEdit}
                    type='number'
                    className={classes.mileageTextField}
                    placeholder={this.state.medicare ? this.state.medicare + "%" : ""}
                    value={!this.state.isEdit ? "" : this.state.medicare}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ className: classes.textFieldLabel }}
                    inputProps={{
                      className: classes.input,
                    }}
                    onChange={(e) => {
                      this.setState({ medicare: e.target.value })
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={11} container justifyContent="flex-end" style={{ margin: "50px 0px" }}>
          <Grid item>
            <Button className={classes.buttonLayout}
              onClick={() => {
                this.updateGlobalSettings()
                // this.setEdit(false)
              }}
            >Save</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(GlobalSetting);

//@ts-nocheck
import React from "react";
import DashboardController from "./DashboardController";
import {
    AppBar,
    Typography,
    Button,
    withStyles,
    Grid,
    NativeSelect,
    TextField,
    Box,
    Snackbar,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Divider
} from "@material-ui/core";
import Settings5Controller from "./Settings5Controller";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Scrollbars } from 'react-custom-scrollbars';
import StorageProvider from "../../../framework/src/StorageProvider.web";
import Alert from '@material-ui/lab/Alert';
import { date } from "yup";
import { Redirect } from "react-router-dom";

const useStyles = (theme) => ({
    border: {
        border: "1px solid",
    },
    typography: {
        color: "red"
        // [theme.breakpoints.up('sm')]: {
        //   fontSize: "12px",
        // },
        // [theme.breakpoints.up('md')]: {
        //   fontSize: "52px",

        // }
    },
    fontForTextField: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#AEAEAE",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },

    fullDisplay: {
        maxWidth: "100%",
        '& Typography': {
            color: "green"
        }
        // '@media (min-width:768px)': {
        //   //fontSize: '18px',
        //   //color: "red",
        // },

        // [theme.breakpoints.up('sm')]: {
        //   color: "red",
        // },
        // [theme.breakpoints.up('md')]: {
        //   color: "green"

        // }




        //height: "100%"
    },
    buttonLayout: {
        backgroundColor: "#5597f4",
        borderRadius: "30px",
        color: "white",
        height: "25px",
        fontSize: "13px",
        fontWeight: "700",
        '@media (max-width:1425px)': {
            fontSize: '9.75px',

        },
        '&:hover': {
            backgroundColor: "#5597f4"
        }
    },
    mainScreenHeader: {
        fontSize: "20px",
        fontWeight: "700",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "15px",
        }
    },
    emailHelperTxt: {
        fontSize: "12px",
        fontWeight: "400",
        color: "#AEAEAE",
        fontStyle: "italic",
        '@media(max-width:1425px)': {
            fontSize: "9px",
        }
    },
    mainTableHeader: {
        fontSize: "13px",
        fontWeight: "600",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "9.75px",
        }
    },
    addUserSelectUser: {
        fontSize: "16px",
        fontWeight: "700",
        color: "#3F4144",
        marginBottom: "20px",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },
    addUserRadioButtonMain: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },
    addUserRadioHelperText: {
        fontSize: "12px",
        fontWeight: "400",
        color: "#AEAEAE",
        '@media(max-width:1425px)': {
            fontSize: "9px",
        }
    },
    fontStyleSecondaryPart: {
        fontSize: "16px",
        fontWeight: "600",
        color: "#3F4144",
        '@media(max-width:1425px)': {
            fontSize: "12px",
        }
    },
    mainTableData: {
        fontSize: "13px",
        fontWeight: "600",
        color: "#AEAEAE",
        '@media(max-width:1425px)': {
            fontSize: "9.75px",
        }
    },
    marginP: {
        margin: "5 2"
    },
    headerUser: {
        textAlign: "center",
        fontWeight: "700",
        fontSize: "20px",
        color: "#3f4144",
        margin: "52 0",
        '@media (max-width:1425px)': {
            fontSize: '15px'

        },



    },
    textInsideBox1: {
        fontSize: "20px",
        '@media (max-width:1425px)': {
            fontSize: '15px'

        },
    },
    textInsideBox2: {
        fontSize: "17",
        '@media (max-width:1425px)': {
            fontSize: '12.75px'

        },
    },
    invitationContainerBox1: {
        height: "50px",
        width: "50px",
        '@media (max-width:1425px)': {
            height: "45px",
            width: "45px",

        },
    },
    inputPropsClass: {
        fontSize: "18px",
        paddingLeft: '15px',
        '@media (max-width:1425px)': {
            fontSize: "13.5px",
            paddingLeft: '15px'

        },
    }



})


class UserSettingsAdd extends Settings5Controller {
    async componentDidMount() {
        this.setState({ token: await StorageProvider.get('token') }),
            this.getAllUser()
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }


    render() {
        console.log(this.props);
        const { classes } = this.props;
        const array = [
            { name: 'Name', userType: 'Admin', email: 'useremail@example.com', status: 'Active', dateAdded: "MM/DD/YYYY", action: "edit" },
            { name: 'Name', userType: 'Basic', email: 'useremail@example.com', status: 'Active', dateAdded: "MM/DD/YYYY", action: "edit" },
            { name: 'Name', userType: 'Basic', email: 'useremail@example.com', status: 'Active', dateAdded: "MM/DD/YYYY", action: "edit" },
            { name: 'Name', userType: 'Basic', email: 'useremail@example.com', status: 'Active', dateAdded: "MM/DD/YYYY", action: "edit" },
            { name: 'Name', userType: 'Accountant', email: 'useremail@example.com', status: 'Active', dateAdded: "MM/DD/YYYY", action: "edit" },
        ]

        return (
            <div className="root">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.errorStatus}
                    onClose={() => {
                        this.setState({
                            errorStatus: false,
                            addAccountFinish: true,
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="error"
                        elevation={6} variant="filled"
                    >{this.state.errorMsg}</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.successStatus}
                    onClose={() => {
                        this.setState({
                            successStatus: false,
                            addAccountFinish: true,
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="success"
                        elevation={6} variant="filled"
                    >{this.state.successMessage}</Alert>
                </Snackbar>

                {this.state.addUserScreen1 ?
                    <Grid container direction="column" justifyContent="space-evenly" className={classes.fullDisplay}>
                        <Grid item xs={1} className={classes.fullDisplay}>
                            <Typography className={classes.headerUser}>Add New User</Typography>

                        </Grid>
                        <Box style={{ width: "85%", margin: "auto" }}>
                            <Grid item xs={5} className={classes.fullDisplay}>
                                <Typography className={classes.addUserSelectUser}>Select user type</Typography>
                                <Typography className={classes.addUserRadioButtonMain} style={{ marginBottom: "10px" }}>The count toward your user limit</Typography>
                                <FormControl>

                                    <RadioGroup
                                        aria-labelledby="addScreen1-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={this.state.addUser1RadioRole}
                                        onChange={(event) => { this.setState({ addUser1RadioRole: event.target.value }) }}
                                    >
                                        <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="admin" control={<Radio color="primary" />} label="Admin" />
                                        <FormLabel id="addScreen1-1-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }}>They can see and do anything. This includes sending money, changing passwords, and adding users. Not everyone should be an admin.</Typography></FormLabel>
                                        <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="basic" control={<Radio color="primary" />} label="Basic" />
                                        <FormLabel id="addScreen1-2-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }} >Full access without the ability to access company settings.</Typography></FormLabel>
                                        <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="accountant" control={<Radio color="primary" />} label="Accountant" />
                                        <FormLabel id="addScreen1-3-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }}>Full access without the ability to access company settings.</Typography></FormLabel>
                                    </RadioGroup>
                                </FormControl>
                                {this.state.actionRoleValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please select the user type</Typography> : <></>}


                            </Grid>
                            <Divider style={{ backgroundColor: "#979797", margin: "20 0" }} />
                            <Grid item xs={3} className={classes.fullDisplay}>
                                <Typography className={classes.fontStyleSecondaryPart}>What is their contact information?</Typography>
                                <Typography className={classes.addUserRadioHelperText} style={{ color: "#3F4144", margin: "10 0 0 0" }}>We will invite them to make a Neeuh account and password for access to your company. This invite expires after 30 days.</Typography>
                                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" className={classes.fullDisplay} style={{ marginTop: "33px" }}>
                                    <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }}>
                                        <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="First Name" value={this.state.firstName} onChange={(e) => { this.setState({ firstName: e.target.value }) }} />
                                        {this.state.firstNameValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please fill the first name</Typography> : <></>}

                                    </Grid>
                                    <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }}>
                                        <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="Last Name" value={this.state.lastName} onChange={(e) => { this.setState({ lastName: e.target.value }) }} />
                                        {this.state.lastNameValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please fill the last name</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }} >
                                        <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="Email" value={this.state.addAccountEmail} onChange={(e) => { this.setState({ addAccountEmail: e.target.value }) }} />
                                        <Typography style={{ paddingTop: "10px" }} className={classes.emailHelperTxt}>This will be their user id. </Typography>
                                        {this.state.emailValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please insert a valid email address</Typography> : <></>}

                                    </Grid>
                                    <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%", marginTop: "20px" }}>
                                        <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="User Name" value={this.state.addAccountUserName} onChange={(e) => { this.setState({ addAccountUserName: e.target.value }) }} />
                                        {this.state.userNameValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please fill the user name</Typography> : <></>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ textAlign: "end", margin: "20 0 40 0" }}>
                                <Button className={classes.buttonLayout} style={{ padding: "0 20" }} onClick={() => { this.addUserValidation(3) }}>Complete</Button>
                            </Grid>
                        </Box>

                    </Grid>
                    :
                    <>
                        {this.state.addUserScreen2 ?
                            <Grid container direction="column" justifyContent="space-evenly" className={classes.fullDisplay}>
                                <Grid item xs={1} className={classes.fullDisplay}>
                                    <Typography className={classes.headerUser}>Add New User</Typography>

                                </Grid>
                                <Box style={{ width: "85%", margin: "auto" }}>
                                    <Grid item xs={5} className={classes.fullDisplay}>
                                        <Grid container direction="row" alignItems="center" justifyContent="flex-start" style={{ margin: "0 0 20 -35" }}>
                                            <Box style={{ height: "25", width: "26.59px", backgroundColor: "#ff9a30", borderRadius: "50%", color: "#FFFFFF", textAlign: "center" }}><Typography>1</Typography></Box>
                                            <Typography className={classes.addUserSelectUser} style={{ marginBottom: "0", marginLeft: "10" }}>Select user settings</Typography>
                                        </Grid>


                                        <FormControl>
                                            <Typography className={classes.addUserSelectUser} style={{ marginBottom: "10px" }}>Do you want this user to add, edit, or remove users?</Typography>
                                            <RadioGroup
                                                aria-labelledby="addScreen1-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={this.state.manageAccount}
                                                onChange={(event) => { this.setState({ manageAccount: event.target.value }) }}
                                            >
                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="yes" control={<Radio color="primary" />} label="Yes" />

                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="no" control={<Radio color="primary" />} label="No" />

                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="view_only" control={<Radio color="primary" />} label="View only" />

                                            </RadioGroup>
                                            <Typography className={classes.addUserSelectUser} style={{ marginBottom: "10px" }}>Do you want this user to edit company info?</Typography>
                                            <RadioGroup
                                                aria-labelledby="addScreen1-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={this.state.manageCompany}
                                                onChange={(event) => { this.setState({ manageCompany: event.target.value }) }}
                                            >
                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="true" control={<Radio color="primary" />} label="Yes" />

                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="false" control={<Radio color="primary" />} label="No" />


                                            </RadioGroup>
                                            <Typography className={classes.addUserSelectUser} style={{ marginBottom: "10px" }}>Do you want this user to manage subscriptions?</Typography>
                                            <RadioGroup
                                                aria-labelledby="addScreen1-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={this.state.manageSubscription}
                                                onChange={(event) => { this.setState({ manageSubscription: event.target.value }) }}
                                            >
                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="allow" control={<Radio color="primary" />} label="Yes" />

                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="restrict" control={<Radio color="primary" />} label="No" />

                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="read_only" control={<Radio color="primary" />} label="View only" />

                                            </RadioGroup>
                                        </FormControl>

                                    </Grid>
                                    <Divider style={{ backgroundColor: "#979797", margin: "20 0" }} />
                                    <Grid item xs={3} className={classes.fullDisplay}>
                                        <Typography className={classes.fontStyleSecondaryPart}>What is their contact information?</Typography>
                                        <Typography className={classes.addUserRadioHelperText} style={{ color: "#3F4144", margin: "10 0 0 0" }}>We will invite them to make a Neeuh account and password for access to your company. This invite expires after 30 days.</Typography>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" className={classes.fullDisplay} style={{ marginTop: "33px" }}>
                                            <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }}>
                                                <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="First Name" value={this.state.firstName} onChange={(e) => { this.setState({ firstName: e.target.value }) }} />
                                                {this.state.firstNameValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please fill the first name</Typography> : <></>}
                                            </Grid>
                                            <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }}>
                                                <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="Last Name" value={this.state.lastName} onChange={(e) => { this.setState({ lastName: e.target.value }) }} />
                                                {this.state.lastNameValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please fill the last name</Typography> : <></>}
                                            </Grid>
                                            <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%" }} >
                                                <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="Email" value={this.state.addAccountEmail} onChange={(e) => { this.setState({ addAccountEmail: e.target.value }) }} />
                                                <Typography style={{ paddingTop: "10px" }} className={classes.emailHelperTxt}>This will be their user id. </Typography>
                                                {this.state.emailValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please insert a valid email address</Typography> : <></>}
                                            </Grid>
                                            <Grid item xs={3} style={{ width: "100%", maxWidth: "30%", flexBasis: "30%", marginTop: "20px" }}>
                                                <TextField inputProps={{ className: classes.fontForTextField }} style={{ width: "100%" }} placeholder="User Name" value={this.state.addAccountUserName} onChange={(e) => { this.setState({ addAccountUserName: e.target.value }) }} />
                                                {this.state.userNameValidate ? <Typography style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>Please fill the user name</Typography> : <></>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ textAlign: "end", margin: "20 0 40 0" }}>
                                        <Button className={classes.buttonLayout} style={{ padding: "0 20" }} onClick={() => { this.addUserValidation(4) }}>Next</Button>
                                    </Grid>
                                </Box>

                            </Grid> :
                            <Grid container direction="column" justifyContent="space-evenly" className={classes.fullDisplay}>
                                <Grid item xs={1} className={classes.fullDisplay}>
                                    <Typography className={classes.headerUser}>Add New User</Typography>

                                </Grid>
                                <Box style={{ width: "85%", margin: "auto" }}>
                                    <Grid item xs={5} className={classes.fullDisplay}>
                                        <Grid container direction="row" alignItems="center" justifyContent="flex-start" style={{ margin: "0 0 20 -35" }}>
                                            <Box style={{ height: "25", width: "26.59px", backgroundColor: "#ff9a30", borderRadius: "50%", color: "#FFFFFF", textAlign: "center" }}><Typography>3</Typography></Box>
                                            <Typography className={classes.addUserSelectUser} style={{ marginBottom: "0", marginLeft: "10" }}>Select user type</Typography>
                                        </Grid>


                                        <FormControl>

                                            <RadioGroup
                                                aria-labelledby="addScreen1-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={this.state.addUser1RadioRole}
                                                onChange={(event) => { this.setState({ addUser1RadioRole: event.target.value }) }}
                                            >
                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="admin" control={<Radio color="primary" />} label="Admin" />
                                                <FormLabel id="addScreen1-1-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }}>They can see and do anything. This includes sending money, changing passwords, and adding users. Not everyone should be an admin.</Typography></FormLabel>
                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="basic" control={<Radio color="primary" />} label="Basic" />
                                                <FormLabel id="addScreen1-2-radio-buttons-group"><Typography className={classes.addUserRadioHelperText} style={{ marginLeft: "30px" }} >Full access without the ability to access company settings.</Typography></FormLabel>
                                                <FormControlLabel classes={{ label: classes.addUserRadioButtonMain }} value="accountant" control={<Radio color="primary" />} label="Accountant" />

                                            </RadioGroup>
                                        </FormControl>

                                    </Grid>

                                    <Grid item style={{ textAlign: "end", margin: "40% 0 40 0" }}>
                                        {this.state.enableFinishButton ? <Button className={classes.buttonLayout} style={{ padding: "0 20" }} onClick={() => { this.sendUserInvitation(), this.setState({ enableFinishButton: false }) }}>Finish</Button> : <Button className={classes.buttonLayout} style={{ padding: "0 20" }} onClick={() => { }}>Finish</Button>}

                                    </Grid>
                                </Box>

                            </Grid>
                        }
                    </>}
                {this.state.addAccountFinish ? <><Redirect to={'/Dashboard/companysettings/user'} />{this.setState({ addAccountFinish: false })} </> : <></>}


            </div>
        );
    }
}

export default withStyles(useStyles)(UserSettingsAdd);

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  activeTab: number;
  token: string;
  errorMsg: string;
  publicToken: string;
  institutionId: string;
  accounts: any;
  expenseCategories: any;
  incomes: any;
  expenses:any;
  incomeCategories: any;
  expenseTypes: any;
  incomeTypes: any;
  openModal: boolean;
  addFlag: boolean;
  transactionDetails: any;
  linkToken:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class BankInformationSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createTokenId: any;
  setAccessToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeTab: 0,
      accounts: [],
      publicToken: "",
      institutionId: "",
      linkToken: '',
      token: '',
      incomes: [],
      expenses: [],
      openModal: false,
      addFlag: true,
      transactionDetails:{},
      errorMsg: '',
      expenseCategories: [1,2,3,4,5,6,7,8,9,10,11,12],
      incomeCategories: [1,2,3,4,5,6,7,8,9],
      expenseTypes: [1,2,3,4],
      incomeTypes: [1,2,3,4,5]
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createTokenId) {
          if (!responseJson.errors) {
              if(responseJson.plaid_response){
                  this.setState({linkToken: 
                    responseJson.plaid_response.link_token
                })
                // let incomesArray:any = []
                // responseJson.data.map((el:any)=>{
                //   let incomeObj: any = {}
                //   incomeObj["id"]= el.id
                //   incomeObj.amount = el.attributes.amount
                //   incomeObj.name = el.attributes.name
                //   incomeObj.date = moment(el.attributes.date).format('MM/DD/YYYY')
                //   incomeObj.expenses_type = el.attributes.expenses_type
                //   incomeObj.notes = el.attributes.notes
                //   incomeObj.file_url = el.attributes.file_url
                //   incomeObj.transaction_category = el.attributes.transaction_category.data.attributes.name
                //   incomesArray = [...incomesArray, incomeObj]
                // })
                // this.setState({incomes:incomesArray})
              }
            }
          }
        } else if (apiRequestCallId === this.setAccessToken) {
            console.log('INSIDE')
          if (!responseJson.errors) {
            if (responseJson.data) {
                console.log(responseJson.data, '--------')
            //   let data: any[] = []
            //   responseJson.data.map((el: any) => {
            //     data = data.concat({ id: el.id, name: el.attributes.name })
            //   })
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  // Customizable Area Start

  switchTab(tab: number){
      this.setState({activeTab: tab})
  }

  showErrorMsg = (msg: string) => {
    this.setState({ errorMsg: msg })
    setTimeout(() => this.setState({ errorMsg: '' }), 2000)
  }

  updateTransactionDetails = (details: object) =>{
    this.setState({transactionDetails: details})
  }

  createToken = ()=>{
    const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createTokenId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.plaidAPIEndPoint}/create_link_token`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleSuccess= (publicToken:any, metadata:any)=>{
    this.setState({publicToken: publicToken, institutionId: metadata.institution.institution_id, accounts: metadata.accounts},()=>{
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token,
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.setAccessToken = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.plaidAPIEndPoint}/set_access_token`
        );
        const httpBody = {
            data: {
                public_token: this.state.publicToken,
                institution_id: this.state.institutionId,
                accounts: this.state.accounts
            },
          };
        
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    })
}

  // Customizable Area End
}

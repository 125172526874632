import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import CompanySetting from './Company.web'
import TransactionSetting from './TransactionSetting.web'
import TaxesSetting from './TaxesSetting.web'
import BillandSubSetting from './BillAndSubSetting.web'
import CreditCardSetting from './CreditCardSetting.web'
import BankInfoSetting from './BankInformationSetting.web'
import OtherSetting from './OtherSetting.web'
import EFTPSSetting from './EFTPSSetting.web'
import EFTPSSettingInstructionPage from './EFTPSSettingInstructionPage.web'
import UserAccountSetting from './UserAccountSetting.web'
import BillAndSubPaymentWeb from './BillAndSubPayment.web'
import VerifyAccountWeb from './VerifyAccount.web'
import VerifyPaymentInfoWeb from './VerifyPaymentInfo.web'
import ChangePasswordSettingsWeb from './ChangePasswordSettings.web'
import CheckEmailViaCodeWeb from './CheckEmailViaCode.web'
import PasswordSuccessMessageWeb from './PasswordSuccesMessage.web'
import EmailSuccessMessageWeb from './EmailSuccessMessage.web'
import CheckPhoneViaCodeWeb from './CheckPhoneViaCode.web'
import ChangeEmailSettingsWeb from './ChangeEmailSettings.web'
import ChangePhoneSettingsWeb from './ChangePhoneSettings.web'
import ManagePaymentInfoWeb from "./ManagePaymentInfo.web"
import UpdatePaymentDetailsWeb from './UpdatePaymentDetails.web';
import AddPaymentDetailsWeb from './AddPaymentDetails.web'
import BillingDetailsWeb from './BillingDetails.web';
import UserSettingsAdd from './UserSettingsAdd.web';
import UserSettingsEdit from './UserSettingsEdit.web';
import DeleteAccount from './DeleteAccount.web';


class Routes extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Switch>
                <Route path="/Dashboard/companysettings/" exact>
                    <CompanySetting updatingValue={this.props.updatingValue} states={this.props.states} getcompanyData={this.props.getcompanyData} />
                </Route>
                <Route path="/Dashboard/companysettings/bill">
                    <BillandSubSetting />
                </Route>
                <Route path="/Dashboard/companysettings/billPaymentHistory">
                    <BillAndSubPaymentWeb />
                </Route>
                <Route path="/Dashboard/companysettings/billVerifyAccount">
                    <VerifyAccountWeb />
                </Route>
                <Route path="/Dashboard/companysettings/billVerifyPaymentInfo">
                    <VerifyPaymentInfoWeb />
                </Route>
                <Route path="/Dashboard/companysettings/ChangePasswordSettings">
                    <ChangePasswordSettingsWeb />
                </Route>
                <Route path="/Dashboard/companysettings/ChangeEmailSettings">
                    <ChangeEmailSettingsWeb />
                </Route>
                <Route path="/Dashboard/companysettings/ChangePhoneSettings">
                    <ChangePhoneSettingsWeb />
                </Route>
                <Route path="/Dashboard/companysettings/CheckEmailForCode">
                    <CheckEmailViaCodeWeb />
                </Route>
                <Route path="/Dashboard/companysettings/CheckPhoneForCode">
                    <CheckPhoneViaCodeWeb />
                </Route>
                <Route path="/Dashboard/companysettings/PasswordSuccessMessage">
                    <PasswordSuccessMessageWeb />
                </Route>
                <Route path="/Dashboard/companysettings/EmailSuccessMessage">
                    <EmailSuccessMessageWeb />
                </Route>
                <Route path="/Dashboard/companysettings/ManagePaymentInfo">
                    <ManagePaymentInfoWeb />
                </Route>
                <Route path="/Dashboard/companysettings/UpdatePaymentDetails">
                    <UpdatePaymentDetailsWeb />
                </Route>
                <Route path="/Dashboard/companysettings/AddPaymentDetails">
                    <AddPaymentDetailsWeb />
                </Route>
                <Route path="/Dashboard/companysettings/BillingDetails">
                    <BillingDetailsWeb />
                </Route>
                <Route path="/Dashboard/companysettings/taxes">
                    <TaxesSetting />
                </Route>
                <Route exact path="/Dashboard/companysettings/transactions">
                    < TransactionSetting />
                </Route>
                <Route path="/Dashboard/companysettings/deleteaccount/">
                    <DeleteAccount></DeleteAccount>
                </Route>
                <Route exact path="/Dashboard/companysettings/eftps">
                    <EFTPSSetting />
                </Route>
                <Route path="/Dashboard/companysettings/bankinfo">
                    <BankInfoSetting />
                </Route>
                <Route path="/Dashboard/companysettings/user">
                    <UserAccountSetting />
                </Route>
                <Route path="/Dashboard/companysettings/adduser">
                    <UserSettingsAdd />
                </Route>
                <Route path="/Dashboard/companysettings/edituser">
                    <UserSettingsEdit />
                </Route>
                <Route path="/Dashboard/companysettings/other">
                    <OtherSetting />
                </Route>
                <Route exact path="/Dashboard/companysettings/eftpsinstruction">
                    <EFTPSSettingInstructionPage />
                </Route>

            </Switch>
        );
    }
}
export default Routes;
Object.defineProperty(exports, "__esModule", {
  value: true
});

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.sendVerificationEmailAPIEndpoint = "bx_block_forgot_password/forgot_password_links"
exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.emptyEmailErrorBody = "Email or Username can't be empty"
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";
exports.errorEmailNotValid = "Email is not valid"

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType = "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";
exports.labelEmailSendSuccessTitle = "Mail Send"
exports.labelEmailSendSuccess = "Please check you email"

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE";

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.resetPasswordEndPoint = "bx_block_forgot_password/reset_passwords";
exports.verifyAccountSignup = "account_block/accounts/send_email_verification_link";
exports.forgotUsernameText = "Forgot Username/Password";
exports.enterTheEmailText = "Enter the email associated with your account.";
exports.sendRecoveryEmail = "SEND RECOVERY EMAIL";
exports.email = "Email";
exports.validaEmailError = "Please use your valid email or username.";
exports.atLease8Characters = "At least 8 characters";
exports.oneUpperCaseLatters = "1 Upper case letters";
exports.oneLowerCaseLatters = "1 Lower case letters";
exports.oneNumber = "1 Number (i.e. 0-9)";
exports.oneSpecialCharacter = "1 Special character e.g., ! @ # ? ]";
exports.confirmPassword = "Confirm Password";
exports.placeholderOfPassword = "************";
exports.newPassword = "New Password";
exports.submitBtnText = "SUBMIT";
exports.confirmPasswordErorr = "Your password and confirmation password must match";
exports.noteText = "Note: < and > are not accepted ";
exports.changePassword = "Change Password";
exports.errorMessage = "All fields are mandatory.";
exports.changePasswordSuccess = "Your password has been reset successfully.";
exports.changePasswordError = "Token Expired. Please try Again.";
exports.resetPasswordContenType = "application/json";
exports.resetPasswordMethodType = "POST";
exports.resetPasswordValidationError = "Your password is not valid.";
exports.forgotPasswordSentSuccessMessage = "If account exists, an email will be sent out with further instructions."
// Customizable Area End
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CardPng = require("../assets/Card.png");
export const lockWithChecked = require("../assets/lockwithchecked.png");
export const lockWithCheckedblue = require("../assets/lockwithcheckedblue.png");
export const AmericanExpress = require("../assets/americanExpress.jpg");
export const MasterCard = require("../assets/mastercard.png");
export const discoverCard = require("../assets/discover_card.jpg");
export const jcbCard= require("../assets/jcb.png");
export const dinersCard = require("../assets/diners.png");
export const visaCard = require("../assets/visa.png");
export const blank_Card = require("../assets/blankCard.jpg");
export const maestroCard = require("../assets/maestroCard.png");
export const unionCard = require("../assets/unionpay.png");




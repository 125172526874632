import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: any;
  userAccountRole: any;
  userAccountName: any;
  userAccountEmail: any;
  userData: any[];
  otherSettingsUseMileage: boolean;
  nameValidation: boolean;
  emailValidation: boolean;
  roleValidation: boolean;
  errorStatus: boolean;
  errorMsg: any;
  successStatus: boolean;
  successMessage: any;
  dummyError: boolean;
  dummySuccess: boolean;
  country: string;
  userAccouuntMain: boolean;
  addUserScreen1: boolean;
  addUserScreen2: boolean;
  addUserScreen3: boolean;
  editUserAccount: boolean;
  addUser1RadioRole: any;
  firstName: any;
  lastName: any;
  addAccountEmail: any;
  addAccountUserName: any;
  clickOnAddAccount: boolean;
  addAccountFinish: boolean;
  editUserPage: boolean;
  manageAccount: any;
  manageCompany: boolean;
  manageSubscription: any;
  userAction: any[];
  editUserName: any;
  editFirstName: any;
  editLastName: any;
  editEmail: any;
  editUserRole: any;
  dummy1: boolean;
  editId: any;
  openEditPopover: boolean;
  anchorElUserEdit: any;
  valve1: boolean;
  valve2: boolean;
  userActionNew: any;
  userActionValue: any;
  deleteUserOpen: boolean;
  userName: any;
  performDelete: boolean;
  firstNameValidate: boolean;
  lastNameValidate: boolean;
  emailValidate: boolean;
  userNameValidate: boolean;
  actionRoleValidate: boolean;
  enableFinishButton: boolean;
  addUserAccount: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  sendUserInvitationId: any;
  getAllUserId: any;
  updateProfileId: any;
  deleteUserId: any;
  getGamificationSwitchStatusId:any;
  UpdateMileageSwitchId:any;



  edditId1: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      userAccountRole: '',
      userAccountName: '',
      userAccountEmail: '',
      userData: [],
      otherSettingsUseMileage: true,
      nameValidation: false,
      emailValidation: false,
      roleValidation: false,
      errorMsg: '',
      errorStatus: false,
      successMessage: '',
      successStatus: false,
      dummyError: false,
      dummySuccess: false,
      country: '',
      userAccouuntMain: false,
      addUserScreen1: true,
      addUserScreen2: false,
      addUserScreen3: false,
      editUserAccount: true,
      addUser1RadioRole: '',
      clickOnAddAccount: false,
      addAccountFinish: false,
      editUserPage: false,
      firstName: '',
      lastName: '',
      addAccountEmail: '',
      addAccountUserName: '',
      manageAccount: '',
      manageCompany: false,
      manageSubscription: '',
      userAction: [],
      editUserName: '',
      editEmail: '',
      editFirstName: '',
      editLastName: '',
      editUserRole: '',
      dummy1: false,
      editId: '',
      openEditPopover: false,
      anchorElUserEdit: '',
      valve1: false,
      valve2: true,
      userActionNew: '',
      userActionValue: '',
      deleteUserOpen: false,
      userName: '',
      performDelete: false,
      firstNameValidate: false,
      lastNameValidate: false,
      emailValidate: false,
      userNameValidate: false,
      actionRoleValidate: false,
      enableFinishButton: true,
      addUserAccount: true,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({ token: await StorageProvider.get('token')})
    this.getGamificationSwitchStatus();
    
   
  }
   // Customizable Area End
  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      //console.log("lele1")

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });

    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.sendUserInvitationId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              this.setState({ successMessage: "Invitation has been sent. please check the email", successStatus: true, userAccountEmail: '', userAccountName: '', userAccountRole: '', enableFinishButton: true })
              this.getAllUser()
            }
          } else {
            //console.log("errors", responseJson)
            //this.showAlert("Error", responseJson?.errors)
            this.setState({ errorMsg: responseJson?.errors, errorStatus: true, enableFinishButton: true })
          }
        } else if (apiRequestCallId === this.getAllUserId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              this.setState({ userData: responseJson?.data, addUserAccount: responseJson?.can_add_new_user })

            }
            this.poopulateUserAction(1, this.state.userData.length)
            //console.log("userdata", this.state.userData.length)
          } else {
            this.setState({ errorMsg: responseJson.errors })
            this.setState({ errorStatus: true })
            console.log("hereeee", responseJson.errors)
          }

        }else if (apiRequestCallId === this.UpdateMileageSwitchId) {
          if (!responseJson.errors) {
            if (responseJson) {
            }
          }
        }
        else if (apiRequestCallId === this.getGamificationSwitchStatusId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              this.setState({otherSettingsUseMileage : responseJson.data?.attributes?.use_mileage})
            }
            this.poopulateUserAction(1, this.state.userData.length)
            //console.log("userdata", this.state.userData.length)
          }
        }
         else if (apiRequestCallId === this.deleteUserId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson.message) {
              this.getAllUser()
              //console.log("delete", responseJson?.message)
              this.setState({ successMessage: responseJson?.message, successStatus: true })

            }
          } else {
            //console.log("delete errors", responseJson?.errors)
            this.setState({ errorMsg: responseJson?.errors, errorStatus: true })
          }
        } else if (apiRequestCallId === this.updateProfileId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              this.setState({ successMessage: "your user account updated" })
              this.setState({ successStatus: true })

            }
          } else {
            this.setState({ errorMsg: responseJson.errors })
            this.setState({ errorStatus: true })
            //console.log("hereeee", responseJson.errors)
          }
        }

      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  //email verification
  verifyEmail(email: any) {
    var formatSpecialCharacter1 = /[@]+/
    var formatSpecialCharacter2 = /[.]+/
    if (formatSpecialCharacter1.test(email) && formatSpecialCharacter2.test(email)) {
      return true
    } else {
      return false
    }
  }
  //validation for user data
  addUserValidation(id: any) {
    if (id == 3) {
      if (this.state.addUser1RadioRole != '' && this.state.addAccountEmail != '' && this.verifyEmail(this.state.addAccountEmail) && this.state.addAccountUserName != '' && this.state.firstName != '' && this.state.lastName != '') {
        this.changePages(3)
        this.setState({ actionRoleValidate: false, emailValidate: false, userNameValidate: false, firstNameValidate: false, lastNameValidate: false })
      } else {

        if (this.state.addUser1RadioRole == '') {
          this.setState({ actionRoleValidate: true })
        } if (this.state.addAccountEmail == '' || this.verifyEmail(this.state.addAccountEmail) == false) {
          this.setState({ emailValidate: true })
        } if (this.state.addAccountUserName == '') {
          this.setState({ userNameValidate: true })
        } if (this.state.firstName == '') {
          this.setState({ firstNameValidate: true })
        } if (this.state.lastName == '') {
          this.setState({ lastNameValidate: true })
        } if (this.state.addUser1RadioRole != '') {
          this.setState({ actionRoleValidate: false })
        } if (this.state.addAccountEmail != '' && this.verifyEmail(this.state.addAccountEmail)) {
          this.setState({ emailValidate: false })
        } if (this.state.addAccountUserName != '') {
          this.setState({ userNameValidate: false })
        } if (this.state.firstName != '') {
          this.setState({ firstNameValidate: false })
        } if (this.state.lastName != '') {
          this.setState({ lastNameValidate: false })
        }

      }
    } else if (id == 4) {
      if (this.state.addUser1RadioRole != '' && this.state.addAccountEmail != '' && this.verifyEmail(this.state.addAccountEmail) && this.state.addAccountUserName != '' && this.state.firstName != '' && this.state.lastName != '') {
        this.changePages(4)
      } else {
        if (this.state.addUser1RadioRole == '') {
          this.setState({ actionRoleValidate: true })
        } if (this.state.addAccountEmail == '' || this.verifyEmail(this.state.addAccountEmail) == false) {
          this.setState({ emailValidate: true })
        } if (this.state.addAccountUserName == '') {
          this.setState({ userNameValidate: true })
        } if (this.state.firstName == '') {
          this.setState({ firstNameValidate: true })
        } if (this.state.lastName == '') {
          this.setState({ lastNameValidate: true })
        } if (this.state.addUser1RadioRole != '') {
          this.setState({ actionRoleValidate: false })
        } if (this.state.addAccountEmail != '' && this.verifyEmail(this.state.addAccountEmail)) {
          this.setState({ emailValidate: false })
        } if (this.state.addAccountUserName != '') {
          this.setState({ userNameValidate: false })
        } if (this.state.firstName != '') {
          this.setState({ firstNameValidate: false })
        } if (this.state.lastName != '') {
          this.setState({ lastNameValidate: false })
        }

      }
    }

  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }
  checkValidationUserAccount() {
    if (this.state.userAccountName === '' || this.state.userAccountEmail === '' || this.state.userAccountRole === '') {
      this.setState({ nameValidation: false, emailValidation: false, roleValidation: false })
      if (this.state.userAccountName === '') {
        this.setState({ nameValidation: true })
      }
      if (this.state.userAccountEmail === '') {
        this.setState({ emailValidation: true })
      }
      if (this.state.userAccountRole === '') {
        this.setState({ roleValidation: true })
      }
    } else {
      this.setState({ nameValidation: false, emailValidation: false, roleValidation: false }),
        this.sendUserInvitation()
    }
  }
  populateDataForEdit() {
    this.state.userData.map((data, index) => {
      if (data?.id == this.state.editId) {

        this.setState({ editEmail: data?.attributes?.email, editFirstName: data?.attributes?.first_name, editLastName: data?.attributes?.last_name, editUserRole: data?.attributes?.role, editUserName: data?.attributes?.user_name, dummy1: true })

      }
    })
  }

  changeEditState(value: any, id: any) {
    //console.log("dekhoooo123", value, id)
    let temp1: any[] = []
    for (let count = 0; count < this.state.userAction.length; count++) {
      if (count === id) {
        temp1[count] = value
      } else {
        temp1[count] = this.state.userAction[count]
      }
    }
    this.setState({ userAction: temp1 })

  }
  callUserAccountEditorDeleteApi(id: any) {
    if (this.state.userAction[id] === 'Edit') {
      this.setState({ dummy1: true })
    } else if (this.state.userAction[id] === 'Delete') {
      this.setState({ deleteUserOpen: true })

    }
  }

  sendUserInvitation() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendUserInvitationId = requestMessage.messageId;

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      user_name: this.state.addAccountUserName,
      email: this.state.addAccountEmail,
      role_id: this.state.addUser1RadioRole,
      manage_account: this.state.manageAccount,
      manage_company: this.state.manageCompany,
      manage_subscription: this.state.manageSubscription,

    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userAccountEndPoint}/add_account_from_setting`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getAllUser() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userAccountEndPoint}/get_all_users`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  async saveEditId(id: any, email: any, firstName: any, lastName: any, role: any, userName: any) {
    //console.log("rouge", role, role.toLowerCase())
    role = role.toLowerCase()
    await StorageProvider.set("editId", id)
    this.state.userData.map((data, index) => {
      if (data?.id == this.state.editId) {

        this.setState({ editEmail: data?.attributes?.email, editFirstName: data?.attributes?.first_name, editLastName: data?.attributes?.last_name, editUserRole: data?.attributes?.role, editUserName: data?.attributes?.user_name, dummy1: true })

      }
    })
    await StorageProvider.set("editEmail", email)
    if (firstName != null) {
      await StorageProvider.set("editFirstName", firstName)
    } else {
      await StorageProvider.set("editLastName", '')
    }
    if (lastName != null) {
      await StorageProvider.set("editLastName", lastName)
    } else {
      await StorageProvider.set("editLastName", '')
    }
    //await StorageProvider.set("editFirstName", firstName)
    //await StorageProvider.set("editLastName", lastName)
    await StorageProvider.set("editUser", userName)
    await StorageProvider.set("editRole", role)

  }
  poopulateUserAction(id: number, length: number) {
    //console.log("dekhlo1", this.state.userAction)
    if (id == 1) {
      let temp: any[] = []
      for (let count = 0; count < length; count++) {
        temp[count] = 'Edit'
      }
      //console.log("dekhlo2", this.state.userData)
      this.setState({ userAction: temp })
    }
    //console.log("dekhlo3", this.state.userAction)
    // else if (id == 2){

    // }
  }
  editUserAction(id: number, count1: number) {

    if (id == 1) {
      let temp: any[] = []
      for (let count = 0; count < length; count++) {
        if (count === count1) {
          temp[count] = 'Delete'
        } else {
          temp[count] = this.state.userAction[count]
        }

      }

      this.setState({ userAction: temp })
    } else if (id == 2) {
      let temp: any[] = []
      for (let count = 0; count < length; count++) {
        if (count === count1) {
          temp[count] = 'Edit'
        } else {
          temp[count] = this.state.userAction[count]
        }

      }

      this.setState({ userAction: temp })
    }
  }

  deleteAccountUser(id: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userAccountEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  editUserData() {
    console.log("clicked1")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileId = requestMessage.messageId;
    const attrs = {
      first_name: this.state.editFirstName,
      last_name: this.state.editLastName,
      user_name: this.state.editUserName,
      email: this.state.editEmail,
      role_id: this.state.editUserRole,
      //manage_account: this.state.manageAccount,
      //manage_company: this.state.manageCompany,
      //manage_subscription: this.state.manageSubscription,

    };
    const data = {
      attributes: attrs,
    };
    console.log("dataaa", data)

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userAccountEndPoint}/update_profile/${this.state.editId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  changePages(id: any) {
    if (id == 1) {
      this.setState({ userAccouuntMain: true, addUserScreen1: false, addUserScreen2: false, addUserScreen3: false, editUserAccount: false })
    } else if (id == 2) {
      this.setState({ userAccouuntMain: false, addUserScreen1: true, addUserScreen2: false, addUserScreen3: false, editUserAccount: false })
    } else if (id == 3) {
      this.setState({ userAccouuntMain: false, addUserScreen1: false, addUserScreen2: true, addUserScreen3: false, editUserAccount: false })
    } else if (id == 4) {
      this.setState({ userAccouuntMain: false, addUserScreen1: false, addUserScreen2: false, addUserScreen3: true, editUserAccount: false })
    } else if (id == 5) {
      this.setState({ userAccouuntMain: false, addUserScreen1: false, addUserScreen2: false, addUserScreen3: false, editUserAccount: true })
    }

  }
  handleUserDelete(id: any) {
    if (id == 1) {
      this.setState({ deleteUserOpen: false })
      this.deleteAccountUser(this.edditId1)
    } else {
      this.setState({ deleteUserOpen: false })
    }

  }

  getGamificationSwitchStatus (){
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
      }
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getGamificationSwitchStatusId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userDataAccountWebEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  UpdateMileageSwitch(switchCondition: boolean) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateMileageSwitchId = requestMessage.messageId;

    const attrs = {
      use_mileage : switchCondition
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.UpdateMileageEndPoint
      
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

}

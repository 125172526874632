// Customizable Area Start
//@ts-nocheck
import React from "react";
import { Redirect, Link } from 'react-router-dom';
import {

    Typography,
    Button,
    withStyles,
    Grid,

    TextField,
    Box,
    Snackbar,

    Dialog,
    DialogTitle,

    DialogContent,

} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import Alert from "@material-ui/lab/Alert";


import CloseIcon from "@material-ui/icons/Close"; import deleteIcon from "../assets/delete.png";
import DeleteAccountController from "./DeleteAccountController.web";

interface Props {
    id: string;
    navigation: any;
    classes: any;
}

const useStyles = () => ({
    border: {
        border: "1px solid",
    },
    typography: {
        color: "red",

    },

    fullDisplay: {
        maxWidth: "100%",
        "& Typography": {
            color: "green",
        },
    },
    buttonLayout: {
        backgroundColor: "#5597f4",
        borderRadius: "30px",
        color: "white",
        height: "25px",
        fontSize: "13px",
        fontWeight: "700",
        "@media (max-width:1425px)": {
            fontSize: "9.75px",
        },
        "&:hover": {
            backgroundColor: "#5597f4",
        },
    },
    mainScreenHeader: {
        fontSize: "20px",
        fontWeight: "700",
        color: "#3F4144",
        "@media(max-width:1425px)": {
            fontSize: "15px",
        },
    },

    deleteButton: {
        background: "#5696F4",
        border: "1px solid #5696F4",
        "& h6": {
            color: "#ffffff"
        },
        "&:hover": {
            background: "red"
        }
    },

    cancelButton: {
        background: "#5696F4",
        border: "1px solid #5696F4",
        "& h6": {
            color: "#ffffff"
        },
        "&:hover": {
            background: "green"
        }
    },


    warningText: {
        fontSize: "16px",
        fontWeight: "700",
        color: "#3F4144",
        margin: "-10px 0 20px 0",
        "@media(max-width:1425px)": {
            fontSize: "12px",
        },
    },
    dialogHeader: {
        margin: "0",
        padding: "15px 0 0 0",



    },


    passwordText: {
        fontSize: "20px",
        fontWeight: "600",
        color: "#3F4144",
        paddingBottom: "15px",
        "@media(max-width:1425px)": {
            fontSize: "12px",
        },

    },
    fontStyleSecondaryPart: {
        fontSize: "16px",
        fontWeight: "600",
        color: "#3F4144",
        "@media(max-width:1425px)": {
            fontSize: "12px",
        },
    },
    fontForTextField: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#AEAEAE",
        "@media(max-width:1425px)": {
            fontSize: "12px",
        },
    },


    textAlignCenter: {
        fontWeight: "700",
        fontSize: "22px",
        color: "#3f4144",
        margin: "15px 40px",
        "@media (max-width:1425px)": {
            fontSize: "15px",
        },
    },
    font15: {
        fontSize: "15px",
    },
    boldFont: {
        fontWeight: "600",
    },
    closeIcon: {
        position: "relative",
        left: "93%",
        top: "-30px"
    },
    link: {
        textDecoration: "none",
        color: "#5597F4",
    },
    textInsideBox1: {
        fontSize: "20px",
        "@media (max-width:1425px)": {
            fontSize: "15px",
        },
    },

});

export class DeleteAccount extends DeleteAccountController {

    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start

            ...this.state,
            // Customizable Area End
        };
    }
    async componentDidMount() {
        this.setState({
            token: await StorageProvider.get("token"),

        })
    }




    render() {






        const { classes } = this.props;


        if ( this.state.redirectToLogin && this.state.successMessage.length >0) {


            return <Redirect to="/" />


        }


        return (


            <Box className="root" data-test-id="deleteAccountComponent">
                {this.state.errorMsg.length > 0 && <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.errorStatus}
                    onClose={() => {
                        this.setState({
                            errorStatus: false,
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="error" elevation={6} variant="filled">
                        {this.state.errorMsg}
                    </Alert>
                </Snackbar>}

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.successStatus}
                    onClose={() => {
                        this.setState({
                            successStatus: false,

                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="success" elevation={6} variant="filled">
                        {this.state.successMessage}
                    </Alert>
                </Snackbar>





                {this.state.deleteAccountOpen && <Dialog
                    fullWidth
                    open={this.state.deleteAccountOpen}
                    aria-labelledby="responsive-dialog-title"
                    data-test-id="DeleteModal"
                >
                    <DialogTitle className={classes.dialogHeader}>



                        <Box style={{
                            textAlign: "center",
                            fontWeight: "500"
                        }}>       <img src={deleteIcon} style={{ height: "20px", width: "20px", paddingRight: "15px" }} /> Delete User Account</Box>

                        <Box item alignSelf="flex-end">     <CloseIcon data-test-id="closeButton" onClick={() => { this.setState({ deleteAccountOpen: false }) }} className={classes.closeIcon} style={{ cursor: "pointer" }} /></Box>



                    </DialogTitle>

                    <DialogContent style={{ overflow: "auto" }}>
                        <Grid container direction="column" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                            <Grid item xs={2} style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                                <Typography style={{
                                    textAlign: "center",
                                    // marginTop: "30px",
                                    marginBottom: "30px", fontWeight: "500"
                                }}>
                                    Are you sure, you want to delete your account ?

                                </Typography>

                            </Grid>

                            <Grid item xs={2} className={classes.fillDis} style={{ maxWidth: "100%" }}>
                                <Grid container direction="row" justifyContent="center"  >
                                    <Grid item xs={2} style={{ marginBottom: "10px", marginRight: "15px" }}>
                                        <Button variant="contained" className={`${classes.deleteButton}`}
                                            type="submit"
                                            data-test-id="deleteConfirm"
                                            onClick={() => {
                                                this.setDeleteUserClose()
                                                this.deleteAccount()
                                                setTimeout(() => {
                                                    this.setState({ redirectToLogin: true });
                                                }, 3000);



                                            }}
                                        >
                                            <Typography variant="subtitle2" className={classes.boldFont}>
                                                delete
                                            </Typography>
                                        </Button>

                                    </Grid>
                                    <Grid item xs={2} style={{ marginBottom: "10px", marginLeft: "15px" }}>
                                        <Button variant="contained" className={`${classes.cancelButton}`}
                                            type="submit"
                                            data-test-id="cancelButton"
                                            onClick={() => { this.setDeleteUserClose() }}
                                        >
                                            <Typography variant="subtitle2" className={classes.boldFont} data-test-id="deleteCancel">
                                                cancel
                                            </Typography>
                                        </Button>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </DialogContent>

                </Dialog>}
                <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    className={classes.fullDisplay}
                >
                    <Grid item xs={1} className={classes.fullDisplay}>
                        <Typography className={classes.textAlignCenter}>
                            Delete Neeuh Account
                        </Typography>
                    </Grid>
                    <Box style={{ width: "85%", margin: "auto" }}>
                        <Grid item xs={5} className={classes.fullDisplay}>
                            <Typography className={classes.warningText}>
                                Are you sure? Your profile and related account information will
                                be deleted from our site.{" "}
                            </Typography>
                        </Grid>

                        <Grid item xs={3} className={classes.fullDisplay}>
                            <Typography className={classes.passwordText}>
                                Password Reconfirmation
                            </Typography>

                            <Grid
                                container
                    /*  */ direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={classes.fullDisplay}
                                data-test-id="passwordReconfirmation"
                            >
                                <Grid
                                    item
                                    xs={3}
                                    style={{ width: "100%", maxWidth: "70%", flexBasis: "70%" }}
                                >
                                    <TextField
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        label="Password"

                                        required
                                        value={this.state.password}
                                        data-test-id="passwordInput"
                                        onChange={(e) => {
                                            this.setInputPasswordValue(e.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Typography
                                className={classes.addUserRadioHelperText}
                                style={{ color: "#3F4144", margin: "10 0 0 0" }}
                            >
                                This is required for account owner verification and prevention
                                of unauthorized activity
                            </Typography>
                        </Grid>
                        <Grid item style={{ textAlign: "left", margin: "20 0 40 0" }}>
                            <Typography className={`${classes.font15} ${classes.boldFont}`}>
                                {" "}
                                Forgot Password{" "}
                                <Link to="/ForgotPassword" className={classes.link} data-test-id="forgotPassword">
                                    Click Here
                                </Link>{" "}
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid
                                item
                                style={{ textAlign: "left", margin: "-10px 20px 40px 0" }}
                            >
                                <Button
                                    className={classes.buttonLayout}
                                    style={{ padding: "0 50px" }}
                                    onClick={() => this.setDeleteUserOpen()}
                                    data-test-id="deleteAccountButton"
                                >
                                    YES, DELETE MY ACCOUNT
                                </Button>
                            </Grid>

                            <Grid
                                item
                                style={{ textAlign: "left", margin: "-10px 0 40px 0" }}
                            >
                                <Typography className={`${classes.font15} ${classes.boldFont}`}>
                                    <Link data-test-id="backtoDashboard" to="/Dashboard" className={classes.link}>
                                        No, I've changed my mind
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Box>
        );
    }
}

export default withStyles(useStyles)(DeleteAccount);


// Customizable Area End
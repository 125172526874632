// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  InputBase,
  Divider,Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import { ReactComponent as VisaSvg } from '../assets/Visa.svg';
import {ReactComponent as ChevronLeftSvg} from '../assets/chevron-left.svg';
import ManagePaymentInfoWeb from "./ManagePaymentInfo.web";
import { discoverCard } from "./assets";
import { MasterCard } from "./assets";
import { CardPng } from "./assets";
import { AmericanExpress } from "./assets";
import { ReactComponent as QuestionSvg } from '../assets/question-circle.svg';
import VerifyAccountController from '../../Settings5/src/VerifyAccountController.web';
import { blank_Card} from "./assets";



const useStyles = (theme) => ({

  emailText:{
    fontSize:40,
    fontWeight:700,
    color:"#000000",
    margin:"5px 0px",
    '@media (max-width:1425px)':{
      fontSize:30
        }
  },
  underLine: {
    width:114,
    height:5,
    backgroundColor:"#B5D8FD",
    '@media (max-width:1425px)':{
        width:85.50,
        height:3.75,
          }
},
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  headerText:{
    fontSize:25,
    fontWeight:500,
    color:"#000000",
    justifyContent:"center",
    alignItems:"center",
    margin:"10px 0px 20px 0px",
    '@media (max-width:1425px)':{
      fontSize:18.75
        }
  },
  paddingLeft:{
    padding:"20px 10px 20px 0px" ,
  },
  desText:{
    fontSize:13,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:9.75
        }
  },
 

  padding:{
    paddingTop:150,
  },
 
formBtnBlue:{
    margin:"20px 0px",
    padding:"0px 40px",
    backgroundColor: "#5597F4",
    width:516,
    height:98,
    borderRadius: "49px",
    color: "white",
    fontSize: "38px",
    fontWeight: 600,
    textTransform:"none",
    "&:hover": {
        backgroundColor: "#5597F4",
    },
    '@media (max-width:1425px)': {
      height: "73.5px",
      width:"387px",
      fontSize: "28.50px",
  },
},
formBtnFont: {
    color: "#3F4144",
    fontSize: "38px",
    fontWeight: 600,
    textTransform:"none",
    '@media (max-width:1425px)': {
        fontSize: "28.50px",
    },
},


  
})
class PaymentSuccessWeb extends VerifyAccountController{
  constructor(props:Props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes, history} = this.props
    return (
     
        <Grid item container justifyContent="center" alignItems="center" className={classes.paddingLeft} >
            <Grid item container lg={12} className={classes.padding}>

            <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                       Payment Complete
                    </Typography> 
                </Grid>
                <Grid item xs={12} lg={12}   className={classes.alignment}>
                        <div  className={classes.underLine}>
                        </div>
                </Grid>
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.headerText}>
                       Receipt of your payment was sent to
                       <b> {this.state.signupEmail?this.state.signupEmail:"your signup Email"}</b>

                    </Typography> 
                </Grid>
                <Grid item  xs={12} lg={12}md={12} sm={12} className={classes.alignment}>
                                        <Button
                                            variant="contained"
                                            component={Link}
                                            to="/dashboard"
                                            className={classes.formBtnBlue}
                                            // onClick={() => {
                                            //     localStorage.removeItem("tempToken"),
                                            //    localStorage.removeItem('signupEmail')
                                            // }}
                                          // onClick={()=>{navigation.navigate("EmailAccountRegistration")}}
                                        >
                                           Start Using Neeuh
                                        </Button>
                </Grid>

            </Grid>
               
              
            <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
            </Snackbar>
        </Grid>
       
    );
  }
}

export default withStyles(useStyles)(PaymentSuccessWeb)
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  employment_tax_threshold: any;
  max_subject_to_social_security: any;
  subject_to_self_employment_tax: any;
  social_security: any;
  medicare: any;
  snackbarMessage: any;
  showSnackbar: any;
  alertType: any;
  globalSettings: any;
  mileage: any;
  social_tax: any;
  medicare_tax: any;
  income_theshold_social: any;
  income_theshold_medicare: any;
  total_employment_tax: any;
  qbi_value: any;
  q1: any;
  q2: any;
  q3: any;
  q4: any;
  transactions: any;
  taxes: any;
  singleTaxes: any;
  marriedTaxes: any;
  householdTaxes: any;
  tax_tables: any;
  selectedYear: any;
  isEdit: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GlobalSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGlobalSettingsApiCallId: string;
  updateGlobalSettingsApiCallId: string;
  createSelfEmploymentTaxApiCallId: string;

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getGlobalSettingsApiCallId ="";
    this.updateGlobalSettingsApiCallId="";
    this.createSelfEmploymentTaxApiCallId="";
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      employment_tax_threshold: "",
      max_subject_to_social_security: "",
      subject_to_self_employment_tax: "",
      social_security: "",
      medicare: "",
      snackbarMessage: "",
      showSnackbar: false,
      alertType: 'error',
      social_tax: "",
      medicare_tax: "",
      income_theshold_social: "",
      income_theshold_medicare: "",
      total_employment_tax: "",
      qbi_value: "",
      globalSettings: {},
      mileage: "",
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      transactions: [],
      singleTaxes: [],
      marriedTaxes: [],
      householdTaxes: [],
      taxes: {},
      tax_tables: {},
      selectedYear: moment().year(),
      isEdit: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    this.getGlobalSettings()
    this.createSelfEmploymentTax()
  }

  showSnackBar = (message: string, alertType?: string) => {
    this.setState({
      snackbarMessage: message,
      alertType: alertType ? "success" : 'error'
    }, () => {
      this.setState({
        showSnackbar: true
      })
    })
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId == this.createSelfEmploymentTaxApiCallId) {
        console.log('API RESPONSE createSelfEmploymentTax... ', responseJson, errorReponse);

        // if (responseJson && responseJson?.data) {
        //   this.showSnackBar("Settings updated successfully.", "success");
        //   this.setEdit(false)
        // }
      }

      if (apiRequestCallId == this.updateGlobalSettingsApiCallId) {
        console.log('API RESPONSE ... ', responseJson, errorReponse);

        if (responseJson && responseJson?.data) {
          this.showSnackBar("Settings updated successfully.", "success");
          this.setEdit(false)
        }
      }

      if (responseJson && responseJson?.data) {
        if (apiRequestCallId == this.getGlobalSettingsApiCallId) {
          this.setState({
            mileage: responseJson.data?.mileage,
            transactions: responseJson.data?.transactions,
            // taxes: responseJson?.data?.taxes,
            singleTaxes: responseJson.data?.taxes?.percentage_methos?.single_filing_separatly,
            marriedTaxes: responseJson.data?.taxes?.percentage_methos?.married_filing_jointly,
            householdTaxes: responseJson.data?.taxes?.percentage_methos?.house_of_hold,
            // tax_tables: responseJson.data?.tax_tables,
            social_tax: responseJson.data?.tax_tables?.attributes?.social_tax ? responseJson.data.tax_tables.attributes.social_tax : "",
            medicare_tax: responseJson.data?.tax_tables?.attributes?.medicare_tax ? responseJson.data.tax_tables.attributes.medicare_tax : "",
            income_theshold_social: responseJson.data?.tax_tables?.attributes?.income_theshold_social ? responseJson.data.tax_tables.attributes.income_theshold_social : "",
            income_theshold_medicare: responseJson.data?.tax_tables?.attributes?.income_theshold_medicare ? responseJson.data.tax_tables.attributes.income_theshold_medicare : "",
            total_employment_tax: responseJson.data?.tax_tables?.attributes?.total_employment_tax ? responseJson.data.tax_tables.attributes.total_employment_tax : "",
            qbi_value: responseJson.data?.tax_tables?.attributes?.qbi_value ? responseJson.data.tax_tables.attributes.qbi_value : "",
            q1: responseJson.data?.tax_tables?.attributes?.q1_payment ? moment(responseJson.data.tax_tables.attributes.q1_payment).format('YYYY-MM-DD') : "",
            q2: responseJson.data?.tax_tables?.attributes?.q2_payment ? moment(responseJson.data.tax_tables.attributes.q2_payment).format('YYYY-MM-DD') : "",
            q3: responseJson.data?.tax_tables?.attributes?.q3_payment ? moment(responseJson.data.tax_tables.attributes.q3_payment).format('YYYY-MM-DD') : "",
            q4: responseJson.data?.tax_tables?.attributes?.q4_payment ? moment(responseJson.data.tax_tables.attributes.q4_payment).format('YYYY-MM-DD') : "",

            employment_tax_threshold: responseJson.data?.self_employment_tax?.employment_tax_threshold,
            max_subject_to_social_security: responseJson.data?.self_employment_tax?.max_subject_to_social_security,
            subject_to_self_employment_tax: responseJson.data?.self_employment_tax?.subject_to_self_employment_tax,
            social_security: responseJson.data?.self_employment_tax?.social_security,
            medicare: responseJson.data?.self_employment_tax?.medicare,
            // globalSettings: responseJson?.data
          })
        }
      }
    }

  }
    // Customizable Area End


  updateGlobalSettings = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    if (this.state.q1 != "" && !moment(this.state.q1).isValid()) {
      this.showSnackBar("Date is invalid for Q1");
      return;
    }
    if (this.state.q2 != "" && !moment(this.state.q2).isValid()) {
      this.showSnackBar("Date is invalid for Q2");
      return;
    }
    if (this.state.q3 != "" && !moment(this.state.q3).isValid()) {
      this.showSnackBar("Date is invalid for Q3");
      return;
    }
    if (this.state.q4 != "" && !moment(this.state.q4).isValid()) {
      this.showSnackBar("Date is invalid for Q4");
      return;
    }

    let tempTransaction: any[] = [];
    this.state.transactions.forEach((element: any) => {
      // if (element?.isEdited) {
        tempTransaction = [...tempTransaction, {
          "id": element.id,
          "name": element.name,
          "percentage": element.percentage
        }]
      // }
    });

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateGlobalSettingsApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        "year": this.state.selectedYear,
        "mileage_rate": this.state.mileage,
        "transaction": tempTransaction,
        "tax_tables": {
          "social_tax": this.state.social_tax,
          "medicare_tax": this.state.medicare_tax,
          "income_theshold_social": this.state.income_theshold_social,
          "income_theshold_medicare": this.state.income_theshold_medicare,
          "total_employment_tax": this.state.total_employment_tax,
          "qbi_value": this.state.qbi_value,
          "q1_payment": this.state.q1,
          "q2_payment": this.state.q2,
          "q3_payment": this.state.q3,
          "q4_payment": this.state.q4
        },
        "self_employment_tax": {
          "employment_tax_threshold": this.state.employment_tax_threshold,
          "max_subject_to_social_security": this.state.max_subject_to_social_security,
          "subject_to_self_employment_tax": this.state.subject_to_self_employment_tax,
          "social_security": this.state.social_security,
          "medicare": this.state.medicare
        }
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.globalSettingsEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveCategoriesAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createSelfEmploymentTax = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSelfEmploymentTaxApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        "attributes": {
          "year": this.state.selectedYear,
          "employment_tax_threshold": this.state.employment_tax_threshold,
          "max_subject_to_social_security": this.state.max_subject_to_social_security,
          "subject_to_self_employment_tax": this.state.subject_to_self_employment_tax,
          "social_security": this.state.social_security,
          "medicare": this.state.medicare
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.globalSettingsEndPoint}/create_self_employment_tax`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGlobalSettings = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGlobalSettingsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.globalSettingsEndPoint}?year=${this.state.selectedYear}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start
  setEdit = (value: boolean) => {
    this.setState({ isEdit: value })
  }
  // Customizable Area End
  
}

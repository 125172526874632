//@ts-nocheck
// Customizable Area Start
import React from "react";
import Alert from '@material-ui/lab/Alert';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ReactComponent as FileAdd } from '../assets/file-add.svg'
import { ReactComponent as FileEmpty } from '../assets/file-empty.svg'
import { ReactComponent as Trash } from '../assets/trash.svg'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckIcon from '@material-ui/icons/Check';
import StorageProvider from "../../../framework/src/StorageProvider.web";
import NotificationContext from '../../../components/src/Context/NotificationContext'
import moment from "moment";

import {
    AppBar,
    Divider,
    InputAdornment,
    Typography,
    Modal,
    Button,
    withStyles,
    Grid,
    TextField,
    TextareaAutosize,
    Select,
    MenuItem,
    Snackbar,
    Box
} from "@material-ui/core";
import TransactionDetailsModalController from "./TransactionDetailsModalController";

const useStyles = (themes) => ({
    modalTitleRoot: {
        padding: 0,
    },
    modalTitle: {
        height: "40px",
        position: "relative"
    },
    fileName: {
        gap: '10px'
    },
    fileIcon: {
        height: "23px",
        width: "23.58px",
        "& path": {
            fill: "#FF9933"
        }
    },
    fileModalTitle: {
        paddingBottom: "10px",
        borderBottom: "1px solid #000000"
    },
    fileUploadBox: {
        width: "250px",
        height: "230px",
        background: '#B5D8FD80',
        border: "1px solid #979797",
        borderRadius: "5px"
    },
    relativePosition: {
        position: "relative"
    },
    floatingButtons: {
        width: "150px",
        position: "absolute",
        right: 0,
        top: "0"
    },
    floatingButtonsLeft: {
        width: "50%",
        position: "absolute",
        left: "20px",
        top: "50%",
        transform: "translate(0, -50%)"
    },
    fontWhite: {
        color: "#ffffff"
    },
    grayBackground: {
        backgroundColor: "#F7F7F7",
        padding: "2px"
    },
    fontBold: {
        fontWeight: "600"
    },
    addIcon: {
        padding: "0 10px",
        height: "100%",
        '& path': {
            strokeWidth: '1px'
        }
    },
    addSvg: {

        width: "18px",
        height: "18px",
        '& path': {
            fill: "#454546"
        }
    },
    modalRoot: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY:'auto',
    },
    displayNone: {
        display: 'none'
    },
    displayNormal: {
        display: ''
    },
    modal: {
        height: "auto",
        background: "#ffffff",
        position: "relative",
        overflowY: "auto",
        paddingBottom: "10px",
    },
    fileModal: {
        height: "350px",
        background: "#ffffff",
        overflowY: "auto",
        maxWidth: "600px",
        borderRadius: "5px",
        paddingTop:'20px'
    },
    tabButtons: {
        color: "#3A79F2",
        height: "40px",
        fontWeight: "bold",
        margin: '0 10px',
        borderRadius: "0",
        borderBottom: "5px solid transparent",
        textTransform: "capitalize"
    },
    fileContent: {
        width: "60%"
    },
    tabButtonActive: {
        borderBottom: "5px solid #3A79F2",
    },
    tabs: {
        borderBottom: "1px solid #000000",
        height: "40px"
    },
    amountField: {
        width: "100px"
    },
    inputs: {
        borderBottom: "1px solid black"
    },
    fieldTitle: {
        fontWeight: "600"
    },
    greyBox: {
        width: "250px",
        height: "100px !important",
        border: "none",
        padding: "5px",
        backgroundColor: "#F7F7F7"
    },
    greyFieldBox: {
        backgroundColor: "#F7F7F7"
    },
    icons: {
        width: "20px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    deleteIcon: {
        height: "17px",
        "&:hover": {
            cursor: 'pointer'
        },

    },
    deleteIconButton: {

        height: "17px",
        "&:hover": {
            cursor: 'pointer'
        },
        "& path": {
            fill: "#5696F4"
        }
    },
    textCapitalize: {
        textTransform: "capitalize",
        color: "black"
    },
    deleteIconText: {
        color: '#5696F4',
        fontSize: "16px",
        fontWeight: "400",
        "&:hover": {
            cursor: 'pointer'
        },
        margin: "-2 0 0 5",
        '@media (max-width:1425px)': {
            fontSize: "12px",
            margin: "auto 0"
        },

    },
    checkCircle: {
        background: "#b5d8fd",
        borderRadius: "50%",
        width: "15px",
        border: "1px solid #5696F4",
        height: "15px",
        margin: "0 2px"

    },
    checkedCircle: {
        width: "15px",
        background: "#58A65F",
        borderRadius: "50%",
        border: "1px solid #437A4A",
        height: "15px",
        fill: "#ffffff",
        margin: "0 2px"
    },
    justifyCenter: {
        justifyContent: "space-evenly",
        textAlign: "center"
    },
    modalButtons: {
        textTransform: "none",
        backgroundColor: "#5597F4",
        width: "80px",
        borderRadius: "30px",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#5597F4",
        },
    },
    grayFont: {
        color: "#AEAEAE"
    },
    blueButton: {
        margin: "5px",
        color: "#ffffff",
        background: "#5597F4",
        borderRadius: "20px",
        fontSize: "10px",
        "&:hover": {
            background: "#5597F4",
        }
    },
    gridItem: {
        margin: "10px 0",
    },
    formDate: {
        "& div": {
            minWidth: 0,
            border: "none !important"

        }
    },
    categorizeGrid: {
        borderLeft: "2px solid #F4F4F4"
    },
    categorizeTitle: {
        borderBottom: "2px solid #F4F4F4",
        height: "40px"
    },
    categorize: {
        margin: "20px 0",
        gap: "20px"
    },
    formButton: {
        borderRadius: "20px",
        border: "2px solid #FFB265",
        padding: "5px"
    },
    editField: {
        width: "80%"
    },
    formSelect: {
        textTransform: "capitalize",
        border: "thin solid gray",
        padding: "4px",
        width: "100%",
        "& svg": {
            width: "20px",
        }
    },
    textFielsFonts: {
        textAlign: 'end',
        fontWeight: '700',
        fontSize: '20px',
        color: "#3F4144",
        padding: "0px",
        '@media (max-width:1425px)': {
            fontSize: "15px",
        },
    },
    selectOption: {
        textTransform: 'capitalize'
    },
    categoriesText: {
        width: "100%",
        padding: "3px 0",
        paddingLeft: "10px",
        textTransform: 'capitalize'
    },
    fileInput: {
        display: "none"
    },
    outlined: {
        background: '#EEEEEE'
    },
    longButton: {
        padding: "2px 15px",
        width: "100px"
    },
    pointerIcon: {
        cursor: "pointer",
        paddingRight: "5px"
    },
    borderTopBottom: {
        borderTop: "1px solid #000000",
        borderBottom: "1px solid #000000"
    },
    borderWide: {
        position: "absolute",
        width: "100%",
        borderTop: "1px solid #000000",
    },
    font25: {
        fontSize: "25px"
    },
    font20: {
        fontSize: "20px",
        "& input": {
            fontSize: '20px'
        }
    },
    font16: {
        fontSize: "16px"
    },
    font13: {
        fontSize: "13px"
    },
    fileSVG: {
        width: "72px",
        height: "86px",
        '& g': {
            fill: '#5696F4'
        }
    },
    font17: {
        fontSize: "17px"
    },
    '@media (max-width:1425px)': {
        font20: {
            fontSize: "15px",
            "& input": {
                fontSize: '15px'
            }
        },
        fileSVG: {
            width: "54px",
            height: "64.5px",
        },
        font16: {
            fontSize: "12px"
        },
        font13: {
            fontSize: "9.75px"
        },
        font25: {
            fontSize: "18.75px"
        },
        category: {
            height: "55px"
        },
        font17: {
            fontSize: "12.75px"
        }
    },
    '@media (max-width:1250px)': {
        font20: {
            fontSize: "10px",
            "& input": {
                fontSize: '10px'
            }
        },
        font16: {
            fontSize: "9px"
        },
        font13: {
            fontSize: "7.3px"
        },
        font25: {
            fontSize: "14px"
        },
        category: {
            margin: "10px 15px"
        },
        userCategory: {
            margin: "10px 15px"
        },
        orangeCircle: {
            width: "90px",
            height: "90px"
        },
        blueCircleTransaction: {
            width: "90px",
            height: "90px"
        },
        whiteCircle: {
            width: "82.5px",
            height: "82.5px"
        },
        blueCircle: {
            width: "90px",
            height: "90px"
        },
        addIcon: {
            width: "25px",
            height: " 25px"
        },
        longButton: {
            width: "50px"
        },
        floatingButtons: {
            width: "105px"
        },
        fileUploadBox: {
            width: "200px",
            height: "180px",
        },
        scrollableContainer: {
            maxHeight: '80vh',
            overflowY: 'auto',
          },
    }
})

class TransactionDetailsModal extends TransactionDetailsModalController {
    constructor(props: any) {
        super(props);
        this.state = {
            ...this.state,
        };
    }
    async componentDidMount() {
        this.setState({ token: await StorageProvider.get('token') }, () => {
            this.setState({ func: this.props.handleModal, from: this.props.from })
            if(this.props.isFromReports){
                this.getAllCategories(this.props.from);
            }else{
                this.getCategories(this.props.from);
            }
            this.getExpenseCategories()
            this.props.transactionId ?
                this.setState({ transactionId: this.props.transactionId })
                : this.setState({ editFields: true })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.splitData !== prevState.splitData ) {
          const paddingTop = `${this.state.splitData.length * 80}px`;
          
          this.setState({ paddingTop });
        }
      }
    
    render() {
        const { classes } = this.props;
        return (
            <NotificationContext.Consumer>
                {(context) => (
                    <Modal onClose={(event, reason) => {
                        if (reason != "backdropClick") this.props.handleModal(false, null)
                    }} open={true} className={classes.modalRoot}>
                        {this.state.fileModal ?
                            <Grid item container justifyContent="center" xs={6} className={classes.fileModal}>
                                <Grid item container xs={12} className={`${classes.modalTitle} ${classes.fileModalTitle}`} justifyContent="center" alignContent="flex-end">
                                    <Grid item container justifyContent="center" alignContent="center">
                                        <AttachFileIcon className={classes.addIcon} />
                                        <Typography variant="body1" className={`${classes.font25}`}>
                                            Attach File
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.floatingButtons} justifyContent="center" alignContent="flex-end">
                                        <Grid item container justifyContent="space-between" alignItems="center">
                                            <Button className={`${classes.blueButton} ${classes.longButton} ${classes.font13}`} onClick={() => this.setState({ fileModal: false })} variant="contained">Save</Button>
                                            <ClearOutlinedIcon data-test-id="closeModal" className={classes.pointerIcon} onClick={() => this.setState({ fileModal: false })} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={6} justifyContent="center" >
                                    <input
                                        disabled={!this.state.editFields}
                                        onChange={(event) => this.handleFile(event)}
                                        type="file"
                                        className={classes.fileInput}
                                        multiple={true}
                                        id="enterFile"
                                    />
                                    <label htmlFor="enterFile"
                                        onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}>
                                        <Grid item container className={classes.fileUploadBox} justifyContent='center' alignItems="center">
                                            <Grid item container xs={12} className={classes.fileContent} justifyContent="center" alignItems="center">
                                                <Grid item container xs={12} justifyContent="center" alignContent="center">
                                                    <FileAdd className={classes.fileSVG} />
                                                </Grid>
                                                <Grid item container xs={12} justifyContent="center" alignContent="center">
                                                    <Typography display="block" className={classes.font20}>Drop your files here</Typography>
                                                    <Typography display="block" className={classes.font20}>or click to browse</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </label>
                                </Grid>
                                <Grid item container xs={6} className={classes.fileName} alignContent="flex-start" justifyContent="flex-end">
                                    {[...this.state.file, this.state.uploadedFiles].length ?
                                        [...this.state.file, ...this.state.uploadedFiles].map((el: any) => {
                                            return (
                                                <Grid item container xs={10} justifyContent="flex-end" alignItems="center">
                                                    <Grid item container xs={2}>
                                                        <FileEmpty className={classes.fileIcon} />
                                                    </Grid>
                                                    <Grid item container xs={10}>
                                                        <Grid item >
                                                            <Typography className={classes.font17}>{el.id ? el.filename : el.name}</Typography>
                                                        </Grid>
                                                        <Grid item container justifyContent="space-between" xs={12}>
                                                            <Grid item container xs={8}>
                                                                <Typography className={`${classes.font17} ${classes.grayFont}`} >{el.id ? this.formatBytes(el.file_size) : this.formatBytes(el.size)}</Typography>
                                                            </Grid>
                                                            <Grid item container xs={4} alignContent="center">
                                                                <CheckIcon className={classes.checkedCircle} />
                                                                <Trash className={classes.deleteIcon} onClick={() => {
                                                                    el.id ? this.deleteFile(null, el.id) :
                                                                        this.deleteFile(el, null)
                                                                }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                        : <></>}
                                </Grid>
                            </Grid>
                            :
                            <Grid item container justifyContent="center" xs={6} className={classes.modal} style={{paddingTop: this.state.activeTab ? this.state.paddingTop: "0"}}>
                                {this.state.deleteTransactionModal && <Dialog open={this.state.deleteTransactionModal} fullWidth onClose={() => this.setState({ deleteTransactionModal: false })} >
                                    {this.state.tranDelete && this.props.handleModal(false, null)}
                                    <DialogTitle className={`${classes.font16} ${classes.justifyCenter}`}>Delete Transaction</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText className={classes.justifyCenter}>
                                            Are you sure you want to delete transaction ?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={classes.justifyCenter}>
                                        <Button className={classes.modalButtons} onClick={() => this.setState({ deleteTransactionModal: false })} color="primary">
                                            No
                                        </Button>
                                        <Button className={classes.modalButtons} onClick={() => this.deleteTransactionData(this.props.from)} color="primary">
                                            Yes
                                        </Button>
                                    </DialogActions>
                                </Dialog>}
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={this.state.showSnackbar}
                                    onClose={() => {
                                        this.setState({
                                            showSnackbar: false
                                        }, () => { this.setState({ saveButtonState: false }) });
                                    }}
                                    autoHideDuration={2000}
                                >
                                    <Alert severity="error"
                                        elevation={6} variant="filled"
                                    >{this.state.snackbarMessage}
                                    </Alert>
                                </Snackbar>
                                <Grid item container xs={12} className={classes.modalTitle} justifyContent="center">

                                    {this.props.transactionId ?

                                        <>

                                            <Grid item container justifyContent="center" alignContent="center" className={classes.relativePosition}>
                                                <Grid item container justifyContent="flex-start" alignContent="center" className={this.state.disableDelete ? `${classes.displayNone} ${classes.floatingButtonsLeft}` : `${classes.floatingButtonsLeft} ${classes.displayNormal}`} >
                                                    <Trash className={classes.deleteIconButton} onClick={() => {
                                                        this.setState({ deleteTransactionModal: true })
                                                    }} />
                                                    <Typography className={classes.deleteIconText}
                                                        onClick={() => {
                                                            this.setState({ deleteTransactionModal: true })
                                                        }}
                                                    >Delete Transaction</Typography>
                                                </Grid>
                                                <AddCircleOutlineOutlinedIcon className={classes.addIcon} />
                                                <Typography variant="body1" className={`${classes.font25}`}>
                                                    Transaction Details
                                                </Typography>
                                            </Grid>
                                        </>
                                        :
                                        <Grid item container justifyContent="center" alignContent="center">
                                            <AddCircleOutlineOutlinedIcon className={classes.addIcon} />
                                            <Typography variant="body1" className={`${classes.font25}`}>
                                                {this.props.from ? "Add Expense Transaction" : "Add Income Transaction"}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid className={classes.floatingButtons} justifyContent="center" alignContent="flex-end">
                                        <Grid item container justifyContent="space-between" alignItems="center" >
                                            <Button disabled={this.state.saveButtonState} className={`${classes.blueButton} ${classes.longButton} ${classes.font13}`} onClick={() => {
                                                this.setState({ saveButtonState: true })
                                                this.state.transactionId ? this.updateTransactionData(this.props.from) :
                                                    this.addTransactionData(this.props.from, context)
                                            }} variant="contained">Save</Button>
                                            <ClearOutlinedIcon className={classes.pointerIcon} onClick={() => this.props.handleModal(false, null)} />
                                        </Grid>
                                        {this.props.transactionId ?
                                            <>
                                                <Button data-test-id="editFieldBtn" className={`${classes.blueButton} ${classes.longButton} ${classes.font13}`} onClick={() => this.setState({ editFields: true })} variant="contained">Edit</Button>

                                            </>
                                            : <></>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center" xs={12} className={classes.tabs}>
                                    <Button onClick={() => this.switchTab(0)} className={this.state.activeTab ? `${classes.tabButtons} ${classes.font20}` : `${classes.tabButtons} ${classes.tabButtonActive} ${classes.font20}`}>Details</Button>
                                    <Button onClick={() => this.switchTab(1)} className={this.state.activeTab ? `${classes.tabButtons} ${classes.tabButtonActive} ${classes.font20}` : `${classes.tabButtons} ${classes.font20}`}>Split Transaction</Button>
                                </Grid>
                                <Grid item container xs={11} justifyContent="center">
                                    {this.state.activeTab ?
                                        <Grid item container justifyContent="space-between" xs={12}>
                                            <Grid item container xs={5} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Name: </Typography>
                                                <Grid item container xs={12} alignItems="center" className={classes.greyFieldBox}>
                                                    <TextField className={`${classes.editField} ${classes.font20}`} InputProps={{ disableUnderline: true }} placeholder="Name" disabled={!this.state.editFields} value={this.state.name} onChange={(e) => this.setState({name: e.target.value })} />
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={6} justifyContent="space-between" >

                                                <Grid item container xs={5} className={classes.gridItem}>
                                                    <Grid item container xs={12} alignItems="center">
                                                        <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Date: </Typography>
                                                    </Grid>
                                                    <Grid item container xs={12} alignItems="center">
                                                        <Typography className={`${classes.font20} ${classes.grayBackground}`}>{moment(this.state.date).format('MM/DD/YYYY')}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={5} justifyContent="flex-end" className={classes.gridItem}>
                                                    <Grid item container xs={12} justifyContent="flex-end">
                                                        <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Type: </Typography>
                                                    </Grid>
                                                    <Grid item container xs={12} justifyContent="flex-end">
                                                        <Typography variant="subtitle2" className={`${classes.font20} ${classes.selectOption}`} style={{ textAlign: "end", display: "ruby" }}>{this.state.transactionId ? this.state.transactionType : 'capital'}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider variant="fullWidth" style={{ width: "120%", marginLeft: "-10%", backgroundColor: "black" }} />
                                            {/* ${classes.borderTopBottom} */}
                                            {/* <Box style={{ height: "2px", width: "100%", backgroundColor: "black" }} /> */}
                                            <Grid item container xs={12} className={`${classes.gridItem} `}>
                                                {/* <Grid className={classes.borderWide}></Grid> */}
                                                {/* <Grid item container xs={12} className={`${classes.gridItem} ${classes.borderTopBottom}`}> */}
                                                {this.state.splitData.map((el: any, index: number) => {
                                                    return (
                                                        <Grid item container justifyContent="space-between" xs={12}>
                                                            <Grid item container xs={5} className={classes.gridItem}>
                                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Amount </Typography>
                                                                <Grid item container xs={12} justifyContent="space-between" alignItems="center" className={classes.inputs}>
                                                                    {/* <Typography>$</Typography> */}
                                                                    {this.state.error && this.state.error.type == 'amount' ?
                                                                        <Typography variant="caption" color="error" className={classes.font20}>{this.state.error.message}</Typography>
                                                                        : <TextField disabled={!this.state.editFields}
                                                                            //style={{ textAlign: "end", width: "inherit" }}
                                                                            inputProps={{ className: classes.textFielsFonts }}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start" >

                                                                                        <Typography style={{ color: "#3F4144", fontWeight: "700" }}>$</Typography>



                                                                                    </InputAdornment>
                                                                                ), disableUnderline: true, style: { textAlign: 'start' }
                                                                            }}
                                                                            value={el && el.amount} onChange={(e) => e.target.value.match(/^[0-9]*(?:\.[0-9]*)?$/) && this.updateSplitData(e.target.value, 'amount', index)} placeholder="0.00" />

                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container xs={6} className={classes.gridItem}>
                                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Category: </Typography>
                                                                <Grid item container xs={12} alignItems="center">
                                                                    <Select className={`${classes.formSelect} ${classes.font20}`} disableUnderline onChange={(e) => this.updateSplitData(e.target.value, 'transaction_category_id', index)} value={el && el.transaction_category_id ? el.transaction_category_id : "empty"}>
                                                                        <MenuItem value={"empty"} className={`${classes.selectOption} ${classes.font20}`}>Drop Down Menu</MenuItem>
                                                                        {[...this.state.categories,...(this.props.addCats || [])].map(el =>
                                                                            <MenuItem value={el.id} className={`${classes.selectOption} ${classes.font20}`}>{el.name}
                                                                                {el.name == "expense reimbursement" ?
                                                                                    <Select className={`${classes.formSelect} ${classes.font20}`} disableUnderline onChange={(e) => this.updateSplitData(e.target.value, 'transaction_category_id', index)} value={el && el.transaction_category_id ? el.transaction_category_id : "empty"}>
                                                                                        {this.state.expenseCategories.map(el =>
                                                                                            <MenuItem value={el.id} className={`${classes.selectOption} ${classes.font20}`}>{el.name}
                                                                                            </MenuItem>
                                                                                        )}
                                                                                    </Select> : ""}
                                                                            </MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                                <Grid item container xs={12} justifyContent="center" alignItems="center" style={{ margin: "5 0 2 0" }}>
                                                    <AddCircleOutlineOutlinedIcon className={classes.addSvg} onClick={() => this.addSplitColumn()} />
                                                    <Button disabled={!this.state.editFields} className={classes.textCapitalize} onClick={() => this.addSplitColumn()}>
                                                        <Typography className={`${classes.fieldTitle} ${classes.font20}`} style={{ color: "#3F4144", fontWeight: "700" }}>Add Split</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            {/* <Divider variant="fullWidth" /> */}
                                            <Divider variant="fullWidth" style={{ width: "120%", marginLeft: "-10%", backgroundColor: "black", borderBottomWidth: 5 }} />
                                            <Grid item container xs={5} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Notes</Typography>
                                                <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                                                    <TextareaAutosize disabled={!this.state.editFields} value={this.state.splitNotes} onChange={(e) => this.setState({ splitNotes: e.target.value })} className={classes.greyBox} />
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={6} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Attach File </Typography>
                                                <Grid item xs={12} container justifyContent="center" alignContent="center" className={classes.greyBox}>
                                                    <Button data-test-id="browse" disabled={!this.state.editFields} onClick={() => this.setState({ fileModal: true })}
                                                        className={`${classes.blueButton} ${classes.font13}`} variant="contained" component="span">
                                                        Browse
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        : <Grid item container justifyContent="space-between" xs={12}>
                                            <Grid item container xs={5} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Amount </Typography>
                                                <Grid item container xs={12} justifyContent="space-between" alignItems="center" className={classes.inputs}>

                                                    <TextField disabled={!this.state.editFields} type="text"
                                                        inputProps={{ className: classes.textFielsFonts }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" >

                                                                    <Typography style={{ color: "#3F4144", fontWeight: "700" }}>$</Typography>



                                                                </InputAdornment>
                                                            ), disableUnderline: true, style: { textAlign: 'start' }
                                                        }}
                                                        value={this.state.amount} onChange={(e) => e.target.value.match(/^[0-9]*(?:\.[0-9]*)?$/) && this.setState({ amount: e.target.value })} placeholder="0.00" />
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={5} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Name: </Typography>
                                                <Grid item container xs={12} alignItems="center" className={classes.greyFieldBox}>
                                                    <TextField className={`${classes.editField} ${classes.font20}`} InputProps={{ disableUnderline: true }} placeholder="Name" disabled={!this.state.editFields} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={3} className={classes.gridItem}>
                                                <Grid item container xs={12} alignItems="center">
                                                    <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Date: </Typography>
                                                </Grid>
                                                <Grid item container xs={12} alignItems="center">
                                                    <Typography className={classes.font20}>{moment(this.state.date).format('MM/DD/YYYY')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={3} style={{ margin: "10 8% 10 -16%" }} justifyContent="flex-start">
                                                <Grid item container xs={12} justifyContent="flex-end">
                                                    <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Type: </Typography>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent="flex-end">
                                                    <Typography variant="subtitle2" className={`${classes.font20} ${classes.selectOption}`} style={{ textAlign: "end" }}>{this.state.transactionId ? this.state.transactionType : 'capital'}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={5} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Category: </Typography>
                                                <Grid item container xs={12} alignItems="center">
                                                    {this.state.categories.length ?
                                                        <Select className={`${classes.formSelect} ${classes.font20}`} disableUnderline onChange={async (e) => await this.updateCategory(e.target.value, (this.state.expenseCategory !== "null" && this.state.expenseCategory) ? true : false)}
                                                            value={(this.state.category !== "null" && this.state.category) ? this.state.category : this.state.expenseCategory}>
                                                            {[...this.state.categories,...(this.props.addCats || [])].map(el =>
                                                                <MenuItem value={el.id} className={`${classes.selectOption} ${classes.font20}`}>{el.name}
                                                                    {el.name == "income categories" ?
                                                                        <Select className={`${classes.formSelect} ${classes.font20}`} disableUnderline onChange={async (e) => await this.updateCategory(e.target.value, false, true)} value={(this.state.category !== "null" && this.state.category) ? this.state.expenseCategory : "null"}>
                                                                            {this.state.expenseCategories.filter(el => el.name !== "expense reimbursement").map(el =>
                                                                                <MenuItem value={el.id} className={`${classes.selectOption} ${classes.font20}`}>{el.name}
                                                                                </MenuItem>
                                                                            )}
                                                                        </Select>
                                                                        : ""}
                                                                    {el.name == "expense reimbursement" ?
                                                                        <Select className={`${classes.formSelect} ${classes.font20}`} disableUnderline onChange={(e) => this.updateCategory(e.target.value, true)} value={(this.state.expenseCategory !== "null" && this.state.expenseCategory) ? this.state.expenseCategory : "null"}>
                                                                            {this.state.expenseCategories.map(el =>
                                                                                <MenuItem value={el.id} className={`${classes.selectOption} ${classes.font20}`}>{el.name}
                                                                                </MenuItem>
                                                                            )}
                                                                        </Select> : ""}
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                        : <></>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={5} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Notes</Typography>
                                                <Grid item container xs={12} justifyContent="space-between" alignItems="center" >
                                                    <TextareaAutosize disabled={!this.state.editFields} value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })} className={classes.greyBox} />
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={5} className={classes.gridItem}>
                                                <Typography variant="subtitle2" className={`${classes.fieldTitle} ${classes.font20}`}>Attach File</Typography>
                                                <Grid item container justifyContent="center" alignContent="center" className={classes.greyBox}>
                                                    <Button data-test-id="browse" disabled={!this.state.editFields} onClick={() => this.setState({ fileModal: true })}
                                                        className={`${classes.blueButton} ${classes.font13}`} variant="contained" component="span">
                                                        Browse
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                        
                    </Modal>
                )}
            </NotificationContext.Consumer>
        );
    }
}
export {TransactionDetailsModal}
export default withStyles(useStyles)(TransactionDetailsModal);
// Customizable Area End
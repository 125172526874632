//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import moment from 'moment'
import BillAndSubSettingController from "./BillAndSubSettingController.web";

const useStyles = (theme) => ({
  mainDiv:{
    minHeight: "100vh",
  },
  formDiv:{
    gap: "20px"
  },
  textField:{
    width: "100%",
    "& input":{
      fontSize: "25px",
      "&::placeholder":{
        color: "#000000"
      },
    },
  },
  textGrid:{
    gap: "20px"
  },
  pageHeader:{
    color: "#FF9933",
    borderBottom: "3px solid #AEAEAE"
  },
  blueButton:{
    marginTop: "20px",
    width: "60%",
    background: "#5597F4",
    borderRadius: "20px",
    color: "#ffffff",
    "&:hover":{
      background: "#5597F4",
    }
  },
  bottomDiv:{
    gap: "10px"
  },
  boldFont:{
    fontWeight: "600"
  },
  link:{
    textDecoration: "none",
    color: "#5597F4"
  },
  font30:{
    fontSize: "30px"
  },
  font25:{
    fontSize:"25px"
  },
  font13:{
    fontSize: "13px"
  },
  font18:{
    fontSize: "18px"
  },
  font17:{
    fontSize: "17px"
  },
  '@media (max-width:1425px)': {
    font30:{
      fontSize: "22.5px"
    },
    font25:{
      fontSize:"18.75px"
    },
    font13:{
      fontSize: "9.75px"
    },
    font18:{
      fontSize: "13.5px"
    },
    font17:{
      fontSize: "12.75px"
    },
    textField:{
      "& input":{
        fontSize:"18.75px"
      },
    },
  }
})
class EFTPSSettingWeb extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
      <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.mainDiv}>
        <Grid item container xs={8} justifyContent="center" alignItems="center" className={classes.formDiv}>
          <Grid item container xs={12} justifyContent='center' alignItems="center">
            <Grid item container xs={3} justifyContent='center' alignItems="center" className={classes.pageHeader}>
              <Typography className={`${classes.font30} ${classes.boldFont}`}>EFTPS</Typography>
            </Grid>
            <Grid item container xs={12} justifyContent='center' alignItems="center">
              <Typography className={classes.font25}>Electronic Federal Tax Payment System</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.textGrid} justifyContent='center' alignItems="center">
            <Grid item container xs={8}>
              <TextField className={classes.textField} placeholder="SSN"/>
            </Grid>
            <Grid item container xs={8}>
              <TextField className={classes.textField} placeholder="PIN"/>
            </Grid>
            <Grid item container xs={8}>
              <TextField className={classes.textField} placeholder="Password"/>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.bottomDiv}>
            <Grid item container xs={5} justifyContent="center" alignItems="center">
              <Button variant="contained" className={classes.blueButton}>
                <Typography className={classes.font13}>
                  SAVE
                </Typography>
              </Button>
            </Grid>
            <Grid item container xs={8} justifyContent="center" alignItems="center" >
              <Typography className={classes.font18}>If you do not have an EFTPS account</Typography>
              <Typography className={classes.font18}>Instruct you on how to create one.</Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center" alignItems="center">
              <Typography className={`${classes.font17} ${classes.boldFont}`} >Click <Link to="/Dashboard/companysettings/eftpsinstruction" className={classes.link}>Here</Link> for Instructions to Create an EFTPS Account.</Typography>
            </Grid>
          </Grid>  
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(EFTPSSettingWeb)


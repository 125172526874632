// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  InputBase,
  Divider,
  Snackbar
} from "@material-ui/core";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON
} from "./constant";
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import VerifyAccountController from './VerifyAccountController.web';
import { ReactComponent as VisaSvg } from '../assets/Visa.svg';
import {ReactComponent as ChevronLeftSvg} from '../assets/chevron-left.svg';
import ManagePaymentInfoWeb from "./ManagePaymentInfo.web";
import { discoverCard } from "./assets";
import { MasterCard } from "./assets";
import { AmericanExpress } from "./assets";
import { ReactComponent as QuestionSvg } from '../assets/question-circle.svg';



const useStyles = (theme) => ({

  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    margin:"10px 0px",
    '@media (max-width:1425px)':{
      fontSize:21.75
        }
  },
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  headerText:{
    fontSize:25,
    fontWeight:500,
    color:"#3F4144",
    justifyContent:"center",
    alignItems:"center",
    '@media (max-width:1425px)':{
      fontSize:18
        }
  },
  paddingLeft:{
    padding:"20px 10px 20px 0px" 
  },
  desText:{
    fontSize:13,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:9.75
        }
  },
  visa:{
    border: "1px solid #000000",
    padding: "0px 5px 0px 5px",
    height:31,
    width:62,
    '@media (max-width:1425px)':{
      width:41,
    height:22,
        }

  },
  logo:{
    width:62,
    height:33,
    '@media (max-width:1425px)':{
      width:42,
    height:23,
        }
  },
  border:{
    borderTop:"1px solid #979797",
    borderBottom:"1px solid #979797",
    margin:"30px 0px "
  },
  verifyButton:{
    width:163,
    height:28,
    color:"white",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0"
  },
  padding:{
    paddingTop:20,
  },
  link:{
    color:"#5696F4",
    fontSize:18,
    fontWeight:400,
    marginTop:10,
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },
formBtnBlue:{
    margin:"20px 0px",
    backgroundColor: "#5597F4",
    width:119,
    height:28,
    borderRadius: "14px",
    "&:hover": {
        backgroundColor: "#5597F4",
    },
    "& p": {
        fontWeight: "bold",
        color: "#ffffff"
    },
},
formBtnFont: {
    color: "#3F4144",
    fontSize: "13px",
    fontWeight: 700,
    '@media (max-width:1425px)': {
        fontSize: "9.75px",
    },
},
textField:{
    border:"1px solid #3F4144",
    padding:"10px 0px 5px 10px",
    borderRadius:7, 
    margin:"10px 0px 0px 0px"
},
currentEmailInput:{
    padingBottom:0,
    marginBottom:0,
    width:"90%",
    '&::placeholder': {
        //textOverflow: 'ellipsis !important',
        color: '#AEAEAE',
        opacity: 1,
        fontWeight: "500",
        fontFamily: "Open Sans",
        fontSize: "18px",
        '@media (max-width:1425px)': {
            fontSize: "13.5px",
        },
    }
},
  
})


class AddPaymentDetailsWeb extends VerifyAccountController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  
  render() {
    const {classes} = this.props
    return (
      <form onSubmit={this.addPaymentCard}>
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" >
          
            <Grid item container xs={12} lg={12} md={12}sm={12}>
                <Grid item xs={2} lg={1} md={2}sm={2} className={classes.alignment}>
                <Link to="/Dashboard/companysettings/ManagePaymentInfo" component={ManagePaymentInfoWeb}style={{textDecoration:"none", }}>

                    <ChevronLeftSvg style={{marginTop:10, width:10, height:10}}/>
                  </Link>
                </Grid>
                <Grid item xs={8} lg={8} md={8}sm={8}>
                    <Link to="/Dashboard/companysettings/ManagePaymentInfo" component={ManagePaymentInfoWeb}style={{textDecoration:"none", }}>
                        <Typography className={classes.link}>
                        Manage Payment Information
                        </Typography>
                    </Link>

                </Grid>

            </Grid>
           <Grid item container lg={7} md={9} sm={10} xs={12} className={classes.padding} className={classes.alignment}>
               
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                 Enter your payment details
                    </Typography>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12}  className={classes.alignment} style={{marginBottom:10}}>
                    <Grid item xs={3} sm={2} md={2}lg={2}>
                         <VisaSvg  className={classes.visa}/>
                    </Grid>
                    <Grid item xs={3} sm={2} md={2}lg={2}>
                        <img src={MasterCard} className={classes.logo}/>
                    </Grid>
                    <Grid item xs={3} sm={2} md={2}lg={2}>
                    <img src={AmericanExpress} className={classes.logo}/>
                    </Grid>
                    <Grid item xs={3} sm={2} md={2}lg={2}>
                    <img src={discoverCard} className={classes.logo}/>
                    </Grid>
                </Grid>
                <Grid item container xs={10} lg={9} sm={9} md={9} className={classes.alignment} className={classes.textField}  >
                    <TextField
                    required
                    id="filled-read-only-input"
                    value={this.state.firstNameCard}
                    onChange={(e)=>{
                      this.setState({firstNameCard:e.target.value})
                    }}
                    placeholder="First Name"
                    fullWidth
                    className={classes.alignment}
                    InputProps={{
                        classes: { input: classes.currentEmailInput },
                        disableUnderline: true
                    }}
                    InputLabelProps={{
                        style: { color: '#AEAEAE',fontWeight:500, fontSize:12 },
                      }}
                    />

                </Grid>
                <Grid item container xs={10} lg={9} sm={9} md={9} className={classes.alignment} className={classes.textField}  >
                    <TextField
                    required
                    id="filled-read-only-input"
                    value={this.state.lastNameCard}
                    onChange={(e)=>{
                      this.setState({lastNameCard:e.target.value})
                    }}
                    placeholder="Last Name"
                    fullWidth
                    className={classes.alignment}
                    InputProps={{
                        classes: { input: classes.currentEmailInput },
                        disableUnderline: true
                    }}
                    InputLabelProps={{
                        style: { color: '#AEAEAE',fontWeight:500, fontSize:12 },
                      }}
                    />

                </Grid>
                <Grid item container xs={10} lg={9} sm={9} md={9} className={classes.alignment} className={classes.textField}  >
                    <TextField
                    required
                    id="filled-read-only-input"
                    value={this.state.cardNumberCard}
                    onChange={this.cardHandler}
                    placeholder="Card number"
                    fullWidth
                    className={classes.alignment}
                    InputProps={{
                        classes: { input: classes.currentEmailInput },
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        style: { color: '#AEAEAE',fontWeight:500, fontSize:12 },
                      }}
                    />
                     
                </Grid>
                <Grid item container xs={10} lg={9} sm={9} md={9} className={classes.alignment} className={classes.textField}  >
                    <TextField
                    required
                    id="filled-read-only-input"
                    value={this.state.expirationDateCard}
                    onChange={this.expiryIdHandler}
                    placeholder="Expiration Date (MM/YY)"
                    fullWidth
                    className={classes.alignment}
                    InputProps={{
                        // readOnly: true,
                        classes: { input: classes.currentEmailInput },
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        style: { color: '#AEAEAE',fontWeight:500, fontSize:12 },
                      }}
                    />

                </Grid>
                <Grid item container xs={10} lg={9} sm={9} md={9} className={classes.alignment} className={classes.textField}  >
                    <TextField
                    required
                    id="filled-read-only-input"
                    value={this.state.securityCodeCard}
                    onChange={(e)=>{
                      this.setState({securityCodeCard:(e.target.value).slice(0,3)})
                    }}
                    placeholder="Security Code (CVV)"
                    fullWidth
                    type="number"
                    className={classes.alignment}
                    InputProps={{
                        // readOnly: true,
                        endAdornment:(<InputAdornment>
                            {/* <LockOutlinedIcon style={{ fill: "#D4D4D4" }} /> */}
                           <QuestionSvg width="20" height="20"/>
                        </InputAdornment>),
                        classes: { input: classes.currentEmailInput },
                        disableUnderline: true
                    }}
                    InputLabelProps={{
                        style: { color: '#AEAEAE',fontWeight:500, fontSize:12 },
                      }}
                    />

                </Grid>
                <Grid item container xs={10} lg={9} sm={9} md={9} className={classes.alignment} className={classes.textField}  >
                    <TextField
                    required
                    id="filled-read-only-input"
                    value={this.state.zipCodeCard}
                    onChange={(e)=>{
                      this.setState({zipCodeCard:(e.target.value).slice(0,6)})
                    }}
                    placeholder="Billing Zip Code"
                    fullWidth
                    type="number"
                    className={classes.alignment}
                    InputProps={{
                        // readOnly: true,
                        classes: { input: classes.currentEmailInput },
                        disableUnderline: true
                    }}
                    InputLabelProps={{
                        style: { color: '#AEAEAE',fontWeight:500, fontSize:12 },
                      }}
                    />

                </Grid>
                <Grid item container xs={10} lg={9} sm={9} md={9} style={{textAlign:"justify",margin:"10px 0px 20px 0px"}}>
                    <Typography className={classes.desText}>
                    By clicking the “Save” button below, you agree that <b>Neeuh will automatically continue your membership and charge the membership fee (currently $00.00) to your payment method until you cancel.</b> You may cancel at any time to avoid future charges. To cancel, go to Company Settings click Billing & Subscription and click “Cancel Membership.”
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}md={12} sm={12} className={classes.alignment}>
                    <Button
                        variant="contained"
                        className={classes.formBtnBlue}
                       type="submit"
                    >
                        <Typography variant="body2" className={classes.formBtnFont}>save</Typography>
                    </Button>

                </Grid>
               
              
           </Grid>
           
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        </form>
    );
  }
}

export default withStyles(useStyles)(AddPaymentDetailsWeb)
// Customizable Area End
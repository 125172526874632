import React from "react";

//Customizable Area Start
import {
  withStyles,
  Checkbox,
  TextField,
  Typography,
  InputAdornment,
  Grid,
  Button,
  Snackbar,
  Box,
  Modal,
  createStyles
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";
import ForgotPasswordController from "./ForgotPasswordController";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
interface Props {
  id:string;
  navigation:any;
  classes:any;
}
//Customizable Area End
//Customizable Area Start
const useStyles = (theme:any) => createStyles({
  root: {
    paddingTop: "60px",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    position: "relative",
  },
  sideLeafs: {
    position: "absolute",
    bottom: "-5px",
    right: "-35px",
  },
  leafTL: {
    width: "100px",
    height: "100px",
    borderRadius: "0 40px",
    margin: "5px 0",
  },
  leafTR: {
    width: "100px",
    height: "100px",
    borderRadius: "40px 0",
    margin: "5px 0",
  },
  pink: {
    backgroundColor: "#ffebd6",
  },
  lBlue: {
    backgroundColor: "#B6D9FD",
  },
  dBlue: {
    backgroundColor: "#0D016A",
  },
  purple: {
    backgroundColor: "#d0cde1",
  },
  blue: {
    backgroundColor: "#5597F4",
  },
  orange: {
    backgroundColor: "#FF9A30",
  },
  pageTitle: {
    fontWeight: 700,
    fontSize: "40px",
    display: "inline-block",
    position: "relative",
    paddingBottom: "3px",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "32%",
      height: "5px",
      bottom: "-3",
      left: "34%",
      borderBottom: "5px solid #B5D8FD",
    },
    '@media (max-width:1425px)': {
      fontSize: "30px",
    },
  },
  underLine: {
    fontWeight: "bold",
    position: "relative",
    paddingBottom: "3px",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "33%",
      height: "20px",
      bottom: "-3",
      left: "38%",
      borderBottom: "0.5px solid #D6D6D6",
    },
  },
  leafGrid: {
    gap: "10px",
  },
  yellowText: {
    color: "#FFBB78",
  },
  form: {
    gap: "20px",
    zIndex: 1,
  },
  textBtn: {
    padding: "0 5px",
    cursor: "pointer",
    fontWeight: "bold",
  },

  formBtn: {
    textTransform: "none",
    backgroundColor: "#5597F4",
    borderRadius: "30px",
    width: "229px",
    height: "28px",
    "&:hover": {
      backgroundColor: "#5597F4",
    },
    "& p": {
      fontWeight: "bold",
      color: "#ffffff"
    },
    '@media (max-width:1425px)': {
      width: "210px",
      height: "21px",
    },
  },
  input: {
    marginLeft: "10px",
    '&::placeholder': {
      //textOverflow: 'ellipsis !important',
      color: '#3f4144',
      opacity: 1,
      fontWeight: "700",
      fontFamily: "Open Sans",
      fontSize: "20px",
      '@media (max-width:1425px)': {
        fontSize: "15px",
      },

    }
  },
  bodyText: {
    width: "85%",
    textAlign: "center",
    marginTop: "40px",
    marginBottom: "20px",
    fontSize: "20px",
    fontWeight: 400,
    '@media (max-width:1425px)': {
      fontSize: "15px",
      width: "68%",
    },
  },
  textFieldWidth: {
    width: "65%",
    '@media (max-width:1425px)': {

      width: "50%",
    },
  },
  buttonFont: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#ffffff",
    '@media (max-width:1425px)': {
      fontSize: "9.75px",
    },
  },
  emailVerifiedBodyFont: {
    fontSize: "20px",
    fontWeight: 400,
    color: "#3F4144",
    '@media (max-width:1425px)': {
      fontSize: "15px",
    },
  },
});
//Customizable Area End
class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  //Customizable Area Start
  //Customizable Area End

  render() {
    //Customizable Area Start
    const { classes } = this.props;
    return (
      <>

        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          className={classes.root}
        >
          <Grid
            item
            container
            direction="column"
            xs={10} sm={6} md={6}
            alignItems="center"
            className={classes.form}
          >
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h5" className={classes.pageTitle} >
                Recover Password
              </Typography>

            </Grid>
            {this.state.emailPasses ?

              <Box style={{
                backgroundColor: "#F3F3F3", width: "100%", height: "250px", display: "flex",

                justifyContent: "center",

                alignItems: "center", marginTop: "20px"
              }}>
                <Box style={{ display: "block" }}>
                  <EmailOutlinedIcon style={{ height: "38px", width: "60.17px", marginLeft: "40%" }} />
                  <Typography style={{ textAlign: "center" }} className={classes.emailVerifiedBodyFont}>
                    <br />If account exists, an email will be<br />sent out with further instructions. </Typography>
                </Box>



              </Box>

              :
              <>
                <Grid item container xs={12} justifyContent="center">

                  <Typography variant="body2" className={classes.bodyText}>
                    Enter the email associated with your account and we will send you
                    an email with instructions on how to reset your password.
                  </Typography>
                </Grid>
                <Grid item container xs={12} className={classes.textFieldWidth}>
                  <TextField
                    size="small"
                    value={this.state.email}
                    type="email"
                   onChange={this.recoveremail}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityIcon style={{ fill: "#000000" }} />
                        </InputAdornment>
                      ),
                      classes: { input: classes.input }
                    }}
                    fullWidth
                    placeholder="email or username"
                  />
                </Grid>
                <Grid item container xs={12} justifyContent="center" style={{ width: "65%" }}>
                  <Button
                    variant="outlined"
                    onClick={() => this.sendVerificationEmail(this.state.email)}
                    className={classes.formBtn}
                    fullWidth
                    style={{ marginTop: "65px" }}
                  >
                    <Typography variant="body1" className={classes.buttonFont}>EMAIL ME A RECOVERY LINK</Typography>
                  </Button>
                </Grid>
              </>
            }

          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showSnakbarError}
            onClose={() => {
              this.setState({
                showSnakbarError: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity="error"
              elevation={6} variant="filled"
            >Please use your valid email or username.</Alert>
          </Snackbar>
          {/* <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showSnakbarSuccess}
            onClose={() => {
              this.setState({
                showSnakbarSuccess: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity="success"
              elevation={6} variant="filled"
            >Sucessfully sent a reset link. Please check your email.</Alert>
          </Snackbar> */}
          {/* <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.sideLeafs}
            xs={6}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              className={classes.leafGrid}
            >
              <Grid item className={`${classes.leafTL} ${classes.orange}`} />
              <Grid item className={`${classes.leafTR} ${classes.lBlue}`} />
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              className={classes.leafGrid}
            >
              <Grid item className={`${classes.leafTR} ${classes.lBlue}`} />
              <Grid item className={`${classes.leafTR} ${classes.blue}`} />
              <Grid item className={`${classes.leafTR} ${classes.dBlue}`} />
            </Grid>
          </Grid> */}
<Modal style={{ display: "flex", 
justifyContent: "center", alignItems: "center", 
fontFamily: "Open Sans Roman" }} open={this.state.openModalLogin}
          onClose={this.handleClose}
 >
<div style={{ backgroundColor: "white",
 padding: "10px 10px 10px 10px", width: "fitContent", borderRadius: 5 }}>
            <Typography style={{ fontSize: 26, fontWeight: "bolder" }} >
              {this.state.title}
              </Typography>

<Typography style={{ fontSize: 18, color: "black", padding: "5 0 10 0", }}>{this.state.modalContent}
            </Typography>
<Button size="small" variant="contained" 
style={{ backgroundColor: "#5597F4", marginLeft: 35, justifyContent: "center", alignItems: "center",
color: "white", width: 30, height: 30, display: "flex",  borderRadius: 2, cursor: "pointer", }}
             onClick={this.handleClose}>OK
            </Button>
          </div>
        </Modal>
        </Grid>
      </>
    ); 
    //Customizable Area End
  }
}

export default withStyles(useStyles)(ForgotPassword);

import React from "react";
// import {getStorageData} from '../../../framework/src/Helpers';


let getNotificationCount = () => {
    let notificationCount = 0;
    return notificationCount;
}

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const NotificationContext = React.createContext({
    notificationCount: getNotificationCount(),
    getNotificationCountFun: (params: any) => {
    }
    , changeNotificationCount: (param: any) => {
        console.log('notification count has been changed...', param);
    }

});

export default NotificationContext;

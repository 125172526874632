import React from "react";
import {
  StyleSheet,
  View,
  Modal,
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";

type Props = React.PropsWithChildren<{
  visible: boolean;
  onRequestClose: () => void;
}>

interface S {
}

interface SS {
}

export default class CustomModal extends BlockComponent<Props, S, SS> {

  render() {
    const { visible, onRequestClose } = this.props;
    return (
      // Customizable Area Start
        <View style={styles.view}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={visible}
                onRequestClose={onRequestClose}>
                {this.props.children}
            </Modal>
        </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  view: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    zIndex:1,
    position: 'relative'
  },
});
// Customizable Area End
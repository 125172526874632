import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Alert } from "react-native";
import { Key } from "react";
import { createRef } from "react";
import { getStorageData } from "framework/src/Utilities";
import { Platform } from "react-native";
import { displayToastAlert } from "../../../components/src/CToastAlert";
let ImagePicker: any;
if (Platform.OS !== "web") {
  ImagePicker = require("react-native-image-crop-picker");
}
export type TransactionDataObjectType = {
  id: string,
  date: string,
  amount: string,
  name: string
}
export interface ExpenseItemType {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  id: number;
  name: string;
  account_id: number;
  is_active: boolean;
  tier: string;
  transaction_type: string;
  transaction_category_type: string;
  cost_of_goods: boolean;
  role_name: string;
  isSelected: boolean
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  expenseInfoModalVisible?: boolean;
  handleExpenseModal?: () => void;
  logout?: any
  handleBottomTab?: any;
  handleBottomTabShow?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  transactionData: TransactionDataObjectType[];
  transactionArray: any;
  token: any;
  activeIndex: number;
  allTransactionArray: any;
  isAllItem: boolean;
  imagesList:any;
  selectedImage:any;
  selectedExpense: ExpenseItemType[];
  expenseInfoModalVisible: boolean;
  isShowSingleTransactionModal: boolean;
  showModal:boolean,
  isUploadCamera:boolean;
  selectedIndex: any;
  image: any;
  isUpload: boolean;
  addNote: string;
  isSave: boolean;
  isEditable: boolean;
  isRemove: boolean;
  isAddDetail:boolean;
  isExpenseCategory: boolean;
  inputFieldData: any;
  total:number;
  remainingAmount:any;
  isEditSplit: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfGrabshowTransactionalInfoController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  showTransactionalInfoApiId: string = "";
  carousel: any;
  getTransactionDataApiCallId: string = "";
  saveDetailsApiCallId: any;
  addSplitTransactionId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      transactionData: [],
      transactionArray: [],
      selectedImage:[],
      selectedIndex: null,
      activeIndex: 0,
      allTransactionArray: [],
      imagesList:[],
      isAllItem: false,
      selectedExpense: [],
      expenseInfoModalVisible: false,
      isUploadCamera:false,
      isShowSingleTransactionModal: false,
      showModal: false,
      image: [],
      isUpload: false,
      addNote: "",
      isSave: false,
      isEditable: false,
      isRemove: false,
      isAddDetail: false,
      isExpenseCategory: false,
      inputFieldData: [],
      total:0,
      remainingAmount:0,
      isEditSplit: false,
     // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.carousel = createRef();
    // Customizable Area End

  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken()
    this.getTransaction()
    this.getTransactionalInfoDataApi()

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.calculateAndRemainingTotal();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.showTransactionalInfoApiId) {
      this.getTransactionDataAPISuccessCallback(responseJson)
    } else if (apiRequestCallId === this.getTransactionDataApiCallId) {
      this.transactionInfo(responseJson)
    } else if (responseJson.errors) {
     this.erroHandlerInfo(responseJson)
    }
  }

  setCategoryItem = (item: ExpenseItemType) => {
    let updatedData = this.state.transactionArray;
    updatedData = updatedData.map((mappedItem: ExpenseItemType) => {
      if (mappedItem.id === item.id) {
        return {
          ...mappedItem, attributes:
            { ...mappedItem.attributes, isSelected: !mappedItem.attributes.isSelected }
        }
      }
      else {
        return {
          ...mappedItem,
        }
      }
    })
    let data = updatedData.filter((item: ExpenseItemType) => item.attributes.isSelected === true)
    this.setState({ selectedExpense: data, transactionArray: updatedData });
  }
  // Customizable Area Start
  transactionInfo = (responseJson: any) => {
    const setNewData: ExpenseItemType[] = [];
    function updateAttributes(node: any) {
      if (Array.isArray(node.values) && node.values.length) {
        node.values.forEach((childNode: any) => {
          if (Array.isArray(childNode.values)) {
            updateAttributes(childNode);
          }
          if (childNode.attributes) {
            childNode.attributes['isSelected'] = false;
            setNewData.push(childNode);
          }
        });
      }
    }
    responseJson.data.forEach((dataNode: any) => updateAttributes(dataNode));
    this.setState({ transactionArray: setNewData, allTransactionArray: setNewData })

  }

  getTransactionDataAPISuccessCallback = (response: any) => {
    if (response.data) {
      this.setState({ transactionData: response.data.bank_transaction })
    }
  }

  erroHandlerInfo=(responseJson: any)=>{
    if (responseJson.errors[0]?.token) {
      this.props.logout();
    }
  }

  getTransactionalInfoDataApi = async () => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showTransactionalInfoApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expensesAPIEndPoint + new Date().getFullYear()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTransaction = async () => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.transactionalListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  };
  handlePressSplitTransaction= () => {
    this.setState({isAddDetail: !this.state.isAddDetail, isExpenseCategory: true})
    this.props.handleBottomTab()
  }
  handleBackPress = () => {
    this.handlePressSplitTransaction();
    this.props.handleBottomTabShow()
  };
  handleSpiltTransaction = () => {
    const { imagesList, selectedImage } = this.state;
    this.state.remainingAmount<0
      ? displayToastAlert("error", "Split should be same as base amount")
      :  this.handlePressSplitTransaction()

     let files:any = [];
     
    const handleImageToConvertBase64Promises = [];

    const processImage = (image:any) => {
      return new Promise<void>((resolve, reject) => {

        if (typeof this.handleImageToConvertBase64 === 'function') {
          this.handleImageToConvertBase64(image.uri)
            .then((base64ImageString) => {

              files.push({
                data: base64ImageString,
                filename: image.name,
              });
              resolve()
            })
            .catch((error) => {
              console.error('Error converting image to baseImage64 :', error)
              reject(error)
            });
        } else {
          reject(new Error('handleImageToConvertBase64 function is not defined or not a function.'));
        }
      });
    };

    if (imagesList && imagesList.length > 0) {
      for (const image of imagesList) {
        handleImageToConvertBase64Promises.push(processImage(image));
      }
    } else if (selectedImage) {
      handleImageToConvertBase64Promises.push(processImage(selectedImage));
    }

    Promise.all(handleImageToConvertBase64Promises)
      .then(() => {

        this.addSplitTransactionApiCaller(files)
      })
      .catch((error) => {

        console.error('Error converting image to base64:', error);
      });
      this.setState({isEditSplit: true, isAddDetail: !this.state.isAddDetail,})
  };

  addSplitTransactionApiCaller = async (files: any) => {
    const updatedSplit = Object.keys(this.state.inputFieldData).map(name => ({
      name,
      transaction_category_id: 3,
      amount: (parseInt(this.state.inputFieldData[name], 10) || 0),
      notes: this.state.addNote,
    }));

    const remaining = parseInt(this.state.remainingAmount);

    updatedSplit.push({
      name: 'remainingAmount',
      transaction_category_id: 3,
      amount: remaining,
      notes: this.state.addNote,
    });

    const data = {
      attributes: {
        expense_id: 1,
        transaction_category_id: this.state.selectedExpense[0]?.id,
        notes: this.state.addNote,
        expenses_type: "capital",
        files: files,
        split: updatedSplit,
      },
    };
    const httpBody = { data };
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addSplitTransactionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addExpenseSplitTransaction}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleEditSplit = () => {
    this.setState({isAddDetail: !this.state.isAddDetail})
  }

  handleInputChange = (index: any, value: any, name: any) => {

    
     if (/[^\d.]/.test(value)) {
      return;
      }

    this.setState(
      (prevState) => ({
        inputFieldData: {

          ...prevState.inputFieldData,
          [name]: value,
        }

      }),  
        () => {

        this.calculateAndRemainingTotal()

        }
    );
  };
  calculateAndRemainingTotal = () => {
    const { inputFieldData, transactionData, activeIndex } = this.state;
    const values = Object.values(inputFieldData);

    const sumofValue:any = values.reduce((acc:any, price:any) => acc + parseFloat(price), 0);
    let remaining = 0;
    if (transactionData && transactionData[activeIndex] && !isNaN(sumofValue)) {
      const listItemValue:any = transactionData[activeIndex].amount;
    remaining = listItemValue - sumofValue;
    }

    this.setState({ total: sumofValue, remainingAmount: remaining});
  };
  onClickDownArrow = () => {
    this.setState({ transactionArray: this.state.allTransactionArray, isAllItem: true });
  }
  handleExpenseModal = () => {
    this.setState({ expenseInfoModalVisible: !this.state.expenseInfoModalVisible });
  }
  handleSingleTransactionModal = () => {
    this.setState({ isShowSingleTransactionModal: !this.state.isShowSingleTransactionModal, isEditable: true })
    this.deleteCameraImage()
  }
  deleteCameraImage = () => {
      this.setState({
        showModal: false,
        selectedImage: [],
        imagesList:[],
        isUploadCamera: false,
        addNote: this.state.addNote
      });

  }

  handleNoteChange = (text: any) => {
    this.setState({ addNote: text })
  }
  handleImageToConvertBase64 = (imageUri: any) => {
    return new Promise((resolve, reject) => {
      const xmlhr = new XMLHttpRequest();
      xmlhr.onload = function () {
        const fileReader = new FileReader();
        fileReader.onloadend = function () {
          resolve(fileReader.result);
        };
        fileReader.readAsDataURL(xmlhr.response);
      };
      xmlhr.onerror = function (err) {
        reject(err);
      };
      xmlhr.responseType = 'blob';
      xmlhr.open('GET', imageUri, true);
      xmlhr.send(null);
    });
  };

  closeModal = () => {
    this.setState({ showModal: false, selectedIndex: null });
  };

  handleDeleteImage = (index: Key | null | undefined) => {
    this.setState({ showModal: true, selectedIndex: index })
  };
  deleteImage = () => {
      if (this.state.selectedIndex !== null) {
        const newImagesList = this.state.imagesList.filter((_: any, i: any) => i !== this.state.selectedIndex);
        this.setState({ imagesList: newImagesList, showModal: false, selectedIndex: null, selectedImage:[] });
      }
  };
  handleAddDetails = () => {
    const { addNote, imagesList, selectedImage } = this.state;
    const today = new Date().toISOString().split('T')[0];
    this.setState({ isShowSingleTransactionModal: false, showModal: false, isSave: true })
    let filesNew: any = [];
    const handleImageToConvertBase64Promises = [];

    const processExpenseImage = (imageNew: any) => {
      return new Promise<void>((resolve, reject) => {
        if (typeof this.handleImageToConvertBase64 === 'function') {
          this.handleImageToConvertBase64(imageNew.uri)
            .then((base64String) => {
              filesNew.push({data: base64String, filename: imageNew.name,});

              resolve();
            })
            .catch((error) => {
              reject(error);
              console.error('Error while converting selected images to base64:', error);
            });
        } else {
          reject(new Error('handleImageToConvertBase64 function is not defined or it is not a function.'));
        }
      });
    };

    if (imagesList && imagesList.length > 0) {
      for (const imageN of imagesList) {
        handleImageToConvertBase64Promises.push(processExpenseImage(imageN));
      }
    } else if (selectedImage) {
      handleImageToConvertBase64Promises.push(processExpenseImage(selectedImage));
    }
    Promise.all(handleImageToConvertBase64Promises)
      .then(() => {
        this.addDetailsApiCaller(filesNew, addNote, today)
      })
      .catch((error) => {
        console.error('Error while converting selected images to base64:', error);
      });
  };
  handleImageLibrary = () => {
    if (Platform.OS !== "web") {
      ImagePicker?.openPicker({
        width: 300,
        height: 400,
        imageLoader: "UNIVERSAL",
        mediaType: "any",
        multiple: false,
      }).then((response: any) => {
        if (!response) {
          return;
        }
        const selectedImageforLibrary = {
          uri: response.path,
          name: response.path.split("/").pop(),
          type: response.mime,
        };
        this.setState({ imagesList: [selectedImageforLibrary], image: selectedImageforLibrary, isUpload: true, isRemove: true });
      });
    }

  }
  addDetailsApiCaller = async (files: any, addNote: any, today: any) => {
    const token = await getStorageData('authToken')
    const data = {
      attributes: {
        amount: this.state?.transactionData[this.state.activeIndex]?.amount,
        transaction_category_id: this.state.selectedExpense[0]?.id,
        name: this.state?.transactionData[this.state.activeIndex]?.name,
        date: today,
        notes: addNote,
        files: files,
        expenses_type: "capital",
      },
    };
    const httpBody = {
      data
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addExpenseTransactions}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleDeleteSelectedImage = () => {
    this.setState({
      showModal: true,
    });

  };
  handleEditDetails = () => {
    this.setState({ isShowSingleTransactionModal: !this.state.isShowSingleTransactionModal })
  }
  handleCameraPress = () => {
    if (Platform.OS !== "web") {
      const options = {
        width: 300,
        height: 400,
        cropping: true,
      };
      ImagePicker?.openCamera(options).then((image: any) => {
        const selectImages = {
          uri: image.path,
          name: image.filename,
        }
        this.setState({ selectedImage: [selectImages], isUploadCamera: true, isEditable: false, isRemove: true });
      });
    }
  }
  handleSnapToItem = (index: any) => {
    this.setState({activeIndex: index})
  }  
  handleAddtoReport = () => {
    Alert.alert("In Progress");
  }
  // Customizable Area End
}

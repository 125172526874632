//@ts-nocheck
import React from "react";
import DashboardController from "./DashboardController";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
} from "@material-ui/core";
import { PlaidLink } from "react-plaid-link";
import Settings5Controller from "./Settings5Controller";
import BankInformationSettingController from './BankInformationSettingController.web'

const useStyles=(themes)=>({
})

class BankInformationSettingWeb extends BankInformationSettingController {
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  async componentDidMount(){
    this.setState({token: await StorageProvider.get('token')}
    ,()=> this.createToken())
  }
  render() {
    const {classes} = this.props
    return (
      <Grid item container xs={12} justifyContent="center">
        {this.state.linkToken && <PlaidLink token={this.state.linkToken} onSuccess={this.handleSuccess}>Link Bank Account</PlaidLink>}
      </Grid>
    );
  }
}

export default withStyles(useStyles)(BankInformationSettingWeb);

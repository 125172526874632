import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from "../../../framework/src/StorageProvider.web";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  editFlag: boolean;
  token: any;
  taxCategories: any;
  showButton: boolean;
  companyData: any;
  snackbarMessage: any;
  error: boolean;
  showSnackbar: boolean;
  companySettingsPageStatus: boolean;
  file: any;
  dragActive: boolean,
  localImage: any,
  reloadedPage: boolean,
  companyId: any
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class CompanySettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  companyGetId: any
  companyPutId: any
  companyCreateId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      token: '',
      showButton: false,
      editFlag: false,
      companyData: {},
      taxCategories: ['Individual', 'Sole Prop', 'S Copr', 'Unreimbersable Partnership Expenses(UPE)'],
      showSnackbar: false,
      snackbarMessage: '',
      error: false,
      companySettingsPageStatus: false,
      file: null,
      dragActive: false,
      localImage: false,
      reloadedPage: false,
      companyId: null
      // Customizable Area End
    };
  }

  async componentDidMount() {
    this.setState({ token: await StorageProvider.get('token') }, () => this.getcompanyData())
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
      this.showSnackbarMsg(responseJson.errors[0] || responseJson.errors, true)
    }
    switch (apiRequestCallId) {
      case this.companyGetId: {
        if (responseJson?.data) {
          responseJson.data.attributes.tax_id = responseJson.data.attributes?.tax_id?.replace(/-/g, "")
          responseJson.data.attributes.tax_id = responseJson.data.attributes?.tax_id?.substr(0, 2) + '-' + responseJson.data.attributes?.tax_id?.substr(2)
          responseJson.data.attributes.contact_phone_number = "(" + responseJson.data.attributes?.contact_phone_number?.substr(0, 3) + ")" + responseJson.data.attributes?.contact_phone_number?.substr(3, 3) + "-" + responseJson.data.attributes?.contact_phone_number?.substr(6)
          responseJson.data.attributes.company_phone_number = "(" + responseJson.data.attributes?.company_phone_number?.substr(0, 3) + ")" + responseJson.data.attributes?.company_phone_number?.substr(3, 3) + "-" + responseJson.data.attributes?.company_phone_number?.substr(6)
          let companyName = responseJson?.data?.attributes?.business_name || ''
          let companyEmail = responseJson?.data?.attributes?.contact_email || ''
          await StorageProvider.set("companyName", companyName)
          await StorageProvider.set("companyEmail", companyEmail)
          await StorageProvider.set("companyLogo", responseJson?.data?.attributes?.logo)
          this.setState({ companyData: responseJson.data.attributes, companyId: responseJson.data.id, file: responseJson.data.attributes.logo, localImage: responseJson.data.attributes.logo && "" }, () => {
            if (this.state.reloadedPage) window.location.reload()
          })
        }
        break;
      }
      case this.companyCreateId:
      case this.companyPutId: {
        if (responseJson?.data) {
          this.showSnackbarMsg("Details Updated", false)
          this.setState({ editFlag: false }, () => this.getcompanyData())
        }
        break;
      }
    }

  }
  // Customizable Area End

  // Customizable Area Start

  getcompanyData = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.companyGetId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companysettings}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async putcompanyData() {
    this.setState({ reloadedPage: true })
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    console.log(this.state.companyData, 'Data going to be updated')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.companyPutId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companysettings}`
    );
    const body = {
      data: {
        attributes: {
          ...this.state.companyData,
        }
      }
    }
    body.data.attributes.company_phone_number = body.data.attributes.company_phone_number?.replace(/[-\(\)]/g, "")
    body.data.attributes.contact_phone_number = body.data.attributes.contact_phone_number?.replace(/[-\(\)]/g, "")
    if (typeof this.state.file != 'string') {
      body.data.attributes.logo = this.state.file && { data: await this.toBase64(this.state.file) }
    } else {
      delete body.data.attributes.logo
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createCompanyData() {
    this.setState({ reloadedPage: true })
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.companyCreateId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCompany}`
    );
    const body = {
      data: {
        attributes: {
          ...this.state.companyData,
        }
      }
    }
    body.data.attributes.company_phone_number = body.data.attributes.company_phone_number?.replace(/[-\(\)]/g, "")
    body.data.attributes.contact_phone_number = body.data.attributes.contact_phone_number?.replace(/[-\(\)]/g, "")
    if (typeof this.state.file != 'string') {
      body.data.attributes.logo = this.state.file && { data: await this.toBase64(this.state.file) }
    } else {
      delete body.data.attributes.logo
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveUserCreatedCategoriesAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  gettingKeyAndValue = (data: any) => {
    let keys = Object.keys(data).map(el => el.split('_').join(" "))
    keys = keys.map(el => {
      if (el == 'over tax paid') {
        el = 'Over/(Under) Tax Paid'
      } else if (el == "qbi deduction") {
        el = "QBI deduction"
      } else if (el == "est taxable income") {
        el = "est. taxable income"
      } else if (el == "cost of goods") {
        el = 'Cost of Goods Sold'
      }
      return el
    })
    const values = Object.values(data)
    return { keys, values }
  }

  showSnackbarMsg = (msg: any, isError: boolean) => {
    if (typeof msg == 'string')
      this.setState({ snackbarMessage: msg, error: isError, showSnackbar: true })
  }

  updatingValue = (key: any, value: any) => {
    const newState: any = { [key]: value }
    this.setState(newState)
  }
  checkWebStatus() {
    if (window.location.pathname === "/Dashboard/companysettings/") {
      return true
    } else {
      return false
    }
  }


  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  // Customizable Area End
}

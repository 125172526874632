import { View, StyleSheet, Platform } from "react-native";
import React from "react";
import { deviceWidth, scaledSize } from "framework/src/Utilities";
import CustomToastAlert from './CustomToastAlert';
import Scale from "./Scale";

declare let window: any;
export const displayToastAlert = (type: string, msg: string) => window.Alert.displayToastAlert(type, msg);

export interface Props {
    navigation?: any;
}

interface S {
    isSuccessAlert: boolean;
    isErrorAlert: boolean;
    successMSG: string;
    errorMSG: string;
}

interface SS {
    id: any;
}
export default class CToastAlert extends React.Component<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isSuccessAlert: false,
            isErrorAlert: false,
            successMSG: "",
            errorMSG: "",
        };
        window.Alert = this;
    }

    displayToastAlert(type: string, msg: string) {
        if (type == 'success') {
            this.setState({ successMSG: msg, isSuccessAlert: true });
            setTimeout(() => {
                this.setState({ successMSG: "", isSuccessAlert: false });
            }, 3000);
        } else if (type == 'error') {
            this.setState({ errorMSG: msg, isErrorAlert: true });
            setTimeout(() => {
                this.setState({ errorMSG: "", isErrorAlert: false });
            }, 3000);
        }
    }

    render() {
        return (
            <View style={styles.container}>
                {this.state.isSuccessAlert && <CustomToastAlert leftColor="#16a136" rigthColor="#d4edda" icon="success" iconColor="#16a136" msg={this.state.successMSG} />}
                {this.state.isErrorAlert && <CustomToastAlert leftColor="#FF1717" rigthColor="#FFCECE" icon="error" iconColor="#FF002F" msg={this.state.errorMSG} />}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        top: scaledSize(35),
        alignSelf: "center",
        alignContent: "center",
        justifyContent: "flex-end",
    },
    body: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: deviceWidth - scaledSize(20),
        paddingLeft: scaledSize(10),
        borderRadius: scaledSize(8),
        backgroundColor: "#b6f8c4",
        height: Scale(42)
    },
    msg: {
        fontSize: scaledSize(12),
        color: "#000024",
    },
});
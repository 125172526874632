import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { table } from "console";
import { config } from "process";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
//import { json } from "stream/consumers";
// Customizable Area End

export const configJSON = require("./config");
//export const fora = require("../../dashboard/src/DashboardController.web");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    fillStatus: string,
    single: boolean,
    married: boolean,
    marriedFillingSeparate: boolean,
    headOfHousehold: boolean,
    numberOfDependencies: number,
    openQuestion: boolean,
    anchorE1: any,
    token: string,
    calculatedQbi: boolean,
    fixedPercentageTaxCalculation: boolean,
    estimatedTaxPayment: boolean,
    stateTaxes: boolean,
    subjectToEmploymentTaxes: boolean,
    dummyValue: boolean,
    checkedTriggeredValue: boolean,
    showSnackbar: boolean,
    snackbarMessage: any,




    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class TaxesSettingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getTaxSettingsId: any;
    updateSettingsId: any;
    updateTaxConfigId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            fillStatus: 'single',
            single: true,
            married: true,
            marriedFillingSeparate: true,
            headOfHousehold: true,
            numberOfDependencies: 0,
            openQuestion: false,
            anchorE1: null,
            token: '',
            calculatedQbi: false,
            fixedPercentageTaxCalculation: false,
            estimatedTaxPayment: false,
            stateTaxes: false,
            subjectToEmploymentTaxes: false,
            dummyValue: false,
            checkedTriggeredValue: false,
            showSnackbar: false,
            snackbarMessage: '',
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getTaxSettingsId) {
                    if (!responseJson.errors) {
                        console.log("--------->", responseJson.data.attributes);
                        if (responseJson.data.attributes) {
                            this.setState({
                                fillStatus: responseJson?.data?.attributes?.filing_status,
                                calculatedQbi: responseJson?.data?.attributes?.calculated_qbi,
                                fixedPercentageTaxCalculation: responseJson?.data?.attributes?.fixed_percentage_tax_calculation,
                                estimatedTaxPayment: responseJson?.data?.attributes?.estimated_tax_payments,
                                stateTaxes: responseJson?.data?.attributes?.state_taxes,
                                subjectToEmploymentTaxes: responseJson?.data?.attributes?.subject_to_employment_taxes,
                                numberOfDependencies: responseJson?.data?.attributes?.number_of_dependant
                            }),
                                this.updateFillingStatusFromApi()
                        }


                    } else {
                        //Check Error Response
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                        this.parseApiErrorResponse(responseJson);
                        this.parseApiCatchErrorResponse(errorReponse);
                    }
                } else if (apiRequestCallId === this.updateSettingsId) {
                    if (!responseJson.errors) {
                        //console.log("heloooo", responseJson)
                        this.getTaxSettingsData()
                    } else {
                        //Check Error Response
                        //console.log("hel", responseJson)
                        this.setState({ snackbarMessage: responseJson?.errors }, () => { this.setState({ showSnackbar: true }) })
                        //this.parseApiErrorResponse(responseJson);
                        //this.parseApiCatchErrorResponse(errorReponse);
                    }
                } else if (apiRequestCallId === this.updateTaxConfigId) {
                    this.getTaxSettingsData()
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    populateStorage(number: number, e: any) {
        if (number === 1) {
            this.setState({ numberOfDependencies: e.target.value })

        }
        console.log(this.state.numberOfDependencies)
        // console.log(e),
        //     await console.log(this.state.numberOfDependencies)
    }
    updateFillingStatus(e: any) {

        if (e.target.checked === true) {
            if (e.target.value === "single") {
                this.setState({ single: true, married: false, marriedFillingSeparate: false, headOfHousehold: false })

            }
            if (e.target.value === "married") {
                this.setState({ single: false, married: true, marriedFillingSeparate: false, headOfHousehold: false })
            }
            if (e.target.value === "marriedFillingSeparate") {
                this.setState({ single: false, married: false, marriedFillingSeparate: true, headOfHousehold: false })
            }
            if (e.target.value === "headOfHousehold") {
                this.setState({ single: false, married: false, marriedFillingSeparate: false, headOfHousehold: true })
            }

            this.setState({ dummyValue: true })
        }
        //(e.target.checked === false)
        else {
            if (e.target.value === "single" && this.state.single) {
                this.setState({ single: true, married: false, marriedFillingSeparate: false, headOfHousehold: false })
            }
            else if (e.target.value === "married" && this.state.married) {
                this.setState({ single: false, married: true, marriedFillingSeparate: false, headOfHousehold: false })
            }
            else if (e.target.value === "marriedFillingSeparate" && this.state.marriedFillingSeparate) {
                this.setState({ single: false, married: false, marriedFillingSeparate: true, headOfHousehold: false })
            }
            else if (e.target.value === "headOfHousehold" && this.state.headOfHousehold) {
                this.setState({ single: false, married: false, marriedFillingSeparate: false, headOfHousehold: true })
            }
            else {
                this.setState({ single: true, married: true, marriedFillingSeparate: true, headOfHousehold: true })

            }
            this.setState({ dummyValue: true })


        }





    }
    updateFillingStatusFromApi() {
        if (this.state?.fillStatus) {
            if (this.state?.fillStatus === "single") {
                this.setState({ single: true, married: false, marriedFillingSeparate: false, headOfHousehold: false })
            }
            else if (this.state?.fillStatus === "married") {
                this.setState({ single: false, married: true, marriedFillingSeparate: false, headOfHousehold: false })
            }
            else if (this.state?.fillStatus === "married_filing_seperate") {
                this.setState({ single: false, married: false, marriedFillingSeparate: true, headOfHousehold: false })
            }
            else if (this.state?.fillStatus === "head_of_household") {
                this.setState({ single: false, married: false, marriedFillingSeparate: false, headOfHousehold: true })
            }
        }
        else {
            this.setState({ single: true, married: false, marriedFillingSeparate: false, headOfHousehold: false })
        }
    }

    getTaxSettingsData = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": configJSON.exampleApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTaxSettingsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxSettingsApiEndpoint}/get_tax_section`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    updateTaxSettingsData = () => {
        //var formData = new FormData();
        // formData.append('filing_status', this.state.fillStatus);
        // formData.append('calculated_qbi', this.state.calculatedQbi);
        // formData.append('fixed_percentage_tax_calculation', this.state.fixedPercentageTaxCalculation);
        // formData.append('estimated_tax_payments', this.state.estimatedTaxPayment);
        // formData.append('state_taxes', this.state.stateTaxes);
        // formData.append('subject_to_employment_taxes', `${this.state.subjectToEmploymentTaxes);
        // formData.append('number_of_dependant', `${this.state.numberOfDependencies}`);
        // for (var form1 of formData) {
        //     console.log("formmmmm dataaa", form1);
        // }
        //var objectify123 = []
        // var a: any = {}
        // formData.forEach((values, key) => {
        //     a[key] = values

        //     //console.log("values", values, "keys", key)
        //     //objectify123[key]=values
        // })
        // var x = JSON.stringify(a)
        console.log("aaaa", this.handleCheckedOne())
        const body: any = {
            filing_status: this.handleCheckedOne(),
            calculated_qbi: this.state.calculatedQbi,
            fixed_percentage_tax_calculation: this.state.fixedPercentageTaxCalculation,
            estimated_tax_payments: this.state.estimatedTaxPayment,
            state_taxes: this.state.stateTaxes,
            subject_to_employment_taxes: this.state.subjectToEmploymentTaxes,
            number_of_dependant: this.state.numberOfDependencies,
        }
        // console.log("body", body)
        // let formData: any = {};
        // formData['filing_status'] = this.state.fillStatus;
        // formData['calculated_qbi'] = this.state.calculatedQbi;
        // formData['fixed_percentage_tax_calculation'] = this.state.fixedPercentageTaxCalculation;
        // formData['estimated_tax_payments'] = this.state.estimatedTaxPayment;
        // formData['state_taxes'] = this.state.stateTaxes;
        // formData['subject_to_employment_taxes'] = this.state.subjectToEmploymentTaxes;
        // formData['number_of_dependant'] = this.state.numberOfDependencies;


        //const body = formData

        const headers = {
            token: this.state.token,
            "Content-Type": configJSON.exampleApiContentType
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateSettingsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxSettingsApiEndpoint}/update_tax_setting`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);





    }

    updateTaxConfig = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": configJSON.exampleApiContentType
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const body = {
            "filing_status": "single",
            "calculated_qbi": false,
            "fixed_percentage_tax_calculation": true,
            "estimated_tax_payments": true,
            "state_taxes": true,
            "subject_to_employment_taxes": true,
            "number_of_dependant": 1
        }
        this.updateTaxConfigId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taxSettingsApiEndpoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleCheckedOne() {
        console.log("check valve v", this.state.single, this.state.married, this.state.marriedFillingSeparate, this.state.headOfHousehold)
        if (this.state.single === false || this.state.married === false || this.state.marriedFillingSeparate === false || this.state.headOfHousehold === false) {
            if (this.state.single === true) {
                return "single"
            }
            else if (this.state.married === true) {
                return "married"
            }
            else if (this.state.marriedFillingSeparate === true) {
                return "married_filing_seperate"
            }
            else if (this.state.headOfHousehold === true) {
                return "head_of_household"
            }
        }
        else {
            return ""
        }
    }


    // showErrorMsg = (msg: string) => {
    //     this.setState({ errorMsg: msg })
    //     setTimeout(() => this.setState({ errorMsg: '' }), 2000)
    // }

    // populateStates() {
    //     this.setState({
    //         tablesBeforeLine: [
    //             { name: "Tentative Profit", value: "0.00" },
    //             { name: "QBI Deduction", value: "0.00" },
    //             { name: "Est. Taxable Income", value: "0.00" },
    //             { name: "Federal Tax", value: "0.00" },
    //             { name: "Employment Tax", value: "0.00" }
    //         ],
    //         tablesAfterLine: [
    //             { name: "Total Tax", value: "0.00" },
    //             { name: "Tax Paid", value: "0.00" },
    //             { name: "Over/(Under) Paid", value: "0.00" }
    //         ],
    //         fixedBeforeLine: [
    //             { name: "Fixed Percentage", value: "0.00" },
    //             { name: "Tentative Profit", value: "0.00" },
    //             { name: "QBI Deduction", value: "0.00" },
    //             { name: "Est. Taxable Income", value: "0.00" }
    //         ],
    //         fixedAfterLine: [
    //             { name: "Calculated Tax", value: "0.00" },
    //             { name: "Tax Paid", value: "0.00" },
    //             { name: "Over/(Under) Paid", value: "0.00" }
    //         ],
    //         estimatedBeforeLine: [
    //             { date: "Due April 15", quarter: "1st Quarter Payment", value: "0.00" },
    //             { date: "Due June 15", quarter: "2nd Quarter Payment", value: "0.00" },
    //             { date: "Due September 15", quarter: "1st Quarter Payment", value: "0.00" },
    //             { date: "Due January 15", quarter: "2nd Quarter Payment", value: "0.00" }
    //         ],
    //         estimatedAfterLine: [
    //             { name: "Tax Paid", value: "0.00" },
    //             { name: "Over/(Under) Paid", value: "0.00" }
    //         ],
    //         stateBeforeLine: [
    //             { name: "Fixed Percentage", value: "0.00" },
    //             { name: "Est. Taxable Income", value: "0.00" }
    //         ],
    //         stateAfterLine: [
    //             { name: "Calculated Tax", value: "0.00" },
    //             { name: "Tax Paid", value: "0.00" },
    //             { name: "Over/(Under) Paid", value: "0.00" }
    //         ]

    //     })
    // }
    // closeModal() {
    //     this.setState({ federalTaxPayment: false, paymentConfirm: false })
    // }





    // Customizable Area End
}

//@ts-nocheck
import React from "react";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import StorageProvider from "../../../framework/src/StorageProvider.web";
import Menu from '@material-ui/core/Menu';
// Customizable Area Start
// Customizable Area End
import {
    // Customizable Area Start
    AppBar,
    Typography,
    Modal,
    Button,
    withStyles,
    Grid,
    TextField,
    TextareaAutosize,
    MenuItem,
    Snackbar,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch
    // Customizable Area End
} from "@material-ui/core";
import Select from "@material-ui/core/Select"
import AddOrEditAccountModalController from "./AddOrEditAccountModalController";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Alert from '@material-ui/lab/Alert';
// Customizable Area Start
// Customizable Area End
const useStyles = (themes) => ({
    // Customizable Area Start
    // Customizable Area End
    modalTitleRoot: {
        padding: 0,
    },
    modalTitle: {
        position: "relative"
    },
    spaceLR: {
        paddingLeft: '10px',
        paddingRight: '1px'
    },
    addAccountButton: {
        margin: "10px",
        width: "200px",
        textAlign: "center",
        borderRadius: "20px",
        background: "#5597F4",
        padding: "5px 20px",
        "& h6": {
            color: "#ffffff",
            margin: 'auto'
        },
        "&:hover": {
            background: "#5597F4",
        }
    },
    floatingButtons: {
        position: "absolute",
        right: "0",
        top: "0"
    },
    floatingButtons2: {
        position: "absolute",
        right: "0",
        top: "15px"
    },
    floatingTitleButton: {
        right: "10%"
    },
    fontWhite: {
        color: "#ffffff"
    },
    fontBold: {
        fontWeight: "600"
    },
    addIcon: {
        padding: "0 10px",
        height: "100%",
        '& path': {
            strokeWidth: '1px'
        }
    },
    modalRoot: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paddingExtra: {
        padding: "20px 0"
    },
    modal2: {
        minHeight: "200px",
        padding: "10px 0",
        borderRadius: "10px",
        gap: "15px",
        background: "#ffffff",
        overflowY: "auto",
        position: 'relative',
        maxWidth: "750px",
    },
    modal2Height: {
        paddingTop: "20px",
        paddingBottom: "25px",
    },
    extraPadding: {
        padding: "10px 0"
    },
    viewSwitch: {
        width: "55px",
        height: "24px",
        padding: "0px"
    },
    switchBase: {
        color: "#818181",
        padding: "1px",
        width: "30px",
        "&$checked": {
            "& + $track": {
                backgroundColor: "orange"
            }
        }
    },
    thumb: {
        color: "white",
        width: "20px",
        height: "20px",
        margin: "1px"
    },
    track: {
        borderRadius: "20px",
        backgroundColor: "#818181",
        opacity: "1 !important",
        "&:after, &:before": {
            color: "white",
            fontSize: "10px",
            position: "absolute",
            top: "6px"
        },
        "&:after": {
            content: "'YES'",
            fontSize: "10px",
            left: "6px"
        },
        "&:before": {
            content: "'NO'",
            fontSize: "10px",
            right: "6px"
        }
    },
    checked: {
        color: "#FF9933 !important",
        transform: "translateX(26px) !important"
    },
    amountField: {
        width: "60px",
    },
    inputs: {
        borderBottom: "1px solid black"
    },
    fieldTitle: {
        fontWeight: "600"
    },
    greyBox: {
        width: "250px",
        height: "100px !important",
        border: "none",
        padding: "5px",
        backgroundColor: "#F7F7F7"
    },
    selectGrid: {
        marginTop: "20px"
    },
    greyFieldBox: {
        backgroundColor: "#F7F7F7"
    },
    icons: {
        width: "20px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    radioButton: {
        '& input + div': {
            color: '#3F4144',
            "& svg": {
                width: "20px",
                height: "20px"
            }
        }
    },
    blueButton: {
        margin: "5px",
        color: "#ffffff",
        background: "#5597F4",
        borderRadius: "20px",
        fontSize: "10px",
        "&:hover": {
            background: "#5597F4",
        }
    },
    gridItem: {
        margin: "10px 0",
    },
    formDate: {
        "& div": {
            minWidth: 0,
            border: "none !important"

        }
    },
    categorizeGrid: {
        borderLeft: "2px solid #F4F4F4"
    },
    categorizeTitle: {
        borderBottom: "2px solid #F4F4F4",
        height: "40px"
    },
    categorize: {
        margin: "20px 0",
        gap: "20px"
    },
    formButton: {
        borderRadius: "20px",
        border: "2px solid #FFB265",
        padding: "5px"
    },
    editField: {
        width: "80%"
    },
    formSelect: {
        border: "thin solid gray",
        padding: "4px",
        width: "100%",
        height: "30px",
        "& svg": {
            width: "20px",
        },
    },
    menuPaper: {
        maxHeight: "200px",
    },
    categoriesText: {
        width: "100%",
        padding: "3px 0",
        paddingLeft: "10px",
        textTransform: 'capitalize'
    },
    fileInput: {
        display: "none"
    },
    outlined: {
        background: '#EEEEEE'
    },
    longButton: {
        padding: "2px 15px",
        width: "100px"
    },
    pointerIcon: {
        cursor: "pointer",
        paddingRight: "5px"
    },
    borderTopBottom: {
        borderTop: "1px solid #000000",
        borderBottom: "1px solid #000000"
    },
    font25: {
        fontSize: "25px"
    },
    selectOptions: {
        padding: "5px",
        fontFamily: 'Open Sans'
    },
    font20: {
        fontSize: "20px",
        "& input": {
            fontSize: '20px'
        }
    },
    font16: {
        fontSize: "16px"
    },
    font13: {
        fontSize: "13px"
    },
    '@media (max-width:1425px)': {
        font20: {
            fontSize: "15px",
            "& input": {
                fontSize: '15px'
            }
        },
        fileSVG: {
            width: "54px",
            height: "64.5px",
        },
        font16: {
            fontSize: "12px"
        },
        font13: {
            fontSize: "9.75px"
        },
        font25: {
            fontSize: "18.75px"
        },
        category: {
            height: "55px"
        },
        modal1: {
            maxWidth: "600px"
        },
        modal2: {
            maxWidth: "600px"
        }
    },
    '@media (max-width:1250px)': {
        font20: {
            fontSize: "10px",
            "& input": {
                fontSize: '10px'
            }
        },
        font16: {
            fontSize: "9px"
        },
        font13: {
            fontSize: "7.3px"
        },
        font25: {
            fontSize: "14px"
        },
        category: {
            margin: "10px 15px"
        },
        userCategory: {
            margin: "10px 15px"
        },
        orangeCircle: {
            width: "90px",
            height: "90px"
        },
        blueCircleTransaction: {
            width: "90px",
            height: "90px"
        },
        whiteCircle: {
            width: "82.5px",
            height: "82.5px"
        },
        blueCircle: {
            width: "90px",
            height: "90px"
        },
        addIcon: {
            width: "25px",
            height: " 25px"
        },
        longButton: {
            width: "50px"
        },
        menuPaper: {
            minWidth: "40% !important"
        }
    }
})


class AddOrEditAccountModal extends AddOrEditAccountModalController {
    constructor(props: Props) {
        super(props);
        this.state = {
            ...this.state,
            // Customizable Area Start
            // Customizable Area End
        };
    }
    // Customizable Area Start
    // Customizable Area End
    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
        this.setState({ token: await StorageProvider.get('token'), role: await StorageProvider.get('role') }, () => {
            this.state.role != "SUPER_ADMIN" && this.setState({ valueOfCat: "user_created" })
        })
    }
    componentDidUpdate() {
        // Customizable Area Start
        // Customizable Area End
        if (!this.state.modalFlag) {
            this.props.handleAddOrEditModal(false)
        }
    }
    // Customizable Area Start
    // Customizable Area End
    selectCOG = (classes) => {
        return (
            // Customizable Area Start
            <>
                <Switch
                    classes={{
                        root: classes.viewSwitch,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                    }}
                    checked={this.state.cog}
                    onChange={(event) => {
                        this.setState({ cog: event.target.checked });
                    }}
                />
                <Typography className={`${classes.font20} ${classes.spaceLR}`}>Cost of Goods Sold</Typography>
                <HelpOutlineIcon className={classes.icons} />
            </>
            // Customizable Area End
        )
    }

    snackbar = (classes) => {
        return (
            // Customizable Area Start
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.state.showSnackbar}
                onClose={() => {
                    this.setState({
                        showSnackbar: false
                    });
                }}
                autoHideDuration={2000}
            >
                <Alert severity="error"
                    elevation={6} variant="filled"
                >{this.state.snackbarMessage}
                </Alert>
            </Snackbar>
            // Customizable Area End
        )
    }
    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <Modal open={true} onClose={() => this.props.handleAddOrEditModal(false)} className={classes.modalRoot}>
                <Grid item container justifyContent="center" alignItems="center" xs={6}
                    className={this.state.addAccountModal ? `${classes.modal2} ${classes.modal2Height}` : `${classes.modal2}`}>
                    {(!this.state.addAccountModal && !this.state.editAccountModal) && <>
                        <Grid item container justifyContent="center" className={classes.modalTitle} alignItems="center">
                            <AddCircleOutlineOutlinedIcon className={classes.addIcon} />
                            <Typography className={`${classes.font25} ${classes.paddingExtra}`}>
                                Add or Edit Account
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} justifyContent="center" alignItems="center">
                            <Typography className={`${classes.font20} ${classes.fontBold}`}>
                                Account Type:&nbsp;
                            </Typography>
                            <Typography className={classes.font20}>
                                {this.props.from ? ' Expense' : ' Income'}
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} justifyContent="center" alignItems="center">
                            <Button className={classes.addAccountButton} onClick={() => this.setState({ addAccountModal: true })}>
                                <Typography className={`${classes.font20} ${classes.fontBold} ${classes.fontWhite}`}>
                                    ADD ACCOUNT
                                </Typography>
                            </Button>
                            <Button className={classes.addAccountButton} onClick={() => this.setState({ editAccountModal: true }, () => this.getCategories(this.props.from))}>
                                <Typography className={`${classes.font20} ${classes.fontBold} ${classes.fontWhite}`}>
                                    EDIT ACCOUNT
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid className={classes.floatingButtons} justifyContent="center" alignContent="flex-end">
                            <Grid item container justifyContent="space-between" alignItems="center">
                                <ClearOutlinedIcon className={classes.pointerIcon} onClick={() => this.props.handleAddOrEditModal(false)} />
                            </Grid>
                        </Grid>
                    </>}
                    {this.state.addAccountModal &&
                        <>
                            {this.snackbar(classes)}
                            <Grid item container justifyContent="center" className={classes.modalTitle} alignItems="center">
                                <AddCircleOutlineOutlinedIcon className={classes.addIcon} />
                                <Typography className={`${classes.font25}`}>
                                    Add Account
                                </Typography>
                                <Grid className={`${classes.floatingButtons} ${classes.floatingTitleButton}`} justifyContent="center" alignContent="flex-end">
                                    <Button className={`${classes.blueButton} ${classes.longButton} ${classes.font13}`} onClick={() => this.createCategory(this.props.from)} variant="contained">Save</Button>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center" >
                                <Typography className={`${classes.font20} ${classes.fontBold}`}>
                                    Account Type:&nbsp;
                                </Typography>
                                <Typography className={classes.font20}>
                                    {this.props.from ? ' Expense' : ' Income'}
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="space-around" alignItems="center">
                                <Grid item container xs={10}><Typography className={`${classes.font20} ${classes.fontBold}`}>Name:</Typography></Grid>
                                <Grid item container xs={10}>
                                    <TextField
                                        autoFocus
                                        value={this.state.name}
                                        className={`${classes.font20}`}
                                        onChange={(e) => e.target.value.length <= 20 && this.setState({ name: e.target.value })}
                                        margin="dense"
                                        placeholder="Name"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} justifyContent="space-around" alignItems="center">
                                <Grid item container xs={10}>
                                    <Typography className={`${classes.font20} ${classes.fontBold}`}>Account Details:</Typography>
                                </Grid>
                                <Grid item container xs={10} justifyContent="space-between">
                                    <Grid item container xs={4} alignItems="center">
                                        <RadioGroup name="type" value={this.state.valueOfCat} onChange={(e) => this.setState({ valueOfCat: e.target.value })}>
                                            {this.state.role == "SUPER_ADMIN" ?
                                                <FormControlLabel value="schedule_c" control={<Radio className={classes.radioButton} />} label={<Typography className={classes.font20}>Schedule C</Typography>} />
                                                : <FormControlLabel value="user_created" control={<Radio className={classes.radioButton} />} label={<Typography className={classes.font20}>User-Created</Typography>} />
                                            }
                                        </RadioGroup>
                                    </Grid>
                                    {!!this.props.from &&
                                        <Grid item container xs={8} justifyContent="flex-end" alignItems="center">
                                            {this.selectCOG(classes)}
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid className={classes.floatingButtons2} justifyContent="center" alignContent="flex-end">
                                <Grid item container justifyContent="space-between" alignItems="center">
                                    <ClearOutlinedIcon className={classes.pointerIcon} onClick={() => this.props.handleAddOrEditModal(false)} />
                                </Grid>
                            </Grid>
                        </>}
                    {this.state.editAccountModal &&
                        <>
                            {this.snackbar(classes)}
                            <Grid item container justifyContent="center" className={classes.modalTitle} alignItems="center">
                                <AddCircleOutlineOutlinedIcon className={classes.addIcon} />
                                <Typography className={`${classes.font25}`}>
                                    Edit Account
                                </Typography>
                                <Grid className={`${classes.floatingButtons} ${classes.floatingTitleButton}`} justifyContent="center" alignContent="flex-end">
                                    <Button className={`${classes.blueButton} ${classes.longButton} ${classes.font13}`} onClick={() => this.updateCategory(this.props.from)} variant="contained">Save</Button>
                                </Grid>
                            </Grid>
                            <Grid item container xs={10} >
                                <Typography className={`${classes.font20} ${classes.fontBold}`}>
                                    Account Type:&nbsp;
                                </Typography>
                                <Typography className={classes.font20}>
                                    {this.props.from ? ' Expense' : ' Income'}
                                </Typography>
                            </Grid>
                            <Grid item container xs={10} className={classes.selectGrid}>
                                {this.state.categories.length ?
                                    <Select disabled={false}
                                        className={`${classes.formSelect} ${classes.font20}`}
                                        disableUnderline onChange={(e) => this.setState({
                                            category: e.target.value, name: this.state.categories.find(el => el.id == e.target.value).name,
                                            valueOfCat: this.state.categories.find(el => el.id == e.target.value).transaction_category_type, cog: this.state.categories.find(el => el.id == e.target.value).cost_of_goods
                                        })} value={this.state.category ? this.state.category : 0}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            classes: { paper: classes.menuPaper }
                                        }}
                                    >
                                        {this.state.categories.map(el =>
                                            <MenuItem value={el.id} className={`${classes.selectOptions} ${classes.font20}`}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                    : <></>
                                }
                            </Grid>
                            <Grid item container xs={12} justifyContent="space-around" alignItems="center">
                                <Grid item container xs={10}>
                                    <TextField
                                        autoFocus
                                        value={this.state.name}
                                        className={`${classes.font20}`}
                                        onChange={(e) => e.target.value.length <= 20 && this.setState({ name: e.target.value })}
                                        margin="dense"
                                        placeholder="Edit name here"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} justifyContent="space-around" alignItems="center">
                                <Grid item container xs={10}>
                                    <Typography className={`${classes.font20} ${classes.fontBold}`}>Account Details:</Typography>
                                </Grid>
                                <Grid item container xs={10}>
                                    <RadioGroup name="type" value={this.state.valueOfCat} onChange={(e) => this.setState({ valueOfCat: e.target.value })}>
                                        {this.state.role === "SUPER_ADMIN" && <FormControlLabel value="schedule_c" control={<Radio className={classes.radioButton} />} label={<Typography className={classes.font20}>Schedule C</Typography>} />}
                                        <FormControlLabel value="user_created" control={<Radio className={classes.radioButton} />} label={<Typography className={classes.font20}>User-Created</Typography>} />
                                    </RadioGroup>
                                </Grid>
                                {!!this.props.from &&
                                    <Grid item container xs={10} alignItems="center">
                                        {this.selectCOG(classes)}
                                    </Grid>
                                }
                                <Grid item container xs={10} justifyContent="center" alignContent="center">
                                    <Typography className={`${classes.font13} ${classes.extraPadding}`}>If you would like to edit the tier where this account will be displayed, please see Company Settings.</Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.floatingButtons2} justifyContent="center" alignContent="flex-end">
                                <Grid item container justifyContent="space-between" alignItems="center">
                                    <ClearOutlinedIcon className={classes.pointerIcon} onClick={() => this.props.handleAddOrEditModal(false)} />
                                </Grid>
                            </Grid>
                        </>
                    }

                </Grid>
            </Modal >
            // Customizable Area End
        );
    }
}

export default withStyles(useStyles)(AddOrEditAccountModal);
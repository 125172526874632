Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";



exports.validationApiContentType = "application/json";


exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfGrabshowTransactionalInfo";
exports.labelBodyText = "CfGrabshowTransactionalInfo Body";
exports.expensesAPIEndPoint = "bx_block_transactions/expenses?tax_year=";
exports.transactionalListEndPoint = "bx_block_transactions/transaction_categories/?transaction_type=expense"
exports.addExpenseTransactions = "bx_block_transactions/expenses"
exports.addExpenseSplitTransaction = "bx_block_transactions/split_expense"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
//@ts-nocheck
import React from "react";
//customizable area Start
import FormController from "./FormsController.web";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Checkbox,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  Snackbar,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import download from "../assets/download.png";
import envelop from "../assets/envelope.png";
import deleteIcon from "../assets/delete.png";
import upload from "../assets/upload.png";
import CloseIcon from "@material-ui/icons/Close";
import { Divider } from "react-native-elements";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import moment from 'moment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
//customizable area End

//customizable area Start
const useStyles = (theme) => ({
  fullDisplay: {
    width: "100%",
    height: "100%"
  },
  fullDis: {
    width: "100%",
    maxWidth: "100%"
  },
  colorSecondary: {
    color: "#5696f4",
  },
  dialogHeader: {
    margin: "0",
    padding: "15px",
    textAlign: "center",
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundColor: "red"

  },
  closeIcon: {
    position: "relative",
    left: "35%",
    top: "5px"
  },
  closeIcon1: {
    position: "relative",
    left: "30%",
    top: "5px"
  },
  buttonForm: {
    border: "2px solid #ff9933",
    borderRadius: "20px",
    fontSize: "0.7rem",
    fontWeight: "bold",
    height: "30px"
  },
  formHeadingText: {
    margin: "20px",
    fontSize: "25px",
    fontWeight: "700",
    '@media (max-width:1425px)': {
      fontSize: '18.75px',
    },

  },
  tabButton: {
    borderRadius: "20px",
    border: "1px solid #ffffff"
  },
  saveButton: {
    // width: "80%",
    background: "#5696F4",
    border: "1px solid #5696F4",
    "& h6": {
      color: "#ffffff"
    },
    "&:hover": {
      background: "#5696F4"
    }
  },
  boldFont: {
    fontSize: '13px',
    fontWeight: 'bold',
    '@media (max-width:1425px)': {
      fontSize: '9.75px',
      fontWeight: 'bold',
    },
  },
  root: {
    "&$checked": {
      color: "#5696F4",
      background: "none"
    },
    "&:hover": {
      background: "none"
    }
  },
  checked: {},
  reactMultiEmailInput: {
    border: 0,
    backgroundColor: '#f3f3f3',
    "&.focused": {
      backgroundColor: '#f3f3f3',
    },
    "&&& > span": {
      fontSize: "1rem",
      '@media (max-width:1425px)': {
        fontSize: '0.75rem',
      },
    },
  },
  textOnly: {
    fontSize: '20px',
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  textForm: {
    '@media (max-width:1425px)': {
      fontSize: '12px',

    },
  },
  text19: {
    fontSize: "19.2px",
    '@media (max-width:1425px)': {
      fontSize: '14.4px',

    },
  }
})
//customizable area End
export class FormsWeb extends FormController {

  // functionConvert(index: number) {
  //   console.log(this.state.row);
  //   if (this.dummyColumn[index].checked == true) {
  //     console.log("-->", this.dummyColumn);
  //     this.dummyColumn[index].checked = false;
  //     this.checked[index] = false;
  //     //console.log("<--", this.dummyColumn);
  //   }
  //   else {
  //     //console.log("hiiiiiiii", this.dummyColumn);
  //     this.dummyColumn[index].checked = true;
  //     this.checked[index] = true;
  //     //console.log("hiiiiiiii222222", this.dummyColumn);
  //   }


  // }


//customizable area Start
  async componentDidMount() {


    this.setState({
      column1:
        [
          { checked: true, name: "WorkersCompWaivers.pdf", date: "08/15/2021" },
          { checked: false, name: "W9.pdf", date: "08/15/2021" },
          { checked: false, name: "1040.pdf", date: "08/15/2021" }
        ],
      column2:
        [
          { checked: false, name: "Sep_FinancialStatement.pdf", date: "08/15/2021" },
          { checked: false, name: "Oct_FinancialStatement.pdf", date: "08/15/2021" },
          { checked: false, name: "01_01_2021-12_31_2021.pdf", date: "08/15/2021" }
        ],
      token: await StorageProvider.get('token'),
      companyName: await StorageProvider.get('companyName')
    })
    this.getFormData(this.props.taxYear);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.taxYear != this.props.taxYear) {
      console.log(nextProps.taxYear, '---------')
      this.getFormData(nextProps.taxYear);
    }
  }
  //customizable area End
  render() {
    //console.log(this.props);
    const { classes } = this.props;


    return (
    //customizable area Start
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.fullDis}

      >
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        <Dialog open={this.state.openEmail} fullWidth >
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showSnackbar}
            onClose={() => {
              this.setState({
                showSnackbar: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity={this.state.alertType}
              elevation={6} variant="filled"
            >{this.state.snackbarMessage}</Alert>
          </Snackbar>
          <DialogTitle className={classes.dialogHeader}>
            <img src={envelop} style={{ height: "20px", width: "20px", paddingRight: "15px", position: "relative", top: "4px" }} />
            Send Email
            <CloseIcon onClick={() => { this.closeModal() }} className={classes.closeIcon} />
          </DialogTitle>
          {this.state.emailSend ?
            <DialogContent style={{ overflow: "auto" }}>
              <Grid container direction="column" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                <Grid item xs={2} style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                  <Typography style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px", fontWeight: "bold", fontSize: "1.2rem" }}>

                    Email send successfully
                  </Typography>

                </Grid>

                <Grid item xs={2} className={classes.fillDis} style={{ maxWidth: "100%" }}>
                  <Grid container direction="row" justifyContent="center"  >
                    <Grid item xs={2} style={{ marginBottom: "10px" }}>
                      <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                        type="submit"
                        onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}
                      >
                        <Typography variant="subtitle2" className={classes.boldFont}>
                          ok
                        </Typography>
                      </Button>
                      {/* <Button className={classes.buttonForm} onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}>
                        ok
                      </Button> */}
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>

            </DialogContent>
            :
            <DialogContent style={{ overflow: "auto" }}>
              <FormControl style={{ width: "100%" }}>
                <Grid container direction="column" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                  <Box style={{ width: "80%", margin: "auto" }}>
                    <Grid item xs={2} style={{ height: "100%", width: "100%", maxWidth: "100%", marginBottom: "15px" }}>
                      <Typography className={classes.textForm}>
                        <Checkbox
                          // color="primary"
                          checked={this.state.sendCopyToYourself}
                          onChange={(e) => this.setState({ sendCopyToYourself: e.target.checked })}
                          classes={{
                            root: classes.root,
                            checked: classes.checked
                          }}
                        />
                        Send myself a copy
                      </Typography>
                      <Divider variant="inset" style={{ backgroundColor: "black" }} />
                    </Grid>
                    <Grid item
                      xs={2}

                      className={classes.fullDis} style={{
                        backgroundColor: "#f3f3f3", marginBottom: "15px",
                        // paddingLeft: "5px",
                        // paddingTop: "5px",
                        overflowY: 'overlay',
                        maxHeight: '60px'
                      }}>
                      <Typography className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink"
                        style={{
                          paddingLeft: '5px',
                          paddingTop: '5px',
                        }}>
                        To
                      </Typography>
                      <ReactMultiEmail
                        placeholder="Recipient"
                        className={classes.reactMultiEmailInput}
                        emails={this.state.emails}
                        onChange={(_emails: string[]) => {
                          this.setState({ emails: _emails });
                        }}
                        validateEmail={email => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void,
                        ) => {
                          return (
                            <div data-tag key={index} style={{ backgroundColor: '#fff' }}>
                              {email}
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item
                      xs={2}

                      className={classes.fullDis} style={{
                        backgroundColor: "#f3f3f3", marginBottom: "15px",
                        // paddingLeft: "5px",
                        // paddingTop: "5px",
                        overflowY: 'overlay',
                        maxHeight: '60px'
                      }}>
                      <Typography className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink"
                        style={{
                          paddingLeft: '5px',
                          paddingTop: '5px',
                        }}>
                        Cc
                      </Typography>
                      <ReactMultiEmail
                        placeholder="Recipient"
                        className={classes.reactMultiEmailInput}
                        emails={this.state.emailsCC}
                        onChange={(_emailsCC: string[]) => {
                          this.setState({ emailsCC: _emailsCC });
                        }}
                        validateEmail={email => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void,
                        ) => {
                          return (
                            <div data-tag key={index} style={{ backgroundColor: '#fff' }}>
                              {email}
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item
                      xs={2}

                      className={classes.fullDis} style={{
                        backgroundColor: "#f3f3f3", marginBottom: "15px",
                        // paddingLeft: "5px",
                        // paddingTop: "5px",
                        overflowY: 'overlay',
                        maxHeight: '60px'
                      }}>
                      <Typography className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink"
                        style={{
                          paddingLeft: '5px',
                          paddingTop: '5px',
                        }}>
                        Bcc
                      </Typography>
                      {/* <TextField
                        id="outlined-helperText"
                        label="Recipient"
                        placeholder="To"
                        style={{ width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={this.state.emailTo}
                        onChange={(e) => {
                          this.setState({
                            emailTo: e.target.value
                          });
                        }}
                      /> */}
                      <ReactMultiEmail
                        placeholder="Recipient"
                        className={classes.reactMultiEmailInput}
                        emails={this.state.emailsBCC}
                        onChange={(_emailsBCC: string[]) => {
                          this.setState({ emailsBCC: _emailsBCC });
                        }}
                        validateEmail={email => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void,
                        ) => {
                          return (
                            <div data-tag key={index} style={{ backgroundColor: '#fff' }}>
                              {email}
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.fullDis} style={{
                      backgroundColor: "#f3f3f3", marginBottom: "15px",
                      paddingLeft: "5px",
                      paddingTop: "5px"
                    }}>
                      <TextField
                        id="outlined-helperText"
                        label="Subject"
                        placeholder="Shared Documents from Neeuh"
                        style={{ width: "100%", }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={this.state.emailSubject}
                        onChange={(e) => {
                          this.setState({
                            emailSubject: e.target.value
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Grid container alignItems="center">
                        <AttachFileIcon fontSize="small" />
                        <Typography className={classes.textForm} style={{ paddingLeft: "10px" }}>Attached Documents</Typography>
                      </Grid>
                      <Divider variant="inset" style={{ backgroundColor: "black" }} />
                      {this.state.documentSection ?
                        <Grid >
                          {/* {this.state.deleteId.map((data, index) => { */}
                          {this.state.selectedDocForEmail.map((data1, index) => {
                            // return (
                            //   <>
                            //     {this.state.documentFormData.map((data1, index1) => {
                            return (
                              <>
                                <Grid container justifyContent="space-between" alignItems="center" style={{ backgroundColor: "#f3f3f3", margin: "5px 0" }}>
                                  <Grid item xs style={{ textAlign: "initial", }}>
                                    <Typography gutterBottom component="div" className={classes.textForm} style={{ paddingLeft: "15px", color: "#5696F4" }}>
                                      {data1?.attributes?.filename}
                                    </Typography>
                                  </Grid>
                                  <Grid item style={{ marginRight: "25px" }}>
                                    <CloseIcon onClick={() => { this.removeAttachedDoc(data1, "deleteId") }}
                                    // className={classes.closeIcon} 
                                    />
                                    {/* <Typography gutterBottom component="div">
                                                {moment(data1.attributes.form_date).format('MM/DD/YYYY')}
                                              </Typography> */}
                                  </Grid>
                                </Grid>
                              </>
                            )
                            //     })}
                            //   </>
                            // )

                          })}
                        </Grid>
                        :
                        <Grid >
                          {this.state?.selectedStatementForEmail?.map((data1, index) => {
                            // return (
                            //   <>
                            //     {this.state?.finantialFormData?.map((data1, index1) => {
                            return (

                              <>
                                <Grid container justifyContent="space-between" alignItems="center" style={{ backgroundColor: "#f3f3f3", margin: "5px 0" }}>
                                  <Grid item xs style={{ textAlign: "initial" }}>
                                    <Typography gutterBottom component="div" style={{ paddingLeft: "15px" }}>
                                      {data1?.attributes?.filename}
                                    </Typography>
                                  </Grid>
                                  <Grid item style={{ marginRight: "25px" }}>
                                    <CloseIcon onClick={() => { this.removeAttachedDoc(data1, "finantialDeleteId") }}
                                    //  className={classes.closeIcon} 
                                    />
                                    {/* <Typography gutterBottom component="div">
                                                {moment(data1.attributes.form_date).format('MM/DD/YYYY')}
                                              </Typography> */}
                                  </Grid>

                                </Grid>
                              </>
                              //     )
                              //   })}
                              // </>
                            )

                          })}
                        </Grid>
                      }
                    </Grid>
                  </Box>
                  <Grid item xs={2} className={classes.fullDis} style={{
                    backgroundColor: "#f3f3f3",
                    paddingLeft: "5px",
                  }}>
                    <TextField
                      required
                      id="outlined-multiline-static"
                      InputProps={{ disableUnderline: true }}
                      multiline
                      rows={6}
                      placeholder="Message"
                      value={this.state.emailMessage}
                      onChange={(e) => {
                        this.setState({
                          emailMessage: e.target.value
                        });
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>

                  <Grid item xs={2} className={classes.fillDis} style={{ maxWidth: "100%", marginTop: "5px" }}>
                    <Grid container direction="row" justifyContent="flex-end"  >

                      <Grid item xs={2}>
                        <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                          type="submit"
                          onClick={() => {
                            this.sendEmail();
                            // this.closeModal(), this.setState({ emailSend: false }) 
                          }}
                        >
                          <Typography variant="subtitle2" className={classes.boldFont}>
                            send
                          </Typography>
                        </Button>
                        {/* <Button type="submit" className={classes.buttonForm}
                        //onClick={() => { this.setState({ emailSend: true }) }}
                        >
                          send
                        </Button> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>

            </DialogContent>

          }

        </Dialog>
        <Dialog open={this.state.openUpload} fullWidth>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showSnackbar}
            onClose={() => {
              this.setState({
                showSnackbar: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity={this.state.alertType}
              elevation={6} variant="filled"
            >{this.state.snackbarMessage}</Alert>
          </Snackbar>
          <DialogTitle className={classes.dialogHeader}>
            <img src={upload} style={{ height: "20px", width: "20px", paddingRight: "15px" }} />
            Upload Document
            <CloseIcon onClick={() => { this.closeModal() }}
              className={classes.closeIcon1}
            />
          </DialogTitle>
          <DialogContent style={{ overflow: "auto" }}>
            {this.state.documentSection ?
              <Grid>
                <Typography style={{
                  marginBottom: '10px'
                }} className={classes.textForm}>Form Type: {this.docType}</Typography>
                <Grid item xs style={{
                  marginBottom: '10px'
                }}>
                  <input
                    multiple
                    onChange={(event) => {
                      console.log('files ', event.target.files);

                      this.setState({
                        //  file: event.target.files[0],
                        selectedFiles: [...event.target.files],
                        convertedFiles: []
                      });

                      for (let index = 0; index < event.target.files.length; index++) {
                        const element = event.target.files[index];
                        // event.target.files?.forEach(element => {
                        this.convertBase(element)
                        // });
                      }

                      // this.convertBase(event?.target?.files[0]);
                    }}
                    type="file"
                    style={{ display: "none" }}
                    id="enterFile"
                  />
                  <label htmlFor="enterFile">
                    <Button variant="contained" component="span" style={{ marginBottom: '5px' }}>
                      Browse
                    </Button>
                  </label>
                  {
                    this.state.selectedFiles?.map(e => (
                      <Typography className={classes.textForm}>{e.name}</Typography>
                    ))
                  }
                </Grid>
                <Grid container xs={12} style={{ justifyContent: "center", marginBottom: '10px', marginTop: '15px' }}>
                  <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                    type="submit"
                    onClick={() => { this.createFormData(0) }}>
                    <Typography variant="subtitle2" className={classes.boldFont}>
                      Submit
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              :
              <Grid>

                <Typography style={{
                  marginBottom: '10px'
                }} className={classes.textForm}>Form Type: {this.finType}</Typography>
                <Grid item xs style={{
                  marginBottom: '10px'
                }}>
                  <input
                    multiple
                    onChange={(event) => {
                      // this.setState({ file: event.target.files[0] }), this.convertBase(event?.target?.files[0]) 
                      console.log('files ', event.target.files);

                      this.setState({
                        //  file: event.target.files[0],
                        selectedFiles: [...event.target.files],
                        convertedFiles: []
                      });

                      for (let index = 0; index < event.target.files.length; index++) {
                        const element = event.target.files[index];
                        // event.target.files?.forEach(element => {
                        this.convertBase(element)
                        // });
                      }

                    }}
                    type="file"
                    style={{ display: "none" }}
                    id="enterFile"
                  />
                  <label htmlFor="enterFile">
                    <Button variant="contained" component="span"
                      style={{ marginBottom: '5px' }}>
                      Browse
                    </Button>
                  </label>
                  {
                    this.state.selectedFiles?.map(e => (
                      <Typography className={classes.textForm}>{e.name}</Typography>
                    ))
                  }
                  {/* <Typography>{this.state?.file?.name}</Typography> */}
                </Grid>
                {/* <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                  type="submit" onClick={() => { this.createFormData(1) }}>Submit 3</Button> */}
                <Grid container xs={12} style={{
                  justifyContent: "center",
                  marginBottom: '10px'
                }}>
                  <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                    type="submit"
                    onClick={() => { this.createFormData(1) }}>
                    <Typography variant="subtitle2" className={classes.boldFont}>
                      Submit
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            }

          </DialogContent>
        </Dialog>
        <Dialog open={this.state.openDownload} fullWidth>
          <DialogTitle className={classes.dialogHeader}>
            Download Form
            <CloseIcon onClick={() => { this.closeModal() }} className={classes.closeIcon} />
          </DialogTitle>
          <DialogContent style={{ overflow: "auto" }}>
            <Grid container direction="column" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
              <Grid item xs={2} style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                <Typography style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px", fontWeight: "bold", fontSize: "1.2rem" }}>

                  Forms are successfully downloaded
                </Typography>

              </Grid>

              <Grid item xs={2} className={classes.fillDis} style={{ maxWidth: "100%" }}>
                <Grid container direction="row" justifyContent="center"  >
                  <Grid item xs={2} style={{ marginBottom: "10px" }}>
                    <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                      type="submit"
                      onClick={() => { this.closeModal() }}
                    >
                      <Typography variant="subtitle2" className={classes.boldFont}>
                        ok
                      </Typography>
                    </Button>
                    {/* <Button className={classes.buttonForm} onClick={() => { this.closeModal() }}>
                      ok
                    </Button> */}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

          </DialogContent>
        </Dialog>
        <Dialog open={this.state.openDelete} fullWidth>
          <DialogTitle className={classes.dialogHeader}>
            <img src={deleteIcon} style={{ height: "20px", width: "20px", paddingRight: "15px" }} />
            Delete Form
            <CloseIcon onClick={() => { this.closeModal() }} className={classes.closeIcon} />
          </DialogTitle>
          {this.state.documentSection ?
            <>
              {/* {
                this.checkArray(this.state.documentsToBeDeleted)
              ?

                <DialogContent style={{ overflow: "auto" }}>

                  <Typography className={classes.text19} style={{
                    textAlign: "center",
                    marginBottom: "30px", fontWeight: "bold"
                  }}
                  >Please select forms to delete</Typography>
                  <Grid container direction="row" justifyContent="center"  >
                    <Grid item xs={2} style={{ marginBottom: "10px" }}>
                      <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                        type="submit"
                        onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}
                      >
                        <Typography variant="subtitle2" className={classes.boldFont}>
                          ok
                        </Typography>
                      </Button>
                    </Grid>

                  </Grid>

                </DialogContent>
                : */}
              <DialogContent style={{ overflow: "auto" }}>
                <Grid container direction="column" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                  <Grid item xs={2} style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                    <Typography style={{
                      textAlign: "center",
                      // marginTop: "30px",
                      marginBottom: "30px", fontWeight: "bold", color: "#5696F4"
                    }}>
                      {/* {this.state.deleteId.map((data, index) => {
                          return (
                            <> */}
                      {this.state.documentsToBeDeleted.map((data1, index1) => {
                        // {this.state.documentFormData.map((data1, index1) => {
                        return (
                          <Grid container justifyContent="space-between" alignItems="center" style={{ borderBottom: "1px solid black" }}>
                            <Grid item xs style={{ textAlign: "initial" }}>
                              <Typography gutterBottom component="div" className={classes.textForm}>

                                <Checkbox
                                  // color="primary"
                                  classes={{
                                    root: classes.root,
                                    checked: classes.checked
                                  }}
                                  checked={true}
                                />
                                {data1?.attributes?.filename}

                              </Typography>
                            </Grid>
                            <Grid item style={{ marginRight: "25px" }}>
                              <Typography gutterBottom component="div" style={{ color: '#000' }} className={classes.textForm}>
                                {moment(data1.attributes.form_date).format('MM/DD/YYYY')}
                              </Typography>
                            </Grid>

                          </Grid>
                        )
                      })}
                      {/* </>
                          )

                        })} */}
                    </Typography>

                  </Grid>

                  <Grid item xs={2} className={classes.fillDis} style={{ maxWidth: "100%" }}>
                    <Grid container direction="row" justifyContent="center"  >
                      <Grid item xs={2} style={{ marginBottom: "10px", marginRight: "15px" }}>
                        <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                          type="submit"
                          onClick={() => {
                            this.deleteFormData(0);
                            this.setState({ emailSend: false });
                            //  this.getFormData(),
                            // this.closeModal()
                          }}
                        >
                          <Typography variant="subtitle2" className={classes.boldFont}>
                            delete
                          </Typography>
                        </Button>
                        {/* <Button className={classes.buttonForm} onClick={() => { this.deleteFormData(), this.setState({ emailSend: false }), this.getFormData(), this.closeModal() }}>
                            delete
                          </Button> */}
                      </Grid>
                      <Grid item xs={2} style={{ marginBottom: "10px", marginLeft: "15px" }}>
                        <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                          type="submit"
                          onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}
                        >
                          <Typography variant="subtitle2" className={classes.boldFont}>
                            cancel
                          </Typography>
                        </Button>
                        {/* <Button className={classes.buttonForm}
                          onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}>
                            cancel
                          </Button> */}
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>

              </DialogContent>

              {/* } */}
            </>
            :
            <>
              {/* {
              this.checkArray(this.state.statementToBeDeleted) ?

                <DialogContent style={{ overflow: "auto" }}>

                  <Typography className={classes.text19} style={{
                    textAlign: "center",
                    marginBottom: "30px", fontWeight: "bold"
                  }}
                  >Please select forms to delete</Typography>
                  <Grid container direction="row" justifyContent="center"  >
                    <Grid item xs={2} style={{ marginBottom: "10px" }}>
                      <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                        type="submit"
                        onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}
                      >
                        <Typography variant="subtitle2" className={classes.boldFont}>
                          ok
                        </Typography>
                      </Button>
                    </Grid>

                  </Grid>

                </DialogContent>
                : */}
              <DialogContent style={{ overflow: "auto" }}>
                <Grid container direction="column" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                  <Grid item xs={2} style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                    <Typography style={{
                      textAlign: "center",
                      //  marginTop: "30px",
                      marginBottom: "30px", fontWeight: "bold"
                    }}>
                      {/* {this.state.finantialDeleteId.map((data, index) => {
                          return (
                            <> */}
                      {this.state.statementToBeDeleted.map((data1, index1) => {
                        return (
                          <Grid container justifyContent="space-between" alignItems="center" style={{ borderBottom: "1px solid black" }}>
                            <Grid item xs style={{ textAlign: "initial" }}>
                              <Typography gutterBottom component="div" className={classes.textForm}>

                                <Checkbox
                                  // color="primary"
                                  classes={{
                                    root: classes.root,
                                    checked: classes.checked
                                  }}
                                  checked={true}
                                />
                                {data1?.attributes?.filename}

                              </Typography>
                            </Grid>
                            <Grid item style={{ marginRight: "25px" }}>
                              <Typography gutterBottom component="div" className={classes.textForm}>
                                {moment(data1.attributes.form_date).format('MM/DD/YYYY')}
                              </Typography>
                            </Grid>

                          </Grid>
                        )
                      })}
                      {/* </>
                          )

                        })} */}
                    </Typography>

                  </Grid>

                  <Grid item xs={2} className={classes.fillDis} style={{ maxWidth: "100%" }}>
                    <Grid container direction="row" justifyContent="center"  >
                      {this.state.documentSection ?
                        <>
                          <Grid item xs={2} style={{ marginBottom: "10px", marginRight: "15px" }}>
                            <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                              type="submit"
                              onClick={() => {
                                this.deleteFormData(0); this.setState({ emailSend: false });
                                //  this.getFormData(), this.closeModal() 
                              }}
                            >
                              <Typography variant="subtitle2" className={classes.boldFont}>
                                delete
                              </Typography>
                            </Button>
                            {/* <Button className={classes.buttonForm} onClick={() => { this.deleteFormData(0), this.setState({ emailSend: false }), this.getFormData(), this.closeModal() }}>
                                delete
                              </Button> */}
                          </Grid>
                        </>
                        :
                        <>
                          <Grid item xs={2} style={{ marginBottom: "10px", marginRight: "15px" }}>
                            {/* <Button className={classes.buttonForm}
                              onClick={() => { this.deleteFormData(1), this.setState({ emailSend: false }), this.getFormData(), this.closeModal() }}>
                                delete
                              </Button> */}
                            <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                              type="submit"
                              onClick={() => {
                                this.deleteFormData(1);
                                this.setState({ emailSend: false });
                                //  this.getFormData(), this.closeModal() 
                              }}
                            >
                              <Typography variant="subtitle2" className={classes.boldFont}>
                                delete
                              </Typography>
                            </Button>
                          </Grid>
                        </>}

                      <Grid item xs={2} style={{ marginBottom: "10px", marginLeft: "15px" }}>
                        <Button variant="contained" className={`${classes.tabButton} ${classes.saveButton}`}
                          type="submit"
                          onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}
                        >
                          <Typography variant="subtitle2" className={classes.boldFont}>
                            cancel
                          </Typography>
                        </Button>
                        {/* <Button className={classes.buttonForm}
                          onClick={() => { this.closeModal(), this.setState({ emailSend: false }) }}>
                            cancel
                          </Button> */}
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>

              </DialogContent>

              {/* } */}
            </>}


        </Dialog>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item xs={6} className={classes.fullDis} style={{ textAlign: "center" }}>
            <Typography className={classes.formHeadingText}>Documents</Typography>
          </Grid>
          <Grid item xs={6} className={classes.fullDis} style={{ textAlign: "center" }}>
            <Typography className={classes.formHeadingText}>{this.state.companyName} Financial Statements</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.fullDis} style={{}}>
          <Grid container direction="column">
            <Grid item xs={11} className={classes.fullDis} style={{}}>
              <Grid container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.fullDis}
                style={{ height: "70vh", borderRight: "1px solid black", overflowY: "overlay" }}
              >
                <Grid item xs={11} className={classes.fullDisplay} style={{ maxWidth: "100%", borderTop: "1px solid black" }}>

                  <Box>
                    {this.state.documentFormData?.map((data, index) => {
                      return (
                        <Grid container alignItems="center" style={{ borderBottom: "1px solid black" }}>
                          <Grid item xs>
                            <Typography gutterBottom component="div" className={classes.textOnly} style={{ color: "#5696F4" }}>

                              <Checkbox
                                // color="primary"
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked
                                }}
                                onChange={(e) => {
                                  this.handleCheckBox(e, 0, data?.id);
                                }}
                                value={data?.id}
                                // checked={data?.isChecked}
                                checked={data?.isChecked ? true : false}
                              />
                              {data?.attributes?.filename}

                            </Typography>
                          </Grid>
                          <Grid item style={{ marginRight: "25px" }}>
                            <Typography gutterBottom component="div" className={classes.textOnly}>
                              {moment(data.attributes.form_date).format('MM/DD/YYYY')}
                            </Typography>
                          </Grid>

                        </Grid>
                      )
                    })}
                  </Box>

                </Grid>

              </Grid>

              <Grid item xs={1} className={classes.fullDis} style={{ marginTop: '15px', }}>
                <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end">
                  <Grid item xs={1}>
                    <img src={download} style={{ height: "20px", width: "20px" }} onClick={() => {
                      let arr = this.state.documentFormData.filter(e => e.isChecked);

                      if (arr.length <= 0) {
                        this.showSnackBar("Please select document to download.");
                        return;
                      }

                      arr.forEach(e => {
                        this.downloadPDF(e?.attributes?.attachment, e?.attributes?.filename);
                      })

                      // this.setState({ documentSection: true }), this.openModal(1) 
                    }} />
                  </Grid>
                  <Grid item xs={1}>
                    <img src={upload} style={{ height: "20px", width: "20px" }} onClick={() => { this.setState({ documentSection: true }), this.openModal(2) }} />
                  </Grid>
                  <Grid item xs={1}
                  >
                    <img src={envelop} style={{ height: "20px", width: "20px" }} onClick={() => {
                      let arr = this.state.documentFormData.filter(e => e.isChecked);

                      if (arr.length <= 0) {
                        this.showSnackBar("Please select document to mail.");
                        return;
                      }

                      this.setState({
                        documentSection: true, selectedDocForEmail: arr, sendCopyToYourself: false,
                        emailTo: "",
                        emailSubject: "",
                        emailMessage: "",
                      });
                      this.openModal(0);

                    }} />
                  </Grid>


                  <Grid item xs={1}>
                    <img src={deleteIcon} style={{ height: "20px", width: "20px", }} onClick={() => {
                      let arr = this.state.documentFormData.filter(e => e.isChecked);

                      if (arr.length <= 0) {
                        this.showSnackBar("Please select document to delete.");
                        return;
                      }

                      this.setState({ documentSection: true, documentsToBeDeleted: arr }), this.openModal(3)
                    }}
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.fullDis} style={{}}>
          <Grid container direction="column">
            <Grid item xs={11} className={classes.fullDis} style={{}}>
              <Grid container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                className={classes.fullDis}
                style={{ height: "70vh", overflowY: "overlay" }}
              >
                <Grid item xs={11} className={classes.fullDisplay} style={{ maxWidth: "100%", borderTop: "1px solid black" }}>
                  <Box>
                    {this.state.finantialFormData?.map((data, index) => {
                      return (
                        <Grid container alignItems="center" style={{ borderBottom: "1px solid black" }}>
                          <Grid item xs>
                            <Typography gutterBottom component="div" className={classes.textOnly} style={{ color: "#5696F4" }}>
                              <Checkbox
                                // color="primary"
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked
                                }}
                                onChange={(e) => {
                                  this.handleCheckBox(e, 1, data?.id);
                                }}
                                value={data?.id}
                                checked={data?.isChecked ? true : false}
                              />
                              {data?.attributes?.filename}
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginRight: "25px" }}>
                            <Typography gutterBottom component="div" className={classes.textOnly} >
                              {moment(data?.attributes?.form_date).format('MM/DD/YYYY')}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Box>
                </Grid>

              </Grid>

              <Grid item xs={1} className={classes.fullDis} style={{ marginTop: '15px' }}>
                <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end" style={{ paddingRight: "65px" }}>
                  <Grid item xs={1}>
                    <img src={download} style={{ height: "20px", width: "20px" }} onClick={() => {
                      let arr = this.state.finantialFormData.filter(e => e.isChecked);

                      if (arr.length <= 0) {
                        this.showSnackBar("Please select statement to download.");
                        return;
                      }

                      arr.forEach(e => {
                        this.downloadPDF(e?.attributes?.attachment, e?.attributes?.filename);
                      })
                      // this.setState({ documentSection: false }), this.openModal(1) 
                    }} />
                  </Grid>
                  <Grid item xs={1}>
                    <img src={upload} style={{ height: "20px", width: "20px" }} onClick={() => { this.setState({ documentSection: false }), this.openModal(2) }} />
                  </Grid>
                  <Grid item xs={1}>
                    <img src={envelop} style={{ height: "20px", width: "20px" }} onClick={() => {
                      let arr = this.state.finantialFormData.filter(e => e.isChecked);

                      if (arr.length <= 0) {
                        this.showSnackBar("Please select statement to mail.");
                        return;
                      }

                      this.setState({
                        documentSection: false, selectedStatementForEmail: arr, sendCopyToYourself: false,
                        emailTo: "",
                        emailSubject: "",
                        emailMessage: "",
                      });
                      this.openModal(0);
                    }} />
                  </Grid>


                  <Grid item xs={1}>
                    <img src={deleteIcon} style={{ height: "20px", width: "20px" }} onClick={() => {
                      let arr = this.state.finantialFormData.filter(e => e.isChecked);

                      if (arr.length <= 0) {
                        this.showSnackBar("Please select document to delete.");
                        return;
                      }

                      this.setState({ documentSection: false, statementToBeDeleted: arr }), this.openModal(3)

                    }} />
                  </Grid>
                </Grid>
              </Grid>


            </Grid>

          </Grid>
        </Grid>


      </Grid>



    //customizable area End
    );
  }
}

export default withStyles(useStyles)(FormsWeb);

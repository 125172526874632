import React, { Component } from "react";
// Customizable Area Start
import {
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,
    Modal,
    Image,
    TouchableWithoutFeedback,
} from "react-native";
import { DeleteImage } from "../../blocks/categoriessubcategories/src/assets";

// Customizable Area End
export default class DeletePhotoModal extends Component<any> {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { imageToBeDeleted,isUploadCamera,deleteCameraImage, selectedImage, deleteImage, closeModal } = this.props
        const [cameraImage] = selectedImage || []; 
        const uri = cameraImage ? cameraImage.uri : null;
        return (
            <View>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                    <View
                                  style={{
                                    marginBottom: 10,
                                    flexDirection:"row",
                                    width: "100%",
                                    position:"relative"
                                  }}
                                >
                                  <View style={{ width: "100%"}}>
                                  <Text
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontFamily: "OpenSans-Bold",
                                      fontWeight: "700",
                                      fontSize: 18,
                                      letterSpacing: 0,
                                      color: "#0F172A",
                                      width: '100%',
                                      textAlign: 'center',
                                    }}
                                  >{`Attached Photo`}</Text></View>
                                    <View style={styles.crossIcon}>
                                    <TouchableOpacity onPress={closeModal}>
                                      <Image source={require("./close_btn.png")} />
                                    </TouchableOpacity>
                                  </View>
                                </View>
                        {(imageToBeDeleted &&
                            imageToBeDeleted.uri) ? (
                            <View style={{ justifyContent: "center", alignItems: "center" }}>
                                <Image source={{ uri: imageToBeDeleted.uri }} style={styles.modalImage} />
                            </View>
                        ) : (

                            <View style={{ justifyContent: "center", alignItems: "center" }}>
                                <Image source={{ uri: uri }} style={styles.modalImage} />
                            </View>

                        )}

                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <TouchableOpacity style={styles.modalDeleteButton}>
                                <Image source={DeleteImage} style={styles.deleteIcon} />
                            </TouchableOpacity>
                            <Text style={{ fontSize: 13, fontWeight: "bold" }}>Delete Photo?</Text>
                        </View>
                        <View style={styles.modalButtons}>
                            <View>
                                <TouchableOpacity
                                    style={{
                                        borderColor: "#3876F1",
                                        height: 45,
                                        width: 150,
                                        borderRadius: 30,
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        margin: 3,
                                        backgroundColor: "white",
                                        alignItems: "center",

                                    }}
                                    onPress={closeModal}
                                >
                                    <Text style={{
                                        color: '#3876F1',
                                        fontSize: 16,
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        marginTop: 9
                                    }}>No</Text>
                                </TouchableOpacity>
                            </View>
                            <View>
                            <TouchableOpacity
                                    style={{
                                        borderColor: "blue",
                                        height: 45,
                                        width: 150,
                                        borderRadius: 30,
                                        margin: 3,
                                        backgroundColor: "#3876F1"
                                    }}
                                    onPress={isUploadCamera ? deleteCameraImage : deleteImage}
                                >
                                    <Text style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        marginTop: 9
                                    }}>Yes</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const styles = StyleSheet.create({
    crossIcon: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: 30,
        width: 30,
        borderRadius: 15,
        position:"absolute",
        right: 20,
      },
    centeredView: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        margin: 0,
    },
    modalImage: {
        justifyContent: "center",
        alignItems: "center",
        width: 130,
        height: 130,
        marginBottom: 20,
        margin: "auto"
    },
    modalText: {
        margin: 18,
        textAlign: 'center',
        fontWeight: "700",
        fontSize: 17,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        padding: 2
    },
    viewBtn: {
        backgroundColor: "blue",
        paddingVertical: 8,
        borderRadius: 4,
        marginTop: 10,
        borderWidth: 1,
        borderColor: "blue"
    },
    modalDeleteButton: {
        paddingVertical: 5,
        paddingHorizontal: 8,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#fff',
        height: 36,
        width: 36,
        borderRadius: 100,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#B3B3B3',
        marginBottom: 20
    },
    deleteButton: {
        position: 'absolute',
        zIndex: 4,
        top: 115,
        left: -1,
        color: "gray",
        paddingVertical: 5,
        paddingHorizontal: 8,
        backgroundColor: '#fff',
        height: 38,
        width: 38,
        borderRadius: 100,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#B3B3B3'
    },
    deleteIcon: {
        color: "gray",
        backgroundColor: "white",
        width: 16,
        height: 22,
    },
});
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isModalOpen: boolean;
  isModalConformOpen: boolean;
  reason:string;
  comments:string;
  password:string;
  showSnakbarError:boolean;
  errorMessage:string;
  email:string;
  passwordVisibility: boolean;
  subscriptionStripeId:string;
  subscriptionEndDate:string;
  redirectToSubscription : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionBillingController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getSubscriptionDetailsId:any;
  cancelSubscriptionId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isModalOpen: false,
      isModalConformOpen: false,
      reason:"",
      comments:"",
      password:"",
      showSnakbarError:false,
      errorMessage:"Something went wrong",
      email:"",
      passwordVisibility:false,
      subscriptionStripeId:"",
      subscriptionEndDate:"",
      redirectToSubscription: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.setState({isModalOpen:false})
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getSubscriptionDetailsId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              console.log(responseJson.data);
              this.setState({subscriptionStripeId:responseJson?.data[0].attributes?.subscription_stripe_id})
              const apiData : string = responseJson?.data[0]?.attributes?.expires_on;
              const date : Date = new Date(apiData);
              const options : Intl.DateTimeFormatOptions = {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              }
              this.setState({subscriptionEndDate: date.toLocaleDateString('en-US', options)})
            }
          }
        }else if(apiRequestCallId === this.cancelSubscriptionId){
          if(!responseJson.error){
            if(responseJson){
              this.setState({isModalConformOpen : true})
            }
          }
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSubscriptionDetails();
  }

  getSubscriptionDetails = () => {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token")
      }
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getSubscriptionDetailsId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subscriptionDetailsEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleModalClose = () => {
    this.setState({isModalOpen:false})
  }
  handleConformModalClose = () => {
    this.setState({isModalConformOpen:false, redirectToSubscription : true})
  }

  onSubmit = () => {
    if(this.state.reason === ""){
      this.setState({errorMessage:"Please select reason for leaving", showSnakbarError:true})
      return
    }
    if(this.state.comments.trim() === ""){
      this.setState({errorMessage:"Please enter some points to improve", showSnakbarError:true})
      return
    }
    if(this.state.password.trim() === ""){
      this.setState({errorMessage:"Please enter password", showSnakbarError:true})
      return
    }
    this.setState({isModalOpen:true})
  }

  handleChangePassword = (e:any) =>{
    this.setState({password: e.target.value});
  }

  handleChangeComments = (e:any) =>{
    this.setState({comments:e.target.value})
  }

  handleChangeReason = (e:any) =>{
    this.setState({reason: e.target.value})
  }

  togglePasswordVisibility = () => {
    this.setState({passwordVisibility : !this.state.passwordVisibility})
  }

  cancelSubscription = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelSubscriptionId = requestMessage.messageId;

    const attrs = {
      password: this.state.password,
      stripe_id: this.state.subscriptionStripeId,
      reason_for_leaving: this.state.reason,
      improve_description: this.state.comments
    };

    const data = {
      attributes: attrs,
    };

    const cancelBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(cancelBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodApiType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}

// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputBase,
  Divider,
  Snackbar
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import VerifyAccountController from './VerifyAccountController.web';
import { lockWithChecked } from "./assets";
import {ReactComponent as EnvelopeSvg} from '../assets/Envelope.svg';
import { CardPng } from "./assets";
const useStyles = (theme) => ({
  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    '@media (max-width:1000px)':{
      fontSize:21.75
       }
  },
  text:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:13.5
       }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  locksvg:{
    width:75,
    height:85,
    paddingBottom:10,
    '@media (max-width:1425px)':{
      width:56.25,
      height:63.75,
       }
  },
  headerText:{
    fontSize:18,
    fontWeight:700,
    color:"#3F4144",
    paddingRight:5,
    '@media (max-width:1425px)':{
      fontSize:13.5
       }
  },

  desText:{
    fontSize:16,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:12
       }
  },
  envelopesvg:{
    width:19,
    height:12
  },
  text2:{
    fontSize:18,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:13.5
       }
  },
  verifyButton:{
    width:163,
    height:28,
    color:"white",
    backgroundColor:"#5696F4",
    borderRadius:14,
    padding:"2px 0",
    fontSize:13,
    "&:hover": {
      backgroundColor: "#5696F4",
  },
  '@media (max-width:1425px)':{
    width:141.5,
    height:21,
    fontSize:9.75
     }
  },
  input:{
    paddingTop:4,
    PaddingLeft:20,
    width:40,
    color:"black",
    borderBottom:"1px solid #AEAEAE",
    '@media (max-width:1425px)':{
      width:30,
       }
    
  },
  padding:{
    paddingTop:80
  },
  link:{
    color:"#5696F4",
    fontSize:18,
    fontWeight:400,
    marginTop:10,
    '@media (max-width:1425px)':{
      fontSize:13.5
       }
  },
  
})
class CheckEmailViaCodeWeb extends VerifyAccountController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    const {classes} = this.props
    return (
      <form onSubmit={this.OnsubmitOtp}>
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" className={classes.padding}>
         
            <Grid item container lg={8} xs={8}  >
                  <Grid item xs={12} lg={12}  className={classes.alignment}>
                  <img src ={lockWithChecked} className={classes.locksvg}/>     
                  </Grid>
                  <Grid item xs={12} lg={12}  className={classes.alignment}>
                      <Typography className={classes.emailText}>
                      Check email for a code
                      </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12}  className={classes.alignment} >
                      <Typography className={classes.text}>
                      Please enter the code we sent to
                      </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12}  className={classes.alignment} >
                          <Typography className={classes.text}>
                              <b className={classes.headerText}>{this.state.userAccount.attributes?.email ?this.state.userAccount.attributes?.email: "noemailprovided"}
                                    </b> to help protect your Account
                          </Typography>
                  </Grid>
                  <Grid item container xs={12} lg={12} spacing={3} style={{margin:"10px 0 20px 0"}}>
                    
                      <Grid item xs={2} lg={2} md={2}>
                      <TextField 
                              required
                              id="txt1"
                              autoFocus={true}
                              className={classes.input}
                              onKeyUp={(event)=>{this.move(event,'','txt1','txt2')}}
                              value={this.state.firstnum}
                              onChange={(e)=>this.setState({firstnum:e.target.value})}
                            
                              InputProps={{disableUnderline:true,   
                              }}
                              inputProps={{
                                  maxLength: 1
                                }}
                                />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2} >
                          <TextField
                          required
                           value={this.state.secondnum}
                          id="txt2"
                          onKeyUp={(event)=>{this.move(event,'txt1','txt2','txt3')}}
                           onChange={(e)=>this.setState({secondnum:e.target.value})}
                            InputProps={{disableUnderline:true,   
                          }}
                          inputProps={{
                              maxLength: 1
                            }}
                          className={classes.input}
                          />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2} style={{justifyContent:"center", alignContent:"center"}}>
                          <TextField
                        required
                        
                         value={this.state.thirdnum}
                          id="txt3"
                          onKeyUp={(event)=>{this.move(event,'txt2','txt3','txt4')}}
                         onChange={(e)=>this.setState({thirdnum:e.target.value})}
                          InputProps={{disableUnderline:true,   
                          }}
                          inputProps={{
                              maxLength: 1
                            }}
                          className={classes.input}
                          />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2}>
                          <TextField
                          required
                           value={this.state.fourthnum}
                            id="txt4"
                            onKeyUp={(event)=>{this.move(event,'txt3','txt4','txt5')}}
                           onChange={(e)=>this.setState({fourthnum:e.target.value})}
                            InputProps={{disableUnderline:true,   
                            }}
                            inputProps={{
                                maxLength: 1
                              }}
                          className={classes.input}
                          />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2}>
                          <TextField
                            required
                             value={this.state.fifthnum}
                            id="txt5"
                            onKeyUp={(event)=>{this.move(event,'txt4','txt5','txt6')}}
                             onChange={(e)=>this.setState({fifthnum:e.target.value})}
                              InputProps={{disableUnderline:true,   
                            }}
                            inputProps={{
                                maxLength: 1
                              }}
                          className={classes.input}
                          />
                      </Grid>
                      <Grid item xs={2} lg={2} md={2}>
                          <TextField
                          required
                           value={this.state.sixthnum}
                            id="txt6"
                            onKeyUp={(event)=>{this.move(event,'txt5','txt6','')}}
                           onChange={(e)=>this.setState({sixthnum:e.target.value})}
                            InputProps={{disableUnderline:true,   
                            }}
                            inputProps={{
                                maxLength: 1
                              }}
                          className={classes.input}
                          />
                      </Grid>
                  </Grid>
                  <Grid item container xs={12} lg={12} className={classes.alignment}>
                    <Button variant="contained" type="submit" className={classes.verifyButton} >
                      Confirm Code
                    </Button>

                  </Grid>
                  <Grid item container xs={12} lg={12} className={classes.alignment}>

                  <Button variant="text" style={{textTransform:"none"}} className={classes.link} onClick={this.resendEmailVerificationCode}>
                      Resend Code
                    </Button>
                    
                    

                  </Grid>
            </Grid>
           
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}</Alert>
        </Snackbar>
        </form>
    );
  }
}

export default withStyles(useStyles)(CheckEmailViaCodeWeb)
// Customizable Area End
//@ts-nocheck
import React from "react";
import Alert from '@material-ui/lab/Alert';
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Routes from './Router'
import CompanySettingController, { Props, S, SS } from "./CompanyController.web";
const useStyles = (theme) => ({
  root: {
    overflowX: "hidden",
    position: "relative",
  },
  boldText: {
    color: "#3F4144",
    paddingLeft: "20px",
    fontWeight: "700",
    fontSize: "20px",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },

  },
  topDiv: {
    backgroundColor: "#F5F5F5",
    height: "50px",
    paddingLeft: "20px"
  },
  textBold: {
    fontWeight: "bold",
  },
  activeLink: {
    borderRadius: "20px",
    border: "2px solid #FFBB78",
  },
  link: {
    color: "#000",
    textDecoration: "none",
  },
  linkButton: {
    fontWeight: "bold",
    borderRadius: "20px",
    padding: "1px 15px",
    fontSize: "14px",
  },
  lBlue: {
    color: "#2a1d77",
    fontWeight: "300",
  },
  dBlue: {
    color: "#2a1d77",
  },
  logo: {
    width: "100px",
    marginBottom: "25px"
  },
  navBar: {
    boxShadow: "none",
  },
  wIcon: {
    fill: "#ffffff",
  },
  navImg: {
    backgroundColor: "#ffffff",
    border: "1px solid #ffffff",
    width: "25px",
    margin: "0 10px",
    height: "25px",
    borderRadius: "50%",
  },
  horizontalBar: {
    backgroundImage: "linear-gradient(to right, #15066A , #5696F4)",
    padding: "10px 2px",
  },
  verticalBar: {
    height: "100vh",
    position: "sticky",
    backgroundColor: "#ffffff",
    zIndex: 1,
  },
  navGrid: {
    borderRight: "1px solid #000000",
  },
  notificationNumber: {
    color: "#ffffff",
    fontSize: "11px",
    backgroundColor: "#000000",
    width: "20px",
    height: "20px",
    borderRadius: "10px 0",
    position: "absolute",
    right: "-6px",
    top: "-10px",
  },
  notification: {
    position: "relative",
    padding: "0 5px",
  },
  navLink: {
    display: 'flex',
    width: "100%",
    textDecoration: 'none',
    alignItems: "center",
    padding: "15px 0",
  },
  activeNavLink: {
    backgroundColor: "#5696F4",
    "& h6": {
      color: "#ffffff"
    }
  },
  iconStyle: {
    width: '20px',
    height: "20px",
    fill: "#ffffff",
    "& path": {
      fill: "#ffffff !important"
    }
  },
  leafTL: {
    width: "30px",
    height: "30px",
    borderRadius: "0 12px",
    margin: "2px",
  },
  leafTR: {
    width: "30px",
    height: "30px",
    borderRadius: "12px 0",
    margin: "2px",
  },
  leafs: {
    position: "absolute",
    bottom: "15px",
    left: "50%",
    transform: "translateX(-35%)"
  },
  iconLeaf: {
    border: '.5px solid #ffffff',
    borderRadius: "0 12px"
  },
  white: {
    backgroundColor: "#ffffff",
  },
  lBlue: {
    backgroundColor: "#B5D8FD",
  },
  dBlue: {
    backgroundColor: "#5696F4",
  },
  orange: {
    backgroundColor: "#FF9933"
  },
  buttons: {
    width: "95px",
    height: "25px",
    background: "#5597F4",
    borderRadius: "20px",
    color: "#ffffff",
    "&:hover": {
      background: "#5597F4"
    }
  },
  emptyGrid: {
    width: "95px"
  },
  font13: {
    fontSize: "13px"
  },
  '@media (max-width:900px)': {
    font13: {
      fontSize: '9.75px',
      '& input': {
        fontSize: '9.75px'
      }
    },
    buttons: {
      width: "71.25px",
      height: "18.75px"
    }
  }
});
class CompanySetting extends CompanySettingController {
  constructor(props) {
    if (!localStorage.getItem('token')) {
      window.location.href = '/EmailAccountRegistration';

    };
    super(props);
    this.state = {
      ...this.state
    };
  }
  // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
  //   this.props.updateLocalStorageData()
  // }
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showSnackbar}
          onClose={() => {
            this.setState({
              showSnackbar: false,
              error: false,
              snackbarMessage: ''
            });
          }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.error ? "error" : "success"}
            elevation={6} variant="filled"
          >{this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        <Grid item container alignItems="center" xs={12} justifyContent="flex-end" className={classes.topDiv}>
          <Grid item container xs={4} className={classes.buttonDiv} justifyContent="space-around">
            {this.state.showButton
              ? <>
                {!this.state.editFlag ? <Grid className={classes.emptyGrid} ></Grid> : <></>}
                <Button className={classes.buttons} onClick={() => this.updatingValue('editFlag', true)}>
                  <Typography variant="caption" className={classes.font13}>
                    Edit
                  </Typography>
                </Button>
                {this.state.editFlag ?
                  <Button className={classes.buttons} onClick={() => this.state.companyId ? this.putcompanyData() : this.createCompanyData()}>
                    <Typography variant="caption" className={classes.font13}>
                      Save
                    </Typography>
                  </Button>
                  : <></>
                }
              </>
              : <></>
            }
          </Grid>
        </Grid>
        <Grid item style={{ width: "21%" }} className={classes.navGrid}>
          <AppBar
            position="fixed"
            className={`${classes.navBar} ${classes.verticalBar}`}
          >
            <Grid container direction="column" alignItems="center">
              <NavLink to="/Dashboard/companysettings/" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container>
                  <Typography variant="subtitle2" className={classes.boldText}>
                    Company
                  </Typography>
                </Grid>
              </NavLink>
              <NavLink to="/Dashboard/companysettings/billPaymentHistory" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container>
                  <Typography variant="subtitle2" className={classes.boldText}>
                    Billing & Subscriptions
                  </Typography>
                </Grid>
              </NavLink>
              <NavLink to="/Dashboard/companysettings/transactions" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container >
                  {/* {this.checkWebStatus() ? */}
                  <Typography variant="subtitle2" className={classes.boldText}>
                    Transactions
                  </Typography>
                  {/* <Typography variant="subtitle2" className={classes.boldText}>
                      Transaction
                    </Typography>
                  } */}

                </Grid>
              </NavLink>
              <NavLink to="/Dashboard/companysettings/taxes" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container >
                  <Typography variant="subtitle2" className={classes.boldText}>
                    Taxes
                  </Typography>
                </Grid>
              </NavLink>
              <NavLink to="/Dashboard/companysettings/eftps" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container >
                  <Typography variant="subtitle2" className={classes.boldText}>
                    EFTPS
                  </Typography>
                </Grid>
              </NavLink>
              <NavLink to="/Dashboard/companysettings/bankinfo" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container >
                  <Typography variant="subtitle2" className={classes.boldText}>
                    Bank Information
                  </Typography>
                </Grid>
              </NavLink>
              <NavLink to="/Dashboard/companysettings/user" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container >
                  <Typography variant="subtitle2" className={classes.boldText}>
                    User Accounts
                  </Typography>
                </Grid>
              </NavLink>
              <NavLink to="/Dashboard/companysettings/other" exact={true} className={classes.navLink} activeClassName={classes.activeNavLink}>
                <Grid container >
                  <Typography variant="subtitle2" className={classes.boldText}>
                    Other Settings
                  </Typography>
                </Grid>
              </NavLink>
            </Grid>
          </AppBar>
        </Grid>
        <Grid item style={{ width: "79%" }}>
          <Grid item xs={12}>
            <Routes updatingValue={this.updatingValue} states={this.state} getcompanyData={this.getcompanyData} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(CompanySetting);

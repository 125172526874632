// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  activeTab: number;
  token: string;
  errorMsg: string;
  categoriesData: any;
  transactionData: any;
  catTypes: any;
  catByTier: any;
  openModal: boolean;
  openAddOrEditModal: boolean;
  addFlag: boolean;
  transactionId: any;
  dataLengths: any;
  snackbarMessage: any;
  showSnackbar: any;
  taxYear: any;
  leftNavigation: boolean;
  mainCount: any;
  openValbe: boolean;
  snackbarState: any;
  expenseReimbursement: boolean;
  dropEventDataTransferId: any
  expenseCategories: any;
  openExpenseCategoriesDropDown: boolean;
  showArrow: boolean;
  updateScroll: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class TransactionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  allCategoriesId: any;
  allTransactionId: any;
  updateCategoryId: any;
  expenseLengthId: any;
  checkBankId: any;
  expenseCategoriesMainId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeTab: 0,
      dataLengths: [0, 0],
      catByTier: [],
      openAddOrEditModal: false,
      transactionData: [],
      token: '',
      catTypes: [],
      transactionId: null,
      openModal: false,
      addFlag: true,
      errorMsg: '',
      categoriesData: [],
      snackbarMessage: '',
      showSnackbar: false,
      taxYear: moment().format('YYYY'),
      leftNavigation: true,
      mainCount: 0,
      openValbe: true,
      snackbarState: null,
      expenseReimbursement: false,
      dropEventDataTransferId: '',
      expenseCategories: [],
      openExpenseCategoriesDropDown: false,
      showArrow: true,
      updateScroll: false
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.allCategoriesId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              let data: any[] = []
              let transactionCategories: any = responseJson.data.find((el: any) => el.name == "static_categories").values?.
                filter((el: any) => el.attributes.name != 'other').
                map((el: any) => {
                  if (el.attributes.name == 'state_tax') {
                    el.attributes.name = 'State Taxes'
                  } else if (el.attributes.name == 'federal_tax') {
                    el.attributes.name = 'Federal Taxes'
                  } else {
                    el.attributes.name = el.attributes.name
                  }
                  return {
                    id: el.attributes.id, name: el.attributes.name.split('_').join(" ")
                  }
                })
              responseJson.data = responseJson.data.filter((el: any) => el.name !== 'static_categories')
              responseJson.data.forEach((el: any, index: number) => {
                if (el.values.length && el.name !== 'other_category') {
                  el.values.forEach((catEl: any) => {
                    data = [...data, ...catEl.values.map((el2: any) => {
                      let obj: any = {}
                      if (el2.attributes.is_active) {
                        let name = el2.attributes.name //.split('_').join(" ")
                        obj.name = name
                        if (catEl.tier == 'tier_one') {
                          obj.tier = 1
                        } else if (catEl.tier == 'tier_two') {
                          obj.tier = 2
                        } else if (catEl.tier == 'tier_three') {
                          obj.tier = 3
                        } else {
                          obj.tier = 0
                        }
                        obj.id = el2.attributes.id
                        obj.catFrom = index
                        obj.catType = 'Capital'
                        obj.isCOG = el.name.includes('_cost_of_goods')
                      }
                      return obj
                    })]
                  })
                }
              })

              
              if(this.state.activeTab === 0){
                transactionCategories = transactionCategories.filter((ele:any) => ele.name !="taxes");
                transactionCategories = [...transactionCategories, { id: 98, name: "Federal Taxes" },{ id: 99, name: "State Taxes" }]
              }
              this.setState({ catTypes: transactionCategories })
              data = data.filter(el => el.name)
              const dataByTier: any = []
              dataByTier.push(data.filter(el => !el.tier))
              dataByTier.push(data.filter(el => el.tier === 1))
              dataByTier.push(data.filter(el => el.tier === 2))
              dataByTier.push(data.filter(el => el.tier === 3))
              console.log(dataByTier)
              this.setState({ categoriesData: data, catByTier: dataByTier })
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else if (apiRequestCallId === this.allTransactionId) {
          if (!responseJson.errors) {
            let numberData = this.state.dataLengths
            let transactionData: any[] = []
            if (responseJson.data) {
              responseJson.data.bank_transaction.length && responseJson.data.bank_transaction.map((el: any) => {
                el.type = 0
                transactionData.push(el)
              })
              responseJson.data.credit_card_transaction.length && responseJson.data.credit_card_transaction.map((el: any) => {
                el.type = 1
                transactionData.push(el)
              })
              numberData[this.state.activeTab] = transactionData.length
              this.setState({ transactionData: transactionData }, () => this.setState({ showArrow: transactionData.length ? true : false, updateScroll: true }))
            } else {
              numberData[this.state.activeTab] = 0
            }
            this.setState({ dataLengths: numberData })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else if (apiRequestCallId === this.expenseLengthId) {
          let numberData = this.state.dataLengths
          if (responseJson.data) {
            const numberToAlter = this.state.activeTab ? 0 : 1
            numberData[numberToAlter] = responseJson.data.bank_transaction.length + responseJson.data.credit_card_transaction.length
            // numberData[1] = responseJson.data.bank_transaction.length + responseJson.data.credit_card_transaction.length
            this.setState({ dataLengths: numberData })
          }
        } else if (apiRequestCallId === this.updateCategoryId) {
          if (responseJson.data) {
            this.onLoad()
            this.setState({ showSnackbar: true, snackbarMessage: "Transaction Updated" })
          }
        } else if (apiRequestCallId === this.expenseCategoriesMainId) {
          if (responseJson.data) {
            let data: any[] = [{ name: 'Choose Category', id: "null" }]
            responseJson.data = responseJson.data.filter((el: any) => el.name !== 'other_category')
            let tier1Data: any[] = []
            let tier2Data: any[] = []
            let tier3Data: any[] = []
            responseJson.data.forEach((el: any, index: number) => {
              if (el.values.length && el.name !== 'static_categories') {
                let valuesDataN = el.values
                tier1Data = [...tier1Data, valuesDataN.filter((el: any) => el.tier == "tier_one")]
                tier2Data = [...tier2Data, valuesDataN.filter((el: any) => el.tier == "tier_two")]
                tier3Data = [...tier3Data, valuesDataN.filter((el: any) => el.tier == "tier_three")]
              }
            })
            const tierDataN = [...tier1Data, ...tier2Data, ...tier3Data].filter((el: any) => el.length)
            tierDataN.forEach((catEl: any) => {
              catEl.map((el: any) => {
                data = [...data,
                ...el.values.map((el2: any) => {
                  let objec: any = {}
                  if (el2.attributes.is_active) {
                    let name = el2.attributes.name.split('_').join(" ")
                    objec.name = name
                    objec.id = el2.attributes.id
                  }
                  return objec
                })]
              })
            })
            data = data.filter(el => el.name)
            this.setState({ expenseCategories: data, openExpenseCategoriesDropDown: true })
          }
          else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start


  getCategories = () => {
    this.setState({ categoriesData: [], catByTier: [] })
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allCategoriesId = requestMessage.messageId;
    const catOf = this.state.activeTab ? 'expense' : 'income'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/transaction_categories?transaction_type=${catOf}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getExpenseCategoriesMain() {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.expenseCategoriesMainId = requestMessage.messageId;
    const catOf = 'expense'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/transaction_categories?transaction_type=${catOf}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTransactionData = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allTransactionId = requestMessage.messageId;
    let fetchDataOf = this.state.activeTab ? 'expenses' : 'incomes'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/${fetchDataOf}?tax_year=${this.state.taxYear || moment().format('YYYY')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  checkBankData = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkBankId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.plaidAPIEndPonit}/get_attached_bank_Account`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkHorizontalNavigation = (scrollLeftValue: any) => {
    if (scrollLeftValue === 0) {
      this.setState({ leftNavigation: false })
      console.log("dekhi1", scrollLeftValue)
    } else {
      this.setState({ leftNavigation: true })
      console.log("dekhi2", scrollLeftValue)
    }
  }

  getExpenseLength = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.expenseLengthId = requestMessage.messageId;
    let fetchDataOf = this.state.activeTab ? 'incomes' : 'expenses'
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/${fetchDataOf}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateCategoryDND = (tranId: any, catId: any, reimbursement?: boolean) => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCategoryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transactionApiEndPoint}/categorized_transaction_updation`
    );
    const attributes = {
      "transaction_id": tranId,
      "transaction_category_id": catId,
      "transaction_type": this.state.activeTab ? "expense" : "income",
      "reimbursement": reimbursement ? true : false
    }
    const data = { attributes }
    const body = { data }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onLoad = () => {
    this.getCategories()
    this.getTransactionData()
    this.getExpenseLength()
  }

  switchTab = (tab: number) => {
    this.setState({ activeTab: tab, categoriesData: [], transactionData: [] }, () => {
      this.getCategories()
      this.getTransactionData()
    })
  }

  handleModal = (state: boolean, id: any) => {
        this.setState({ transactionId: id, openModal: state }, () => {
      this.getTransactionData()
    })
      }

  handleAddOrEditModal = (state: boolean) => {
    this.setState({ openAddOrEditModal: state }, () => {
      this.getCategories()
    })
  }

  showErrorMsg = (msg: string) => {
    this.setState({ errorMsg: msg })
    setTimeout(() => this.setState({ errorMsg: '' }), 2000)
  }

  dragStart = (event: any, id: any) => {
    console.log(id)
    event.dataTransfer.setData("id", id);
  }

  dropOver = (event: any, catId: any) => {
    this.updateCategoryDND(event.dataTransfer.getData('id'), catId)
  }

  // makeSlideWork = ()

  // Customizable Area End
}
// Customizable Area End

//@ts-nocheck
import React from 'react'
import {withStyles, createStyles, makeStyles,Typography, Grid} from '@material-ui/core'
import LandingPageController,{Props} from './LandingPageController'
import brandMark from '../assets/Brandmark_White.png'
import TopNav from '../../../components/src/TopNav'

const useStyles = (theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#15066A",
        overflow: "hidden",
        position: "relative",
     },
     svg :{
        display: "inline-block",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0
      },
     brandImg:{
        position: "absolute",
        width: "400px",
        bottom: "-70px",
        left: "-70px"
     },
     brandText:{
         width: "35%",
         position: "absolute",
         top: "20%",
         right: "5%"
     },
     leafTL:{
         width: "50px",
         height: "50px",
         borderRadius: "0 15px",
         margin: "5px 0"
     },
     leafTR:{
        width: "50px",
        height: "50px",
        borderRadius: "15px 0",
        margin: "5px 0"
     },
     blueText:{
        color: "#2a1d77",
     },
     orange:{
         backgroundColor: "#FF9933"
     },
     lBlue:{
        backgroundColor: "#B5D8FD"
     },
     dBlue:{
        backgroundColor: "#5696F4"
     },
});
class LandingPage extends LandingPageController{
    constructor(props: Props){
        super(props)
    }
    render(){
        const {classes} = this.props;
        return(
            <>
            <TopNav/>
            <div className={classes.root}> 
                <svg className={classes.svg} viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                    <path d="M0,10 C250,0 350,200 500,100 L500,0 L0,0 Z" style={{stroke: 0, fill:"white"}}/>
                </svg>
                <img src={brandMark} className={classes.brandImg}/>
                <Grid container alignItems="center" className={classes.brandText}>
                    <Grid item xs={10}>
                        <Typography align="right" variant="h5" className={classes.blueText}>Have you heard of Neeuh?</Typography>
                        <Typography align="right" variant="body2" className={classes.blueText}>We are committed to helping individual business owners reach their potential by educating and providing
                            tools and feedback that will help you understand the nuts 
                            and bolts of accounting and tax that can feel overwhelming and intimidating to the most knowledgeable of us.
                        </Typography>
                    </Grid>
                    <Grid item container direction='column' alignItems='center' xs={2}>
                        <Grid item className={`${classes.leafTL} ${classes.orange}`}></Grid>
                        <Grid item className={`${classes.leafTR} ${classes.lBlue}`}></Grid>
                        <Grid item className={`${classes.leafTR} ${classes.dBlue}`}></Grid>
                    </Grid>
                </Grid>
            </div>
            </>
        )
    }
}

export default withStyles(useStyles)(LandingPage)
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import NavigationMessage from "../../../framework/src/Messages/NavigationMessage";
import moment from "moment";
import TimeCalculator from "timecalup"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: any;
  userAccountRole: any;
  userAccountName: any;
  userAccountEmail: any;
  userData: any[];
  cardData: any[];
  otherSettingsUseMileage: boolean;
  nameValidation: boolean;
  emailValidation: boolean;
  roleValidation: boolean;
  errorStatus: boolean;
  errorMsg: any;
  successStatus: boolean;
  successMessage: any;
  dummyError: boolean;
  dummySuccess: boolean;
  country: any;
  phone: any;
  flagImg: string;
  userInput: string;
  tax: string;
  userAccount: any;
  firstnum: any;
  secondnum: any;
  thirdnum: any;
  fourthnum: any;
  fifthnum: any;
  sixthnum: any;
  otp2: any;
  new_email: any;
  first: any;
  second: any;
  third: any;
  fourth: any;
  open: boolean;
  modalContent: string;
  title: string;
  firstName: string;
  lastName: string;
  cardNumber: string;
  expirationDate: string;
  securityCode: string;
  zipCode: string;
  cardDataById: any;
  firstNameCard: string;
  lastNameCard: string;
  cardNumberCard: string;
  expirationDateCard: string;
  securityCodeCard: string;
  zipCodeCard: string;
  current_password: string;
  new_password: string;
  confirm_password: string;
  eightCharracter: boolean;
  mixtureOfUpLow: boolean;
  mixtureOfLetterNumbers: boolean;
  oneSpecialCharacter: boolean;
  triggerMethod: boolean;
  cardId: string;
  showSnackbar: boolean;
  alertType: string;
  snackbarMessage: string;
  cardType: string;
  updateCardType: string;
  planId: any;
  price: any;
  saving: any;
  total: any;
  est_tax: any;
  planDataById: any;
  dataUrl: any;
  status: any;
  allPlans: any[];
  is_Active: boolean;
  signupEmail: any;
  expires: any
  getUserSub: any;
  allBillingDetails: any[];
  time: string | null | number[];
  expired:boolean;
  timeToStop: string | null | number[];
  expiredTime: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  sendUserInvitationId: any;
  getAllUserId: any;
  updateProfileId: any;
  deleteUserId: any;
  getUserDataId: any;
  createEmailOtpId: any;
  otpAuthApiCallId: any;
  changeEmailId: any;
  changePhoneId: any;
  verifyPhoneId: any;
  addPaymentCardId: any;
  getAllCardsId: any;
  getCardDataById: any;
  verifyCardNumberId: any;
  updatePaymentCardId: any;
  createResendEmailOtpId: any;
  changePasswordId: any;
  changeResendPhoneId: any;
  deleteCardDataById: any;
  makePaymentId: any;
  getSinglePlansById: any;
  getUserSubscription: any;
  getAllPlans: any;
  getTransactionBillingDetails: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.detectCardType = this.detectCardType.bind(this);
    this.addPaymentCard = this.addPaymentCard.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      userAccountRole: '',
      userAccountName: '',
      userAccountEmail: '',
      userData: [],
      cardData: [],
      otherSettingsUseMileage: true,
      nameValidation: false,
      emailValidation: false,
      roleValidation: false,
      errorMsg: '',
      errorStatus: false,
      successMessage: '',
      successStatus: false,
      dummyError: false,
      dummySuccess: false,
      country: "1",
      phone: "",
      flagImg: 'https://flagpedia.net/data/flags/h80/us.webp',
      userInput: '',
      tax: '',
      userAccount: [],
      firstnum: '',
      secondnum: '',
      thirdnum: '',
      fourthnum: '',
      fifthnum: '',
      sixthnum: '',
      otp2: this.state?.firstnum + this.state?.secondnum + this.state?.thirdnum + this.state?.fourthnum + this.state?.fifthnum + this.state?.sixthnum,
      new_email: '',
      first: "",
      second: "",
      third: "",
      fourth: '',
      open: false,
      modalContent: '',
      title: '',
      firstName: '',
      lastName: '',
      cardNumber: '',
      expirationDate: '',
      securityCode: '',
      zipCode: '',
      firstNameCard: '',
      lastNameCard: '',
      cardNumberCard: '',
      expirationDateCard: '',
      securityCodeCard: '',
      zipCodeCard: '',
      cardDataById: {},
      current_password: '',
      new_password: '',
      confirm_password: '',
      eightCharracter: false,
      mixtureOfLetterNumbers: false,
      mixtureOfUpLow: false,
      oneSpecialCharacter: false,
      triggerMethod: false,
      cardId: "",
      showSnackbar: false,
      alertType: 'error',
      snackbarMessage: "",
      cardType: "",
      updateCardType: "",
      planId: "",
      price: 0,
      saving: 0,
      total: 0,
      est_tax: 0,
      planDataById: {},
      dataUrl: "",
      status: "",
      allPlans: [],
      is_Active: false,
      signupEmail: "",
      expires: "",
      getUserSub: {},
      allBillingDetails: [],
      expired:false,
      time:null,
      timeToStop: localStorage.getItem("endTime"),
      expiredTime : false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      //console.log("lele1")

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });

    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.sendUserInvitationId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              this.setState({ successMessage: "Invitation has been sent. please check the email", successStatus: true, userAccountEmail: '', userAccountName: '', userAccountRole: '' })
              this.getAllUser()
            }
          } else {
            //console.log("errors", responseJson)
            //this.showAlert("Error", responseJson?.errors)
            this.setState({ errorMsg: responseJson?.errors, errorStatus: true })
          }
        } else if (apiRequestCallId === this.getAllUserId) {
          if (!responseJson.errors) {
            if (responseJson.data) {

              this.setState({ userData: responseJson?.data })
              console.log(this.state.userData, 'userdata')

            } else {
              console.log("errors", responseJson.errors)
            }
          }
        } else if (apiRequestCallId === this.deleteUserId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson.message) {
              this.getAllUser(),
                console.log("delete", responseJson?.message)
              this.setState({ successMessage: responseJson?.message, successStatus: true })

            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.setState({ errorMsg: responseJson?.errors, errorStatus: true })
          }
        } else if (apiRequestCallId === this.getUserDataId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson.data) {
              this.setState({ userAccount: responseJson?.data })
              console.log("userdatasingle", this.state.userAccount)

            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.setState({ open: true, title: "Error", modalContent: JSON.stringify(responseJson?.errors) })
          }
        } else if (apiRequestCallId === this.createEmailOtpId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson) {
              console.log("clicked")
              console.log(this.state.userAccount.attributes.email);
              localStorage.setItem("verifyEmail", this.state.userAccount.attributes.email);
              localStorage.setItem("verifyEmailOtp", JSON.stringify(responseJson?.otp_pin));
              this.showSnackBar(JSON.stringify(responseJson?.message), "success");
              //@ts-ignore
              navigation.navigate("CheckEmailForCode");
            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors), "error");
          }
        }
        else if (apiRequestCallId === this.createResendEmailOtpId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson) {
              console.log("clicked")
              console.log(localStorage.getItem("verifyEmail"));
              localStorage.setItem("verifyEmailOtp", JSON.stringify(responseJson?.otp_pin));
              this.showSnackBar(JSON.stringify(responseJson?.message), "success");

            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors), "error");
          }
        }
        else if (apiRequestCallId === this.otpAuthApiCallId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson) {
              console.log("clicked")
              localStorage.removeItem("verifyEmail");
              localStorage.removeItem("verifyEmailOtp");
              this.showSnackBar(JSON.stringify(responseJson?.message), "success");
              console.log("verified successfully");
              //@ts-ignore
              navigation.navigate("ChangeEmailSettings");
            } else {
              this.showSnackBar(JSON.stringify(responseJson?.errors), "error");
            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors), "error");
          }

        }
        else if (apiRequestCallId === this.changeEmailId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson.data) {
              console.log("clicked")
              console.log("changed successfully");
              //@ts-ignore
              navigation.navigate("EmailSuccessMessage");

            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.showSnackBar("Email already used. Try with different email.", "error");
          }
        }
        else if (apiRequestCallId === this.changePhoneId) {

          //console.log("1111")
          if (responseJson.data) {
            console.log("phoneotp response", responseJson?.meta);
            localStorage.setItem("phoneNumber", this.state.country + this.state.phone)
            localStorage.setItem("phoneOtpToken", responseJson?.meta.sms_token)
            this.showSnackBar("Code has been sent to your phone. Check phone.", "success");
            // @ts-ignore
            navigation.navigate("CheckPhoneForCode");

          }
          else if (responseJson.error) {
            console.log("delete errors", responseJson?.error)
            this.showSnackBar("The number is unverified. Twilio Trial accounts cannot send messages to unverified numbers.Verify at twilio or purchase a Twilio number.", "error");
          } else if (responseJson.errors[0].full_phone_number) {
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].full_phone_number), "error");
          } else if (responseJson.errors) {
            this.showSnackBar(JSON.stringify(responseJson?.errors), "error");

          }
        }
        else if (apiRequestCallId === this.changeResendPhoneId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson.data) {
              console.log("phoneotp response", responseJson?.meta);
              localStorage.setItem("phoneOtpToken", responseJson?.meta.sms_token)
              this.showSnackBar("Code has been sent to your phone. Check phone.", "success");
            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors), "error");
          }
        }
        else if (apiRequestCallId === this.verifyPhoneId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson.data) {
              console.log("verifyotp response", responseJson.data);
              localStorage.removeItem("phoneOtpToken");
              localStorage.removeItem("phoneNumber");
              this.showSnackBar("Phone Number Successfully Verified.", "success");
              //@ts-ignore
              navigation.navigate("billPaymentHistory");

            }
          } else {
            console.log("delete errors", responseJson?.error)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].pin), "error");

            // this.showAlert("Error", responseJson?.errors)
            // this.setState({open:true, title:"Error ",modalContent:JSON.stringify(responseJson?.error)});
          }
        }

        else if (apiRequestCallId === this.addPaymentCardId) {
          if (!responseJson.errors) {
            //console.log("1111")
            if (responseJson) {
              console.log("paymentResponse", responseJson);
              this.showSnackBar("Card Details added successfully.", "success");
              window.location.reload();

            }
          } else {
            console.log("delete errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors), "error");
          }
        } else if (apiRequestCallId === this.getAllCardsId) {
          if (!responseJson.errors) {
            if (responseJson.data) {

              this.setState({ cardData: responseJson?.data })
              console.log(this.state.cardData, 'cardData')


            } else {
              console.log("errors", responseJson.errors)
            }
          }
        } else if (apiRequestCallId === this.getCardDataById) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              this.setState({
                firstName: responseJson?.data.attributes.first_name,
                lastName: responseJson?.data.attributes.last_name,
                cardNumber: responseJson?.data.attributes.card_number,
                securityCode: responseJson?.data.attributes.cvv_code,
                expirationDate: responseJson?.data.attributes.expiration_date,
                zipCode: responseJson?.data.attributes.zipcode,
                cardType: responseJson?.data.attributes.card_type
              })
              this.setState({ cardDataById: responseJson?.data }),
                this.setState({ cardId: this.state.cardDataById.id })
              console.log('cardId', this.state.cardId);
              console.log('cardDataById', responseJson?.data);
              // localStorage.removeItem("cardDetails");
            }
          } else {
            console.log("errors", responseJson.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].token), "error");

          }
        } else if (apiRequestCallId === this.verifyCardNumberId) {
          if (!responseJson) {
            if (responseJson.data) {
              console.log('verifyCardDataById', responseJson?.data);
              this.showSnackBar("Card Details Verified Successfully.", "success");
              //@ts-ignore
              navigation.navigate("ManagePaymentInfo");

            }
          } else {
            console.log("errors", responseJson)
            this.showSnackBar(JSON.stringify(responseJson?.message), "error");

          }
        } else if (apiRequestCallId === this.deleteCardDataById) {
          if (!responseJson.errors) {
            if (responseJson) {
              console.log('deleted', responseJson);
              this.showSnackBar("Card deleted Successfully.", "success");

              window.location.reload();
            }
          } else {
            console.log("errors coming")
            console.log("errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].message), "error");

            this.setState({ open: true, title: "Error", modalContent: JSON.stringify(responseJson?.errors[0].message) });

          }
        }
        else if (apiRequestCallId === this.updatePaymentCardId) {
          if (!responseJson.errors) {
            if (responseJson.data) {

              // this.setState({ cardDataById: responseJson?.data })
              console.log('updateCardDataById', responseJson?.data);

              this.showSnackBar("Card Details updated successfully.", "success");
              // localStorage.removeItem("cardDetails");
              window.location.reload();
            }
          } else {
            console.log("errors", responseJson.errors)
            this.showSnackBar(responseJson?.errors, "error");

          }
        } else if (apiRequestCallId === this.changePasswordId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              console.log('password', responseJson);
              //@ts-ignore
              navigation.navigate("PasswordSuccessMessage");
            }
          } else {
            console.log("errors coming")
            console.log("errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].message), "error");


          }
        } else if (apiRequestCallId === this.makePaymentId) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              console.log('paymentsuccess', responseJson);
              this.setState({ dataUrl: responseJson?.data.url })
              console.log(this.state.dataUrl, "dataUrl")
              //@ts-ignore
              navigation.navigate(this.state.dataUrl);

            } else if (responseJson.error) {
              this.showSnackBar(JSON.stringify(responseJson?.error), "error");

            }
          } else {
            console.log("errors coming")
            console.log("errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].token), "error");


          }
        }
        else if (apiRequestCallId === this.getSinglePlansById) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              this.setState({
                price: responseJson?.data.attributes.price,
                saving: responseJson?.data.attributes.saving,
                total: responseJson?.data.attributes.total,
                est_tax: responseJson?.data.attributes.est_tax
              })

              this.setState({ planDataById: responseJson?.data });
              console.log(this.state.planDataById, "planDataById");
              console.log(this.state.planDataById.attributes.price);


            }
          } else {
            console.log("errors coming")
            console.log("errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].token), "error");


          }
        } else if (apiRequestCallId === this.getUserSubscription) {
          if (!responseJson.errors) {
            if (responseJson.data) {
              console.log('getUserSubscription', responseJson);
              this.setState({
                getUserSub: responseJson,
                status: responseJson?.data[0]?.attributes?.status,
                expires: moment(responseJson?.data[0]?.attributes?.expires_on).format('MM-DD-YYYY')
              })
              console.log(this.state.status, "status**********")

            } else if (responseJson.error) {
              this.showSnackBar(JSON.stringify(responseJson?.error), "error");

            }
          } else {
            console.log("errors coming")
            console.log("errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].token), "error");


          }
        }
        else if (apiRequestCallId === this.getAllPlans) {
          if (!responseJson.errors) {
            if (responseJson) {
              // console.log('getAllPlans',responseJson);
              this.setState({ allPlans: responseJson?.plans });
              // this.setState({is_Active:responseJson?.plans?.attributes?.is_active})
              console.log('getAllPlans', this.state.allPlans);

            } else if (responseJson.error) {
              this.showSnackBar(JSON.stringify(responseJson?.error), "error");

            }
          } else {
            console.log("errors coming")
            console.log("errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].token), "error");


          }
        } else if (apiRequestCallId === this.getTransactionBillingDetails) {
          if (!responseJson.errors) {
            if (responseJson?.data) {
              // console.log('getAllPlans',responseJson);
              this.setState({ allBillingDetails: responseJson?.data });
              // this.setState({is_Active:responseJson?.plans?.attributes?.is_active})
              console.log('getAllBillingDetails', this.state.allBillingDetails);

            } else if (responseJson.error) {
              this.showSnackBar(JSON.stringify(responseJson?.error), "error");

            }
          } else {
            console.log("errors coming")
            console.log("errors", responseJson?.errors)
            this.showSnackBar(JSON.stringify(responseJson?.errors[0].token), "error");


          }
        }


      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }
  checkValidationUserAccount() {
    if (this.state.userAccountName === '' || this.state.userAccountEmail === '' || this.state.userAccountRole === '') {
      this.setState({ nameValidation: false, emailValidation: false, roleValidation: false })
      if (this.state.userAccountName === '') {
        this.setState({ nameValidation: true })
      }
      if (this.state.userAccountEmail === '') {
        this.setState({ emailValidation: true })
      }
      if (this.state.userAccountRole === '') {
        this.setState({ roleValidation: true })
      }
    } else {
      this.setState({ nameValidation: false, emailValidation: false, roleValidation: false }),
        this.sendUserInvitation()
    }
  }

  sendUserInvitation() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendUserInvitationId = requestMessage.messageId;

    const attrs = {
      user_name: this.state.userAccountName,
      email: this.state.userAccountEmail,
      role_id: this.state.userAccountRole

    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userAccountEndPoint}/add_account_from_setting`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getAllUser() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userAccountEndPoint}/get_all_users`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  updateProfile(id: any) {

  }
  deleteAccountUser(id: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userAccountEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.updateTimeFromLast();
    let data: any = localStorage.getItem("cardDetails");
    let newData: any = JSON.parse(data);

    this.setState({ token: await StorageProvider.get('token') }, () => this.getUserData());
    console.log(this.state.token, "token*************")
    let tempToken: any = localStorage.getItem('tempToken');
    console.log("temptoken", tempToken);
    let signupemail: any = localStorage.getItem('signupEmail');
    console.log('signupemail', signupemail);
    if (signupemail) {
      this.setState({ signupEmail: signupemail })

    }
    if (tempToken) {
      this.setState({ token: tempToken })
    }
    this.getAllCards();
    if (newData) {
      this.getSingleCardDataById(newData.test.id);
    }
    let planIdFromLocal: any = localStorage.getItem('subscriptionPlan');
    console.log(planIdFromLocal, "from localStorage");
    this.setState({ planId: planIdFromLocal });
    console.log(this.state.planId, "*******")
    if (this.state.planId) {
      this.getPlansById(this.state.planId);
    }
    if (this.state.getUserSub) {
      this.getUserSubscriptions();
    }
    this.getAllPlan();
    if (this.state.allBillingDetails) {
      this.getBillingDetails();
    }

    // this.getUserSubscriptions();
  }

  getUserData = () => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDataAccountWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  move(e: any, p: any, c: any, n: any) {
    //@ts-ignore
    let length = document.getElementById(c)?.value?.length;

    let maxlength = document.getElementById(c)?.getAttribute('maxlength');

    if (length == maxlength) {
      document.getElementById(n)?.focus();
    }
    if (e.key == "Backspace") {
      document.getElementById(p)?.focus();
    }

  }
  submitEmailCode = () => {
    this.props.navigation.navigate("ChangeEmailSettings")
  }

  handleChange = (event: any) => {

    // var country = document.getElementById("country");
    //@ts-ignore
    // country.options[country.selectedIndex].text = '+' + country.value;
    // console.log(country, "newcountrycode")
    this.setState({ country: event.target.value });
    console.log(this.state.country);
  };

  showSnackBar = (message: string, alertType: string) => {
    console.log('alertType ', alertType);

    this.setState({
      snackbarMessage: message,
      alertType: alertType
    }, () => {
      this.setState({
        showSnackbar: true,
      })
    })
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  phoneHandler = (e: any) => {

    let phoneNumber = e.target.value;
    console.log(phoneNumber, 'value')
    // if (!phoneNumber) return phoneNumber;
    let phoneNumberformat = phoneNumber.replace(/[^\d]/g, "");

    this.setState({ phone: phoneNumberformat })
    console.log(this.state.phone, "phonenumber");

    let phoneNumberLength = phoneNumberformat.length;

    if (phoneNumberLength < 4) {
      return phoneNumberformat;
    }
    if (phoneNumberLength < 7) {
      return this.setState({ phone: `(${phoneNumberformat.slice(0, 3)})${phoneNumberformat.slice(3)}` })
    }

    return this.setState({
      phone: `(${phoneNumberformat.slice(0, 3)})${phoneNumberformat.slice(
        3,
        6
      )}-${phoneNumberformat.slice(6, 10)}`
    })



  };

  countryFlagHandler = () => {
    console.log("striked")
    const select = document.getElementById('country');
    console.log(select, "selected")
    this.setState({
      //@ts-ignore
      flagImg: `https://flagpedia.net/data/flags/h80/${select?.selectedOptions[0].dataset.countrycode.toLowerCase()}.webp`
    });
  };


  // inputCurrencyHandler = (e:any)=>{

  //   let currency= e.target.value;
  //   console.log(currency,"input");

  //  let sravs= '$' + parseFloat(currency).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  //  console.log(sravs,"sravs");

  //  this.setState({userInput:'$' + parseFloat(currency).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')});
  //  console.log(this.state.userInput,"input");
  // }

  expiryIdHandler = (e: any) => {
    let cvvNum = e.target.value;
    console.log(cvvNum, 'value');
    let cvvNumberFormat = cvvNum.replace(/[^\d]/g, "");
    console.log(typeof (cvvNumberFormat.slice(0, 2)), "type");
    if (cvvNumberFormat.slice(0, 2) > 12) {
      this.showSnackBar("Entered month should be less than or equal to 12", "error")
      return false
    }

    if (cvvNumberFormat.slice(3, 4)) {
      console.log(cvvNumberFormat.slice(2, 4));
      let lastDig = cvvNumberFormat.slice(2, 4);
      console.log(lastDig, "********")
      let today: any = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + '/' + dd + '/' + yyyy;
      console.log(today, "date")

      let simpleYear: any = moment(today, "MM.DD.YYYY").format("YY");
      console.log(simpleYear, "type");
      // console.log(simpleYear,"type");
      if (lastDig < simpleYear) {
        this.showSnackBar("Entered expiration year should not be less than present year", "error")
        return false
      }
    }
    this.setState({ expirationDateCard: cvvNumberFormat })
    let cvvNumberLength = cvvNumberFormat.length;
    console.log(cvvNumberLength, "length");
    if (cvvNumberLength > 2) {
      return this.setState({ expirationDateCard: `${cvvNumberFormat.slice(0, 2)}/${cvvNumberFormat.slice(2, 4)}` })
    }

  }

  expiryIdHandlerUpdate = (e: any) => {

    let cvvNum = e.target.value;
    console.log(cvvNum, 'value');
    let cvvNumberFormat = cvvNum.replace(/[^\d]/g, "");
    console.log(typeof (cvvNumberFormat.slice(0, 2)), "type");
    if (cvvNumberFormat.slice(0, 2) > 12) {
      this.showSnackBar("Entered month should be less than or equal to 12", "error")
      return false
    }

    if (cvvNumberFormat.slice(3, 4)) {
      console.log(cvvNumberFormat.slice(2, 4))
      let lastDig = cvvNumberFormat.slice(2, 4);
      console.log(lastDig, "********")
      let today: any = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + '/' + dd + '/' + yyyy;
      console.log(today, "date")

      let simpleYear: any = moment(today, "MM.DD.YYYY").format("YY");
      console.log(typeof (simpleYear), "type");
      // console.log(simpleYear,"type");
      if (lastDig < simpleYear) {
        this.showSnackBar("Entered expiration year should not be less than present year", "error")
        return false
      }
    }
    this.setState({ expirationDate: cvvNumberFormat })
    let cvvNumberLength = cvvNumberFormat.length;
    console.log(cvvNumberLength, "length");
    if (cvvNumberLength > 2) {
      return this.setState({ expirationDate: `${cvvNumberFormat.slice(0, 2)}/${cvvNumberFormat.slice(2, 4)}` })
    }

  }
  cardHandler = (e: any) => {
    let cardNumber = e.target.value;
    console.log(cardNumber, 'value')
    // if (!cardNumber) return cardNumber;
    let cardNumberformat = cardNumber.replace(/[^\d]/g, "");
    this.setState({ cardNumberCard: cardNumberformat })
    let cardNumberLength = cardNumberformat.length;
    console.log('cardnumberlength', cardNumberLength);
    if (cardNumberLength < 5) {
      return this.setState({ cardNumberCard: `${cardNumberformat.slice(0, 4)}` });
    }

    if (cardNumberLength < 9) {
      return this.setState({ cardNumberCard: `${cardNumberformat.slice(0, 4)} ${cardNumberformat.slice(4, 8)}` })
    }
    if (cardNumberLength < 13) {
      return this.setState({ cardNumberCard: `${cardNumberformat.slice(0, 4)} ${cardNumberformat.slice(4, 8)} ${cardNumberformat.slice(8, 12)}` })
    }

    return this.setState({ cardNumberCard: `${cardNumberformat.slice(0, 4)} ${cardNumberformat.slice(4, 8)} ${cardNumberformat.slice(8, 12)} ${cardNumberformat.slice(12, 16)}` })

  }
  cardHandlerUpdate = (e: any) => {
    let cardNumber = e.target.value;
    console.log(cardNumber, 'value')
    // if (!cardNumber) return cardNumber;
    let cardNumberformat = cardNumber.replace(/[^\d]/g, "");
    this.setState({ cardNumber: cardNumberformat })
    let cardNumberLength = cardNumberformat.length;
    console.log('cardnumberlength', cardNumberLength);
    if (cardNumberLength < 5) {
      return this.setState({ cardNumber: `${cardNumberformat.slice(0, 4)}` });
    }

    if (cardNumberLength < 9) {
      return this.setState({ cardNumber: `${cardNumberformat.slice(0, 4)} ${cardNumberformat.slice(4, 8)}` })
    }
    if (cardNumberLength < 13) {
      return this.setState({ cardNumber: `${cardNumberformat.slice(0, 4)} ${cardNumberformat.slice(4, 8)} ${cardNumberformat.slice(8, 12)}` })
    }

    return this.setState({ cardNumber: `${cardNumberformat.slice(0, 4)} ${cardNumberformat.slice(4, 8)} ${cardNumberformat.slice(8, 12)} ${cardNumberformat.slice(12, 16)}` })
  }

  sendEmailVerificationCode = () => {
    console.log('clicked');

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const attrs = {
      email: this.state.userAccount.attributes?.email,
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEmailOtpId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEmailOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  resendEmailVerificationCode = () => {
    console.log('clicked');

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const attrs = {
      email: localStorage.getItem("verifyEmail"),
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createResendEmailOtpId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEmailOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  OnsubmitOtp = (e: any) => {

    e.preventDefault();
    const otp2 = this.state.firstnum + this.state.secondnum + this.state.thirdnum + this.state.fourthnum + this.state.fifthnum + this.state.sixthnum
    console.log(otp2);
    let otp3 = parseInt(otp2);
    console.log(otp3);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    //GO TO REQUEST STATE
    this.otpAuthApiCallId = requestMessage.messageId;

    const attrs = {
      email: localStorage.getItem('verifyEmail'),
      otp: otp3
    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerificationApiEndPointWeb
    );
    console.log(configJSON.otpVerificationApiEndPointWeb);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ new_email: event.target.value as string });

  };

  handleNewEmail = (e: any) => {
    e.preventDefault();
    console.log(this.state.new_email, "newEmail");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const attrs = {
      email: this.state.userAccount.attributes?.email,
      new_email: this.state.new_email
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeEmailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeEmailWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handlePhoneOtp = (e: any) => {

    e.preventDefault();

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const attrs = {
      full_phone_number: this.state.country + this.state.phone

    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePhoneId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePhoneWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  verifyPhoneOtp = (e: any) => {
    e.preventDefault();

    const otp = this.state.first + this.state.second + this.state.third + this.state.fourth
    console.log(otp);
    let phoneOtp2 = parseInt(otp);
    console.log(phoneOtp2);

    let phoneOtptoken = localStorage.getItem("phoneOtpToken")
    console.log(phoneOtptoken, "meta token");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const httpBody = {
      pin: phoneOtp2,
      sms_token: phoneOtptoken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyPhoneId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyPhoneWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  resendPhoneVerificationCode = (e: any) => {
    e.preventDefault();

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const attrs = {
      full_phone_number: localStorage.getItem("phoneNumber")

    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeResendPhoneId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePhoneWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClose = () => {
    this.setState({ open: false })
  };


  detectCardType = (cardNumberCard: any) => {
    console.log("striked")
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
      MaestroCard: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
      UnionPayCard: /^(62[0-9]{14,17})$/
    };

    if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.AMERICAN_EXPRESS)) {
      return this.setState({ cardType: "americanExpress" }, () => { console.log(this.state.cardType) })
    } else if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.DINERS_CLUB)) {
      return this.setState({ cardType: "diners" }, () => { console.log(this.state.cardType) })
    } else if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.DISCOVER)) {
      return this.setState({ cardType: "discover" }, () => { console.log(this.state.cardType) })
    } else if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.MASTERCARD)) {
      return this.setState({ cardType: "masterCard" }, () => { console.log(this.state.cardType) })
    } else if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.VISA)) {
      return this.setState({ cardType: "visa" }, () => { console.log(this.state.cardType) })
    } else if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.JCB)) {
      return this.setState({ cardType: "jcb" }, () => { console.log(this.state.cardType) })
    } else if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.MaestroCard)) {
      return this.setState({ cardType: "maestro" }, () => { console.log(this.state.cardType) })
    } else if (cardNumberCard.replace(/[^\d]/g, "").match(regexPattern.UnionPayCard)) {
      return this.setState({ cardType: "unionPay" }, () => { console.log(this.state.cardType) })
    } else {
      this.showSnackBar("Enter Valid card details", "error");
      return false;
    }

  }

  addPaymentCard = async (e: any) => {
    e.preventDefault();

    console.log(this.state.firstNameCard);
    console.log(this.state.lastNameCard);
    console.log(this.state.cardNumberCard);
    console.log(this.state.expirationDateCard);
    console.log(this.state.securityCodeCard);
    console.log(this.state.zipCodeCard);

    if (!(/^[a-zA-Z\s]*$/g).test(this.state.firstNameCard)) {
      this.showSnackBar("First name allowed only Letters", "error")
      return false;
    }

    if (this.state.firstNameCard.length < 4 || this.state.firstNameCard.length > 15) {
      this.showSnackBar("Length of the characters in first name should be between 4 to 15 ", "error")
      return false;
    }

    if (this.state.lastNameCard.length < 4 || this.state.lastNameCard.length > 15) {
      this.showSnackBar("Length of the characters in last name should be between 4 to 15 ", "error")
      return false;
    }
    if (!(/^[a-zA-Z\s]*$/g).test(this.state.lastNameCard)) {
      this.showSnackBar("Last name allowed only Letters", "error")
      return false;
    }

    if (this.state.expirationDateCard == "") {
      this.showSnackBar("Enter Expiration Date", "error")
      return false;
    }

    if (this.state.securityCodeCard == "") {
      this.showSnackBar("Enter CVV", "error")
      return false;
    }
    if (this.state.zipCodeCard == "") {
      this.showSnackBar("Enter Zipcode", "error")
      return false;
    }
    await this.detectCardType(this.state.cardNumberCard);
    console.log(this.state.cardType);

    if (this.state.cardType) {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
      }

      const attrs = {
        first_name: this.state.firstNameCard,
        last_name: this.state.lastNameCard,
        card_number: this.state.cardNumberCard,
        expiration_date: this.state.expirationDateCard,
        cvv_code: parseInt(this.state.securityCodeCard),
        zipcode: parseInt(this.state.zipCodeCard),
        card_type: this.state.cardType
      };
      const data = {
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentCardId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addPaymentCardWebEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }
  getAllCards = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCardsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCardsWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSingleCardDataById = (id: any) => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCardDataById = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllCardsWebEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyCardNumber = (e: any) => {
    e.preventDefault();
    console.log(this.state.cardNumber, "cardNumber");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const attrs = {
      card_number: this.state.cardNumber
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyCardNumberId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyCardNumberWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  detectUpdateCardType = async (cardNumber: any) => {
    console.log("striked")
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
      MaestroCard: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
      UnionPayCard: /^(62[0-9]{14,17})$/
    };

    if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.AMERICAN_EXPRESS)) {
      return this.setState({ updateCardType: "americanExpress" }, () => { console.log(this.state.updateCardType) })
    } else if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.DINERS_CLUB)) {
      return this.setState({ updateCardType: "diners" }, () => { console.log(this.state.updateCardType) })
    } else if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.DISCOVER)) {
      return this.setState({ updateCardType: "discover" }, () => { console.log(this.state.updateCardType) })
    } else if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.MASTERCARD)) {
      return this.setState({ updateCardType: "masterCard" }, () => { console.log(this.state.updateCardType) })
    } else if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.VISA)) {
      return this.setState({ updateCardType: "visa" }, () => { console.log(this.state.updateCardType) })
    } else if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.JCB)) {
      return this.setState({ updateCardType: "jcb" }, () => { console.log(this.state.updateCardType) })
    } else if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.MaestroCard)) {
      return this.setState({ updateCardType: "maestro" }, () => { console.log(this.state.updateCardType) })
    } else if (cardNumber.replace(/[^\d]/g, "").match(regexPattern.UnionPayCard)) {
      return this.setState({ updateCardType: "unionPay" }, () => { console.log(this.state.updateCardType) })
    } else {
      this.showSnackBar("Enter Valid card details", "error");
      return false;
    }
  }

  updateCardInfo = async (id: any) => {
    console.log(this.state.firstName);
    console.log(this.state.lastName);
    console.log(this.state.cardNumber);
    console.log(this.state.expirationDate);
    console.log(this.state.securityCode);
    console.log(this.state.zipCode);
    console.log(id, "id***********");

    if (this.state.firstName == "" || this.state.firstName.length < 4 || this.state.firstName.length > 15) {
      this.showSnackBar("Length of the characters in first name  should be between 4 to 15 ", "error")
      return false;
    }

    if (this.state.lastName == "" || this.state.lastName.length < 4 || this.state.lastName.length > 15) {
      this.showSnackBar("Length of the characters in last name should be between 4 to 15 ", "error")
      return false;
    }

    if (!(/^[a-zA-Z\s]*$/g).test(this.state.firstName)) {
      this.showSnackBar("First name allowed only Letters", "error")
      return false;
    }


    if (!(/^[a-zA-Z\s]*$/g).test(this.state.lastName)) {
      this.showSnackBar("Last name allowed only Letters", "error")
      return false;
    }

    if (this.state.expirationDate == "") {
      this.showSnackBar("Enter Expiration Date", "error")
      return false;
    }

    if (this.state.securityCode == "") {
      this.showSnackBar("Enter CVV", "error")
      return false;
    }
    if (this.state.zipCode == "") {
      this.showSnackBar("Enter Zipcode", "error")
      return false;
    }


    await this.detectUpdateCardType(this.state.cardNumber);
    console.log(this.state.cardType);
    console.log(this.state.updateCardType);


    if (this.state.updateCardType) {

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
      }

      const attrs = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        card_number: this.state.cardNumber,
        expiration_date: this.state.expirationDate,
        cvv_code: this.state.securityCode,
        zipcode: this.state.zipCode,
        card_type: this.state.updateCardType
      };
      const data = {
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePaymentCardId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addPaymentCardWebEndPoint}/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleUpdateChange = (prop: any) => (event: any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
    // console.log(this.state.email,"values")
  };


  handlePasswordChange = (e: any) => {
    e.preventDefault();
    console.log(this.state.current_password);
    console.log(this.state.new_password);
    console.log(this.state.confirm_password)

    if (this.state.new_password !== this.state.confirm_password) {
      this.showSnackBar("Passwords are different", "error");

    } else if (this.state.current_password == this.state.new_password) {
      this.showSnackBar("Current Password and New password should not be same", "error");

    } else {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
      }

      const attrs = {
        token: this.state.token,
        current_password: this.state.current_password,
        password: this.state.new_password,
        password_confirmation: this.state.confirm_password
      };
      const data = {
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changePasswordId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.changePasswordWebEndApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validatePassword() {
    var formatUpperandLower = /[A-Z][a-z]/
    var formatUpperandLower2 = /[a-z][A-Z]/
    var formatUpperandLower3 = /[A-Z][0-9][a-z]/
    var formatUpperandLower4 = /[a-z][0-9][A-Z]/
    var formatUpperandLower5 = /[0-9][A-Z][a-z]/
    var formatUpperandLower6 = /[0-9][a-z][A-Z]/
    var formatSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.\/?]+/
    var formatLettersandNumbers = /[0-9]/
    var formatLettersandNumbers2 = /[A-Z][0-9]/
    var formatLettersandNumbers3 = /[a-z][0-9]/
    var formatLettersandNumbers4 = /[0-9][A-Z]/
    var formatLettersandNumbers5 = /[0-9][a-z]/
    // var formatLettersandNumbers2 = /[A-Z][0-9][a-z]/
    // var formatLettersandNumbers3 = /[0-9][A-Z][a-z]/
    // var formatLettersandNumbers3 = /[a-z][A-Z][0-9]/
    // var formatLettersandNumbers3 = /[0-9][A-Z][a-z]/

    //0-9a-zA-Z
    console.log("aluuuuu", this.state.new_password, "--", formatUpperandLower.test(this.state.new_password), "--", formatLettersandNumbers.test(this.state.new_password), "--", formatSpecialCharacter.test(this.state.new_password))
    if (this.state.new_password.length >= 8) {
      this.setState({ eightCharracter: true })
    } else {
      this.setState({ eightCharracter: false })
    }
    if (formatUpperandLower.test(this.state.new_password) || formatUpperandLower2.test(this.state.new_password) || formatUpperandLower3.test(this.state.new_password) || formatUpperandLower4.test(this.state.new_password) || formatUpperandLower5.test(this.state.new_password) || formatUpperandLower6.test(this.state.new_password)) {
      this.setState({ mixtureOfUpLow: true })
    } else {
      this.setState({ mixtureOfUpLow: false })
    }
    if (this.state.mixtureOfUpLow) {
      if (formatLettersandNumbers.test(this.state.new_password)) {
        this.setState({ mixtureOfLetterNumbers: true })
      } else {
        this.setState({ mixtureOfLetterNumbers: false })
      }
    } else {
      this.setState({ mixtureOfLetterNumbers: false })
    }
    if (formatLettersandNumbers2.test(this.state.new_password) || formatLettersandNumbers3.test(this.state.new_password) || formatLettersandNumbers4.test(this.state.new_password) || formatLettersandNumbers5.test(this.state.new_password)) {
      this.setState({ mixtureOfLetterNumbers: true })
    } else {
      this.setState({ mixtureOfLetterNumbers: false })
    }

    if (formatSpecialCharacter.test(this.state.new_password)) {
      this.setState({ oneSpecialCharacter: true })
    } else {
      this.setState({ oneSpecialCharacter: false })
    }
    if (this.state.oneSpecialCharacter && this.state.mixtureOfUpLow || this.state.mixtureOfUpLow || this.state.oneSpecialCharacter) {
      if (formatLettersandNumbers.test(this.state.new_password)) {
        this.setState({ mixtureOfLetterNumbers: true })
      } else {
        this.setState({ mixtureOfLetterNumbers: false })
      }
    }

  }
  handleDeleteCard = (id: any) => {
    console.log(id);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCardDataById = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllCardsWebEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleEmailCancel = () => {
    //@ts-ignore
    navigation.navigate("billPaymentHistory")

  }

  handlePhoneCancel = () => {
    //@ts-ignore
    navigation.navigate("billPaymentHistory")
  }

  handleNewData = () => {
    if (localStorage.getItem("cardDetails")) {
      localStorage.removeItem("cardDetails");
    }
    //@ts-ignore
    navigation.navigate("ManagePaymentInfo")
  }

  makePayment = (e: any) => {
    e.preventDefault();
    console.log("payment striked");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const httpBody = {
      plan_id: this.state.planId,
      //for test you need to paste the base url here like : "neeuhfinalbuildcard-125853-react-native.b125853.dev.eastus.az.svc.builder.cafe"
      success_url: `https://${window.location.host}/paymentSuccess?success=true`,
      cancel_url: `https://${window.location.host}/paymentFailure?canceled=true`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.makePaymentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makePayment
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPlansById = (id: any) => {
    console.log("planid", this.state.planId)
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSinglePlansById = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllPlansWebEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getUserSubscriptions = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserSubscription = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserSubscriptionWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllPlan = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPlans = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPlansWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBillingDetails = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionBillingDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBillingDetailsWebEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  updateTimeFromLast = () => {
    new TimeCalculator(this.state.timeToStop).updateTime((d:any) => {
        this.setState({ time: d[0], expiredTime: d[1] })
    })
  }

  // Customizable Area End

}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import StorageProvider from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  defaultBackground: boolean;
  selectedCategory: any;
  financialStatementPdf: any;
  financialCapitalStatementPdf: any;
  financialCapitalStatementCsv: any;
  financialStatementCsv: any;
  financialDetailStatementPdf: any;
  capitalDetailStatementPdf: any;
  financialDetailStatementCsv: any;
  capitalDetailStatementCsv: any;
  dashboardData: any;
  resetPage: boolean
  token: string;
  errorMsg: string;
  loading: boolean;
  activeYear: any;
  selectedButton: string;
  reportPeriod: any;
  reportPeriodStartDate: any;
  reportPeriodEndDate: any;
  reportPeriodData: any;
  reportData: any;
  capitalReportData: any;
  stateTaxForCapital: any;
  federalTaxForCapital: any;
  reportDetailsData: any;
  capitalDetailsData: any;
  snackbarMessage: any;
  alertType: any;
  showSnackbar: any;
  openModal: boolean;
  isTransactionUpdated: boolean;
  transactionId: any;
  from: any;
  companyName: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ReportsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getReportApiCallId: string;
  getReportDetailsApiCallId: string;
  getPdfApiCallId: string;
  getCsvApiCallId: string;
  getDetailsPdfApiCallId: string;
  getDetailsCsvApiCallId: string;
  saveFormDataApiCallId: string;
  saveFormDetailsApiCallId: string = "";
  nf: any = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });

  scrollValues: any = {
    //scrollWidth: "5px",
    scrollHeight: "5px"
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.getReportApiCallId = "";
    this.getReportDetailsApiCallId = "";
    this.getPdfApiCallId = "";
    this.getCsvApiCallId = "";
    this.getDetailsPdfApiCallId = "";
    this.getDetailsCsvApiCallId = "";
    this.saveFormDataApiCallId = "";

    // current month -->
    // const startOfMonth = moment().startOf('month');
    // const endOfMonth = moment().endOf('month');
    // <-- current month
    const startOfMonth = moment().startOf('year');
    const endOfMonth = new Date();

    this.state = {
      defaultBackground: true,
      selectedCategory: {},
      financialStatementPdf: "",
      financialCapitalStatementPdf: "",
      resetPage: true,
      financialCapitalStatementCsv: "",
      financialStatementCsv: "",
      financialDetailStatementPdf: "",
      financialDetailStatementCsv: "",
      capitalDetailStatementPdf: "",
      capitalDetailStatementCsv: "",
      snackbarMessage: "",
      showSnackbar: false,
      alertType: "error",
      reportData: [],
      capitalReportData: [],
      stateTaxForCapital: "",
      federalTaxForCapital: "",
      reportDetailsData: [],
      capitalDetailsData: [],
      reportPeriodData: [
        "Current Month",
        "Current Quarter",
        "Year to Date",
        "Previous Month",
        "Previous Quarter",
        "Previous Year",
        "Custom"
      ],
      dashboardData: [],
      activeYear: '',
      errorMsg: "",
      token: "",
      loading: false,
      selectedButton: 'Financial',
      reportPeriod: "Year to Date",
      reportPeriodStartDate: startOfMonth,
      reportPeriodEndDate: endOfMonth,
      openModal: false,
      isTransactionUpdated: false,
      transactionId: null,
      from: null,
      companyName: ''
    };
    // Customizable Area End 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    let { taxYear }: any = this.props

    // const id = await StorageProvider.get('id')

    // if (id) {
    //   this.getReportDetails();
    //   // this.getReportDetailsPdf(id);
    //   this.getReportDetailsCsv();
    // } else {
    this.getReportDetails();
    // this.getReportDetailsPdf();
    this.getReportDetailsCsv();
    // }
    await StorageProvider.remove('id')
    this.setState({ companyName: await StorageProvider.get('companyName') })
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    if (taxYear && taxYear != moment().format('YYYY')) {
      this.setState({ reportPeriodStartDate: moment(`01/01/${taxYear}`), reportPeriodEndDate: moment(`12/31/${taxYear}`) },
        () => {
          this.resetDateAndGetReport()
        })
    } else {
      this.resetDateAndGetReport()
    }

  }

  downloadPDF = (url: any, name: any) => {

    if (!url) {
      return;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        // @ts-ignore
        link.parentNode.removeChild(link);
      });
  }

  resetDateAndGetReport = () => {
    // Current month -->
    // const startOfMonth = moment().startOf('month');
    // const endOfMonth = moment().endOf('month');
    // this.setState({
    //   reportPeriod: "Year to Date",
    //   reportPeriodStartDate: new Date(startOfMonth.toString()),
    //   reportPeriodEndDate: new Date(endOfMonth.toString()),
    // }, () => {
    // <-- Current month 

    const startOfMonth = moment().startOf('year');
    if (moment(this.state.reportPeriodStartDate).format('YYYY') != moment().format('YYYY')) {
      this.getReports();
      // this.getPdfForFinancial();
      this.getCsvForFinancial();
      this.getReportDetails();
      this.getReportDetailsCsv();

      if (this.state.selectedButton == 'Capital') {
        this.getReportDetails();
        // this.getReportDetailsPdf();
        this.getReportDetailsCsv();
      }
    } else {
      this.setState({
        reportPeriod: "Year to Date",
        reportPeriodStartDate: new Date(startOfMonth.toString()),
        reportPeriodEndDate: new Date(),
      }, () => {
        this.getReports();
        // this.getPdfForFinancial();
        this.getCsvForFinancial();
        this.getReportDetails();
        this.getReportDetailsCsv();

        if (this.state.selectedButton == 'Capital') {
          this.getReportDetails();
          // this.getReportDetailsPdf();
          this.getReportDetailsCsv();
        }
      });
    }
  }
  handleModal = (state: boolean, id: any) => {
    this.setState({ transactionId: id, openModal: state }, () => {

      if (this.state.isTransactionUpdated && !state) {
        this.getReports();
        this.getCsvForFinancial();
        this.getReportDetails();
        this.getReportDetailsCsv();

        // if (this.state.selectedButton == 'Capital') {
        //   this.getReportDetails();
        //   this.getReportDetailsCsv();
        // }
      } else {
        this.setState({ isTransactionUpdated: false })
      }
    })
  }
  setSelectedButton = (label: string) => {
    this.setState({ selectedButton: label, selectedCategory: {} }, () => {
      this.resetDateAndGetReport()
    })
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId == this.saveFormDataApiCallId) {
        if (responseJson) {
          this.showSnackBar("Report is successfully saved in Forms.", "success")
        }
      }

      if (apiRequestCallId == this.saveFormDetailsApiCallId) {
        if (responseJson) {
          this.showSnackBar("Report is successfully saved in Forms.", "success")
        }
      }


      if (apiRequestCallId == this.getReportApiCallId) {

        console.log('save usercat api called responseJson... ', responseJson);
        console.log('save usercat api called errorReponse... ', errorReponse);
        if (responseJson?.data && this.state.selectedButton == 'Financial') {
          this.setState({
            reportData: responseJson?.data[0],
            //  ? responseJson?.data[0]?.operating_expenses.data : []
          }, () => {
            if (this.state.isTransactionUpdated && !this.state.openModal) {
              this.updateSelectedCategoryAmount("Financial");
            }
          })
        } else if (responseJson?.data && this.state.selectedButton == 'Capital') {
          let stateTax = ""
          let federalTax = ""

          responseJson?.data[0]?.taxes?.forEach((element: any) => {
            if (element.name == "State Taxes") {
              stateTax = element
            }
            if (element.name == "Federal Taxes") {
              federalTax = element
            }
          })

          this.setState({
            capitalReportData: responseJson?.data[0],
            stateTaxForCapital: stateTax,
            federalTaxForCapital: federalTax,
          }, () => {
            if (this.state.isTransactionUpdated && !this.state.openModal) {
              this.updateSelectedCategoryAmount("Capital");
            }
          })
        }
      }
      if (apiRequestCallId == this.getReportDetailsApiCallId) {

        console.log('save getReportDetailsApiCallId called responseJson... ', responseJson);
        console.log('save getReportDetailsApiCallId called errorReponse... ', errorReponse);

        if (this.state.selectedCategory.id == "abc3") {

          let data = {};
          if (responseJson?.data?.[0]) {
            data = responseJson?.data?.[0]
          }
          // {
          //   "gross_profit": 410.0,
          //   "uncategorized_expense": 20000.0,
          //   "total_operating_expenses": 0,
          //   "mileage": 0.0,
          //   "net_profit": -19590.0
          // };

          let filteredData = [];

          for (const [key, value] of Object.entries(data)) {
            filteredData.push({
              category: key,
              amount: value
            });
          }

          this.setState({
            reportDetailsData: filteredData,
          })
        }

        else if (responseJson?.data) {
          if (this.state.selectedButton == 'Capital') {
            this.setState({
              capitalDetailsData: responseJson.data,
              resetPage: true
            })
          } else {
            this.setState({
              reportDetailsData: responseJson.data
            })
          }
        }
      }
      if (apiRequestCallId == this.getPdfApiCallId) {

        console.log('save getPdfApiCallId called responseJson... ', responseJson);
        console.log('save getPdfApiCallId called errorReponse... ', errorReponse);
        if (responseJson?.data) {
          if (this.state.selectedButton == 'Capital') {
            this.setState({
              financialCapitalStatementPdf: responseJson?.data?.attributes
            })
          } else {
            this.setState({
              financialStatementPdf: responseJson?.data?.attributes
            })
          }
        }
      }
      if (apiRequestCallId == this.getCsvApiCallId) {

        console.log('save getCsvApiCallId called responseJson... ', responseJson);
        console.log('save getCsvApiCallId called errorReponse... ', errorReponse);
        if (responseJson?.data) {
          if (this.state.selectedButton == 'Capital') {
            this.setState({
              financialCapitalStatementCsv: responseJson?.data?.attributes
            })
          } else {
            this.setState({
              financialStatementCsv: responseJson?.data?.attributes
            })
          }
        }
      }
      if (apiRequestCallId == this.getDetailsPdfApiCallId) {

        console.log('save getDetailsPdfApiCallId called responseJson... ', responseJson);
        console.log('save getDetailsPdfApiCallId called errorReponse... ', errorReponse);
        if (responseJson?.data) {
          if (this.state.selectedButton == 'Capital') {
            this.setState({
              capitalDetailStatementPdf: responseJson?.data?.attributes
            })
          } else {
            this.setState({
              financialDetailStatementPdf: responseJson?.data?.attributes
            })
          }
        }
      }
      if (apiRequestCallId == this.getDetailsCsvApiCallId) {
        console.log('save getDetailsCsvApiCallId called responseJson... ', responseJson);
        console.log('save getDetailsCsvApiCallId called errorReponse... ', errorReponse);
        if (responseJson?.data) {
          if (this.state.selectedButton == 'Capital') {
            this.setState({
              capitalDetailStatementCsv: responseJson?.data?.attributes
            })
          } else {
            this.setState({
              financialDetailStatementCsv: responseJson?.data?.attributes
            })
          }
        }
      }
    }
    // Customizable Area End
  }

  updateYear = (year: any) => {
    if (!year) {
      this.setState({ activeYear: new Date().getFullYear() })
    } else {
      this.setState({ activeYear: year })
    }

  }
  // Customizable Area Start

  updateSelectedCategoryAmount = (button: string) => {
    let cat = Object.assign({}, this.state.selectedCategory);
    console.log('cat === ', JSON.stringify(cat));


    if (button == "Capital") {
      switch (this.state.selectedCategory?.name) {
        case "Capital Contribution": cat.amount = this.state.capitalReportData?.capital_contribution
          break;
        case "Capital Distribution": cat.amount = this.state.capitalReportData?.capital_distribution
          break;
        case "State Taxes": cat.amount = this.state.stateTaxForCapital?.amount
          break;
        case "Federal Taxes": cat.amount = this.state.federalTaxForCapital?.amount
          break;

        default:
          for (let index = 0; index < this.state.capitalReportData?.capital_categories?.length; index++) {
            const element = this.state.capitalReportData.capital_categories[index];
            if (element.id == this.state.selectedCategory?.id) {
              cat.amount = element?.amount
            }
          }
      }
    } else {
      switch (this.state.selectedCategory?.name) {
        case "Total Income": cat.amount = this.state.reportData?.total_income
          break;
        case "Costs of Goods Sold": cat.amount = this.state.reportData?.cost_of_goods
          break;
        case "Net Profit": cat.amount = this.state.reportData?.net_operating_profit
          break;

        default:
          for (let index = 0; index < this.state.reportData?.operating_expenses?.data?.length; index++) {
            const element = this.state.reportData?.operating_expenses?.data[index];
            if (element.id == this.state.selectedCategory?.id) {
              cat.amount = element?.amount
            }
          }
      }
    }

    console.log('cat === updated', JSON.stringify(cat));

    this.setState({ selectedCategory: cat })
  }

  showSnackBar = (message: string, alertType?: string) => {
    this.setState({
      snackbarMessage: message,
      alertType: alertType ? "success" : 'error'
    }, () => {
      this.setState({
        showSnackbar: true
      })
    })
  }

  getReports = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReport}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}&transaction_type=expense`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // getReportDetails = (categoryId?: any) => {
  getReportDetails = () => {

    let categoryId = "";
    if (this.state.selectedCategory?.id) {
      categoryId = this.state.selectedCategory?.id
    }

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportDetailsApiCallId = requestMessage.messageId;
    this.setState({ resetPage: false })
    let URL = categoryId ?
      `${configJSON.getReportDetails}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&transaction_category_id=${categoryId}&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}&transaction_type=expense`
      :
      `${configJSON.getReportDetails}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}&transaction_type=expense`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPdfForFinancial = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPdfApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPDF}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&transaction_type=expense&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCsvForFinancial = () => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCsvApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCSV}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&transaction_type=expense&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReportDetailsPdf = (categoryId?: any) => {

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailsPdfApiCallId = requestMessage.messageId;

    let URL = categoryId ?
      `${configJSON.getDetailsPDF}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&transaction_category_id=${categoryId}&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}`
      :
      `${configJSON.getDetailsPDF}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReportDetailsCsv = () => {

    let categoryId = "";
    if (this.state.selectedCategory?.id) {
      categoryId = this.state.selectedCategory?.id
    }

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailsCsvApiCallId = requestMessage.messageId;

    let URL = categoryId ?
      `${configJSON.getDetailsCSV}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&transaction_category_id=${categoryId}&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}&transaction_type=expense`
      :
      `${configJSON.getDetailsCSV}?start_date=${moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY')}&end_date=${moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY')}&report_type=${this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement"}&transaction_type=expense`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveFormData = () => {
    console.log("saveFormData");

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const httpBody = {
      "start_date": moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY'),
      "end_date": moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY'),
      "report_type": this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement",
      "transaction_type": "expense",
      "report_period": this.state.reportPeriod
    }


    this.saveFormDataApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveFormDataApiEndPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  saveFormDetails = () => {

    let categoryId = "";
    if (this.state.selectedCategory?.id) {
      categoryId = this.state.selectedCategory?.id
    }

    let token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let httpBody = {
      "start_date": moment(this.state.reportPeriodStartDate).format('DD/MM/YYYY'),
      "end_date": moment(this.state.reportPeriodEndDate).format('DD/MM/YYYY'),
      "report_type": this.state.selectedButton == 'Capital' ? "Capital" : "Financial Statement",
      "transaction_type": "expense",
      "report_period": this.state.reportPeriod,
      // "transaction_category_id": ""
    };

    if (categoryId) {
      // @ts-ignore
      httpBody.transaction_category_id = categoryId
    }

    this.saveFormDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveFormDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  // Customizable Area End

}

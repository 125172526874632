//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField
} from "@material-ui/core";
import CompanySettingController from "./CompanyController.web";
import { ReactComponent as FileAdd } from '../../dashboard/assets/file-add.svg'
import { number } from "yup";

const useStyles = (theme) => ({
  gridBox: {
    padding: "20px 0"
  },
  gridMiddleBox: {
    padding: "10px 0",
    borderTop: "2px solid #DADADA",
    borderBottom: "2px solid #DADADA",
  },
  gridTitle: {
    paddingLeft: "50px",
    paddingTop: "10px",
    fontWeight: "bold"
  },
  textField: {
    width: "240px",
    "& div": {
      borderRadius: "4px"
    },
    "& input": {
      padding: "10px",
    }
  },
  dataGrid: {
    padding: "10px 0",

  },
  formSelect: {
    width: "240px",
    border: "1px solid #000000",
    "& div": {
      padding: "10px",
    }
  },
  fileInput: {
    display: "none"
  },
  fileName: {
    gap: '10px'
  },
  fileIcon: {
    height: "23px",
    width: "23.58px",
    "& path": {
      fill: "#FF9933"
    }
  },
  fileModalTitle: {
    paddingBottom: "10px",
    borderBottom: "1px solid #000000"
  },
  fileUploadBox: {
    width: "145px",
    height: "145px",
    background: '#B5D8FD80',
    border: "1px solid #979797",
    borderRadius: "5px"
  },
  fileSVG: {
    width: "50px",
    height: "90px",
    '& g': {
      fill: '#5696F4'
    }
  },
  fileImagePreview: {
    width: "145px",
    height: "145px",
    border: "1px solid #979797",
    borderRadius: "5px",
    "& img": {
      maxWidth: "140px",
      maxHeight: "140px",
      objectFit: "contain"
    }
  },
  buttons: {
    marginLeft: "10px",
    height: "25px",
    background: "#5597F4",
    borderRadius: "20px",
    color: "#ffffff",
    fontSize: "13px",
    "&:hover": {
      background: "#5597F4"
    },
    "&:disabled": {
      color: "#ffffff",
      background: "#89898A"
    }
  },
  valueField: {
    color: "#CBCCCC !important",
    paddingLeft: "7px"
  },
  selectOption: {
    width: "240px",
    whiteSpace: 'normal'
  },
  font20: {
    fontSize: '20px',
    '& input': {
      fontSize: '20px'
    }
  },
  font13: {
    fontSize: '13px',
    '& input': {
      fontSize: '13px'
    }
  },
  '@media (max-width:1425px)': {
    font20: {
      fontSize: "15px",
      '& input': {
        fontSize: '15px'
      }
    },
  },
  '@media (max-width:900px)': {
    font20: {
      fontSize: '11.25px',
      '& input': {
        fontSize: '11.25px'
      }
    }
  }
})
class CompanySettingWeb extends CompanySettingController {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    }
  }

  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover" || e.type === 'dragleave') {
      this.props.updatingValue('dragActive', true)
    }
  }
  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.updatingValue('dragActive', false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.props.updatingValue('file', e.dataTransfer.files[0])
      this.props.updatingValue('localImage', true)
    }
  };

  handleFile = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      this.props.updatingValue('file', e.target.files[0])
      this.props.updatingValue('localImage', true)
    }
  }
  updateData(key, value) {
    if (key.includes('phone')) {
      const numberValue = value.replace(/[^\d]/g, "")
      if (numberValue.length > 10) return
      value = numberValue.length > 3 && numberValue.length < 7 ? '(' + numberValue.substr(0, 3) + ')' + numberValue.substr(3)
        : numberValue.length > 6 ? '(' + numberValue.substr(0, 3) + ')' + numberValue.substr(3, 3) + '-' + numberValue.substr(6)
          : numberValue
    } else if (key.includes('tax_id')) {
      const idValue = value.replace(/-/g, "")
      if (idValue.length > 9) return
      value = idValue.length > 2 ? idValue.substr(0, 2) + '-' + idValue.substr(2) : idValue
    }
    const dataToInsert = { ...this.props.states.companyData, [key]: value }
    this.props.updatingValue('companyData', dataToInsert)
  }

  async componentDidMount() {
    this.props.getcompanyData()
    this.props.updatingValue('showButton', true)
  }
  async componentWillUnmount() {
    this.props.updatingValue('showButton', false)
    this.props.updatingValue('editFlag', false)
  }
  render() {
    const { classes } = this.props
    return (
      <Grid item container xs={12} justifyContent="center">
        <Grid item container xs={12} className={classes.gridBox}>
          <Grid item container xs={4}>
            <Typography variant="body1" className={classes.font20} className={`${classes.gridTitle} ${classes.font20}`}>
              Company Name
            </Typography>
          </Grid>
          <Grid item container xs={8} justifyContent="center">
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography varisant="body1" className={classes.font20}>
                  Business Name
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.business_name : ''} onChange={(e) => this.updateData('business_name', e.target.value)} InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.business_name ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.business_name}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Business Name</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20} className={classes.font20}>
                  Proprietor Name
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.proprietor_name : ''} onChange={(e) => this.updateData('proprietor_name', e.target.value)} InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.proprietor_name ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.proprietor_name}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Proprietor Name</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Tax Classification
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                <Select disabled={this.state.editFlag}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  className={`${classes.formSelect} ${classes.font20}`} disableUnderline onChange={(e) => this.updateData('tax_classification', e.target.value)} value={this.props.states.companyData.tax_classification ? this.props.states.taxCategories[this.props.states.taxCategories.findIndex((el) => el == this.props.states.companyData.tax_classification)] : this.props.states.taxCategories[0]}>
                  {this.state.taxCategories.map(name =>
                    <MenuItem value={name} className={`${classes.selectOption} ${classes.font20}`}>{name}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Federal Tax ID EIN
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.tax_id : ''} onChange={(e) => this.updateData('tax_id', e.target.value)} InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.tax_id ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.tax_id}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Tax Id</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  SSN#
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.company_ssn : ''} onChange={(e) => this.updateData('company_ssn', e.target.value)} InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.company_ssn ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.company_ssn}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>SSN#</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Business Code
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.business_code : ''} onChange={(e) => this.updateData('business_code', e.target.value)} InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.business_code ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.business_code}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Business Code</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Business Logo
                </Typography>
              </Grid>
              <Grid item container alignItems="center" xs={6}>
                <input
                  onChange={(event) => this.handleFile(event)}
                  type="file"
                  className={classes.fileInput}
                  id="enterFile"
                  accept="image/png, image/gif, image/jpeg"
                  disabled={!this.props.states.editFlag}
                />
                <label htmlFor="enterFile"
                  onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}>
                  {this.props.states.file ?
                    <Grid container justifyContent="center" alignItems="center" className={classes.fileImagePreview}>
                      <img src={
                        this.props.states.localImage ? URL.createObjectURL(this.props.states.file) : this.props.states.file} />

                    </Grid>
                    :
                    <Grid item container className={classes.fileUploadBox} justifyContent='center' alignItems="center">
                      <Grid item container xs={12} className={classes.fileContent} justifyContent="center" alignItems="center">
                        <Grid item container xs={12} justifyContent="center" alignContent="center">
                          <FileAdd className={classes.fileSVG} />
                        </Grid>
                        <Grid item container xs={12} justifyContent="center" alignContent="center">
                          <Typography display="block" className={classes.font13}>Drop your files here</Typography>
                          <Typography display="block" className={classes.font13}>or click to browse</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </label>
                <Button disabled={!this.props.states.editFlag} className={classes.buttons} >
                  <label className={classes.label} htmlFor="enterFile">
                    Browse
                  </label>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.gridMiddleBox}>
          <Grid item container xs={4}>
            <Typography variant="body1" className={classes.font20} className={`${classes.gridTitle} ${classes.font20}`}>
              Contact Information
            </Typography>
          </Grid>
          <Grid item container xs={8} justifyContent="center">
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Contact Name
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.contact_name : ''} onChange={(e) => this.updateData('contact_name', e.target.value)} InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.contact_name ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.contact_name}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Contact Name</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Contact Email
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.contact_email : ''} onChange={(e) => this.updateData('contact_email', e.target.value)} InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.contact_email ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.contact_email}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Contact Email</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Contact Phone
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.contact_phone_number : ''}
                    onChange={(e) => this.updateData('contact_phone_number', e.target.value)}
                    InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.contact_phone_number ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.contact_phone_number}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Contact Phone</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="center">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Company Phone
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.company_phone_number : ''}
                    onChange={(e) => this.updateData('company_phone_number', e.target.value)}
                    InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.company_phone_number ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.company_phone_number}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Company Phone</Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.gridBox}>
          <Grid item container xs={4}>
            <Typography variant="body1" className={classes.font20} className={`${classes.gridTitle} ${classes.font20}`}>
              Address
            </Typography>
          </Grid>
          <Grid item container xs={8} justifyContent="center">
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="flex-start">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Company Address 1
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.company_address : ''}
                    onChange={(e) => this.updateData('company_address', e.target.value)}
                    InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.company_address ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.company_address}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Company Address</Typography>
                }
              </Grid>
            </Grid>
            <Grid item container className={classes.dataGrid} justifyContent="space-between" alignItems="flex-start">
              <Grid item container xs={4}>
                <Typography variant="body1" className={classes.font20}>
                  Company Address 2
                </Typography>
              </Grid>
              <Grid item container xs={6} >
                {this.props.states.editFlag ?
                  <TextField value={this.props.states.companyData ? this.props.states.companyData.company_address2 : ''}
                    onChange={(e) => this.updateData('company_address2', e.target.value)}
                    InputProps={{ disableUnderline: true }} className={`${classes.textField} ${classes.font20}`} variant="filled" />
                  : this.props.states.companyData.company_address2 ?
                    <Typography className={`${classes.valueField} ${classes.font20}`}>{this.props.states.companyData.company_address2}</Typography>
                    : <Typography className={`${classes.valueField} ${classes.font20}`}>Company Address 2</Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(CompanySettingWeb)

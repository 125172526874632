export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CardPng = require("../assets/Card.png");
export const lockWithChecked = require("../assets/lockwithchecked.png");
export const lockWithCheckedblue = require("../assets/lockwithcheckedblue.png");
export const AmericanExpress = require("../assets/americanExpress.jpg");
export const MasterCard = require("../assets/mastercard.png");
export const discoverCard = require("../assets/discover_card.jpg");
export const jcbCard = require("../assets/jcb.png");
export const dinersCard = require("../assets/diners.png");
export const visaCard = require("../assets/visa.png");
export const blank_Card = require("../assets/blankCard.jpg");
export const maestroCard = require("../assets/maestroCard.png");
export const unionCard = require("../assets/unionpay.png");
export const blueImage = require("../assets/111951013vxHOsHALPpTKW3CbwTojop.png");
export const leftLeave = require("../assets/111951014vxHOsHALPpTKW3CbwTojop.png");
export const rightLeave = require("../assets/111951015vxHOsHALPpTKW3CbwTojop.png");
export const orangeLine = require("../assets/10938902vxHOsHALPpTKW3CbwTojop.png");
export const showPassword = require("../assets/I10941107221945296vxHOsHALPpTKW3CbwTojop.png");
export const hidePassword = require("../assets/I1131441819447591944621vxHOsHALPpTKW3CbwTojop.png");
export const checkIcon = require("../assets/checkbox_.png");

//@ts-nocheck
import React from "react";
import DashboardController from "./DashboardController";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
} from "@material-ui/core";
import Settings5Controller from "./Settings5Controller";

class CreditCardSettingWeb extends Settings5Controller {
  render() {
    console.log(this.props);
    return (
      <div className="root">
        <Typography variant="h5">Credit Card setting</Typography>
        {/* <h1 className={classes.lol}>Hello</h1> */}
      </div>
    );
  }
}

export default CreditCardSettingWeb;

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { Image, Alert } from "react-native";
import { carIcon, imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, scaledSize } from "framework/src/Utilities";
import moment from "moment";
import { CalendarUtils } from "../../../components/src/CustomCalendar";
import { displayToastAlert } from "../../../components/src/CToastAlert";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  yearToDateMiles: number;
  totalMonthlyMiles: number;
  totalDeduction: number;
  mileageData: any;
  selectedFilterValue: string;
  carsData: any;
  carsLoading: boolean;
  noCarsView: boolean;
  isVisibleYearToDateModal: boolean;
  fromDate: string;
  toDate: string;
  applyFilterValue: string;
  startDate: string;
  endDate: string;
  isFromDateCalendarVisible: boolean;
  markedFromDate: any;
  isToDateCalendarVisible: boolean;
  markedToDate: any;
  showAddMileageComponent: boolean;
  carsDropdownData: any;
  isOpenCarsDropdown: boolean;
  carId: number;
  selectedCarData: any;
  businessPurposeData: any;
  businessPurpose: string;
  additionalNotes: string;
  isOpenBusinessPurposeDropdown: boolean;
  businessMiles: string;
  openDatePicker: boolean;
  mileageDate: any;
  markedMileageDate: any;
  businessMilesFocus: boolean;
  additionalNotesFocus: boolean;
  isMileageInfoModalVisible: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CalendarController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getMileageDataAPICallID: string = "";
  getCarsAPICallId: string = "";
  addMileageAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      yearToDateMiles: 0,
      totalMonthlyMiles: 0,
      totalDeduction: 0,
      mileageData: [],
      selectedFilterValue: "Year to Date",
      carsData: [],
      carsLoading: false,
      noCarsView: false,
      isVisibleYearToDateModal: false,
      fromDate: `01/01/${new Date().getFullYear()}`,
      toDate: `31/12/${new Date().getFullYear()}`,
      applyFilterValue: "Year to Date",
      startDate: `01/01/${new Date().getFullYear()}`,
      endDate: `31/12/${new Date().getFullYear()}`,
      isFromDateCalendarVisible: false,
      markedFromDate: {
        [CalendarUtils.getCalendarDateString(`01/01/${new Date().getFullYear()}`)]: {
        selected: true,
        selectedColor: "#3876F1",
      }
    },
      isToDateCalendarVisible: false,
      markedToDate: {
        [CalendarUtils.getCalendarDateString(`12/31/${new Date().getFullYear()}`)]: {
        selected: true,
        selectedColor: "#3876F1",
      }
    },
      showAddMileageComponent: false,
      carsDropdownData: [],
      isOpenCarsDropdown: false,
      carId: 0,
      selectedCarData: {},
      businessPurposeData: [{ value: "Meeting with client", label: "Meeting with client" }, { value: "Visiting job site", label: "Visiting job site" }, { value: "Pick up supplies", label: "Pick up supplies" }],
      businessPurpose: "",
      additionalNotes: "",
      isOpenBusinessPurposeDropdown: false,
      additionalNotesFocus: false,
      businessMiles: "",
      openDatePicker: false,
      mileageDate: null,
      markedMileageDate: {},
      businessMilesFocus: false,
      isMileageInfoModalVisible: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        this.successApiCallBack(apiRequestCallId, responseJson);
      } else if (responseJson?.errors) {
        this.failureApiCallBack(apiRequestCallId, responseJson);
      } else {
        if (apiRequestCallId === this.getCarsAPICallId) {
          this.setState({ noCarsView: true });
        }
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ carsLoading: false, isLoading: false });
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  async componentDidMount() {
    this.getCarsData();
    this.getMileageData();
    let date = new Date()
    date.setDate(date.getDate() - 1);
    let markedMileageDate = {[CalendarUtils.getCalendarDateString(date)]: {
      selected: true,
      selectedColor: "#3876F1",
    }}
    this.setState({ mileageDate: date, markedMileageDate });
  }

  successApiCallBack = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getMileageDataAPICallID:
        this.getMileageApiSuccessCallback(responseJson.data);
        break;
      case this.getCarsAPICallId:
        this.getCarsDataApiSuccessCallback(responseJson.data)
        break;
      case this.addMileageAPICallId:
        this.addMileageApiSuccessCallBack();
        break;
      default:
        break;
    }
  }

  failureApiCallBack = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getMileageDataAPICallID:
        this.getMileageApiFailureCallback(responseJson.errors);
        break;
      case this.getCarsAPICallId:
        this.getCarsDataApiFailureCallback(responseJson.errors);
        break;
      case this.addMileageAPICallId:
        this.addMileageApiFailureCallBack(responseJson.errors);
        break;
      default:
        break;
    }
  }

  getMileageData = async () => {
    this.setState({ isLoading: true })
    const token = await getStorageData("authToken")
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMileageDataAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMileageApiEndPoint}start_date=${this.state.startDate}&end_date=${this.state.endDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMileageApiSuccessCallback = (data: any) => {
    let mileage_data: any = [];
    data.mileages?.forEach((item: any) => {
      mileage_data = mileage_data.concat(item.mileage_data)
    })
    this.setState({ 
      yearToDateMiles: data.mileage_deduction?.yearly_miles || 0,
      totalMonthlyMiles: data.mileage_deduction?.monthly_miles || 0,
      totalDeduction: data.mileage_deduction?.total_mileage_deduction?.toFixed(2) || 0,
      mileageData: mileage_data,
      isLoading: false,
    });
  }

  getMileageApiFailureCallback = (errors: any) => {
    this.setState({ isLoading: false });
  }

  getCarsData = async () => {
    this.setState({ carsLoading: true })
    const token = await getStorageData("authToken");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCarsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCarsApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCarsDataApiSuccessCallback = (data: any) => {
    let carsDropdownData: any = [];
    let filteredData = data.filter((item: any) => item.attributes.is_activated)
    console.log("getCarsData:::", filteredData)
    if (filteredData.length === 0) {
      this.setState({ noCarsView: true });
    } else {
      this.setState({ noCarsView: false });
      filteredData.forEach((item: any) => {
        const obj = {
          ...item,
          label: item.attributes.name,
          value: item.id,
          icon: () => <Image source={carIcon} style={{ height: scaledSize(23), width: scaledSize(33), tintColor: item.attributes.color.toLowerCase(), resizeMode: "contain"}} />
        }
        carsDropdownData.push(obj);
      })
    }
    this.setState({ carsLoading: false, carsData: filteredData, carsDropdownData, carId: carsDropdownData[0].value, selectedCarData: carsDropdownData[0] });
  }

  getCarsDataApiFailureCallback = (errors: any) => {
    this.setState({ carsLoading: false, noCarsView: true });
  }

  onPressBack = () => {
    this.setState({ noCarsView: false });
  }

  onPressAddMileage = () => {
    if (this.state.carsData.length === 0) {
      this.setState({ noCarsView: true });
    } else {
      this.setState({ showAddMileageComponent: true });
    }
  }

  onPressNoMileage = () => {
    if (this.state.carsData.length === 0) {
      this.setState({ noCarsView: true });
    } else {
      this.addMileageApiCall();
    }
  }

  openDateFilterModal = () => {
    this.setState({ isVisibleYearToDateModal: true });
  }

  closeDateFilterModal = () => {
    this.setState({ isVisibleYearToDateModal: false });
  }

  onPressApplyFilters = () => {
    this.closeDateFilterModal();
    this.setState({ 
      startDate: this.state.fromDate,
      endDate: this.state.toDate,
      applyFilterValue: this.state.selectedFilterValue,
    }, () => {
      this.getMileageData();
    })
  }

  onPressFilters = (item: string) => {
    switch (item) {
      case "This Week":
        this.getThisWeekStartDateEndDate();
        break;
      case "This Month":
        this.getThisMonthStartDateEndDate();
        break;
      case "This Quarter":
        this.getThisQuarterStartDateEndDate();
        break;
      case "Year to Date":
        this.setState({
          fromDate: `01/01/${new Date().getFullYear()}`,
          toDate: `31/12/${new Date().getFullYear()}`,
          selectedFilterValue: "Year to Date"
        });
        break;
      default:
        break;
    }
  }

  getThisWeekStartDateEndDate = () => {
    let curr = new Date; // get current date
    let first = curr.getDate() - curr.getDay();
    let firstdayOb = new Date(curr.setDate(first));
    let firstday = moment(firstdayOb.toUTCString()).format("DD/MM/YYYY");

    let firstdayTemp = firstdayOb;
    let lastday =moment( new Date(firstdayTemp.setDate(firstdayTemp.getDate() + 6 )).toUTCString()).format("DD/MM/YYYY");
    const markedFromDate = this.getMarkedFromDate(moment(firstday, "DD/MM/YYYY").format("MM/DD/YYYY"))
    const markedToDate = this.getMarkedToDate(moment(lastday, "DD/MM/YYYY").format("MM/DD/YYYY"))
    this.setState({ fromDate: firstday, toDate: lastday, selectedFilterValue: "This Week", markedFromDate, markedToDate });
  }

  getThisMonthStartDateEndDate = () => {
    let date = new Date();
    let firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("DD/MM/YYYY");
    let lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format("DD/MM/YYYY");
    const markedFromDate = this.getMarkedFromDate(moment(firstDay, "DD/MM/YYYY").format("MM/DD/YYYY"));
    const markedToDate = this.getMarkedToDate(moment(lastDay, "DD/MM/YYYY").format("MM/DD/YYYY"));
    this.setState({ 
      fromDate: firstDay,
      toDate: lastDay,
      selectedFilterValue: "This Month",
      markedFromDate,
      markedToDate,
    });
  }

  getThisQuarterStartDateEndDate = () => {
    const today = new Date();
    // Get current quarter
    const currentQuarter = Math.floor((today.getMonth() / 3)); 
    // Get current year
    const year = today.getFullYear();
    // Get current quarter's start and end date
    let quarterStart = moment(new Date(year, currentQuarter * 3, 1)).format("DD/MM/YYYY"); 
    let quarterEnd = moment(new Date(year, (currentQuarter + 1) * 3, 0)).format("DD/MM/YYYY");
    console.log('Quarter start date: ', quarterStart);
    console.log('Quarter end date: ', quarterEnd);
    const markedFromDate = this.getMarkedFromDate(moment(quarterStart, "DD/MM/YYYY").format("MM/DD/YYYY"));
    const markedToDate = this.getMarkedToDate(moment(quarterEnd, "DD/MM/YYYY").format("MM/DD/YYYY"));
    this.setState({
      fromDate: quarterStart,
      toDate: quarterEnd,
      selectedFilterValue: "This Quarter",
      markedFromDate,
      markedToDate,
    });
  }

  onPressCancelModal = () => {
    this.closeDateFilterModal();
    const markedFromDate = this.getMarkedFromDate(moment(this.state.startDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
    const markedToDate = this.getMarkedToDate(moment(this.state.endDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
    this.setState({ 
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      selectedFilterValue: this.state.applyFilterValue,
      markedFromDate,
      markedToDate,
    })
  }

  onPressFromDay = (day: any) => {
    console.log("moment(day.dateString).format(DD/MM/YYYY)", moment(day.dateString).format("DD/MM/YYYY"));
    const markedFromDate = this.getMarkedFromDate(day.dateString);
    this.setState({ fromDate: moment(new Date(day.dateString)).format("DD/MM/YYYY"), markedFromDate, selectedFilterValue: "Custom", isFromDateCalendarVisible: false });
  }

  onPressToDay = (day: any) => {
    console.log("moment(day.dateString).format(DD/MM/YYYY)", moment(day.dateString).format("DD/MM/YYYY"));
    const markedToDate = this.getMarkedToDate(day.dateString);
    this.setState({ toDate: moment(new Date(day.dateString)).format("DD/MM/YYYY"), selectedFilterValue: "Custom", isToDateCalendarVisible: false, markedToDate });
  }

  getMarkedFromDate = (date: string) => {
    let markedFromDate: any = {};
    markedFromDate[CalendarUtils.getCalendarDateString(date)] = {
      selected: true,
      selectedColor: "#3876F1",
    };
    return markedFromDate;
  }

  getMarkedToDate = (date: string) => {
    let markedToDate: any = {};
    markedToDate[CalendarUtils.getCalendarDateString(date)] = {
      selected: true,
      selectedColor: "#3876F1",
    };
    return markedToDate;
  }

  onOpenFromDateCalendar = () => {
    this.setState({ isFromDateCalendarVisible: true });
  }

  onCloseFromDateCalendar = () => {
    this.setState({ isFromDateCalendarVisible: false });
  }

  onOpenToDateCalendar = () => {
    this.setState({ isToDateCalendarVisible: true });
  }

  onCloseToDateCalendar = () => {
    this.setState({ isToDateCalendarVisible: false });
  }

  onOpenCarsDropdown = () => {
    this.setState({ isOpenCarsDropdown: !this.state.isOpenCarsDropdown, isOpenBusinessPurposeDropdown: false });
  }

  onSetCarValue = (item: any) => {
    this.setState({ carId: item.value, selectedCarData: item });
  }

  onOpenBusinessPurposeDropdown = () => {
    this.setState({ isOpenBusinessPurposeDropdown: !this.state.isOpenBusinessPurposeDropdown, isOpenCarsDropdown: false });
  }

  onSetBusinessPurposeValue = (item: any) => {
    this.setState({ businessPurpose: item.value });
  }

  onFocusAdditionalNotes = () => {
    this.setState({ additionalNotesFocus: true });
  }

  onBlurAdditionalNotes = () => {
    this.setState({ additionalNotesFocus: false });
  }

  onChangeAdditionalNotes = (value: string) => {
    this.setState({ additionalNotes: value });
  }

  onFocusBusinessMiles = () => {
    this.setState({ businessMilesFocus: true });
  }

  onBlurBusinessMiles = () => {
    this.setState({ businessMilesFocus: false });
  }

  onChangeBusinessMiles = (value: string) => {
    this.setState({ businessMiles: value });
  }

  onPressInfoIcon = () => {
    this.onOpenMileageInfoModal();
    if (this.state.showAddMileageComponent) {
      Alert.alert("In Progress");
    }
  }

  onOpenDate = (value: boolean) => {
    this.setState({ openDatePicker: value });
  }

  onChangeMileageDate = (date: any) => {
    let markedMileageDate: any = {};
    markedMileageDate[CalendarUtils.getCalendarDateString(date.dateString)] = {
      selected: true,
      selectedColor: "#3876F1",
    };
    this.onOpenDate(false);
    console.log("change date:::", new Date(date.dateString));
    this.setState({ mileageDate: new Date(date.dateString), markedMileageDate });
  }

  addMileageApiCall = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };
    const data = {
      attributes: {
        date_of_mileage: moment(this.state.mileageDate, "ddd MMM DD, YYYY").format("DD/MM/YYYY"),
        car_id: this.state.carId,
        miles: Number(this.state.businessMiles) || 0,
        business_purpose: this.state.businessPurpose,
        additional_notes: this.state.additionalNotes,
      }
    };
    console.log("data:::", data);
    const httpBody = {
      data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addMileageAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addMileageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addMileageApiSuccessCallBack = () => {
    this.setState({ showAddMileageComponent: false });
    this.getMileageData();let date = new Date()
    date.setDate(date.getDate() - 1);
    let markedMileageDate = {[CalendarUtils.getCalendarDateString(date)]: {
      selected: true,
      selectedColor: "#3876F1",
    }}
    this.setState({ mileageDate: date, markedMileageDate, businessMiles: "", carId: this.state.carsDropdownData[0].value, selectedCarData: this.state.carsDropdownData[0], businessPurpose: "", additionalNotes: "", });
  }

  addMileageApiFailureCallBack = (error: any) => {
    displayToastAlert("error", error.message);
  }

  onSaveAddMileageDetails = () => {
    if (!this.state.businessMiles) {
      displayToastAlert("error", configJSON.emptyMilesMessage);
    } else if (!this.state.businessPurpose) {
      displayToastAlert("error", configJSON.emptyBusinessPurpose);
    } else {
      this.addMileageApiCall();
    }
  }

  onOpenMileageInfoModal = () => {
    this.setState({
      isMileageInfoModalVisible: true,
    })
  }

  onCloseMileageInfoModal = () => {
    this.setState({
      isMileageInfoModalVisible: false,
    })
  }
  // Customizable Area End
}

//@ts-nocheck
 // Customizable Area Start
import React from "react";
import DashboardController from "./DashboardController";
import {
    // Customizable Area Start
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Switch
    // Customizable Area End
} from "@material-ui/core";
import Settings5Controller from "./Settings5Controller";
  // Customizable Area End
const useStyles = (theme) => ({
  // Customizable Area Start
  viewSwitch: {
    width: "55px",
    height: "24px",
    padding: "0px",
    paddingLeft: "3"
  },
  switchBase: {
    color: "#818181",
    padding: "1px",
    width: "23px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#ff9a30"
      }
    }
  },
  thumb: {
    color: "white",
    width: "17px",
    height: "17px",
    margin: "1px"
  },
  track: {
    width: "45px",
    height: "21px",
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "10px",
      position: "absolute",
      top: "6px"
    },
    "&:after": {
      content: "'YES'",
      left: "9px"
    },
    "&:before": {
      content: "'NO'",
      right: "6px"
    }
  },
  checked: {
    color: "#FF9933 !important",
    transform: "translateX(26px) !important"
  },
  settingsFont: {
    fontWeight: "700",
    fontSize: "20px",
    '@media (max-width:1425px)': {
      fontSize: '15px',

    },
  },
  // Customizable Area End
})
class OtherSettingWeb extends Settings5Controller {
    // Customizable Area Start
    // Customizable Area End
  render() {
    console.log(this.props);
    const { classes } = this.props;
    return (
        // Customizable Area Start
      <div className="root">
        <Grid container direction="column" style={{ margin: "55 0 0 60" }}>
          <Grid item style={{ paddingBottom: "20px" }}><Typography className={classes.settingsFont}>Game Settings</Typography></Grid>
          <Grid item style={{ paddingBottom: "15px", width:'58%' }}><Typography >By turning on this setting you will be reminded to enter mileage and categorize income and expenses every day. You can follow your streak and earn leaves along the way!</Typography></Grid>
          <Grid item >
            <Switch
              value={this.state.otherSettingsUseMileage}
              classes={{
                root: classes.viewSwitch,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
              }}
              checked={this.state.otherSettingsUseMileage}
              onChange={(e) => {
                this.setState({ otherSettingsUseMileage: e.target.checked })
                this.UpdateMileageSwitch(e.target.checked)
              }}
              data-testid="my-switch"
            />
          </Grid>
        </Grid>

        {/* <h1 className={classes.lol}>Hello</h1> */}
      </div>
        // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withStyles(useStyles)(OtherSettingWeb);
export { OtherSettingWeb };
// Customizable Area End
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, TextInput, Image, TouchableWithoutFeedback } from 'react-native';
import DropDownPicker from '../../components/src/DropDownPicker';
import { scaledSize } from '../../framework/src/Utilities';
import Button from './Button';
import moment from 'moment';
import CustomModal from './CustomModal';
import { Calendar as CustomCalendar } from "../../components/src/CustomCalendar";

type MyProps = {
    testID?: string,
    state: any,
    onChangeMileageDate: (e: any) => void,
    onOpenDatePicker: (e: boolean) => void,
    onChangeAdditionalNotes: (e: string) => void,
    setOpenCarsDropdown: () => void,
    onChangeCarsValue: (e: any) => void,
    setOpenBusinessPurposeDropdown: () => void,
    onChangeBusinessPurposeValue: (e: any) => void,
    onFocusAdditionalNotes: () => void,
    onBlurAdditionalNotes: () => void,
    onSaveEdit: () => void,
    onChangeBusinessMiles: (e: string) => void,
    renderCalendarArrow: (direction: string) => React.ReactNode,
    onFocusBusinessMiles: () => void,
    onBlurBusinessMiles: () => void,
};
type MyState = {};

export default class AddMileage extends Component<MyProps, MyState> {
  static readonly propTypes = {
    testID: PropTypes.string, 
    state: PropTypes.any.isRequired,
    onChangeMileageDate: PropTypes.func,
    onOpenDatePicker: PropTypes.func,
    onChangeAdditionalNotes: PropTypes.func,
    setOpenCarsDropdown: PropTypes.func,
    onChangeCarsValue: PropTypes.func,
    setOpenBusinessPurposeDropdown: PropTypes.func,
    onChangeBusinessPurposeValue: PropTypes.func,
    onFocusAdditionalNotes: PropTypes.func,
    onBlurAdditionalNotes: PropTypes.func,
    onSaveEdit: PropTypes.func,
    onChangeBusinessMiles: PropTypes.func,
    renderCalendarArrow: PropTypes.func,
    onFocusBusinessMiles: PropTypes.func,
    onBlurBusinessMiles: PropTypes.func,
  };
  milesRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
    };
    this.milesRef = React.createRef();
  }

  componentDidMount(): void {
      this.milesRef.focus()
  }

  render() {
      const {
          testID,
          state,
          onChangeMileageDate,
          onOpenDatePicker,
          onChangeAdditionalNotes,
          setOpenCarsDropdown,
          onChangeCarsValue,
          setOpenBusinessPurposeDropdown,
          onChangeBusinessPurposeValue,
          onFocusAdditionalNotes,
          onBlurAdditionalNotes,
          onSaveEdit,
          onChangeBusinessMiles,
          renderCalendarArrow,
          onFocusBusinessMiles,
          onBlurBusinessMiles,
      } = this.props;
    return (
        <View style={styles.container} testID={testID}>
            <TouchableOpacity style={styles.datePickerView} onPress={() => onOpenDatePicker(true)}>
                <View style={styles.dateTextButton}>
                    {/* <Text style={styles.dateText}>Mon Jan 05, 2024</Text> */}
                    <Text style={styles.dateText}>{moment(state.mileageDate).format("ddd MMM DD, YYYY")}</Text>
                </View>
                <Image source={require("./calendarIcon.png")} style={styles.calendarIcon} />
            </TouchableOpacity>
            <View style={[styles.seperatorLine, state.openDatePicker && {borderBottomColor: "#3876F1" }]} />
            {/* <DatePicker
                modal
                open={state.openDatePicker}
                date={state.mileageDate}
                onConfirm={(date: any) => {
                    onOpenDatePicker(false)
                    onChangeMileageDate(date)
                }}
                onCancel={() => {
                    onOpenDatePicker(false)
                }}
                mode='date'
                maximumDate={new Date()}
            /> */}
            <View style={styles.milesView}>
                <View style={styles.businessMilesTextView}>
                    <Text style={styles.businessMilesText}>Business Miles</Text>
                </View>
                <TextInput
                    style={styles.milesValue}
                    value={state.businessMiles}
                    onChangeText={onChangeBusinessMiles}
                    placeholder='23'
                    placeholderTextColor={"#999"}
                    keyboardType='number-pad'
                    onFocus={onFocusBusinessMiles}
                    onBlur={onBlurBusinessMiles}
                    ref={(ref) => this.milesRef = ref}
                />
                {!state.businessMiles && <View style={styles.dividerLine} />}
                <Text style={styles.mileText}>Miles</Text>
            </View>
            <View style={[styles.seperatorLine, state.businessMilesFocus && { borderBottomColor: "#3876F1" }]} />
            {state.carsDropdownData.length > 2 ? <DropDownPicker
                items={state.carsDropdownData}
                style={styles.dropDownStyle}
                value={state.carId}
                setValue={() => { }}
                onSelectItem={onChangeCarsValue}
                setOpen={setOpenCarsDropdown}
                open={state.isOpenCarsDropdown}
                containerStyle={styles.dropDownContainerStyle}
                listParentContainerStyle={[styles.pickerStyle]}
                listItemContainerStyle={{ borderColor: "#999", borderWidth: 1, height: scaledSize(42) }}
                flatListProps={{
                    initialNumToRender: 3
                }}
                listMode="FLATLIST"
                showTickIcon={false}
                textStyle={styles.businessPurposeValue}
            /> :
                <View style={styles.twoCarsView}>
                    <TouchableOpacity style={styles.addCarsBtnView}>
                        <Image source={require("./carPlusIcon.png")} style={styles.addCarImage} />
                        <Text style={styles.addCarText} numberOfLines={1}>ADD CAR</Text>
                    </TouchableOpacity>
                    {state.carsDropdownData.map((item: any) => (
                        <TouchableOpacity style={[styles.addCarsBtnView, item.value === state.carId && { borderWidth: 2, borderColor: "#3876F1" }]} key={item.value} onPress={() => onChangeCarsValue(item)}>
                            <Image source={require("./grey_car.png")} style={[styles.addCarImage, { tintColor: item.attributes.color.toLowerCase(), resizeMode: "contain" }]} />
                            <Text style={styles.addCarText} numberOfLines={1}>{item.label}</Text>
                        </TouchableOpacity>
                    ))}
                </View>}
            <DropDownPicker
                items={state.businessPurposeData}
                style={styles.businessPurposeDropdownStyle}
                value={state.businessPurpose}
                setValue={() => {}}
                onSelectItem={onChangeBusinessPurposeValue}
                setOpen={setOpenBusinessPurposeDropdown}
                open={state.isOpenBusinessPurposeDropdown}
                containerStyle={styles.dropDownContainerStyle}
                listItemContainerStyle={{ height: scaledSize(42) }}
                flatListProps={{
                    initialNumToRender: 3
                }}
                listMode="FLATLIST"
                showTickIcon={false}
                placeholder='Business purpose'
                placeholderStyle={styles.businessPurposePlaceholder}
                textStyle={styles.businessPurposeValue}
            />
            <View style={styles.additionNotesView}>
                <Text style={styles.additionalNotesText}>Additional Notes</Text>
                <View style={[styles.additionTextInputView, state.additionalNotesFocus && { borderColor: "#3876F1"}]}>
                    {!state.additionalNotes && <View style={{ alignItems: "center", position: "absolute", top: scaledSize(20) }}>
                        <Text style={styles.optionalText}>(Optional)</Text>
                        <Text style={styles.optionalText}>Add details for miles driven today.</Text>
                    </View>}
                    <TextInput
                        value={state.additionalNotes}
                        style={styles.additionalNotesTextInput}
                        onChangeText={onChangeAdditionalNotes}
                        onFocus={onFocusAdditionalNotes}
                        onBlur={onBlurAdditionalNotes}
                        numberOfLines={4}
                        multiline
                    />
                </View>
            </View>
                <Button
                  theme={"secondary"}
                  text={"SAVE DETAILS"}
                  disabled={false}
                  textStyle={styles.saveEditBtnText}
                  style={styles.saveEditBtn}
                  testID=""
                  onPress={onSaveEdit}
                />
                <CustomModal
                  visible={state.openDatePicker}
                  onRequestClose={() => onOpenDatePicker(false)}
                >
                  <TouchableWithoutFeedback onPress={() => onOpenDatePicker(false)}>
                    <View style={styles.centeredCalendarView}>
                      <CustomCalendar
                        maxDate={moment(new Date()).format("YYYY-MM-DD")}
                        initialDate={moment(state.mileageDate).format("YYYY-MM-DD")}
                        markedDates={state.markedMileageDate}
                        onDayPress={day => onChangeMileageDate(day)}
                        renderArrow={direction => renderCalendarArrow(direction)}
                        monthFormat={'MMMM yyyy'}
                        onMonthChange={month => {
                          console.log('month changed from to date', month);
                        }}
                        onPressArrowLeft={subtractMonth => subtractMonth()}
                        onPressArrowRight={addMonth => addMonth()}
                        disableAllTouchEventsForDisabledDays={true}
                        enableSwipeMonths={true}
                        testID="testToCalendar"
                      />
                    </View>
                  </TouchableWithoutFeedback>
                </CustomModal>
        </View>
    );
  }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#ffffff"
    },
    datePickerView: {
        marginTop: scaledSize(10),
        height: scaledSize(45),
        width: scaledSize(277),
        paddingHorizontal: scaledSize(17),
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        flexDirection: "row",
    },
    dateTextButton: {
        height: scaledSize(28),
        width: scaledSize(218),
        alignItems: "center",
        justifyContent: "center",
    },
    dateText: {
        fontSize: scaledSize(21),
        lineHeight: scaledSize(28),
        textAlign: "center",
        color: "#3876F1",
        fontFamily: "OpenSans-Bold"
    },
    calendarIcon: {
        height: scaledSize(21),
        width: scaledSize(21),
    },
    seperatorLine: {
        marginTop: scaledSize(9),
        width: scaledSize(277),
        borderBottomColor: "#B3B3B3",
        borderBottomWidth: 1,
        alignSelf: "center",
    },
    milesView: {
        height: scaledSize(34),
        width: scaledSize(285),
        borderRadius: scaledSize(5),
        backgroundColor: "#FFF",
        alignSelf: "center",
        marginTop: scaledSize(10),
        paddingHorizontal: scaledSize(9),
        flexDirection: "row",
        alignItems: "center"
    },
    businessMilesTextView: {
        height: scaledSize(21),
        width: scaledSize(96),
    },
    businessMilesText: {
        fontSize: scaledSize(13),
        lineHeight: scaledSize(21),
        fontFamily: "OpenSans-Regular",
        color: "#999",
    },
    milesValue: {
        fontSize: scaledSize(14),
        color: "#3876F1",
        lineHeight: scaledSize(20),
        fontFamily: "OpenSans-Bold",
        width: scaledSize(129),
        textAlign: "right",
        paddingHorizontal: scaledSize(2),
        height: scaledSize(40),
    },
    dividerLine: {
        height: scaledSize(20),
        width: 1,
        backgroundColor: "#000",
    },
    mileText: {
        fontSize: scaledSize(14),
        lineHeight: scaledSize(21),
        fontFamily: "OpenSans-Bold",
        color: "#000",
        marginLeft: scaledSize(2)
    },
    dropDownStyle: {
        height: scaledSize(45),
        width: scaledSize(286),
        alignSelf: "center",
        borderColor: "#FFF",
        borderWidth: 0,
        borderBottomColor: "#E6E6E6",
        borderBottomWidth: 2,
        zIndex: 2000,
    },
    dropDownContainerStyle: {
        height: scaledSize(45),
        width: scaledSize(286),
        alignSelf: "center",
    },
    carsImage: {
        height: scaledSize(32),
        width: scaledSize(44),
        marginRight: scaledSize(10),
    },
    carValueText: {
        width: scaledSize(176),
        fontSize: scaledSize(12),
        lineHeight: scaledSize(19),
        fontFamily: "OpenSans-Regular",
        color: "#000"
    },
    downIcon: {
        height: scaledSize(14),
        width: scaledSize(14),
    },
    pickerStyle: {
        borderColor: "#999",
        borderWidth: 1,
    },
    businessPurposeDropdownStyle: {
        height: scaledSize(45),
        width: scaledSize(286),
        alignSelf: "center",
        borderColor: "#E6E6E6",
        borderWidth: 1,
        zIndex: 1000,
        marginTop: scaledSize(5)
    },
    businessPurposePlaceholder: {
        fontSize: scaledSize(12),
        lineHeight: scaledSize(19),
        color: "#999",
        fontFamily: "OpenSans-Regular",
    },
    businessPurposeValue: {
        fontSize: scaledSize(12),
        lineHeight: scaledSize(19),
        color: "#000000",
        fontFamily: "OpenSans-Regular",
    },
    additionNotesView: {
        width: scaledSize(285),
        marginTop: scaledSize(10),
        alignSelf: "center",
    },
    additionalNotesText: {
        fontSize: scaledSize(14),
        lineHeight: scaledSize(21),
        fontFamily: "OpenSans-Regular",
        color: "#0F172A",
    },
    additionTextInputView: {
        width: scaledSize(285),
        height: scaledSize(87),
        borderRadius: scaledSize(7),
        borderWidth: 1,
        borderColor: "#CBD5E1",
        justifyContent: "center",
        alignItems: "center",
        marginTop: scaledSize(5),
    },
    optionalText: {
        fontSize: scaledSize(14),
        lineHeight: scaledSize(21),
        fontFamily: "OpenSans-Regular",
        color: "#CCC",
    },
    additionalNotesTextInput: {
        width: scaledSize(285),
        height: scaledSize(87),
        fontSize: scaledSize(12),
        lineHeight: scaledSize(19),
        fontFamily: "OpenSans-Regular",
        color: "#0F172A",
    },
    saveEditBtnText: { 
      color: "#ffffffff", 
      fontSize: scaledSize(12), 
      lineHeight: scaledSize(19) 
    },
    saveEditBtn: {
      height: scaledSize(28),
      width: scaledSize(140),
      borderRadius: scaledSize(44),
      paddingHorizontal: 0,
      paddingVertical: 0,
      alignSelf: "center",
      marginTop: scaledSize(15)
    },
    centeredCalendarView: {
        width: "90%",
        alignSelf: 'center',
        marginTop: scaledSize(200),
        zIndex:-1,
        paddingBottom: scaledSize(16),
        position: 'relative',
        bottom: 0,
        backgroundColor: "white",
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 22,
        elevation: 5,
        paddingHorizontal: scaledSize(21),
        borderRadius: scaledSize(22)
      },
      twoCarsView: {
        height: scaledSize(80),
        width: scaledSize(288),
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "center",
        marginTop: scaledSize(5),
      },
      addCarsBtnView: {
        height: scaledSize(77),
        width: scaledSize(83),
        alignItems: "center",
        justifyContent: "center",
        borderRadius: scaledSize(4)
      },
      addCarImage: {
        height: scaledSize(48),
        width: scaledSize(48),
      },
      addCarText: {
        fontSize: scaledSize(10),
        lineHeight: scaledSize(16),
        color: "#0F172A",
        fontFamily: "OpenSans-Regular",
        width: scaledSize(75),
        textAlign: "center",
        textTransform: "uppercase",
      },
})

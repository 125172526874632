//@ts-nocheck
// Customizable Area Start
import * as React from "react";
import DashboardController from "./DashboardController.web";
import {
  AppBar,
  Typography,
  Button,
  withStyles,
  Grid,
  Box,
  Popover,
} from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import { ReactComponent as ChartIcon } from "../assets/chart-874.svg";
import { ReactComponent as TaxIcon } from "../assets/tax-file-9250.svg";
import { ReactComponent as TransactionIcon } from "../assets/transaction.svg";
import TreasureIcon from "../assets/thumbnail.png";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import Routes from "./Router";
import Logo from "../assets/Logo.png";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PublicIcon from "@material-ui/icons/Public";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import TreasureAccordian from "./treasureAccordian";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import NotificationContext from '../../../components/src/Context/NotificationContext'
//import   StyledCheckbox  from "./Checkbox"
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
const useStyles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
    position: "relative",
  },
  wText: {
    color: "#ffffff",
    fontWeight: "700",
    fontSize: "18px",
    '@media (max-width:1425px)': {
      fontSize: '13.5px',

    },
  },

  logo: {
    width: "100px",
    marginBottom: "25px",
  },
  navBar: {
    boxShadow: "none",
  },
  wIcon1: {
    fill: "#ffffff",
  },
  navImg1: {
    backgroundColor: "#ffffff",
    border: "1px solid black",
    width: "30px",
    margin: "0 15px",
    height: "30px",
    borderRadius: "50%",
    objectFit: "contain",
  },
  horizontalBar1: {
    padding: "10px 2px",
    backgroundImage: "linear-gradient(to right, #15066A , #5696F4)"
  },
  verticalBar1: {
    height: "100vh",
    backgroundColor: "#15066A",
    position: "sticky",
    zIndex: 1,
  },
  notificationNumber: {
    color: "#ffffff",
    fontSize: "11px",
    backgroundColor: "#15066A",
    width: "20px",
    height: "20px",
    borderRadius: "10px 0",
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  notification: {
    position: "relative",
    padding: "0 10px",
  },
  navLink: {
    display: "flex",
    width: "100%",
    textDecoration: "none",
    alignItems: "center",
    padding: "15px 0",
  },
  activeNavLink: {
    backgroundColor: "#5696F4",
  },
  iconStyle: {
    width: "20px",
    height: "20px",
    fill: "#ffffff",
    "& path": {
      fill: "#ffffff !important",
    },
  },
  leafs: {
    position: "absolute",
    bottom: "15px",
    left: "50%",
    transform: "translateX(-35%)",
  },
  iconLeaf: {
    border: ".5px solid #ffffff",
    borderRadius: "0 12px",
  },
  white: {
    backgroundColor: "#ffffff",
  },
  orange: {
    backgroundColor: "#FF9933",
  },
  profilePopupBox: {
    display: "flex",
    color: "#4e5053",
    margin: "5px 0px 5px 0px",
  },
  blueText: {
    color: "#15066A",
    fontWeight: "700",
    fontSize: "26px",
    '@media (max-width:1425px)': {
      fontSize: '19.5px',

    },

  },
  font26: {
    fontSize: "26px",
    '@media (max-width:1425px)': {
      fontSize: '19.5px',

    },
  },
  downWard: {
    margin: "11px 0 0 -2px",
    color: "white",
    '@media (max-width:1425px)': {
      margin: "6px 0 0 -2px",

    },

  },
  tbox: {
    fontSize: "25px",
    fontWeight: 700,
    "@media (max-width:1425px)": {
      fontSize: "18.75px"
    }
  },
  tboxlevel: {
    fontSize: '20px',
    fontWeight: '700',
    "@media (max-width:1425px)": {
      fontSize: "15px"
    }
  },
  spaceBetween: {
    gap: "10px",
  },
  navLinkMain: {
    textDecoration: "none",
    color: "#4e5053",
  },
  boldText: {
    fontSize: "15px",
    fontWeight: "700",
    color: "#3F4144",
  },
  notifText: {
    fontSize: "15px",
    fontWeight: 700,
    "@media (max-width:1425px)": {
      fontSize: "11.25px"
    }
  },
  viewText: {
    color: "#5696F4", textTransform: "none",
    fontSize: "17px",
    fontWeight: 700,
    "@media (max-width:1425px)": {
      fontSize: "12.75px"
    }
  },
  notifTextContainer: {
    fontSize: "15px",
    fontWeight: 700,
    "@media (max-width:1425px)": {
      fontSize: "11.25px"
    },
    borderBottom: "1px solid #000",
    marginBottom: "10px",
    paddingBottom: "10px",
  },
});



export class NavBar extends DashboardController {
  constructor(props) {
    super(props);
    if (!localStorage.getItem("token")) {
      window.location.href = "/EmailAccountRegistration";
    }
    this.state = {
      ...this.state,
      role: localStorage.getItem("role"),
      manage_account: localStorage.getItem("manage_account"),
      manage_company: localStorage.getItem("manage_company"),
      manage_subscription: localStorage.getItem("manage_subscription"),
    };
  }


  componentDidUpdate() {
    document.getElementById('scrollUp')?.scrollIntoView()
  }
  async componentDidMount() {
    this.updateLocalStorageData()
    this.getNotificationCount();
  }
  updateTaxYearN(value: any) {
    this.setState({
      taxYear: value,
      updatedTaxYear: true,
    }, () => { this.setState({ openTaxYear: false }) })
    StorageProvider.set(
      "taxYear",
      value
    )
    StorageProvider.set("taxYearChanged", true);
  }
  notificationCountDataGet() {
    return this.state.notificationCountData || 0
  }
  handleOpenTaxYear(e: any) {
    if (this.state.openTaxYear) {
      this.setState({ openTaxYear: false, anchorElTax: null });
    } else {
      this.setState({
        openTaxYear: true,
        anchorElTax: e.currentTarget,
      });
    }
  }

  handleOpenTreasure() {
    if (this.state.openTreasure) {
      this.setState({ openTreasure: false });
    } else {
      this.getTreasureTip2Data();
    }
  }
  removeLocalStorage = () => {
    localStorage.removeItem("token")
    localStorage.clear()
    this.setState({ open: false })
  }
  getTresureBoxDataComp = () => {
    const { classes } = this.props
    return (
      <>
        {this.state.treasureBoxData.map((levels) => {
          return (
            <div key={levels.level}>
              <Typography
                className={classes.tboxlevel} style={{
                  margin: "10px auto"
                }}>{levels.level}</Typography>
              {levels.tips.map((tip: any, id: any) => <TreasureAccordian key={"" + id + levels.level} level={levels.level} treasureBoxData={tip} updateData={(levelToUpdate, tipId) => {
                console.log(levelToUpdate, tipId);

                let data = this.state.treasureBoxData.map((item: any) => {
                  if (item.level == levelToUpdate) {
                    return Object.assign({}, item, {
                      tips: item?.tips.map(el => {
                        if (el.id == tipId) {
                          let attr = el.attributes;
                          attr.notification = null
                          return Object.assign({}, el, { attributes: attr })
                        }
                        return el
                      })
                    })
                  }
                  return item
                });

                let count = this.state.notificationCountData - 1

                this.setState({ treasureBoxData: data, notificationCountData: count })
              }}
              />)
              }
            </div>
          );
        })}
      </>
    );
  };

  taxYear() {
    return this.state.taxYear || this.state.currentYear
  }
  handleOpenNotification() {
    if (this.state.openNotificaton) {
      this.setState({ openNotificaton: false });
    } else {
      this.setState({ openNotificaton: true });
    }
  }
  handleOpenBox() {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }

  getCompanyName() {
    if (!this.state.companyName) {
      return 'Company Name'
    }
    return this.state.companyName
  }
  getCompanyEmail() {
    if (!this.state.companyEmail) return "Company Email"
    return this.state.companyEmail
  }
  async handlePushNotification(t: any, navTo: string) {
    await StorageProvider.set('push_notificable_type', t?.attributes?.push_notificable_type);
    this.setState({ idOfNotiToMarkRead: t.id })
    this.markNotificationViewed(t.id);
    this.setState({ allNotifications: this.state.allNotifications - 1 })
  }
  render() {
    const { classes } = this.props;

    return (
      <NotificationContext.Consumer>
        {(context) => (
          <Grid container className={classes.root}>
            {this.state.updateNotification && this.setState({ updateNotification: false }, context.getNotificationCountFun())}
            <Grid item xs={2} >
              <AppBar
                position="fixed"
                className={`${classes.navBar} ${classes.verticalBar1}`}
                style={{ minWidth: "200px", zIndex: "10" }}
              >
                <Grid container direction="column" alignItems="center">
                  <NavLink to="/dashboard">
                    <img src={Logo} className={classes.logo} />
                  </NavLink>
                  <NavLink
                    to="/Dashboard"
                    exact={true}
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                    style={{
                      marginTop: 15,

                    }}
                  >
                    <Grid container justifyContent="center" xs={3}>
                      <HomeOutlinedIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="subtitle1" className={classes.wText}>
                        Dashboard
                      </Typography>
                    </Grid>
                  </NavLink>
                  <NavLink
                    to="/Dashboard/transactions"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    <Grid container justifyContent="center" xs={3}>
                      <TransactionIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="subtitle1" className={classes.wText}>
                        Transactions
                      </Typography>
                    </Grid>
                  </NavLink>
                  <NavLink
                    to="/Dashboard/taxes"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    <Grid container justifyContent="center" xs={3}>
                      <TaxIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="subtitle1" className={classes.wText}>
                        Taxes
                      </Typography>
                    </Grid>
                  </NavLink>
                  <NavLink
                    to="/Dashboard/reports"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    <Grid container justifyContent="center" xs={3}>
                      <ChartIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="subtitle1" className={classes.wText}>
                        Reports
                      </Typography>
                    </Grid>
                  </NavLink>
                  <NavLink
                    to="/Dashboard/mileage"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    <Grid container justifyContent="center" xs={3}>
                      <DriveEtaOutlinedIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="subtitle1" className={classes.wText}>
                        Mileage
                      </Typography>
                    </Grid>
                  </NavLink>
                  <NavLink
                    to="/Dashboard/forms"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    <Grid container justifyContent="center" xs={3}>
                      <NoteOutlinedIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="subtitle1" className={classes.wText}>
                        Forms
                      </Typography>
                    </Grid>
                  </NavLink>
                  <NavLink
                    to="/Dashboard/w1"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    <Grid container justifyContent="center" xs={3}>
                      <Grid
                        item
                        className={`${classes.iconStyle} ${classes.iconLeaf}`}
                      />
                    </Grid>
                    <Grid xs={9}>
                      <Typography variant="subtitle1" className={classes.wText}>
                        W1
                      </Typography>
                    </Grid>
                  </NavLink>
                </Grid>
              </AppBar>
            </Grid>
            <Grid item xs={10} id="scrollUp">
              <AppBar
                position="static"
                className={`${classes.navBar} ${classes.horizontalBar1}`}
                style={{ position: "sticky", top: "0" }}
              >
                <Grid
                  container
                  className={classes.spaceBetween}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box
                    onClick={(e) => this.handleOpenTaxYear(e)}
                    style={{ display: "flex" }}
                  >
                    <Typography variant="subtitle1" className={classes.blueText} style={{ paddingRight: "10", cursor: "pointer" }}>
                      Tax Year
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={`${classes.wText} ${classes.font26}`}
                      style={{ fontWeight: "400", cursor: "pointer" }}
                    >
                      {this.taxYear()}
                    </Typography>
                    <KeyboardArrowDownIcon

                      className={classes.downWard}
                    />
                  </Box>
                  <Grid className={classes.notification}>
                    {/* <InboxOutlinedIcon
                      style={{
                        height: "37",
                        width: "31px",
                      }}
                      className={classes.wIcon1}
                      onClick={() => this.handleOpenTreasure()}
                    /> */}
                    <img onClick={() => this.handleOpenTreasure()}
                      src={TreasureIcon} style={{ height: "28px", width: "35px", fill: '#fff' }} />
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.notificationNumber}
                    >
                      {this.notificationCountDataGet()}
                    </Grid>
                    <Popover
                      open={this.state.openTreasure}
                      onClose={() => {
                        this.setState({ openTreasure: false });
                      }}
                      key={this.state.randomKey}
                      //anchorReference="anchorPosition"
                      //anchorPosition={{ top: 50, left: 3800 }}
                      anchorOrigin={{
                        vertical: 50,
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        height: "100%",
                        // '&.MuiPaper-rounded': {
                        //   borderRadius: "0"
                        // }
                      }}
                      PaperProps={{
                        square: true,
                        style: {
                          height: "100%",
                          backgroundColor: "#f3f3f3",
                          border: "1px solid",
                        },
                      }}
                    >
                      {" "}
                      <Box style={{ padding: "1px", borderRadius: 0 }}>
                        <Box>
                          <Box
                            style={{
                              // height: "80px",
                              fontFamily: "Basic Sans",
                              fontSize: "25px",
                              fontWeight: 700,
                              marginTop: "50px",
                            }}
                          >
                            <Typography
                              className={classes.tbox}
                            >
                              Treasure Box
                            </Typography>
                          </Box>
                          <CloseIcon
                            style={{
                              position: "relative",
                              top: "-75px",
                              left: "110px",
                            }}
                            onClick={() => {
                              this.setState({ openTreasure: false });
                            }}
                          />
                          <Box style={{ width: "300px", height: "220px" }}>

                            {this.getTresureBoxDataComp()}
                          </Box>
                        </Box>
                      </Box>
                    </Popover>
                  </Grid>
                  <Grid className={classes.notification}>
                    <NotificationsOutlinedIcon
                      className={classes.wIcon1}
                      style={{
                        height: "38px",
                        width: "38px",
                      }}
                      onClick={() => this.handleOpenNotification()}
                    />
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.notificationNumber}
                    >
                      {this.state.allNotifications}


                    </Grid>
                    <Popover
                      open={this.state.openNotificaton}
                      onClose={() => {
                        this.setState({ openNotificaton: false });
                      }}
                      //anchorReference="anchorPosition"
                      //anchorPosition={{ top: 50, left: 3800 }}
                      anchorOrigin={{
                        vertical: 50,
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        // '&.MuiPaper-rounded': {
                        //   borderRadius: "0"
                        // }
                      }}
                      PaperProps={{
                        square: true,
                        style: { backgroundColor: "#f3f3f3", border: "1px solid" },
                      }}
                    >
                      {" "}
                      <Grid style={{ padding: "1px", borderRadius: 0 }}>
                        <Grid>
                          <Grid container style={{
                            flexDirection: "row", alignItems: 'center',
                            justifyContent: "center",
                            paddingBottom: "10px",
                            borderBottom: "1px solid rgb(0, 0, 0)",
                          }} >

                            <Grid item style={{ height: "80px", maxWidth: "85%", paddingBottom: "10px" }}>
                              <NotificationsNoneRoundedIcon
                                style={{
                                  height: "37px",
                                  width: "37px",
                                  marginTop: "20px",
                                  borderRadius: 30,
                                  padding: 2,
                                  backgroundColor: "#5696f4",
                                  color: "white"
                                }}
                              />
                              <Typography
                                className={classes.notifText}
                              >
                                Notifications
                              </Typography>
                            </Grid>
                            <CloseIcon
                              style={{
                                right: "15px",
                                position: "absolute",
                                // position: "relative",
                                // top: "-55px",
                                // left: "110px",
                              }}
                              onClick={() => {
                                this.setState({ openNotificaton: false });
                              }}
                            />
                          </Grid>

                          <Box style={{ width: "300px", height: "220px" }}>
                            {/* <Accordian data={this.state.notificationData} /> */}
                            {this.state.notificationData.map((t: any, i: any) => {

                              let navTo = "/Dashboard"
                              switch (t?.attributes?.push_notificable_type) {
                                case "BxBlockTransactions::Income":
                                  navTo = "/Dashboard/transactions?income"
                                  break;
                                case "BxBlockTransactions::Expense":
                                  navTo = "/Dashboard/transactions?expense"
                                  break;
                                case "BxBlockCompanySettings::Company":
                                  navTo = "/Dashboard/companysettings"
                                  break;
                                case "AccountBlock::EmailAccount":
                                  navTo = "/Dashboard/mileage"
                                  break;
                              }


                              return <Grid key={t.id} container style={{ flexDirection: "row", alignItems: 'center', paddingTop: i == 0 ? "10px" : 0 }} className={classes.notifTextContainer}>

                                <Typography
                                  className={classes.notifText} style={{ width: "80%", fontWeight: t?.attributes?.is_read ? 400 : 700 }}>
                                  {t?.attributes?.remarks} <Typography >{t?.attributes?.date_of_notification.toLocaleString()}</Typography></Typography>
                                <Typography
                                  component={Link}
                                  to={navTo}
                                  onClick={async () => this.handlePushNotification(t, navTo)}
                                  style={{ textDecoration: 'none', display: 'inline', cursor: 'pointer' }}

                                >
                                  <Typography className={classes.viewText}>
                                    View
                                  </Typography>
                                </Typography>

                              </Grid>
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Popover>
                  </Grid>

                  <img
                    className={classes.navImg1}
                    src={this.state.companyLogo}
                    onClick={() => this.handleOpenBox()}
                    style={{ marginRight: "45px" }}
                  />
                  <Popover
                    open={this.state.open}
                    onClose={() => {
                      this.setState({ open: false });
                    }}
                    //anchorReference="anchorPosition"
                    //anchorPosition={{ top: 50, left: 3800 }}
                    anchorOrigin={{
                      vertical: 65,
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      // '&.MuiPaper-rounded': {
                      //   borderRadius: "0"
                      // }
                    }}
                    PaperProps={{
                      square: true,
                    }}
                  >
                    <Box
                      style={{
                        padding: "20px 30px 40px 30px",
                        backgroundColor: "#f3f3f3",
                        border: "1px solid",
                        borderRadius: 0,
                      }}
                    >
                      <Grid container alignItems="center" justifyContent="center">
                        <Grid container alignItems="center" justifyContent="center"
                          style={{
                            height: "35px",
                            width: "35px",
                            borderRadius: "50%",
                            backgroundColor: "#ffffff",
                            marginBottom: "10px",
                            border: "1px solid",
                          }}
                        >
                          <img
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "50%",
                              objectFit: "contain",
                            }}
                            src={this.state.companyLogo}
                          />
                        </Grid>
                        <CloseIcon
                          style={{
                            position: "relative",
                            top: "-25px",
                            left: "80px",
                          }}
                          onClick={() => {
                            this.setState({ open: false });
                          }}
                        />
                      </Grid>
                      <Typography className={classes.boldText}>
                        {this.getCompanyName()}
                      </Typography>
                      <Typography
                        className={classes.boldText}
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        {this.getCompanyEmail()}
                      </Typography>

                      <Box>
                        <Box className={classes.profilePopupBox}>
                          {
                            (
                              ((this.state.manage_account == "yes" || this.state.manage_account == "view_only") ||
                                (this.state.manage_company) ||
                                (this.state.manage_subscription == "read_only'" || this.state.manage_subscription == "allow"))
                              ||
                              this.state.role == "SUPER_ADMIN") &&
                            <>
                              <SettingsOutlinedIcon style={{ marginRight: "10px" }} />
                              <NavLink
                                to="/Dashboard/companysettings"
                                className={classes.navLinkMain}
                                onClick={() => {
                                  this.setState({ open: false });
                                }}
                              >
                                <Typography className={classes.boldText}>
                                  Company Settings
                                </Typography>
                              </NavLink>
                            </>
                          }
                        </Box>
                        {this.state.role == "SUPER_ADMIN" &&
                          (
                            <NavLink
                              to="/Dashboard/globalsettings"
                              style={{
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                this.setState({ open: false });
                              }}
                            // className={classes.navLink}
                            // activeClassName={classes.activeNavLink}
                            >
                              <Box className={classes.profilePopupBox}>
                                <PublicIcon style={{ marginRight: "10px" }} />
                                {/* <img src={worldWide} alt="" style={{ height: "20px", width: "20px" }} /> */}
                                <Typography className={classes.boldText}>
                                  Global settings
                                </Typography>
                              </Box>
                            </NavLink>
                          )}

                        {/* <Box className={classes.profilePopupBox}>
                      <NotificationsNoneIcon style={{ marginRight: "10px" }} />
                      <img src={notification} alt="" style={{ height: "20px", width: "20px" }} />
                      <Typography>Notifications</Typography>
                    </Box> */}
                      </Box>

                      <Button
                        component={Link}
                        to="/"
                        onClick={() => {
                          this.removeLocalStorage()
                        }}
                        className={classes.boldText}
                        style={{
                          textTransform: "none",
                          marginTop: "30px",
                          marginBottom: "-30px",
                        }}
                      >
                        Sign Out
                      </Button>
                    </Box>
                  </Popover>
                  <Popover
                    open={this.state.openTaxYear}
                    onClose={() => {
                      this.setState({ openTaxYear: false });
                    }}
                    //anchorReference='anchorPosition'
                    //anchorPosition={{ top: 700, left: 1000 }}
                    // vertical: (window.innerHeight / 2),
                    //   horizontal: (window.innerWidth / 2.4)
                    anchorEl={this.state.anchorElTax}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    {/* color: "#363636" */}

                    <Box style={{ width: "100%", padding: "10px 15px" }}>

                      <Box style={{ width: "100%" }}>
                        {this.state.taxYear === this.state.currentYear - 2 ? (
                          <Typography
                            style={{ color: "#5696F4", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => {
                              this.updateTaxYearN(this.state.currentYear - 2)
                            }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              {this.state.currentYear - 2}
                            </span>{" "}
                            Tax Year
                          </Typography>
                        ) : (
                          <Typography
                            style={{ color: "#3F4144", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => {
                              this.updateTaxYearN(this.state.currentYear - 2)
                            }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              {this.state.currentYear - 2}
                            </span>{" "}
                            Tax Year
                          </Typography>
                        )}
                        {this.state.taxYear === this.state.currentYear - 1 ? (
                          <Typography
                            style={{ color: "#5696F4", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => {
                              this.updateTaxYearN(this.state.currentYear - 1)
                            }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              {this.state.currentYear - 1}
                            </span>{" "}
                            Tax Year
                          </Typography>
                        ) : (
                          <Typography
                            style={{ color: "#3F4144", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => {
                              this.updateTaxYearN(this.state.currentYear - 1)
                            }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              {this.state.currentYear - 1}
                            </span>{" "}
                            Tax Year
                          </Typography>
                        )}
                        {this.state.taxYear ? (
                          <>
                            {this.state.taxYear === this.state.currentYear ? (
                              <Typography
                                style={{ color: "#5696F4", fontSize: "20px", cursor: "pointer" }}
                                onClick={() => {
                                  this.updateTaxYearN(this.state.currentYear)
                                }}
                              >
                                <span style={{ fontWeight: "700" }}>
                                  {this.state.currentYear}
                                </span>{" "}
                                Tax Year
                              </Typography>
                            ) : (
                              <Typography
                                style={{ color: "#3F4144", fontSize: "20px", cursor: "pointer" }}
                                onClick={() => {
                                  this.updateTaxYearN(this.state.currentYear)
                                }}
                              >
                                <span style={{ fontWeight: "700" }}>
                                  {this.state.currentYear}
                                </span>{" "}
                                Tax Year
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Typography
                            style={{ color: "#5696F4", fontSize: "20px", cursor: "pointer" }}
                            onClick={() => {
                              this.updateTaxYearN(this.state.currentYear)
                            }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              {this.state.currentYear}
                            </span>{" "}
                            Tax Year
                          </Typography>
                        )}

                        {/* <Typography style={{}} onClick={() => { this.setState({ taxYear: this.state.currentYear }) }} >{this.state.currentYear - 0} Tax Year</Typography> */}
                      </Box>
                    </Box>
                  </Popover>
                </Grid>
              </AppBar>
              <Grid item xs={12}>
                {this.state.updatedTaxYear ? (
                  <>
                    <Routes updateLocalStorageData={this.updateLocalStorageData} taxYear={this.state.taxYear} updatedTaxYear={true} />
                    {this.setState({ updatedTaxYear: false })}
                  </>
                ) : (
                  <Routes updateLocalStorageData={this.updateLocalStorageData} taxYear={this.state.taxYear} updatedTaxYear={false} />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </NotificationContext.Consumer>
    );
  }
}
// Customizable Area End
export default withStyles(useStyles)(NavBar);
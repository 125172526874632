// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  InputBase,
  Divider
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import moment from 'moment'
import VerifyAccountController from './VerifyAccountController.web';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {ReactComponent as ChevronLeftSvg} from '../assets/chevron-left.svg';
import BillAndSubSettingWeb from "./BillAndSubSetting.web";



const useStyles = (theme) => ({

  emailText:{
    fontSize:29,
    fontWeight:700,
    color:"#3F4144",
    margin:"10px 0px",
    '@media (max-width:1425px)':{
      fontSize:21.75
        }
  },
  text:{
    fontSize:14,
    fontWeight:400,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:10.5
        }
  },
  alignment:{
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    display:"flex"
  },
  headerText:{
    fontSize:14,
    fontWeight:600,
    color:"#3F4144",
    textAlign:"center",
    '@media (max-width:1425px)':{
      fontSize:10.5
        }
  },
  paddingLeft:{
    padding:"20px 10px 20px 0px" 
  },
  desText:{
    fontSize:13,
    fontWeight:400,
    color:"#3F4144",
    '@media (max-width:1425px)':{
      fontSize:9.75
        }
  },
 
  border:{
    borderTop:"1px solid #979797",
    borderBottom:"1px solid #979797",
    margin:"30px 0px "
  },
 
  padding:{
    paddingTop:30,
  },
  link:{
    color:"#5696F4",
    fontSize:18,
    fontWeight:400,
    marginTop:10,
    '@media (max-width:1425px)':{
      fontSize:13.5
        }
  },


  
})
class AddPaymentDetailsWeb extends VerifyAccountController{
  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }
  render() {
    function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
        return { name, calories, fat, carbs, protein };
      }
    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
      ];
    const {classes} = this.props
    return (
        <Grid item container xs={12} lg={12} md={12}sm={12} justifyContent="center" alignItems="center" >
            <Grid item container xs={12} lg={12} md={12}sm={12}>
                <Grid item xs={2} lg={1} md={2}sm={2} className={classes.alignment}>
                <Link to="/Dashboard/companysettings/billPaymentHistory" component={BillAndSubSettingWeb}style={{textDecoration:"none", }}>

                    <ChevronLeftSvg style={{marginTop:10, width:10,height:10}}/>
                  </Link>
                </Grid>
                <Grid item xs={8} lg={8} md={8}sm={8}>
                    <Link to="/Dashboard/companysettings/billPaymentHistory" component={BillAndSubSettingWeb}style={{textDecoration:"none", }}>
                        <Typography className={classes.link}>
                        Billing & Subscription
                        </Typography>
                    </Link>

                </Grid>

            </Grid>
           <Grid item container lg={12} md={12} sm={12} xs={12} className={classes.padding} className={classes.alignment}>
               
                <Grid item xs={12} lg={12}  className={classes.alignment}>
                    <Typography className={classes.emailText}>
                 Billing details
                    </Typography>
                </Grid>
               <Grid item xs={12} lg={10}  className={classes.alignment} className={classes.padding}>
               <TableContainer >
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell className={classes.headerText}>Date</TableCell>
                            <TableCell className={classes.headerText} >Description</TableCell>
                            <TableCell className={classes.headerText}>Service Period</TableCell>
                            <TableCell className={classes.headerText}>Payment Method</TableCell>
                            <TableCell className={classes.headerText}>Subtotal</TableCell>
                            <TableCell className={classes.headerText}>Total</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                         
                        {this.state.allBillingDetails.map((row) => (
                            <TableRow >
                            <TableCell component="th" scope="row" className={classes.text} style={{height:20}}>
                              {this.state.allBillingDetails?moment(row?.attributes?.created_at).format('MM-DD-YYYY'): ""}
                            </TableCell>
                            <TableCell className={classes.text} style={{height:20}}>{this.state.allBillingDetails? row?.attributes?.plan?.data?.attributes?.details:""}</TableCell>
                            <TableCell className={classes.text}style={{height:20}} >{this.state.allBillingDetails? row?.attributes?.plan?.data?.attributes?.duration:""}</TableCell>
                            <TableCell className={classes.text} style={{height:20}}>{this.state.allBillingDetails? row?.attributes?.payment_method:""}</TableCell>
                            <TableCell className={classes.text} style={{height:20}}>{this.state.allBillingDetails? row?.attributes?.plan?.data?.attributes?.price:""}</TableCell>
                            <TableCell className={classes.text} style={{height:20}}>{this.state.allBillingDetails? row?.attributes?.plan?.data?.attributes?.total:""}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                       
                    </Table>
                </TableContainer>

               </Grid>
               
              
           </Grid>
        </Grid>
    );
  }
}

export default withStyles(useStyles)(AddPaymentDetailsWeb)
// Customizable Area End
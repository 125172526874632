import React, { Component } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import AntDesign from "react-native-vector-icons/AntDesign";
import { deviceWidth, scaledSize } from "framework/src/Utilities";
import Scale from './Scale';

interface AvatarRequiredProps {
    leftColor: string,
    rigthColor: string,
    icon: string,
    iconColor: string,
    msg: string
}

export default class CustomToastAlert extends Component<AvatarRequiredProps> {

    render() {
        const { leftColor, rigthColor, icon, iconColor, msg } = this.props;
        return (
            <View style={{
                height: scaledSize(65),
                width: scaledSize(302),
                backgroundColor: rigthColor,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: scaledSize(17),
                paddingVertical: scaledSize(13),
                borderLeftWidth: scaledSize(3),
                borderLeftColor: leftColor,
            }}>
                <Image source={icon === 'success' ? require('./successIcon.png') : require('./alertIcon.png')} style={{ height: scaledSize(29), width: scaledSize(32) }} />
                <Text style={{ width: scaledSize(210), fontSize: scaledSize(13), fontFamily: "OpenSans-Bold", fontWeight: "600", color: iconColor }} numberOfLines={2}>{msg}</Text>
            </View>
        );
    }
};

const styles = StyleSheet.create({
    body: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: deviceWidth - scaledSize(20),
        paddingLeft: scaledSize(10),
        borderRadius: scaledSize(8),
        backgroundColor: "#b6f8c4",
        height: Scale(42)
    },
    text: {
        flex: 1,
        marginHorizontal: scaledSize(10)
    }
});
//@ts-nocheck
import React from "react";
import {
    // Customizable Area Start
    withStyles,
    Checkbox,
    TextField,
    Typography,
    InputAdornment,
    Grid,
    Button,
    Dialog,
    Box,
    DialogContent,
    Card,
    CardActions,
    CardContent,
    Snackbar
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import user from "../assets/user.png";
import DoneIcon from '@material-ui/icons/Done';
import { Link, Redirect } from "react-router-dom";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import TopNav from "../../../components/src/TopNav";
import signin from "../assets/signIn.png";
import subscription from "../assets/subscription.png";
import StorageProvider from "../../../framework/src/StorageProvider.web";
// Customizable Area End

// Customizable Area Start
const useStyles = (theme) => ({
    root: {
        paddingTop: "60px",
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
    },
    leafTL: {
        width: "176.9px",
        height: "176.89px",
        borderRadius: "0 60px",
        margin: "5px 0",
    },
    leafTR: {
        width: "176.9px",
        height: "176.89px",
        borderRadius: "60px 0",
        margin: "5px 0",
    },
    orange: {
        backgroundColor: "#FF9A30",
    },
    lBlue: {
        backgroundColor: "#B6D9FD",
    },
    blue: {
        backgroundColor: "#5597F4",
    },
    dBlue: {
        backgroundColor: "#0D016A",
    },
    pageTitle: {
        fontWeight: "700",
        fontSize: "40px",
        display: "inline-block",
        position: "relative",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "70%",
            height: "5px",
            bottom: "0",
            left: "15%",
            borderBottom: "2px solid #B5D8FD",
        },
    },
    underLine: {
        fontWeight: "bold",
        position: "relative",
        paddingBottom: "3px",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "50%",
            height: "5px",
            bottom: "0",
            left: "25%",
            borderBottom: "0.5px solid #3F4144",
        },
    },
    leafGrid: {
        gap: "10px",
    },
    normalUrl: {
        color: "#7BAEF7",
        textDecoration: "none",
    },
    orangeText: {
        fontSize: "30px",
        color: "#FF9B31",
        fontWeight: '800'
    },
    form: {
        gap: "20px",
        zIndex: 1,
        maxWidth: "28%"
    },
    textBtn: {
        padding: "0 5px",
        fontWeight: "800",
        fontSize: "30px",
        cursor: "pointer",
    },
    formBtn: {
        textTransform: "none",
        backgroundColor: "#5597F4",
        borderRadius: "30px",
        width: "40%",
        "&:hover": {
            backgroundColor: "#5597F4",
        },
        "& p": {
            fontWeight: "bold",
            color: "#ffffff"
        },
    },
    formBtnFont: {
        fontSize: "20px",
        fontWeight: "700",
        '@media (max-width:1425px)': {
            fontSize: "15px",
        },
    },
    cardTitleGray: {
        fontSize: "31px",
        fontWeight: "800",
        color: "#A8AAAE",
        '@media (max-width:1425px)': {
            fontSize: "23.25px"

        },
    },
    cardTitleBlue: {
        fontSize: "36px",
        fontWeight: "700",
        color: "#5696F4",
        '@media (max-width:1425px)': {
            fontSize: "27px"

        },
    },
    cardTitleBlueSelected: {
        fontSize: "51px",
        fontWeight: "800",
        color: "#5696F4",
        lineHeight: "1",
        '@media (max-width:1425px)': {
            fontSize: "38.25px"

        },
    },
    cardRate: {
        fontSize: "45px",
        fontWeight: "600",
        color: "#000000",
        '@media (max-width:1425px)': {
            fontSize: "33.75px"

        },
    },
    cardRateText: {
        fontSize: "34px",
        fontWeight: "400",
        color: "#000000",
        '@media (max-width:1425px)': {
            fontSize: "25.5px"

        },
    },
    cardRateTextGrey: {
        fontSize: "18px",
        fontWeight: "400",
        color: "#AEAEAE",
        '@media (max-width:1425px)': {
            fontSize: "13.5px"

        },
    },
    cardBodyText: {
        fontSize: "17px",
        fontWeight: "600",
        color: "#000000",
        '@media (max-width:1425px)': {
            fontSize: "12.75px"

        },
    },


    border: {
        border: "3px solid #5696F4"
    },
    // formBtn: {
    //     textTransform: "none",
    //     backgroundColor: "#5597F4",
    //     borderRadius: "30px",
    //     width: "50%",
    //     "&:hover": {
    //         backgroundColor: "#5597F4",
    //     },
    //     "& p": {
    //         fontWeight: "bold",
    //         color: "#ffffff",
    //         fontSize: "13px"
    //     },
    // },
    checkBox: {
        padding: 5
    },
    textField: {
        "& input": {
            fontSize: "20px",
        }
    },
    iconClass: {
        fill: "#D4D4D4"
    },
    font20px: {
        fontSize: "20px",
        fontWeight: "700"
    },
    font25px: {
        fontSize: "25px"
    },
    '@media (max-width:1425px)': {
        font20px: {
            fontSize: "15px"
        },
        font25px: {
            fontSize: "17.5px"
        },
        orangeText: {
            fontSize: "22.5px"
        },
        pageTitle: {
            fontSize: "30px"
        },
        textField: {
            "& input": {
                fontSize: "15px",
            }
        },
        textBtn: {
            fontSize: "22.5px"
        }
    },
    '@media (max-width:1250px)': {
        font20px: {
            fontSize: "11.25px"
        },
        font25px: {
            fontSize: "13px"
        },
        orangeText: {
            fontSize: "16"
        },
        pageTitle: {
            fontSize: "22.5px"
        },
        textField: {
            "& input": {
                fontSize: "11.25px",
            }
        },
        textBtn: {
            fontSize: "16px"
        },
        checkBox: {
            "& svg": {
                width: "15px",
                height: "15px"
            }
        },
        formBtn: {
            width: "40%"
        },
        iconClass: {
            width: "20px"
        },
        flexFor1250: {
            maxWidth: "100%",
            justifyContent: "center",
            flexBasis: "100%"
        }
    },
    dialogBody: {
        fontSize: "30px",
        fontWeight: "700",
        marginBottom: "30px",
        '@media (max-width:1425px)': {
            fontSize: "22.5px",
        },

    },
});
// Customizable Area End
export class RedirectPage extends EmailAccountRegistrationController {
    // Customizable Area Start
    constructor(props: any) {
        super(props);
        this.state = {
            loginPage: true,
            ...this.state,
        };
        // this.setState({ redirectPage: true })
        //this.setState({ tokenUrl: new URLSearchParams(location.search)?.get('token') })
    }
    componentDidMount() {
        this.setState({ newAccountToken: new URLSearchParams(location.search)?.get('token')})
        localStorage.setItem("endTime", new URLSearchParams(location.search)?.get('end_time'));
        this.getAllPackages(1, new URLSearchParams(location.search)?.get('token'));
            this.validateNewAccount(1, new URLSearchParams(location.search)?.get('token'));
            this.setState({ callApi: false });

        //console.log("helloo2", StorageProvider.get("token"))
    }

    goToDashboard() {
        this.props.history.push('/dashboard');
    }
    updatePage = () => {
        this.setState({ loginPage: !this.state.loginPage, userName: "", email: "", password: "", reTypePassword: "", phone: "" })
    }
    updateValue = (name, value) => {
        this.setState({ [name]: value });
    };
    runTheApi() {
            this.setState({ triggerNewRegistration: false })
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Grid
                container
                justifyContent="center"
                alignItems="flex-start"
                className={classes.root}
            >
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.errorVerification}
                    onClose={() => {
                        this.setState({
                            errorVerification: false
                        });
                    }}
                    autoHideDuration={3000}
                >
                    <Alert severity="error"
                        elevation={6} variant="filled"
                    >{this.state.errorVerificationMessage}</Alert>
                </Snackbar>
                {/* <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.errorMessage1}
            onClose={() => {
              this.setState({
                errorMessage1: false
              });
            }}
            autoHideDuration={3000}
          >
            <Alert severity="error"
              elevation={6} variant="filled"
            >Account not activated.</Alert>
          </Snackbar> */}
                {/* {this.state.errorVerification ? console.log("bull", this.state.errorVerificationMessage) : <></>} */}
                {this.state.triggerNewRegistration ? this.runTheApi() : <></>}
                {/* {this.state.callApi ? <>{this.getAllPackages(1), this.validateNewAccount(1), this.setState({ callApi: false })}</> : <></>} */}
                {this.state.activated == true ?
                    <>
                        {/* {this.setState({})} */}
                        <img src={signin} alt="" style={{ height: "100%", width: "100%" }} />
                        <Dialog open={this.state.openRedirectModal} fullWidth
                            style={{ textAlign: "center" }}


                        >
                            {this.state.openRedirectModal ? <></> : <Redirect to={'/'} />}


                            <DialogContent>
                                <Typography className={classes.dialogBody}>{this.state.message}</Typography>

                                <Button className={classes.formBtn} style={{ margin: "30 0" }} onClick={() => {
                                    this.setState({ openRedirectModal: false })
                                    //this.setState({ paymentConfirm: true })
                                    //console.log("dekhoooo321", this.state.newAccountToken)
                                    localStorage.setItem("tempToken", this.state.newAccountToken);
                                    //StorageProvider.set("token", this.state.newAccountToken)
                                }}><Typography className={classes.formBtnFont}>Ok</Typography></Button>


                            </DialogContent>

                        </Dialog>

                    </>
                    :
                    <>
                        <Dialog open={this.state.openRedirectModal} fullWidth
                            style={{ textAlign: "center" }}


                        >
                            {this.state.openRedirectModal ? <></> : <Redirect to={'/signupsubscriptionplan'} />}


                            <DialogContent>
                                <Typography className={classes.dialogBody}>{this.state.message}</Typography>

                                <Button className={classes.formBtn} style={{ margin: "30 0" }} onClick={() => {
                                    this.setState({ openRedirectModal: false })
                                    //this.setState({ paymentConfirm: true })
                                    //console.log("dekhoooo321", this.state.newAccountToken)
                                    localStorage.setItem("tempToken", this.state.newAccountToken);
                                    //StorageProvider.set("token", this.state.newAccountToken)
                                }}><Typography className={classes.formBtnFont}>Ok</Typography></Button>


                            </DialogContent>

                        </Dialog>

                        <Grid
                            item
                            container
                            direction="column"
                            xs={12}
                            alignItems="center"
                            className={classes.form}
                            style={{ maxWidth: "100%" }}
                        >
                            <Grid>
                                <img src={user} style={{ height: "59", width: "56.05" }} />
                            </Grid>
                            <Grid>
                                <Typography variant="h6">
                                    STEP 2 OF 3

                                </Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">

                                <Typography variant="h5" className={classes.pageTitle}>
                                    Choose your subscription plan.
                                </Typography>

                            </Grid>

                            <Grid item container xs={12} justifyContent="center" style={{
                                background: "linear-gradient(to bottom, #EFEFEF 0%, #EFEFEF 40%, #EFEFEF 90%,#ffffff 90%, #ffffff 100%)",
                                maxWidth: "100%",
                                marginTop: "30px",
                                padding: "40 0 0 0"
                            }} alignItems="flex-end">

                                <Grid item xs>
                                    <Card style={{ cursor: "pointer", textAlign: "center", width: "40%", height: "auto", marginLeft: "52%", borderRadius: "15px" }} className={this.state.subscriptionPlan == 1 ? classes.border : <></>} onClick={() => { this.setState({ selected: 1, subscriptionPlan: 1 }) }}>
                                        <CardContent>
                                            <Typography className={classes.cardTitleGray} style={{ margin: "5 0 10 0" }}>{this.state.packageType1}</Typography>
                                            <Typography className={classes.cardTitleBlue}>{this.state.packageName1} </Typography>
                                            <Typography className={classes.cardRate}>${this.state.packageRate1}<span className={classes.cardRateText}>/mo</span></Typography>
                                            <Typography className={classes.cardRateTextGrey}>+ applicable taxes</Typography>
                                            <Typography style={{ margin: "30 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /> <span className={classes.cardBodyText}>Cancel anytime</span></Typography>

                                        </CardContent>
                                        <CardActions style={{ margin: "0 0 50 0" }}>
                                            <Button onClick={() => { this.setState({ subscriptionPlan: 1, selected: 1 }) }} className={classes.formBtn} style={{ margin: "auto" }}><Typography>CHOOSE PLAN</Typography></Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs>

                                    <Card style={{ cursor: "pointer", textAlign: "center", width: "40%", height: "auto", borderRadius: "15px", marginLeft: "3%" }} className={this.state.subscriptionPlan == 2 ? classes.border : <></>} onClick={() => { this.setState({ selected: 2, subscriptionPlan: 2 }) }}>
                                        <CardContent>
                                            <Typography style={{ margin: "10 0 30 0" }} className={classes.cardTitleGray}>{this.state.packageType2}</Typography>
                                            <Typography className={classes.cardTitleBlueSelected}>{this.state.packageName2}!</Typography>
                                            <Typography className={classes.cardRate}>${this.state.packageRate2}<span className={classes.cardRateText}>/year</span></Typography>
                                            <Typography className={classes.cardRateTextGrey}>+ applicable taxes</Typography>
                                            <Typography style={{ margin: "30 0 10 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /><span className={classes.cardBodyText}> Save money</span></Typography>
                                            <Typography style={{ margin: "10 0 30 0" }}><DoneIcon style={{ color: "#5696F4", marginBottom: "-5" }} /><span className={classes.cardBodyText}> One time payment</span></Typography>
                                        </CardContent>
                                        <CardActions style={{ margin: "0 0 50 0" }}>
                                            <Button className={classes.formBtn} style={{ margin: "auto" }} onClick={() => { this.setState({ subscriptionPlan: 2, selected: 2 }) }}><Typography>CHOOSE PLAN</Typography></Button>
                                        </CardActions>
                                    </Card>
                                </Grid>




                            </Grid>


                            {/* {this.state.goToDashboard && this.state.loginSuccess ? <Redirect to={'/dashboard'} /> : <></>} */}
                            {/* {this.state.goToChangePassword && this.state.loginSuccess ? <Redirect to={'/changepassword'} /> : <></>} */}


                            {this.state.goToInitialPayment ? <Redirect to={'/initialPayment'} /> : <></>}




                        </Grid>
                        <Grid item container xs={11} justifyContent="flex-end" style={{ paddingBottom: "40" }}>
                            <Button
                                variant="contained"
                                // onClick={() =>
                                //     this.passValue()


                                // }
                                className={classes.formBtn}
                                style={{ width: "auto" }}
                            >

                                <Typography variant="body2">CONTINUE</Typography>

                            </Button>
                        </Grid>
                    </>}

            </Grid>
        );
    }
    // Customizable Area Emd
}

export default withStyles(useStyles)(RedirectPage);

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    dataToRender: any;
    w1Data: any;
    token: any;
    totalKeys: any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class TransactionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    w1DataId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            dataToRender: [],
            token: '',
            w1Data: "",
            totalKeys: []
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!responseJson.errors && apiRequestCallId == this.w1DataId) {
                const personalData = this.gettingKeyAndValue(responseJson.data[0].personal_1040[0], 1)
                const scheduleC = this.gettingKeyAndValue(responseJson.data[0].schedule_c[0], 0)
                const dataToRender = [{ data: [...personalData], name: "Personal 1040", text: "U.S. Individual Income Tax Return" }, { data: [...scheduleC], name: "Schedule C", text: "Profit or Loss from Your Business" }]
                const totalKeys = personalData.length > scheduleC.length ? personalData : scheduleC
                this.setState({ dataToRender: dataToRender, w1Data: responseJson.data[0], totalKeys: totalKeys })
            } else {
                this.parseApiErrorResponse(responseJson);
            }
        }
    }
    // Customizable Area End

    // Customizable Area Start

    getData = (year: any) => {
        const headers = {
            "Content-Type": configJSON.ApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.w1DataId = requestMessage.messageId;
        let selectedYear = year ? year : moment().format('YYYY')
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.formApiW1EndPoint}?year=${selectedYear}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    gettingKeyAndValue = (data: any, flag: number) => {
        let keys = Object.keys(data).map(el => el.split('_').join(" "))
        keys = keys.map(el => {
            if (el == 'over tax paid') {
                el = 'Over/(Under) Tax Paid'
            } else if (el == "qbi deduction") {
                el = "QBI deduction"
            } else if (el == "est taxable income") {
                el = "taxable income"
            } else if (el == "cost of goods") {
                el = 'Cost of Goods Sold'
            } else if (el == 'tentative profit') {
                el = 'Net Profit'
            }
            return el
        })
        let values = Object.values(data)
        values = values.map((el: any) => {
            return Math.floor(el).toLocaleString(undefined)
        })
        const returnObj = keys.map((key, index) => {
            let number = 0
            if (flag) {
                switch (index) {
                    case 0:
                        number = 8
                        break;
                    case 1:
                        number = 13
                        break;
                    case 2:
                        number = 15
                        break;
                    case 3:
                        number = 16
                        break;
                    case 4:
                        number = 23
                        break;
                    case 5:
                        number = 24
                        break;
                    case 6:
                        number = 26
                        break;
                    default:
                        number = 36
                }
            } else {
                switch (index) {
                    case 0:
                        number = 1
                        break;
                    case 1:
                        number = 4
                        break;
                    case 2:
                        number = 7
                        break;
                    case 3:
                        number = 8
                        break;
                    case 4:
                        number = 28
                        break;
                    default:
                        number = 29
                }
            }
            return { key: key, value: values[index], number: number }
        })
        return returnObj
    }



    // Customizable Area End
}

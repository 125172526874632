import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Dimensions,
  Image,
  TouchableOpacity,
  FlatList,
  TouchableHighlight,
} from "react-native";
import Carousel from '../../../components/src/Carousel/Carousel';
import CategoriessubcategoriesController, {
  CategoryItemType,
  IncomeDataObjectType,
  Props
} from "./CategoriessubcategoriesController";
import { scaledSize } from "../../../framework/src/Utilities";
import { check, downArrowIcon, roundCheckIcon } from "./assets";
import Button from "../../../components/src/Button";
import Categoriesdetails from '../../../components/src/Categoriesdetails';
import SingleTransactionModal from "../../../components/src/SingleTransactionModal";
import CategoriessubcategoriesMainCompo from "../../../components/src/CategoriessubcategoriesMainCompo";
// Customizable Area End
export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    return (
      <>
        <CategoriessubcategoriesMainCompo 
          carousel={this.carousel}
          handleSnapToItem={this.handleSnapToItem}
          closeModal={this.closeModal}
          deleteCameraImage={this.deleteCameraImage}
          deleteImage={this.deleteImage}
          handleBackPress={this.handleBackPress}
          handleCameraPress={this.handleCameraPress}
          handleDeleteImage={this.handleDeleteImage}
          handleDeleteSelectedImage={this.handleDeleteSelectedImage}
          handleImageLibrary={this.handleImageLibrary}
          handleInputChange={this.handleInputChange}
          handleNoteChange={this.handleNoteChange}
          handlePressAddDetails={this.handlePressAddDetails}
          handleSingleTransactionModal={this.handleSingleTransactionModal}
          onClickDownArrow={this.onClickDownArrow}
          onPressAddToReport={this.handleAddtoReport}
          setCategoryItem={this.setCategoryItem}
          state={this.state}
          check={check} 
          downArrowIcon={downArrowIcon} 
          roundCheckIcon={roundCheckIcon}
          handleSpiltTransaction={this.handleSpiltTransaction}
          handleEditSplit={this.handleEditSplit}
          handleEditDetails={this.handleEditDetails}
        />
        {this.state.isShowSingleTransactionModal
          && <SingleTransactionModal
            state={this.state}
            handleSingleTransactionModal={this.handleSingleTransactionModal}
            handleNoteChange={this.handleNoteChange}
            handleAddDetails={this.handleAddDetails}
            handleDeleteImage={this.handleDeleteImage}
            handleImageLibrary={this.handleImageLibrary}
            closeModal={this.closeModal}
            deleteImage={this.deleteImage}
            handleDeleteSelectedImage={this.handleDeleteSelectedImage}
            deleteCameraImage={this.deleteCameraImage}
            handleCameraPress={this.handleCameraPress}
            handleSpiltTransaction={this.handleSpiltTransaction}
            handleAddtoReport={this.handleAddtoReport}
          />}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffffff"
  },
  carouselView: {
    marginTop: scaledSize(10)
  },
  expenseFlatListView:{
    paddingLeft: 30,
  },
  incomeItemContainer: {
    height: scaledSize(140),
    width: scaledSize(140),
    borderRadius: scaledSize(70),
    backgroundColor: "#3876F1",
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: "center",
    opacity: 1,
    marginTop: scaledSize(13),
  },
  inactiveIncomeItemContainer: {
    height: scaledSize(126),
    width: scaledSize(126),
    borderRadius: scaledSize(63),
    backgroundColor: "#3876F1",
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: "center",
    marginTop: scaledSize(17),
    opacity: 0.8,
  },
  innerIncomeItemContainer: {
    height: scaledSize(124),
    width: scaledSize(124),
    borderRadius: scaledSize(62),
    backgroundColor: "#FFFFFF",
    alignItems: "center"
  },
  inactiveInnerIncomeItemContainer: {
    height: scaledSize(112),
    width: scaledSize(112),
    borderRadius: scaledSize(56),
    backgroundColor: "#FFFFFF",
    alignItems: "center"
  },
  incomeDateText: {
    fontSize: scaledSize(12),
    fontFamily: "OpenSans-Bold",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(109),
    marginTop: scaledSize(26)
  },
  inactiveIncomeDateText: {
    fontSize: scaledSize(11),
    fontFamily: "OpenSans-Bold",
    lineHeight: scaledSize(20),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(98),
    marginTop: scaledSize(23.4)
  },
  incomeTitleText: {
    fontSize: scaledSize(12),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#3876F1",
    width: scaledSize(120),
    textTransform: "uppercase"
  },
  inactiveIncomeTitleText: {
    fontSize: scaledSize(11),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(20),
    textAlign: 'center',
    color: "#3876F1",
    width: scaledSize(108),
    textTransform: "uppercase"
  },
  incomePriceText: {
    fontSize: scaledSize(15),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(22),
    textAlign: 'center',
    color: "#000",
    width: scaledSize(107),
    marginTop: scaledSize(5),
  },
  inactiveIncomePriceText: {
    fontSize: scaledSize(13),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(20),
    textAlign: 'center',
    color: "#000",
    width: scaledSize(96),
    marginTop: scaledSize(5),
  },
  incomCreditText: {
    fontSize: scaledSize(7),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(12),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(90),
    textTransform: "uppercase"
  },
  inactiveIncomCreditText: {
    fontSize: scaledSize(7),
    fontFamily: "OpenSans-Medium",
    lineHeight: scaledSize(12),
    textAlign: 'center',
    color: "#B3B3B3",
    width: scaledSize(81),
    textTransform: "uppercase"
  },
  rightArrowIconView: {
    position: 'absolute',
    top: scaledSize(65),
    left: scaledSize(253),
  },
  rightArrowIcon: {
    height: scaledSize(23),
    width: scaledSize(23)
  },
  leftArrowIconView: {
    position: 'absolute',
    top: scaledSize(65),
    left: scaledSize(80),
  },
  leftArrowIcon: {
    height: scaledSize(23),
    width: scaledSize(23),
    transform: [{ rotate: '180deg' }]
  },
  categoryFlatListView: {
    marginTop: scaledSize(15),
    // height: scaledSize(260)
  },
  categoryItemContainer: {
    width: scaledSize(284),
    minHeight: scaledSize(38),
    borderRadius: scaledSize(44),
    backgroundColor: "#FFF",
    shadowColor: "#000000",
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 20,
    alignSelf: "center",
    marginVertical: scaledSize(7),
    elevation: 7,
    alignItems: "center",
    flexDirection: "row"
  },
  expenseItemContainer:{
    width: scaledSize(260),
    minHeight: scaledSize(38),
    borderRadius: scaledSize(44),
    backgroundColor: "#FFF",
    shadowColor: "#000000",
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 20,
    alignSelf: "center",
    marginVertical: scaledSize(7),
    elevation: 7,
    alignItems: "center",
    flexDirection: "row"
  },
  categoryText: {
    fontSize: scaledSize(14),
    lineHeight: scaledSize(21),
    fontFamily: "OpenSans-Bold",
    color: "#0F172A",
    marginLeft: scaledSize(22),
    width: scaledSize(224)
  },
  expenseCategoryText: {
    fontSize: scaledSize(14),
    lineHeight: scaledSize(21),
    fontFamily: "OpenSans-Bold",
    color: "#0F172A",
    marginLeft: scaledSize(22),
    width: scaledSize(200)
  },
  checkIconStyle: {
    height: scaledSize(21),
    width: scaledSize(21),
  },
  roundCheckIcon: {
    height: scaledSize(44),
    width: scaledSize(44),
    position: "absolute",
    alignSelf: "center",
    top: scaledSize(0),
    zIndex: 1000
  }
});
// Customizable Area End
